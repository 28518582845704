import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tablechild from "./TableChild/Tablechild";
import "./Table.component.css";
import { SET_FORM_TYPES } from "../../../../redux/actions/action.types";

const setFormTypes = (stateHandler: any, formTypes: any[]) => {
  stateHandler({
    type: SET_FORM_TYPES,
    payload: formTypes,
  });
};

export default function Table() {
  const recData = useSelector((state: any) => state.rpRecipientData);
  const formTypes = useSelector((state: any) => state.formTypes);
  const dispatch = useDispatch();

  // Get the form type from each recipient data object
  useEffect(() => {
    const formTypes: any[] = [];
    recData.forEach((dataObj: any, i: number) => {
      let index = formTypes.length - 1;
      if (formTypes.length > 0 && formTypes[index].type === dataObj.form_type) {
        let stIdx = formTypes[index].startIndex;
        formTypes.pop();
        formTypes.push({
          type: dataObj.form_type,
          startIndex: stIdx,
          endIndex: i,
        });
      } else {
        formTypes.push({
          type: dataObj.form_type,
          startIndex: i,
          endIndex: i,
        });
      }
    });
    setFormTypes(dispatch, formTypes);
    return () => {
      setFormTypes(dispatch, []);
    };
  }, [recData, dispatch]);

  return (
    <div
      style={{
        padding: "0 1rem 1.5rem",
        marginTop: "0.5rem",
        height: "59.5vh",
        overflow: "auto",
      }}
    >
      {formTypes.map((form: any, i: number) => {
        return (
          <Tablechild
            key={i}
            formIdx={i}
            formType={form.type}
            stidx={form.startIndex}
            endIdx={form.endIndex}
          />
        );
      })}
    </div>
  );
}
