import { ReactNode } from "react";
import {
  SET_MODAL_HEADING,
  SET_MODAL_MESSAGE,
  TO_DISPLAY_MODAL,
} from "../../redux/actions/action.types";
import { INFO_MODAL } from "../constants";

export default function displayInfoModal(
  toDisplay: boolean,
  message: string | ReactNode,
  heading: string,
  stateSetter: any
) {
  stateSetter({
    type: TO_DISPLAY_MODAL,
    payload: {
      toDisplay: toDisplay,
      modaltype: INFO_MODAL,
    },
  });

  // Set the message
  stateSetter({
    type: SET_MODAL_MESSAGE,
    payload: message,
  });

  // Set heading
  stateSetter({
    type: SET_MODAL_HEADING,
    payload: heading,
  });
}
