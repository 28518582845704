import { TO_DISPLAY_CONFIRMATION_MODAL } from "../../redux/actions/action.types";

export default function displayConfirmationModal(
  stateSetter: any,
  action: any,
  status: any,
  stateUpdateMethod?: React.Dispatch<React.SetStateAction<any>>,
  extraPayloadIds?: string[]
) {
  stateSetter({
    type: TO_DISPLAY_CONFIRMATION_MODAL,
    payload: {
      status,
      action,
      stateUpdateMethod,
      extraPayloadIds,
    },
  });
}
