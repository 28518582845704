import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ActionPopup.css";
import { useState } from "react";
import { FORM_DELETE, FORM_DOWNLOAD, FORM_EDIT } from "../../../lib/constants";
import { SingleAction } from "../../../lib/utils/SingleAction";
import devLogInstance from "../../../lib/loggerConfig";
import { useNavigate } from "react-router-dom";
import displayConfirmationModal from "../../../lib/StateSetters/displayConfirmationModal";
import { DELETE_ACTION_SINGLE } from "../../../lib/UserActions/action";

export default function ActionPopup({ cartId }: any) {
  const style = useSelector((state: any) => state.themeChange);
  const stateSetter = useDispatch();
  //   const stateSetter = useDispatch();
  const [isHovered, setIsHovered] = useState({
    download: false,
    delete: false,
    edit: false,
  });
  const navigate = useNavigate();

  const handleHover = (e: any) => {
    const { id: hoveredOn } = e.target;
    hoveredOn === FORM_DOWNLOAD && setIsHovered({ download: true, delete: false, edit: false });
    hoveredOn === FORM_DELETE && setIsHovered({ download: false, delete: true, edit: false });
    hoveredOn === FORM_EDIT && setIsHovered({ download: false, delete: false, edit: true });
  };

  const handleMouseLeave = (e: any) => {
    setIsHovered({
      download: false,
      delete: false,
      edit: false,
    });
  };

  //  Handle click on any action
  const handleClick = (e: any) => {
    const action = e.target.id ? e.target.id : e.target.parentElement.id;
    devLogInstance.log(action);
    SingleAction.cartIds = SingleAction.getCartIds(cartId);
    if (action === FORM_DOWNLOAD) {
      SingleAction.formDownload();
    } else if (action === FORM_DELETE) {
      // Call the confirmation modal
      displayConfirmationModal(stateSetter, DELETE_ACTION_SINGLE, true);
    } else if (action === FORM_EDIT) {
      SingleAction.edit(cartId, navigate);
    }
  };

  return (
    <main
      className="action-popup-main"
      style={{
        background: style.siteBg,
        boxShadow: `2px 2px 10px ${style.boxShadowColor}`,
      }}
    >
      <div
        id={FORM_DOWNLOAD}
        onMouseOver={handleHover}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        style={{
          background: isHovered.download ? style.hoverableNameBg : "",
          color: isHovered.download ? style.hoverableNameColor : "",
        }}
      >
        <i className="fa-solid fa-download" onClick={handleClick} />
        <p>Download</p>
      </div>
      <div
        id={FORM_EDIT}
        onClick={handleClick}
        onMouseOver={handleHover}
        onMouseLeave={handleMouseLeave}
        style={{
          background: isHovered.edit ? style.hoverableNameBg : "",
          color: isHovered.edit ? style.hoverableNameColor : "",
        }}
      >
        <i className="fa-solid fa-file-pen" />
        <p>Edit</p>
      </div>
      <div
        id={FORM_DELETE}
        onClick={handleClick}
        onMouseOver={handleHover}
        onMouseLeave={handleMouseLeave}
        style={{
          background: isHovered.delete ? style.hoverableNameBg : "",
          color: isHovered.delete ? style.hoverableNameColor : "",
        }}
      >
        <i className="fa-solid fa-trash" />
        <p>Delete</p>
      </div>
    </main>
  );
}
