import setSelectedPayerId from "../StateSetters/setSelectedPayerId";
import selectDefaultPayer from "./selectDefaultPayer";

export default function selectCurrentPayer(payerId: any, nonReviewedData: any, stateHandler: any) {
  const currentDataObj = nonReviewedData.filter((dataObj: any) => {
    return dataObj.payer_data.payer_id === payerId;
  })[0];
  currentDataObj && currentDataObj.length !== 0
    ? setSelectedPayerId(currentDataObj.payer_data.payer_id, stateHandler)
    : selectDefaultPayer(nonReviewedData, stateHandler); // If the payer dosent exist switch to the default payer
  // Remove the payer id from ls once the payer is selected
  localStorage.removeItem("payer_id");
}
