import React from "react";
import "./FeedbackModal.css";
import Rating from "../../../RatingSystem/Rating";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Select from "react-select";
import SelectStyle from "../../../../lib/ReactSelectTheme/reactSelectStyle";
import { SKIP_FEEDBACK, SUBMIT_FEEDBACK, SUBMIT_FEEDBACK_LOGOUT } from "../../../../lib/constants";
import logoutUser from "../../../../lib/authRequests/logoutUser";
import Handler from "../../../../lib/Handlers/main";
import devLogInstance from "../../../../lib/loggerConfig";
import displayFeedbackModal from "../../../../lib/StateSetters/displayFeedbackModal";

export default function FeedbackModal() {
  const style = useSelector((state: any) => state.themeChange);
  const [feedbackValues, setFeedbackValues] = useState({
    form_type: "1099",
    overall_experience: 0,
    most_liked_features: "",
    feature_can_be_improved: "",
    recommend_to_a_friend: false,
    testimonials: "",
    consent_for_sharing_review: false,
    show_feedback_window: true,
  });
  const [noFeedback, setNoFeedback] = useState(true);
  const stateSetter = useDispatch();

  // To monitor if user puts in a feedback
  useEffect(() => {
    // console.log(feedbackValues.show_feedback_window);
    const isThereaFeedback = () => {
      if (
        feedbackValues.overall_experience !== 0 ||
        feedbackValues.most_liked_features ||
        feedbackValues.feature_can_be_improved ||
        feedbackValues.recommend_to_a_friend ||
        feedbackValues.testimonials ||
        feedbackValues.consent_for_sharing_review
      ) {
        return true;
      } else {
        return false;
      }
    };
    isThereaFeedback() ? setNoFeedback(false) : setNoFeedback(true);
    return () => {
      setNoFeedback(true);
    };
  }, [feedbackValues]);

  // Handle input value change
  const handleChange = (e: any) => {
    const { id: feedbackType, value } = e.target;
    switch (feedbackType) {
      case "most-liked-feature":
        setFeedbackValues({
          ...feedbackValues,
          most_liked_features: value,
        });
        break;

      case "improvement":
        setFeedbackValues({
          ...feedbackValues,
          feature_can_be_improved: value,
        });
        break;

      case "testimonial":
        setFeedbackValues({
          ...feedbackValues,
          testimonials: value,
        });
        break;

      case "recommend-checkbox":
        setFeedbackValues({
          ...feedbackValues,
          recommend_to_a_friend: !feedbackValues.recommend_to_a_friend,
        });
        break;

      case "consent-checkbox":
        setFeedbackValues({
          ...feedbackValues,
          consent_for_sharing_review: !feedbackValues.consent_for_sharing_review,
        });
        break;

      case "do-not-show-popup-checkbox":
        setFeedbackValues({
          ...feedbackValues,
          show_feedback_window: !feedbackValues.show_feedback_window,
        });
        break;

      default:
        break;
    }
  };

  // Rating system callback
  const handleRating = (rating: any) => {
    setFeedbackValues({
      ...feedbackValues,
      overall_experience: rating,
    });
  };

  // Handle click
  const handleClick = (e: any) => {
    const { id: clickType } = e.target;
    const _feedbackValues =
      feedbackValues.overall_experience < 1
        ? { ...feedbackValues, overall_experience: 1 }
        : feedbackValues;

    devLogInstance.log(_feedbackValues);
    // Hide the feedback modal
    displayFeedbackModal(false, stateSetter);
    switch (clickType) {
      case SKIP_FEEDBACK:
        // If user has checked do not show popup checkbox
        if (!feedbackValues.show_feedback_window) {
          // Call the API to update the value
          Handler.handleFeedbackSubmission(_feedbackValues)
            .then((res) => {
              devLogInstance.log(res);
            })
            .catch((err) => {
              devLogInstance.error(err);
            })
            .finally(() => {
              // Logout the user
              logoutUser(stateSetter);
            });
        } else {
          // Simply Logout the user
          logoutUser(stateSetter);
        }
        break;

      case SUBMIT_FEEDBACK:
        // Submit the data to backend
        Handler.handleFeedbackSubmission({ ..._feedbackValues, show_feedback_window: false })
          .then((res) => {
            devLogInstance.log(res);
          })
          .catch((err) => {
            devLogInstance.error(err);
          });
        break;

      case SUBMIT_FEEDBACK_LOGOUT:
        // Submit the data to backend
        Handler.handleFeedbackSubmission({ ..._feedbackValues, show_feedback_window: false })
          .then((res) => {
            devLogInstance.log(res);
          })
          .catch((err) => {
            devLogInstance.error(err);
          })
          .finally(() => {
            // Logout the user
            logoutUser(stateSetter);
          });
        break;

      case null:
        break;

      default:
        break;
    }
  };

  return (
    <div className="fixed top-4 modal-wrapper">
      <div
        className="modal overflow-auto h-[88vh]"
        style={{ background: style.siteBg, boxShadow: `4px 4px 20px ${style.boxShadowColor}` }}
      >
        <div className="modal-heading" style={{ background: style.modalTopPanelBg }}>
          <h2>Please provide us a feedback</h2>
        </div>
        <div className="form-type-container">
          <Select
            theme={new SelectStyle(style).customTheme}
            styles={new SelectStyle(style).customStyle}
            isDisabled
            placeholder="Form-1099"
          />
        </div>
        <Rating callback={handleRating} />
        <div style={{ display: "flex" }}>
          <div className="most-liked-feature-container">
            <label htmlFor="most-liked-feature" className="most-liked-feature-label">
              Which feature did you like the most?
            </label>
            <input
              id="most-liked-feature"
              value={feedbackValues.most_liked_features}
              onChange={handleChange}
              autoComplete="off"
            />
          </div>
          <div className="improvement-container">
            <label htmlFor="improvement" className="improvement-label">
              What can be improved?
            </label>
            <input
              id="improvement"
              value={feedbackValues.feature_can_be_improved}
              onChange={handleChange}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="testimonial-container">
          <label htmlFor="testimonial" className="testimonial-label">
            Please say something about us in brief:
          </label>
          <textarea
            className="border border-slate-400"
            id="testimonial"
            value={feedbackValues.testimonials}
            onChange={handleChange}
          />
        </div>
        <div style={{ display: "flex" }}>
          <div className="recommend-checkbox-container">
            <label htmlFor="recommend-checkbox" className="recommend-checkbox-label">
              Would you recommend us to a friend?
            </label>
            <input
              id="recommend-checkbox"
              type="checkbox"
              checked={feedbackValues.recommend_to_a_friend}
              onChange={handleChange}
            />
          </div>
          <div className="consent-checkbox-container">
            <label htmlFor="consent-checkbox" className="consent-checkbox-label">
              Do you give consent to share your feedback?
            </label>
            <input
              id="consent-checkbox"
              type="checkbox"
              checked={feedbackValues.consent_for_sharing_review}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="feedback-submit-container">
          <div className="do-not-show-popup">
            <input
              type="checkbox"
              id="do-not-show-popup-checkbox"
              checked={!feedbackValues.show_feedback_window}
              onChange={handleChange}
            />
            <label htmlFor="do-not-show-popup-checkbox" className="do-not-show-popup-label">
              Do not show this popup again
            </label>
          </div>
          <div className="skip-btn-container">
            <button
              id={SKIP_FEEDBACK}
              className="skip-btn"
              style={{ background: style.btnSecondaryBg, color: style.btnFillFontColor }}
              onClick={handleClick}
            >
              Skip & Logout
            </button>
          </div>
          {!noFeedback && (
            <>
              <div className="submit-feedback-btn-container">
                <button
                  id={SUBMIT_FEEDBACK}
                  className="submit-feedback-btn"
                  style={{ background: style.btnBg, color: style.btnFillFontColor }}
                  onClick={handleClick}
                >
                  Submit
                </button>
              </div>
              <div className="submit-feedback-btn-container">
                <button
                  id={SUBMIT_FEEDBACK_LOGOUT}
                  className="submit-feedback-btn"
                  style={{ background: style.btnBg, color: style.btnFillFontColor }}
                  onClick={handleClick}
                >
                  Submit & Logout
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
