import React from "react";
import "./DateInput.css";
// import "react-calendar/dist/Calendar.css";
import { useEffect, useMemo, useState } from "react";
import Calendar from "react-calendar";
import devLogInstance from "../../../lib/loggerConfig";
import { organizeDate } from "../../forms/ReviewForm/Form1099SRightPortion/SRightPortion";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import NavigationLabel from "./NavigationLabel/NavigationLabel";

interface Props {
  minDate: Date;
  maxDate: Date;
  clearDate: any;
  callback: any;
  groupId: string;
  classes: string;
  value: any;
  showDateRequiredIndicator?: boolean;
  toShowDateRequired?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function DateInput({
  minDate,
  maxDate = new Date(),
  clearDate = null,
  callback,
  groupId = "",
  classes,
  value,
  showDateRequiredIndicator,
  toShowDateRequired,
}: Props) {
  const [displayCalendar, toDisplayCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(maxDate.getDate());
  const [selectedMonth, setSelectedMonth] = useState(maxDate.getMonth());
  const [selectedYear, setSelectedYear] = useState(maxDate.getFullYear());
  const [date, setDate] = useState("");
  const [isInvalidDate, setIsInvalidDate] = useState<boolean | null>(null);
  const [calendarView, setCalendarView] = useState("month");
  // const [group, setGroup] = useState(null);

  // Set the group
  // useEffect(() => {
  //   setGroup(groupId);

  //   return () => {
  //     setGroup(groupId);
  //   };
  // }, [groupId]);

  // Return any invalid status and the current date in the callback
  useEffect(() => {
    date && callback(date, isInvalidDate);
  }, [date, isInvalidDate, callback]);

  // Clear the date if true
  useEffect(() => {
    clearDate && setDate("");
  }, [clearDate]);

  // Set any default value
  useEffect(() => {
    if (value) {
      setDate(organizeDate(value));
    }

    return () => {
      setDate("");
    };
  }, [value]);

  const styleTileClassname = ({ activeStartDate, date }: any) => {
    const tileDate = date.getDate();
    const tileMonth = date.getMonth();
    const tileYear = date.getFullYear();

    const activeDate = activeStartDate.getDate();
    const activeMonth = activeStartDate.getMonth();
    const activeYear = activeStartDate.getFullYear();
    // devLogInstance.log(`Selected Year ---> ${selectedYear}`);
    // devLogInstance.log(`Tile Year ---> ${tileYear}`);

    // Assign a class name to color the background if the current date and tile date matches
    return (selectedDate === tileDate &&
      selectedMonth === tileMonth &&
      selectedYear === tileYear) ||
      (activeDate === selectedDate && activeMonth === selectedMonth && activeYear === selectedYear)
      ? `active-date ${groupId}`
      : `tile ${groupId}`;
  };

  // Handle Date change
  const handleDateChange = (date: any, event: any) => {
    setSelectedDate(date.getDate());
    setSelectedMonth(date.getMonth());
    setSelectedYear(date.getFullYear());

    let month: number | string = date.getMonth() + 1;
    month = month.toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });
    const date_ = date
      .getDate()
      .toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });
    const year = date.getFullYear().toString();

    setDate(month + "/" + date_ + "/" + year);

    // Keep the calendar open only when the user is not on the month view
    calendarView !== "month" && toDisplayCalendar(true);
  };

  // Handle date input change
  const handleDateInputChange = (e: any) => {
    let { value } = e.target;
    value = value.replaceAll("/", "");
    const numberRegex = new RegExp("^[0-9]+$");
    const isValidNumber = value ? numberRegex.test(value) : true;
    if (value.length <= 8 && isValidNumber) {
      if (value.length > 2 && value.length < 5) {
        setDate(`${value.substring(0, 2)}/${value.substring(2)}`);
      } else if (value.length >= 5) {
        setDate(`${value.substring(0, 2)}/${value.substring(2, 4)}/${value.substring(4)}`);
      } else {
        setDate("undefined");
      }
    }
  };

  // Validate the date in realtime
  useMemo(() => {
    if (date) {
      toShowDateRequired && toShowDateRequired(false); // Remove any existing error
      const dateRegex = new RegExp("^(0[1-9]|1[0-2])/(0[1-9]|[1-2][0-9]|3[0-1])/\\d{4}$");
      const isValid = dateRegex.test(date);

      devLogInstance.log("Is valid date: " + isValid);

      isValid ? setIsInvalidDate(false) : setIsInvalidDate(true);
    } else {
      setIsInvalidDate(null);
    }
  }, [date, toShowDateRequired]);

  // Handle view change
  const handleViewChange = ({ action, activeStartDate, value, view }: any) => {
    devLogInstance.log("Current view is ---> " + view);
    setCalendarView(view);
    // // Keep the calendar open
    // toDisplayCalendar(true);
  };

  // handle clicks outside the calendar
  useEffect(() => {
    const handleClick = (e: any) => {
      let class_ = "";
      // Get the topmost parent's classname
      const currentElement = e.target;
      let topParent = currentElement;
      try {
        while (topParent.parentElement.nodeName !== "MAIN") {
          topParent = topParent.parentElement;
        }
        class_ = topParent.className;
      } catch (err) {
        devLogInstance.warn(
          "Error while drilling up the parent elements. Checking if the initial child element or its parent has calendar in classname"
        );
        class_ = currentElement.className.includes("calendar")
          ? currentElement.className
          : currentElement.parentElement.className;

        !class_.includes("calendar") &&
          devLogInstance.warn("No match found! Must be a click outside calendar");
      }

      const { id } = e.target;
      const { id: parentId } = e.target.parentElement;
      let onCalendar = false;

      onCalendar = class_.includes("calendar") && class_.includes(groupId);
      devLogInstance.log(`Clicked inside calendar of group ${groupId}? --> ${onCalendar}`);

      (id === groupId || parentId === groupId) && !onCalendar
        ? toDisplayCalendar(!displayCalendar)
        : onCalendar
        ? toDisplayCalendar(true)
        : toDisplayCalendar(false);
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [displayCalendar, groupId]);

  return (
    <>
      <main id={groupId} className="date-input-container">
        <input
          id={groupId}
          type="text"
          value={date.includes("undefined") ? "" : date}
          placeholder="MM/DD/YYYY"
          onChange={handleDateInputChange}
        />
        <div className="calendar-icon-wrapper">
          <i
            id={groupId}
            className="fa-solid fa-calendar calendar-icon"
            // onClick={toggleCalendar}
          />
        </div>
        <Calendar
          // id={groupId}
          className={`calendar ${groupId} ${
            displayCalendar ? "show-calendar" : "hide-calendar"
          } ${classes}`}
          tileClassName={styleTileClassname}
          onChange={handleDateChange}
          defaultActiveStartDate={maxDate}
          navigationLabel={(params) => (
            <NavigationLabel
              _id={groupId}
              // _className={JSON.stringify({ baseVal: "react-calendar-nav-label" })}
              _className={"react-calendar-nav-label"}
              date={params.date}
              locale={params.locale}
              label={params.label}
              view={params.view}
            />
          )}
          nextLabel={
            <IoIosArrowForward
              id={groupId}
              className="react-calendar-next-label"
              style={{ paddingTop: "0.25rem" }}
            />
          }
          next2Label={
            <MdKeyboardDoubleArrowRight
              id={groupId}
              className="react-calendar-next2-label"
              style={{ paddingTop: "0.25rem", fontSize: "1.5rem" }}
            />
          }
          prevLabel={
            <IoIosArrowBack
              id={groupId}
              className="react-calendar-prev-label"
              style={{ paddingTop: "0.25rem" }}
            />
          }
          prev2Label={
            <MdKeyboardDoubleArrowLeft
              id={groupId}
              className="react-calendar-prev2-label"
              style={{ paddingTop: "0.25rem", fontSize: "1.5rem" }}
            />
          }
          // next2Label={isNextLabelDisabled ? "" : "»"}
          // onActiveStartDateChange={handleActiveStartDateChange}
          onViewChange={handleViewChange}
          // value={calendarVal}
          minDate={minDate}
          maxDate={maxDate}
        />
      </main>
      {isInvalidDate && <p className="invalid-date-indicator">Invalid Date</p>}
      {showDateRequiredIndicator && (
        <p className="invalid-date-indicator">* This field is required</p>
      )}
    </>
  );
}
