import { DisplayHammenuReducerAction } from "../../../../interfaces/main";
import { TO_DISPLAY_HAM_MENU } from "../../actions/action.types";

const initial: { toDisplay: boolean; menutype: string } = {
  toDisplay: false,
  menutype: "",
};

const displayHammenu = (state = initial, action: DisplayHammenuReducerAction) => {
  if (action.type === TO_DISPLAY_HAM_MENU) return action.payload;
  return state;
};

export default displayHammenu;
