import { SET_STATE_AMOUNT } from "../actions/action.types";

const initialState = {
  stateTax1: "",
  stateIncome1: "",

  stateTax2: "",
  stateIncome2: "",
};

const stateAmount = (state = initialState, action: any) => {
  if (action.type === SET_STATE_AMOUNT) return action.payload;
  else return state;
};

export default stateAmount;
