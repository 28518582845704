import React from "react";
import { payerDetails, payerDetailsFor1099S } from "../../../../lib/languagePacks/en-us";
import formatTin from "../../../../lib/form/utils/formatTin";
import { FORM1099_S } from "../../../../lib/constants";
import maskTin, { isEin } from "../../../../lib/utils/maskTin";
import { useSelector } from "react-redux";

export default function RFPayerTin({ formData, styles }: any) {
  const toMaskTin = useSelector((state: any) => state.toMaskTin);
  return (
    <div
      style={{ width: "50%", borderRight: `1px solid black`, ...styles }}
      className="tin-container"
    >
      <div>
        <h4>
          {formData?.form_type === FORM1099_S
            ? payerDetailsFor1099S.payerTinDisplayText
            : payerDetails.payerTinDisplayText}
        </h4>
      </div>
      <div style={{ color: "#30A2FF" }}>
        <p>
          {formData?.payer_tin &&
            `${formData?.payer_tin_type}: ${
              !isEin(formData?.payer_tin_type)
                ? toMaskTin
                  ? maskTin(
                      formatTin(formData?.payer_tin, formData?.payer_tin_type),
                      formData?.payer_tin_type
                    )
                  : formatTin(formData?.payer_tin, formData?.payer_tin_type)
                : formatTin(formData?.payer_tin, formData?.payer_tin_type)
            }`}
        </p>
      </div>
    </div>
  );
}
