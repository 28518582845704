import React from "react";
import { useDispatch, useSelector } from "react-redux";
import devLogInstance from "../../../lib/loggerConfig";
import "./Collapsible.css";
import { useRef, useState, useEffect, useMemo } from "react";
import {
  removeAllRecForPayer,
  selectAllRecForPayer,
} from "../../../lib/StateSetters/allRecipientsForPayer";

export default function Collapsible({
  header,
  body,
  headerStyle,
  clMainStyle,
  setToDisplaySelectAllPayer,
  payerId,
  setTotalSelectedRec = null,
  recipientDataArray,
  isDraftData = false,
}: any) {
  const [collapse, toCollapse] = useState(false);
  const [overflowVal, setOverflow] = useState<string | null>(null);
  const [collapsibleHeadBorderRadius, setCollapsibleHeadBorderRadius] = useState<string | null>(
    null
  );
  const bodyContent = useRef<HTMLDivElement | null>(null);
  const [bodyContentHeight, setbodyContentHeight] = useState(9000);
  const [isChecked, setIsChecked] = useState(false);
  const [isSemiChecked, setIsSemiChecked] = useState(false);
  const dispatch = useDispatch();
  const cartPageSelectedRec = useSelector((state: any) => state.cartPageSelectedRec);

  useEffect(() => {
    const currentHeight = bodyContent.current?.offsetHeight;
    devLogInstance.log(`Cl body height ---> ${currentHeight}`);
    currentHeight && currentHeight > 0 && setbodyContentHeight(currentHeight);
    setTimeout(() => {
      toCollapse(true);
    }, 700); // Collapse after 0.7 secs for the animation to trigger
  }, []);

  //  Set the overflow value
  useMemo(() => {
    if (collapse) setOverflow("hidden");
    else {
      setTimeout(() => {
        setOverflow("visible");
      }, 700);
    }
  }, [collapse]);

  // Set the collapsible heading border radius value based on different point in collapse action
  useMemo(() => {
    if (collapse) {
      setTimeout(() => {
        setCollapsibleHeadBorderRadius("0.5rem");
      }, 700);
    } else {
      setCollapsibleHeadBorderRadius("0.5rem 0.5rem 0 0");
    }
  }, [collapse]);

  // Set the total selected Rec for each payer
  useEffect(() => {
    if (cartPageSelectedRec[payerId] && setTotalSelectedRec !== null)
      setTotalSelectedRec((prevState: string[][]) => {
        return [...prevState, ...cartPageSelectedRec[payerId].cartIds];
      });
    return () => {
      setTotalSelectedRec !== null && setTotalSelectedRec([]);
    };
  }, [payerId, setTotalSelectedRec, cartPageSelectedRec]);

  // Udate the checkbox state based on the rec selected
  useEffect(() => {
    if (!isDraftData) {
      if (
        cartPageSelectedRec[payerId]?.cartIds.length !== 0 &&
        cartPageSelectedRec[payerId]?.cartIds.length === recipientDataArray.length
      ) {
        setIsChecked(true);
        setIsSemiChecked(false);
        // Display deselect all payer
        setToDisplaySelectAllPayer(false);
      } else if (
        cartPageSelectedRec[payerId] &&
        cartPageSelectedRec[payerId].cartIds.length !== 0 &&
        cartPageSelectedRec[payerId].cartIds.length !== recipientDataArray.length
      ) {
        console.log(cartPageSelectedRec);
        console.log(recipientDataArray.length);

        setIsChecked(false);
        setIsSemiChecked(true);
        // Display deselect all payer
        setToDisplaySelectAllPayer(false);
      } else if (cartPageSelectedRec[payerId]?.cartIds.length === 0) {
        setIsChecked(false);
        setIsSemiChecked(false);
      }
    }
  }, [recipientDataArray, payerId, cartPageSelectedRec, setToDisplaySelectAllPayer, isDraftData]);

  return (
    <main
      className="cl-main-container"
      style={{
        ...clMainStyle,
      }}
    >
      <div
        className="cl-heading"
        id="CL_HEADING"
        onClick={(e: any) => {
          const { id } = e.target;
          id === "CL_HEADING" && toCollapse(!collapse);
        }}
        style={{
          ...headerStyle,
          cursor: "pointer",
          borderRadius: collapsibleHeadBorderRadius,
        }}
      >
        <div className="cl-header-title-wrapper">
          {!isSemiChecked ? (
            <input
              type="checkbox"
              checked={isChecked}
              disabled={isDraftData}
              onChange={() => {
                console.log(`CLICKED--->${payerId}`);
                isChecked
                  ? removeAllRecForPayer(dispatch, payerId)
                  : selectAllRecForPayer(dispatch, payerId);
                // setIsChecked(!isChecked);
              }}
            />
          ) : (
            <i
              id="minus"
              onClick={() => {
                removeAllRecForPayer(dispatch, payerId);
              }}
              className="fa-solid fa-square-minus"
              style={{
                fontSize: "1.25rem",
                color: "#040D12",
                marginRight: "0.5rem",
              }}
            />
          )}
          <p style={{ paddingBottom: "0.2rem" }}>{header}</p>
        </div>
        <i
          className="fa-solid fa-chevron-down cl-arrow"
          onClick={(e: any) => {
            const { id } = e.target.parentElement;
            id === "CL_HEADING" && toCollapse(!collapse);
          }}
          style={{
            transform: !collapse ? "rotate(-180deg)" : "rotate(0)",
          }}
        />
      </div>
      <div
        ref={bodyContent}
        className="cl-body"
        style={{
          maxHeight: collapse ? "0" : `${bodyContentHeight}px`,
          overflow: overflowVal ? overflowVal : "",
        }}
      >
        {body}
      </div>
    </main>
  );
}
