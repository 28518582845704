import React from "react";
import StateIncome from "./StateIncome/StateIncome";
import StateNumber from "./StateNumber/StateNumber";
import StateTax from "./StateTax/StateTax";
import states_key_val from "../../../../lib/json/states_key_val.json";
import { useSelector } from "react-redux";

const statesKeyVal: any = states_key_val;

export const stateCode = (stateName: string) => {
  return stateName !== "" ? statesKeyVal[stateName] : "";
};

export default function State({ displayTexts, formData }: any) {
  const style = useSelector((state: any) => state.themeChange);
  return (
    <div
      className="rf-state-main"
      style={{
        borderTop: `1px solid ${style.eFormBorderColor}`,
        borderBottom: `1px solid ${style.eFormBorderColor}`,
      }}
    >
      <StateTax
        heading={displayTexts.stateTaxWithheldLabel}
        taxamt1={formData.state_income_tax_withheld1}
        taxAmt2={formData.state_income_tax_withheld2}
        state1={formData.state1}
        state2={formData.state2}
      />
      <StateNumber
        heading={`${displayTexts.stateLabel}/${displayTexts.stateNoLabel}`}
        state1={formData.state1}
        state2={formData.state2}
        stateNo1={formData.payer_state_number1}
        stateNo2={formData.payer_state_number2}
      />
      <StateIncome
        heading={displayTexts.stateIncLabel}
        incAmt1={formData.state_income1}
        incAmt2={formData.state_income2}
        state1={formData.state1}
        state2={formData.state2}
      />
    </div>
  );
}
