import React from "react";
import Amount from "../../AmountContainer/AmountInput/Amount";
import { useSelector } from "react-redux";
import Indicator from "../../../../Indicator";
import { NUMBER_OF_W2_FILED, TOTAL_TAX_WITHHELD_FOR_W2 } from "../../../../../lib/constants";
import { stateFilingErrMsg } from "../../../../../lib/languagePacks/en-us";

interface Props {
  onChangeHandler: (e: any) => void;
  w2sFiledVal: string;
  w2sTaxWithheldVal: string;
}

export default function WestVirginiaExtraFields({
  onChangeHandler,
  w2sFiledVal,
  w2sTaxWithheldVal,
}: Props) {
  const showValidationError = useSelector((state: any) => state.showValidationErr);

  return (
    <div className="flex px-4 py-4">
      <div className="w-[50%] flex flex-col px-4 py-2 border border-black">
        <label htmlFor="W2S-FILED" className="label py-1">
          Total number of W2s' filed
        </label>
        <input
          type="text"
          id="W2S-FILED"
          className="py-2"
          onChange={onChangeHandler}
          value={w2sFiledVal}
        />
        {/* <Amount type="text" id="W2S-FILED" class_="py-2 w-[100%]" /> */}
        {showValidationError.onNumberOfStateFiled && (
          <Indicator
            type={NUMBER_OF_W2_FILED}
            message={stateFilingErrMsg.wvExtraFieldValidationErr}
          />
        )}
      </div>

      <div className="w-[50%] flex flex-col px-4 py-2 border border-black border-l-0">
        <label htmlFor="W2-TAX-WITHHELD" className="label py-1">
          Total tax withheld for all W2s'
        </label>
        {/* <input type="text" id="W2-TAX-WITHHELD" className="py-2" /> */}
        <Amount
          type="text"
          id="W2-TAX-WITHHELD"
          class_="py-2 w-[100%]"
          value={w2sTaxWithheldVal}
          onChangeHandler={onChangeHandler}
        />
        {showValidationError.onTotalTaxForW2 && (
          <Indicator
            type={TOTAL_TAX_WITHHELD_FOR_W2}
            message={stateFilingErrMsg.wvExtraFieldValidationErr}
          />
        )}
      </div>
    </div>
  );
}
