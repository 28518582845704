import React from "react";
import "../../../styles/forms/globalComponentStyles/StateInfo.css";
import { useSelector, useDispatch } from "react-redux";
import StateDetails from "./InternalComponents/StateDetails";
import {
  SET_STATE_AMOUNT,
  TO_CLEAR,
  TO_SHOW_FORM_VALIDATION_ERR,
} from "../../../redux/actions/action.types";
import { useEffect } from "react";
import { STATE1_ALL_DETAILS, STATE2_ALL_DETAILS } from "../../../lib/constants";
import { BsInfoCircleFill } from "react-icons/bs";
import displayInfoModal from "../../../lib/StateSetters/displayInfoModal";
import StateFilingModalBody from "../../decorations/Modal/StateFilingModalBody/StateFilingModalBody";

const STATE_FILING_INFO_ICON = "STATE_FILING_INFO_ICON";

export default function StateInfo({
  state1Heading,
  state2Heading,
  taxWithheldLabel,
  stateVolLabel,
  stateNoLabel,
  stateIncomeLabel,
  globalFormData,
  setGlobalFormData,
  formType,
  isCorrection,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  const clearStatus = useSelector((state: any) => state.clearDataStatus);
  const stateAmount = useSelector((state: any) => state.stateAmount);
  const dispatch = useDispatch();

  // Remove all error indicators initially from state group 1 and group 2
  useEffect(() => {
    dispatch({
      type: TO_SHOW_FORM_VALIDATION_ERR,
      payload: {
        type: STATE1_ALL_DETAILS,
        toShowErr: false,
      },
    });
    dispatch({
      type: TO_SHOW_FORM_VALIDATION_ERR,
      payload: {
        type: STATE2_ALL_DETAILS,
        toShowErr: false,
      },
    });
  }, [dispatch]);

  const handleClearingData = (e: any) => {
    const group = e.target.id;
    if (group === "state1") {
      // Clear group state 1
      // Clear the state amount data
      dispatch({
        type: SET_STATE_AMOUNT,
        payload: {
          ...stateAmount,
          stateTax1: "",
          stateIncome1: "",
        },
      });

      // Update the global data
      setGlobalFormData({
        ...globalFormData,
        state_income_tax_withheld1: 0,
        state_income_1: 0,
        payer_state_number1: "",
        state1: "",
        number_of_w2_filed: 0,
        total_tax_withheld_for_all_w2s: 0,
      });

      // Update the clear status
      dispatch({
        type: TO_CLEAR,
        payload: {
          ...clearStatus,
          group_state1: false,
        },
      });
    } else if (group === "state2") {
      // Clear group state 2
      // Clear the state amount data
      dispatch({
        type: SET_STATE_AMOUNT,
        payload: {
          ...stateAmount,
          stateTax2: "",
          stateIncome2: "",
        },
      });

      // Update the global data
      setGlobalFormData({
        ...globalFormData,
        state_income_tax_withheld2: 0,
        state_income_2: 0,
        payer_state_number2: "",
        state2: "",
        number_of_w2_filed: 0,
        total_tax_withheld_for_all_w2s: 0,
      });
    }
  };

  // Handle click event
  const handleClick = (e: React.MouseEvent<SVGElement | HTMLElement>) => {
    const { id } = e.currentTarget;

    switch (id) {
      case STATE_FILING_INFO_ICON:
        // Display the info modal for state filing
        displayInfoModal(
          true,
          <StateFilingModalBody
            withoutIncomeField={stateIncomeLabel ? false : true}
            stateBoxNo={stateVolLabel.split(". ")[0]}
            stateTaxBoxNo={taxWithheldLabel.split(". ")[0]}
            stateIncomeBoxNo={stateIncomeLabel ? stateIncomeLabel.split(". ")[0] : ""}
            stateIdBoxNo={stateNoLabel.split(". ")[0]}
          />,
          "State Filing Information (For State 1 and State 2)",
          dispatch
        );
        break;

      default:
        break;
    }
  };

  return (
    <div
      className="relative state-info-box"
      style={{
        boxShadow: `2px 2px 10px ${style.eFormBorderColor}`,
      }}
    >
      <div className="state-1">
        <div className="flex items-center gap-6 state-info-heading">
          <h2>{state1Heading}</h2>
          <BsInfoCircleFill
            id={STATE_FILING_INFO_ICON}
            className="cursor-pointer"
            onClick={handleClick}
          />
        </div>
        <StateDetails
          compId={"state1"}
          taxWithheldLabel={taxWithheldLabel}
          stateVolLabel={stateVolLabel}
          stateNoLabel={stateNoLabel}
          stateIncomeLabel={stateIncomeLabel}
          gData={globalFormData}
          setGData={setGlobalFormData}
          formType={formType}
        />

        {globalFormData.state1.length !== 0 && (
          <div className="state-info-clear-btn">
            <button
              onClick={handleClearingData}
              id="state1"
              style={{
                background: style.btnBg,
                color: style.btnFillFontColor,
              }}
            >
              Clear
            </button>
          </div>
        )}
      </div>
      <div className="state-2">
        <div className="state-info-heading state-info-heading-2">
          <h2>{state2Heading}</h2>
        </div>
        <StateDetails
          compId={"state2"}
          taxWithheldLabel={taxWithheldLabel}
          stateVolLabel={stateVolLabel}
          stateNoLabel={stateNoLabel}
          stateIncomeLabel={stateIncomeLabel}
          gData={globalFormData}
          setGData={setGlobalFormData}
          formType={formType}
        />
        {globalFormData.state2.length !== 0 && (
          <div className="state-info-clear-btn">
            <button
              onClick={handleClearingData}
              id="state2"
              style={{
                background: style.btnBg,
                color: style.btnFillFontColor,
              }}
            >
              Clear
            </button>
          </div>
        )}
      </div>
      {isCorrection && <div className="absolute h-[100%] w-[100%] top-0 bg-slate-200 opacity-30" />}
    </div>
  );
}
