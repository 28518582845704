import Handler from "../../Handlers/main";
import scrollToTop from "../../utils/scrollToTop";
import clearForm from "./clearForm";
import handleInputDisplayClearance from "./handleInputDisplayClearance";

const clearAmountField = {
  amount1: "",
  amount2: "",
  amount3: "",
  amount4: "",
  amount5: "",
  amount6: "",
  amount7: "",
  amount8: "",
  amount9: "",
  amountA: "",
  amountB: "",
  amountC: "",
  amountD: "",
  amountE: "",
  amountF: "",
  amountG: "",
  amountH: "",
  amountJ: "",
};

export default function handleFormSubmitAndFillAgain(
  globalFormData: any,
  handleFormClearance: any,
  dispatch: any,
  clearAmountDisplay: any
) {
  // Update the navbar data
  Handler.handleHomeDataProcessing(dispatch);
  // Clear the entire form
  clearForm(globalFormData, handleFormClearance);
  // Clear remaining input display values
  handleInputDisplayClearance(dispatch);
  // Clear amount display values
  clearAmountDisplay(clearAmountField);

  // Scroll page to top
  scrollToTop();
}
