import React from "react";
import { reviewFormLabels } from "../../../../lib/languagePacks/en-us";

export default function RFStreetAddress({ formData }: any) {
  return (
    <div className="rf-child-component">
      <div>
        <h4>{reviewFormLabels.recStreetAddress}</h4>
      </div>
      <div style={{ color: "#30A2FF" }}>
        <p>
          {formData?.recipient_address_line1} {formData?.recipient_address_line2}
        </p>
      </div>
    </div>
  );
}
