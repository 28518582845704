import { SET_FORM_HISTORY_CART_IDS } from "../../actions/action.types";

const formHistoryCartIds = (state: any[] = [], action: any) => {
  const type = action.type;
  if (type === SET_FORM_HISTORY_CART_IDS) {
    const cartId = action.payload.cartid;
    const updateType = action.payload.updateType;
    const initialCartIds = state;

    switch (updateType) {
      case "push":
        if (!initialCartIds.includes(cartId)) {
          const updatedArr = [...initialCartIds, cartId];
          return updatedArr;
        }
        break;

      case "remove":
        if (initialCartIds.includes(cartId)) {
          const filteredCartIds = initialCartIds.filter((_cartId) => {
            return _cartId !== cartId;
          });
          return filteredCartIds;
        }
        break;

      case "reset":
        const resetArr: any[] = [];
        return resetArr;

      default:
        break;
    }
  }
  return state;
};

export default formHistoryCartIds;
