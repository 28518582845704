import React from "react";
import { ImLinkedin } from "react-icons/im";
import { GrYoutube } from "react-icons/gr";

export default function SubFooter() {
  return (
    <main className="bg-[#03001C] text-white opacity-90 px-4 sm:px-80 py-4 flex justify-between items-center gap-3">
      <div id="copyright">Copyright &copy; 2023, TaxEve LLC. All rights reserved</div>
      <div id="socials" className="flex items-center gap-7">
        {/* <div id="linkedin">
          <a href="https://linkedin.com">
            <ImLinkedin className="text-2xl hover:text-blue-600 cursor-pointer transition-transform hover:scale-125" />
          </a>
        </div> */}
        <div id="youtube">
          <a href="https://www.youtube.com/@TaxEve">
            <GrYoutube className="text-3xl hover:text-red-600 cursor-pointer transition-transform hover:scale-125" />
          </a>
        </div>
      </div>
    </main>
  );
}
