import { axiosInstance } from "../axiosConfig";
import { prodLogInstance } from "../loggerConfig";

export default async function getAsciiFile(): Promise<[blobData: Blob, headers: any]> {
  try {
    const { data: blobData, headers } = await axiosInstance.get("/admin/ascii_generate", {
      responseType: "blob",
    });
    return [blobData, headers];
  } catch (err) {
    prodLogInstance.error(err);
    throw err;
  }
}
