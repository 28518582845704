import {
  DISPLAY_POPUP,
  SET_POPUP_MESSAGE,
  SET_POPUP_STATUS,
} from "../../redux/actions/action.types";

export default function removePopup(duration: number, dispatch: any) {
  setTimeout(() => {
    dispatch({
      type: DISPLAY_POPUP,
      payload: false,
    });
    dispatch({
      type: SET_POPUP_STATUS,
      payload: "",
    });
    dispatch({
      type: SET_POPUP_MESSAGE,
      payload: "",
    });
  }, duration);
}
