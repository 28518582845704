import { SET_BULK_ACTION_ITEMS, CLEAR_BULK_ACTION_ITEMS } from "../../actions/action.types";

const bulkActionItems = (state: any[] = [], action: any) => {
  if (action.type === SET_BULK_ACTION_ITEMS) {
    const form = state[action.payload.formIdx];
    if (action.payload.isChecked) {
      if (!form) {
        state.push({
          formType: action.payload.formType,
          data: [
            {
              record: action.payload.checkedRecord,
              checked: action.payload.isChecked,
              idx: action.payload.idx,
            },
          ],
        });
        return [...state];
      } else {
        let dataWithSameIdxExists = false;
        state[action.payload.formIdx].data.forEach((dataObj: any) => {
          if (dataObj.idx === action.payload.idx) dataWithSameIdxExists = true;
        });
        if (!dataWithSameIdxExists) {
          state[action.payload.formIdx].data.push({
            record: action.payload.checkedRecord,
            checked: action.payload.isChecked,
            idx: action.payload.idx,
          });
          state[action.payload.formIdx] = {
            formType: form.formType,
            data: [...state[action.payload.formIdx].data],
          };
          return [...state];
        }
        return state;
      }
    } else {
      if (!form) {
        console.log(action.payload.formType);
        state.push({
          formType: action.payload.formType,
          data: [],
        });
        return [...state];
      } else {
        let dataWithSameIdxExists = false;
        let idxOfItemToUncheck = null;
        state[action.payload.formIdx].data.forEach((dataObj: any, i: number) => {
          if (dataObj.idx === action.payload.idx) {
            dataWithSameIdxExists = true;
            idxOfItemToUncheck = i;
          }
        });
        if (dataWithSameIdxExists && idxOfItemToUncheck !== null) {
          state[action.payload.formIdx].data.splice(idxOfItemToUncheck, 1);
          state[action.payload.formIdx] = {
            formType: form.formType,
            data: [...state[action.payload.formIdx].data],
          };
          return [...state];
        }
        return state;
      }
    }
  } else if (action.type === CLEAR_BULK_ACTION_ITEMS) {
    return action.payload;
  }
  return state;
};

export default bulkActionItems;
