import { SET_POPUP_STATUS } from "../actions/action.types";

const popupStatus = (state = "", action: any) => {
  if (action.type === SET_POPUP_STATUS) {
    return action.payload;
  } else {
    return state;
  }
};

export default popupStatus;
