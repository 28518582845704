// import retrieveToken from "../authRequests/retrieveTokens";
import retrieveToken from "../authRequests/retrieveTokens";
import { axiosInstance } from "../axiosConfig";

const arrangeCartIds = (cartIds: any[]) => {
  const arrangedIds: any[] = [];
  cartIds.forEach((cartId) => {
    arrangedIds.push({ _id: cartId });
  });
  return arrangedIds;
};

export default async function getSelectedNonReviewedData(cartIds: any[], year: number) {
  const dataToSend = arrangeCartIds(cartIds);
  try {
    const { data } = await axiosInstance.post(
      "/get_selected_non_reviewed_forms",
      {
        cart_id_array: dataToSend,
        year,
      },
      {
        headers: {
          "Content-Type": "application/json",
          auth_token: retrieveToken("auth_token"),
        },
      }
    );
    return data;
  } catch (err) {
    throw err;
  }
}
