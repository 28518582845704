import { FormUnreviewHelperArgs } from "../../../../interfaces/main";
import Handler from "../../Handlers/main";
import removeReviewedFormIds from "../../StateSetters/removeReviewedFormIds";
import review from "../Review";
import setNoDefault from "../setNoDefault";

export default async function formUnreviewHelper({
  dispatch,
  cartIds,
  selectedRecordIdx,
}: FormUnreviewHelperArgs) {
  const res = await Handler.handleFormUnreview(cartIds, dispatch);

  removeReviewedFormIds(dispatch, res.successfulIdsArray);

  // Mark the current record as reviewed
  review.markUnreviewedLocally(dispatch, selectedRecordIdx);

  // Use no default
  setNoDefault(dispatch, true);
}
