import { axiosInstance } from "../axiosConfig";

export default async function changeEmail(newEmail: string, verifyCode: string) {
  try {
    const { data } = await axiosInstance.post("/change_email", {
      new_email: newEmail,
      verification_code: verifyCode,
    });
    return data;
  } catch (err) {
    throw err;
  }
}
