import CartValueArray from "../../../lib/utils/CartValues";
import {
  REMOVE_CART_PAGE_SELECTED_RECIPIENTS,
  SET_CART_PAGE_SELECTED_RECIPIENTS,
} from "../../actions/action.types";

const cartPageSelectedRec = (state: any[] = [], action: any) => {
  if (action.type === SET_CART_PAGE_SELECTED_RECIPIENTS) {
    if (!state[action.payload.payerId]) {
      return [
        ...state,
        {
          payerId: action.payload.payerId,
          cartIds: action.payload.cartId === null ? [] : [action.payload.cartId],
        },
      ];
    } else {
      if (action.payload.cartId !== null) {
        state[action.payload.payerId].cartIds.push(action.payload.cartId);
        return [...state];
      }
      return [];
    }
  }
  if (action.type === REMOVE_CART_PAGE_SELECTED_RECIPIENTS) {
    if (state[action.payload.payerId]) {
      const updatedCartIdsArrray = CartValueArray.remove(
        state[action.payload.payerId].cartIds,
        action.payload.cartIdToRemove,
        action.payload.occurence
      );
      state[action.payload.payerId].cartIds = updatedCartIdsArrray;
      return [...state];
    }
    return [...state];
  }
  return state;
};

export default cartPageSelectedRec;
