import React from "react";
import Hamicon from "../Hamicon";
import { useHam } from "../../../../lib/customHooks";
import { POST_LOGIN_HAM } from "../../../../lib/constants";

export default function PostLoginHam() {
  useHam(POST_LOGIN_HAM);
  return (
    <div className="xl:hidden flex items-center">
      <Hamicon />
    </div>
  );
}
