import React from "react";
import Select from "react-select";
import SelectStyle from "../../../lib/ReactSelectTheme/reactSelectStyle";
import { useSelector, useDispatch } from "react-redux";
import ReactSelectOptions from "../../../lib/utils/ReactSelectOptions";
import devLogInstance from "../../../lib/loggerConfig";
import {
  CP_BULK_DELETE,
  CP_BULK_DISABLE_ONLINE_ACCESS,
  CP_BULK_DISABLE_POSTAL_MAILING,
  CP_BULK_DISABLE_STATE_FILING,
  CP_BULK_DOWNLOAD,
  CP_BULK_ENABLE_ONLINE_ACCESS,
  CP_BULK_ENABLE_POSTAL_MAILING,
  CP_BULK_ENABLE_STATE_FILING,
} from "../../../lib/constants";
import {
  BulkAction,
  BulkDisableOnlineAccess,
  BulkDisablePostalMailing,
  BulkEnableOnlineAccess,
  BulkEnablePostalMailing,
  BulkEnableStateFiling,
} from "../../../lib/utils/BulkAction";
import displayConfirmationModal from "../../../lib/StateSetters/displayConfirmationModal";
import { DELETE_ACTION_BULK, DISABLE_STATE_FILING_BULK } from "../../../lib/UserActions/action";

export default function BulkUpdate({ totalSelectedCartIds }: any) {
  const style = useSelector((state: any) => state.themeChange);
  const recsWithoutEmailId = useSelector((state: any) => state.recipientsWithoutEmailId);
  const totalCartValues = useSelector((state: any) => state.totalCartValues);
  const stateSetter = useDispatch();
  // const stateSetter = useDispatch();
  // Handle bulk action selection
  const handleBulkActionSelection = (option: any) => {
    devLogInstance.log(option);
    // BulkAction.totalSelectedCartIds = totalSelectedCartIds;
    // BulkAction.stateSetter = stateSetter;
    // const bulkAction = new BulkAction(totalSelectedCartIds);
    BulkAction.cartIds = BulkAction.getCartIds(totalSelectedCartIds);
    const bulkActionType = option.value;
    if (bulkActionType === CP_BULK_ENABLE_POSTAL_MAILING) {
      new BulkEnablePostalMailing();
    } else if (bulkActionType === CP_BULK_DISABLE_POSTAL_MAILING) {
      new BulkDisablePostalMailing();
    } else if (bulkActionType === CP_BULK_ENABLE_STATE_FILING) {
      new BulkEnableStateFiling();
    } else if (bulkActionType === CP_BULK_DISABLE_STATE_FILING) {
      // new BulkDisableStateFiling();
      // Display the confirmation modal
      displayConfirmationModal(stateSetter, DISABLE_STATE_FILING_BULK, true);
    } else if (bulkActionType === CP_BULK_ENABLE_ONLINE_ACCESS) {
      new BulkEnableOnlineAccess(totalSelectedCartIds, recsWithoutEmailId);
    } else if (bulkActionType === CP_BULK_DISABLE_ONLINE_ACCESS) {
      new BulkDisableOnlineAccess();
    } else if (bulkActionType === CP_BULK_DOWNLOAD) {
      BulkAction.formDownload();
    } else if (bulkActionType === CP_BULK_DELETE) {
      // Take confirmation from user
      // Display the confirmation modal for delete action
      displayConfirmationModal(stateSetter, DELETE_ACTION_BULK, true);
    }
  };

  return (
    <main style={{ width: "20%" }}>
      <Select
        isSearchable={false}
        options={ReactSelectOptions.cartPageBulkUpdateOptions(totalCartValues)}
        theme={new SelectStyle(style).customTheme}
        styles={new SelectStyle(style).customStyle}
        value="Bulk Update"
        placeholder="Bulk Update"
        onChange={handleBulkActionSelection}
      />
    </main>
  );
}
