import React from "react";
import { GoDotFill } from "react-icons/go";
import { info } from "../../../../lib/languagePacks/en-us";

interface Props {
  localTaxBoxNo: string;
  localBoxNo: string;
  localIncomeBoxNo?: string;
}

export default function LocalFilingModalBody(props: Props) {
  return (
    <div className="px-4 py-4">
      <ul className="flex flex-col gap-6 text-lg">
        <li className="flex items-center gap-2">
          <GoDotFill />
          <p>
            {info.localFiling.income}{" "}
            <span className="font-bold">Box {props.localIncomeBoxNo}.</span>
          </p>
        </li>
        <li>
          <div className="flex items-center gap-2">
            <GoDotFill />
            <p>
              {info.localFiling.locality} <span className="font-bold">Box {props.localBoxNo}.</span>
            </p>
          </div>
        </li>
        <li className="flex items-center gap-2">
          <GoDotFill />
          <p>
            {info.localFiling.tax} <span className="font-bold">Box {props.localTaxBoxNo}.</span>
          </p>
        </li>
      </ul>
    </div>
  );
}
