import { TO_SHOW_FA_WARNING } from "../../actions/action.types";

const initialState = {
  onPayerCity: false,
  onPayerState: false,
  onPayerZipcode: false,

  // Recipient address block
  onRecipientCity: false,
  onRecipientState: false,
  onRecipientZipccode: false,
};

const showFAWarning = (state = initialState, action: any) => {
  if (action.type === TO_SHOW_FA_WARNING) return action.payload;
  else return state;
};

export default showFAWarning;
