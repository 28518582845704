import { axiosInstance } from "../../axiosConfig";

export default async function submitCorrectedData(correctedData: any) {
  try {
    const { data } = await axiosInstance.post("/submit_corrected_form", correctedData);
    return data;
  } catch (err) {
    throw err;
  }
}
