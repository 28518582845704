import { axiosInstance } from "../axiosConfig";
import { XLSX } from "../constants";
import devLogInstance from "../loggerConfig";

export default async function uploadFile(
  file: File,
  format: string,
  payerId: string,
  formType: string,
  year: number
) {
  const templFormat = format === XLSX ? "excel" : "csv";
  devLogInstance.log(`Form type upper case ---> ${formType}`);
  try {
    const { data } = await axiosInstance.post(
      `/submit_excel_csv/${formType}/${templFormat}/${payerId}/${year}`,
      {
        file: file,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  } catch (err) {
    throw err;
  }
}

export async function uploadBusinessTemplate(file: File, format: string) {
  const templFormat = format === XLSX ? "excel" : "csv";
  try {
    const { data } = await axiosInstance.post(
      `/submit_excel_csv_payer/${templFormat}`,
      {
        file: file,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  } catch (err) {
    throw err;
  }
}

export async function uploadRecipientTemplate(file: File, format: string) {
  const templFormat = format === XLSX ? "excel" : "csv";
  try {
    const { data } = await axiosInstance.post(
      `/submit_excel_csv_recipient/${templFormat}`,
      {
        file: file,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  } catch (err) {
    throw err;
  }
}
