import { SET_CART_PAGE_SELECTED_RECIPIENTS } from "../../redux/actions/action.types";

export default function setCartPageSelectedRec(stateHandler: any, cartId: any, payerId: any) {
  stateHandler({
    type: SET_CART_PAGE_SELECTED_RECIPIENTS,
    payload: {
      cartId: cartId,
      payerId: payerId,
    },
  });
}
