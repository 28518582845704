import { axiosInstance } from "../../axiosConfig";
import devLogInstance, { prodLogInstance } from "../../loggerConfig";

export default async function submitConsentData(imgFile: File, consent: boolean) {
  try {
    const { data } = await axiosInstance.post(
      "/submit_consent_page_data",
      {
        esign: imgFile,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          consent: `${consent}`,
        },
      }
    );
    devLogInstance.log("Response after submitting the consent page data");
    devLogInstance.log(data);
  } catch (err) {
    prodLogInstance.error(err);
    throw err;
  }
}
