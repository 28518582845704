import { Dispatch } from "@reduxjs/toolkit";
import { ViewGroupsModalAction } from "../../../redux/reducers/Modal/displayGroupViewingModal";
import { TO_DISPLAY_GROUP_VIEWING_MODAL } from "../../../redux/actions/action.types";

export default function displayViewGroupsModal(
  toDisplay: boolean,
  staffId: string,
  dispatch: Dispatch<ViewGroupsModalAction>
) {
  dispatch({
    type: TO_DISPLAY_GROUP_VIEWING_MODAL,
    payload: {
      toDisplay,
      staffId,
    },
  });
}
