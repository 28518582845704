import React from "react";
import "../../../../styles/forms/Form.css";

export default function AmountContainer({ children, class_, id, style }: any) {
  return (
    <div className={`amount-container ${class_}`} id={id ? id : ""} style={style ? style : {}}>
      {children}
    </div>
  );
}
