import React from "react";
import { useSelector } from "react-redux";
import AmountContainer from "../AmountContainer/AmountContainer";

export default function Box10And11({ box10, box11 }: any) {
  const style = useSelector((state: any) => state.themeChange);
  return (
    <div
      className="payment-amounts-container"
      style={{
        borderRight: `1px solid ${style.eFormBorderColor}`,
        width: "75%",
      }}
    >
      <AmountContainer
        class_="misc-info" // Only one class can be passed
        style={{ borderRight: `1px solid black` }}
      >
        {box10}
      </AmountContainer>
      <AmountContainer
        class_="misc-info" // Only one class can be passed
      >
        {box11}
      </AmountContainer>
    </div>
  );
}
