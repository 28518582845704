import React from "react";
import { useSelector } from "react-redux";
import "../styles/FormModal.css";
import { RxCrossCircled } from "react-icons/rx";
import CreateFormComponent from "./CreateFormComponent";
import { useEffect } from "react";
import scrollToTop from "../lib/utils/scrollToTop";

export default function FormCreationModal({ openModal, formType, createForm, toCreateForm }: any) {
  const style = useSelector((state: any) => state.themeChange);

  useEffect(() => {
    const preventScroll = () => {
      scrollToTop();
    };
    window.addEventListener("scroll", preventScroll);
    return () => {
      window.removeEventListener("scroll", preventScroll);
    };
  }, []);

  // Handle modal closure when clicked outside
  const handleModalClosure = (e: any) => {
    const { id } = e.target;
    id === "modal-bg" && openModal(false);
  };
  return (
    <div className="modal-main-container px-4" id="modal-bg" onClick={handleModalClosure}>
      <div
        className="modal w-[100%] sm:w-[70vw] overflow-auto"
        style={{ background: style.siteBg, height: "80%" }}
      >
        <div className="modal-top-panel" style={{ background: style.modalTopPanelBg }}>
          <div className="panel-heading">
            <h3 style={{ color: style.modalTopPanelFontColor }} className="text-xl font-bold">
              {`${formType.title}`}
            </h3>
          </div>
          <RxCrossCircled
            className="modal-close-btn"
            onClick={() => {
              openModal(false);
            }}
          />
        </div>
        <CreateFormComponent formType={formType} toCreateForm={toCreateForm} />
      </div>
    </div>
  );
}
