import { SET_RECIPIENTS_WITHOUT_STATE_FILING_FOR_CURRENT_PAYER } from "../../redux/actions/action.types";

export default function setRecsWithoutStateFiling(
  stateSetter: any,
  noStateFiling: any,
  payerId: any
) {
  stateSetter({
    type: SET_RECIPIENTS_WITHOUT_STATE_FILING_FOR_CURRENT_PAYER,
    payload: {
      noStateFiling: noStateFiling,
      payerId: payerId,
    },
  });
}
