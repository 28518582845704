import devLogInstance from "../../../lib/loggerConfig";
import { REMOVE_REVIEWED_FORM_IDS, SET_REVIEWED_FORM_IDS } from "../../actions/action.types";

const reviewedFormIds = (state: any[] = [], action: any) => {
  const type = action.type;
  if (type === SET_REVIEWED_FORM_IDS) {
    const successfulIdsArray = action.payload;
    devLogInstance.log(typeof successfulIdsArray);
    const reviewedIds = successfulIdsArray.filter((id: any) => {
      return !state.includes(id);
    });
    return successfulIdsArray.length !== 0 ? [...state, ...reviewedIds] : [];
  } else if (type === REMOVE_REVIEWED_FORM_IDS) {
    const reviewedIdsToRemove = action.payload;
    const updatedState = state.filter((id) => {
      return !reviewedIdsToRemove.includes(id);
    });
    return [...updatedState];
  }
  return state;
};

export default reviewedFormIds;
