import { axiosInstance } from "../axiosConfig";

export default async function updateonlineAccessEmails(dataArray: any[]) {
  try {
    const { data } = await axiosInstance.post("/update_online_access_email", {
      emails_for_online_access: dataArray,
    });
    return data;
  } catch (err) {
    throw err;
  }
}
