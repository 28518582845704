import React from "react";
import { useState } from "react";
import PasswordInput from "../Password/PasswordInput";
import { useDispatch, useSelector } from "react-redux";
import "./ChangePhonenumber.css";
import Handler from "../../../lib/Handlers/main";
import { SHA256 } from "crypto-js";
import { prodLogInstance } from "../../../lib/loggerConfig";

export default function ChangePhonenumber() {
  const [inputVals, setInputVals] = useState({
    password: "",
    phoneNumber: "",
  });
  const style = useSelector((state: any) => state.themeChange);
  const stateSetter = useDispatch();

  const handleChange = (e: any) => {
    const { id: inputType, value } = e.target;
    switch (inputType) {
      case "new-phonenumber":
        setInputVals({
          ...inputVals,
          phoneNumber: value,
        });
        break;

      case "pass":
        setInputVals({
          ...inputVals,
          password: value,
        });
        break;

      default:
        break;
    }
  };

  const handlePhonenumberUpdate = async (e: any) => {
    e.preventDefault();
    try {
      const hashedPass = SHA256(process.env.REACT_APP_NONCE + inputVals.password).toString();
      await Handler.handlePhonenumberChange(hashedPass, inputVals.phoneNumber, stateSetter);
    } catch (err) {
      prodLogInstance.error(err);
    }
  };

  return (
    <main className="phone-setting-main">
      <form className="phone-setting-left-portion" onSubmit={handlePhonenumberUpdate}>
        <PasswordInput password={inputVals.password} setPassword={handleChange} />
        <div className="new-phonenumber-container">
          <div className="new-phonenumber-wrapper">
            <label htmlFor="new-phonenumber">
              <h3>Phone Number</h3>
            </label>
            <p>Enter your new phone number that you want to update</p>
            <input
              className="new-phonenumber-input"
              type="number"
              id="new-phonenumber"
              value={inputVals.phoneNumber}
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <button
            type="submit"
            className="phonenumber-change-btn"
            style={{ background: style.btnBg, color: style.btnFillFontColor }}
          >
            Change Phone Number
          </button>
        </div>
      </form>
    </main>
  );
}
