import React from "react";
import Navbar from "../components/navbar";
import { useState, useEffect } from "react";
import HomeAllFormsContainer from "../components/HomeAllFormsContainer";
import FormCreationModal from "../components/FormCreationModal";
import { useDispatch, useSelector } from "react-redux";
import loader from "../components/decorations/Loader";
import devLogInstance from "../lib/loggerConfig";
import { HOME } from "../lib/routes";
import toDisplayModal from "../lib/StateSetters/toDisplayModal";
// import AdBanner from "../components/decorations/AdBanner";
// import { homePageDeadlineAdText } from "../lib/languagePacks/en-us";
// import Handler from "../lib/Handlers/main";

export default function Home({ showFeedbackWindow: feedbackWindowObj }: any) {
  const [historyData, setHistoryData] = useState([]);
  const [displayModal, toDisplayModal_] = useState(false);
  const [createForm, toCreateForm] = useState(false);
  const [formType, setFormType] = useState({
    title: "",
    description: "",
    filingDueDate: "",
  });
  const startLoader = useSelector((state: any) => state.startLoader);
  const style = useSelector((state: any) => state.themeChange);
  const dispatch = useDispatch();

  // Log the show feedback window status
  devLogInstance.log(feedbackWindowObj);

  // Remove all locally stored cart ids
  useEffect(() => {
    localStorage.removeItem("cart_ids");
  }, []);

  // if the modal is closed remove create form component as well
  useEffect(() => {
    !displayModal && toCreateForm(false);
  }, [displayModal]);

  // Hide the global modal component on page destroy
  useEffect(() => {
    return () => {
      toDisplayModal(false, "", dispatch);
    };
  }, [dispatch]);

  return !startLoader ? (
    <>
      <div style={{ position: "relative" }}>
        {displayModal && (
          <FormCreationModal
            openModal={toDisplayModal_}
            formType={formType}
            createForm={createForm}
            toCreateForm={toCreateForm}
          />
        )}
        <Navbar page={HOME} />
        {/* <AdBanner adtext={homePageDeadlineAdText} /> */}
        {historyData.length === 0 ? (
          <HomeAllFormsContainer
            isAdBannerPresent={false}
            openModal={toDisplayModal_}
            setFormType={setFormType}
          />
        ) : (
          <div>history</div>
        )}
      </div>
    </>
  ) : (
    loader.start(style)
  );
}
