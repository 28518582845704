import decrypt from "../AESHashing/decrypt";

export default function getUserDetailsLocally() {
  const encryptedUserDetails = localStorage.getItem("user_details");
  let decryptedUserDetails = { name: "", full_name: "", forms_filed: 0, showFeedbackWindow: null };
  if (encryptedUserDetails) {
    decryptedUserDetails = encryptedUserDetails ? JSON.parse(decrypt(encryptedUserDetails)) : "";
  }
  return decryptedUserDetails;
}
