export default function getFormattedDate(date?: string | number | Date): string {
  if (!date) {
    const year = new Date().getFullYear().toString();
    const month = (new Date().getMonth() + 1).toString();
    const day = new Date().getDate().toString();

    return day + "/" + month + "/" + year;
  }
  const year = new Date(date).getFullYear().toString();
  const month = (new Date(date).getMonth() + 1).toString();
  const day = new Date(date).getDate().toString();

  return day + "/" + month + "/" + year;
}
