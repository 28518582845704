import { IsRecipientAuthenticatedAction } from "../../../../interfaces/main";
import { SET_IS_RECIPIENT_AUTHENTICATED } from "../../actions/action.types";

const isRecipientAuthenticated = (
  state: boolean | null = null,
  action: IsRecipientAuthenticatedAction
) => {
  const { type, payload } = action;
  if (type === SET_IS_RECIPIENT_AUTHENTICATED) return payload;
  return state;
};

export default isRecipientAuthenticated;
