import { TO_SHOW_INVALID_TIN_ERROR } from "../../actions/action.types";

const initialState = {
  type: "",
  toShowErr: false,
};

const showInvalidTinError = (
  state = initialState,
  action: { type: string; payload: { type: string; toShowErr: boolean } }
) => {
  const { type, payload } = action;

  if (type === TO_SHOW_INVALID_TIN_ERROR) {
    return payload;
  }
  return state;
};

export default showInvalidTinError;
