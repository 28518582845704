import { SET_PAYER_TIN_DISPLAY, SET_RECIPIENT_TIN_DISPLAY } from "../actions/action.types";

export const payerTinDisplay = (state = "", action: any) => {
  if (action.type === SET_PAYER_TIN_DISPLAY) return action.payload;
  else return state;
};

export const recTinDisplay = (state = "", action: any) => {
  if (action.type === SET_RECIPIENT_TIN_DISPLAY) return action.payload;
  else return state;
};
