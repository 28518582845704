import React from "react";
import FilerType from "./FilerType/FilerType";
import PaymentType from "./PaymentType/PaymentType";
import "./FilerPayment.css";
import { useSelector } from "react-redux";

export default function FilerPayment({
  handleChange,
  paymentTypeIndicator,
  filerTypeIndicator,
  paymentTypeRequired,
  filerTypeRequired,
  inReviewForm = false,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  return (
    <div
      className="filer-payment-container"
      style={{
        border: !inReviewForm ? `1px solid ${style.eFormBorderColor}` : "",
        borderTop: "none",
        borderBottom: `1px solid ${style.eFormBorderColor}`,
      }}
    >
      <FilerType
        handleChange={handleChange}
        filerTypeIndicator={filerTypeIndicator}
        required={filerTypeRequired}
      />
      <PaymentType
        handleChange={handleChange}
        paymentTypeIndicator={paymentTypeIndicator}
        required={paymentTypeRequired}
      />
    </div>
  );
}
