import { OnlineAccessLoginData } from "../../../../interfaces/main";
import { axiosInstance } from "../../axiosConfig";
import { prodLogInstance } from "../../loggerConfig";

export default async function recipientLogin(
  loginData: OnlineAccessLoginData,
  formId: string,
  oaKey: string
) {
  try {
    const { data } = await axiosInstance.post(`/online_access/${formId}/${oaKey}`, loginData);
    return data;
  } catch (err) {
    prodLogInstance.error(err);
    throw err;
  }
}
