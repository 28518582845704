import { ONLINE_ACCESS, POSTAL_MAILING, STATE_FILING } from "../../../lib/constants";
import devLogInstance from "../../../lib/loggerConfig";
// import CartValueArray from "../../../lib/utils/CartValues";
import {
  REMOVE_TRUTHY_CART_VALUES,
  REVERT_TO_DEFAULT_TRUTHY_CART_VALUES,
  SET_TRUTHY_CART_VALUES,
} from "../../actions/action.types";

const initialState = {
  onlineAccess: [],
  stateFiling: [],
  postalMailing: [],
};

interface TruthyCartValsState {
  onlineAccess: any[];
  stateFiling: any[];
  postalMailing: any[];
}

const truthyCartValues = (state: TruthyCartValsState = initialState, action: any) => {
  if (action.type === SET_TRUTHY_CART_VALUES) {
    if (action.payload.cartValType === POSTAL_MAILING) {
      if (!state.postalMailing[action.payload.payerId]) {
        state.postalMailing.push({
          payerId: action.payload.payerId,
          data: action.payload.cartId !== null ? [action.payload.cartId] : [],
        });
        devLogInstance.log(state);
        return { ...state };
      } else {
        devLogInstance.log(state);
        action.payload.cartId !== null &&
          !state.postalMailing[action.payload.payerId].data.includes(action.payload.cartId) &&
          state.postalMailing[action.payload.payerId].data.push(action.payload.cartId);
        return { ...state };
      }
    }
    if (action.payload.cartValType === ONLINE_ACCESS) {
      if (!state.onlineAccess[action.payload.payerId]) {
        state.onlineAccess.push({
          payerId: action.payload.payerId,
          data: action.payload.cartId !== null ? [action.payload.cartId] : [],
        });
        devLogInstance.log(state);
        return { ...state };
      } else {
        action.payload.cartId !== null &&
          !state.onlineAccess[action.payload.payerId].data.includes(action.payload.cartId) &&
          state.onlineAccess[action.payload.payerId].data.push(action.payload.cartId);
        return { ...state };
      }
    }
    if (action.payload.cartValType === STATE_FILING) {
      if (!state.stateFiling[action.payload.payerId]) {
        state.stateFiling.push({
          payerId: action.payload.payerId,
          data:
            action.payload.cartId !== null
              ? action.payload.occurence === 1
                ? [action.payload.cartId]
                : [action.payload.cartId, action.payload.cartId]
              : [],
        });
        devLogInstance.log(state);
        return { ...state };
      } else {
        if (action.payload.occurence === 1) {
          action.payload.cartId !== null &&
            !state.stateFiling[action.payload.payerId].data.includes(action.payload.cartId) &&
            state.stateFiling[action.payload.payerId].data.push(action.payload.cartId);
          return { ...state };
        } else {
          if (
            action.payload.cartId !== null &&
            !state.stateFiling[action.payload.payerId].data.includes(action.payload.cartId)
          ) {
            state.stateFiling[action.payload.payerId].data.push(action.payload.cartId);
            state.stateFiling[action.payload.payerId].data.push(action.payload.cartId);
          }
          return { ...state };
        }
      }
    }
    return state;
  }
  if (action.type === REMOVE_TRUTHY_CART_VALUES) {
    if (action.payload.cartValType === POSTAL_MAILING) {
      if (state.postalMailing[action.payload.payerId]) {
        const updatedArray = state.postalMailing[action.payload.payerId].data.filter(
          (cartId: any) => {
            return cartId !== action.payload.cartId;
          }
        );
        devLogInstance.log("UPDATED ARRAY AFTER REMOVAL:");
        devLogInstance.log(updatedArray);
        state.postalMailing[action.payload.payerId].data = updatedArray;
        devLogInstance.log(state);
        return { ...state };
      }
      return state;
    }
    if (action.payload.cartValType === ONLINE_ACCESS) {
      if (state.onlineAccess[action.payload.payerId]) {
        const updatedArray = state.onlineAccess[action.payload.payerId].data.filter(
          (cartId: any) => {
            return cartId !== action.payload.cartId;
          }
        );
        devLogInstance.log("UPDATED ARRAY AFTER REMOVAL:");
        devLogInstance.log(updatedArray);
        state.onlineAccess[action.payload.payerId].data = updatedArray;
        devLogInstance.log(state);
        return { ...state };
      }
      return state;
    }
    if (action.payload.cartValType === STATE_FILING) {
      if (state.stateFiling[action.payload.payerId]) {
        // const updatedArray = CartValueArray.remove(
        //   state.stateFiling[action.payload.payerId].data,
        //   action.payload.cartId,
        //   action.payload.occurence
        // );
        const updatedArray = state.stateFiling[action.payload.payerId].data.filter(
          (cartId: any) => {
            return cartId !== action.payload.cartId;
          }
        );
        devLogInstance.log("UPDATED ARRAY AFTER REMOVAL:");
        devLogInstance.log(updatedArray);
        state.stateFiling[action.payload.payerId].data = updatedArray;
        devLogInstance.log(state);
        return { ...state };
      }
      return state;
    }
    return state;
  }

  if (action.type === REVERT_TO_DEFAULT_TRUTHY_CART_VALUES) {
    return {
      onlineAccess: [],
      stateFiling: [],
      postalMailing: [],
    };
  }
  return state;
};

export default truthyCartValues;
