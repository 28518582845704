import { SET_TOTAL_CART_ITEMS } from "../../redux/actions/action.types";

export default function setTotalCartItems(totalCartItemSetter: any, totalCartItems: number) {
  if (totalCartItems >= 100) {
    const totalCartItemsStr = "99+";
    totalCartItemSetter({
      type: SET_TOTAL_CART_ITEMS,
      payload: totalCartItemsStr,
    });
  } else {
    totalCartItemSetter({
      type: SET_TOTAL_CART_ITEMS,
      payload: totalCartItems.toString(),
    });
  }
}
