import React from "react";
import "./PseDetails.css";
import { pseDetails } from "../../../lib/languagePacks/en-us";
import { useSelector } from "react-redux";
import { PSE_NAME, PSE_NAME_CONT, PSE_PHONE } from "../../../lib/constants";

export default function PseDetails({
  handleChange,
  psename,
  psenamecont,
  psephone,
  inReviewForm = false,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  return (
    <div
      className="pse-details-container"
      style={{
        border: !inReviewForm ? `1px solid ${style.eFormBorderColor}` : "",
        borderTop: "none",
        borderBottom: `1px solid ${style.eFormBorderColor}`,
        height: inReviewForm ? "20%" : "",
      }}
    >
      <div className="pse-details-heading">{pseDetails.heading}</div>
      {!inReviewForm ? (
        <div className="pse-details-wrapper">
          <div className="pse-name-wrapper">
            <label htmlFor={PSE_NAME} className="pse-name-label">
              {pseDetails.details.name}
            </label>
            <input
              id={PSE_NAME}
              type="text"
              style={{ width: "100%" }}
              value={psename}
              onChange={handleChange}
            />
          </div>
          <div className="pse-name-cont-wrapper">
            <label htmlFor={PSE_NAME_CONT} className="pse-name-cont-label">
              {pseDetails.details.nameContd}
            </label>
            <input
              id={PSE_NAME_CONT}
              type="text"
              style={{ width: "100%" }}
              value={psenamecont}
              onChange={handleChange}
            />
          </div>
          <div className="pse-phone-wrapper">
            <label htmlFor={PSE_PHONE} className="pse-phone-label">
              {pseDetails.details.phone}
            </label>
            <input
              id={PSE_PHONE}
              type="text"
              style={{ width: "100%" }}
              value={psephone}
              onChange={handleChange}
            />
          </div>
        </div>
      ) : (
        <div className="px-4 text-blue-400">
          {psename} {psenamecont} {psephone}
        </div>
      )}
    </div>
  );
}
