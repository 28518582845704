import { Dispatch } from "@reduxjs/toolkit";
import { TO_DISPLAY_HAM_MENU } from "../../../redux/actions/action.types";

const toDisplayHammenu = (dispatch: Dispatch<any>, toDisplay: boolean, menutype: string) => {
  dispatch({
    type: TO_DISPLAY_HAM_MENU,
    payload: {
      toDisplay,
      menutype,
    },
  });
};

export default toDisplayHammenu;
