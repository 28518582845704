import React from "react";
import "../../../../../styles/forms/Form.css";
import { TbPercentage } from "react-icons/tb";

export default function PercentageAmount({
  type,
  id,
  placeHolder,
  class_,
  value,
  onChangeHandler,
}: any) {
  return (
    <div className="amount-input-container">
      <div
        className="dollar-sign"
        style={{ right: 0, fontSize: "1.25rem", padding: "1rem 0.85rem", paddingLeft: "0.5rem" }}
      >
        <TbPercentage />
      </div>
      <input
        type={type}
        id={id}
        placeholder={placeHolder}
        className={`amount-input ${class_}`}
        value={value}
        onChange={onChangeHandler}
        autoComplete="off"
        style={{ textAlign: "left" }}
      />
    </div>
  );
}
