import "../styles/Navbar.css";
import React from "react";
import LoginRegister from "./LoginRegister";
import ContactCreds from "./ContactCreds";
import AccountDetails from "./AccountDetails/AccountDetails";
import { Link, useLocation } from "react-router-dom";
import { LANDING_PAGE, LOGIN_PAGE, REGISTER_PAGE } from "../lib/constants";
import { useSelector } from "react-redux";
import Cart from "./Cart/Cart";
import ReviewPending from "./ReviewPending/ReviewPending";
import devLogInstance from "../lib/loggerConfig";
import { useEffect, useState } from "react";
import HistoryNav from "./decorations/HistoryNav/HistoryNav";
import NavLinks from "./NavLinks/NavLinks";
import {
  ADMIN,
  BUSINESS_SETTINGS,
  CONTACT_US_PAGE,
  ENQUIRY,
  HOME,
  ONLINE_ACCESS_DASHBOARD,
  PASSWORD_RESET,
  PRICING_PAGE,
  PRIVACY_POLICY,
  TEMPLATES_DOWNLOAD,
  TERMS_AND_CONDITION,
  WHY_CHOOSE_US_PAGE,
  _1099_FORMS_PAGE,
} from "../lib/routes";
import LogoutRecipient from "./OAPRelatedComponents/LogoutRecipient";
import LandingHam from "./decorations/HamburgerMenu/LandingHam/LandingHam";
import PostLoginHam from "./decorations/HamburgerMenu/PostLoginHam/PostLoginHam";
import { LANDING_PAGE as LANDING } from "../lib/routes";
import { RiUserSettingsFill } from "react-icons/ri";
import { useInProgress } from "../lib/customHooks";

const pagesToIncludeNavAndRegisterBtns = [
  LANDING_PAGE,
  PRICING_PAGE,
  CONTACT_US_PAGE,
  _1099_FORMS_PAGE,
  WHY_CHOOSE_US_PAGE,
  TERMS_AND_CONDITION,
  PRIVACY_POLICY,
  ENQUIRY,
];

const pagesToShowContactCreds = [LOGIN_PAGE, REGISTER_PAGE, PASSWORD_RESET];

const otherPortals = [ONLINE_ACCESS_DASHBOARD, ADMIN];

export default function Navbar({ page }: any) {
  const style = useSelector((state: any) => state.themeChange);
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState("");
  const { inProgress: progress } = useInProgress();

  // Set the current path
  useEffect(() => {
    devLogInstance.log(`Current route ---> ${location.pathname}`);
    setCurrentRoute(location.pathname);

    return () => {
      setCurrentRoute("");
    };
  }, [location]);

  return (
    <div
      className="navbar-container py-4 px-6 sm:px-20 z-20"
      style={{
        background: style.siteBg,
      }}
    >
      <div className="flex items-center logo">
        <h1 className="text-4xl font-bold">
          <Link
            to={
              pagesToIncludeNavAndRegisterBtns.includes(page) ||
              pagesToShowContactCreds.includes(page)
                ? LANDING
                : HOME
            }
          >
            <img src="/Assets/taxeve_logo.png" alt="TaxEve" className="w-16 sm:w-20" />
          </Link>
        </h1>
      </div>
      {!otherPortals.includes(currentRoute) &&
        (pagesToIncludeNavAndRegisterBtns.includes(page) ? (
          <>
            <div className="hidden xl:flex items-center gap-8">
              <NavLinks onLandingpage={page === LANDING_PAGE} currentpage={page} />
              <LoginRegister />
            </div>
            <LandingHam />
          </>
        ) : !pagesToShowContactCreds.includes(page) ? (
          <>
            <div className="account-related-component-container hidden xl:flex xl:justify-end xl:w-[60%] 3xl:w-[25%]">
              <Link to={TEMPLATES_DOWNLOAD} className="font-bold w-[8%]">
                <img
                  src="/Assets/template_down_icon.png"
                  alt="Template download"
                  className="w-[100%]"
                />
              </Link>
              <HistoryNav currentRoute={currentRoute} />
              <ReviewPending />
              <div className="relative">
                <Link id="business-settings-icon" to={BUSINESS_SETTINGS}>
                  <RiUserSettingsFill
                    className={`text-4xl cursor-pointer ${progress.inProgress && "opacity-40"}`}
                  />
                </Link>
                <div
                  className={`absolute top-0 h-full w-full ${!progress.inProgress && "hidden"}`}
                />
              </div>
              <Cart />
              <AccountDetails />
            </div>
            <PostLoginHam />
          </>
        ) : (
          <>
            <ContactCreds />
            <LandingHam />
          </>
        ))}
      {currentRoute === ONLINE_ACCESS_DASHBOARD && <LogoutRecipient />}
    </div>
  );
}
