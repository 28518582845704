import React from "react";
import { info } from "../../../../lib/languagePacks/en-us";

export default function OnlineAccessModalBody() {
  return (
    <div className="px-4 py-11 w-[45rem]">
      <p className="text-lg">{info.onlineAccess}</p>
    </div>
  );
}
