// import { BsNutFill } from "react-icons/bs";
// import DisableScreen from "./disableScreen";
// import Handler from "../Handlers/main";
import Handler from "../Handlers/main";
import { STATE_FILING } from "../constants";
import devLogInstance from "../loggerConfig";
import { Action } from "./Action";
import { StateFiling } from "./CartValues";

export class SingleAction extends Action {
  static getCartIds(cartId: any) {
    const formattedIds = [{ _id: cartId }];
    return formattedIds;
  }

  disableCartValue(e: any) {
    Handler.handleCartCheckboxUpdate(
      e,
      null,
      false,
      StateFiling.filteredCartIdsForStatefiling,
      StateFiling.currentpayerId,
      SingleAction.stateSetter,
      { state1: StateFiling.state1, state2: StateFiling.state2 }
    ).then(() => {
      devLogInstance.log("STATE FILING DISABLED");
    });
  }
}

class StateFilingSingleAction extends SingleAction {
  e = {
    target: {
      parentElement: {
        id: STATE_FILING,
      },
    },
  };
}

export class DisableStateFiling extends StateFilingSingleAction {
  constructor() {
    super();
    this.disableStatefiling();
  }
  disableStatefiling() {
    this.disableCartValue(this.e);
  }
}
