import React from "react";
import "./PasswordInput.css";

interface Props {
  password: string;
  setPassword: (e: any) => void;
}

export default function PasswordInput({ password, setPassword }: Props) {
  return (
    <main className="password-container">
      <div className="password-wrapper">
        <label htmlFor="pass">
          <h3>Password</h3>
        </label>
        <p>Enter your current password</p>
        <input
          className="password-input"
          type="password"
          id="pass"
          value={password}
          onChange={setPassword}
        />
      </div>
    </main>
  );
}
