import { SET_IS_AUTHENTICATED } from "../../redux/actions/action.types";

export default function logoutUser(dispatch: any) {
  dispatch({
    type: SET_IS_AUTHENTICATED,
    payload: false,
  });
  // Remove all local storage items
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("form_data");
  localStorage.removeItem("cart_ids");
  localStorage.removeItem("user_details");

  // Remove isStaff value from session storage
  sessionStorage.removeItem("isStaff");
}
