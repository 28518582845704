import { axiosInstance } from "../../axiosConfig";
import devLogInstance, { prodLogInstance } from "../../loggerConfig";

export default async function getFormCorrectionSummary(payerId: string, year: string) {
  try {
    devLogInstance.log("Calling the form summary for correction API...");
    const { data } = await axiosInstance.post("/get_all_forms_summary_for_correction", {
      payer_id: payerId,
      year,
    });
    devLogInstance.log("Response: ");
    devLogInstance.log(data);
    return data;
  } catch (err) {
    prodLogInstance.error(err);
    throw err;
  }
}
