import React from "react";
import Hamicon from "../Hamicon";
import { useHam } from "../../../../lib/customHooks";
import { LANDING_HAM } from "../../../../lib/constants";

export default function LandingHam() {
  useHam(LANDING_HAM);

  return (
    <div className="xl:hidden flex items-center">
      <Hamicon />
    </div>
  );
}
