import { TO_DISPLAY_MODAL } from "../../redux/actions/action.types";
import { FEEDBACK_MODAL } from "../constants";

export default function displayFeedbackModal(toDisplay = false, stateSetter: any) {
  stateSetter({
    type: TO_DISPLAY_MODAL,
    payload: {
      toDisplay: toDisplay,
      modaltype: FEEDBACK_MODAL,
    },
  });
}
