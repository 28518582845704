import { SET_PHONE_DISPLAY, PAYER_PHONE, REC_PHONE } from "../actions/action.types";

const initialState = {
  payerPhone: "",
  recPhone: "",
};

const phoneDisplay = (state = initialState, action: any) => {
  if (action.type === SET_PHONE_DISPLAY) {
    if (action.phoneType === PAYER_PHONE) {
      return {
        ...state,
        payerPhone: action.payload,
      };
    } else if (action.phoneType === REC_PHONE) {
      return {
        ...state,
        recPhone: action.payload,
      };
    }
  } else {
    return state;
  }
};

export default phoneDisplay;
