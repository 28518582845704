import { Dispatch } from "@reduxjs/toolkit";
import { CSTReducerAction } from "../../../../interfaces/main";
import { SET_CORRECTION_SUBMISSION_TYPE } from "../../../redux/actions/action.types";

export default function setCorrectionSubmissionType(
  submissionType: string | null,
  dispatch: Dispatch<CSTReducerAction>
) {
  dispatch({
    type: SET_CORRECTION_SUBMISSION_TYPE,
    payload: submissionType,
  });
}
