// import getUserDetailsLocally from "../RetrieveUserDetailsLocally/getUserDetailsLocally";
import devLogInstance from "../loggerConfig";

export default class Price {
  static federalPrice: number[] = [];
  static stateFileprice: number = 0;
  static onlineAccessPrice: number = 0;
  static postalMailingPrice: number = 0;
  static forms: number[] = [];
  static formsAlreadyFiled: number = 0;

  // static totalItems = 0;
  static minItemsArray = [];
  static discountPercentArray = [];
  static discountAmountArray = [];
  static discountType: string;

  public static calculateBasePrice(formsFiled: number, formsToBeFiled: number): number {
    devLogInstance.log("Total forms filed by the user uptill now: ");
    devLogInstance.log(formsFiled);

    const formsFiledTillNow = formsFiled;

    // Calculate the pricing based on the forms filed
    const newSubmissions = formsToBeFiled;

    devLogInstance.log("Forms filed till now: ");
    devLogInstance.log(formsFiledTillNow);

    devLogInstance.log("New submissions: ");
    devLogInstance.log(newSubmissions);

    // Call the price table to get the latest prices
    // const priceObj = await Price.findOne({ form_type: "1099" });
    const prices = Price.federalPrice;
    const forms = Price.forms;

    devLogInstance.log("Prices: ");
    devLogInstance.log(prices);

    devLogInstance.log("Forms: ");
    devLogInstance.log(forms);

    // Calculate the highest number of forms (highest integer algorithm)
    let highestIntIdx = forms.length - 1;
    let i = 0;
    while (i < forms.length) {
      if (forms[i] >= formsFiledTillNow) {
        highestIntIdx = i;
        break;
      }
      i++;
    }

    devLogInstance.log({ highestIntIdx });

    let actualPrice = 0;
    let curr = formsFiledTillNow;
    let rem = 0;
    let j = highestIntIdx;
    while (j < forms.length) {
      if (forms[j] <= newSubmissions + formsFiledTillNow) {
        const curr_ = forms[j] - curr;
        devLogInstance.log({ curr_ });
        curr = curr_ <= 0 ? curr : forms[j];
        actualPrice += curr_ * prices[j];
        devLogInstance.log({ actual_price_inside_loop: actualPrice });
        rem += curr_;
      } else break;
      j++;
    }

    devLogInstance.log({ rem });
    rem = newSubmissions - rem;
    devLogInstance.log({ rem });
    devLogInstance.log(prices[j]);
    actualPrice += rem * prices[j];

    devLogInstance.log({ actualPrice_outside_loop: actualPrice });

    return actualPrice;
  }

  static calculateTotalPrice(
    totalOnlineAccess: any,
    totalPostalMailing: any,
    totalStateFiling: any,
    totalSelectedRec: any,
    discount = 0
  ) {
    const fedPrice = Price.calculateBasePrice(Price.formsAlreadyFiled, totalSelectedRec);
    const oaPrice = Price.onlineAccessPrice * totalOnlineAccess;
    const pmPrice = Price.postalMailingPrice * totalPostalMailing;
    const sfPrice = Price.stateFileprice * totalStateFiling;

    const totalPrice = fedPrice + oaPrice + pmPrice + sfPrice;
    // const totalPrice = fedPrice;
    devLogInstance.log(`Total price ---> ${totalPrice}`);
    // if (Price.discountType === "percentage" && discount) {
    //   devLogInstance.warn(`Percentage discount ---> ${totalPrice - (discount / 100) * fedPrice}`);
    //   return [totalPrice, totalPrice - (discount / 100) * fedPrice];
    // } else if (Price.discountType === "amount" && discount) {
    //   devLogInstance.warn(`Amount discount ---> ${fedPrice - discount}`);
    //   return [totalPrice, fedPrice - discount];
    // }
    return [totalPrice, totalPrice];
  }

  static getDiscount(totalItems: number) {
    let initialIdx = 0;
    let finalIdx = 1;
    let idxOfMinItem;
    while (true) {
      devLogInstance.log(`${[initialIdx, finalIdx]}`);
      if (totalItems < Price.minItemsArray[initialIdx]) break;
      else if (finalIdx >= Price.minItemsArray.length) {
        idxOfMinItem = Price.minItemsArray.length > 0 ? finalIdx - 1 : null; // Set the last index value of the min items array
        break;
      } else if (
        totalItems >= Price.minItemsArray[initialIdx] &&
        totalItems < Price.minItemsArray[finalIdx]
      ) {
        idxOfMinItem = initialIdx;
        break;
      }
      initialIdx++;
      finalIdx++;
    }
    devLogInstance.log(Price.discountType);
    devLogInstance.log("IDX OF MIN ITEM: " + idxOfMinItem);
    if (Price.discountType === "percentage") {
      if (idxOfMinItem !== null && idxOfMinItem !== undefined) {
        const discountPercent = Price.discountPercentArray[idxOfMinItem];
        devLogInstance.log(`Discount percent ---> ${discountPercent}`);
        return discountPercent;
      }
    } else if (Price.discountType === "amount") {
      if (idxOfMinItem !== null && idxOfMinItem !== undefined) {
        const discountAmount = Price.discountAmountArray[idxOfMinItem];
        devLogInstance.log(`Discount amount ---> ${discountAmount}`);
        return discountAmount;
      }
    }
    return 0;
  }
}
