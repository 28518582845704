import React from "react";
import { Link } from "react-router-dom";
import { ADMIN, ADMIN_PASSWORD_CHANGE } from "../../lib/routes";
import { useDispatch } from "react-redux";
import logoutAdmin from "../../lib/AdminRelatedUtils/logoutAdmin";
import { FaHome } from "react-icons/fa";

export default function AdminNavbar() {
  const dispatch = useDispatch();

  return (
    <div className="absolute z-10 bg-white border-b flex items-center justify-between w-full px-20 py-4">
      <Link to={ADMIN}>
        <FaHome className="text-3xl" />
      </Link>
      <div className="flex items-center gap-10">
        <Link
          to={ADMIN_PASSWORD_CHANGE}
          className="text-taxeve-primary-blue font-bold underline underline-offset-4 text-lg"
        >
          Change Password
        </Link>

        <button
          className="admin-logout bg-taxeve-primary-violet hover:opacity-75 text-white rounded-lg px-4 py-2"
          onClick={() => logoutAdmin(dispatch)}
        >
          Logout
        </button>
      </div>
    </div>
  );
}
