import React from "react";
import UserDetailsCard from "./UserDetailsCard";
import { StaffAccountInfo, UserAccountInfo } from "../../../../interfaces/main";
import StaffDetailsCard from "./StaffDetailsCard";

interface Props {
  userDetails: UserAccountInfo | null;
  staffDetails: StaffAccountInfo[];
}

export default function AccountSwitchContainer({ userDetails, staffDetails }: Props) {
  return (
    <>
      <div className="py-4 px-8 font-bold">
        <span className="text-lg font-bold underline underline-offset-2">
          Switch between main & member accounts
        </span>{" "}
        :
      </div>
      <div className="account-details-container p-8 flex items-center justify-center gap-8">
        <UserDetailsCard userDetails={userDetails} />
        {staffDetails.length > 0 && (
          <div className="w-12 h-12 bg-gray-300 p-4 rounded-full flex items-center justify-center">
            OR
          </div>
        )}
        {staffDetails.length > 0 && <StaffDetailsCard staffDetails={staffDetails} />}
      </div>
    </>
  );
}
