import callPopup from "./callPopup";
import removePopup from "./removePopup";
import popupDuration from "./popupDuration";

export default function handlePopup(message: string, status: string, popupHelper: any) {
  // Show popup
  callPopup(message, status, popupHelper);
  // Rremove popup
  removePopup(popupDuration(message.length), popupHelper);
}
