import React from "react";
import { accountDisplayTexts } from "../../../../../lib/languagePacks/en-us";
import { FORM1099_S } from "../../../../../lib/constants";

export default function AccountNumber({ formData }: any) {
  return (
    <div
      style={{
        width: "100%",
        borderRight: formData?.form_type !== FORM1099_S ? `1px solid black` : "",
      }}
      className="ac-no-container"
    >
      <div>
        <h4>{accountDisplayTexts.heading}</h4>
      </div>
      <div style={{ color: "#30A2FF" }}>{formData?.payers_account_number_for_payee}</div>
    </div>
  );
}
