import devLogInstance from "../loggerConfig";
import Cookie from "../utils/Cookie";
import authenticateAdmin from "./authenticateAdmin";
import logoutAdmin from "./logoutAdmin";

export default function checkAdminAuth(adminTokenName: string, stateSetter: any) {
  const adminTokenCookieExists = Cookie.retrieveCookie(adminTokenName);
  if (adminTokenCookieExists) {
    devLogInstance.log("authenticating admin");
    authenticateAdmin(stateSetter, true);
  } else {
    // Logout admin
    logoutAdmin(stateSetter);
  }
}
