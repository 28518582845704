import { axiosInstance } from "../../axiosConfig";

export default async function getOnlineAccessFormData() {
  try {
    const { data } = await axiosInstance.get("/get_online_access_form_data");
    return data;
  } catch (err) {
    throw err;
  }
}
