import React from "react";

export default function IRSDisclaimer({ toPutBorderbottom }: any) {
  return (
    <div
      className="disclaimer"
      style={{ fontWeight: "normal", borderBottom: toPutBorderbottom ? `1px solid black` : "" }}
    >
      <h3>Copy A</h3>
      <h4>For Internal Revenue Service Center</h4>
      <h4>File with Form 1096.</h4>
      <p>
        For Privacy Act and Paperwork Reduction Act Notice, see the{" "}
        <span>current General Instructions for Certain Information Returns.</span>
      </p>
    </div>
  );
}
