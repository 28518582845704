import { axiosInstance } from "../axiosConfig";
import { calCurrentTaxYear } from "../calculateTaxYear";

export default async function getSpecificHistoryData(payerId = null, year = calCurrentTaxYear()) {
  try {
    const { data } = await axiosInstance.post("/get_history_data_for_payer", {
      payer_id: payerId,
      year: year,
    });
    return data;
  } catch (err) {
    throw err;
  }
}
