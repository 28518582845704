import SessionStorage from "../LocalCaching/SessionStorage";
import Cookie from "../utils/Cookie";
import authenticateAdmin from "./authenticateAdmin";

export default function logoutAdmin(stateSetter: any) {
  // Remove the admin token
  Cookie.removeCookie("admin_token");

  // Delete the cached admin level
  SessionStorage.removeCacheItem("admin_level");

  // Unauthenticate admin
  authenticateAdmin(stateSetter, false);
}
