import { TO_DISPLAY_MANUAL_GROUP_CREATING_MODAL } from "../../actions/action.types";
import { CreateGroupParams } from "../../../../interfaces/main";

export interface CreateGroupModalActionPayload {
  toDisplay: boolean;
  handleCreatingGroup: ((params: CreateGroupParams) => Promise<void>) | null;
}

export interface CreateGroupModalAction {
  type: string;
  payload: CreateGroupModalActionPayload;
}

const initialState: CreateGroupModalActionPayload = {
  toDisplay: false,
  handleCreatingGroup: null,
};

const displayManualGroupCreatingModal = (state = initialState, action: CreateGroupModalAction) => {
  if (action.type === TO_DISPLAY_MANUAL_GROUP_CREATING_MODAL) return action.payload;
  return state;
};

export default displayManualGroupCreatingModal;
