import { axiosInstance } from "../axiosConfig";

export default async function payment(cartIds: any[], couponCode = "") {
  try {
    console.log(cartIds);
    const { data } = await axiosInstance.post("/payment", {
      cart_id_array: cartIds,
      coupon_code: couponCode,
    });
    return data;
  } catch (err) {
    throw err;
  }
}
