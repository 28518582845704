import { axiosInstance } from "../../axiosConfig";

export default async function activateEmail(userId: string, userToken: string) {
  try {
    const res = await axiosInstance.get(`/activate/${userId}/${userToken}`);
    return { data: res.data, status: res.status };
  } catch (error) {
    throw error;
  }
}
