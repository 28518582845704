export default function filterList(filterQueue: any, initialList: any, type: any) {
  const newList =
    type === "payer"
      ? initialList.filter((payerObj: any) => {
          return (
            payerObj.payer_name.substring(0, filterQueue.length).toLowerCase() ===
            filterQueue.toLowerCase()
          );
        })
      : initialList.filter((recObj: any) => {
          return (
            recObj.recipient_name.substring(0, filterQueue.length).toLowerCase() ===
            filterQueue.toLowerCase()
          );
        });
  return newList;
}
