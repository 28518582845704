import React from "react";
import { form1099 } from "../lib/languagePacks/en-us";
// import { useSelector } from "react-redux";
import { useState } from "react";
import { Link } from "react-router-dom";
import { E_FILING } from "../lib/routes";
import { ga4 } from "../App";
import ga4Const from "../lib/json/AnalyticsRelated/analyticsConst.json";

interface Props {
  formType: any;
  filingYear: number;
}

export default function ManualEntryCard({ formType, filingYear }: Props) {
  // const style = useSelector((state: any) => state.themeChange);
  const [isHovered, setIshovered] = useState(false);

  const handleClick = () => {
    ga4.sendEvent({
      category: ga4Const.event.category["e-filing"],
      action: `${ga4Const.event.action.manualEntry} (${formType.title} for ${filingYear})`,
    });
  };

  return (
    <Link
      onClick={handleClick}
      to={`${E_FILING}/${formType.title.split(" ")[1]}/${filingYear}`}
      className="card w-[100%] lg:w-[30rem]"
    >
      <div className="card-heading">{form1099.createForm.manualDataEntry.heading}</div>
      <div className="card-description">
        {form1099.createForm.manualDataEntry.description_pt1} {formType.title}{" "}
        {form1099.createForm.manualDataEntry.description_pt2}
      </div>

      <div className="file-btn-wrapper start-filing-btn-wrapper" style={{ margin: "2rem 0 0" }}>
        <div className="form-icon w-[15%]">
          {/* <i className="fa-brands fa-wpforms" /> */}
          <img src="/Assets/manualEntry.svg" alt="Manual entry" className="w-[100%]" />
        </div>
        <button
          className={`file-btn bg-taxeve-primary-violet text-white rounded-lg ${
            isHovered && "shadow-lg shadow-taxeve-primary-violet"
          }`}
          onMouseOver={() => {
            setIshovered(true);
          }}
          onMouseLeave={() => {
            setIshovered(false);
          }}
        >
          {form1099.createForm.manualDataEntry.startBtnText}
        </button>
      </div>
    </Link>
  );
}
