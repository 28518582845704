import React from "react";
import "../styles/LoginRegisterPage.css";

export default function TaxEveImg() {
  return (
    <div className="taxeve-img-wrapper shadow-none hidden sm:block">
      <img className="taxeve-img" src="/Assets/login_signup_img.svg" alt="tax eve" />
    </div>
  );
}
