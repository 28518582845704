import { GroupRecord, StaffRecord } from "../../../../interfaces/main";
import { TO_DISPLAY_PAYER_ASSIGNMENT_MODAL } from "../../actions/action.types";

export interface PayerAssignmentModalActionProps {
  type: string;
  payload: {
    status: boolean;
    isStaff: boolean;
    staffOrGroupId: string;
    updateStaffRecords?: React.Dispatch<React.SetStateAction<StaffRecord[]>> | null;
    updateGroupRecords?: React.Dispatch<React.SetStateAction<GroupRecord[]>> | null;
    staffName?: string;
    staffEmail?: string;
    groupName?: string;
  };
}

const initialState = {
  status: false,
  isStaff: false,
  staffOrGroupId: "",
  updateStaffRecords: null,
  updateGroupRecords: null,
};

const displayPayerAssignmentModal = (
  state = initialState,
  action: PayerAssignmentModalActionProps
) => {
  if (action.type === TO_DISPLAY_PAYER_ASSIGNMENT_MODAL) return action.payload;
  return state;
};

export default displayPayerAssignmentModal;
