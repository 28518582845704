import { axiosInstance } from "../axiosConfig";

export default async function getVerificationCode(email: string, hashedpass: string) {
  try {
    const { data } = await axiosInstance.post("/get_verification_code", {
      password: hashedpass,
      new_email: email,
    });
    return data;
  } catch (err) {
    throw err;
  }
}
