import devLogInstance from "../loggerConfig";

export default class Cookie {
  static setCookie(name: string, payload: string, setExpiry?: boolean) {
    let maxAge: string = "";
    if (setExpiry) {
      const d = new Date();
      const expirySeconds = process.env.REACT_APP_AUTH_TOKEN_EXPIRY_SECONDS || "1000000";
      d.setTime(d.getTime() + parseInt(expirySeconds) * 1000);
      maxAge = d.toUTCString();
    }
    // Set a cookie
    document.cookie = `${name}=${payload}; Path=/; Secure; SameSite=None; ${
      maxAge ? "Expires=" + maxAge + "; " : ""
    } Domain=.${process.env.REACT_APP_DOMAIN}`;
  }

  static retrieveCookie(name: string): string | null {
    // Rectrive a cookie
    if (this.cookieExists(name)) {
      const startIdx = this.cookiePos(name); // Position of the cookie in string
      const cookieSplit = document.cookie.substring(startIdx, document.cookie.length + 1);

      const cookie = cookieSplit.split("; ")[0]; // The first element is the desired cookie because the cookie string was split from the start idx of the desired cookie
      return cookie;
    }
    return null;
  }

  static removeCookie(name: string) {
    devLogInstance.log(`Name of cookie to remove ---> ${name}`);
    // Remove the cookie
    const cookie = this.retrieveCookie(name);
    devLogInstance.log(`Cookie to remove ---> ${cookie}`);
    if (cookie) {
      const cookiename = cookie.split("=")[0];
      document.cookie = `${cookiename}=; Expires=${new Date().toUTCString()}; Path=/; Domain=.${
        process.env.REACT_APP_DOMAIN
      }`;
    }
  }

  private static cookieExists(name: string) {
    return document.cookie.includes(name);
  }

  private static cookiePos(name: string): number {
    const startIdx = document.cookie.indexOf(name);
    return startIdx;
  }
}
