import React from "react";
import { FORM1099_S } from "../../../../lib/constants";
import formatPhonenum from "../../../../lib/form/utils/formatPhone";
import { payerDetails, payerDetailsFor1099S } from "../../../../lib/languagePacks/en-us";

export default function RFPayerDetails({ formData }: any) {
  return (
    <div className="rf-child-component rf-pd">
      <div>
        <h4>
          {formData?.form_type === FORM1099_S
            ? payerDetailsFor1099S.requiredDetails
            : payerDetails.requiredDetails}
        </h4>
      </div>
      <div style={{ color: "#30A2FF" }}>
        <p>{formData?.payer_name}</p>
        <p>
          {formData?.payer_address_line1} {formData?.payer_address_line2}
        </p>
        <p>
          {formData?.payer_city} {formData?.payer_state}{" "}
          {formData?.payer_is_foreign_address ? formData?.payer_country : ""}{" "}
          {formData?.payer_zipcode}
        </p>
        <p>
          {formData?.payer_phone_number && formatPhonenum(formData?.payer_phone_number.toString())}
        </p>
      </div>
    </div>
  );
}
