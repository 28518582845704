import React from "react";
import { useSelector } from "react-redux";
import "../styles/Pages/404.css";
import { Link } from "react-router-dom";
import { useHover } from "../lib/customHooks";
import { LANDING_PAGE } from "../lib/routes";

export default function PageNotFound() {
  const style = useSelector((state: any) => state.themeChange);
  const [isHovered, handleHoverIn, handleHoverOut] = useHover();

  const handleMouseOver = (e: any) => {
    const { id } = e.target;
    handleHoverIn(id);
  };

  const handleMouseLeave = (e: any) => {
    const { id } = e.target;
    handleHoverOut(id);
  };

  return (
    <div
      className="page-not-found-container"
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <Link
        className="home-btn"
        to={LANDING_PAGE}
        id="HOME_BTN"
        style={{
          background: style.btnBg,
          boxShadow: isHovered["HOME_BTN"] === true ? "4px 4px 0 black" : "6px 6px 0 black",
          color: style.btnFillFontColor,
        }}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        Go back to Home
      </Link>
    </div>
  );
}
