import React, { ReactNode, createContext, useMemo, useState } from "react";
import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { useEffect, useLayoutEffect } from "react";
import Home from "./pages/Home";
import Eform from "./pages/E-Form";
import { useSelector, useDispatch } from "react-redux";
import Review from "./pages/Review";
import { useLocation } from "react-router-dom";
import checkAuth from "./lib/authRequests/checkAuth";
import Handler, { Main } from "./lib/Handlers/main";
import Upload from "./pages/Upload";
import CartPage from "./pages/CartPage";
import LoaderState from "./lib/Loader/main";
import DisableScreen from "./lib/utils/disableScreen";
import { Action } from "./lib/utils/Action";
// import { FormUnreview } from "./lib/utils/BulkAction";
import Settings from "./pages/Settings";
import {
  HOME,
  LANDING_PAGE,
  REGISTER,
  SIGNIN,
  E_FILING,
  REVIEW,
  FILE_UPLOAD,
  CART,
  SETTINGS,
  HISTORY_PAGE,
  PRICING_PAGE,
  CONTACT_US_PAGE,
  _1099_FORMS_PAGE,
  WHY_CHOOSE_US_PAGE,
  ADMIN,
  ADMIN_LOGIN,
  ADMIN_MANAGEMENT,
  ADMIN_PRICE_MANAGEMENT,
  TEMPLATES_DOWNLOAD,
  ADMIN_PASSWORD_CHANGE,
  PASSWORD_RESET,
  ONLINE_ACCESS_LOGIN,
  ONLINE_ACCESS_CONSENT,
  ONLINE_ACCESS_LANDING,
  CORRECTION,
  ADMIN_FIRE_FILING,
  ONLINE_ACCESS_DASHBOARD,
  TERMS_AND_CONDITION,
  VERIFY_EMAIL,
  ENQUIRY,
  PRIVACY_POLICY,
  ADMIN_TRACK_TRANSACTIONS,
  BUSINESS_SETTINGS,
  SWITCH_ACCOUNTS,
} from "./lib/routes";
import History from "./pages/History";
import PageNotFound from "./pages/404Page";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
import Forms1099 from "./pages/1099Forms";
import WhyChooseUs from "./pages/WhyChooseUs";
import checkAdminAuth from "./lib/AdminRelatedUtils/checkAdminAuth";
import Cookie from "./lib/utils/Cookie";
import devLogInstance from "./lib/loggerConfig";
import AdminDashboard from "./pages/AdminRelatedPages/AdminDashboard";
import AdminLogin from "./pages/AdminRelatedPages/AdminLogin";
import AdminManagement from "./pages/AdminRelatedPages/AdminManagement";
import PriceManagement from "./pages/AdminRelatedPages/PriceManagement";
import TemplatesDownloadPage from "./pages/TemplatesDownload";
import Modal from "./components/decorations/Modal/Modal";
import {
  ADD_BULK_STAFF_MODAL,
  ADD_STAFFS_TO_GROUP_MODAL,
  BULK_CREATE_GROUP_MODAL,
  CONFIRMATION_MODAL,
  GROUP_VIEWING_MODAL,
  LANDING_HAM,
  MANUAL_GROUP_CREATING_MODAL,
  MANUAL_STAFF_ADDING_MODAL,
  PAYER_ASSIGNMENT_MODAL,
  PAYER_UNASSIGNMENT_MODAL,
  PDF_VIEWER_MODAL,
  POST_LOGIN_HAM,
  REMOVE_STAFFS_FROM_GROUP_MODAL,
  VIEW_ASSIGNED_PAYERS_MODAL,
  VIEW_ASSIGNED_STAFFS_MODAL,
} from "./lib/constants";
import Popup from "./components/Popup";
import { usePopup } from "./lib/customHooks";
import screenDisabler from "./components/decorations/ScreenDisabler";
import EmailRequiredModal from "./components/decorations/EmailRequiredModal/EmailRequiredModal";
import AdminChangePassword from "./pages/AdminRelatedPages/AdminChangePassword";
import ResetPassword from "./pages/ResetPassword";
import LoginOnlineAccess from "./pages/OnlineAccessPortal/LoginOnlineAccess";
import checkRecipientAuth from "./lib/OnlineAccessRelatedUtils/checkRecipientAuth";
import OnlineAccessConsent from "./pages/OnlineAccessPortal/OnlineAccessConsent";
import OnlineAccessLanding from "./pages/OnlineAccessPortal/OnlineAccessLanding";
import SessionStorage from "./lib/LocalCaching/SessionStorage";
import Correction1099 from "./pages/Correction1099";
import { GA4 } from "./lib/utils/AnalyticsRelated/Analytics";
import FireFiling from "./pages/AdminRelatedPages/FireFiling";
import OnlineAccessDashboard from "./pages/OnlineAccessPortal/OnlineAccessDashboard";
import LandingHammenu from "./components/decorations/HamburgerMenu/LandingHam/LandingHammenu";
import PostLoginHammenu from "./components/decorations/HamburgerMenu/PostLoginHam/PostLoginHammenu";
import TermsAndCondition from "./pages/TermsAndCondition";
import EmailActivation from "./pages/VerificationRelated/EmailActivation";
import Enquiry from "./pages/Enquiry";
import ProgressContainer from "./components/decorations/ProgressContainer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import EnquiryFloater from "./components/EnquiryRelated/EnquiryFloater";
import TrackTransactions from "./pages/AdminRelatedPages/TrackTransactions";
import AdminNavbar from "./components/AdminRelatedComponents/AdminNavbar";
import BusinessSettings from "./pages/BusinessSettings";
import {
  AddBulkStaffActionPayload,
  BulkCreateGroupActionPayload,
  ViewAssignedPayersActionPayload,
  ViewAssignedStaffsActionPayload,
} from "../interfaces/main";
import AccountSwitch from "./pages/AccountSwitch";

let ga4MID = process.env.REACT_APP_GA4MID ?? "";
export const ga4 = new GA4(ga4MID);
// ga4.reset();
ga4.initialize();

type SetProgress = React.Dispatch<React.SetStateAction<ProgressInterface>>;
type SetTotalStaffAccounts = React.Dispatch<React.SetStateAction<number>>;

interface ProgressInterface {
  inProgress: boolean | null;
  message: string | ReactNode;
}

interface StaffAccountsContextState {
  totalStaffAccounts: number;
  setTotalStaffAccounts: SetTotalStaffAccounts;
}

export const ProgressContext = createContext<[ProgressInterface, SetProgress] | null>(null);

export const StaffAccountsContext = createContext<StaffAccountsContextState | null>(null);

const isAfterLoginRoute = (currentRoute: string) => currentRoute.includes("taxeve");

function App() {
  const isAuthenticated = useSelector((state: any) => state.isAuthenticated);
  const isAdminAuthenticated = useSelector((state: any) => state.isAdminAuthenticated);
  const isRecipientAuthenticated = useSelector((state: any) => state.isRecipientAuthenticated);
  const [consentStatus, setConsentStatus] = useState(false);
  const [displayPopup, popupMessage, popupStatus] = usePopup();
  const toDisableScreen = useSelector((state: any) => state.toDisableScreen);
  const displayConfirmationModal = useSelector((state: any) => state.displayConfirmationModal);
  const displayPdfViewerModal = useSelector((state: any) => state.displayPdfViewerModal);
  const toCallEmailRequiredModal = useSelector((state: any) => state.toCallEmailRequiredModal);
  const displayModal = useSelector((state: any) => state.displayModal);
  const displayHammenu: { toDisplay: true; menutype: string } = useSelector(
    (state: any) => state.displayHammenu
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const [progress, setProgress] = useState<ProgressInterface>({
    inProgress: null,
    message: "",
  });

  devLogInstance.log(displayHammenu);

  const displayPayerAssignmentModal = useSelector(
    (state: any) => state.displayPayerAssignmentModal
  );
  const displayPayerUnassignmentModal = useSelector(
    (state: any) => state.displayPayerUnassignmentModal
  );

  const displayManualStaffAddingModal = useSelector(
    (state: any) => state.displayManualStaffAddingModal
  );
  const displayManualGroupCreatingModal = useSelector(
    (state: any) => state.displayManualGroupCreatingModal
  );

  const displayViewGroupsModal = useSelector((state: any) => state.displayViewGroupsModal);

  const displayAddStaffsToGroupModal = useSelector(
    (state: any) => state.displayAddStaffsToGroupModal
  );
  const displayRemoveStaffsFromGroupModal = useSelector(
    (state: any) => state.displayRemoveStaffsFromGroupModal
  );

  const displayViewAssignedPayersModal: ViewAssignedPayersActionPayload = useSelector(
    (state: any) => state.displayViewAssignedPayersModal
  );
  const displayViewAssignedStaffsModal: ViewAssignedStaffsActionPayload = useSelector(
    (state: any) => state.displayViewAssignedStaffsModal
  );

  const displayAddBulkStaffModal: AddBulkStaffActionPayload = useSelector(
    (state: any) => state.displayAddBulkStaffModal
  );
  const displayBulkCreateGroupModal: BulkCreateGroupActionPayload = useSelector(
    (state: any) => state.displayBulkCreateGroupModal
  );

  const [totalStaffAccounts, setTotalStaffAccounts] = useState<number>(0);

  // check authentication
  useLayoutEffect(() => {
    checkAuth(dispatch);
    checkAdminAuth("admin_token", dispatch);
    checkRecipientAuth("recipient_token", dispatch);
  }, [dispatch]);

  // Handle processing of homepage data
  useEffect(() => {
    isAfterLoginRoute(location.pathname) && Handler.handleHomeDataProcessing(dispatch); // Get home data only when not in admin page
  }, [dispatch, location]);

  // delete existing jwt if authentication fails
  useMemo(() => {
    if (isAuthenticated === false) {
      // document.cookie = `auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      // Removes the user auth token cookie
      // document.cookie = document.cookie
      //   .replace(/^ +/, "")
      //   .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      devLogInstance.warn("User is not authenticated");
      Cookie.removeCookie("auth_token");
    }
    if (isAdminAuthenticated === false) {
      devLogInstance.warn("Admin is not authenticated");
      Cookie.removeCookie("admin_token");
    }
  }, [isAuthenticated, isAdminAuthenticated]);

  // Check consent status for online access portal, if not pending, redirect to the dashboard
  useEffect(() => {
    if (
      location.pathname === ONLINE_ACCESS_CONSENT ||
      location.pathname === ONLINE_ACCESS_DASHBOARD
    ) {
      const consent = SessionStorage.getCacheItem("rec_consent_status");
      devLogInstance.log(`Consent: ${consent}`);
      consent !== "pending" && consent !== "no" ? setConsentStatus(true) : setConsentStatus(false);
    }
  }, [location]);

  // Send page view to GA4
  useEffect(() => {
    const path = location.pathname;
    ga4.sendPageHit(path);
  }, [location]);

  // Initialize the Loader, Screen disabler
  // Set the Action stateSetter variable
  // Set the Handler class stateDispatch variable
  useEffect(() => {
    LoaderState.stateHandler = dispatch;
    DisableScreen.stateHandler = dispatch;
    Action.stateSetter = dispatch;
    Main.stateDispatch = dispatch; // Handler main class
  }, [dispatch]);

  // If any task is in progress then prevent user from directly reloading the page
  useEffect(() => {
    const unloadCallback = (event: any) => {
      if (progress.inProgress) {
        event.preventDefault();
        event.returnValue = true;
      }
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, [progress.inProgress]);

  return (
    <>
      <ProgressContext.Provider value={[progress, setProgress]}>
        <StaffAccountsContext.Provider value={{ totalStaffAccounts, setTotalStaffAccounts }}>
          {(isAuthenticated !== null || isAdminAuthenticated !== null) && (
            <main className="relative min-h-screen">
              {toDisableScreen && screenDisabler.start()}
              {/* {toDisplayFeedbackModal && <Modal modalType={FEEDBACK_MODAL} />} */}
              {displayModal.toDisplay && <Modal modalType={displayModal.modaltype} />}
              {displayConfirmationModal.status === true && (
                <Modal modalType={CONFIRMATION_MODAL} action={displayConfirmationModal.action} />
              )}
              {toCallEmailRequiredModal.toCall && (
                <EmailRequiredModal
                  dataArray={toCallEmailRequiredModal.dataArray}
                  payerId={toCallEmailRequiredModal.payerId}
                  cartIds={toCallEmailRequiredModal.cartIds}
                />
              )}

              {displayPdfViewerModal.status === true && (
                <Modal modalType={PDF_VIEWER_MODAL} blobData={displayPdfViewerModal.pdfBlob} />
              )}

              {displayPayerAssignmentModal.status === true && (
                <Modal modalType={PAYER_ASSIGNMENT_MODAL} />
              )}
              {displayPayerUnassignmentModal.status === true && (
                <Modal modalType={PAYER_UNASSIGNMENT_MODAL} />
              )}

              {displayManualStaffAddingModal.toDisplay && (
                <Modal modalType={MANUAL_STAFF_ADDING_MODAL} />
              )}
              {displayManualGroupCreatingModal.toDisplay && (
                <Modal modalType={MANUAL_GROUP_CREATING_MODAL} />
              )}

              {displayAddStaffsToGroupModal.toDisplay && (
                <Modal modalType={ADD_STAFFS_TO_GROUP_MODAL} />
              )}
              {displayRemoveStaffsFromGroupModal.toDisplay && (
                <Modal modalType={REMOVE_STAFFS_FROM_GROUP_MODAL} />
              )}

            {location.pathname.includes("admin") &&
              !location.pathname.includes("login") &&
              process.env.REACT_APP_ADMIN_SECRET_CODE &&
              location.pathname.includes(process.env.REACT_APP_ADMIN_SECRET_CODE) && (
                <AdminNavbar />
              )}

              {displayViewGroupsModal.toDisplay && <Modal modalType={GROUP_VIEWING_MODAL} />}

              {displayViewAssignedPayersModal.toDisplay && (
                <Modal modalType={VIEW_ASSIGNED_PAYERS_MODAL} />
              )}
              {displayViewAssignedStaffsModal.toDisplay && (
                <Modal modalType={VIEW_ASSIGNED_STAFFS_MODAL} />
              )}
              {displayBulkCreateGroupModal.toDisplay && (
                <Modal modalType={BULK_CREATE_GROUP_MODAL} />
              )}

              {displayAddBulkStaffModal.toDisplay && <Modal modalType={ADD_BULK_STAFF_MODAL} />}

              {displayPopup && <Popup message={popupMessage} status={popupStatus} />}

              {/* Ham menu */}
              {displayHammenu.menutype === LANDING_HAM && displayHammenu.toDisplay && (
                <LandingHammenu currentRoute={location.pathname} />
              )}

              {displayHammenu.menutype === POST_LOGIN_HAM && displayHammenu.toDisplay && (
                <PostLoginHammenu />
              )}

              {/* Enquiry Floater */}
              {isAfterLoginRoute(location.pathname) && <EnquiryFloater />}

              {/* Progress container */}
              {progress.inProgress !== null && (
                <ProgressContainer inProgress={progress.inProgress} message={progress.message} />
              )}

              <Routes>
                <Route path={LANDING_PAGE} element={<LandingPage />} />
                <Route path={`${VERIFY_EMAIL}/:userId/:userToken`} element={<EmailActivation />} />
                <Route
                  path={SIGNIN}
                  element={!isAuthenticated ? <Login /> : <Navigate to={HOME} />}
                />
                <Route
                  path={REGISTER}
                  element={!isAuthenticated ? <Register /> : <Navigate to={HOME} />}
                />
                <Route
                  path={HOME}
                  element={
                    isAuthenticated ? (
                      totalStaffAccounts > 0 ? (
                        <Navigate to={SWITCH_ACCOUNTS} />
                      ) : (
                        <Home />
                      )
                    ) : (
                      <Navigate to={SIGNIN} />
                    )
                  }
                />
                <Route
                  path={`${E_FILING}/:formType/:taxYear`}
                  element={isAuthenticated ? <Eform /> : <Navigate to={SIGNIN} />}
                />
                <Route
                  path={REVIEW}
                  element={isAuthenticated ? <Review /> : <Navigate to={SIGNIN} />}
                />
                <Route
                  path={`${FILE_UPLOAD}/:formType/:flowType/:taxYear`}
                  element={isAuthenticated ? <Upload /> : <Navigate to={SIGNIN} />}
                />
                <Route
                  path={CART}
                  element={isAuthenticated ? <CartPage /> : <Navigate to={SIGNIN} />}
                />
                <Route
                  path={`${SETTINGS}/:settingsType`}
                  element={isAuthenticated ? <Settings /> : <Navigate to={SIGNIN} />}
                />
                <Route
                  path={HISTORY_PAGE}
                  element={isAuthenticated ? <History /> : <Navigate to={SIGNIN} />}
                />
                <Route
                  path={TEMPLATES_DOWNLOAD}
                  element={isAuthenticated ? <TemplatesDownloadPage /> : <Navigate to={SIGNIN} />}
                />
                <Route
                  path={`${CORRECTION}/:taxYear`}
                  element={isAuthenticated ? <Correction1099 /> : <Navigate to={SIGNIN} />}
                />
                <Route
                  path={BUSINESS_SETTINGS}
                  element={isAuthenticated ? <BusinessSettings /> : <Navigate to={SIGNIN} />}
                />
                <Route
                  path={SWITCH_ACCOUNTS}
                  element={isAuthenticated ? <AccountSwitch /> : <Navigate to={SIGNIN} />}
                />

                {/* Admin Routes */}
                <Route
                  path={ADMIN_LOGIN}
                  element={!isAdminAuthenticated ? <AdminLogin /> : <Navigate to={ADMIN} />}
                />

                <Route
                  path={ADMIN}
                  element={
                    isAdminAuthenticated ? <AdminDashboard /> : <Navigate to={ADMIN_LOGIN} />
                  }
                />

                <Route
                  path={ADMIN_MANAGEMENT}
                  element={
                    isAdminAuthenticated ? <AdminManagement /> : <Navigate to={ADMIN_LOGIN} />
                  }
                />

                <Route
                  path={ADMIN_PRICE_MANAGEMENT}
                  element={
                    isAdminAuthenticated ? <PriceManagement /> : <Navigate to={ADMIN_LOGIN} />
                  }
                />

                <Route
                  path={ADMIN_PASSWORD_CHANGE}
                  element={
                    isAdminAuthenticated ? <AdminChangePassword /> : <Navigate to={ADMIN_LOGIN} />
                  }
                />

                <Route
                  path={ADMIN_FIRE_FILING}
                  element={isAdminAuthenticated ? <FireFiling /> : <Navigate to={ADMIN_LOGIN} />}
                />

                {/* Online access routes */}
                <Route
                  path={`${ONLINE_ACCESS_LANDING}`}
                  element={
                    !isRecipientAuthenticated ? (
                      <OnlineAccessLanding />
                    ) : (
                      <Navigate to={ONLINE_ACCESS_CONSENT} />
                    )
                  }
                />
                <Route
                  path={`${ONLINE_ACCESS_LOGIN}/:formId/:oaKey`}
                  element={<LoginOnlineAccess />}
                />
                <Route
                  path={ONLINE_ACCESS_CONSENT}
                  element={
                    isRecipientAuthenticated ? (
                      consentStatus === false ? (
                        <OnlineAccessConsent />
                      ) : (
                        <Navigate to={ONLINE_ACCESS_DASHBOARD} />
                      )
                    ) : (
                      <Navigate to={ONLINE_ACCESS_LANDING} />
                    )
                  }
                />
                <Route
                  path={ONLINE_ACCESS_DASHBOARD}
                  element={
                    isRecipientAuthenticated ? (
                      consentStatus === true ? (
                        <OnlineAccessDashboard />
                      ) : (
                        <Navigate to={ONLINE_ACCESS_CONSENT} />
                      )
                    ) : (
                      <Navigate to={ONLINE_ACCESS_LANDING} />
                    )
                  }
                />

                {/* 404 Route */}
                <Route path={"*"} element={<PageNotFound />} />
              </Routes>
            </main>
          )}
        </StaffAccountsContext.Provider>
      </ProgressContext.Provider>
    </>
  );
}

export default App;
