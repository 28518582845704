import { axiosInstance } from "../axiosConfig";

export default async function submitFeedback(feedbackVals: any) {
  try {
    let response = {};
    if (feedbackVals) {
      const { data } = await axiosInstance.post("/submit_feedback", {
        ...feedbackVals,
      });
      response = data;
    }
    return response;
  } catch (err) {
    throw err;
  }
}
