import { combineReducers } from "@reduxjs/toolkit";
import themeChange from "./themeChange";
import isAuthenticated from "./authSet";
import displayPopup from "./popupDisplay";
import popupMessage from "./popupMessage";
import popupStatus from "./popupStatus";
import { setPayerNames } from "./setPayerData";
import { payerTinDisplay, recTinDisplay } from "./tinDisplay";
import toDisableScreen from "./screenDisabler";
import zipcode from "./zipcode";
import phoneDisplay from "./phoneDisplay";
import { recipientNames } from "./recipientData";
import { clearDataStatus } from "./clearFormData";
import stateAmount from "./stateAmount";
import showFAWarning from "./ForeignAddress/showFAWarning";
import FAWarnMsg from "./ForeignAddress/FAWarnMsg";
import ALWarnMsg from "./AddressLine/ALWarnMsg";
import showALWarning from "./AddressLine/showALWarning";
import showValidationErr from "./FormFieldValidation/showValidationErr";
import displayModal from "./Modal/displayModal";
import displayConfirmationModal from "./Modal/displayConfirmationModal";
import modalMessage from "./Modal/modalMessage";
import modalHeading from "./Modal/modalHeading";
import totalCartItems from "./UserAccount/totalCartItems";
import draftData from "./UserAccount/draftData";
import totalRPItems from "./UserAccount/totalRPItems";
import startLoader from "./Loader/startLoader";
import nonReviewedData from "./NonReviewedData/nonReviewedData";
import selectedPayerId from "./NonReviewedData/selectedPayerId";
import rpRecipientData from "./NonReviewedData/rpRecipientData";
import selectedRecData from "./NonReviewedData/selectedRecData";
import noDefault from "./NonReviewedData/noDefault";
import formTypes from "./NonReviewedData/formTypes";
import bulkActionItems from "./NonReviewedData/bulkActionItems";
import dataToEdit from "./NonReviewedData/dataToEdit";
import cartData from "./Cart/cartData";
import truthyCartValues from "./Cart/truthyCartValues";
import cartPageSelectedRec from "./Cart/cartPageSelectedRec";
import totalCartValues from "./Cart/totalCartValues";
import toRemoveAllRecForPayer from "./Cart/toRemoveAllRecForPayer";
import recipientsWithoutEmailId from "./Cart/recipientsWithoutEmailId";
import toCallEmailRequiredModal from "./Cart/toCallEmailRequiredModal";
import toSelectAllRecForPayer from "./Cart/toSelectAllRecForPayer";
import cartPageBulkAction from "./Cart/cartPageBulkActions";
import totalRecsWithoutStateFiling from "./Cart/totalRecsWithoutStateFiling";
import reviewedFormIds from "./FormReview/reviewedFormsIds";
import toMaskTin from "./Tin/toMaskTin";
import formHistoryCartIds from "./FormHistory/formHistoryCartIds";
import isAdminAuthenticated from "./AdminAuth/isAdminAuthenticated";
import isRecipientAuthenticated from "./OnlineAccessAuth/isRecipientAuthenticated";
import correctionSubmissionType from "./CorrectionRelated/correctionSubmissionType.reducer";
import displayPdfViewerModal from "./Modal/displayPdfViewerModal.reducer";
import displayHammenu from "./HamburgerMenu/displayHammenu";
import showInvalidTinError from "./FormFieldValidation/showInvalidTinError";
import displayPayerAssignmentModal from "./Modal/displayPayerAssignmentModal";
import displayPayerUnassignmentModal from "./Modal/displayPayerUnassignmentModal";
import displayManualStaffAddingModal from "./Modal/displayManualStaffAddingModal";
import displayViewGroupsModal from "./Modal/displayGroupViewingModal";
import displayManualGroupCreatingModal from "./Modal/displayManualGroupCreatingModal";
import displayAddStaffsToGroupModal from "./Modal/displayAddStaffsToGroupModal";
import displayRemoveStaffsFromGroupModal from "./Modal/displayRemoveStaffsFromGroupModal";
import displayViewAssignedPayersModal from "./Modal/displayViewAssignedPayersModal";
import displayViewAssignedStaffsModal from "./Modal/displayViewAssignedStaffsModal";
import displayAddBulkStaffModal from "./Modal/displayAddBulkStaffModal";
import displayBulkCreateGroupModal from "./Modal/displayBulkCreateGroupModal";

const rootReducer = combineReducers({
  themeChange,
  isAuthenticated,
  displayPopup,
  popupMessage,
  popupStatus,
  setPayerNames,
  payerTinDisplay,
  recTinDisplay,
  toDisableScreen,
  zipcode,
  phoneDisplay,
  recipientNames,
  clearDataStatus,
  stateAmount,
  showFAWarning,
  FAWarnMsg,
  ALWarnMsg,
  showALWarning,
  showValidationErr,
  displayModal,
  displayConfirmationModal,
  modalMessage,
  modalHeading,
  totalCartItems,
  draftData,
  totalRPItems,
  startLoader,
  nonReviewedData,
  selectedPayerId,
  rpRecipientData,
  selectedRecData,
  noDefault,
  formTypes,
  bulkActionItems,
  dataToEdit,
  cartData,
  truthyCartValues,
  cartPageSelectedRec,
  totalCartValues,
  toSelectAllRecForPayer,
  toRemoveAllRecForPayer,
  recipientsWithoutEmailId,
  toCallEmailRequiredModal,
  cartPageBulkAction,
  totalRecsWithoutStateFiling,
  reviewedFormIds,
  toMaskTin,
  formHistoryCartIds,
  isAdminAuthenticated,
  isRecipientAuthenticated,
  correctionSubmissionType,
  displayPdfViewerModal,
  displayHammenu,
  showInvalidTinError,
  displayPayerAssignmentModal,
  displayPayerUnassignmentModal,
  displayManualStaffAddingModal,
  displayViewGroupsModal,
  displayManualGroupCreatingModal,
  displayAddStaffsToGroupModal,
  displayRemoveStaffsFromGroupModal,
  displayViewAssignedPayersModal,
  displayViewAssignedStaffsModal,
  displayAddBulkStaffModal,
  displayBulkCreateGroupModal,
});

export default rootReducer;
