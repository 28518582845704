import { SET_SELECTED_REC_DATA } from "../../actions/action.types";

const initialState = {
  selectedRecRecord: {},
  selectedRecRecordId: 0,
  selectedRecRecordIdx: 0,
};

const selectedRecData = (state = initialState, action: any) => {
  if (action.type === SET_SELECTED_REC_DATA) {
    if (action.payload !== undefined) {
      return {
        ...state,
        selectedRecRecord: action.payload.recData[action.payload.selectedRecordIdx],
        selectedRecRecordId: action.payload.selectedRecordId,
        selectedRecRecordIdx: action.payload.selectedRecordIdx,
      };
    }
    return state;
  }
  return state;
};

export default selectedRecData;
