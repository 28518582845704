export default function formatNum(numstr: string) {
  const numstrs = [];
  let counter = 0;
  for (let i = numstr.length - 1; i >= 0; i--) {
    counter++;
    numstrs.push(numstr[i]);
    if (counter % 3 === 0 && i !== 0) {
      counter = 0;
      numstrs.push(",");
    }
  }
  let valstr = "";
  for (let i = numstrs.length - 1; i >= 0; i--) {
    valstr += numstrs[i];
  }

  return valstr;
}
