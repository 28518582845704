import removeTruthyCartValues from "../StateSetters/removeTruthyCartValues";
import setTruthyCartValues from "../StateSetters/setTruthyCartValues";
import { STATE_FILING } from "../constants";

export default class TruthyCartValues {
  static cartValues = {};

  static remove(array: any, searchQuery: any, removeOccurence: any) {
    const idx = array.indexOf(searchQuery);
    array.splice(idx, removeOccurence);
    return array;
  }

  static setTruthyCartVals = (
    cartId: any | null,
    payerId: any | null,
    cartValType: any | null,
    stateSetter: any | null,
    stateFilingOptions?: any
  ) => {
    let valObj = {
      cartValType: cartValType,
      cartId: cartId,
      payerId: payerId,
      occurence: 1,
    };
    if (cartValType === STATE_FILING && stateFilingOptions) {
      console.log("STATEFILING_OPTIONS:");
      console.log(stateFilingOptions);
      const { state1, state2 } = stateFilingOptions;
      if (state1 && state2) {
        console.log("Two states present");
        valObj = {
          ...valObj,
          occurence: 2,
        };
      }
    }
    setTruthyCartValues(valObj, stateSetter);
  };

  static removeTruthyCartVals = (
    cartId: string,
    payerId: string,
    cartValType: string,
    stateSetter: any
  ) => {
    const valObj = {
      cartValType: cartValType,
      cartId: cartId,
      payerId: payerId,
      occurence: 1,
    };
    removeTruthyCartValues(valObj, stateSetter);
  };
}

export class StateFiling {
  static filteredCartIdsForStatefiling: string[] = [];
  static currentpayerId: string;
  static state1: string;
  static state2: string;
}
