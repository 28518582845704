import { TO_DISPLAY_GROUP_VIEWING_MODAL } from "../../actions/action.types";

export interface ViewGroupsModalAction {
  type: string;
  payload: {
    toDisplay: boolean;
    staffId: string;
  };
}

const initialState: { toDisplay: boolean; staffId: string } = {
  toDisplay: false,
  staffId: "",
};

const displayViewGroupsModal = (state = initialState, action: ViewGroupsModalAction) => {
  if (action.type === TO_DISPLAY_GROUP_VIEWING_MODAL) return action.payload;
  return state;
};

export default displayViewGroupsModal;
