import {
  CorrectionCode2Data,
  ExtraCorrectionCode1Data,
  FileCorrectionMidwareArgs,
} from "../../../interfaces/main";
import SessionStorage from "../LocalCaching/SessionStorage";
import {
  CORRECTION_INITIAL,
  CORRECTION_VOID,
  FORM1099_B,
  FORM1099_DIV,
  FORM1099_INT,
  FORM1099_K,
  FORM1099_S,
} from "../constants";
import { noCorrectionRegisteredError } from "../languagePacks/en-us";
import devLogInstance from "../loggerConfig";

export default class Middleware {
  public static fileCorrection(
    options: FileCorrectionMidwareArgs,
    callback: (err?: any, arg?: any) => void
  ) {
    const {
      isCorrection,
      correctionSubmissionType,
      correctionCode1Details,
      correctionCode2Details,
      formType,
    } = options;

    let initial: any[] = [];
    let final: any[] = [];

    if (!isCorrection) {
      callback();
      return;
    }

    if (!correctionSubmissionType)
      return callback(new Error("No correction type provided").message);
    // For void correction type submit the data without monitoring any change
    // Since the payment amount gets edited during void, the correction code should
    // be 1
    else if (correctionSubmissionType.includes("void")) return callback(null, "one");

    // Compare correction code 1 details
    devLogInstance.log("Getting the correction code 2 data from cache...");
    const originalData: CorrectionCode2Data = SessionStorage.getCacheItem(CORRECTION_INITIAL);
    devLogInstance.log(originalData);

    initial = [
      originalData.recipient_address_line1,
      originalData.recipient_address_line2,
      originalData.recipient_city,
      originalData.recipient_country,
      originalData.recipient_email,
      originalData.recipient_is_foreign_address,
      originalData.recipient_name,
      originalData.recipient_name_cont,
      originalData.recipient_phone_number,
      originalData.recipient_phone_number_extn,
      originalData.recipient_state,
      originalData.recipient_tin,
      originalData.recipient_tin_type,
      originalData.recipient_zipcode,
    ];
    final = [
      correctionCode2Details.recipient_address_line1,
      correctionCode2Details.recipient_address_line2,
      correctionCode2Details.recipient_city,
      correctionCode2Details.recipient_country,
      correctionCode2Details.recipient_email,
      correctionCode2Details.recipient_is_foreign_address,
      correctionCode2Details.recipient_name,
      correctionCode2Details.recipient_name_cont,
      correctionCode2Details.recipient_phone_number,
      correctionCode2Details.recipient_phone_number_extn,
      correctionCode2Details.recipient_state,
      correctionCode2Details.recipient_tin,
      correctionCode2Details.recipient_tin_type,
      correctionCode2Details.recipient_zipcode,
    ];

    devLogInstance.log("Comparing code 2 data for change...");
    const isCode2DataSame = this.compare(initial, final);
    devLogInstance.log("Finished comparing code 2 data..");
    // if (isCode1DataSame) {
    //   callback(new Error("No correction registered in code 1 data!").message);
    //   return;
    // }
    // devLogInstance.log("Change in code 1 data detected !");

    // Compare correction code 2 details
    devLogInstance.log("Getting correction code 1 data from cache...");
    const originalData1: ExtraCorrectionCode1Data = SessionStorage.getCacheItem(CORRECTION_INITIAL);
    devLogInstance.log(originalData1);

    initial = [
      originalData1.payment_amount1,
      originalData1.payment_amount2,
      originalData1.payment_amount3,
      originalData1.payment_amount4,
      originalData1.payment_amount5,
      originalData1.payment_amount6,
      originalData1.payment_amount7,
      originalData1.payment_amount8,
      originalData1.payment_amount9,
      originalData1.payment_amountA,
      originalData1.payment_amountB,
      originalData1.payment_amountC,
      originalData1.payment_amountD,
      originalData1.payment_amountE,
      originalData1.payment_amountF,
      originalData1.fatca_filing_requirement_indicator,
    ];
    final = [
      correctionCode1Details.payment_amount1 * 100,
      correctionCode1Details.payment_amount2 * 100,
      correctionCode1Details.payment_amount3 * 100,
      correctionCode1Details.payment_amount4 * 100,
      correctionCode1Details.payment_amount5 * 100,
      correctionCode1Details.payment_amount6 * 100,
      correctionCode1Details.payment_amount7 * 100,
      correctionCode1Details.payment_amount8 * 100,
      correctionCode1Details.payment_amount9 * 100,
      correctionCode1Details.payment_amountA * 100,
      correctionCode1Details.payment_amountB * 100,
      correctionCode1Details.payment_amountC * 100,
      correctionCode1Details.payment_amountD * 100,
      correctionCode1Details.payment_amountE * 100,
      correctionCode1Details.payment_amountF * 100,
      correctionCode1Details.fatca_filing_requirement_indicator,
    ];

    // Include the extra form specific code 1 data
    if (formType === FORM1099_INT) {
      initial = [
        ...initial,
        originalData1.payers_rtn,
        originalData1.cusip_Number,
        originalData1.foreign_country_or_us_Possession,
      ];

      final = [
        ...final,
        correctionCode1Details.payers_rtn,
        correctionCode1Details.cusip_Number,
        correctionCode1Details.foreign_country_or_us_Possession,
      ];
    } else if (formType === FORM1099_DIV) {
      initial = [...initial, originalData1.foreign_country_or_us_Possession];

      final = [...final, correctionCode1Details.foreign_country_or_us_Possession];
    } else if (formType === FORM1099_S) {
      initial = [
        ...initial,
        originalData1.address_or_legal_description,
        originalData1.property_or_services_indicator,
        originalData1.foreign_transferor,
      ];

      final = [
        ...final,
        correctionCode1Details.address_or_legal_description,
        correctionCode1Details.property_or_services_indicator,
        correctionCode1Details.foreign_transferor,
      ];
    } else if (formType === FORM1099_B) {
      initial = [
        ...initial,
        originalData1.date_acquired,
        originalData1.date_sold_or_disposed,
        originalData1.type_of_gain_or_loss_indicator,
      ];

      final = [
        ...final,
        correctionCode1Details.date_acquired,
        correctionCode1Details.date_sold_or_disposed,
        correctionCode1Details.type_of_gain_or_loss_indicator,
      ];
    } else if (formType === FORM1099_K) {
      initial = [
        ...initial,
        originalData1.merchant_category_code,
        originalData1.number_of_payment_transactions,
        originalData1.type_of_payment_indicator,
        originalData1.type_of_filer_indicator,
        originalData1.p_s_e_name,
        originalData1.p_s_e_name_cont,
        originalData1.p_s_e_phone_number,
      ];

      final = [
        ...final,
        correctionCode1Details.merchant_category_code,
        correctionCode1Details.number_of_payment_transactions,
        correctionCode1Details.type_of_payment_indicator,
        correctionCode1Details.type_of_filer_indicator,
        correctionCode1Details.p_s_e_name,
        correctionCode1Details.p_s_e_name_cont,
        correctionCode1Details.p_s_e_phone_number,
      ];
    }

    devLogInstance.log("Comparing code 1 data for change...");
    const isCode1DataSame = this.compare(initial, final);
    devLogInstance.log("Finished comparing code 1 data..");

    // Finalize the result
    if (isCode1DataSame && isCode2DataSame) {
      callback(new Error(noCorrectionRegisteredError).message);
      return;
    }

    if ((!isCode1DataSame && !isCode2DataSame) || !isCode2DataSame) {
      devLogInstance.log("Change detected !");
      const correctionCode = "two";
      callback(null, correctionCode);
      return;
    } else if (!isCode1DataSame) {
      devLogInstance.log("Change detected in code 1 data !");
      const correctionCode = "one";
      callback(null, correctionCode);
      return;
    }

    callback();
  }

  private static compare(arr1: any[], arr2: any[]) {
    console.log(arr1, arr2);

    let status = true;
    let i = 0;
    while (i < arr1.length) {
      if (arr1[i] !== arr2[i]) {
        status = false;
        break;
      }
      i++;
    }
    return status;
  }
}
