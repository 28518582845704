import {
  RESET_TOTAL_CART_VALUES,
  SET_TOTAL_ONLINE_ACCESS,
  SET_TOTAL_POSTAL_MAILING,
  SET_TOTAL_STATE_FILING,
} from "../../actions/action.types";

const initialState = {
  totalPM: 0,
  totalOA: 0,
  totalSF: 0,
};

const totalCartValues = (state = initialState, action: any) => {
  if (action.type === SET_TOTAL_POSTAL_MAILING) {
    const occurence = action.payload;
    const totalPM = state.totalPM + occurence;
    return { ...state, totalPM: totalPM };
  } else if (action.type === SET_TOTAL_ONLINE_ACCESS) {
    const occurence = action.payload;
    const totalOA = state.totalOA + occurence;
    return { ...state, totalOA: totalOA };
  } else if (action.type === SET_TOTAL_STATE_FILING) {
    const occurence = action.payload;
    const totalSF = state.totalSF + occurence;
    return { ...state, totalSF: totalSF };
  }
  if (action.type === RESET_TOTAL_CART_VALUES) {
    return {
      totalPM: 0,
      totalOA: 0,
      totalSF: 0,
    };
  }
  return state;
};

export default totalCartValues;
