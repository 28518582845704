import { axiosInstance } from "../axiosConfig";
import { calCurrentTaxYear } from "../calculateTaxYear";

export default async function getAllHistoryData(year = calCurrentTaxYear()) {
  try {
    const { data } = await axiosInstance.post("/get_all_history_data", {
      year: year,
    });
    return data;
  } catch (err) {
    throw err;
  }
}
