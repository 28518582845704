import { Dispatch } from "@reduxjs/toolkit";
import { TO_DISPLAY_PAYER_UNASSIGNMENT_MODAL } from "../../../redux/actions/action.types";
import { PayerUnassignmentModalActionProps } from "../../../redux/reducers/Modal/displayPayerUnassignmentModal";
import { GroupRecord, PayerUnderStaffOrGroup, StaffRecord } from "../../../../interfaces/main";

interface PayerUnassignmentState {
  toDisplay: boolean;
  isStaff: boolean;
  staffOrGroupId: string;
  assignedPayers: PayerUnderStaffOrGroup[];
  updateStaffRecords: React.Dispatch<React.SetStateAction<StaffRecord[]>> | null;
  updateGroupRecords: React.Dispatch<React.SetStateAction<GroupRecord[]>> | null;
  staffName?: string;
  staffEmail?: string;
  groupName?: string;
}

export default function displayPayerUnassignmentModal(
  unassignmentStateData: PayerUnassignmentState,
  dispatch: Dispatch<PayerUnassignmentModalActionProps>
) {
  dispatch({
    type: TO_DISPLAY_PAYER_UNASSIGNMENT_MODAL,
    payload: {
      status: unassignmentStateData.toDisplay,
      isStaff: unassignmentStateData.isStaff,
      staffOrGroupId: unassignmentStateData.staffOrGroupId,
      assignedPayers: unassignmentStateData.assignedPayers,
      updateStaffRecords: unassignmentStateData.updateStaffRecords,
      updateGroupRecords: unassignmentStateData.updateGroupRecords,
      staffName: unassignmentStateData.staffName,
      staffEmail: unassignmentStateData.staffEmail,
      groupName: unassignmentStateData.groupName,
    },
  });
}
