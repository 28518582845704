import React from "react";
import Navbar from "../components/navbar";
import { LANDING_PAGE } from "../lib/constants";
import { TiArrowRightThick } from "react-icons/ti";
import { Link, useNavigate } from "react-router-dom";
import { REGISTER } from "../lib/routes";
import Footer from "../components/Footer/Footer";
import { features } from "./WhyChooseUs";
import Card from "../components/decorations/Card/Card";
import AdBanner from "../components/decorations/AdBanner";
import { landingPagePricingText } from "../lib/languagePacks/en-us";
import scrollToTop from "../lib/utils/scrollToTop";
import { Ga4EventParams } from "../../interfaces/main";
import { ga4 } from "../App";
import ga4Evts from "../lib/json/AnalyticsRelated/analyticsConst.json";

const sendGa4Event = ({ category, action }: Ga4EventParams) => {
  ga4.sendEvent({
    category,
    action,
  });
};

export default function LandingPage() {
  const navigate = useNavigate();

  scrollToTop();
  // useEffect(() => {
  //   return () => {
  //     scrollToTop();
  //   };
  // }, []);

  return (
    <div className="page">
      <Navbar page={LANDING_PAGE} />
      <AdBanner
        adtext={landingPagePricingText}
        hyperlink={
          <Link
            className="text-taxeve-primary-violet underline underline-offset-1"
            to={`${REGISTER}?from_efile_banner_ad=true`}
          >
            E-File Now!
          </Link>
        }
      />
      <section className="banner px-4 lg:px-40">
        <div className="nested-div-in-banner py-16">
          <div className="banner-info w-[100%] lg:w-[50%]">
            <h1 className="text-4xl px-10 font-semibold text-left">
              E-file IRS Forms{" "}
              <span className="text-yellow-500">1099-NEC, 1099-Misc, 1099-Series</span>
            </h1>
            <ul className="py-4">
              <div className="flex items-center gap-4 px-10 py-2">
                <div className="text-3xl text-green-400">
                  <TiArrowRightThick />
                </div>
                <p className="text-xl text-left font-semibold">
                  E-filing of Federal and State Form 1099
                </p>
              </div>

              <div className="flex items-center gap-4 px-10 py-2">
                <div className="text-3xl text-green-400">
                  <TiArrowRightThick />
                </div>
                <p className="text-xl text-left font-semibold">
                  Supports edelivery to your recipients
                </p>
              </div>

              <div className="flex items-center gap-4 px-10 py-2">
                <div className="text-3xl text-green-400">
                  <TiArrowRightThick />
                </div>
                <p className="text-xl text-left font-semibold">
                  Provide print and mailing services to your recipients
                </p>
              </div>

              <div className="flex items-center gap-4 px-10 py-2">
                <div className="text-3xl text-green-400">
                  <TiArrowRightThick />
                </div>
                <p className="text-xl text-left font-semibold">Easy Excel Integration</p>
              </div>
            </ul>

            <button
              className="py-2 px-6 font-semibold rounded-lg bg-yellow-500 hover:bg-yellow-300 text-[1.25rem] text-black"
              onClick={() => {
                sendGa4Event({
                  category: ga4Evts.event.category.clickEvent,
                  action: ga4Evts.event.action.clickedOnEfileBtn,
                });
                navigate(REGISTER);
              }}
            >
              E-File Now!
            </button>
          </div>
          <div className="w-[100%] lg:w-[50%]">
            <iframe
              title="Tutorial"
              src="https://www.youtube.com/embed/xfc6O5Jo0uY"
              className="w-[100%] h-[100%] border-2 border-white rounded-lg"
            />
          </div>
        </div>
      </section>
      <section className="features-section py-12">
        <div className="features-heading">
          <h1 className="text-center text-4xl">Why choose TaxEve?</h1>
        </div>
        <div className="features flex flex-wrap justify-center gap-4 py-10">
          {features.map((featureObj, i) => {
            return (
              <Card
                key={i}
                heading={featureObj.heading}
                description={featureObj.description}
                icons={featureObj.icon}
                width="w-[24rem]"
              />
            );
          })}
        </div>
      </section>
      <Footer />
    </div>
  );
}
