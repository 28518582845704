import React from "react";
import {
  PayerUnderStaffOrGroup,
  StaffRecord,
  ViewAssignedPayersActionPayload,
} from "../../../../interfaces/main";
import { FaMinusSquare } from "react-icons/fa";
import { TbDots } from "react-icons/tb";
import StaffRelatedActionPopup from "./StaffRelatedActionPopup";
import displayViewAssignedPayersModal from "../../../lib/StateSetters/StaffAndGroupRelated/displayViewAssignedPayersModal";
import { useDispatch } from "react-redux";

interface Props extends React.HTMLProps<HTMLElement> {
  staffRecords: StaffRecord[];
  setStaffRecords: React.Dispatch<React.SetStateAction<StaffRecord[]>>;
  handleSingleStaffSelection: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleAllStaffSelection: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedStaffIds: string[];
  deselectAllStaff: () => void;
  clickedOnActionWithId?: (e: React.MouseEvent<any>) => void;
  actionId?: string;
  insideModal?: boolean;
}

export default function StaffTable(props: Props) {
  const dispatch = useDispatch();

  const viewAssignedPayers = (assignedPayers: PayerUnderStaffOrGroup[], staffName: string) => {
    const payload: ViewAssignedPayersActionPayload = {
      toDisplay: true,
      assignedPayers,
      staffOrGroupName: staffName,
      isStaff: true,
    };

    displayViewAssignedPayersModal(payload, dispatch);
  };

  return (
    <div className={`${props.className}`}>
      <table>
        <thead>
          <tr>
            <th className="text-center relative">
              {props.selectedStaffIds.length > 0 &&
              props.staffRecords.length !== props.selectedStaffIds.length ? (
                <FaMinusSquare
                  id="semi-checked-icon"
                  className="absolute left-7 top-3 text-blue-600"
                  onClick={props.deselectAllStaff}
                />
              ) : (
                <input
                  type="checkbox"
                  name="All Staff Selector"
                  id="select-all-staff"
                  onChange={props.handleAllStaffSelection}
                  checked={props.staffRecords.length === props.selectedStaffIds.length}
                />
              )}
            </th>
            <th className="text-center">Name</th>
            <th className="text-center">Email</th>
            <th className="text-center">Invitation Status</th>
            {!props.insideModal && <th className="text-center">Total Business' Assigned</th>}
            {!props.insideModal && <th className="text-center">Actions</th>}
          </tr>
        </thead>
        <tbody>
          {props.staffRecords.map((staff) => {
            return (
              <tr key={staff._id}>
                <td className="text-center py-3">
                  <input
                    type="checkbox"
                    name="Staff Selector"
                    id={staff._id}
                    onChange={props.handleSingleStaffSelection}
                    checked={props.selectedStaffIds.includes(staff._id)}
                  />
                </td>
                <td className="text-center">
                  <p>{staff.staff_name}</p>
                </td>
                <td className="text-center">
                  <p>{staff.staff_email}</p>
                </td>
                <td className="text-center">
                  <p
                    className={`${
                      staff.is_invitation_accepted ? "text-green-400" : "text-yellow-500"
                    }`}
                  >
                    {staff.is_invitation_accepted ? "Accepted" : "Pending"}
                  </p>
                </td>
                {!props.insideModal && (
                  <td className="text-center">
                    <div className="flex items-center justify-center">
                      <button
                        className="bg-[#7E30E1] text-white text-md font-semibold rounded-full w-2 h-2 p-4 flex items-center justify-center"
                        onClick={() => viewAssignedPayers(staff.payer_ids, staff.staff_name)}
                      >
                        {staff.payer_ids.length}
                      </button>
                    </div>
                  </td>
                )}
                {!props.insideModal && (
                  <td className="relative">
                    <h1
                      className="flex justify-center action-icon-wrapper"
                      id={staff._id}
                      onClick={props.clickedOnActionWithId}
                    >
                      <TbDots id={staff._id} className="cursor-pointer action-icon" />
                    </h1>
                    {props.actionId?.includes(staff._id) && (
                      <StaffRelatedActionPopup
                        staffId={staff._id}
                        staffName={staff.staff_name}
                        staffEmail={staff.staff_email}
                        assignedPayers={staff.payer_ids}
                        setStaffRecords={props.setStaffRecords}
                      />
                    )}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
