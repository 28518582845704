import React from "react";
import Bulkaction from "./BulkAction/BulkAction";
import PayerDropDown from "./PayerDropDown/PayerDropDown";
import Table from "./Table/Table";
import YearSelector from "../../YearSelector";

interface Props {
  year: { label: number; value: number };
  handleYearChange: (option: any) => void;
}

export default function ReviewTable(props: Props) {
  return (
    <div>
      <div
        style={{
          height: "10rem",
          width: "20rem",
          padding: "2rem 0 0",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <YearSelector {...props} className="p-4" />
          <PayerDropDown />
          <Table />
        </div>
        <Bulkaction />
      </div>
    </div>
  );
}
