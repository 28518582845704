import React from "react";
import Navbar from "../components/navbar";
import TaxEveImg from "../components/TaxEveImg";
import LoginForm from "../components/LoginForm";
import { useLayoutEffect, useState } from "react";
import EmailVerification from "../components/EmailVerification";
import { useDispatch } from "react-redux";
import "../styles/LoginRegisterPage.css";
import { LOGIN_PAGE } from "../lib/constants";
import checkAuth from "../lib/authRequests/checkAuth";

export default function Login() {
  const [responseStatus, setResponseStatus] = useState(null);
  const [input, setInput] = useState({
    email: "",
    name: "",
    password: "",
    phoneNo: "",
  });
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    checkAuth(dispatch);
  }, [dispatch]);
  return (
    <main>
      <Navbar page={LOGIN_PAGE} />
      <div className="min-h-[100vh] py-20 px-4 lg:px-16 flex flex-col justify-center">
        <section className="flex justify-between mt-12">
          <TaxEveImg />
          {responseStatus === 401 ? (
            <EmailVerification responseType="emailActivation" userEmail={input.email} />
          ) : (
            <LoginForm input={input} setInput={setInput} responseStatus={setResponseStatus} />
          )}
        </section>
      </div>
    </main>
  );
}
