import React from "react";
import { MdHistory } from "react-icons/md";
import { HISTORY_ICON } from "../../../lib/constants";
import { HISTORY_PAGE } from "../../../lib/routes";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./HistoryNav.css";

// const onHistoryPage = (currentRoute) => {
//   if (currentRoute === HISTORY_PAGE) return true;
//   return false;
// };

interface Props {
  currentRoute: string;
}

export default function HistoryNav({ currentRoute }: Props) {
  // const style = useSelector((state: any) => state.themeChange);
  return (
    <div>
      <Link
        className={`py-2 px-6 rounded-3xl font-bold bg-[#b262d163] border-2 border-[#b262d1c5]`}
        style={{
          height: "100%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.5rem",
        }}
        to={HISTORY_PAGE}
      >
        <MdHistory
          id={HISTORY_ICON}
          className="text-taxeve-primary-violet"
          style={{
            fontSize: "1.85rem",
            cursor: "pointer",
          }}
        />
        <span className="text-taxeve-primary-violet">Order History</span>
      </Link>
    </div>
  );
}
