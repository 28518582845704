import { SET_PHONE_DISPLAY, PAYER_PHONE, REC_PHONE } from "../../../../redux/actions/action.types";
import formatPhonenum from "../formatPhone";

export default function updatePhoneDisplay(phoneNum: any, type: any, handleUpdate: any) {
  const formattedNum = formatPhonenum(phoneNum.toString());
  if (type === PAYER_PHONE) {
    handleUpdate({
      type: SET_PHONE_DISPLAY,
      phoneType: PAYER_PHONE,
      payload: formattedNum,
    });
  } else if (type === REC_PHONE) {
    handleUpdate({
      type: SET_PHONE_DISPLAY,
      phoneType: REC_PHONE,
      payload: formattedNum,
    });
  }
}
