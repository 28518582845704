import React from "react";
import "../styles/Form1099.css";
import IndividualForm from "./IndividualForm";
import { form1099 } from "../lib/languagePacks/en-us";

interface Props extends React.HTMLAttributes<HTMLElement> {
  openModal: React.Dispatch<React.SetStateAction<boolean>>;
  setFormType: React.Dispatch<
    React.SetStateAction<{ title: string; description: string; filingDueDate: string }>
  >;
  isAdBannerPresent: boolean;
}

export default function HomeAllFormsContainer({
  openModal,
  setFormType,
  isAdBannerPresent,
}: Props) {
  return (
    <div
      className={`form1099-main-container ${
        isAdBannerPresent ? "py-[10.5rem]" : "py-[8.5rem]"
      } px-4 sm:px-[8rem]`}
    >
      <div>
        <h3>{form1099.main.heading}</h3>
        <div className="forms-container">
          {form1099.forms.map((form, i) => {
            return (
              <IndividualForm
                key={form.id}
                title={form.title}
                description={form.description}
                filingDueDate={form.filingDueDate}
                filingText={form1099.filingText}
                currentFormId={i + 1}
                totalForms={form1099.forms.length}
                openModal={openModal}
                setFormType={setFormType}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
