import { SET_REVIEWED } from "../../redux/actions/action.types";

class Review {
  markReviewedLocally() {}

  markUnreviewedLocally(stateHandler: any, selectedRecordIdx: any) {
    stateHandler({
      type: SET_REVIEWED,
      payload: {
        cidx: selectedRecordIdx,
        status: false,
      },
    });
  }
}

const review = new Review();

export default review;
