import { axiosInstance } from "../axiosConfig";

export default async function changeFullname(newFullname: string) {
  try {
    const { data } = await axiosInstance.post("/change_full_name", {
      new_full_name: newFullname,
    });
    return data;
  } catch (err) {
    throw err;
  }
}
