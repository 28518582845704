import { axiosInstance } from "../axiosConfig";

export default async function formReview(cartIds: any[]) {
  try {
    const { data } = await axiosInstance.post("/review_form", {
      cart_id_array: cartIds,
    });
    return data;
  } catch (err) {
    throw err;
  }
}
