/* SNS --> Service not supported */

import React from "react";

interface Props {
  message: string;
}

export default function SNSModalBody({ message }: Props) {
  return (
    <div className="px-10 py-6">
      <h2>{message}</h2>
    </div>
  );
}
