export class CustomArray {
  /*
        Remove any ith element from an array. 
        This method returns the updated array after removing the element
    */
  static removeElement(pos: number, array: any[]) {
    const updatedArray = array.filter((val, idx) => {
      return idx !== pos;
    });
    return updatedArray;
  }
}
