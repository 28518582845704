import { SET_REVIEWED, SET_RP_RECIPIENT_DATA } from "../../actions/action.types";

const rpRecipientData = (state: any[] = [], action: any) => {
  if (action.type === SET_RP_RECIPIENT_DATA) {
    const newState: any[] = [];
    action.payload.forEach((obj: any) => {
      newState.push({ ...obj, reviewed: false, checked: false });
    });
    return newState;
  } else if (action.type === SET_REVIEWED && state.length !== 0) {
    const updatedState: any[] = [];
    state.forEach((obj, i) => {
      if (i === action.payload.cidx) {
        updatedState.push({ ...obj, reviewed: action.payload.status });
      } else {
        updatedState.push(obj);
      }
    });
    return updatedState;
  }
  return state;
};

export default rpRecipientData;
