import { AES } from "crypto-js";
import devLogInstance from "../loggerConfig";

export default function encrypt(dataString: string | null) {
  const secretKey = process.env.REACT_APP_AES_SECRET_KEY;
  if (secretKey && dataString) {
    const encryptedData = AES.encrypt(dataString, secretKey);
    return encryptedData;
  }
  devLogInstance.warn("No secret key found in the environment variable or no datastring provided");
  return "";
}
