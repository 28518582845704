import React, { useEffect } from "react";
import "../../styles/forms/Form.css";
import PayerDetails from "./GlobalFormComponents/PayerDetails";
import RecipientDetails from "./GlobalFormComponents/RecipientDetails";
import RecAccount from "./GlobalFormComponents/RecAccount";
import { nec1099AmountDisplayTexts, stateInfoDisplayTexts } from "../../lib/languagePacks/en-us";
import { useSelector } from "react-redux";
import { useState } from "react";
import logger from "../../logger";
import { useDispatch } from "react-redux";
import FormDetail from "./GlobalFormComponents/InternalComponents/FormDetail";
import handleAmountFormat from "../../lib/form/utils/handleAmountFormat";
import StateInfo from "./GlobalFormComponents/StateInfo";
import {
  AMOUNT_1,
  AMOUNT_4,
  CORRECTION_INITIAL,
  DEFAULT_NON_FOREIGN_COUNTRY,
  ERROR_POPUP,
  NEC_1099,
  PAGE_E_FILE,
} from "../../lib/constants";
import SubmitButton from "../SubmitButton/SubmitButton";
import formSubmissionHelper from "../../lib/FormSubmission/formSubmissionHelper";
import AmountContainer from "./GlobalFormComponents/AmountContainer/AmountContainer";
import AmountHeading from "./GlobalFormComponents/AmountContainer/AmountHeading/AmountHeading";
import Amount from "./GlobalFormComponents/AmountContainer/AmountInput/Amount";
import PayerDirectSales from "./GlobalFormComponents/InternalComponents/PayerDirectSales/PayerDirectSales";
import { useNavigate } from "react-router-dom";
import UpdateButton from "../UpdateButton/UpdateButton";
import { isValidAmount } from "./Form1099-B";
import CorrectionSubmissionType from "./GlobalFormComponents/CorrectionRelated/CorrectionSubmissionType";
import Middleware from "../../lib/utils/Middleware";
import handlePopup from "../../lib/popup/handlePopup";
import devLogInstance from "../../lib/loggerConfig";
import SessionStorage from "../../lib/LocalCaching/SessionStorage";

export default function Form1099NEC({
  formData,
  setFormData,
  formType,
  taxYear,
  isEditable,
  isCorrection,
  historyId,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const correctionSubmissionType = useSelector((state: any) => state.correctionSubmissionType);

  const [amount, setAmount] = useState({
    amount1: "",
    amount4: "",
  }); // for displaying on the input field

  // Set the amount values
  useEffect(() => {
    setAmount({
      amount1: formData.payment_amount1
        ? handleAmountFormat((formData.payment_amount1 / 100).toString())
        : "",
      amount4: formData.payment_amount4
        ? handleAmountFormat((formData.payment_amount4 / 100).toString())
        : "",
    });

    return () => {
      setAmount({
        amount1: "",
        amount4: "",
      });
    };
  }, [formData.payment_amount1, formData.payment_amount4]);

  // Handle amount input change
  const handleChange = (e: any) => {
    const { value, id } = e.target;
    const formattedAmount = handleAmountFormat(value);
    switch (id) {
      case AMOUNT_1:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount1: formattedAmount,
          });
        break;

      case AMOUNT_4:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount4: formattedAmount,
          });
        break;

      case "direct-sales":
        setFormData({
          ...formData,
          direct_sales_indicator: !formData.direct_sales_indicator,
        });
        break;

      default:
        break;
    }
  };

  // Handle form submit
  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    const { id: submitType } = e.target;

    let data = {
      ...formData,
      payer_country: !formData.payer_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.payer_country,
      recipient_country: !formData.recipient_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.recipient_country,
      form_type: formType,
      payment_amount1: amount.amount1 ? parseFloat(amount.amount1.replace(/,/gi, "")) : 0,
      payment_amount4: amount.amount4 ? parseFloat(amount.amount4.replace(/,/gi, "")) : 0,
    };
    logger.log(data);

    const incomeAmounts = [data.payment_amount1];

    // Correction filing middleware
    Middleware.fileCorrection(
      {
        isCorrection: isCorrection,
        correctionSubmissionType: correctionSubmissionType,
        correctionCode1Details: data,
        correctionCode2Details: data,
        formType: data.form_type,
      },
      (err, correctionCode) => {
        if (err) {
          console.error(err);
          handlePopup(err, ERROR_POPUP, dispatch);
          return;
        }

        if (correctionCode) {
          data = {
            ...data,
            file_submission_type: correctionSubmissionType,
            correction_type: correctionCode,
            payer_is_new_or_updated: false,
            history_id: historyId,
          };
          devLogInstance.log("Data before sumbitting correction");
          devLogInstance.log(data);
        }

        devLogInstance.log("Removing cached intial data...");
        SessionStorage.removeCacheItem(CORRECTION_INITIAL);
        devLogInstance.log("Cache removed!");

        formSubmissionHelper(
          data,
          [data.payment_amount1, data.payment_amount4],
          data.payment_amount4,
          incomeAmounts,
          setFormData,
          setAmount,
          dispatch,
          submitType,
          navigate,
          PAGE_E_FILE
        );
      }
    );

    // formSubmissionHelper(
    //   data,
    //   [data.payment_amount1, data.payment_amount4],
    //   data.payment_amount4,
    //   incomeAmounts,
    //   setFormData,
    //   setAmount,
    //   dispatch,
    //   submitType,
    //   navigate,
    //   PAGE_E_FILE
    // );
  };

  return (
    <>
      <form className="form-main-container">
        {isCorrection && <CorrectionSubmissionType />}
        <div
          className="form-wrapper"
          style={{
            border: `2px solid ${style.eFormBorderColor}`,
          }}
        >
          <div className="form-details-amount-wrapper">
            <div className="left-portion-details">
              <PayerDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                isCorrection={isCorrection}
              />
              <RecipientDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                formType={formType}
                isCorrection={isCorrection}
              />
              <RecAccount globalFormData={formData} setGlobalFormData={setFormData} />
            </div>
            <div
              className="right-portion-details"
              style={{
                overflow: "hidden",
                borderBottom: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <div className="top-section">
                <div
                  className="amount-container blank"
                  style={{
                    background: "#F5F5F5",
                    width: "20rem",
                    borderTop: `1px solid ${style.eFormBorderColor}`,
                  }}
                />
                <FormDetail formType={formType} taxYear={taxYear} formName={NEC_1099} />
              </div>
              <AmountContainer
                class_="nonemployee-compensation"
                style={{
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  width: "100%",
                }}
              >
                <AmountHeading
                  heading={nec1099AmountDisplayTexts.compensationHeading}
                  amountNum={"1"}
                />
                <Amount
                  type="text"
                  id={AMOUNT_1}
                  placeHolder="0.00"
                  class_="payer-details-input"
                  value={amount.amount1}
                  onChangeHandler={handleChange}
                />
              </AmountContainer>

              <PayerDirectSales
                status={formData.direct_sales_indicator}
                onChangeHandler={handleChange}
              />

              <div
                className="blank amount-container"
                style={{
                  height: "13vh",
                  // borderTop: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  background: "#F5F5F5",
                  width: "100%",
                }}
              >
                <h4>3.</h4>
              </div>

              <AmountContainer
                class_="nonemployee-compensation" // Only one class can be passed
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                  width: "100%",
                }}
              >
                <AmountHeading
                  heading={nec1099AmountDisplayTexts.incomeTaxWithheldDisplayText}
                  amountNum="4"
                />
                <Amount
                  type="text"
                  id={AMOUNT_4}
                  placeHolder="0.00"
                  class_="payer-details-input" // Only one class can be passed
                  value={amount.amount4}
                  onChangeHandler={handleChange}
                />
              </AmountContainer>

              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                }}
              />
            </div>
          </div>
        </div>
        <StateInfo
          state1Heading={stateInfoDisplayTexts.nec1099.state1.heading}
          state2Heading={stateInfoDisplayTexts.nec1099.state2.heading}
          taxWithheldLabel={stateInfoDisplayTexts.nec1099.stateTaxWithheldLabel}
          stateVolLabel={stateInfoDisplayTexts.nec1099.stateLabel}
          stateNoLabel={stateInfoDisplayTexts.nec1099.stateNoLabel}
          stateIncomeLabel={stateInfoDisplayTexts.nec1099.stateIncLabel}
          globalFormData={formData}
          setGlobalFormData={setFormData}
          isCorrection={isCorrection}
        />
      </form>
      {!isEditable ? (
        <SubmitButton handleSubmit={handleFormSubmit} isCorrection={isCorrection} />
      ) : (
        <UpdateButton handleUpdate={handleFormSubmit} />
      )}
    </>
  );
}
