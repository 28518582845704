import { markReviewed } from "../../components/forms/ReviewForm/Edit-Next/EditNextBtn";
// import logger from "../../logger";
import { CLEAR_BULK_ACTION_ITEMS, SET_BULK_ACTION_ITEMS } from "../../redux/actions/action.types";
import Handler from "../Handlers/main";
import callEmailRequiredModal from "../StateSetters/callEmailRequiredModal";
import setCartPageBulkActionPayload from "../StateSetters/setCartPageBulkActionPayload";
import {
  CP_BULK_DISABLE_ONLINE_ACCESS,
  CP_BULK_DISABLE_POSTAL_MAILING,
  CP_BULK_DISABLE_STATE_FILING,
  CP_BULK_ENABLE_ONLINE_ACCESS,
  CP_BULK_ENABLE_POSTAL_MAILING,
  CP_BULK_ENABLE_STATE_FILING,
  ONLINE_ACCESS,
  POSTAL_MAILING,
  STATE_FILING,
} from "../constants";
import review from "./Review";
// import { getTheFileName } from "../../components/forms/ReviewTable/BulkAction/BulkAction.component";
// import DisableScreen from "./disableScreen";
// import devLogInstance from "../loggerConfig";
import { Action } from "./Action";
import devLogInstance from "../loggerConfig";
import DisableScreen from "./disableScreen";
import setReviewedFormIds from "../StateSetters/setReviewedFormIds";
import removeReviewedFormIds from "../StateSetters/removeReviewedFormIds";

export class BulkAction extends Action {
  static getCartIds(cartIds: string[]) {
    console.log(cartIds);
    // Arrange the cart ids in required format
    const arrangedCartIds = cartIds.map((cartId) => {
      return { _id: cartId };
    });
    return arrangedCartIds;
  }

  markAsChecked(
    stateHandler: any,
    formType: string,
    checkedRecordIdx: number,
    checkedRecord: any,
    formIdx: number
  ) {
    stateHandler({
      type: SET_BULK_ACTION_ITEMS,
      payload: {
        formType: formType,
        idx: checkedRecordIdx,
        checkedRecord: checkedRecord,
        isChecked: true,
        formIdx: formIdx,
      },
    });
  }

  markAsUnchecked(
    stateHandler: any,
    formType: string,
    checkedRecordIdx: number,
    checkedRecord: any,
    formIdx: number
  ) {
    stateHandler({
      type: SET_BULK_ACTION_ITEMS,
      payload: {
        formType: formType,
        idx: checkedRecordIdx,
        checkedRecord: checkedRecord,
        isChecked: false,
        formIdx: formIdx,
      },
    });
  }

  clearBulkActionItems(stateHandler: any) {
    stateHandler({
      type: CLEAR_BULK_ACTION_ITEMS,
      payload: [],
    });
  }

  bulkReviewLocally(selectedRecordIdxs: any[], stateHandler: any) {
    selectedRecordIdxs.forEach((idx) => {
      markReviewed(stateHandler, idx);
    });
  }

  bulkUnreviewLocally(selectedRecordIdxs: any[], stateHandler: any) {
    selectedRecordIdxs.forEach((idx) => {
      review.markUnreviewedLocally(stateHandler, idx);
    });
  }

  bulkEnableCartValues(
    e: any,
    bulkActionType: string,
    filteredCartIds?: string[],
    recsToExclude?: any[]
  ) {
    console.log("RECS TO EXCLUDE: ");
    console.log(recsToExclude);
    if (filteredCartIds == null) {
      Handler.handleCartCheckboxUpdate(
        e,
        null,
        true,
        BulkAction.cartIds,
        null,
        BulkAction.stateSetter
      ).then(() => {
        setCartPageBulkActionPayload(BulkAction.stateSetter, bulkActionType);
      });
    } else {
      Handler.handleCartCheckboxUpdate(
        e,
        null,
        true,
        filteredCartIds,
        null,
        BulkAction.stateSetter
      ).then(() => {
        setCartPageBulkActionPayload(BulkAction.stateSetter, bulkActionType, recsToExclude);
        // Display the email required pop up
      });
    }
  }

  bulkDisableCartValues(e: any, bulkActionType: string) {
    Handler.handleCartCheckboxUpdate(
      e,
      null,
      false,
      BulkAction.cartIds,
      null,
      BulkAction.stateSetter
    ).then(() => {
      setCartPageBulkActionPayload(BulkAction.stateSetter, bulkActionType);
    });
  }

  // callEmailRequiredPopup() {
  //   const options = {
  //     dataArray: this.recsWithoutEmailId,
  //     payerId: null,
  //     cartIds: BulkAction.cartIds,
  //     toCall: true,
  //   };
  //   callEmailRequiredModal(BulkAction.stateSetter, options);
  // }
}

const Bulkaction = new BulkAction();
export default Bulkaction;

class PostalMailingBulkAction extends BulkAction {
  e: any;
  constructor() {
    super();
    this.e = {
      target: {
        parentElement: {
          id: POSTAL_MAILING,
        },
      },
    };
  }
}

class StateFilingBulkAction extends BulkAction {
  e: any;
  constructor() {
    super();
    this.e = {
      target: {
        parentElement: {
          id: STATE_FILING,
        },
      },
    };
  }
}

class OnlineAccessBulkAction extends BulkAction {
  e: any;
  constructor() {
    super();
    this.e = {
      target: {
        parentElement: {
          id: ONLINE_ACCESS,
        },
      },
    };
  }
}

export class BulkEnablePostalMailing extends PostalMailingBulkAction {
  // totalSelectedCartIds = [];
  //   stateSetter;
  bulkActionType = CP_BULK_ENABLE_POSTAL_MAILING;
  constructor() {
    super();
    this.bulkEnablePostalMailing();
  }

  bulkEnablePostalMailing() {
    this.bulkEnableCartValues(this.e, this.bulkActionType);
  }
}

export class BulkDisablePostalMailing extends PostalMailingBulkAction {
  //   totalSelectedCartIds = [];
  //   stateSetter;
  bulkActionType = CP_BULK_DISABLE_POSTAL_MAILING;
  constructor() {
    super();
    this.bulkDisablePostalMailing();
  }

  bulkDisablePostalMailing() {
    this.bulkDisableCartValues(this.e, this.bulkActionType);
  }
}

export class BulkEnableStateFiling extends StateFilingBulkAction {
  bulkActionType = CP_BULK_ENABLE_STATE_FILING;
  constructor() {
    super();
    this.bulkEnableStateFiling();
  }

  bulkEnableStateFiling() {
    this.bulkEnableCartValues(this.e, this.bulkActionType);
  }
}

export class BulkDisableStateFiling extends StateFilingBulkAction {
  bulkActionType = CP_BULK_DISABLE_STATE_FILING;
  constructor() {
    super();
    this.bulkDisableStateFiling();
  }

  bulkDisableStateFiling() {
    this.bulkDisableCartValues(this.e, this.bulkActionType);
  }
}

export class BulkEnableOnlineAccess extends OnlineAccessBulkAction {
  recsWithoutEmailId;
  selectedRecipientCartIds: string[] = [];
  bulkActionType = CP_BULK_ENABLE_ONLINE_ACCESS;
  constructor(selectedRecCartIds: string[], recsWithoutEmailId: any[]) {
    super();
    this.recsWithoutEmailId = recsWithoutEmailId;
    this.selectedRecipientCartIds = selectedRecCartIds;
    this.takeAction();
  }

  takeAction() {
    if (this.areRecipientsWithoutEmail()) {
      this.bulkEnableOnlineAccessForChosenRecs();
    } else {
      this.bulkEnableOnlineAccess();
    }
  }

  areRecipientsWithoutEmail() {
    return this.recsWithoutEmailId.length !== 0;
  }

  bulkEnableOnlineAccess() {
    this.bulkEnableCartValues(this.e, this.bulkActionType);
  }

  bulkEnableOnlineAccessForChosenRecs() {
    const recWithoutEmailCartIds = this.recsWithoutEmailId.map((obj) => {
      return obj.cartId;
    });
    console.log(recWithoutEmailCartIds);
    const filteredCartIds: any[] = [];
    this.selectedRecipientCartIds.forEach((cartId) => {
      !recWithoutEmailCartIds.includes(cartId) && filteredCartIds.push({ _id: cartId });
    });
    devLogInstance.log("FILETERED CART IDS INITIALLY: ");
    devLogInstance.log(filteredCartIds);
    this.bulkEnableCartValues(this.e, this.bulkActionType, filteredCartIds, recWithoutEmailCartIds);
  }

  callEmailRequiredPopup() {
    const options = {
      dataArray: this.recsWithoutEmailId,
      payerId: null,
      cartIds: BulkAction.cartIds,
      toCall: true,
    };
    callEmailRequiredModal(BulkAction.stateSetter, options);
  }
}

export class BulkDisableOnlineAccess extends OnlineAccessBulkAction {
  bulkActionType = CP_BULK_DISABLE_ONLINE_ACCESS;
  constructor() {
    super();
    this.bulkDisableOnlineAccess();
  }

  bulkDisableOnlineAccess() {
    this.bulkDisableCartValues(this.e, this.bulkActionType);
  }
}

export class FormUnreview {
  static selectedRecordIdxs: any[] = [];
}

export class BulkUnreviewForm extends BulkAction {
  constructor(selectedRecordIdxs: any[]) {
    super();
    this.bulkUnreview(selectedRecordIdxs);
  }
  bulkUnreview(selectedRecordIdxs: any[]) {
    DisableScreen.enable();
    Handler.handleFormUnreview(BulkAction.cartIds, BulkAction.stateSetter).then((res) => {
      DisableScreen.disable();
      devLogInstance.log("RESPONSE AFTER UNREVIEWING FORM:");
      devLogInstance.log(res);
      removeReviewedFormIds(BulkAction.stateSetter, res.successfulIdsArray);
      this.bulkUnreviewLocally(selectedRecordIdxs, BulkAction.stateSetter);
    });
  }
}

export class FormReview extends FormUnreview {}

export class BulkReviewForm extends BulkAction {
  constructor(selectedRecordIdxs: any[]) {
    super();
    this.bulkReview(selectedRecordIdxs);
  }
  bulkReview(selectedRecordIdxs: any) {
    DisableScreen.enable();
    Handler.handleFormReview(BulkAction.cartIds, BulkAction.stateSetter).then((res) => {
      devLogInstance.log("RESPONSE AFTER REVIEWING FORM:");
      devLogInstance.log(res);
      DisableScreen.disable();
      if (selectedRecordIdxs.length > 0) {
        setReviewedFormIds(BulkAction.stateSetter, res.successfulIdsArray);
        this.bulkReviewLocally(selectedRecordIdxs, BulkAction.stateSetter);
        return;
      }
      window.location.reload();
    });
  }
}
