import React from "react";
import { useDispatch, useSelector } from "react-redux";
import maskTin from "../../../lib/StateSetters/maskTin";
import { TIN_MASKING_CONTAINER } from "../../../lib/constants";
import "./TinMaskContainer.css";

export default function TinMaskContainer() {
  const toMaskTin = useSelector((state: any) => state.toMaskTin);
  const dispatch = useDispatch();

  const handleChange = (e: any) => {
    const { id: type } = e.target;
    switch (type) {
      case TIN_MASKING_CONTAINER:
        if (toMaskTin) {
          // Unmask tin by changing the state
          maskTin(false, dispatch);
        } else {
          // Mask tin by changing the state
          maskTin(true, dispatch);
        }
        break;

      default:
        break;
    }
  };
  return (
    <div className="tin-mask-container">
      <label className="switch">
        <input
          id={TIN_MASKING_CONTAINER}
          type="checkbox"
          checked={toMaskTin}
          onChange={handleChange}
        />
        <span className="slider round" />
      </label>
      {toMaskTin ? "Click to unmask TIN" : "Click to mask TIN"}
    </div>
  );
}
