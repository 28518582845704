import React, { useState, useLayoutEffect, useCallback, useEffect } from "react";
import Navbar from "../components/navbar";
import Select from "react-select";
import ReactSelectOptions from "../lib/utils/ReactSelectOptions";
import { FormCorrectionSummary, PayerData } from "../../interfaces/main";
import SelectStyle from "../lib/ReactSelectTheme/reactSelectStyle";
import { useSelector } from "react-redux";
import Handler from "../lib/Handlers/main";
import devLogInstance from "../lib/loggerConfig";
import FormTable from "../components/forms/FormTable/FormTable";
import TinMaskContainer from "../components/decorations/TinMaskContainer/TinMaskContainer";
import { useParams } from "react-router-dom";
import { calCurrentTaxYear } from "../lib/calculateTaxYear";

export default function Correction1099() {
  const style = useSelector((state: any) => state.themeChange);
  const [payerDataArray, setPayerDataArray] = useState<PayerData[]>([]);
  const [payer, setPayer] = useState<{ label: string; value: null | PayerData }>({
    label: "",
    value: null,
  });
  const [formCorrectionSummary, setFormCorrectionSummary] = useState<FormCorrectionSummary[]>([]);
  const [forms, setForms] = useState<{ startIdx: number; endIdx: number; formType: string }[]>([]);
  const { taxYear } = useParams();

  // Get the payer names
  useLayoutEffect(() => {
    Handler.handleGettingPayerNames()
      .then((res) => {
        setPayerDataArray(res.payer_names);
      })
      .catch((err) => {
        // Error will be handle by the error handler
      });
  }, []);

  // Select default payer
  const setDefaultPayer = useCallback(() => {
    const valObj = {
      label: payerDataArray[0]?.payer_name,
      value: payerDataArray[0],
    };
    setPayer(valObj);
  }, [payerDataArray]);

  // Set the default payer
  useEffect(() => {
    setDefaultPayer();
    return () => {
      setPayer({
        label: "",
        value: null,
      });
    };
  }, [setDefaultPayer]);

  // Get the form summary for the selected payer
  useEffect(() => {
    devLogInstance.log("Payer selected: ");
    devLogInstance.log(payer);

    payer.value?._id &&
      Handler.handleGetFormSummaryForCorrection(
        payer.value?._id,
        taxYear || calCurrentTaxYear().toString()
      )
        .then((res) => {
          setFormCorrectionSummary(res.form_summary);
        })
        .catch((err) => {
          // Error will be handled by error handlers
        });

    return () => {
      setFormCorrectionSummary([]);
    };
  }, [payer, taxYear]);

  // Filter the form correction summary data and set each form type start and end idxs' whenever the dependency changes
  useEffect(() => {
    if (formCorrectionSummary.length > 0) {
      let initialFormType = formCorrectionSummary[0].form_type; // Initial value
      let startIdx = 0; // Initial value
      const forms: { startIdx: number; endIdx: number; formType: string }[] = [];

      formCorrectionSummary.forEach((form, i) => {
        const currentFormType = form.form_type;
        if (currentFormType !== initialFormType) {
          const obj = {
            formType: initialFormType,
            startIdx: startIdx,
            endIdx: i - 1,
          };
          forms.push(obj);
          startIdx = i;
          initialFormType = currentFormType;
        }
        if (i === formCorrectionSummary.length - 1) {
          const obj = {
            formType: currentFormType,
            startIdx: startIdx,
            endIdx: i,
          };
          forms.push(obj);
          initialFormType = "";
          startIdx = 0;
        }
      });
      setForms(forms);
    }

    return () => {
      setForms([]);
    };
  }, [formCorrectionSummary]);

  // Payer Change handler
  const handlePayerChange = (option: any) => {
    // Handle the change
    // Cache the selected payer
    // SessionStorage.setCacheItem("payer_id", option);

    // Set the current payer
    setPayer({
      label: option.label,
      value: option.value,
    });
  };

  return (
    <main className="relative">
      <Navbar />
      <div className="min-h-[100vh] pt-20">
        <section className="filter-panel">
          <div className="pt-12 px-12 w-[30%] flex flex-col gap-1">
            <label className="label">Payer</label>
            <Select
              value={payer}
              options={ReactSelectOptions.payerOptions(payerDataArray)} // Use the payer data array to set the payer select options
              theme={new SelectStyle(style).customTheme}
              styles={new SelectStyle(style).customStyle}
              onChange={handlePayerChange}
              isSearchable={false}
              isDisabled={forms.length === 0}
            />
          </div>
        </section>
        <section>
          <div className="warning px-12 py-8 font-bold flex items-center gap-4">
            You can only correct or void a 1099 form only if IRS accepted it.
          </div>
        </section>
        <section>
          {/* Form table */}
          {forms.map((formObj, i) => {
            return (
              <FormTable
                key={i}
                form={formObj}
                allFormData={formCorrectionSummary}
                tableId={i}
                onCorrectionPage={true}
                taxYear={taxYear}
              />
            );
          })}
          {/* Tin mask switch */}
          <div className="tin-mask-container-wrapper" style={{ padding: "3rem" }}>
            <TinMaskContainer />
          </div>
        </section>
      </div>
    </main>
  );
}
