import React from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import displayManualStaffAddingModal from "../../../../lib/StateSetters/StaffAndGroupRelated/displayManualStaffAddingModal";
import { useDispatch, useSelector } from "react-redux";
import { AddStaffModalActionPayload } from "../../../../redux/reducers/Modal/displayManualStaffAddingModal";
import AddStaffForm from "../../../BusinessSettingsRelated/StaffRelated/AddStaffForm";

export default function ManualStaffAddingModal() {
  const dispatch = useDispatch();

  const addStaffActionProps: AddStaffModalActionPayload = useSelector(
    (state: any) => state.displayManualStaffAddingModal
  );

  // Close the modal
  const closeModal = () => {
    displayManualStaffAddingModal({ toDisplay: false, handleAddingStaff: null }, dispatch);
  };

  return (
    <div className="form-modal shadow-lg">
      <div className="form-modal-header bg-taxeve-primary-violet text-white flex items-center justify-between">
        <h2>Manually Add Member</h2>
        <IoIosCloseCircleOutline className="text-3xl cursor-pointer" onClick={closeModal} />
      </div>
      <div className="form-modal-body bg-white p-4">
        <AddStaffForm handleAddingStaff={addStaffActionProps.handleAddingStaff} />
      </div>
    </div>
  );
}
