export const arrangeStates = (states: any) => {
  const updatedList = states.map((state: any) => {
    return {
      label: `${state.state} (${state.code})`,
      value: state.state,
    };
  });
  return updatedList;
};

export const arrangeCountries = (countries: any) => {
  const updatedList = countries.map((country: any) => {
    return {
      label: `${country.country} (${country.code})`,
      value: country.code,
    };
  });
  return updatedList;
};
