import { MASK_TIN } from "../../actions/action.types";

const toMaskTin = (state = true, action: any) => {
  const type = action.type;
  if (type === MASK_TIN) {
    return action.payload;
  }
  return state;
};

export default toMaskTin;
