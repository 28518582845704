import { axiosInstance } from "../../axiosConfig";

export default async function sendPasswordResetVerifyCode(email: string) {
  try {
    const { data } = await axiosInstance.post("/forgot_password/verification_code", {
      email: email,
    });
    return data;
  } catch (err) {
    throw err;
  }
}
