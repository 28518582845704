import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ReviewForm from "../components/forms/ReviewForm/ReviewForm";
import Navbar from "../components/navbar";
import loader from "../components/decorations/Loader";
import { useEffect, useState } from "react";
import decryptCartIds from "../lib/Cart/decryptCartIds";
import Handler from "../lib/Handlers/main";
import ReviewTable from "../components/forms/ReviewTable/ReviewTable";
import selectDefaultPayer from "../lib/utils/selectDefaultPayer";
import filterNRFforRecData from "../lib/utils/filterNRFforRecData";
import setRPRecipientData from "../lib/StateSetters/setRPRecipientData";
import { TO_START_LOADER } from "../redux/actions/action.types";
import globalFormData from "../lib/globalFormData";
import setNonReviewedData from "../lib/StateSetters/setNonReviewedData";
import selectCurrentPayer from "../lib/utils/selectCurrentPayer";
import setNoDefault from "../lib/utils/setNoDefault";
import { REVIEW_PAGE_EFORM } from "../lib/constants";
import devLogInstance from "../lib/loggerConfig";
import { useLocation } from "react-router-dom";
import saveReviewPageType from "../lib/SaveData/saveReviewPageType";
import deleteReviewPageType from "../lib/DeleteDataLocally/deleteReviewPageType";
import deleteCartIds from "../lib/DeleteDataLocally/deleteCartIds";
import maskTin from "../lib/StateSetters/maskTin";
import { useInactive, useYear } from "../lib/customHooks";

export default function Review({ showFeedbackWindow: feedbackWindowObj }: any) {
  const location = useLocation();
  const isIntdReviewPage = new URLSearchParams(location.search).get("eform") === "true";
  const startLoader = useSelector((state: any) => state.startLoader);
  const nonReviewedData = useSelector((state: any) => state.nonReviewedData);
  const selectedPayerId = useSelector((state: any) => state.selectedPayerId);
  const style = useSelector((state: any) => state.themeChange);
  const [formData, setFormData] = useState<any | null>(null);
  // const [initialTime, setInitialTime] = useState(0);
  const [payerId, setPayerId] = useState<string | null>(null);
  const selectedRecData = useSelector((state: any) => state.selectedRecData);
  const dispatch = useDispatch();
  const handleInactivity = useInactive();

  const { year, handleYearChange } = useYear();

  // Log the feedback window object
  devLogInstance.log(feedbackWindowObj);

  // logger.log(recData);
  // logger.log(`Initial Time -> ${initialTime}`);

  // Check if payer id already exists in ls
  useEffect(() => {
    const payerId_ = localStorage.getItem("payer_id");
    payerId_
      ? devLogInstance.log("PAYER ID EXISTS...UPDATING STATE")
      : devLogInstance.log("PAYER_ID DOSENT EXIST!");
    payerId_ && setPayerId(payerId_);
  }, []);

  // Get the non reviewed form data
  useEffect(() => {
    const cartIds = isIntdReviewPage ? localStorage.getItem("cart_ids") : null;
    // Store the current status of the review page type for proper redirection from the e filing page
    if (isIntdReviewPage) {
      // Save the review page type
      saveReviewPageType(REVIEW_PAGE_EFORM);
    } else {
      // Delete the review page type
      deleteReviewPageType();
      // Delete any stored cart ids
      deleteCartIds();
    }
    let decryptedCartIds = [];
    if (cartIds) {
      // Get the decrypted cart ids
      decryptedCartIds = decryptCartIds(cartIds);
      devLogInstance.log("Decrypted Cart ids:");
      devLogInstance.log(decryptedCartIds);
    }
    // Handle getting the non reviewed forms
    Handler.handleGettingSelectedNRF(dispatch, decryptedCartIds, year.value);
    return () => {
      setNonReviewedData(dispatch, []);
    };
  }, [dispatch, isIntdReviewPage, year]);

  // Select the first payer by default if no payer id is present in localstorage or select the current payer
  useEffect(() => {
    if (nonReviewedData.length !== 0 && payerId === null) {
      selectDefaultPayer(nonReviewedData, dispatch);
    } else if (nonReviewedData.length !== 0 && payerId !== null) {
      selectCurrentPayer(payerId, nonReviewedData, dispatch);
    }
  }, [nonReviewedData, dispatch, payerId]);

  // Set the recipient data as per the selected payer id
  useEffect(() => {
    if (nonReviewedData.length !== 0 && selectedPayerId.length !== 0) {
      const recData_ = filterNRFforRecData(nonReviewedData, selectedPayerId);
      recData_ && setRPRecipientData(recData_, dispatch);
      // Stop the loader
      dispatch({
        type: TO_START_LOADER,
        payload: false,
      });
    }
    //setInitialTime(new Date().getSeconds()); // Set the initial time in seconds after the first load
    return () => {
      setRPRecipientData([], dispatch);
      setNoDefault(dispatch, false); // On each render, so that the first rec gets selected
    };
  }, [selectedPayerId, nonReviewedData, dispatch]);

  // Update the global form data when a recipient record is selected
  useEffect(() => {
    if (selectedRecData) {
      const updatedData = selectedRecData.selectedRecRecord;
      setFormData({
        ...globalFormData,
        ...updatedData,
      });
    }
  }, [selectedRecData]);

  // Revert to default mask tin state when this page component is destroyed
  useEffect(() => {
    return () => {
      maskTin(true, dispatch);
    };
  }, [dispatch]);

  return !startLoader ? (
    <div
      style={{ position: "relative", height: "100vh" }}
      onMouseMove={handleInactivity}
      onKeyDown={handleInactivity}
    >
      <Navbar />
      <div
        style={{
          paddingTop: "5rem",
          display: "flex",
          height: "100vh",
        }}
      >
        <ReviewTable year={year} handleYearChange={handleYearChange} />
        <ReviewForm formType={formData?.form_type} taxYear={formData?.year} formData={formData} />
      </div>
    </div>
  ) : (
    loader.start(style)
  );
}
