import React from "react";
import { form1099 } from "../lib/languagePacks/en-us";
// import { useSelector } from "react-redux";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FILE_UPLOAD } from "../lib/routes";
import ga4Const from "../lib/json/AnalyticsRelated/analyticsConst.json";
import { ga4 } from "../App";
import { useInProgress } from "../lib/customHooks";

interface Props {
  formType: any;
  filingYear: number;
}

export default function FileUploadCard({ formType, filingYear }: Props) {
  // const style = useSelector((state: any) => state.themeChange);
  const [isHovered, setIshovered] = useState(false);
  const { inProgress } = useInProgress();

  const handleClick = () => {
    ga4.sendEvent({
      category: ga4Const.event.category["e-filing"],
      action: `${ga4Const.event.action.fileUpload} (${formType.title} for ${filingYear})`,
      label: "File by uploading excel/csv",
    });
  };

  return (
    <Link
      onClick={!inProgress.inProgress ? handleClick : () => {}}
      to={
        !inProgress.inProgress
          ? `${FILE_UPLOAD}/${formType.title.split(" ")[1]}/select-payer/${filingYear}`
          : ""
      }
      className="card w-[100%] lg:w-[30rem]"
    >
      {!inProgress.inProgress ? (
        <>
          <div className="card-heading">{form1099.createForm.fileUpload.heading}</div>
          <div className="card-description">
            {form1099.createForm.fileUpload.description_pt1} {formType.title}{" "}
            {form1099.createForm.fileUpload.description_pt2}
          </div>

          <div className="file-btn-wrapper start-filing-btn-wrapper" style={{ margin: "2rem 0 0" }}>
            <div className="form-icon w-[16%]">
              {/* <i className="fa-solid fa-file-csv" /> */}
              <img src="/Assets/excel.svg" alt="Excel" className="w-[100%]" />
            </div>
            <button
              className={`file-btn bg-taxeve-primary-violet text-white rounded-lg ${
                isHovered && "shadow-lg shadow-taxeve-primary-violet"
              }`}
              onMouseOver={() => {
                setIshovered(true);
              }}
              onMouseLeave={() => {
                setIshovered(false);
              }}
            >
              {form1099.createForm.fileUpload.startBtnText}
            </button>
          </div>
        </>
      ) : (
        <h2 className="h-full text-center text-slate-400 flex items-center">
          A task is already in progress, you can upload excel/csv again once the current task is
          complete
        </h2>
      )}
    </Link>
  );
}
