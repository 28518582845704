import { AES } from "crypto-js";
import CryptoJS from "crypto-js";
import devLogInstance from "../loggerConfig";

export default function decrypt(encryptedData: string) {
  const secretKey = process.env.REACT_APP_AES_SECRET_KEY;
  if (secretKey) {
    const decryptedData = AES.decrypt(encryptedData, secretKey);
    return decryptedData.toString(CryptoJS.enc.Utf8);
  } else {
    devLogInstance.warn("Cannot decrypt! No secret key provided");
    return "";
  }
}
