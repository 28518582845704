import React from "react";
import { CardComponentProps } from "../../../../interfaces/main";

export default function Card({
  heading,
  description,
  buttons,
  icons,
  width,
  className,
}: CardComponentProps) {
  return (
    <div
      className={`custom-card relative overflow-hidden ${
        width ? width : ""
      } transition-all ${className}`}
    >
      {icons && (
        <div className="absolute left-0 top-0 -z-0 opacity-[0.08] w-[100%] h-[100%] flex items-center justify-center">
          {icons}
        </div>
      )}
      <div className="card-header">
        <h1>{heading}</h1>
        <div className="flex justify-center py-2">
          <hr className="border-2 border-taxeve-primary-violet w-16" />
        </div>
      </div>

      <div className="card-desc text-lg text-slate-500">
        <p>{description}</p>
      </div>

      {buttons && (
        <div className="btn-wrapper py-4 flex justify-center gap-6">
          {buttons?.map((button) => {
            return button;
          })}
        </div>
      )}
    </div>
  );
}
