import { SET_RECIPIENTS_WITHOUT_STATE_FILING_FOR_CURRENT_PAYER } from "../../actions/action.types";

const totalRecsWithoutStateFiling = (state: any[] = [], action: any) => {
  const type = action.type;
  // let totalRecsWithoutStatefiling = 0;
  if (type === SET_RECIPIENTS_WITHOUT_STATE_FILING_FOR_CURRENT_PAYER) {
    const noStateFiling = action.payload.noStateFiling;
    const payerId = action.payload.payerId;
    if (noStateFiling) {
      if (!state[payerId]) {
        state[payerId] = { totalRecs: 1 };
        return [...state];
      } else {
        state[payerId] = { totalRecs: state[payerId]?.totalRecs + 1 };
        return [...state];
      }
    } else {
      if (!state[payerId]) {
        state[payerId] = { totalRecs: 0 };
        return [...state];
      } else {
        state[payerId] = {
          totalRecs: !state[payerId]?.totalRecs
            ? state[payerId]?.totalRecs
            : state[payerId]?.totalRecs - 1,
        };
        return [...state];
      }
    }
  }
  return state;
};

export default totalRecsWithoutStateFiling;
