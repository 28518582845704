import { NewAdminData } from "../../../interfaces/main";
import { axiosInstance } from "../axiosConfig";

export default async function addAdmin(newAdminData: NewAdminData) {
  try {
    const { data } = await axiosInstance.post("/admin/add", newAdminData);
    return data;
  } catch (err) {
    throw err;
  }
}
