import React from "react";
import "./PaymentButton.css";
import { useDispatch, useSelector } from "react-redux";
import Handler from "../../../lib/Handlers/main";
import { btnInnerTxts } from "../../../lib/languagePacks/en-us";

interface ComponentProps {
  cartIds: string[];
  isDisabled: boolean;
}

export default function PaymentButton({ cartIds, isDisabled }: ComponentProps) {
  const stateHandler = useDispatch();
  const style = useSelector((state: any) => state.themeChange);
  const handleClick = async () => {
    await Handler.handleOrderCompletion(stateHandler, cartIds);
  };
  return (
    <button
      className="payment-btn"
      onClick={handleClick}
      disabled={isDisabled} // Replace true with 'isDisabled'(referencing the state) when filing starts
      style={{
        background: style.btnSecondaryBg,
        color: style.btnFillFontColor,
        opacity: isDisabled ? "0.5" : "1", // Uncomment when filing starts
        // opacity: "0.5", // Remove when filing starts
      }}
    >
      {btnInnerTxts.completePayment}
    </button>
  );
}
