import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import Form1099NEC from "../components/forms/Form1099-NEC";
import Navbar from "../components/navbar";
import globalFormData from "../lib/globalFormData";
import { useEffect } from "react";
import loader from "../components/decorations/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  CORRECTION_INITIAL,
  FORM1099_B,
  FORM1099_DIV,
  FORM1099_INT,
  FORM1099_K,
  FORM1099_MISC,
  FORM1099_NEC,
  FORM1099_R,
  FORM1099_S,
} from "../lib/constants";
import Form1099MISC from "../components/forms/Form1099-MISC";
import handleInputDisplayClearance from "../lib/form/utils/handleInputDisplayClearance";
import Handler from "../lib/Handlers/main";
import updateTinDisplay from "../lib/form/utils/Display Update/updateTinDisplay";
import updatePhoneDisplay from "../lib/form/utils/Display Update/updatePhoneDisplay";
import {
  PAYER_PHONE,
  REC_PHONE,
  SET_PAYER_NAMES,
  SET_RECIPIENT_NAMES,
} from "../redux/actions/action.types";
import updateZipDisplay from "../lib/form/utils/Display Update/updateZipDisplay";
import setDataToEdit from "../lib/StateSetters/setDataToEdit";
import Form1099INT from "../components/forms/Form1099-INT";
import Form1099DIV from "../components/forms/Form1099-DIV";
import Form1099S from "../components/forms/Form1099-S";
import Form1099B from "../components/forms/Form1099-B";
import Form1099K from "../components/forms/Form1099-K";
import devLogInstance from "../lib/loggerConfig";
import Form1099R from "../components/forms/Form1099-R";
import { HOME } from "../lib/routes";
import SessionStorage from "../lib/LocalCaching/SessionStorage";

export default function Eform({ showFeedbackWindow: feedbackWindowObj }: any) {
  const { formType, taxYear } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const isEditable = new URLSearchParams(location.search).get("edit") === "1";
  const isCorrection = new URLSearchParams(location.search).get("correction") === "true";
  const formId = new URLSearchParams(location.search).get("formId");
  devLogInstance.log(`Is Editable ---> ${isEditable}`);
  const [formData, setFormData] = useState({
    ...globalFormData,
    year: taxYear ? parseInt(taxYear) : "", // update the year on the global data
  });
  devLogInstance.log(formData);
  const dispatch = useDispatch();
  const style = useSelector((state: any) => state.themeChange);
  const startLoader = useSelector((state: any) => state.startLoader);
  const dataToEdit = useSelector((state: any) => state.dataToEdit);
  const [historyId, setHistoryId] = useState("");

  // Log the feed back window object
  devLogInstance.log(feedbackWindowObj);

  // Handle getting payer names
  useEffect(() => {
    Handler.handlePayerNameProcessing(dispatch);
  }, [dispatch]);

  // Remove all input display values
  useEffect(() => {
    handleInputDisplayClearance(dispatch);
  }, [dispatch]);

  // Handle if user came from the review page to edit
  useEffect(() => {
    if (isEditable && dataToEdit !== null) {
      // Get the editable form data from the redux state and the update the local form data state
      setFormData({
        ...dataToEdit,
        payer_is_new_or_updated: false,
        state_income1: dataToEdit.state_income1 ?? 0,
        state_income2: dataToEdit.state_income2 ?? 0,
      });
      // Update payer tin display
      updateTinDisplay(dataToEdit.payer_tin, dataToEdit.payer_tin_type, "payer", dispatch);
      // Update recipient tin display
      updateTinDisplay(
        dataToEdit.recipient_tin,
        dataToEdit.recipient_tin_type,
        "recipient",
        dispatch
      );
      // Update payer phone display
      updatePhoneDisplay(dataToEdit.payer_phone_number, PAYER_PHONE, dispatch);
      // Update recipient phone display
      updatePhoneDisplay(dataToEdit.recipient_phone_number, REC_PHONE, dispatch);
      // Update zip display
      updateZipDisplay(dataToEdit.payer_zipcode, "payer", dispatch);
      // Update recipient display
      updateZipDisplay(dataToEdit.recipient_zipcode, "recipient", dispatch);
    } else if (isEditable && !dataToEdit) {
      navigate(`${HOME}`);
    }
    return () => {
      setDataToEdit(null, dispatch);
      handleInputDisplayClearance(dispatch);
    };
  }, [isEditable, dataToEdit, dispatch, navigate]);

  // Get the form data for correction if isCorrection
  useEffect(() => {
    if (isCorrection && formId) {
      Handler.handleGettingFormCorrectionData(formId)
        .then(([formData, historyId]) => {
          setFormData({
            ...formData,
            payer_is_new_or_updated: false,
          });
          // Update payer tin display
          updateTinDisplay(formData.payer_tin, formData.payer_tin_type, "payer", dispatch);
          // Update recipient tin display
          updateTinDisplay(
            formData.recipient_tin,
            formData.recipient_tin_type,
            "recipient",
            dispatch
          );
          // Update payer phone display
          updatePhoneDisplay(formData.payer_phone_number, PAYER_PHONE, dispatch);
          // Update recipient phone display
          updatePhoneDisplay(formData.recipient_phone_number, REC_PHONE, dispatch);
          // Update zip display
          updateZipDisplay(formData.payer_zipcode, "payer", dispatch);
          // Update recipient display
          updateZipDisplay(formData.recipient_zipcode, "recipient", dispatch);

          // Set the history id
          setHistoryId(historyId);

          // Save the details in session storage for later comparison
          SessionStorage.setCacheItem(CORRECTION_INITIAL, formData);
        })
        .catch((err) => {
          // Error will be handled by the error handler
        });
    } else if (isCorrection && !formId) {
      navigate(HOME);
    }

    return () => {
      setDataToEdit(null, dispatch);
      handleInputDisplayClearance(dispatch);
    };
  }, [isCorrection, formId, dispatch, navigate]);

  // Set th payer and rec name list empty on component destroy
  useEffect(() => {
    return () => {
      dispatch({
        type: SET_PAYER_NAMES,
        payload: [],
      });
      dispatch({
        type: SET_RECIPIENT_NAMES,
        payload: [],
      });
    };
  }, [dispatch]);

  return !startLoader ? (
    <div style={{ position: "relative" }}>
      <Navbar />
      <div className="pt-4">
        {formType === FORM1099_NEC && (
          <Form1099NEC
            formData={formData}
            setFormData={setFormData}
            formType={formType}
            taxYear={taxYear}
            isEditable={isEditable}
            isCorrection={isCorrection}
            historyId={historyId}
          />
        )}
        {formType === FORM1099_MISC && (
          <Form1099MISC
            formData={formData}
            setFormData={setFormData}
            formType={formType}
            taxYear={taxYear}
            isEditable={isEditable}
            isCorrection={isCorrection}
            historyId={historyId}
          />
        )}
        {formType === FORM1099_INT && (
          <Form1099INT
            formData={formData}
            setFormData={setFormData}
            formType={formType}
            taxYear={taxYear}
            isEditable={isEditable}
            isCorrection={isCorrection}
            historyId={historyId}
          />
        )}
        {formType === FORM1099_DIV && (
          <Form1099DIV
            formData={formData}
            setFormData={setFormData}
            formType={formType}
            taxYear={taxYear}
            isEditable={isEditable}
            isCorrection={isCorrection}
            historyId={historyId}
          />
        )}
        {formType === FORM1099_S && (
          <Form1099S
            formData={formData}
            setFormData={setFormData}
            formType={formType}
            taxYear={taxYear}
            isEditable={isEditable}
            isCorrection={isCorrection}
            historyId={historyId}
          />
        )}
        {formType === FORM1099_B && (
          <Form1099B
            formData={formData}
            setFormData={setFormData}
            formType={formType}
            taxYear={taxYear}
            isEditable={isEditable}
            isCorrection={isCorrection}
            historyId={historyId}
          />
        )}
        {formType === FORM1099_R && (
          <Form1099R
            formData={formData}
            setFormData={setFormData}
            formType={formType}
            taxYear={taxYear}
            isEditable={isEditable}
            isCorrection={isCorrection}
            historyId={historyId}
          /> // Form submission not complete. Excluded from MVP
        )}
        {formType === FORM1099_K && (
          <Form1099K
            formData={formData}
            setFormData={setFormData}
            formType={formType}
            taxYear={taxYear}
            isEditable={isEditable}
            isCorrection={isCorrection}
            historyId={historyId}
          />
        )}
      </div>
    </div>
  ) : (
    loader.start(style)
  );
}
