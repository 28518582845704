import { axiosInstance } from "../axiosConfig";

export default async function changePassword(newPass: string, oldPass: string) {
  try {
    const { data } = await axiosInstance.post("/change_password", {
      password: oldPass,
      new_password: newPass,
    });
    return data;
  } catch (err) {
    throw err;
  }
}
