import React from "react";
import "./ConfirmationModal.css";
import {
  BULK_REVIEW_FORM,
  BULK_UNREVIEW_FORM,
  CONTINUE_TO_CART_WITHOUT_REVIEWING_ALL_FORMS,
  DELETE_ACTION_BULK,
  DELETE_ACTION_SINGLE,
  DELETE_GROUP,
  DISABLE_STATE_FILING_BULK,
  DISABLE_STATE_FILING_FOR_PAYER,
  DISABLE_STATE_FILING_SINGLE,
  REMOVE_STAFF,
} from "../../../../lib/UserActions/action";
import ConfirmButtons from "./ConfirmButtons/ConfirmButtons";
import { useDispatch, useSelector } from "react-redux";
// import { useEffect } from "react";
import displayConfirmationModal from "../../../../lib/StateSetters/displayConfirmationModal";
// import { Action } from "../../../../lib/utils/Action";
import {
  BulkAction,
  BulkDisableStateFiling,
  BulkReviewForm,
  BulkUnreviewForm,
  FormReview,
  FormUnreview,
} from "../../../../lib/utils/BulkAction";
import { DisableStateFiling, SingleAction } from "../../../../lib/utils/SingleAction";
import TruthyCartValues, { StateFiling } from "../../../../lib/utils/CartValues";
import Handler from "../../../../lib/Handlers/main";
import { BULK_DELETE_GROUPS, BULK_REMOVE_STAFF, STATE_FILING } from "../../../../lib/constants";
import redirect from "../../../../lib/RoutingMethods/redirect";
import { CART } from "../../../../lib/routes";

export default function ConfirmationModal({ action }: any) {
  const style = useSelector((state: any) => state.themeChange);
  const truthyCartValues = useSelector((state: any) => state.truthyCartValues);

  const confirmModalProps: {
    stateUpdateMethod: React.Dispatch<React.SetStateAction<any>>;
    extraPayloadIds: string[];
  } = useSelector((state: any) => state.displayConfirmationModal);

  // const displayConfirmModal = useSelector((state) => state.displayConfirmationModal);
  const stateSetter = useDispatch();
  // const stateSetter = useDispatch();
  // Handle confirmation
  const handleConfirmation = () => {
    switch (action) {
      case DELETE_ACTION_BULK:
        BulkAction.delete();
        break;

      case DELETE_ACTION_SINGLE:
        SingleAction.delete();
        break;

      case DISABLE_STATE_FILING_FOR_PAYER:
        const e = {
          target: {
            parentElement: {
              id: STATE_FILING,
            },
          },
        };
        console.log(StateFiling.filteredCartIdsForStatefiling, StateFiling.currentpayerId);
        Handler.handleCartCheckboxUpdate(
          e,
          truthyCartValues,
          false,
          StateFiling.filteredCartIdsForStatefiling,
          StateFiling.currentpayerId,
          stateSetter
        ).then(() => {
          StateFiling.filteredCartIdsForStatefiling.forEach((cartId) => {
            TruthyCartValues.removeTruthyCartVals(
              cartId,
              StateFiling.currentpayerId,
              STATE_FILING,
              stateSetter
            );
          });
        });
        break;

      case DISABLE_STATE_FILING_BULK:
        new BulkDisableStateFiling();
        break;

      case DISABLE_STATE_FILING_SINGLE:
        new DisableStateFiling();
        break;

      case BULK_REVIEW_FORM:
        const selectedRecIdxsForReview = FormReview.selectedRecordIdxs;
        new BulkReviewForm(selectedRecIdxsForReview);
        break;

      case BULK_UNREVIEW_FORM:
        const selectedRecIdxsForUnreview = FormUnreview.selectedRecordIdxs;
        new BulkUnreviewForm(selectedRecIdxsForUnreview);
        break;

      case CONTINUE_TO_CART_WITHOUT_REVIEWING_ALL_FORMS:
        redirect(`${CART}`);
        break;

      case REMOVE_STAFF:
      case BULK_REMOVE_STAFF:
        // Do not perform any action if the required values are not present
        if (confirmModalProps.extraPayloadIds && confirmModalProps.stateUpdateMethod) {
          // Handle staff removal
          Handler.handleRemovingStaff(
            confirmModalProps.extraPayloadIds,
            confirmModalProps.stateUpdateMethod
          );
        }
        break;

      case DELETE_GROUP:
      case BULK_DELETE_GROUPS:
        // Do not perform any action if the required values are not present
        if (confirmModalProps.extraPayloadIds && confirmModalProps.stateUpdateMethod) {
          // Handle group removal
          Handler.handleRemovingGroup(
            confirmModalProps.extraPayloadIds,
            confirmModalProps.stateUpdateMethod
          );
        }
        break;

      default:
        break;
    }
  };

  // Close modal when clicked outside
  const handleClick = () => {
    displayConfirmationModal(stateSetter, action, false);
  };

  return (
    <main
      onClick={handleClick}
      className="form-modal confirmation-modal-main"
      style={{ background: style.siteBg }}
    >
      <div
        className="form-modal-header"
        style={{
          background: style.gModalTopPanelBg,
        }}
      >
        <h2 style={{ color: style.btnFillFontColor }}>
          {/* {(action === DELETE_ACTION_BULK || action === DELETE_ACTION_SINGLE) &&
            "Confirm Delete Action"} */}
          Confirm Action
        </h2>
      </div>
      <div className="form-modal-message">
        {action === DELETE_ACTION_BULK && (
          <div>
            <p>You cannot retrieve the selected forms once deleted.</p>
            <p>Are you sure you want to delete the selected forms?</p>
          </div>
        )}
        {action === DELETE_ACTION_SINGLE && (
          <div>
            <p>You cannot retrieve this form once deleted.</p>
            <p>Are you sure you want to delete this form?</p>
          </div>
        )}
        {action === DISABLE_STATE_FILING_FOR_PAYER && (
          <div>
            <p>This will disable state filing for all the recipients under this payer.</p>
            <p>Are you sure you want to disable state filing for this payer?</p>
          </div>
        )}
        {action === DISABLE_STATE_FILING_BULK && (
          <div>
            <p>This will disable state filing for all the selected recipients under every payer.</p>
            <p>Are you sure you want to disable state filing for all the recipients?</p>
          </div>
        )}
        {action === DISABLE_STATE_FILING_SINGLE && (
          <div>
            <p>This will disable state filing for the current recipient.</p>
            <p>Are you sure you want to disable state filing for this recipient?</p>
          </div>
        )}
        {action === BULK_REVIEW_FORM && (
          <div>
            <p>This will review all the selected forms.</p>
            <p>Are you sure you want to review all the selected forms?</p>
          </div>
        )}
        {action === BULK_UNREVIEW_FORM && (
          <div>
            <p>This will unreview all the selected forms.</p>
            <p>Are you sure you want to unreview all the selected forms?</p>
          </div>
        )}
        {action === CONTINUE_TO_CART_WITHOUT_REVIEWING_ALL_FORMS && (
          <div>
            <p>Not all forms are reviewed for the current payer!</p>
            <p>Are you sure you want to continue to the cart page?</p>
          </div>
        )}
        {action === REMOVE_STAFF && (
          <div>
            <p>This will remove the member and any data related to it!</p>
            <p>Are you sure you want to continue?</p>
          </div>
        )}
        {action === BULK_REMOVE_STAFF && (
          <div>
            <p>This will remove all the selected members and any data related to them!</p>
            <p>Are you sure you want to continue?</p>
          </div>
        )}
        {action === DELETE_GROUP && (
          <div>
            <p>This will delete the current group and any data related to it!</p>
            <p>Are you sure you want to continue?</p>
          </div>
        )}
        {action === BULK_DELETE_GROUPS && (
          <div>
            <p>This will delete all the selected groups and any data related to them!</p>
            <p>Are you sure you want to continue?</p>
          </div>
        )}
      </div>
      <ConfirmButtons handleConfirmation={handleConfirmation} />
    </main>
  );
}
