import { Dispatch } from "@reduxjs/toolkit";
import { RemoveStaffsFromGroupActionPayload } from "../../../../interfaces/main";
import { RemoveStaffsFromGroupAction } from "../../../redux/reducers/Modal/displayRemoveStaffsFromGroupModal";
import { TO_DISPLAY_REMOVE_STAFFS_FROM_GROUP_MODAL } from "../../../redux/actions/action.types";

export default function displayRemoveStaffsFromGroupModal(
  payload: RemoveStaffsFromGroupActionPayload,
  dispatch: Dispatch<RemoveStaffsFromGroupAction>
) {
  dispatch({
    type: TO_DISPLAY_REMOVE_STAFFS_FROM_GROUP_MODAL,
    payload,
  });
}
