export const isEin = (tintype: string) => {
  return tintype === "EIN";
};

export default function maskTin(tin: string, tintype: string) {
  if (!isEin(tintype)) {
    const tinLastDigits = tin.split("-")[2];
    return `XXX-XX-${tinLastDigits}`;
  } else {
    return tin;
  }
}
