// import retrieveToken from "../authRequests/retrieveTokens";
import { axiosInstance } from "../axiosConfig";

export default async function deleteForms(cartIds: any[]) {
  try {
    const { data } = await axiosInstance.delete("/delete_cart_items", {
      data: {
        cart_id_array: cartIds,
      },
      // headers: {
      // 	"Content-Type": "application/json",
      // 	auth_token: retrieveToken("auth_token"),
      // },
    });
    return data;
  } catch (err) {
    throw err;
  }
}
