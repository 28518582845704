import React, { ReactNode } from "react";

interface Props extends React.HtmlHTMLAttributes<HTMLElement> {
  adtext: string;
  hyperlink?: ReactNode;
}

export default function AdBanner({ adtext, hyperlink }: Props) {
  return (
    <div className="w-[100%] fixed top-[5rem] sm:top-[5.85rem] py-2 px-6 bg-yellow-400 z-10 text-[1.2rem]">
      <p className="text-center font-bold">
        {adtext} {hyperlink}
      </p>
    </div>
  );
}
