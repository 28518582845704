import React, { useEffect, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { BsEraserFill } from "react-icons/bs";

interface Props {
  toReturnRef: boolean;
  setReference: React.Dispatch<
    React.SetStateAction<React.MutableRefObject<HTMLElement | null> | null>
  >;
}

export default function SignatureDrawingCanvas({ toReturnRef, setReference }: Props) {
  const ref: any = useRef(null);

  useEffect(() => {
    if (toReturnRef) setReference(ref);
    return () => {
      setReference(null);
    };
  }, [toReturnRef, setReference]);

  const handleClear = () => {
    ref.current.clear();
  };

  return (
    <div className="flex flex-col items-center gap-4 py-4 border-b-2">
      <SignatureCanvas
        ref={ref}
        penColor="black"
        canvasProps={{ width: 1200, height: 250, className: "sigCanvas bg-[#F3EEEA]" }}
      />
      <button onClick={handleClear}>
        <BsEraserFill className="text-2xl" />
      </button>
    </div>
  );
}
