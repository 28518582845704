import { Dispatch } from "@reduxjs/toolkit";
import devLogInstance from "../loggerConfig";
import Cookie from "../utils/Cookie";
import authenticateRecipient from "./authenticateRecipient";
import { IsRecipientAuthenticatedAction } from "../../../interfaces/main";
import logoutRecipient from "./logoutRecipient";

export default function checkRecipientAuth(
  recipientTokenName: string,
  stateSetter: Dispatch<IsRecipientAuthenticatedAction>
) {
  const recTokenCookieExists = Cookie.retrieveCookie(recipientTokenName);
  if (recTokenCookieExists) {
    devLogInstance.log("authenticating recipient..");
    authenticateRecipient(stateSetter, true);
    devLogInstance.log("recipient authenticated!");
  } else {
    // Logout recipient
    logoutRecipient(stateSetter);
  }
}
