import React from "react";

interface InvalidDataError {
  invalidField: string;
  error: string;
}

interface Props {
  errors: InvalidDataError[];
}

export default function InvalidDataModalMsg({ errors }: Props) {
  return (
    <div className="p-4">
      <h2>Following errors were caught in the file that was just uploaded:</h2>
      {errors.map((errObj, i) => {
        return (
          <div className="p-2 text-lg font-semibold" key={i}>
            {i + 1}. {errObj.error}
          </div>
        );
      })}
    </div>
  );
}
