import React from "react";
import "../styles/Popup.css";
import { useSelector } from "react-redux";
import { SUCCESS_POPUP } from "../lib/constants";

export default function Popup({ message, status }: any) {
  const style = useSelector((state: any) => state.themeChange);
  return (
    <div className="pop-up-container">
      <div
        className="pop-up"
        style={{
          background: status === SUCCESS_POPUP ? style.popupSuccessBg : style.popupErrorBg,
          color: status !== SUCCESS_POPUP ? "white" : "initial",
          boxShadow: `4px 4px 10px ${style.boxShadowColor}`,
        }}
      >
        {message}
      </div>
    </div>
  );
}
