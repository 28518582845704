import React from "react";
import { EMAIL } from "../../pages/Contact";
import { TIN } from "../../lib/constants";
import { FormProps } from "../../../interfaces/main";
import Password from "../Password";

export default function OnlineAccessLoginForm({
  onChange,
  onSubmit,
  onlineAccessLoginData,
}: FormProps) {
  return (
    <form className="w-[50%] px-6 flex flex-col gap-6" onSubmit={onSubmit}>
      <h2>Access Form 1099 Online</h2>
      <p>Enter your credentials to view and download your Form 1099</p>
      <div className="flex flex-col gap-1">
        <label className="label" htmlFor={EMAIL}>
          Email Address
        </label>
        <p>Enter the email address where you received the online access link</p>
        <input
          id={EMAIL}
          type="email"
          className="py-2"
          value={onlineAccessLoginData.recipient_email}
          onChange={onChange}
        />
      </div>
      <div className="flex flex-col gap-1">
        <label className="label" htmlFor={TIN}>
          9-digit TIN
        </label>
        <p>Enter your 9-digit TIN (SSN, EIN, ATIN, ITIN) without hyphen</p>
        <Password
          id={TIN}
          width="w-[50%]"
          value={onlineAccessLoginData.recipient_tin}
          sendOnChangeEvent={true}
          onChangeEvent={onChange}
        />
        {/* <input
          id={TIN}
          type="password"
          className="py-2 w-[50%]"
          value={onlineAccessLoginData.recipient_tin}
          onChange={onChange}
        /> */}
      </div>
      {onlineAccessLoginData.recipient_email && onlineAccessLoginData.recipient_tin && (
        <div>
          <button
            className="primary-button py-3 px-6"
            type="submit"
            // disabled={!onlineAccessLoginData.recipient_email && !onlineAccessLoginData.recipient_tin}
          >
            Continue
          </button>
        </div>
      )}
    </form>
  );
}
