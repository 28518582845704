import React from "react";
import "../../../../../styles/forms/globalComponentStyles/StateInfo.css";
import { useDispatch, useSelector } from "react-redux";
import LocalFilingDetails from "./LocalFilingDetails/LocalFilingDetails";
import { BsInfoCircleFill } from "react-icons/bs";
import displayInfoModal from "../../../../../lib/StateSetters/displayInfoModal";
import LocalFilingModalBody from "../../../../decorations/Modal/LocalFilingModalBody/LocalFilingModalBody";

const LOCAL_FILING_INFO_ICON = "LOCAL_FILING_INFO_ICON";

export default function LocalFiling() {
  const style = useSelector((state: any) => state.themeChange);
  const stateSetter = useDispatch();

  // Handle click event
  const handleClick = (e: React.MouseEvent<SVGElement | HTMLElement>) => {
    const { id } = e.currentTarget;

    switch (id) {
      case LOCAL_FILING_INFO_ICON:
        // Display the info modal
        displayInfoModal(
          true,
          <LocalFilingModalBody localBoxNo="18" localTaxBoxNo="17" localIncomeBoxNo="19" />,
          "Local Filing information (For all local filings)",
          stateSetter
        );
        break;

      default:
        break;
    }
  };

  return (
    <div
      className="state-info-box"
      style={{
        boxShadow: `2px 2px 10px ${style.eFormBorderColor}`,
      }}
    >
      <div className="state-1">
        <div className="flex items-center gap-6 state-info-heading">
          <h2>Local filing 1 details</h2>
          <BsInfoCircleFill
            id={LOCAL_FILING_INFO_ICON}
            className="cursor-pointer"
            onClick={handleClick}
          />
        </div>
        <LocalFilingDetails
        //   compId={"state1"}
        //   taxWithheldLabel={taxWithheldLabel}
        //   stateVolLabel={stateVolLabel}
        //   stateNoLabel={stateNoLabel}
        //   stateIncomeLabel={stateIncomeLabel}
        //   gData={globalFormData}
        //   setGData={setGlobalFormData}
        //   formType={formType}
        />

        {/* {globalFormData.state1.length !== 0 && (
      <div className="state-info-clear-btn">
        <button
          onClick={handleClearingData}
          id="state1"
          style={{
            background: style.btnBg,
            color: style.btnFillFontColor,
          }}
        >
          Clear
        </button>
      </div>
    )} */}
      </div>
      <div className="state-2">
        <div className="state-info-heading state-info-heading-2">
          <h2>Local filing 2 details</h2>
        </div>
        <LocalFilingDetails
        //   compId={"state2"}
        //   taxWithheldLabel={taxWithheldLabel}
        //   stateVolLabel={stateVolLabel}
        //   stateNoLabel={stateNoLabel}
        //   stateIncomeLabel={stateIncomeLabel}
        //   gData={globalFormData}
        //   setGData={setGlobalFormData}
        //   formType={formType}
        />
        {/* {globalFormData.state2.length !== 0 && (
      <div className="state-info-clear-btn">
        <button
          onClick={handleClearingData}
          id="state2"
          style={{
            background: style.btnBg,
            color: style.btnFillFontColor,
          }}
        >
          Clear
        </button>
      </div>
    )} */}
      </div>
    </div>
  );
}
