import React from "react";
import { SIGNIN } from "../../lib/routes";
import { Link } from "react-router-dom";

export default function EmailActivationFailed() {
  return (
    <div>
      <section className="verified-container h-[100vh] flex flex-col items-center gap-11 py-20">
        <div className="verified-logo flex justify-center w-[10%]">
          <img src="/Assets/delete-button.svg" alt="verified" className="w-[100%]" />
        </div>
        <div className="verified-text">
          <h1 className="text-2xl">Email verification failed!</h1>
        </div>
        <div className="redirect-wrapper text-lg">
          <p>
            Try{" "}
            <span className="login">
              <Link to={SIGNIN} className="text-taxeve-primary-blue font-bold">
                Logging in
              </Link>
            </span>{" "}
            again to verify your email
          </p>
        </div>
      </section>
    </div>
  );
}
