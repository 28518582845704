import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./FormTable.css";
import { useEffect, useMemo, useState } from "react";
import TableBody from "./TableBody/TableBody";
import { BsInfoCircle } from "react-icons/bs";
import {
  FEDERAL_EFILE_STATUS,
  ONLINE_ACCESS_STATUS,
  POSTAL_MAILING_STATUS,
  STATE_EFILE_STATUS,
} from "../../../lib/constants";
import displayInfoModal from "../../../lib/StateSetters/displayInfoModal";
import FormStatusInfoBody from "../../decorations/InfoRelated/FormStatusInfoBody";
// import devLogInstance from "../../../lib/loggerConfig";

const isAllChildItemsChecked = (itemsChecked: number, totalItems: number) => {
  if (itemsChecked === totalItems) return true;
  return false;
};

export default function FormTable({ form, allFormData, tableId, onCorrectionPage, taxYear }: any) {
  const style = useSelector((state: any) => state.themeChange);
  const [formsData, setFormsData] = useState<any[]>([]);
  const [childItemsChecked, setChildItemsChecked] = useState(0);
  const [checkAllChildItems, toCheckAllChildItems] = useState(false);
  const [semiCheck, toSemiCheck] = useState(false);
  const [uncheckAllChildren, toUncheckAllChildren] = useState<boolean | null>(null);
  const dispatch = useDispatch();

  // devLogInstance.log(childItemsChecked);
  // devLogInstance.log(checkAllChildItems);

  // devLogInstance.log(formsData);

  useEffect(() => {
    const startIdx = form.startIdx;
    const endIdx = form.endIdx;
    const formData = allFormData.slice(startIdx, endIdx + 1);

    setFormsData(formData);

    return () => {
      setFormsData([]);
    };
  }, [form, allFormData]);

  // Called everytime the checked items length / the overall formsdata length changes
  useMemo(() => {
    toUncheckAllChildren(null); // Reset

    if (isAllChildItemsChecked(childItemsChecked, formsData.length) && childItemsChecked > 0) {
      toCheckAllChildItems(true);
      toSemiCheck(false);
    } else if (
      !isAllChildItemsChecked(childItemsChecked, formsData.length) &&
      childItemsChecked > 0
    ) {
      toSemiCheck(true);
      toCheckAllChildItems(false);
    } else if (childItemsChecked === 0) {
      toCheckAllChildItems(false);
      toSemiCheck(false);
    }
  }, [childItemsChecked, formsData.length]);

  // Change event handler
  const handleChange = () => {
    if (isAllChildItemsChecked(childItemsChecked, formsData.length)) {
      toUncheckAllChildren(true);
    } else {
      toUncheckAllChildren(false);
    }
  };

  // Click event handler
  const handleClick = () => {
    // Uncheck all child items
    toUncheckAllChildren(true);
  };

  // Handle display of info dialog
  const showInfoDialog = (e: React.MouseEvent<SVGAElement>) => {
    const { id: type } = e.currentTarget;
    switch (type) {
      case FEDERAL_EFILE_STATUS:
        displayInfoModal(
          true,
          <FormStatusInfoBody statusType={FEDERAL_EFILE_STATUS} />,
          "Federal E-File Status",
          dispatch
        );
        break;

      case STATE_EFILE_STATUS:
        displayInfoModal(
          true,
          <FormStatusInfoBody statusType={STATE_EFILE_STATUS} />,
          "State E-File Status",
          dispatch
        );
        break;

      case POSTAL_MAILING_STATUS:
        displayInfoModal(
          true,
          <FormStatusInfoBody statusType={POSTAL_MAILING_STATUS} />,
          "Postal Mailing Status",
          dispatch
        );
        break;

      case ONLINE_ACCESS_STATUS:
        displayInfoModal(
          true,
          <FormStatusInfoBody statusType={ONLINE_ACCESS_STATUS} />,
          "Online Access Status",
          dispatch
        );
        break;

      default:
        break;
    }
  };

  return (
    <main className="mb-6 form-table-container">
      <div
        className="table-heading"
        style={{ background: style.cartTableTopPanel, color: style.cartTableTopPanelFontColor }}
      >
        <h2>Form {form.formType}</h2>
      </div>
      {/* Table body */}
      <table>
        <thead>
          <tr>
            {!onCorrectionPage && (
              <th>
                <div>
                  {!checkAllChildItems && semiCheck ? (
                    <i
                      className="fa-solid fa-square-minus"
                      style={{ position: "relative", left: "-0.25rem" }}
                      onClick={handleClick}
                    />
                  ) : (
                    <input type="checkbox" checked={checkAllChildItems} onChange={handleChange} />
                  )}
                </div>
              </th>
            )}
            <th>
              <div style={{ textAlign: "center" }}>Recipient</div>
            </th>
            <th>
              <div style={{ textAlign: "center" }}>TIN</div>
            </th>
            <th className="w-[15%]">
              <div
                style={{ textAlign: "center" }}
                className="flex justify-center items-center gap-2"
              >
                Federal E-file status{" "}
                <BsInfoCircle
                  id={FEDERAL_EFILE_STATUS}
                  className="cursor-pointer text-sm"
                  onClick={showInfoDialog}
                />
              </div>
            </th>
            {!onCorrectionPage && (
              <>
                <th className="w-[15%]">
                  <div
                    style={{ textAlign: "center" }}
                    className="flex justify-center items-center gap-2"
                  >
                    State E-file status{" "}
                    <BsInfoCircle
                      id={STATE_EFILE_STATUS}
                      className="cursor-pointer text-sm"
                      onClick={showInfoDialog}
                    />
                  </div>
                </th>
                <th className="w-[16%]">
                  <div
                    style={{ textAlign: "center" }}
                    className="flex justify-center items-center gap-2"
                  >
                    Postal Mailing status{" "}
                    <BsInfoCircle
                      id={POSTAL_MAILING_STATUS}
                      className="cursor-pointer text-sm"
                      onClick={showInfoDialog}
                    />
                  </div>
                </th>
                <th className="w-[16%]">
                  <div
                    style={{ textAlign: "center" }}
                    className="flex justify-center items-center gap-2"
                  >
                    Online Access status{" "}
                    <BsInfoCircle
                      id={ONLINE_ACCESS_STATUS}
                      className="cursor-pointer text-sm"
                      onClick={showInfoDialog}
                    />
                  </div>
                </th>
                <th>
                  <div style={{ textAlign: "center" }}>Order Placed on</div>
                </th>
              </>
            )}
            <th style={{ width: "25%" }}>
              <div style={{ textAlign: "center" }}>Action</div>
            </th>
          </tr>
        </thead>
        {formsData.map((formData, i) => {
          return (
            <TableBody
              key={i}
              formData={formData}
              itemsChecked={setChildItemsChecked}
              uncheckAll={uncheckAllChildren}
              id={i}
              tableId={tableId}
              onCorrectionPage={onCorrectionPage}
              taxYear={taxYear}
              formType={form.formType}
              formId={formData.form_id}
            />
          );
        })}
      </table>
    </main>
  );
}
