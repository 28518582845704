import React from "react";
import { useState } from "react";
import "./SubmitButton.component.css";
import { useSelector } from "react-redux";
import {
  PAGE_UPLOAD,
  SUBMIT_ADD_ANOTHER,
  SUBMIT_CONTINUE,
  SUBMIT_CORRECTION,
} from "../../lib/constants";
import { btnInnerTxts } from "../../lib/languagePacks/en-us";

export default function SubmitButton({ handleSubmit, pageType, isCorrection }: any) {
  const style = useSelector((state: any) => state.themeChange);
  const [isHovered, setIsHovered] = useState({
    submit_addAnother: false,
    submit_continue: false,
  });
  return (
    <div className="submit-btn-container">
      {pageType !== PAGE_UPLOAD && !isCorrection && (
        <button
          id={SUBMIT_ADD_ANOTHER}
          type="submit"
          className="submit-btn"
          onClick={handleSubmit}
          onMouseEnter={() => setIsHovered({ ...isHovered, submit_addAnother: true })}
          onMouseLeave={() => setIsHovered({ ...isHovered, submit_addAnother: false })}
          style={{
            background: style.btnBg,
            color: style.btnFillFontColor,
            boxShadow: isHovered.submit_addAnother ? `4px 4px 20px ${style.btnBg}` : "initial",
          }}
        >
          {btnInnerTxts.submitAndFillAgain}
        </button>
      )}
      <button
        id={isCorrection ? SUBMIT_CORRECTION : SUBMIT_CONTINUE}
        type="submit"
        className="submit-btn"
        onClick={handleSubmit}
        onMouseEnter={() => setIsHovered({ ...isHovered, submit_continue: true })}
        onMouseLeave={() => setIsHovered({ ...isHovered, submit_continue: false })}
        style={{
          background: style.btnSecondaryBg,
          color: style.btnFillFontColor,
          boxShadow: isHovered.submit_continue ? `4px 4px 20px ${style.btnSecondaryBg}` : "initial",
        }}
      >
        {btnInnerTxts.submitAndContinue}
      </button>
    </div>
  );
}
