import { axiosInstance } from "../axiosConfig";

interface LoginData {
  email: string;
  password: string;
}

export default async function submitLoginData(loginData: LoginData) {
  try {
    const { data } = await axiosInstance.post("/admin/login", loginData);
    return data;
  } catch (err) {
    throw err;
  }
}
