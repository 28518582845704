import { PasswordChangeData } from "../../../interfaces/main";
import { axiosInstance } from "../axiosConfig";

export default async function changeAdminPassword(data_: PasswordChangeData) {
  try {
    const { data } = await axiosInstance.post("/admin/change_password", data_);
    return data;
  } catch (err) {
    throw err;
  }
}
