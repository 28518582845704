import React from "react";

export const USER_ID = "USER_ID";
export const REMOVE_USER_ID = "REMOVE_USER_ID";

interface UserIdProp {
  idx: number;
  userIds: string[];
  addUserId: (e: React.ChangeEvent<HTMLInputElement>, idx?: number) => void;
  removeUserId: (e: React.MouseEvent<HTMLElement>, idx?: number) => void;
}

export default function AddUserId({ idx, userIds, addUserId, removeUserId }: UserIdProp) {
  return (
    <div className="py-2 flex">
      <input
        id={USER_ID}
        type="text"
        className="py-2 w-[100%]"
        value={userIds[idx] ?? ""}
        onChange={(e) => {
          addUserId(e, idx);
        }}
      />
      <span className="flex items-center px-2">
        <i
          id={REMOVE_USER_ID}
          className="fa-solid fa-circle-minus text-xl cursor-pointer"
          onClick={(e) => {
            removeUserId(e, idx);
          }}
        />
      </span>
    </div>
  );
}
