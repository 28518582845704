import React from "react";
import { PAYMENT } from "../../../../../lib/constants";
import StateAmount from "../StateAmount/StateAmount";

export default function StateTax({
  heading,
  taxamt1,
  taxAmt2,
  state1,
  state2,
  styles = {},
  onLeft = false,
}: any) {
  return (
    <div style={{ borderRight: `1px solid black`, ...styles }} className="rf-amt-child">
      <div className="rf-si-heading">{heading}</div>
      <StateAmount
        type={PAYMENT}
        amt1={taxamt1}
        amt2={taxAmt2}
        state1={state1}
        state2={state2}
        onLeft={onLeft}
      />
    </div>
  );
}
