import React, { useEffect, useState } from "react";
import { SiMinutemailer } from "react-icons/si";
import EnquiryContainer from "./EnquiryContainer";
import { ENQUIRY_CONTAINER, ENQUIRY_FLOATER, ENQUIRY_FLOATER_ICON } from "../../lib/constants";
import { QUERY } from "../../pages/Contact";

export default function EnquiryFloater() {
  const [displayEnquiryContainer, toDisplayEnquiryContainer] = useState(false);

  // Handle click on the enquiry float icon
  useEffect(() => {
    const handleClick = (e: any) => {
      const id = e.target.id || e.target.parentElement.id || null;
      if (id === ENQUIRY_FLOATER || id === ENQUIRY_FLOATER_ICON)
        toDisplayEnquiryContainer(!displayEnquiryContainer);
      else if (id === ENQUIRY_CONTAINER || id === QUERY) toDisplayEnquiryContainer(true);
      else toDisplayEnquiryContainer(false);
    };
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, [displayEnquiryContainer]);

  return (
    <div
      id={ENQUIRY_FLOATER}
      className="float-icon fixed bottom-6 right-10 z-10 cursor-pointer flex f items-center w-[4rem] justify-center bg-taxeve-primary-violet text-white p-4 rounded-[100%] shadow-md shadow-slate-400"
    >
      {displayEnquiryContainer && <EnquiryContainer />}
      <SiMinutemailer id={ENQUIRY_FLOATER_ICON} className="text-3xl" />
    </div>
  );
}
