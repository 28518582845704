import { Dispatch } from "@reduxjs/toolkit";
import UpdateGroupRecords from "../../types/UpdateGroupRecords";
import { TO_DISPLAY_ADD_STAFFS_TO_GROUP_MODAL } from "../../../redux/actions/action.types";
import { AddStaffsToGroupAction } from "../../../redux/reducers/Modal/displayAddStaffsToGroupModal";

export default function displayAddStaffsToGroupModal(
  toDisplay: boolean,
  groupId: string,
  updateGroupRecords: UpdateGroupRecords,
  dispatch: Dispatch<AddStaffsToGroupAction>
) {
  dispatch({
    type: TO_DISPLAY_ADD_STAFFS_TO_GROUP_MODAL,
    payload: {
      toDisplay,
      groupId,
      updateGroupRecords,
    },
  });
}
