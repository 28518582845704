import React from "react";
import "../../../../../styles/forms/Form.css";
import { useSelector } from "react-redux";
import { nec1099AmountDisplayTexts } from "../../../../../lib/languagePacks/en-us";
import { FORM1099_MISC } from "../../../../../lib/constants";

export default function PayerDirectSales({
  status,
  onChangeHandler,
  style_,
  formType,
  fieldId,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  return (
    <div
      className="payer-direct-sales items-center px-4 py-4"
      style={{
        ...style_,
        borderBottom: formType !== FORM1099_MISC ? `1px solid ${style.eFormBorderColor}` : "",
        borderRight: `1px solid ${style.eFormBorderColor}`,
        // display: formType === FORM1099_MISC && "block",
      }}
    >
      <div className="heading">
        <h4 className="flex gap-2">
          {fieldId && `${fieldId}`} <div>{nec1099AmountDisplayTexts.directSalesDisplayText}</div>
        </h4>
      </div>
      <div
        style={{
          // padding: "0.65rem",
          textAlign: formType === FORM1099_MISC ? "center" : "initial",
        }}
      >
        <input type="checkbox" id="direct-sales" checked={status} onChange={onChangeHandler} />
      </div>
    </div>
  );
}
