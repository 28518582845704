import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./BulkAction.component.css";
import { FaDownload } from "react-icons/fa";
import { BsClipboardCheck, BsClipboardX } from "react-icons/bs";
import { ImBin2 } from "react-icons/im";
import { useEffect, useState } from "react";
import {
  BULK_DELETE,
  BULK_REVIEW,
  BULK_UNREVIEW,
  DOWNLOAD,
  SUCCESS_POPUP,
} from "../../../../lib/constants";
// import Bulkaction from "../../../../lib/utils/BulkAction";
// import { markReviewed } from "../../ReviewForm/Edit-Next/EditNextBtn.component"
import logger from "../../../../logger";
import handlePopup from "../../../../lib/popup/handlePopup";
import { action, actionInstruction, toastMessage } from "../../../../lib/languagePacks/en-us";
import { BulkAction, FormReview, FormUnreview } from "../../../../lib/utils/BulkAction";
import displayConfirmationModal from "../../../../lib/StateSetters/displayConfirmationModal";
import {
  BULK_REVIEW_FORM,
  BULK_UNREVIEW_FORM,
  DELETE_ACTION_BULK,
} from "../../../../lib/UserActions/action";
import setReviewedFormIds from "../../../../lib/StateSetters/setReviewedFormIds";
import { useInProgress } from "../../../../lib/customHooks";

export const getTheFileName = (contentDisposition: any) => {
  const filenameStr = contentDisposition.split(";")[1];
  const filename: string = filenameStr.split("=")[1].replaceAll(/"/g, "");
  return filename;
};

export default function Bulkaction() {
  const bulkActionItems = useSelector((state: any) => state.bulkActionItems);
  const recData = useSelector((state: any) => state.rpRecipientData);
  const style = useSelector((state: any) => state.themeChange);
  const selectedPayerId = useSelector((state: any) => state.selectedPayerId);
  const [selectedCartIds, setSelectedCartIds] = useState<any[]>([]);
  const [selectedRecordIdxs, setSelectedrecordIdxs] = useState<any[]>([]);
  const [isBulkRecordSelected, setIsBulkRecordSelected] = useState(false);
  const [isBulkReviewed, setIsBulkReviewed] = useState(false);
  // const [reviewedIds, setReviewedIds] = useState([]);
  const reviewedIds = useSelector((state: any) => state.reviewedFormIds);
  const [isHovered, setIsHovered] = useState({
    download: false,
    bulkreview: false,
    bulkunreview: false,
    bulkdelete: false,
  });
  const dispatch = useDispatch();
  const { inProgress } = useInProgress();

  // Set the array of cart ids for all the records selected for bulk action
  useEffect(() => {
    if (bulkActionItems.length !== 0 && bulkActionItems !== undefined) {
      let cartIds: any[] = [];
      let selectedRecordIdxs: any[] = [];
      bulkActionItems.forEach((obj: any) => {
        const selectedCartIds = obj.data.map((item: any) => {
          selectedRecordIdxs = [...selectedRecordIdxs, item.idx];
          return { _id: item.record.cartId };
        });

        cartIds = [...cartIds, ...selectedCartIds];
      });
      setSelectedCartIds(cartIds);
      setSelectedrecordIdxs(selectedRecordIdxs);

      return () => {
        setSelectedCartIds([]);
      };
    }
  }, [bulkActionItems]);

  // Set is bulk reviewed state
  useEffect(() => {
    selectedCartIds.length === 0 ? setIsBulkRecordSelected(false) : setIsBulkRecordSelected(true);
  }, [selectedCartIds]);

  // Monitr change in the reviewed ids and show toast message if all forms are reviewed
  useEffect(() => {
    logger.log(`Rec data length ---> ${recData.length}`);
    logger.log(`Reviewed ids length ---> ${reviewedIds.length}`);
    logger.log(reviewedIds);
    if (recData.length === reviewedIds.length && recData.length + reviewedIds.length > 0) {
      handlePopup(toastMessage.allFormsReviewed, SUCCESS_POPUP, dispatch);
      setIsBulkReviewed(true); // All forms are bulk reviewed
    }
    // // Empty the reviewed ids array after every check if its not already empty  to prevent conflict
    // reviewedIds.length !== 0 && setReviewedFormIds(dispatch, []);
    return () => {
      setIsBulkReviewed(false);
    };
  }, [reviewedIds, dispatch, recData]);

  // Empty the reviewed ids array on component destroy
  useEffect(() => {
    return () => {
      setReviewedFormIds(dispatch, []);
    };
  }, [dispatch]);

  // Handle click
  const handleClick = (e: any) => {
    logger.log("clicked");
    let id = e.target.id ? e.target.id : e.target.parentElement.id;
    logger.log(id);
    BulkAction.cartIds = selectedCartIds;
    BulkAction.stateSetter = dispatch;
    switch (id) {
      case DOWNLOAD:
        BulkAction.formDownload();
        break;

      case BULK_REVIEW:
        if (!isBulkReviewed) {
          FormReview.selectedRecordIdxs = selectedRecordIdxs;
          // Display the confirmation modal
          displayConfirmationModal(dispatch, BULK_REVIEW_FORM, true);
        }
        break;

      case BULK_UNREVIEW:
        FormUnreview.selectedRecordIdxs = selectedRecordIdxs;
        // Display the confirmation modal
        displayConfirmationModal(dispatch, BULK_UNREVIEW_FORM, true);
        break;

      case BULK_DELETE:
        // Store the current payer id
        logger.log("Current payer id when deleting ---> " + selectedPayerId);
        localStorage.setItem("payer_id", selectedPayerId);
        // Display the confirmation modal
        displayConfirmationModal(dispatch, DELETE_ACTION_BULK, true);
        break;

      default:
        break;
    }
  };

  return (
    <div className="bulk-action-container" style={{ background: style.bulkActionContainerBg }}>
      <div className="individual-action-container">
        <FaDownload
          className="text-2xl cursor-pointer"
          id={DOWNLOAD}
          onClick={isBulkRecordSelected ? handleClick : () => {}}
          onMouseOver={() => {
            setIsHovered({ ...isHovered, download: true });
          }}
          onMouseLeave={() => {
            setIsHovered({ ...isHovered, download: false });
          }}
          style={{ opacity: !isBulkRecordSelected ? "0.25" : "1" }}
        />
        {isHovered.download && (
          <div
            className={`absolute ${
              !isBulkRecordSelected ? "top-[-3.5rem]" : "top-[-2rem]"
            } left-[-2rem] ${!isBulkRecordSelected ? "w-64" : "w-[8rem]"} px-2 z-[5]`}
            style={{
              border: `1px solid ${style.eFormBorderColor}`,
              background: style.hoverableNameBg,
            }}
          >
            {!isBulkRecordSelected ? (
              <p>
                {actionInstruction} <span className="font-bold">{action.download}.</span>
              </p>
            ) : (
              <p className="text-center">{action.download}</p>
            )}
          </div>
        )}
      </div>
      <div className="individual-action-container">
        <BsClipboardCheck
          id={BULK_REVIEW}
          onClick={isBulkRecordSelected ? handleClick : () => {}}
          onMouseOver={() => {
            setIsHovered({ ...isHovered, bulkreview: true });
          }}
          onMouseLeave={() => {
            setIsHovered({ ...isHovered, bulkreview: false });
          }}
          style={{ opacity: !isBulkRecordSelected ? "0.25" : "1" }}
        />
        {isHovered.bulkreview && (
          <div
            className={`absolute ${!isBulkRecordSelected ? "top-[-3.5rem]" : "top-[-2rem]"} ${
              !isBulkRecordSelected ? "w-64" : "w-[7rem]"
            } ${!isBulkRecordSelected ? "left-[-5.5rem]" : "left-[-2.5rem]"} px-2 z-[5]`}
            style={{
              border: `1px solid ${style.eFormBorderColor}`,
              background: style.hoverableNameBg,
            }}
          >
            {!isBulkRecordSelected ? (
              <p>
                {actionInstruction} <span className="font-bold">{action.bulkReview}.</span>
              </p>
            ) : (
              <p className="text-center">{action.bulkReview}</p>
            )}
          </div>
        )}
      </div>
      <div className="individual-action-container">
        <BsClipboardX
          id={BULK_UNREVIEW}
          onClick={isBulkRecordSelected ? handleClick : () => {}}
          onMouseOver={() => {
            setIsHovered({ ...isHovered, bulkunreview: true });
          }}
          onMouseLeave={() => {
            setIsHovered({ ...isHovered, bulkunreview: false });
          }}
          style={{ opacity: !isBulkRecordSelected ? "0.25" : "1" }}
        />
        {isHovered.bulkunreview && (
          <div
            className={`absolute ${!isBulkRecordSelected ? "top-[-3.5rem]" : "top-[-2rem]"} ${
              !isBulkRecordSelected ? "w-64" : "w-[8rem]"
            } ${!isBulkRecordSelected ? "left-[-5.5rem]" : "left-[-2.8rem]"} px-2 z-[5]`}
            style={{
              border: `1px solid ${style.eFormBorderColor}`,
              background: style.hoverableNameBg,
            }}
          >
            {!isBulkRecordSelected ? (
              <p>
                {actionInstruction} <span className="font-bold">{action.bulkUnreview}.</span>
              </p>
            ) : (
              <p className="text-center">{action.bulkUnreview}</p>
            )}
          </div>
        )}
      </div>
      {!inProgress.inProgress && (
        <div className="individual-action-container">
          <ImBin2
            id={BULK_DELETE}
            onClick={isBulkRecordSelected ? handleClick : () => {}}
            onMouseOver={() => {
              setIsHovered({ ...isHovered, bulkdelete: true });
            }}
            onMouseLeave={() => {
              setIsHovered({ ...isHovered, bulkdelete: false });
            }}
            style={{ opacity: !isBulkRecordSelected ? "0.25" : "1" }}
          />
          {isHovered.bulkdelete && (
            <div
              className={`absolute ${!isBulkRecordSelected ? "top-[-3.5rem]" : "top-[-2rem]"} ${
                !isBulkRecordSelected ? "w-64" : "w-[7rem]"
              } ${!isBulkRecordSelected ? "left-[-6.5rem]" : "left-[-2.5rem]"} px-2 z-[5]`}
              style={{
                border: `1px solid ${style.eFormBorderColor}`,
                background: style.hoverableNameBg,
              }}
            >
              {!isBulkRecordSelected ? (
                <p>
                  {actionInstruction} <span className="font-bold">{action.bulkDelete}.</span>
                </p>
              ) : (
                <p className="text-center">{action.bulkDelete}</p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
