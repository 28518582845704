import React from "react";
import { AMOUNT_A } from "../../../../../lib/constants";
import { r1099AmountDisplayTexts } from "../../../../../lib/languagePacks/en-us";
import AmountHeading from "../../AmountContainer/AmountHeading/AmountHeading";
import Amount from "../../AmountContainer/AmountInput/Amount";

export default function Box11({ amountObj, changeHandler }: any) {
  return (
    <>
      <AmountHeading heading={r1099AmountDisplayTexts.rothContrib} amountNum="11" />
      <Amount
        type="text"
        id={AMOUNT_A}
        placeHolder="0.00"
        class_="payer-details-input" // Only one class can be passed
        value={amountObj.amountA}
        onChangeHandler={changeHandler}
      />
    </>
  );
}
