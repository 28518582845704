import decrypt from "../AESHashing/decrypt";
import encrypt from "../AESHashing/encrypt";

export function isPresent(value: any) {
  if (value !== null) return true;
  else return false;
}

export default function saveFormData(data: any) {
  const encFormDataStr = localStorage.getItem("form_data");
  if (isPresent(encFormDataStr)) {
    const formData = encFormDataStr ? JSON.parse(decrypt(encFormDataStr)) : "";
    formData.push(data);
    localStorage.setItem("form_data", encrypt(JSON.stringify(formData)).toString());
  } else {
    const formData = [data];
    localStorage.setItem("form_data", encrypt(JSON.stringify(formData)).toString());
  }
}
