import React from "react";
import { Link } from "react-router-dom";
import {
  CONTACT_US_PAGE,
  LANDING_PAGE,
  PRICING_PAGE,
  PRIVACY_POLICY,
  TERMS_AND_CONDITION,
  WHY_CHOOSE_US_PAGE,
  _1099_FORMS_PAGE,
} from "../../lib/routes";
import { FaPhoneAlt } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import SubFooter from "./SubFooter/SubFooter";
import contactInfo from "../../lib/json/contactInfo.json";
import formatPhonenum from "../../lib/form/utils/formatPhone";

export default function Footer() {
  return (
    <main className="font-semibold text-white">
      <div
        id="footer-first-portion"
        className="bg-[#301E67] opacity-80 px-4 py-8 flex justify-center gap-8 lg:gap-32"
      >
        <ul id="quick-links" className="text-xl hidden sm:block">
          <h2 className="text-2xl font-bold py-4">Quick links</h2>
          <li className="list-inside list-disc py-2 hover:text-blue-600">
            <Link to={LANDING_PAGE}>Home</Link>
          </li>
          <li className="list-inside list-disc py-2 hover:text-blue-600">
            <Link to={_1099_FORMS_PAGE}>Forms</Link>
          </li>
          <li className="list-inside list-disc py-2 hover:text-blue-600">
            <Link to={WHY_CHOOSE_US_PAGE}>Services</Link>
          </li>
          <li className="list-inside list-disc py-2 hover:text-blue-600">
            <Link to={PRICING_PAGE}>Pricing</Link>
          </li>
          <li className="list-inside list-disc py-2 hover:text-blue-600">
            <Link to={CONTACT_US_PAGE}>Contact us</Link>
          </li>
        </ul>

        <ul id="legal-links" className="text-xl">
          <h2 className="text-2xl font-bold py-4">Legal information</h2>
          <li className="list-inside list-disc py-2 hover:text-blue-600">
            <a href={TERMS_AND_CONDITION} rel="noreferrer" target="_blank">
              Terms of use
            </a>
          </li>
          <li className="list-inside list-disc py-2 hover:text-blue-600">
            <a href={PRIVACY_POLICY} rel="noreferrer" target="_blank">
              Privacy Policy
            </a>
          </li>
          {/* <li className="list-inside list-disc py-2 hover:text-blue-600">
            <Link to={""}>Cookies Policy</Link>
          </li> */}
        </ul>

        <ul id="legal-links" className="text-xl">
          <h2 className="text-2xl font-bold py-4">Contact</h2>
          <li className="py-2 hover:text-blue-600 flex items-center gap-2">
            <span>
              <FaPhoneAlt />
            </span>
            <a href={`tel:${contactInfo.contactnumber}`}>
              {formatPhonenum(contactInfo.contactnumber)}
            </a>
          </li>
          <li className="py-2 hover:text-blue-600 flex items-center gap-2">
            <span>
              <GrMail />
            </span>
            <a href={`mailto:${contactInfo.contactemail}`}>{contactInfo.contactemail}</a>
          </li>
        </ul>
      </div>
      {/* Sub footer */}
      <SubFooter />
    </main>
  );
}
