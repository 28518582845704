import { Dispatch } from "@reduxjs/toolkit";
import {
  AddStaffModalAction,
  AddStaffModalActionPayload,
} from "../../../redux/reducers/Modal/displayManualStaffAddingModal";
import { TO_DISPLAY_MANUAL_STAFF_ADDING_MODAL } from "../../../redux/actions/action.types";

export default function displayManualStaffAddingModal(
  payload: AddStaffModalActionPayload,
  dispatch: Dispatch<AddStaffModalAction>
) {
  dispatch({
    type: TO_DISPLAY_MANUAL_STAFF_ADDING_MODAL,
    payload,
  });
}
