import { ViewAssignedStaffsActionPayload } from "../../../../interfaces/main";
import { TO_DISPLAY_VIEW_ASSIGNED_STAFFS_MODAL } from "../../actions/action.types";

export interface ViewAssignedStaffsAction {
  type: string;
  payload: ViewAssignedStaffsActionPayload;
}

const initialState: ViewAssignedStaffsActionPayload = {
  toDisplay: false,
  assignedStaffs: [],
  groupName: "",
};

const displayViewAssignedStaffsModal = (state = initialState, action: ViewAssignedStaffsAction) => {
  if (action.type === TO_DISPLAY_VIEW_ASSIGNED_STAFFS_MODAL) return action.payload;
  return state;
};

export default displayViewAssignedStaffsModal;
