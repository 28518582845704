import { axiosInstance } from "../../axiosConfig";

export default async function getPayerCopies(cartIds: string[]) {
  try {
    const res = await axiosInstance.post(
      "/get_copies/Form",
      {
        history_id_array: cartIds,
        copy_type: "payer copy",
      },
      {
        responseType: "blob",
      }
    );
    return { data: res.data, header: res.headers };
  } catch (err) {
    throw err;
  }
}
