import React from "react";
import { useSelector } from "react-redux";
import "./VerificationContainer.css";
import { EMAIL_SETTING } from "../../../lib/constants";
import { useState } from "react";

/*
The styling for the update button has been defined in the Settings css file
*/

interface Prop {
  settingType: string;
  handleUpdate: any;
}

export default function VerificationContainer({ settingType, handleUpdate }: Prop) {
  const style = useSelector((state: any) => state.themeChange);
  const [verificationCode, setVerificationCode] = useState("");

  const handleChange = (e: any) => {
    const { value } = e.target;
    setVerificationCode(value);
  };

  return (
    <main
      className="verification-container-main"
      style={{ borderLeft: `1px solid ${style.secondaryBorderColor}` }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleUpdate(verificationCode);
        }}
      >
        <div>
          <label htmlFor="verify">
            <h3>Verification code</h3>
          </label>
          <p style={{ padding: "0.5rem 0" }}>
            {settingType === EMAIL_SETTING
              ? "Please enter the verification code sent to your new email id"
              : "Please enter the verification code sent to your registered email id"}
          </p>
          <input
            id="verify"
            type="text"
            style={{ padding: "0.5rem 1rem" }}
            value={verificationCode}
            onChange={handleChange}
          />
        </div>
        <button
          type="submit"
          className="update-btn"
          disabled={!verificationCode}
          style={{
            background: style.btnSecondaryBg,
            color: style.btnFillFontColor,
            opacity: !verificationCode ? "0.5" : "1",
          }}
        >
          {settingType === EMAIL_SETTING && "Verify"}
        </button>
      </form>
    </main>
  );
}
