import React from "react";
import Select from "react-select";
import Navbar from "../components/navbar";
import "../styles/Pages/History.css";
import SelectStyle from "../lib/ReactSelectTheme/reactSelectStyle";
import { useDispatch, useSelector } from "react-redux";
import ReactSelectOptions from "../lib/utils/ReactSelectOptions";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { calCurrentTaxYear } from "../lib/calculateTaxYear";
import Handler from "../lib/Handlers/main";
import devLogInstance from "../lib/loggerConfig";
import SessionStorage from "../lib/LocalCaching/SessionStorage";
import FormTable from "../components/forms/FormTable/FormTable";
import TinMaskContainer from "../components/decorations/TinMaskContainer/TinMaskContainer";
import { useLocation } from "react-router-dom";
import handlePopup from "../lib/popup/handlePopup";
import { ERROR_POPUP, SUCCESS_POPUP } from "../lib/constants";
import { HistoryData } from "../../interfaces/main";
import { Action } from "../lib/utils/Action";
import { HISTORY_PAGE } from "../lib/routes";
import redirect from "../lib/RoutingMethods/redirect";
import loader from "../components/decorations/Loader";

export default function History() {
  const style = useSelector((state: any) => state.themeChange);
  const [year, setYear] = useState({
    label: calCurrentTaxYear(),
    value: calCurrentTaxYear(),
  });
  const [payer, setPayer] = useState({
    label: "",
    value: null,
  });
  const [payerDataArray, setPayerDataArray] = useState<any[]>([]);
  const [formData, setFormData] = useState<HistoryData[]>([]);
  const [forms, setForms] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const formHistorySelectedCartIds = useSelector((state: any) => state.formHistoryCartIds);
  const dispatch = useDispatch();
  const location = useLocation();
  const paymentStatus = new URLSearchParams(location.search).get("q");

  devLogInstance.log("FORMS: ");
  devLogInstance.log(forms);

  // devLogInstance.log("SELECTED FORM CART IDS");
  // devLogInstance.log(formHistorySelectedCartIds);

  useEffect(() => {
    if (paymentStatus === "success") {
      handlePopup("Payment successfull", SUCCESS_POPUP, dispatch);

      // Refresh the history page in mentioned time period
      const refreshIn = process.env.REACT_APP_HISTORY_REFRESH_IN_MS ?? "5000";
      setTimeout(() => {
        redirect(HISTORY_PAGE);
      }, parseInt(refreshIn));
    }
  }, [paymentStatus, dispatch]);

  // Call the get_all_history_data API and set the Payer data array
  useLayoutEffect(() => {
    // Start loading
    setIsLoading(true);

    Handler.handleGettingAllHistoryData(year.value).then((res) => {
      // Stop loading
      setIsLoading(false);

      devLogInstance.log("All HISTORY DATA: ");
      devLogInstance.log(res);
      // Set the payer data array
      res && setPayerDataArray(res.payerDataArray);
      // Set the form data of the first payer initially
      res && setFormData(res.allFormsForFirstPayer);
    });
    return () => {
      setPayerDataArray([]);
      setFormData([]);
      setForms([]);
    };
  }, [year.value]);

  // Select default payer
  const setDefaultPayer = useCallback(() => {
    const valObj = {
      label: payerDataArray[0]?.payer_name,
      value: payerDataArray[0],
    };
    setPayer(valObj);
  }, [payerDataArray]);

  // Check wether payer id exists in session storage
  useEffect(() => {
    if (!SessionStorage.exists("payer_id")) {
      // Set the first payer as the default payer
      setDefaultPayer();
    } else {
      // Select the cached payer
      const payer = SessionStorage.getCacheItem("payer_id");
      setPayer(payer);
    }

    return () => {
      // Remove any cached payer
      SessionStorage.removeCacheItem("payer_id");

      setPayer({
        label: "",
        value: null,
      });
    };
  }, [setDefaultPayer]);

  // Filter the form data and set each form type start and end idxs' whenever the form/history data changes
  useMemo(() => {
    if (formData.length !== 0) {
      let initialFormType = formData[0].form_type; // Initial value
      let startIdx = 0; // Initial value
      const forms: any[] = [];

      formData.forEach((form, i) => {
        const currentFormType = form.form_type;
        if (currentFormType !== initialFormType) {
          const obj = {
            formType: initialFormType,
            startIdx: startIdx,
            endIdx: i - 1,
          };
          forms.push(obj);
          startIdx = i;
          initialFormType = currentFormType;
        }
        if (i === formData.length - 1) {
          const obj = {
            formType: currentFormType,
            startIdx: startIdx,
            endIdx: i,
          };
          forms.push(obj);
          initialFormType = "";
          startIdx = 0;
        }
      });

      setForms(forms);
    }
  }, [formData]);

  // Year change event handler
  const handleYearChange = (option: any) => {
    // Handle the change
    setYear({
      label: option.label,
      value: option.value,
    });
  };

  // Payer Change handler
  const handlePayerChange = (option: any) => {
    // Handle the change
    // Cache the selected payer
    // SessionStorage.setCacheItem("payer_id", option);

    // Set the current payer
    setPayer({
      label: option.label,
      value: option.value,
    });

    // Get the form data for the selected payer
    const payerId = option.value.payer_id;
    const _year = year.value;

    // Start loading
    setIsLoading(true);

    Handler.handleGettingSpecificHistoryData(payerId, _year).then((res) => {
      // Stop loading
      setIsLoading(false);

      devLogInstance.log("HISTORY DATA OF PAYER " + option.label);
      devLogInstance.log(res);

      // Set the new form/history data
      setFormData(res.allFormsForSpecificPayer);
    });
  };

  // Bulk download event handler
  const handleBulkDownload = (options: any) => {
    const downloadType = options.value;
    devLogInstance.log(`Download Type: ${downloadType}`);

    if (formHistorySelectedCartIds.length > 0) {
      devLogInstance.log("Download for the selected ids: ");
      devLogInstance.log(formHistorySelectedCartIds);
      Action.cartIds = formHistorySelectedCartIds;
      Action.formDownload({ type: downloadType });
    } else {
      devLogInstance.log("No forms selected to download");
      handlePopup(
        "You must select atleast one or more forms to bulk download",
        ERROR_POPUP,
        dispatch
      );
    }
  };

  if (isLoading) return loader.start(style);

  return (
    <main style={{ position: "relative" }}>
      <Navbar />
      <div
        style={{
          paddingTop: "6rem",
          minHeight: "100vh",
        }}
      >
        <div className="history-filter-panel">
          <div className="year-and-payer">
            <div className="year">
              <label>Year</label>
              <Select
                value={year}
                options={ReactSelectOptions.yearOptions()}
                theme={new SelectStyle(style).customTheme}
                styles={new SelectStyle(style).customStyle}
                onChange={handleYearChange}
                isSearchable={false}
              />
            </div>
            <div className="payer">
              <label>Payer</label>
              <Select
                value={payer}
                options={ReactSelectOptions.payerOptions(payerDataArray)} // Use the payer data array to set the payer select options
                theme={new SelectStyle(style).customTheme}
                styles={new SelectStyle(style).customStyle}
                onChange={handlePayerChange}
                isSearchable={false}
                isDisabled={forms.length === 0}
              />
            </div>
          </div>
          <div className="bulk-download">
            <div>
              <Select
                value={{ label: "Bulk Download", value: null }}
                options={ReactSelectOptions.formDownloadOptions(formHistorySelectedCartIds.length)}
                theme={new SelectStyle(style).customTheme}
                styles={new SelectStyle(style).customStyle}
                isSearchable={false}
                onChange={handleBulkDownload}
                isDisabled={forms.length === 0}
              />
            </div>
          </div>
        </div>
        {forms.length > 0 ? (
          <>
            {/* Form table */}
            {forms.map((formObj, i) => {
              return (
                <FormTable
                  key={i}
                  form={formObj}
                  allFormData={formData}
                  tableId={i}
                  onCorrectionPage={false}
                />
              );
            })}

            {/* Tin mask switch */}
            <div className="tin-mask-container-wrapper" style={{ padding: "3rem" }}>
              <TinMaskContainer />
            </div>
          </>
        ) : (
          <div style={{ display: "flex", justifyContent: "center", padding: "10rem 2rem" }}>
            <h2 style={{ opacity: "0.4" }}>
              Currently there are no history records for this time period.
            </h2>
          </div>
        )}
      </div>
    </main>
  );
}
