import React, { useMemo, useState } from "react";
import { contactDisplayText } from "../../lib/languagePacks/en-us";
import { QUERY } from "../../pages/Contact";
import { ENQUIRY_CONTAINER } from "../../lib/constants";
import Handler from "../../lib/Handlers/main";
import { EnquiryData } from "../../../interfaces/main";
import getUserDetailsLocally from "../../lib/RetrieveUserDetailsLocally/getUserDetailsLocally";

export default function EnquiryContainer() {
  const [query, setQuery] = useState("");
  const [displaySubmitBtn, toDisplaySubmitBtn] = useState(false);

  useMemo(() => {
    // Display the submit button only when query body is not empty
    query ? toDisplaySubmitBtn(true) : toDisplaySubmitBtn(false);
  }, [query]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;
    setQuery(value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const enquiryData: EnquiryData = {
      name: getUserDetailsLocally().full_name,
      email: getUserDetailsLocally().name,
      phone: "",
      query: query,
    };
    Handler.handleEnquirySubmission(enquiryData, null, true);
    setQuery("");
  };

  return (
    <form
      id={ENQUIRY_CONTAINER}
      className="absolute flex flex-col gap-2 items-center justify-center bottom-8 -left-[20.5rem] text-black bg-taxeve-secondary-violet p-2 rounded-lg w-[20rem]"
      onSubmit={handleSubmit}
    >
      <textarea
        name="help-desk"
        id={QUERY}
        rows={5}
        placeholder={contactDisplayText.enquiryPlaceholder}
        className="w-[100%] border-[1px] border-slate-400 rounded-lg resize-none p-2 text-sm"
        onChange={handleChange}
        value={query}
      />
      {displaySubmitBtn && (
        <button type="submit" className="py-1 px-4 bg-taxeve-primary-violet text-white rounded-md">
          Send
        </button>
      )}
    </form>
  );
}
