import { REMOVE_ALL_RECIPIENTS_FOR_A_PAYER } from "../../actions/action.types";

// const initialState = {
//   payerId: 0,
//   toRemove: false,
// };

const toRemoveAllRecForPayer = (state = null, action: any) => {
  const type = action.type;
  if (type === REMOVE_ALL_RECIPIENTS_FOR_A_PAYER) {
    const payload = action.payload;
    if (payload !== null && payload !== undefined) {
      return payload === "ALL_PAYERS" ? { payerId: "ALL_PAYERS" } : { payerId: payload };
    }
    return state;
  }
  return state;
};

export default toRemoveAllRecForPayer;
