import React from "react";
import "../../styles/decorations/ScreenDisabler.css";

class ScreenDisabler {
  start() {
    return <div className="disabled-screen" />;
  }
}

const screenDisabler = new ScreenDisabler();

export default screenDisabler;
