import {
  RESET_TOTAL_CART_VALUES,
  SET_TOTAL_ONLINE_ACCESS,
  SET_TOTAL_POSTAL_MAILING,
  SET_TOTAL_STATE_FILING,
} from "../../redux/actions/action.types";

export function setTotalPM(stateHandler: any, occurence: number) {
  stateHandler({
    type: SET_TOTAL_POSTAL_MAILING,
    payload: occurence,
  });
}

export function setTotalOA(stateHandler: any, occurence: number) {
  stateHandler({
    type: SET_TOTAL_ONLINE_ACCESS,
    payload: occurence,
  });
}

export function setTotalSF(stateHandler: any, occurence: number) {
  stateHandler({
    type: SET_TOTAL_STATE_FILING,
    payload: occurence,
  });
}

export function resetTotalCartValues(stateHandler: any) {
  stateHandler({
    type: RESET_TOTAL_CART_VALUES,
  });
}
