import React from "react";
import { info } from "../../../../lib/languagePacks/en-us";
import { GoDotFill } from "react-icons/go";

interface Props {
  withoutIncomeField: boolean;
  stateTaxBoxNo: string;
  stateBoxNo: string;
  stateIdBoxNo?: string;
  stateIncomeBoxNo?: string;
}

export default function StateFilingModalBody(props: Props) {
  return (
    <div className="px-4 py-4">
      <ul className="flex flex-col gap-6 text-lg">
        <li className="flex items-center gap-2">
          <GoDotFill />
          {!props.withoutIncomeField ? (
            <p>
              {info.stateFiling.withIncome.income}{" "}
              <span className="font-bold">Box {props.stateIncomeBoxNo}.</span>
            </p>
          ) : (
            <p>
              {info.stateFiling.withoutIncome.state}{" "}
              <span className="font-bold">Box {props.stateBoxNo}.</span>
            </p>
          )}
        </li>
        <li>
          <div className="flex items-center gap-2">
            <GoDotFill />
            {!props.withoutIncomeField ? (
              <p>
                {info.stateFiling.withIncome.stateAndStateNo}{" "}
                <span className="font-bold">Box {props.stateBoxNo}.</span>
              </p>
            ) : (
              <p>
                {info.stateFiling.withoutIncome.stateNo}{" "}
                <span className="font-bold">Box {props.stateIdBoxNo}.</span>
              </p>
            )}
          </div>
          <em className="px-6 font-bold">({info.stateFiling.noStateNo})</em>
        </li>
        <li className="flex items-center gap-2">
          <GoDotFill />
          <p>
            {info.stateFiling.tax} <span className="font-bold">Box {props.stateTaxBoxNo}.</span>
          </p>
        </li>
      </ul>
    </div>
  );
}
