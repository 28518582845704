import { SET_DATA_TO_EDIT } from "../../actions/action.types";

const dataToEdit = (state = null, action: any) => {
  if (action.type === SET_DATA_TO_EDIT) {
    return action.payload;
  }
  return state;
};

export default dataToEdit;
