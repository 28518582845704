import React from "react";
import { formFieldErrMsg, payerDetails } from "../../../../lib/languagePacks/en-us";
import Select from "react-select";
import { arrangeStates, arrangeCountries } from "../../../../lib/form/arrangeSelectValues";
import states from "../../../../lib/json/states.json";
import countries from "../../../../lib/json/countries.json";
import { useSelector, useDispatch } from "react-redux";
import customFilter from "../../../../lib/form/utils/reactSelectFilter";
import validateFALimit from "../../../../lib/form/utils/ForeignAddressUtils/validateFALimit";
import {
  TO_SHOW_AL_WARNING,
  TO_SHOW_FA_WARNING,
  TO_SHOW_FORM_VALIDATION_ERR,
} from "../../../../redux/actions/action.types";
import {
  AL_WARNING_INDICATOR,
  FA_WARNING_INDICATOR,
  PAYER_ADDRESS_1,
  PAYER_CITY,
  PAYER_COUNTRY,
  PAYER_STATE,
  PAYER_ZIPCODE,
} from "../../../../lib/constants";
import Indicator from "../../../Indicator";
import validateAddressLineLimit from "../../../../lib/form/utils/AddressLine/validateAddressLineLimit";
import SelectStyle from "../../../../lib/ReactSelectTheme/reactSelectStyle";

function PayerAddressBlock({
  globalFormData,
  handleChange,
  setGlobalFormData,
  zipcode,
  isCorrection,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  const showFAWarning = useSelector((state: any) => state.showFAWarning);
  const warnMessage = useSelector((state: any) => state.FAWarnMsg);
  const showALWarning = useSelector((state: any) => state.showALWarning);
  const ALWarnMsg = useSelector((state: any) => state.ALWarnMsg);
  const showValidationError = useSelector((state: any) => state.showValidationErr);
  const dispatch = useDispatch();

  // Update the global payer state data
  const updateGlobalPayerState = (selectedObj: any) => {
    const splits = selectedObj.label.split(" ");
    const payerStateCode = splits[splits.length - 1];
    setGlobalFormData({
      ...globalFormData,
      payer_state: payerStateCode.replace(/\(/g, "").replace(/\)/g, ""),
    });

    // Remove any mandatory field warning from payer state select field
    dispatch({
      type: TO_SHOW_FORM_VALIDATION_ERR,
      payload: {
        type: PAYER_STATE,
        toShowErr: false,
      },
    });
  };

  // Update the global payer country data
  const updateGlobalPayerCountry = (selectedObj: any) => {
    setGlobalFormData({
      ...globalFormData,
      payer_country: selectedObj.value,
    });

    // Remove any mandatory field warning from payer country select field
    dispatch({
      type: TO_SHOW_FORM_VALIDATION_ERR,
      payload: {
        type: PAYER_COUNTRY,
        toShowErr: false,
      },
    });
  };

  // Handle input focus
  const handleInputFocus = (e: any) => {
    const { id } = e.target;
    if (globalFormData.payer_is_foreign_address) {
      // Run this block only when the payer is foreign address
      const { status } = validateFALimit(
        globalFormData.payer_city,
        globalFormData.payer_state,
        globalFormData.payer_zipcode
      );
      switch (id) {
        case "city":
          if (status === true) {
            // Set the show warning state to true
            dispatch({
              type: TO_SHOW_FA_WARNING,
              payload: {
                ...showFAWarning,
                onPayerCity: true,
              },
            });
          }
          break;

        case "state":
          if (status === true) {
            // Set the show warning state to true
            dispatch({
              type: TO_SHOW_FA_WARNING,
              payload: {
                ...showFAWarning,
                onPayerState: true,
              },
            });
          }
          break;

        case "zip-code":
          if (status === true) {
            // Set the show warning state to true
            dispatch({
              type: TO_SHOW_FA_WARNING,
              payload: {
                ...showFAWarning,
                onPayerZipcode: true,
              },
            });
          }
          break;

        default:
          break;
      }
    }
  };

  // Handle address line focus
  const handleALFocus = (e: any) => {
    const { id } = e.target;
    const { status } = validateAddressLineLimit(
      globalFormData.payer_address_line1,
      globalFormData.payer_address_line2
    );
    switch (id) {
      case "address-1":
        if (status === true) {
          // Set the show warning state to true
          dispatch({
            type: TO_SHOW_AL_WARNING,
            payload: {
              ...showALWarning,
              onPayerAL1: true,
            },
          });
        }
        break;

      case "address-2":
        if (status === true) {
          // Set the show warning state to true
          dispatch({
            type: TO_SHOW_AL_WARNING,
            payload: {
              ...showALWarning,
              onPayerAL2: true,
            },
          });
        }
        break;

      default:
        break;
    }
  };

  //  Handle input blur
  const handleInputBlur = (e: any) => {
    const { id } = e.target;
    switch (id) {
      case "city":
        //  Hide any displayed warning
        dispatch({
          type: TO_SHOW_FA_WARNING,
          payload: {
            ...showFAWarning,
            onPayerCity: false,
          },
        });
        break;

      case "state":
        //  Hide any displayed warning
        dispatch({
          type: TO_SHOW_FA_WARNING,
          payload: {
            ...showFAWarning,
            onPayerState: false,
          },
        });
        break;

      case "zip-code":
        //  Hide any displayed warning
        dispatch({
          type: TO_SHOW_FA_WARNING,
          payload: {
            ...showFAWarning,
            onPayerZipcode: false,
          },
        });
        break;

      case "address-1":
        //  Hide any displayed warning
        dispatch({
          type: TO_SHOW_AL_WARNING,
          payload: {
            ...showALWarning,
            onPayerAL1: false,
          },
        });
        break;

      case "address-2":
        //  Hide any displayed warning
        dispatch({
          type: TO_SHOW_AL_WARNING,
          payload: {
            ...showALWarning,
            onPayerAL2: false,
          },
        });
        break;

      default:
        break;
    }
  };

  // // Custom theme for the dd
  // const customTheme = (theme) => ({
  //   ...theme,
  //   borderRadius: "0.5rem",
  //   colors: {
  //     ...theme.colors,
  //     primary25: style.ddOptionHover,
  //   },
  // });

  // const customStyle = {
  //   control: (styles) => ({
  //     ...styles,
  //     border: `1px solid ${style.ddBorder}`,
  //   }),
  //   option: (styles, { isSelected, isFocused }) => {
  //     return {
  //       ...styles,
  //       color: isSelected && style.isSelectedColor,
  //       backgroundColor: isSelected
  //         ? style.isSelectedBg
  //         : isFocused && style.ddOptionHover,
  //     };
  //   },
  // };

  return (
    <div className="address-block">
      <div className="foreign-address-confirmation">
        <input
          type="checkbox"
          id="foreign-address-checkbox"
          onChange={handleChange}
          checked={globalFormData.payer_is_foreign_address}
          disabled={isCorrection}
        />
        <label>{payerDetails.foreignAddressConfirmText} ?</label>
      </div>

      <div className="address-wrapper">
        <div className="address-line-1">
          <label htmlFor="address-1">
            {payerDetails.addressLine1Placeholder}{" "}
            <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
          </label>
          <input
            className="payer-details-input"
            id="address-1"
            value={globalFormData.payer_address_line1}
            onChange={handleChange}
            onFocus={handleALFocus}
            onBlur={handleInputBlur}
            autoComplete="off"
            disabled={isCorrection}
          />
          {showALWarning.onPayerAL1 && (
            <Indicator type={AL_WARNING_INDICATOR} message={ALWarnMsg} />
          )}
          {showValidationError.onPayerAddress1 && (
            <Indicator
              type={PAYER_ADDRESS_1}
              message={formFieldErrMsg.nec1099.payer.emptyAddress1}
            />
          )}
        </div>
        <div className="address-line-2">
          <label htmlFor="address-2">{payerDetails.addressLine2Placeholder}</label>
          <input
            className="payer-details-input"
            id="address-2"
            value={globalFormData.payer_address_line2}
            onChange={handleChange}
            onFocus={handleALFocus}
            onBlur={handleInputBlur}
            autoComplete="off"
            disabled={isCorrection}
          />
          {showALWarning.onPayerAL2 && (
            <Indicator type={AL_WARNING_INDICATOR} message={ALWarnMsg} />
          )}
        </div>
      </div>

      <div className="city-state-wrapper">
        <div className="city-wrapper">
          <label htmlFor="city">
            {globalFormData.payer_is_foreign_address
              ? payerDetails.foreignCityPlaceholder
              : payerDetails.cityPlaceholder}{" "}
            <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
          </label>
          <input
            className="payer-details-input"
            id="city"
            value={globalFormData.payer_city}
            onChange={handleChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            autoComplete="off"
            disabled={isCorrection}
          />
          {showFAWarning.onPayerCity && (
            <Indicator type={FA_WARNING_INDICATOR} message={warnMessage} />
          )}
          {showValidationError.onPayerCity && (
            <Indicator type={PAYER_CITY} message={formFieldErrMsg.nec1099.payer.emptyCity} />
          )}
        </div>
        <div className="state-wrapper">
          <label htmlFor="state">
            {globalFormData.payer_is_foreign_address
              ? payerDetails.foreignStatePlaceholder
              : payerDetails.statePlaceholder}{" "}
            {!globalFormData.payer_is_foreign_address && (
              <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
            )}
          </label>
          {!globalFormData.payer_is_foreign_address ? (
            <Select
              theme={new SelectStyle(style).customTheme}
              styles={new SelectStyle(style).customStyle}
              id="state"
              options={arrangeStates(states)}
              onChange={updateGlobalPayerState}
              filterOption={customFilter}
              value={arrangeStates(states).filter((option: any) => {
                const splits = option.label.split(" ");
                const payerStateCode = splits[splits.length - 1];
                return (
                  payerStateCode.replace(/\(/g, "").replace(/\)/g, "") ===
                  globalFormData.payer_state
                );
              })}
              placeholder=""
              isDisabled={isCorrection}
            />
          ) : (
            <input
              className="payer-details-input"
              id="state"
              autoComplete="off"
              value={globalFormData.payer_state}
              onChange={handleChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              disabled={isCorrection}
            />
          )}
          {showFAWarning.onPayerState && (
            <Indicator type={FA_WARNING_INDICATOR} message={warnMessage} />
          )}
          {showValidationError.onPayerState && !globalFormData.payer_is_foreign_address && (
            <Indicator type={PAYER_STATE} message={formFieldErrMsg.nec1099.payer.emptyState} />
          )}
        </div>
      </div>

      <div className="zip-country-wrapper">
        <div
          className="zip-wrapper"
          style={{ width: !globalFormData.payer_is_foreign_address ? "48%" : "initial" }}
        >
          <label htmlFor="zip-code">
            {globalFormData.payer_is_foreign_address
              ? payerDetails.foreignZipCodePlaceholder
              : payerDetails.zipCodePlaceholder}{" "}
            <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
          </label>
          <input
            className="payer-details-input"
            type="text"
            id="zip-code"
            autoComplete="off"
            value={zipcode.payer}
            onChange={handleChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            disabled={isCorrection}
          />
          {showFAWarning.onPayerZipcode && (
            <Indicator type={FA_WARNING_INDICATOR} message={warnMessage} />
          )}

          {showValidationError.onPayerZipcode && (
            <Indicator type={PAYER_ZIPCODE} message={formFieldErrMsg.nec1099.payer.emptyZipcode} />
          )}
        </div>

        {globalFormData.payer_is_foreign_address && (
          <div className="country-wrapper">
            <label htmlFor="country">
              {payerDetails.countryPlaceholder}{" "}
              <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
            </label>
            <Select
              theme={new SelectStyle(style).customTheme}
              styles={new SelectStyle(style).customStyle}
              id="state"
              options={arrangeCountries(countries)}
              onChange={updateGlobalPayerCountry}
              filterOption={customFilter}
              value={arrangeCountries(countries).filter((option: any) => {
                return option.value === globalFormData.payer_country;
              })}
              placeholder=""
              isDisabled={isCorrection}
            />
            {showValidationError.onPayerCountry && (
              <Indicator
                type={PAYER_COUNTRY}
                message={formFieldErrMsg.nec1099.payer.emptyCountry}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default PayerAddressBlock;
