import React, { ReactNode, createContext, useState } from "react";
import Navbar from "../components/navbar";
import AddBulkBusiness from "../components/BusinessSettingsRelated/AddBulkBusiness";
import SidePanel from "../components/decorations/SidePanel";
import { RiUserSettingsFill } from "react-icons/ri";
import AddBulkRecipient from "../components/BusinessSettingsRelated/AddBulkRecipient";
import ManageStaff from "../components/BusinessSettingsRelated/ManageStaff";
import ManageGroup from "../components/BusinessSettingsRelated/ManageGroup";
import isUserStaff from "../lib/utils/isUserStaff";

interface PanelOptionReactState {
  option: number;
  selectOption: React.Dispatch<React.SetStateAction<number>>;
}

const panelOptionSelectionMap: Record<number, ReactNode> = {
  0: <AddBulkBusiness />,
  1: <AddBulkRecipient />,
  2: <ManageStaff />,
  3: <ManageGroup />,
};

export const PanelOptionContext = createContext<PanelOptionReactState | null>(null);

export default function BusinessSettings() {
  const [panelOption, selectPanelOption] = useState(0);

  const panelOptions = !isUserStaff()
    ? ["Add Business", "Add Recipient", "Manage Members", "Manage Groups"]
    : ["Add Business", "Add Recipient"];

  return (
    <main className="page">
      <Navbar />
      <div className="business-settings pt-24 h-screen flex">
        <PanelOptionContext.Provider
          value={{ option: panelOption, selectOption: selectPanelOption }}
        >
          <SidePanel
            heading="Business Settings"
            headingIcon={<RiUserSettingsFill className="text-2xl" />}
            options={panelOptions}
          />

          <section className="panel-selection-content w-[85%] overflow-auto">
            {panelOptionSelectionMap[panelOption]}
          </section>
        </PanelOptionContext.Provider>
      </div>
    </main>
  );
}
