import React from "react";
import { FiLogOut } from "react-icons/fi";
import logoutRecipient from "../../lib/OnlineAccessRelatedUtils/logoutRecipient";
import { useDispatch } from "react-redux";

export default function LogoutRecipient() {
  const dispatch = useDispatch();

  // Handle logout
  const handleLogout = () => {
    logoutRecipient(dispatch);
  };

  return (
    <h2
      className="flex items-center gap-2 px-6 cursor-pointer underline underline-offset-2 text-taxeve-primary-blue"
      onClick={handleLogout}
    >
      <span className="pb-1">Logout</span> <FiLogOut />
    </h2>
  );
}
