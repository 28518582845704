import React from "react";
import { useDispatch, useSelector } from "react-redux";
import displayInfoModal from "../../../../lib/StateSetters/displayInfoModal";
import SNSModalBody from "../NonSupportServiceModalBody/SNSModalBody";
import { NO_SUPPORT_HEADING } from "../../../../lib/constants";

interface Props {
  message: string | React.ReactNode;
  heading: string;
}

const CONTINUE = "CONTINUE";

export default function InfoModal({ heading, message }: Props) {
  const style = useSelector((state: any) => state.themeChange);
  const stateSetter = useDispatch();

  // Handle click event
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const { id } = e.currentTarget;

    switch (id) {
      case CONTINUE:
        // Close the info modal
        displayInfoModal(false, message, heading, stateSetter);
        break;

      default:
        break;
    }
  };

  return (
    <div className="fixed top-[20%] modal-wrapper">
      <div
        className="rounded-xl overflow-hidden"
        style={{ background: style.siteBg, boxShadow: `4px 4px 20px ${style.boxShadowColor}` }}
      >
        <div className="modal-heading" style={{ background: style.modalTopPanelBg }}>
          <h2>{heading}</h2>
        </div>
        {!heading.includes(NO_SUPPORT_HEADING) && <>{message}</>}
        {heading.includes(NO_SUPPORT_HEADING) && (
          <SNSModalBody message={typeof message === "string" ? message : ""} />
        )}
        <footer className="flex justify-end px-4 py-6">
          <button
            id={CONTINUE}
            className="px-6 py-3 rounded-lg bg-taxeve-primary-blue text-white"
            onClick={handleClick}
          >
            I Understand
          </button>
        </footer>
      </div>
    </div>
  );
}
