import { CALL_EMAIL_REQUIRED_MODAL } from "../../actions/action.types";

const toCallEmailRequiredModal = (state = false, action: any) => {
  if (action.type === CALL_EMAIL_REQUIRED_MODAL) {
    return action.payload;
  }
  return state;
};

export default toCallEmailRequiredModal;
