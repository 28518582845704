import React from "react";
import { useSelector } from "react-redux";
import "./ConfirmButtons.css";

export default function ConfirmButtons({ handleConfirmation }: any) {
  const style = useSelector((state: any) => state.themeChange);
  // Handle click
  const handleClick = (e: any) => {
    const { id: status } = e.target;
    if (status === "CANCEL") {
      // Close the modal
    } else if (status === "CONTINUE") {
      // handleConfirmation
      handleConfirmation();
    }
  };
  return (
    <main className="confirm-btn-main flex">
      <button
        id="CANCEL"
        onClick={handleClick}
        style={{
          background: style.btnSecondaryBg,
          color: style.btnFillFontColor,
        }}
      >
        No, cancel
      </button>
      <button
        id="CONTINUE"
        onClick={handleClick}
        style={{ background: style.btnBg, color: style.btnFillFontColor }}
      >
        Yes, continue
      </button>
    </main>
  );
}
