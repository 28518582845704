import React from "react";
import Select from "react-select";
import ReactSelectOptions from "../lib/utils/ReactSelectOptions";
import SelectStyle from "../lib/ReactSelectTheme/reactSelectStyle";
import { useSelector } from "react-redux";

interface Props extends React.HTMLProps<HTMLDivElement> {
  year: { label: number; value: number };
  handleYearChange: (option: any) => void;
  noLabel?: boolean;
}

export default function YearSelector(props: Props) {
  const style = useSelector((state: any) => state.themeChange);

  return (
    <div className={`${props.className}`}>
      <div className={`year`}>
        {!props.noLabel && <label>Year</label>}
        <Select
          value={props.year}
          options={ReactSelectOptions.yearOptions()}
          theme={new SelectStyle(style).customTheme}
          styles={new SelectStyle(style).customStyle}
          onChange={props.handleYearChange}
          isSearchable={false}
        />
      </div>
    </div>
  );
}
