import { axiosInstance } from "../axiosConfig";
import retrieveToken from "../authRequests/retrieveTokens";

export async function getPayerNames() {
  try {
    const { data } = await axiosInstance.get("/payer_names", {
      headers: {
        auth_token: retrieveToken("auth_token"),
      },
    });
    return data;
  } catch (err) {
    throw err;
  }
}

export async function getPayerDetails(payerId: string, recNotNeeded: boolean) {
  try {
    const { data } = await axiosInstance.post("/payer_details", {
      payer_id: payerId,
      recipient_not_needed: recNotNeeded,
    });
    return data;
  } catch (err) {
    throw err;
  }
}
