import {
  SET_MODAL_HEADING,
  SET_MODAL_MESSAGE,
  TO_DISPLAY_MODAL,
} from "../../redux/actions/action.types";
import { E_FILING_ERROR_MODAL } from "../constants";

export default function displayErrorModal(heading: any, message: any, stateSetter: any) {
  // Display the modal
  stateSetter({
    type: TO_DISPLAY_MODAL,
    payload: {
      toDisplay: true,
      modaltype: E_FILING_ERROR_MODAL,
    },
  });
  // Set the displayy message
  stateSetter({
    type: SET_MODAL_MESSAGE,
    payload: message,
  });
  // Set the display heading
  stateSetter({
    type: SET_MODAL_HEADING,
    payload: heading,
  });
}
