import React from "react";
import Navbar from "../components/navbar";
import { TEMPLATES_DOWNLOAD } from "../lib/routes";
import {
  FORM1099_B,
  FORM1099_DIV,
  FORM1099_INT,
  FORM1099_K,
  FORM1099_MISC,
  FORM1099_NEC,
  // FORM1099_R,
  FORM1099_S,
} from "../lib/constants";
import Card from "../components/decorations/Card/Card";
import DownloadTemplateBtn from "../components/UploadPage/AddFile/TemplateDownloader/DownloadTemplateBtn/DownloadTemplateBtn";

const forms = [
  {
    title: FORM1099_NEC,
  },
  {
    title: FORM1099_MISC,
  },
  {
    title: FORM1099_INT,
  },
  {
    title: FORM1099_DIV,
  },
  // TODO: Enable when 1099-R is supported
  // {
  //   title: FORM1099_R,
  // },
  {
    title: FORM1099_B,
  },
  {
    title: FORM1099_K,
  },
  {
    title: FORM1099_S,
  },
];

export default function TemplatesDownloadPage() {
  return (
    <div>
      <Navbar page={TEMPLATES_DOWNLOAD} />
      <section className="banner">
        <div className="nested-div-in-banner">
          <h1>Download Form 1099 templates</h1>
        </div>
      </section>
      <section className="flex flex-wrap justify-center gap-20 py-16">
        {forms.map((formObj, i) => {
          return (
            <Card
              key={i}
              heading={formObj.title}
              description=""
              buttons={[
                <DownloadTemplateBtn
                  key={i}
                  payerId=""
                  formType={formObj.title.replace("-", "")}
                />,
              ]}
            />
          );
        })}
      </section>
    </div>
  );
}
