import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableContent from "./TableContent/TableContent";
import "../Table.component.css";
import { SET_SELECTED_REC_DATA } from "../../../../../redux/actions/action.types";
// import logger from "../../../../../logger";
import setSelectedRecData from "../../../../../lib/utils/setSelectedRecData";
import { FaMinusSquare } from "react-icons/fa";
import Bulkaction from "../../../../../lib/utils/BulkAction";
import logger from "../../../../../logger";

export default function Tablechild({ formType, stidx, endIdx, formIdx }: any) {
  const recData = useSelector((state: any) => state.rpRecipientData);
  const [filteredRecData, setFilteredRecData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [semiChecked, setSemiChecked] = useState(false);
  // const selectedRecData = useSelector((state: any) => state.selectedRecData);
  const noDefault = useSelector((state: any) => state.noDefault);
  const bulkActionItems = useSelector((state: any) => state.bulkActionItems);
  const dispatch = useDispatch();
  // logger.log(selectedRecData);

  // Filter the recData as per the formType
  useEffect(() => {
    const filteredList = recData.slice(stidx, endIdx + 1);
    setFilteredRecData(filteredList);
    // console.log(formType, stidx, endIdx, filteredList.length, filteredList);
  }, [recData, stidx, endIdx]);

  // Handle click
  const handleClick = (formId: any, idx: number) => {
    dispatch({
      type: SET_SELECTED_REC_DATA,
      payload: {
        recData: recData,
        selectedRecordId: formId,
        selectedRecordIdx: idx,
      },
    });
  };

  // Set the first recipient record as default if no default is not true
  useEffect(() => {
    if (recData.length !== 0 && !noDefault) {
      const payload = {
        recData: recData,
        selectedRecordId: recData[0].cartId,
        selectedRecordIdx: 0,
      };
      logger.log("Selecting new default recipient");
      setSelectedRecData(dispatch, payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recData]);

  // Select/Deselect all records under a table child
  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (isChecked) {
      for (let i = stidx; i <= endIdx; i++) {
        Bulkaction.markAsChecked(dispatch, formType, i, recData[i], formIdx);
      }
    } else {
      for (let i = stidx; i <= endIdx; i++) {
        Bulkaction.markAsUnchecked(dispatch, formType, i, recData[i], formIdx);
      }
    }
    // return ()=>
    // {
    //   setIsChecked(!isChecked)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked, dispatch, stidx, endIdx]);

  // Type of check(all, none, semi)
  useEffect(() => {
    if (bulkActionItems.length !== 0) {
      if (bulkActionItems[formIdx]?.data.length === filteredRecData.length) {
        setIsChecked(true);
        setSemiChecked(false);
      } else setSemiChecked(true);
      if (bulkActionItems[formIdx]?.data.length === 0 || bulkActionItems[formIdx] === undefined) {
        setSemiChecked(false);
        setIsChecked(false);
      }
    }
  }, [bulkActionItems, formIdx, filteredRecData]);

  // Clear any/all checked records
  const handleClearChecked = () => {
    for (let i = stidx; i <= endIdx; i++) {
      Bulkaction.markAsUnchecked(dispatch, formType, i, recData[i], formIdx);
    }
  };

  return (
    <div>
      <div style={{ padding: "0.5rem 0" }}>
        <h3 style={{ fontSize: "1.25rem" }}>Form-{formType}</h3>
      </div>
      <table>
        <thead>
          <tr>
            <th className="checkbox-container">
              {!semiChecked ? (
                <input type="checkbox" checked={isChecked} onChange={handleChange} />
              ) : (
                <div
                  onClick={handleClearChecked}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    padding: "0 0.2rem",
                  }}
                >
                  <h3 style={{ fontSize: "1.1rem" }}>
                    <FaMinusSquare style={{ color: "blue" }} />
                  </h3>
                </div>
              )}
            </th>
            <th>Recipient Details</th>
          </tr>
        </thead>
        {filteredRecData.length !== 0 &&
          filteredRecData.map((dataObj: any, i: number) => {
            return (
              <TableContent
                key={i}
                formIdx={formIdx}
                formType={formType}
                recName={dataObj.recipient_name}
                recNameCont={dataObj.recipient_name_cont}
                recTin={dataObj.recipient_tin}
                recTinType={dataObj.recipient_tin_type}
                correctionType={dataObj.correction_type}
                idx={stidx > 0 ? i + stidx : i}
                formId={dataObj.cartId}
                onClickHandler={handleClick}
                isAllChecked={isChecked}
              />
            );
          })}
      </table>
    </div>
  );
}
