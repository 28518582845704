import { TO_CLEAR } from "../actions/action.types";

const inititalClearStatusState = {
  group_state1: false,
  group_state2: false,
};

export const clearDataStatus = (state = inititalClearStatusState, action: any) => {
  if (action.type === TO_CLEAR) return action.payload;
  else return state;
};
