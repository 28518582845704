import React from "react";
import DownloadTemplateInstr from "../DownloadTemplateInstr";
import { FileUploader } from "react-drag-drop-files";
import { useFileUpload } from "../../lib/customHooks";
import Dropbox from "../UploadPage/AddFile/Dropbox/Dropbox";
import SubmitButton from "../SubmitButton/SubmitButton";
import { PAGE_UPLOAD } from "../../lib/constants";
import { calCurrentTaxYear } from "../../lib/calculateTaxYear";

const instructions = [
  "Download the Tax Eve template for adding business' in bulk",
  "Fill the downloaded template and upload the file in the upload section below",
];

export default function AddBulkBusiness() {
  const {
    originalFile: file,
    handleChange,
    handleError,
    handleSizeError,
    handleFileUpload,
  } = useFileUpload({
    payerId: "",
    userId: "",
    formType: "business",
    taxYear: calCurrentTaxYear(),
    noForm: true,
  });
  return (
    <div className="px-8 py-6 flex flex-col justify-center gap-4">
      <DownloadTemplateInstr instructions={instructions} templateFor="business" />
      <FileUploader
        children={<Dropbox file={file} />}
        multiple={false}
        types={["xlsx", "csv"]}
        handleChange={handleChange}
        maxSize={20}
        onTypeError={handleError}
        onSizeError={handleSizeError}
      />
      <SubmitButton pageType={PAGE_UPLOAD} handleSubmit={handleFileUpload} />
    </div>
  );
}
