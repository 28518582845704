import { SET_ZIPCODE, PAYER_ZIP, REC_ZIP } from "../../../../redux/actions/action.types";

export default function updateZipDisplay(zip: any, type: any, handleUpdate: any) {
  if (type === "payer") {
    // Update the payer zip code
    handleUpdate({
      type: SET_ZIPCODE,
      zipType: PAYER_ZIP,
      payload: zip,
    });
  } else if (type === "recipient") {
    // update the recipient zip code
    handleUpdate({
      type: SET_ZIPCODE,
      zipType: REC_ZIP,
      payload: zip,
    });
  }
}
