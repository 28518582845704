import React from "react";
import { NavLinkProps } from "../../../interfaces/main";
import { Link } from "react-router-dom";
import {
  CONTACT_US_PAGE,
  LANDING_PAGE,
  PRICING_PAGE,
  WHY_CHOOSE_US_PAGE,
  _1099_FORMS_PAGE,
} from "../../lib/routes";

export default function NavLinks({ onLandingpage, currentpage }: NavLinkProps) {
  return (
    <ul className="flex flex-col sm:flex-row gap-8 font-semibold">
      {!onLandingpage && (
        <li className="hover:text-taxeve-primary-violet border-b-[1px] border-slate-400 sm:border-none">
          <Link to={LANDING_PAGE}>Home</Link>
        </li>
      )}
      <li
        className={`${
          currentpage === _1099_FORMS_PAGE ? "text-taxeve-primary-violet" : "text-black"
        }  hover:text-taxeve-primary-violet border-b-[1px] border-slate-400 sm:border-none`}
      >
        <Link to={_1099_FORMS_PAGE}>1099 Forms</Link>
      </li>
      <li
        className={`${
          currentpage === WHY_CHOOSE_US_PAGE ? "text-taxeve-primary-violet" : "text-black"
        }  hover:text-taxeve-primary-violet border-b-[1px] border-slate-400 sm:border-none`}
      >
        <Link to={WHY_CHOOSE_US_PAGE}>Why Choose Us?</Link>
      </li>
      <li
        className={`${
          currentpage === PRICING_PAGE ? "text-taxeve-primary-violet" : "text-black"
        }  hover:text-taxeve-primary-violet border-b-[1px] border-slate-400 sm:border-none`}
      >
        <Link to={PRICING_PAGE}>Pricing</Link>
      </li>
      <li
        className={`${
          currentpage === CONTACT_US_PAGE ? "text-taxeve-primary-violet" : "text-black"
        }  hover:text-taxeve-primary-violet border-b-[1px] border-slate-400 sm:border-none`}
      >
        <Link to={CONTACT_US_PAGE}>Contact Us</Link>
      </li>
    </ul>
  );
}
