import {
  REMOVE_ALL_RECIPIENTS_FOR_A_PAYER,
  SELECT_ALL_RECIPIENTS_FOR_A_PAYER,
} from "../../redux/actions/action.types";

export function selectAllRecForPayer(stateSetter: any, payload: any) {
  stateSetter({
    type: SELECT_ALL_RECIPIENTS_FOR_A_PAYER,
    payload: payload,
  });
}

export function removeAllRecForPayer(stateSetter: any, payload: any) {
  stateSetter({
    type: REMOVE_ALL_RECIPIENTS_FOR_A_PAYER,
    payload: payload,
  });
}
