import { AdminPriceData } from "../../../interfaces/main";
import { axiosInstance } from "../axiosConfig";

export default async function editAdminPrice(adminPricedata: AdminPriceData) {
  try {
    const { data } = await axiosInstance.post("/admin/edit_price", adminPricedata);
    return data;
  } catch (err) {
    throw err;
  }
}
