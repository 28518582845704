import React from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
// import { useSelector } from "react-redux";
import contactInfo from "../lib/json/contactInfo.json";
import "../styles/ContactCreds.css";
import formatPhonenum from "../lib/form/utils/formatPhone";

export default function ContactCreds() {
  // const style = useSelector((state: any) => state.themeChange);
  return (
    <div className="contact-creds-container hidden xl:flex">
      <div className="phone flex items-center">
        <h2>
          <BsFillTelephoneFill className="text-taxeve-primary-violet" />
        </h2>
        <h2 className="font-normal">{formatPhonenum(contactInfo.contactnumber)}</h2>
      </div>
      <div className="email flex items-center">
        <h2>
          <AiOutlineMail className="text-taxeve-primary-violet" />
        </h2>
        <h2 className="font-normal">{contactInfo.contactemail}</h2>
      </div>
    </div>
  );
}
