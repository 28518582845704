import React, { useState, useEffect } from "react";
import StyleSelectionCanvas from "./StyleSelectionCanvas";
import { useScreenshot } from "../../../lib/customHooks";
import devLogInstance from "../../../lib/loggerConfig";
import SignatureDrawingCanvas from "./SignatureDrawingCanvas";
import getFormattedDate from "../../../lib/utils/getFormattedDate";

interface Props {
  setSignatureDataUrl: React.Dispatch<React.SetStateAction<string | null>>;
  toDisplayModal: React.Dispatch<React.SetStateAction<boolean>>;
  recName: string;
}

export default function ESigningModal({ setSignatureDataUrl, toDisplayModal, recName }: Props) {
  /*
      0 -> Signature by typing and selecting from a given style
      1 -> Signature by manually drawing it
  */
  const [signatureType, setSignatureType] = useState(0);
  const [name, setName] = useState(recName);
  const [ref1, setRef1] = useState<React.MutableRefObject<HTMLElement | null> | null>(null);
  const [ref2, setRef2] = useState<any | null>(null);
  const [image, takeScreenshot] = useScreenshot();
  const [isChecked, toCheck] = useState(false);
  const [isDisabled, toDisable] = useState(isChecked);

  // Monitor click events and close the modal if clicked outside the modal
  useEffect(() => {
    const handleClick = (e: any) => {
      const classname: string = e.target.className ?? "";
      if (classname.includes("modal-container")) toDisplayModal(false); // Close the modal
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [toDisplayModal]);

  // Monitor the checkbox change event to disable/enable continue button state
  useEffect(() => {
    isChecked ? toDisable(false) : toDisable(true);
  }, [isChecked]);

  // Handle when clicked on continue button
  const handleClick = async (e: React.MouseEvent<HTMLElement>) => {
    switch (signatureType) {
      case 0:
        // !manual signature
        devLogInstance.log(ref1);

        setSignatureDataUrl(image);

        // close the modal
        toDisplayModal(false);
        break;

      case 1:
        // manual signature drawn
        devLogInstance.log(ref2);

        const imgDataUrl = ref2.current.toDataURL("image/png");
        setSignatureDataUrl(imgDataUrl);

        // close the modal
        toDisplayModal(false);
        break;

      default:
        break;
    }
  };

  return (
    <div className="main-modal-container">
      <div className="bg-white shadow-2xl fixed top-14 w-[80%] max-h-[80%] rounded-lg overflow-auto">
        <div className="px-4 py-4 bg-taxeve-primary-blue text-white modal-heading">
          <h2>Digital signature</h2>
        </div>
        <div className="modal-body px-4 py-4 flex flex-col gap-4">
          {signatureType === 0 && (
            <div className="flex items-center gap-4">
              <span>Type your name here: </span>
              <input
                type="text"
                className="py-1 w-1/4"
                value={name}
                onChange={(e) => {
                  setName(e.currentTarget.value);
                }}
              />
            </div>
          )}

          <div className="flex gap-8 items-center border-b-2 py-2 signature-type-selection">
            <span
              className={`cursor-pointer ${
                signatureType === 0 && "text-taxeve-primary-blue"
              } relative`}
              onClick={() => {
                setSignatureType(0);
              }}
            >
              Select Style
              {signatureType === 0 && (
                <div className="bg-taxeve-primary-blue w-[100%] h-[0.2rem] absolute bottom-[-0.5rem]" />
              )}
            </span>
            <span
              className={`cursor-pointer ${
                signatureType === 1 && "text-taxeve-primary-blue"
              } relative`}
              onClick={() => {
                setSignatureType(1);
              }}
            >
              Draw
              {signatureType === 1 && (
                <div className="bg-taxeve-primary-blue w-[100%] h-[0.2rem] absolute bottom-[-0.5rem]" />
              )}
            </span>
          </div>

          {signatureType === 0 && (
            <StyleSelectionCanvas
              name={name}
              toReturnRef={true}
              setReference={setRef1}
              takeScreenshot={takeScreenshot}
            />
          )}

          {signatureType === 1 && (
            <SignatureDrawingCanvas toReturnRef={true} setReference={setRef2} />
          )}

          <div className="flex justify-between items-center">
            <div className="e-sign-consent flex items-center">
              <input
                id="e-signature-consent"
                type="checkbox"
                onChange={() => {
                  toCheck(!isChecked);
                }}
                checked={isChecked}
              />
              <label htmlFor="e-signature-consent" className="pb-[0.2rem] font-semibold">
                I agree that this mark will be the electronic representation of my signature
              </label>
            </div>

            <div className="current-date">
              Date: <span className="font-bold">{getFormattedDate()}</span>
            </div>

            <button
              className={`primary-button py-2 px-4 text-lg ${isDisabled && "opacity-40"}`}
              onClick={handleClick}
              disabled={!isChecked}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
