import React from "react";
import { useSelector } from "react-redux";
import FormDetail from "../../GlobalFormComponents/InternalComponents/FormDetail";
import AmountContainer from "../../GlobalFormComponents/AmountContainer/AmountContainer";
import AmountHeading from "../../GlobalFormComponents/AmountContainer/AmountHeading/AmountHeading";
import { s1099AmountDisplayTexts } from "../../../../lib/languagePacks/en-us";
import { FiDollarSign } from "react-icons/fi";
import IRSDisclaimer from "../FormIRSDisclaimer/IRSDisclaimer";
import { useEffect } from "react";
import "./SRightPortion.css";

export const organizeDate = (date: string) => {
  // The date is in YYYY/MM/DD format without the slashes(YYYYMMDD)
  // Will have to convert to MM/DD/YYYY
  const year = date.substring(0, 4);
  const month = date.substring(4, 6);
  const _date = date.substring(6, 8);
  return `${month}/${_date}/${year}`;
};

export default function SRightPortion({
  formType,
  taxYear,
  formName,
  formData,
  ombNum,
  revMonth,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  useEffect(() => {
    console.log(formData);
  }, [formData]);
  return (
    <div className="right-portion-details">
      <div className="top-section rf-ts">
        <div>
          <AmountContainer
            class_="misc-info" // Only one class can be passed
            style={{
              borderTop: `1px solid ${style.eFormBorderColor}`,
              height: "50%",
              width: "100%",
            }}
          >
            <AmountHeading heading={s1099AmountDisplayTexts.dateOfClosing} amountNum="1" />
            {formData?.date_of_closing && (
              <div
                className="amount-value"
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#30A2FF",
                }}
              >
                {organizeDate(formData.date_of_closing)}
              </div>
            )}
          </AmountContainer>
          <AmountContainer
            class_="misc-info" // Only one class can be passed
            style={{
              borderTop: `1px solid ${style.eFormBorderColor}`,
              height: "50%",
              width: "100%",
            }}
          >
            <AmountHeading heading={s1099AmountDisplayTexts.grossProceeds} amountNum="2" />
            {formData?.payment_amount2 !== 0 && (
              <div
                className="amount-value"
                style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
              >
                <FiDollarSign /> {(formData.payment_amount2 / 100).toFixed(2)}
              </div>
            )}
          </AmountContainer>
        </div>
        <FormDetail
          formType={formType}
          taxYear={taxYear}
          formName={formName}
          ombNum={ombNum}
          revMonth={revMonth}
        />
      </div>
      <div style={{ display: "flex", overflow: "hidden" }}>
        <div style={{ width: "90%" }}>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="misc-info" // Only one class can be passed
            >
              <AmountHeading heading={s1099AmountDisplayTexts.address} amountNum="3" />
              <div
                className="amount-value"
                style={{
                  color: "#30A2FF",
                  padding: "0 0 4rem",
                }}
              >
                {formData?.address_or_legal_description}
              </div>
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{ flexDirection: "row" }}
            >
              <AmountHeading
                heading={s1099AmountDisplayTexts.transferorReceivedProperty}
                amountNum="4"
              />
              <div className="property-service-indicator">
                <input
                  type="checkbox"
                  checked={formData?.property_or_services_indicator}
                  onChange={() => {}}
                />
              </div>
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="payer-direct-sales" // Only one class can be passed
              style={{ flexDirection: "row" }}
            >
              <AmountHeading heading={s1099AmountDisplayTexts.transferorIsForeign} amountNum="5" />
              <div className="foreign-transferor-indicator">
                <input
                  type="checkbox"
                  defaultChecked={formData?.foreign_transferor}
                  onChange={() => {}}
                />
              </div>
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="misc-info" // Only one class can be passed
            >
              <AmountHeading
                heading={s1099AmountDisplayTexts.buyersPartOfRealEstateTax}
                amountNum="6"
              />
              {formData?.payment_amount5 !== 0 && (
                <div
                  className="amount-value"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#30A2FF",
                  }}
                >
                  <FiDollarSign /> {(formData.payment_amount5 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>
          {/* <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                }}
              /> */}
        </div>
        <IRSDisclaimer toPutBorderbottom={true} />
      </div>
    </div>
  );
}
