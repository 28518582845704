import { Dispatch } from "@reduxjs/toolkit";
import { TO_DISPLAY_MANUAL_GROUP_CREATING_MODAL } from "../../../redux/actions/action.types";
import {
  CreateGroupModalAction,
  CreateGroupModalActionPayload,
} from "../../../redux/reducers/Modal/displayManualGroupCreatingModal";

export default function displayManualGroupCreatingModal(
  payload: CreateGroupModalActionPayload,
  dispatch: Dispatch<CreateGroupModalAction>
) {
  dispatch({
    type: TO_DISPLAY_MANUAL_GROUP_CREATING_MODAL,
    payload,
  });
}
