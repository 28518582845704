import { BulkCreateGroupAction, BulkCreateGroupActionPayload } from "../../../../interfaces/main";
import { TO_DISPLAY_BULK_CREATE_GROUP_MODAL } from "../../actions/action.types";

const initialState: BulkCreateGroupActionPayload = {
  toDisplay: false,
};

const displayBulkCreateGroupModal = (state = initialState, action: BulkCreateGroupAction) => {
  if (action.type === TO_DISPLAY_BULK_CREATE_GROUP_MODAL) return action.payload;
  return state;
};

export default displayBulkCreateGroupModal;
