import React from "react";
import StateAmount from "../StateAmount/StateAmount";

export default function StateName({ heading, state1, state2, styles = {}, onleft = false }: any) {
  return (
    <div style={{ borderRight: `1px solid black`, ...styles }} className="rf-state-number">
      <div className="rf-si-heading" style={{ padding: "0.25rem 0.5rem" }}>
        {heading}
      </div>
      <StateAmount state1={state1} state2={state2} onLeft={onleft} />
    </div>
  );
}
