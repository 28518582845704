// Custom theme for the dd
class SelectStyle {
  style: any;
  constructor(style: any) {
    this.style = style;
  }
  customTheme = (theme: any) => ({
    ...theme,
    borderRadius: "0.5rem",
    colors: {
      ...theme.colors,
      primary25: this.style.ddOptionHover,
    },
  });
  customStyle = {
    control: (styles: any, state: any) => ({
      ...styles,
      border: `1px solid ${this.style.ddBorder}`,
      outline: state.isFocused ? "1px solid red" : "none",
    }),
    option: (styles: any, { isSelected, isFocused, isDisabled }: any) => {
      return {
        ...styles,
        color: isSelected && this.style.isSelectedColor,
        backgroundColor: isSelected
          ? this.style.isSelectedBg
          : isFocused && this.style.ddOptionHover,
        opacity: isDisabled && "0.5",
      };
    },
  };
  rpCustomStyle = {
    control: (styles: any, state: any) => ({
      ...styles,
      border: `1px solid ${this.style.ddBorder}`,
      outline: state.isFocused ? "1px solid red" : "none",
    }),
    option: (styles: any, { isSelected, isFocused }: any) => {
      return {
        ...styles,
        color: isSelected && this.style.isSelectedColor,
        backgroundColor: isSelected
          ? this.style.isSelectedBg
          : isFocused && this.style.ddOptionHover,
        fontSize: "0.9rem",
        fontWeight: "bold",
      };
    },
  };

  merchantCodeSelectStyle = {
    control: (styles: any, state: any) => ({
      ...styles,
      border: `1px solid ${this.style.ddBorder}`,
      outline: state.isFocused ? "1px solid red" : "none",
    }),
    option: (styles: any, { isSelected, isFocused, isDisabled }: any) => {
      return {
        ...styles,
        color: isSelected && this.style.isSelectedColor,
        backgroundColor: isSelected
          ? this.style.isSelectedBg
          : isFocused && this.style.ddOptionHover,
        opacity: isDisabled && "0.5",
      };
    },
    menu: (styles: any) => ({
      ...styles,
      width: "30rem",
      position: "absolute",
      left: "-11.5rem",
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      // textAlign: "right",
      visibility: "visible",
      position: "relative",
    }),
  };
}

export default SelectStyle;
