import { ViewAssignedPayersActionPayload } from "../../../../interfaces/main";
import { TO_DISPLAY_VIEW_ASSIGNED_PAYERS_MODAL } from "../../actions/action.types";

export interface ViewAssignedPayersAction {
  type: string;
  payload: ViewAssignedPayersActionPayload;
}

const initialState: ViewAssignedPayersActionPayload = {
  toDisplay: false,
  assignedPayers: [],
  staffOrGroupName: "",
  isStaff: false,
};

const displayViewAssignedPayersModal = (state = initialState, action: ViewAssignedPayersAction) => {
  if (action.type === TO_DISPLAY_VIEW_ASSIGNED_PAYERS_MODAL) return action.payload;
  return state;
};

export default displayViewAssignedPayersModal;
