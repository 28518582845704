import { axiosInstance } from "../axiosConfig";

export default async function getAdminPrices() {
  try {
    const { data } = await axiosInstance.get("/admin/get_price");
    return data;
  } catch (err) {
    throw err;
  }
}
