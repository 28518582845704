import { Dispatch } from "@reduxjs/toolkit";
import { ViewAssignedStaffsActionPayload } from "../../../../interfaces/main";
import { ViewAssignedStaffsAction } from "../../../redux/reducers/Modal/displayViewAssignedStaffsModal";
import { TO_DISPLAY_VIEW_ASSIGNED_STAFFS_MODAL } from "../../../redux/actions/action.types";

export default function displayViewAssignedStaffsModal(
  payload: ViewAssignedStaffsActionPayload,
  dispatch: Dispatch<ViewAssignedStaffsAction>
) {
  dispatch({
    type: TO_DISPLAY_VIEW_ASSIGNED_STAFFS_MODAL,
    payload,
  });
}
