import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./EmailRequiredCard.css";
import UpdateEmail from "./UpdateEmail/UpdateEmail";
import { useState } from "react";
import devLogInstance from "../../../../lib/loggerConfig";
import { OnlineAccessValidator } from "../../../../lib/validators";
import callEmailRequiredModal from "../../../../lib/StateSetters/callEmailRequiredModal";
import Handler from "../../../../lib/Handlers/main";
import { CP_BULK_ENABLE_ONLINE_ACCESS, ONLINE_ACCESS } from "../../../../lib/constants";
import TruthyCartValues from "../../../../lib/utils/CartValues";
import setCartPageBulkActionPayload from "../../../../lib/StateSetters/setCartPageBulkActionPayload";

export default function EmailRequiredCard({ dataArray, payerId, cartIds }: any) {
  const style = useSelector((state: any) => state.themeChange);
  const truthyCartValues = useSelector((state: any) => state.truthyCartValues);
  const [onlineAccessEmails, setonlineAccessEmails] = useState<any[]>([]);
  // const [areValidEmailIds, setAreValidEmailIds] = useState([]);
  const dispatch = useDispatch();

  // Log the current online access email array format
  devLogInstance.log(onlineAccessEmails);

  // Handle continue
  const handleContinue = async (e: any) => {
    if (onlineAccessEmails.length !== 0) {
      const recEmails: string[] = [];
      const recsToIncludeCartIds: any[] = [];
      // const cartIds = [];
      onlineAccessEmails.forEach((emailObj: any) => {
        recEmails.push(emailObj.new_recipient_email);
        recsToIncludeCartIds.push(emailObj.cart_id);
        // cartIds.push(emailObj.cart_id);
      });
      try {
        const isValid = await OnlineAccessValidator.validateEmailList(recEmails);
        devLogInstance.log(`All emails valid --->  ${isValid}`);
        // Handle online access email update
        await Handler.handleOnlineAccessEmailUpdate(dispatch, onlineAccessEmails);
        // Close the modal
        closeModal();
        // Handle cart checkbox update
        const e = {
          target: {
            parentElement: {
              id: ONLINE_ACCESS,
            },
          },
        };
        await Handler.handleCartCheckboxUpdate(
          e,
          truthyCartValues,
          true,
          recsToIncludeCartIds,
          payerId,
          dispatch
        );
        // Update the checkbox for frontend display
        payerId !== null
          ? recsToIncludeCartIds.forEach((cartId) => {
              TruthyCartValues.setTruthyCartVals(cartId, payerId, ONLINE_ACCESS, dispatch);
            })
          : setCartPageBulkActionPayload(dispatch, CP_BULK_ENABLE_ONLINE_ACCESS);
      } catch (errList: any) {
        devLogInstance.log(errList);
        errList.forEach((errObj: any) => {
          const initialArray: any[] = onlineAccessEmails;
          initialArray[errObj.idx].isValid = false;
          initialArray[errObj.idx].invalidErrMsg = errObj.message;
          setonlineAccessEmails([...initialArray]);
        });
      }
    }
  };

  // Skip the recs without email id and continue with email update
  const handleRecSkipAndContinue = async () => {
    if (onlineAccessEmails.length !== 0) {
      const recEmails: string[] = [];
      const updatedOAEmails: string[] = [];
      const recsToInclude: any[] = [];
      const recsToExclude: any[] = [];

      onlineAccessEmails.forEach((emailObj) => {
        emailObj.new_recipient_email
          ? recEmails.push(emailObj.new_recipient_email)
          : recsToExclude.push(emailObj.cart_id);
        emailObj.new_recipient_email && updatedOAEmails.push(emailObj);
        emailObj.new_recipient_email && recsToInclude.push(emailObj.cart_id);
      });
      try {
        if (recEmails.length !== 0) {
          const isValid = await OnlineAccessValidator.validateEmailList(recEmails);
          devLogInstance.log(`All emails valid --->  ${isValid}`);
          // Handle online access email update
          await Handler.handleOnlineAccessEmailUpdate(dispatch, updatedOAEmails);
          // Close the modal
          closeModal();
          // Handle cart checkbox update
          const e = {
            target: {
              parentElement: {
                id: ONLINE_ACCESS,
              },
            },
          };
          // const filteredCartIds = cartIds.filter(cartId => {
          //   return !recsToExclude.includes(cartId);
          // });
          console.log(recsToInclude);
          // Update the checkbox for frontend display
          await Handler.handleCartCheckboxUpdate(
            e,
            truthyCartValues,
            true,
            recsToInclude,
            payerId,
            dispatch
          );
          payerId !== null
            ? recsToInclude.forEach((cartId) => {
                TruthyCartValues.setTruthyCartVals(cartId, payerId, ONLINE_ACCESS, dispatch);
              })
            : setCartPageBulkActionPayload(dispatch, CP_BULK_ENABLE_ONLINE_ACCESS, recsToExclude);
        } else closeModal();
      } catch (errList) {
        devLogInstance.log(errList);
        // errList.forEach(errObj => {
        //   const initialArray = onlineAccessEmails;
        //   initialArray[errObj.idx].isValid = false;
        //   initialArray[errObj.idx].invalidErrMsg = errObj.message;
        //   setonlineAccessEmails([...initialArray]);
        // });
      }
    }
  };

  // Close the modal
  const closeModal = () => {
    callEmailRequiredModal(dispatch, { dataArray: dataArray, payerId: payerId, toCall: false });
  };

  return (
    <main className="email-required-card-main" style={{ background: style.siteBg }}>
      <div className="top-panel" style={{ background: style.modalTopPanelBg }}>
        Give Online Access
      </div>
      <div className="modal-card-body">
        <div className="modal-card-body-header">
          Please provide recipient email for the following recipient(s) to allow him/her online
          access
        </div>
        <table>
          <thead>
            <tr>
              <th style={{ textAlign: "left", width: "18rem" }}>Recipient</th>
              <th>Email Address</th>
            </tr>
          </thead>
          {dataArray.map((dataObj: any, i: number) => {
            return (
              <UpdateEmail
                key={i}
                recName={dataObj.recipient_name}
                recTin={dataObj.recipient_tin}
                recTinType={dataObj.recipient_tin_type}
                cartId={dataObj.cartId}
                onlineAccessEmails={onlineAccessEmails}
                setonlineAccessEmails={setonlineAccessEmails}
                currentIdx={i}
              />
            );
          })}
        </table>
        <div className="modal-action-btn-container">
          {onlineAccessEmails.length > 1 && (
            <button
              // style={{ color: style.fontColor }}
              style={{ background: style.btnBg, color: style.btnFillFontColor }}
              // className="rec-skip-text"
              onClick={handleRecSkipAndContinue}
            >
              Continue with provided emails only
            </button>
          )}
          <button
            style={{ background: style.btnSecondaryBg, color: style.btnFillFontColor }}
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            style={{ background: style.btnBg, color: style.btnFillFontColor }}
            onClick={handleContinue}
          >
            Continue
          </button>
        </div>
      </div>
    </main>
  );
}
