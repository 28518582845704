export const DELETE_ACTION_SINGLE = "DELETE_ACTION_SINGLE";
export const DELETE_ACTION_BULK = "DELETE_ACTION_BULK";
export const DISABLE_STATE_FILING_FOR_PAYER = "DISABLE_STATE_FILING_FOR_PAYER";
export const DISABLE_STATE_FILING_BULK = "DISABLE_STATE_FILING_BULK";
export const DISABLE_STATE_FILING_SINGLE = "DISABLE_STATE_FILING_SINGLE";
export const BULK_REVIEW_FORM = "BULK_REVIEW_FORM";
export const BULK_UNREVIEW_FORM = "BULK_UNREVIEW_FORM";
export const CONTINUE_TO_CART_WITHOUT_REVIEWING_ALL_FORMS =
  "CONTINUE_TO_CART_WITHOUT_REVIEWING_ALL_FORMS";

// Enterprise system related
export const REMOVE_STAFF = "REMOVE_STAFF";
export const DELETE_GROUP = "DELETE_GROUP";
