import { isPresent } from "./saveFormData";
import encrypt from "../AESHashing/encrypt";
import decrypt from "../AESHashing/decrypt";

export default function saveCartId(cartId: any) {
  const encCartIdsStr = localStorage.getItem("cart_ids");
  if (isPresent(encCartIdsStr)) {
    const cartIds = encCartIdsStr ? JSON.parse(decrypt(encCartIdsStr)) : "";
    cartIds.push(cartId);
    localStorage.setItem("cart_ids", encrypt(JSON.stringify(cartIds)).toString());
  } else {
    const cartIds = [cartId];
    localStorage.setItem("cart_ids", encrypt(JSON.stringify(cartIds)).toString());
  }
}
