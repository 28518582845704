import { Dispatch } from "@reduxjs/toolkit";
import SessionStorage from "../LocalCaching/SessionStorage";
import Cookie from "../utils/Cookie";
import authenticateRecipient from "./authenticateRecipient";
import { IsRecipientAuthenticatedAction } from "../../../interfaces/main";

export default function logoutRecipient(stateSetter: Dispatch<IsRecipientAuthenticatedAction>) {
  // Remove the admin token
  Cookie.removeCookie("recipient_token");

  // Delete the cached consent status
  SessionStorage.removeCacheItem("rec_consent_status");

  // Delete the cached fid
  SessionStorage.removeCacheItem("fid");

  // Unauthenticate admin
  authenticateRecipient(stateSetter, false);
}
