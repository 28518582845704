import retrieveToken from "../authRequests/retrieveTokens";
import { axiosInstance } from "../axiosConfig";
// import retrieveToken from "../authRequests/retrieveTokens"

export default async function getRecDetails(recid: any) {
  try {
    const { data } = await axiosInstance.post(
      "/recipient_details",
      {
        recipient_id: recid,
      },
      {
        headers: {
          auth_token: retrieveToken("auth_token"),
        },
      }
    );
    return data;
  } catch (err) {
    throw err;
  }
}
