import { Dispatch } from "@reduxjs/toolkit";
import { SET_IS_RECIPIENT_AUTHENTICATED } from "../../redux/actions/action.types";
import { IsRecipientAuthenticatedAction } from "../../../interfaces/main";

export default function authenticateRecipient(
  stateSetter: Dispatch<IsRecipientAuthenticatedAction>,
  authStatus: boolean
) {
  stateSetter({
    type: SET_IS_RECIPIENT_AUTHENTICATED,
    payload: authStatus,
  });
}
