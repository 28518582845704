import React from "react";
import { useDispatch, useSelector } from "react-redux";
import formatTin from "../../../../lib/form/utils/formatTin";
import maskTin from "../../../../lib/utils/maskTin";
import { useCallback, useEffect, useMemo, useState } from "react";
// import devLogInstance from "../../../../lib/loggerConfig";
import {
  pushCartId,
  removeCartId,
  resetFormHistoryCartIdsArr,
} from "../../../../lib/StateSetters/setFormHistoryCartIds";
import { DOWNLOAD, PAYER_COPY, RECIPIENT_COPY, STATE_COPY } from "../../../../lib/constants";
import Formatter from "../../../../lib/utils/Formatter";
import { FaEye } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { E_FILING } from "../../../../lib/routes";
import { Action } from "../../../../lib/utils/Action";
import { FaCopyright } from "react-icons/fa";
import { IoEyeSharp } from "react-icons/io5";

// The key is directly mapped with the string literal received from backend
// So if the strings change, the keys will have to be changbed accordingly as well
const statusMap: any = {
  Transmitted: "Transmitted",
  Accepted: "Consent Accepted",
  Dispatched: "Dispatched",
  AwaitingTransmission: "Transmission Pending",
  DispatchPending: "Dispatch Pending",
  ConsentPending: "Consent Pending",
  EmailPending: "Dispatch pending",
  Rejected: "Consent Rejected",
  NotOpted: "Not-Opted",
  "postal mail not opted": "Not-Opted",
  Viewed: "Viewed",
  Downloaded: "Downloaded",
  "Form Accepted": "Accepted",
  "Form Rejected": "Rejected",
  MailSent: "Mail Sent",
  "state filing accepted": "Accepted",
  "state filing rejected": "Rejected",
  "state filing transmitted": "Transmitted",
  OnlineAccessMailSent: "Email Sent",
};

const statusColorMap: any = {
  Transmitted: "text-blue-500",
  Accepted: "text-green-500",
  Dispatched: "text-green-500",
  AwaitingTransmission: "text-yellow-500",
  DispatchPending: "text-yellow-500",
  ConsentPending: "text-yellow-500",
  EmailPending: "text-yellow-500",
  Rejected: "text-red-500",
  NotOpted: "text-gray-400",
  "postal mail not opted": "text-gray-500",
  Viewed: "text-violet-500",
  Downloaded: "text-blue-800",
  "Form Accepted": "text-green-500",
  "Form Rejected": "text-red-500",
  MailSent: "text-green-500",
  "state filing accepted": "text-green-500",
  "state filing rejected": "text-red-500",
  "state filing transmitted": "text-yellow-500",
  OnlineAccessMailSent: "text-blue-800",
};

const isFormAccepted = (efileStatus: string) => {
  return statusMap[efileStatus].includes("Accepted");
};

export default function TableBody({
  formData,
  uncheckAll,
  itemsChecked,
  id,
  tableId,
  onCorrectionPage,
  taxYear,
  formType,
  formId,
}: any) {
  const recipient = formData.recipient_name;
  const tin = formData.recipient_tin;
  const tinType = formData.recipient_tin_type;
  const orderPlacedOn = !onCorrectionPage
    ? Formatter.formatDate(new Date(formData.order_placed_on))
    : null;
  const fedEfileStatus = formData.fed_efile_status ?? formData.status;
  const stateEfileStatus = formData.state_efile_status;
  const postalMailStatus = formData.postal_mail_status;
  const onlineAccessStatus = formData.online_access_status;

  const toMaskTin = useSelector((state: any) => state.toMaskTin);
  // const style = useSelector((state: any) => state.themeChange);
  const [isChecked, setIsChecked] = useState(false);
  const [showDownloadPopup, toShowDownloadPopup] = useState(false);
  const stateSetter = useDispatch();

  // Reset on component destroy
  useEffect(() => {
    return () => {
      setIsChecked(false);
      itemsChecked(0);
      resetFormHistoryCartIdsArr(stateSetter, null);
    };
  }, [formData, itemsChecked, stateSetter]);

  // Change event handler
  const handleChange = (e: any) => {
    // Set the new checked state
    setIsChecked(!isChecked);
  };

  // // Download event handler
  // const handleDownload = () => {
  //   // Handle download here
  // };

  // // Preview event handler
  // const handlePreview = () => {
  //   // Handle preview here
  // };

  // Handle check all children event
  useMemo(() => {
    if (uncheckAll !== null) {
      if (uncheckAll) {
        setIsChecked(false);
      } else {
        setIsChecked(true);
      }
    }
  }, [uncheckAll]);

  // Handler when an item is checked or unchecked
  const updateItemsCheckedVal = useCallback(() => {
    if (isChecked) {
      itemsChecked((prevState: any) => {
        return ++prevState;
      });
      // Push the cart id
      const newCartId = formData.cartId;

      pushCartId(stateSetter, newCartId);
    } else {
      itemsChecked((prevState: any) => {
        return prevState <= 0 ? 0 : --prevState;
      });

      // Remove the cart id
      const newCartId = formData.cartId;

      removeCartId(stateSetter, newCartId);
    }
  }, [isChecked, itemsChecked, formData, stateSetter]);

  useEffect(() => {
    updateItemsCheckedVal();
  }, [updateItemsCheckedVal]);

  // Handle download icon click
  useEffect(() => {
    const handleClick = (e: any) => {
      const { id: _id } = e.target;
      if (_id.includes(`${DOWNLOAD}_${tableId}_${id}`)) toShowDownloadPopup(!showDownloadPopup);
      else toShowDownloadPopup(false);
    };
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [showDownloadPopup, id, tableId]);

  // Handle single form download
  const handleDownload = (e: React.MouseEvent<HTMLLIElement>) => {
    const { id: type } = e.currentTarget;
    const historyID = formData.cartId;
    Action.cartIds = [historyID];
    Action.formDownload({ type: type });
  };

  // Handle pdf preview
  const handlePreview = (e: React.MouseEvent<any>) => {
    const id = onCorrectionPage ? formData.history_id : formData.cartId;
    Action.cartIds = [id];
    Action.previewPdf({ type: PAYER_COPY, inModal: true }, stateSetter);
  };

  return (
    <tbody style={{ textAlign: "center" }}>
      <tr>
        {!onCorrectionPage && (
          <td>
            <div>
              <input type="checkbox" onChange={handleChange} checked={isChecked} />
            </div>
          </td>
        )}
        <td className="w-[15%]">
          <div className="flex items-center justify-center gap-2">
            <div style={{ padding: "0.5rem 0" }}>{recipient}</div>
            {formData.correction_type && (
              <h2 className="px-2">
                <FaCopyright className="text-red-500" />
              </h2>
            )}
          </div>
        </td>
        <td className="w-[12%]">
          <div>
            <span style={{ fontWeight: "bold" }}>{tinType}</span>:{" "}
            {toMaskTin ? maskTin(formatTin(tin, tinType), tinType) : formatTin(tin, tinType)}
          </div>
        </td>
        <td className="w-[13%]">
          <div className={`${statusColorMap[fedEfileStatus]}`}>{statusMap[fedEfileStatus]}</div>
        </td>
        {!onCorrectionPage && (
          <>
            <td className="w-[13%]">
              <div className={`${statusColorMap[stateEfileStatus]}`}>
                {statusMap[stateEfileStatus]}
              </div>
            </td>
            <td className="w-[14%]">
              <div className={`${statusColorMap[postalMailStatus]}`}>
                {statusMap[postalMailStatus]}
              </div>
            </td>
            <td className="w-[13%]">
              <div className={`${statusColorMap[onlineAccessStatus]}`}>
                {statusMap[onlineAccessStatus]}
              </div>
            </td>
            <td className="w-[12%]">
              <div>{orderPlacedOn}</div>
            </td>
          </>
        )}
        {!onCorrectionPage ? (
          <td>
            <div className="flex justify-center gap-6 action-wrapper">
              <div className="cursor-pointer preview">
                <IoEyeSharp className="text-2xl fa-solid fa-file" onClick={handlePreview} />
              </div>
              <div className="cursor-pointer relative download">
                <i id={`${DOWNLOAD}_${tableId}_${id}`} className="text-xl fa-solid fa-download" />
                {showDownloadPopup && (
                  <div className="absolute w-[9rem] left-[-4.75rem] bg-white z-[5] mt-1 rounded-xl shadow-xl download-popup overflow-hidden">
                    <ul className="flex flex-col gap-2">
                      <li
                        id={STATE_COPY}
                        className="px-2 py-2 hover:bg-taxeve-primary-violet-low"
                        onClick={handleDownload}
                      >
                        State copy
                      </li>
                      <li
                        id={PAYER_COPY}
                        className="px-2 py-2 hover:bg-taxeve-primary-violet-low"
                        onClick={handleDownload}
                      >
                        Payer copy
                      </li>
                      <li
                        id={RECIPIENT_COPY}
                        className="px-2 py-2 hover:bg-taxeve-primary-violet-low"
                        onClick={handleDownload}
                      >
                        Recipient copy
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </td>
        ) : (
          <td>
            <div className="flex justify-center gap-6 py-2">
              <h1 className="py-2 px-6">
                <FaEye className="cursor-pointer" onClick={handlePreview} />
              </h1>
              {formType && taxYear && formId && (
                <div className="flex items-center relative">
                  <Link
                    to={`${E_FILING}/${formType}/${taxYear}?correction=true&formId=${formId}`}
                    className="py-2 px-6 primary-button"
                  >
                    Start correcting
                  </Link>
                  {!isFormAccepted(fedEfileStatus) && (
                    <div
                      className={`blocker h-full w-full bg-slate-500 opacity-60 absolute rounded-lg`}
                    />
                  )}
                </div>
              )}
            </div>
          </td>
        )}
      </tr>
    </tbody>
  );
}
