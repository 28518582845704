import React, { useState } from "react";
import OnlineAccessLoginForm from "../../components/OAPRelatedComponents/OnlineAccessLoginForm";
import { OnlineAccessLoginData } from "../../../interfaces/main";
import { EMAIL } from "../Contact";
import { TIN } from "../../lib/constants";
import { NUMBER_ONLY_REGEX } from "../../lib/patterns";
// import OnlineAccessLoginImg from "../../components/decorations/OnlineAccessLoginImg";
import Handler from "../../lib/Handlers/main";
import { useNavigate, useParams } from "react-router-dom";
import devLogInstance from "../../lib/loggerConfig";

export default function LoginOnlineAccess() {
  const [onlineAccessLoginData, setOnlineAccessLoginData] = useState<OnlineAccessLoginData>({
    recipient_email: "",
    recipient_tin: "",
  });
  const { formId, oaKey } = useParams();
  const navigate = useNavigate();

  // Handle change event
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id: type, value } = e.currentTarget;

    console.log(type);

    switch (type) {
      case EMAIL:
        setOnlineAccessLoginData({
          ...onlineAccessLoginData,
          recipient_email: value,
        });
        break;

      case TIN:
        const isValidLength = value.length <= 9;
        const isNumberOnly = new RegExp(NUMBER_ONLY_REGEX).test(value);

        isValidLength &&
          isNumberOnly &&
          setOnlineAccessLoginData({
            ...onlineAccessLoginData,
            recipient_tin: value,
          });
        break;

      default:
        break;
    }
  };

  // Handle form submit event
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    devLogInstance.log(`Form ID: ${formId}, OAKey: ${oaKey}`);
    // Call the recipient login handler
    if (formId && oaKey)
      try {
        await Handler.handleRecipientLogin(onlineAccessLoginData, formId, oaKey, navigate);
      } catch (err) {
        // Error will be handled by the error handler
      }
  };

  return (
    <div className="min-h-[100vh]">
      <section className="text-center py-10 flex justify-center">
        {/* <h1 className="text-3xl">Tax Eve Logo</h1> */}
        <img src="/Assets/taxeve_logo.png" alt="Taxeve" className="w-[10%]" />
      </section>
      <section className="flex justify-center items-center gap-8 px-20 py-6">
        <div className="w-[50%] px-20 text-slate-400">
          {/* <div className="flex justify-center">
            <OnlineAccessLoginImg />
          </div> */}
          <p>This secure portal enables you to view and download your Form 1099 at any time.</p>
          <br />
          <p>
            You will receive email notifications if your employer files any corrections to your Form
            1099.
          </p>
        </div>
        <OnlineAccessLoginForm
          onlineAccessLoginData={onlineAccessLoginData}
          onChange={handleChange}
          onSubmit={handleSubmit}
        />
      </section>
    </div>
  );
}
