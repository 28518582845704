import React from "react";
import DownloadTemplateInstr from "../../../DownloadTemplateInstr";
import { FileUploader } from "react-drag-drop-files";
import Dropbox from "../../../UploadPage/AddFile/Dropbox/Dropbox";
import SubmitButton from "../../../SubmitButton/SubmitButton";
import { PAGE_UPLOAD } from "../../../../lib/constants";
import { useFileUpload } from "../../../../lib/customHooks";
import { calCurrentTaxYear } from "../../../../lib/calculateTaxYear";
import { IoIosCloseCircleOutline } from "react-icons/io";
import displayAddBulkStaffModal from "../../../../lib/StateSetters/StaffAndGroupRelated/displayAddBulkStaffModal";
import { useDispatch } from "react-redux";

const instructions = [
  "Download the Tax Eve template for adding members in bulk",
  "Fill the downloaded template and upload the file in the upload section below",
];

const downloadLink = `${process.env.REACT_APP_API_ENDPOINT}/enterprise/non_staff_operation/download_staff_template`;

export default function AddBulkStaffModal() {
  const dispatch = useDispatch();

  const {
    handleChange,
    handleError,
    handleSizeError,
    handleFileUpload,
    originalFile: file,
  } = useFileUpload({
    payerId: "",
    userId: "",
    formType: "staff",
    taxYear: calCurrentTaxYear(),
    noForm: true,
  });

  const closeModal = () => {
    displayAddBulkStaffModal(
      {
        toDisplay: false,
        updateStaffRecords: null,
      },
      dispatch
    );
  };

  return (
    <div className="w-[80rem] rounded-xl overflow-hidden shadow-lg">
      <header className="form-modal-header bg-taxeve-primary-violet text-white flex items-center justify-between">
        <h2>Bulk Add Members</h2>
        <IoIosCloseCircleOutline className="text-3xl cursor-pointer" onClick={closeModal} />
      </header>
      <body className="form-modal-body bg-white">
        <div className="px-8 py-6 flex flex-col justify-center gap-4">
          <DownloadTemplateInstr
            instructions={instructions}
            templateFor="member"
            noCSV={true}
            downloadLink={downloadLink}
          />
          <FileUploader
            children={<Dropbox file={file} noCSV={true} />}
            multiple={false}
            types={["xlsx"]}
            handleChange={handleChange}
            maxSize={20}
            onTypeError={handleError}
            onSizeError={handleSizeError}
          />
          <SubmitButton pageType={PAGE_UPLOAD} handleSubmit={handleFileUpload} />
        </div>
      </body>
    </div>
  );
}
