import React from "react";
import { FaDownload } from "react-icons/fa";
import { Link } from "react-router-dom";

interface Props extends React.HTMLAttributes<HTMLElement> {
  templateFor: string;
  noCSV?: boolean;
  downloadLink?: string;
}

export default function DownloadOtherTemplates(props: Props) {
  return (
    <div className="flex items-center justify-center gap-8">
      <Link
        to={
          props.downloadLink
            ? props.downloadLink
            : `${process.env.REACT_APP_API_ENDPOINT}/get_${props.templateFor}_templates/excel`
        }
        className="bg-red-500 text-white px-4 py-2 rounded-lg hover:opacity-70 flex items-center gap-2"
      >
        <FaDownload />
        Download Excel Template
      </Link>

      {!props.noCSV && (
        <Link
          to={`${process.env.REACT_APP_API_ENDPOINT}/get_${props.templateFor}_templates/csv`}
          className="bg-taxeve-primary-violet text-white px-4 py-2 rounded-lg hover:opacity-70 flex items-center gap-2"
        >
          <FaDownload />
          Download CSV Template
        </Link>
      )}
    </div>
  );
}
