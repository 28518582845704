import React, { useEffect, useRef, useState } from "react";
import {
  ALLURA,
  CAVEAT,
  REENIE_BEANIE,
  ROCKSALT,
  SACRAMENTO,
  ZEYADA,
} from "../../../lib/constants";
import devLogInstance from "../../../lib/loggerConfig";

interface Props {
  name: string;
  toReturnRef: boolean;
  setReference: React.Dispatch<
    React.SetStateAction<React.MutableRefObject<HTMLElement | null> | null>
  >;
  takeScreenshot: (node: any) => Promise<string | void>;
}

export default function StyleSelectionCanvas({
  name,
  toReturnRef,
  setReference,
  takeScreenshot,
}: Props) {
  const [selected, setSelected] = useState(CAVEAT); // By default caveat will be selected
  const ref = useRef(null);

  useEffect(() => {
    takeScreenshot(ref.current).then(() => {
      //
    });
  }, [takeScreenshot]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id: type } = e.currentTarget;
    devLogInstance.log(type);
    await takeScreenshot(ref.current);
    setSelected(type);
  };

  useEffect(() => {
    if (toReturnRef) setReference(ref);
    return () => {
      setReference(null);
    };
  }, [toReturnRef, setReference]);

  return (
    <div className="border-b-2">
      <div className="signature-display bg-[#F3EEEA] py-4 flex justify-center items-center">
        <div ref={ref} className="py-4 px-2">
          <h1 className={`font-${selected} text-4xl`}>{name}</h1>
        </div>
      </div>
      <ul className="flex flex-wrap items-center justify-center px-4 py-4 gap-10 text-2xl">
        <li className="text-4xl mx-8">
          <input id={CAVEAT} type="radio" onChange={handleChange} checked={selected === CAVEAT} />
          <label htmlFor={CAVEAT}>
            <span className="font-caveat px-1">{name}</span>
          </label>
        </li>
        <li className="text-4xl mx-8">
          <input id={ZEYADA} type="radio" onChange={handleChange} checked={selected === ZEYADA} />
          <label htmlFor={ZEYADA}>
            <span className="font-zeyada px-1">{name}</span>
          </label>
        </li>
        <li className="text-4xl mx-8">
          <input
            id={SACRAMENTO}
            type="radio"
            onChange={handleChange}
            checked={selected === SACRAMENTO}
          />
          <label htmlFor={SACRAMENTO}>
            <span className="font-sacramento px-1">{name}</span>
          </label>
        </li>
        <li className="text-4xl mx-8">
          <input id={ALLURA} type="radio" onChange={handleChange} checked={selected === ALLURA} />
          <label htmlFor={ALLURA}>
            <span className="font-allura px-1">{name}</span>
          </label>
        </li>
        <li className="mx-8">
          <input
            id={ROCKSALT}
            type="radio"
            onChange={handleChange}
            checked={selected === ROCKSALT}
          />
          <label htmlFor={ROCKSALT}>
            <span className="font-rockSolt px-1">{name}</span>
          </label>
        </li>
        <li className="text-4xl mx-8">
          <input
            id={REENIE_BEANIE}
            type="radio"
            onChange={handleChange}
            checked={selected === REENIE_BEANIE}
          />
          <label htmlFor={REENIE_BEANIE}>
            <span className="font-reenieBeanie px-1">{name}</span>
          </label>
        </li>
      </ul>
    </div>
  );
}
