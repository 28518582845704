import React from "react";
import { Link } from "react-router-dom";
import {
  ADMIN_FIRE_FILING,
  ADMIN_MANAGEMENT,
  ADMIN_PRICE_MANAGEMENT,
  ADMIN_TRACK_TRANSACTIONS,
} from "../../lib/routes";

export default function AdminDashboard() {
  return (
    <main className="h-[100vh] flex flex-col items-center justify-center relative">
      <Link
        to={ADMIN_FIRE_FILING}
        className="admin-management text-3xl py-4 px-6 my-10 rounded-xl bg-yellow-600 text-white shadow-lg"
      >
        FIRE Filing System
      </Link>
      <div className="flex items-center justify-center flex-wrap gap-12 px-12 py-8">
        <Link
          to={ADMIN_MANAGEMENT}
          className="admin-management text-3xl py-4 px-6 rounded-xl bg-blue-600 text-white shadow-lg"
        >
          Admin management
        </Link>
        <Link
          to={ADMIN_PRICE_MANAGEMENT}
          className="admin-management text-3xl py-4 px-6 rounded-xl bg-red-600 text-white shadow-lg"
        >
          Price management
        </Link>
        <Link
          to={""}
          className="admin-management text-3xl py-4 px-6 rounded-xl bg-violet-600 text-white shadow-lg"
        >
          Doc management
        </Link>
        <Link
          to={ADMIN_TRACK_TRANSACTIONS}
          className="admin-management text-3xl py-4 px-6 rounded-xl bg-green-400 text-white shadow-lg"
        >
          Track Transactions
        </Link>
        <Link
          to={`${process.env.REACT_APP_API_ENDPOINT}/admin/transmission-pending-data`}
          className="admin-management text-3xl py-4 px-6 rounded-xl bg-orange-400 text-white shadow-lg"
        >
          Download Transmission Pending Data
        </Link>
      </div>
    </main>
  );
}
