import { Dispatch } from "@reduxjs/toolkit";
import { AddBulkStaffAction, AddBulkStaffActionPayload } from "../../../../interfaces/main";
import { TO_DISPLAY_ADD_BULK_STAFF_MODAL } from "../../../redux/actions/action.types";

export default function displayAddBulkStaffModal(
  payload: AddBulkStaffActionPayload,
  dispatch: Dispatch<AddBulkStaffAction>
) {
  dispatch({
    type: TO_DISPLAY_ADD_BULK_STAFF_MODAL,
    payload,
  });
}
