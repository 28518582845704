import React from "react";
import Select from "react-select";
import rpPayerDDOptions from "../../../../lib/utils/reviewPagePayerDDOptions";
import { useSelector } from "react-redux";
import SelectStyle from "../../../../lib/ReactSelectTheme/reactSelectStyle";
import { useEffect, useState } from "react";

export default function PayerDropDown() {
  const nonReviewedData = useSelector((state: any) => state.nonReviewedData);
  const style = useSelector((state: any) => state.themeChange);
  const selectedPayerId = useSelector((state: any) => state.selectedPayerId);
  const [selectedPayer, setSelectedPayer] = useState({
    label: "",
    value: "",
  });

  useEffect(() => {
    nonReviewedData.forEach((dataObj: any) => {
      if (dataObj.payer_data.payer_id === selectedPayerId) {
        setSelectedPayer({
          label: dataObj.payer_data.payer_name,
          value: dataObj.payer_data.payer_id,
        });
      }
    });
  }, [selectedPayerId, nonReviewedData]);

  // Clear any selected record

  // Handle change in selection
  const handleChange = (option: any) => {
    // Store the payer id of the selected payer in localstorage
    localStorage.setItem("payer_id", option.value);
    window.location.reload(); // RReload the page
  };

  return (
    <div style={{ padding: "0 1rem" }}>
      <Select
        options={rpPayerDDOptions(nonReviewedData)}
        theme={new SelectStyle(style).customTheme}
        styles={new SelectStyle(style).rpCustomStyle}
        value={selectedPayer}
        onChange={handleChange}
        placeholder=""
      />
    </div>
  );
}
