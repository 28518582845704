import totalChars from "./totalChars";
import remainingChars from "./remainingChars";
import logger from "../../../../logger";
import { FACharLimit, FAWarningTriggerLimit } from "../../../constants";
import { FAWarningMessage } from "../../../languagePacks/en-us";

export default function validateFALimit(city: any, state: any, zipcode: any) {
  // Check for current total char length
  const currentTotalChars = totalChars(city, state, zipcode);
  logger.log(`Total chars ---> ${currentTotalChars}`);
  if (currentTotalChars >= FAWarningTriggerLimit && currentTotalChars < FACharLimit) {
    // Check for remaining chars
    const remainingChars_ = remainingChars(currentTotalChars);
    logger.log(`Remaining Chars ---> ${remainingChars_}`);

    return {
      status: true,
      remainingChars: remainingChars_,
      messagePt1: FAWarningMessage.nec1099.message_pt1,
      messagePt2: FAWarningMessage.nec1099.message_pt2,
    };
  } else if (currentTotalChars > FACharLimit) {
    return {
      status: true,
      remainingChars: 0,
      messagePt1: FAWarningMessage.nec1099.exceed_msg,
      messagePt2: "",
    };
  } else {
    return {
      status: false,
      remainingChars: null,
    };
  }
}
