import { Dispatch } from "@reduxjs/toolkit";
import { ViewAssignedPayersActionPayload } from "../../../../interfaces/main";
import { ViewAssignedPayersAction } from "../../../redux/reducers/Modal/displayViewAssignedPayersModal";
import { TO_DISPLAY_VIEW_ASSIGNED_PAYERS_MODAL } from "../../../redux/actions/action.types";

export default function displayViewAssignedPayersModal(
  payload: ViewAssignedPayersActionPayload,
  dispatch: Dispatch<ViewAssignedPayersAction>
) {
  dispatch({
    type: TO_DISPLAY_VIEW_ASSIGNED_PAYERS_MODAL,
    payload,
  });
}
