import React, { useEffect } from "react";
import "./Modal.component.css";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { TO_DISPLAY_MODAL } from "../../../redux/actions/action.types";
import { modalDisplayTexts } from "../../../lib/languagePacks/en-us";
import {
  ADD_BULK_STAFF_MODAL,
  ADD_STAFFS_TO_GROUP_MODAL,
  BULK_CREATE_GROUP_MODAL,
  CONFIRMATION_MODAL,
  CORRECTION_FILING_MODAL,
  E_FILING_ERROR_MODAL,
  FEEDBACK_MODAL,
  GROUP_VIEWING_MODAL,
  INFO_MODAL,
  MANUAL_GROUP_CREATING_MODAL,
  MANUAL_STAFF_ADDING_MODAL,
  PAYER_ASSIGNMENT_MODAL,
  PAYER_UNASSIGNMENT_MODAL,
  PDF_VIEWER_MODAL,
  REMOVE_STAFFS_FROM_GROUP_MODAL,
  VIEW_ASSIGNED_PAYERS_MODAL,
  VIEW_ASSIGNED_STAFFS_MODAL,
} from "../../../lib/constants";
import ConfirmationModal from "./ConfirmationModal/ConfirmationModal";
import FeedbackModal from "./FeedbackModal/FeedbackModal";
import InfoModal from "./InfoModal/InfoModal";
import CorrectionFilingModal from "./CorrectionFilingRelated/CorrectionFilingModal";
import toDisplayModal from "../../../lib/StateSetters/toDisplayModal";
import PdfViewerModal from "./PdfViewingRelated/PdfViewerModal";
import toDisplayPdfViewerModal from "../../../lib/StateSetters/toDisplayPdfViewerModal";
import AssignPayerModal from "./StaffAndGroupRelated/AssignPayerModal";
import displayPayerAssignmentModal from "../../../lib/StateSetters/StaffAndGroupRelated/displayPayerAssignmentModal";
import UnassignPayerModal from "./StaffAndGroupRelated/UnassignPayerModal";
import displayPayerUnassignmentModal from "../../../lib/StateSetters/StaffAndGroupRelated/displayPayerUnassignmentModal";
import ManualStaffAddingModal from "./StaffAndGroupRelated/ManualStaffAddingModal";
import displayManualStaffAddingModal from "../../../lib/StateSetters/StaffAndGroupRelated/displayManualStaffAddingModal";
import GroupViewingModal from "./StaffAndGroupRelated/GroupViewingModal";
import displayViewGroupsModal from "../../../lib/StateSetters/StaffAndGroupRelated/displayViewGroupsModal";
import ManualGroupCreatingModal from "./StaffAndGroupRelated/ManualGroupCreatingModal";
import AddStaffsToGroupModal from "./StaffAndGroupRelated/AddStaffsToGroupModal";
import displayAddStaffsToGroupModal from "../../../lib/StateSetters/StaffAndGroupRelated/displayAddStaffsToGroupModal";
import RemoveStaffsFromGroupModal from "./StaffAndGroupRelated/RemoveStaffsFromGroupModal";
import displayRemoveStaffsFromGroupModal from "../../../lib/StateSetters/StaffAndGroupRelated/displayRemoveStaffsFromGroupModal";
import ViewAssignedPayersModal from "./StaffAndGroupRelated/ViewAssignedPayersModal";
import displayViewAssignedPayersModal from "../../../lib/StateSetters/StaffAndGroupRelated/displayViewAssignedPayersModal";
import ViewAssignedStaffsModal from "./StaffAndGroupRelated/ViewAssignedStaffsModal";
import displayViewAssignedStaffsModal from "../../../lib/StateSetters/StaffAndGroupRelated/displayViewAssignedStaffsModal";
import AddBulkStaffModal from "./StaffAndGroupRelated/AddBulkStaffModal";
import displayAddBulkStaffModal from "../../../lib/StateSetters/StaffAndGroupRelated/displayAddBulkStaffModal";
import displayBulkCreateGroupModal from "../../../lib/StateSetters/StaffAndGroupRelated/displayBulkCreateGroupModal";
import BulkCreateGroupModal from "./StaffAndGroupRelated/BulkCreateGroupModal";

interface Props {
  action?: string;
  modalType: string;
  blobData?: Blob;
}

export default function Modal(props: Props) {
  const style = useSelector((state: any) => state.themeChange);
  const modalMessage = useSelector((state: any) => state.modalMessage);
  const modalHeading = useSelector((state: any) => state.modalHeading);
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();

  // Handle mouse over
  const handleMouseOver = () => {
    setIsHovered(true);
  };

  // Handle mouse leave
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // Handle modal closure
  const handleModalClosure = () => {
    dispatch({
      type: TO_DISPLAY_MODAL,
      payload: false,
    });
  };

  // Prevent screen scrolling
  useEffect(() => {
    const pageYOffset = window.scrollY;
    const preventScroll = () => {
      console.log(pageYOffset);

      window.scrollTo(0, pageYOffset);
    };
    window.addEventListener("scroll", preventScroll);
    return () => {
      window.removeEventListener("scroll", preventScroll);
    };
  }, []);

  // Monitor click events and close the modal if clicked outside the modal
  useEffect(() => {
    const handleClick = (e: any) => {
      const classname: string = e.target.className;
      if (
        typeof classname === "string" &&
        (classname.includes("modal-container") || classname.includes("modal-wrapper"))
      ) {
        toDisplayModal(false, "", dispatch); // Close the modal

        // Close the payer assignment modal
        displayPayerAssignmentModal(
          {
            toDisplay: false,
            isStaff: false,
            staffOrGroupId: "",
            updateGroupRecords: null,
            updateStaffRecords: null,
          },
          dispatch
        );

        // Close the payer unassignment modal
        displayPayerUnassignmentModal(
          {
            toDisplay: false,
            isStaff: false,
            staffOrGroupId: "",
            assignedPayers: [],
            updateGroupRecords: null,
            updateStaffRecords: null,
          },
          dispatch
        );

        // Close the manual staff adding modal
        displayManualStaffAddingModal(
          {
            toDisplay: false,
            handleAddingStaff: null,
          },
          dispatch
        );

        // Close group viewing modal
        displayViewGroupsModal(false, "", dispatch);

        // Close "add staffs to group modal"
        displayAddStaffsToGroupModal(false, "", null, dispatch);

        // Close "remove staffs from group" modal
        displayRemoveStaffsFromGroupModal(
          { toDisplay: false, groupId: "", assignedStaffs: [], updateGroupRecords: null },
          dispatch
        );

        // Close "view assigned payers" modal
        displayViewAssignedPayersModal(
          {
            toDisplay: false,
            assignedPayers: [],
            staffOrGroupName: "",
            isStaff: false,
          },
          dispatch
        );

        // Close "view assigned staffs" modal
        displayViewAssignedStaffsModal(
          {
            toDisplay: false,
            assignedStaffs: [],
            groupName: "",
          },
          dispatch
        );

        // Close add bulk staff modal
        displayAddBulkStaffModal(
          {
            toDisplay: false,
            updateStaffRecords: null,
          },
          dispatch
        );

        // Close create bulk group modal
        displayBulkCreateGroupModal(
          {
            toDisplay: false,
          },
          dispatch
        );
      }
      toDisplayPdfViewerModal(false, null, dispatch); // close the pdf viewer modal if displayed
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [dispatch]);

  return (
    <div className="main-modal-container" id="MODAL_CONTAINER">
      {props.modalType === E_FILING_ERROR_MODAL && (
        <div
          className="form-modal"
          style={{
            background: style.siteBg,
          }}
        >
          <div
            className="form-modal-header"
            style={{
              background: style.gModalTopPanelBg,
            }}
          >
            <h2>{modalHeading}</h2>
          </div>
          <div className="form-modal-message">
            <h3>{modalMessage}</h3>
          </div>
          <div className="form-modal-footer">
            <button
              className="close-btn"
              style={{
                background: style.btnBg,
                color: style.btnFillFontColor,
                boxShadow: isHovered ? `4px 4px 20px ${style.btnBg}` : "",
              }}
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
              onClick={handleModalClosure}
            >
              {modalDisplayTexts.closeBtnText}
            </button>
          </div>
        </div>
      )}
      {props.modalType === CONFIRMATION_MODAL && props.action !== null && (
        <ConfirmationModal action={props.action} />
      )}
      {props.modalType === FEEDBACK_MODAL && <FeedbackModal />}
      {props.modalType === INFO_MODAL && (
        <InfoModal heading={modalHeading} message={modalMessage} />
      )}

      {props.modalType === CORRECTION_FILING_MODAL && <CorrectionFilingModal />}
      {props.modalType === PDF_VIEWER_MODAL && props.blobData && (
        <PdfViewerModal pdfBlob={props.blobData} />
      )}

      {props.modalType === PAYER_ASSIGNMENT_MODAL && <AssignPayerModal />}
      {props.modalType === PAYER_UNASSIGNMENT_MODAL && <UnassignPayerModal />}

      {props.modalType === MANUAL_STAFF_ADDING_MODAL && <ManualStaffAddingModal />}
      {props.modalType === MANUAL_GROUP_CREATING_MODAL && <ManualGroupCreatingModal />}

      {props.modalType === ADD_STAFFS_TO_GROUP_MODAL && <AddStaffsToGroupModal />}
      {props.modalType === REMOVE_STAFFS_FROM_GROUP_MODAL && <RemoveStaffsFromGroupModal />}

      {props.modalType === GROUP_VIEWING_MODAL && <GroupViewingModal />}

      {props.modalType === VIEW_ASSIGNED_PAYERS_MODAL && <ViewAssignedPayersModal />}
      {props.modalType === VIEW_ASSIGNED_STAFFS_MODAL && <ViewAssignedStaffsModal />}

      {props.modalType === ADD_BULK_STAFF_MODAL && <AddBulkStaffModal />}
      {props.modalType === BULK_CREATE_GROUP_MODAL && <BulkCreateGroupModal />}
    </div>
  );
}
