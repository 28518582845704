import React from "react";
import Hammenu from "../Hammenu";
import NavLinks from "../../../NavLinks/NavLinks";
import { LANDING_PAGE } from "../../../../lib/routes";
import LoginRegister from "../../../LoginRegister";

export default function LandingHammenu({ currentRoute }: { currentRoute: string }) {
  return (
    <Hammenu>
      <NavLinks onLandingpage={currentRoute === LANDING_PAGE} currentpage={currentRoute} />
      <div className="py-10">
        <LoginRegister />
      </div>
    </Hammenu>
  );
}
