import React, { useState } from "react";
import Indicator from "../../Indicator";
import devLogInstance from "../../../lib/loggerConfig";
import { CreateGroupParams } from "../../../../interfaces/main";

interface Props extends React.HTMLProps<HTMLElement> {
  handleCreatingGroup: ((params: CreateGroupParams) => Promise<void>) | null;
}

export default function CreateGroupForm(props: Props) {
  const [groupData, setGroupData] = useState<CreateGroupParams>({
    group_name: "",
    group_description: "",
  });

  const [showErrorOn, toShowErrorOn] = useState({
    group_name: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.currentTarget;

    switch (id) {
      case "group-name":
        // Remove any error indicator
        toShowErrorOn({ ...showErrorOn, group_name: false });

        setGroupData({
          ...groupData,
          group_name: value,
        });
        break;

      case "group-description":
        value.length <= 400 &&
          setGroupData({
            ...groupData,
            group_description: value,
          });
        break;

      default:
        break;
    }
  };

  const basicValidations = (group_name: string) => {
    return new Promise(async (res, rej) => {
      const invalidFields = [];
      if (!group_name) invalidFields.push("group-name");

      invalidFields.length > 0 ? rej(invalidFields) : res(true);
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent the page from refresing

    const { group_name } = groupData;

    try {
      await basicValidations(group_name);

      if (props.handleCreatingGroup) {
        // Call the create group handler
        props.handleCreatingGroup(groupData);

        // Clear the input fields
        setGroupData({
          group_description: "",
          group_name: "",
        });
      } else devLogInstance.error("create group handler method is null!");
    } catch (invalidFields: any) {
      invalidFields.forEach((field: string) => {
        switch (field) {
          case "group-name":
            toShowErrorOn((prevState) => {
              return { ...prevState, group_name: true };
            });
            break;

          default:
            break;
        }
      });
    }
  };

  return (
    <form className={`${props.className} py-6 flex flex-col gap-6`} onSubmit={handleSubmit}>
      <div className="flex flex-col gap-1">
        <label htmlFor="group-name" className="label">
          Group Name*
        </label>
        <input
          type="text"
          id="group-name"
          className="p-2 w-full"
          onChange={handleChange}
          value={groupData.group_name}
        />
        {showErrorOn.group_name && (
          <Indicator type="group-name" message="Group name is required!" />
        )}
      </div>

      <div className="flex flex-col gap-1">
        <label htmlFor="group-description" className="label">
          Description
        </label>
        <input
          type="text"
          id="group-description"
          className="p-2 w-full"
          onChange={handleChange}
          value={groupData.group_description}
        />
      </div>

      <div className="text-center">
        <button
          id="create-group-btn"
          type="submit"
          className="py-2 px-4 rounded-md hover:opacity-80 bg-taxeve-primary-violet text-white"
        >
          Create Group
        </button>
      </div>
    </form>
  );
}
