import { axiosInstance } from "../axiosConfig";
import { XLSX } from "../constants";
import devLogInstance from "../loggerConfig";

export default async function getTemplate(format: string, payerId: string, formType: string) {
  const templFormat = format === XLSX ? "excel" : "csv";
  devLogInstance.log(`Form type while getting the template ---> ${formType}`);
  try {
    const res = await axiosInstance.get(`/get_templates/${formType}/${templFormat}`, {
      responseType: "blob",
    });
    const blobData = res.data;
    const header = res.headers;
    return { data: blobData, header: header };
  } catch (err) {
    throw err;
  }
}
