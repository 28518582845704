import { axiosInstance } from "../axiosConfig";

export default async function deleteCoupon(couponId: string) {
  try {
    const { data } = await axiosInstance.delete("/admin/delete_coupon", {
      data: {
        coupon_id: couponId,
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
}
