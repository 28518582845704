import React, { ReactNode } from "react";
import {
  formFieldErrMsg,
  invalidTinErrIndicator,
  payeeReferredrecipientDetails,
  recipientDetails,
  recipientDetailsFor1099S,
} from "../../../../lib/languagePacks/en-us";
import { useSelector, useDispatch } from "react-redux";
import {
  SET_RECIPIENT_TIN_DISPLAY,
  TO_SHOW_FORM_VALIDATION_ERR,
  TO_SHOW_INVALID_TIN_ERROR,
} from "../../../../redux/actions/action.types";
import formatTin from "../../../../lib/form/utils/formatTin";
import "../../../../styles/forms/globalComponentStyles/RecipientTin.css";
import Indicator from "../../../Indicator";
import {
  ALL_SAME_DIGIT_INVALID_RECIPIENT_TIN,
  ALL_ZERO_INVALID_RECIPIENT_TIN,
  INVALID_RECIPIENT_EIN,
  INVALID_RECIPIENT_SSN,
  INVALID_RECIPIENT_TIN_IN_ORDER,
  RECIPIENT_TIN,
} from "../../../../lib/constants";
import { formsWithRecAsPayee, formsWithRecAsTransferor } from "../RecipientDetails";
import { TinValidator } from "../../../../lib/validators";

const errIndicatorMap: Record<string, ReactNode> = {
  [INVALID_RECIPIENT_EIN]: (
    <Indicator type={RECIPIENT_TIN} message={invalidTinErrIndicator.invalidEin} />
  ),
  [INVALID_RECIPIENT_SSN]: (
    <Indicator type={RECIPIENT_TIN} message={invalidTinErrIndicator.invalidSsn} />
  ),
  [INVALID_RECIPIENT_TIN_IN_ORDER]: (
    <Indicator type={RECIPIENT_TIN} message={invalidTinErrIndicator.invalidTinInOrder} />
  ),
  [ALL_SAME_DIGIT_INVALID_RECIPIENT_TIN]: (
    <Indicator type={RECIPIENT_TIN} message={invalidTinErrIndicator.allDigitSameInvalidTin} />
  ),
  [ALL_ZERO_INVALID_RECIPIENT_TIN]: (
    <Indicator type={RECIPIENT_TIN} message={invalidTinErrIndicator.allZeroInvalidTin} />
  ),
};

function RecipientTin({ globalFormData, setGlobalFormData, formType }: any) {
  const style = useSelector((state: any) => state.themeChange);
  const tinDisplay = useSelector((state: any) => state.recTinDisplay);
  const showValidationError = useSelector((state: any) => state.showValidationErr);
  const showInvalidTinError = useSelector((state: any) => state.showInvalidTinError);
  const dispatch = useDispatch();

  // handle change on tin type
  const handleChange = (e: any) => {
    const { id, value } = e.target;
    switch (id) {
      case "ein-recipient":
        // Format the TIN
        const formattedTinEin = formatTin(tinDisplay, "EIN");

        // Update the tin display value
        formattedTinEin
          ? dispatch({
              type: SET_RECIPIENT_TIN_DISPLAY,
              payload: formattedTinEin,
            })
          : dispatch({
              type: SET_RECIPIENT_TIN_DISPLAY,
              payload: "",
            });

        setGlobalFormData({
          ...globalFormData,
          recipient_tin_type: "EIN",
          recipient_is_new_or_updated: true,
        });
        break;

      case "ssn-recipient":
        // Format the TIN
        const formattedTinSsn = formatTin(tinDisplay, "SSN");

        // Update the tin display value
        formattedTinSsn
          ? dispatch({
              type: SET_RECIPIENT_TIN_DISPLAY,
              payload: formattedTinSsn,
            })
          : dispatch({
              type: SET_RECIPIENT_TIN_DISPLAY,
              payload: "",
            });

        setGlobalFormData({
          ...globalFormData,
          recipient_tin_type: "SSN",
          recipient_is_new_or_updated: true,
        });
        break;

      case "atin-recipient":
        // Format the TIN
        const formattedTinAtin = formatTin(tinDisplay, "ATIN");

        // Update the tin display value
        formattedTinAtin
          ? dispatch({
              type: SET_RECIPIENT_TIN_DISPLAY,
              payload: formattedTinAtin,
            })
          : dispatch({
              type: SET_RECIPIENT_TIN_DISPLAY,
              payload: "",
            });

        setGlobalFormData({
          ...globalFormData,
          recipient_tin_type: "ATIN",
          recipient_is_new_or_updated: true,
        });
        break;

      case "itin-recipient":
        // Format the TIN
        const formattedTinItin = formatTin(tinDisplay, "ITIN");

        // Update the tin display value
        formattedTinItin
          ? dispatch({
              type: SET_RECIPIENT_TIN_DISPLAY,
              payload: formattedTinItin,
            })
          : dispatch({
              type: SET_RECIPIENT_TIN_DISPLAY,
              payload: "",
            });

        setGlobalFormData({
          ...globalFormData,
          recipient_tin_type: "ITIN",
          recipient_is_new_or_updated: true,
        });
        break;

      case "recipient-tin-input":
        // Remove any mandatory field warning on the recipient tin field
        dispatch({
          type: TO_SHOW_FORM_VALIDATION_ERR,
          payload: {
            type: RECIPIENT_TIN,
            toShowErr: false,
          },
        });

        dispatch({
          type: TO_SHOW_INVALID_TIN_ERROR,
          payload: {
            type: "",
            toShowErr: false,
          },
        });

        // SSN validation
        const isValidTin = TinValidator.ssnValidator(globalFormData.recipient_tin_type, value);

        // Format the tin and update the display val
        const formattedTin = isValidTin
          ? formatTin(value, globalFormData.recipient_tin_type)
          : null;
        formattedTin
          ? dispatch({
              type: SET_RECIPIENT_TIN_DISPLAY,
              payload: formattedTin,
            })
          : dispatch({
              type: SET_RECIPIENT_TIN_DISPLAY,
              payload: "",
            });

        // Update the payer tin on global data
        const pureVal = value.replaceAll("-", "");
        if (pureVal.length <= 9) {
          // const parsedVal = pureVal;
          setGlobalFormData({
            ...globalFormData,
            recipient_tin: pureVal ? pureVal : "",
            recipient_is_new_or_updated: true,
          });
        }
        break;

      default:
        break;
    }
  };

  return (
    <div className="recipient-tin-container">
      <div className="recipient-tin-heading-wrapper">
        <div className="recipient-tin-heading">
          <h4>
            {!formsWithRecAsPayee.includes(formType) && !formsWithRecAsTransferor.includes(formType)
              ? recipientDetails.recipientTinDisplayText
              : formsWithRecAsTransferor.includes(formType)
              ? recipientDetailsFor1099S.recipientTinDisplayText
              : formsWithRecAsPayee.includes(formType) &&
                payeeReferredrecipientDetails.recipientTinDisplayText}
          </h4>
        </div>
        <div className="tin-type">
          <div
            className="ein-container"
            style={{
              background:
                globalFormData.recipient_tin_type === "EIN"
                  ? style.tinSelectedBgColor
                  : style.tinBgColor,
            }}
          >
            <input
              type="radio"
              id="ein-recipient"
              onChange={handleChange}
              checked={globalFormData.recipient_tin_type === "EIN"}
            />
            <label htmlFor="ein-recipient">EIN</label>
          </div>
          <div
            className="ssn-container"
            style={{
              background:
                globalFormData.recipient_tin_type === "SSN"
                  ? style.tinSelectedBgColor
                  : style.tinBgColor,
            }}
          >
            <input
              type="radio"
              id="ssn-recipient"
              onChange={handleChange}
              checked={globalFormData.recipient_tin_type === "SSN"}
            />
            <label htmlFor="ssn-recipient">SSN</label>
          </div>
          <div
            className="atin-container"
            style={{
              background:
                globalFormData.recipient_tin_type === "ATIN"
                  ? style.tinSelectedBgColor
                  : style.tinBgColor,
            }}
          >
            <input
              type="radio"
              id="atin-recipient"
              onChange={handleChange}
              checked={globalFormData.recipient_tin_type === "ATIN"}
            />
            <label htmlFor="atin-recipient">ATIN</label>
          </div>
          <div
            className="itin-container"
            style={{
              background:
                globalFormData.recipient_tin_type === "ITIN"
                  ? style.tinSelectedBgColor
                  : style.tinBgColor,
            }}
          >
            <input
              type="radio"
              id="itin-recipient"
              onChange={handleChange}
              checked={globalFormData.recipient_tin_type === "ITIN"}
            />
            <label htmlFor="itin-recipient">ITIN</label>
          </div>
        </div>
      </div>

      <div className="tin-input-container">
        <label htmlFor="tin-input">
          {globalFormData.recipient_tin_type === "EIN" &&
          !formsWithRecAsPayee.includes(formType) &&
          !formsWithRecAsTransferor.includes(formType)
            ? recipientDetails.recipientEINPlaceholder
            : globalFormData.recipient_tin_type === "EIN" &&
              formsWithRecAsTransferor.includes(formType)
            ? recipientDetailsFor1099S.recipientEINPlaceholder
            : globalFormData.recipient_tin_type === "EIN" &&
              formsWithRecAsPayee.includes(formType) &&
              payeeReferredrecipientDetails.recipientEINPlaceholder}{" "}
          {globalFormData.recipient_tin_type === "SSN" &&
          !formsWithRecAsPayee.includes(formType) &&
          !formsWithRecAsTransferor.includes(formType)
            ? recipientDetails.recipientSSNPlaceholder
            : globalFormData.recipient_tin_type === "SSN" &&
              formsWithRecAsTransferor.includes(formType)
            ? recipientDetailsFor1099S.recipientSSNPlaceholder
            : globalFormData.recipient_tin_type === "SSN" &&
              formsWithRecAsPayee.includes(formType) &&
              payeeReferredrecipientDetails.recipientSSNPlaceholder}{" "}
          {globalFormData.recipient_tin_type === "ATIN" &&
          !formsWithRecAsPayee.includes(formType) &&
          !formsWithRecAsTransferor.includes(formType)
            ? recipientDetails.recipientATINPlaceholder
            : globalFormData.recipient_tin_type === "ATIN" &&
              formsWithRecAsTransferor.includes(formType)
            ? recipientDetailsFor1099S.recipientATINPlaceholder
            : globalFormData.recipient_tin_type === "ATIN" &&
              formsWithRecAsPayee.includes(formType) &&
              payeeReferredrecipientDetails.recipientATINPlaceholder}{" "}
          {globalFormData.recipient_tin_type === "ITIN" &&
          !formsWithRecAsPayee.includes(formType) &&
          !formsWithRecAsTransferor.includes(formType)
            ? recipientDetails.recipientITINPlaceholder
            : globalFormData.recipient_tin_type === "ITIN" &&
              formsWithRecAsTransferor.includes(formType)
            ? recipientDetailsFor1099S.recipientITINPlaceholder
            : globalFormData.recipient_tin_type === "ITIN" &&
              formsWithRecAsPayee.includes(formType) &&
              payeeReferredrecipientDetails.recipientITINPlaceholder}{" "}
          <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
        </label>
        <input
          className="payer-details-input"
          type="text"
          value={tinDisplay}
          id="recipient-tin-input"
          onChange={handleChange}
        />
        {showValidationError.onRecipientTin && (
          <Indicator
            type={RECIPIENT_TIN}
            message={
              !formsWithRecAsPayee.includes(formType) ||
              !formsWithRecAsTransferor.includes(formType)
                ? formFieldErrMsg.nec1099.recipient.emptyTin
                : formsWithRecAsTransferor.includes(formType)
                ? formFieldErrMsg.s1099.recipient.emptyTin
                : formsWithRecAsPayee.includes(formType) &&
                  formFieldErrMsg.payeeReferredRecipient.recipient.emptyTin
            }
          />
        )}
        {showInvalidTinError.toShowErr && errIndicatorMap[showInvalidTinError.type]}
      </div>
    </div>
  );
}

export default RecipientTin;
