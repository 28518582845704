import { Dispatch } from "@reduxjs/toolkit";
import { getTheFileName } from "../../components/forms/ReviewTable/BulkAction/BulkAction";
import Handler from "../Handlers/main";
import toDisplayPdfViewerModal from "../StateSetters/toDisplayPdfViewerModal";
import devLogInstance from "../loggerConfig";
import { Download } from "./Download";
import DisableScreen from "./disableScreen";

export class Action {
  static cartIds: any[] = [];
  static stateSetter: any = () => {};

  static download(downloadOptions = null) {
    Handler.handleDownload(this.cartIds, this.stateSetter, downloadOptions).then((res: any) => {
      if (res) {
        devLogInstance.log(res);
        const filename = getTheFileName(res.header["content-disposition"]);
        Download.downloadbyBlob(res.data, filename);
      }
    });
  }

  public static async previewPdf(
    previewOptions: { type: string; inModal: boolean },
    dispatch?: Dispatch<any>
  ) {
    const res = await Handler.handleDownload(this.cartIds, this.stateSetter, previewOptions);
    if (res) {
      const blobData: Blob = res.data;
      if (!previewOptions.inModal) return blobData;
      // Display the pdf in modal
      dispatch && toDisplayPdfViewerModal(true, blobData, dispatch);
      return null;
    }
    return null;
  }

  static formDownload(downloadOptions?: any) {
    this.download(downloadOptions);
  }

  static templateDownload(downloadOptions: any) {
    this.download(downloadOptions);
  }

  static delete() {
    DisableScreen.enable();
    Handler.handleFormDelete(this.cartIds, this.stateSetter).then((data) => {
      devLogInstance.log(data);
      DisableScreen.disable();
      window.location.reload();
    });
  }

  static edit(cartId: any, navigate: any) {
    Handler.handleFormEdit(cartId, this.stateSetter, navigate).then(() => {
      devLogInstance.log("Recieved response from backend, transferring to edit page");
    });
  }
}
