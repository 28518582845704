import { axiosInstance } from "../axiosConfig";

export default async function deleteAdmin(adminEmail: string) {
  try {
    const { data } = await axiosInstance.post("/admin/delete", {
      email: adminEmail,
    });
    return data;
  } catch (err) {
    throw err;
  }
}
