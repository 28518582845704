import React from "react";
import { ViewAssignedPayersActionPayload } from "../../../../../interfaces/main";
import { useDispatch, useSelector } from "react-redux";
import { IoIosCloseCircleOutline } from "react-icons/io";
import displayViewAssignedPayersModal from "../../../../lib/StateSetters/StaffAndGroupRelated/displayViewAssignedPayersModal";
import formatTin from "../../../../lib/form/utils/formatTin";

export default function ViewAssignedPayersModal() {
  const dispatch = useDispatch();

  const { assignedPayers, staffOrGroupName, isStaff }: ViewAssignedPayersActionPayload =
    useSelector((state: any) => state.displayViewAssignedPayersModal);

  const closeModal = () =>
    displayViewAssignedPayersModal(
      {
        toDisplay: false,
        assignedPayers: [],
        staffOrGroupName: "",
        isStaff: false,
      },
      dispatch
    );

  return (
    <div className="form-modal shadow-lg">
      <div className="form-modal-header bg-taxeve-primary-violet text-white flex items-center justify-between">
        <h2>Assigned Business'</h2>
        <IoIosCloseCircleOutline className="text-3xl cursor-pointer" onClick={closeModal} />
      </div>
      <div className="form-modal-body bg-white p-4 h-[25rem] overflow-auto">
        <div>
          <h3>
            <span className="underline underline-offset-2">Viewing all Business' assigned to</span>:{" "}
            {staffOrGroupName}
          </h3>
        </div>
        {assignedPayers.length > 0 ? (
          <table className="mt-4">
            <thead>
              <tr>
                <th>S. No.</th>
                <th className="text-center">Business Name</th>
                <th className="text-center">Business TIN</th>
              </tr>
            </thead>
            <tbody>
              {assignedPayers.map((payer, i) => (
                <tr key={payer._id}>
                  <td className="font-bold">{i + 1}</td>
                  <td className="text-center py-2 font-semibold">
                    <em>{payer.payer_name}</em>
                  </td>
                  <td className="text-center">
                    <span className="font-bold">{payer.payer_tin_type}</span>:{" "}
                    {formatTin(payer.payer_tin, payer.payer_tin_type)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="h-[18rem] flex items-center justify-center text-2xl font-bold opacity-40">
            <em>
              Currently there are no Business' assigned to this {isStaff ? "member" : "group"} yet
            </em>
          </div>
        )}
      </div>
    </div>
  );
}
