import React from "react";
import { useSelector } from "react-redux";
import DownloadTemplateBtn from "./DownloadTemplateBtn/DownloadTemplateBtn";
import "./TemplateDownloader.component.css";

interface Props {
  payerId: string;
  formType: string;
}

export default function TemplateDownloader({ payerId, formType }: Props) {
  const style = useSelector((state: any) => state.themeChange);
  return (
    <main
      className="template-downloader-main"
      style={{ border: `1px solid ${style.tableHeadingPrimaryColor}` }}
    >
      <div className="instruction">
        <div className="instruction-heading" style={{ background: style.tableHeadingPrimaryColor }}>
          <h2>Instructions</h2>
        </div>
        <ul>
          <li>Download the TaxEve template</li>
          <li>Fill the downloaded template and upload the file in the upload section below</li>
        </ul>
      </div>
      <DownloadTemplateBtn payerId={payerId} formType={formType} />
    </main>
  );
}
