// Format the tin with hyphens as per the tin type for display. Easier for the user to understand
export default function formatTin(value: any, type: any) {
  if (type === "EIN") {
    const tinRegex = /^\d(-|\d)*$/;
    let updatedVal = value.replace(/-/gi, "");
    if (updatedVal.length > 2) {
      updatedVal = updatedVal.substring(0, 2) + "-" + updatedVal.substring(2);
    }
    const isValidTin = tinRegex.test(updatedVal);
    if (isValidTin) {
      if (updatedVal.length <= 10) return updatedVal;
      else return updatedVal.substring(0, 10);
    }
  } else {
    const tinRegex = /^\d(-|\d)*$/;
    let updatedVal = value.replace(/-/gi, "");
    if (updatedVal.length > 3) {
      updatedVal = updatedVal.substring(0, 3) + "-" + updatedVal.substring(3);
    }
    if (updatedVal.length > 6) {
      updatedVal =
        updatedVal.substring(0, 3) +
        "-" +
        updatedVal.substring(4, 6) +
        "-" +
        updatedVal.substring(6);
    }

    const isValidTin = tinRegex.test(updatedVal);
    if (isValidTin) {
      if (updatedVal.length <= 11) return updatedVal;
      else return updatedVal.substring(0, 11);
    }
  }
}
