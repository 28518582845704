import { axiosInstance } from "../axiosConfig";

export default async function updateCartCheckbox(updateOptions: any) {
  try {
    const { checkbox_type, cart_id_array, checked } = updateOptions;
    const { data } = await axiosInstance.post("/update_check_boxes_in_cart", {
      checkbox_type: checkbox_type,
      cart_id_array: cart_id_array,
      checked: checked,
    });
    return data;
  } catch (err) {
    throw err;
  }
}
