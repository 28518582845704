import { SET_IS_AUTHENTICATED } from "../actions/action.types";

const initialState = null;

const isAuthenticated = (state = initialState, action: any) => {
  if (action.type === SET_IS_AUTHENTICATED) {
    state = action.payload;
    return state;
  } else {
    return state;
  }
};

export default isAuthenticated;
