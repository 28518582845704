// import retrieveToken from "../authRequests/retrieveTokens";
import { axiosInstance } from "../axiosConfig";

export default async function getIrscopies(cartIds: any[]) {
  try {
    const res = await axiosInstance.post(
      "/get_irs_copies",
      {
        cart_id_array: cartIds,
      },
      {
        responseType: "blob",
        // headers: {
        // 	auth_token: retrieveToken("auth_token"),
        // },
      }
    );
    return { data: res.data, header: res.headers };
  } catch (err) {
    throw err;
  }
}
