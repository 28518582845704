import React from "react";
import { RxCross2 } from "react-icons/rx";

export default function Hammenu({ children }: any) {
  return (
    <div className="fixed h-[100%] w-[100%] overflow-auto bg-white z-20 px-6 pb-6">
      <div className="py-4 flex justify-end">
        <RxCross2 className="text-xl text-black cursor-pointer" />
      </div>
      {children}
    </div>
  );
}
