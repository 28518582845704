import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import displayViewGroupsModal from "../../../../lib/StateSetters/StaffAndGroupRelated/displayViewGroupsModal";
import { useDispatch, useSelector } from "react-redux";
import { GroupsForSingleStaffData } from "../../../../../interfaces/main";
import Handler from "../../../../lib/Handlers/main";

export default function GroupViewingModal() {
  const dispatch = useDispatch();
  const [groups, setGroups] = useState<GroupsForSingleStaffData[]>([]);

  const viewGroupsProps: { staffId: string } = useSelector(
    (state: any) => state.displayViewGroupsModal
  );

  // Get all the groups for the current staff and set them as a state
  useEffect(() => {
    const getGroups = async () => {
      const groups: GroupsForSingleStaffData[] = await Handler.handleGettingGroupsForSingleStaff(
        viewGroupsProps.staffId
      );
      setGroups(groups);
    };
    getGroups();

    return () => setGroups([]);
  }, [viewGroupsProps.staffId]);

  // Close modal
  const closeModal = () => {
    displayViewGroupsModal(false, "", dispatch);
  };

  return (
    <div className="form-modal shadow-lg">
      <div className="form-modal-header bg-taxeve-primary-violet text-white flex items-center justify-between">
        <h2>Groups</h2>
        <IoIosCloseCircleOutline className="text-3xl cursor-pointer" onClick={closeModal} />
      </div>
      <div className="form-modal-body bg-white p-4 h-[25rem] overflow-auto">
        <div>
          <h3>Viewing all the Groups the current member is assigned to:</h3>
        </div>
        {groups.length > 0 ? (
          <table className="mt-4">
            <thead>
              <tr>
                <th>S. No.</th>
                <th className="text-center">Group name</th>
                <th className="text-center">Group description</th>
              </tr>
            </thead>
            <tbody>
              {groups.map((group, i) => (
                <tr key={group._id}>
                  <td className="font-bold">{i + 1}</td>
                  <td className="text-center py-2 font-semibold">
                    <em>{group.group_name}</em>
                  </td>
                  <td className="text-center">{group.group_description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="h-[18rem] flex items-center justify-center text-2xl font-bold opacity-40">
            <em>Currently this member is not assigned to any Group</em>
          </div>
        )}
      </div>
    </div>
  );
}
