import { browserLogger } from "@banglarthek/logger";
import { DEV_ENVIRONMENT, PROD_ENVIRONMENT, STAGING_ENVIRONMENT } from "./constants";

const devLogInstance = browserLogger.create({
  loggerEnvironments: [DEV_ENVIRONMENT, STAGING_ENVIRONMENT],
});

export const prodLogInstance = browserLogger.create({
  loggerEnvironments: [PROD_ENVIRONMENT, DEV_ENVIRONMENT, STAGING_ENVIRONMENT],
});

export default devLogInstance;
