/*
This function filters the search based on immediate inputs by the user.
The search is done based on the react select label value. Since most of the time the label might
not be just a string (it can be a React element), a condition is checked before assigning a string value
to the label variable.
If the react select label value is not a string, it is considered a React element and handled accordingly.
As of now, only the 1st and the 3rd element element in the child prop of the parent element is another react element which
contains the content as a child, so we get that by content[0].props.children (here content is derived 
from the parent element)
*/

export default function customFilter(option: any, searchText: any) {
  const labelType = typeof option.label;
  let label = option.label;
  if (labelType !== "string") {
    const content = label.props.children;
    label = content[0].props.children + content[1] + content[2] + content[3].props.children;
  }
  if (label.toLowerCase().substring(0, searchText.length) === searchText.toLowerCase()) return true;
  else return false;
}
