import React, { useEffect, useState } from "react";
import Navbar from "../components/navbar";
import { CONTACT_US_PAGE } from "../lib/routes";
import { FaPhoneAlt } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import Footer from "../components/Footer/Footer";
import { contactDisplayText } from "../lib/languagePacks/en-us";
import contactInfo from "../lib/json/contactInfo.json";
import formatPhonenum from "../lib/form/utils/formatPhone";
import { ContactUsData } from "../../interfaces/main";
import Indicator from "../components/Indicator";
import { EMAIL_REGEX } from "../lib/patterns";
import devLogInstance from "../lib/loggerConfig";
import Handler from "../lib/Handlers/main";
import { ga4 } from "../App";
import ga4Const from "../lib/json/AnalyticsRelated/analyticsConst.json";
import scrollToTop from "../lib/utils/scrollToTop";

export const NAME = "NAME";
export const EMAIL = "EMAIL";
export const COMPANY = "COMPANY";
export const PHONE = "PHONE";
export const QUERY = "QUERY";

export default function Contact() {
  scrollToTop();

  const [contactData, setContactData] = useState<ContactUsData>({
    full_name: "",
    email: "",
    company_name: "",
    phone: "",
    body: "",
  });
  const [showErrIndicator, toShowErrIndicator] = useState({
    onName: false,
    onEmail: false,
    onQuery: false,
  });

  devLogInstance.log(contactData);

  // Handle change event
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id: type, value } = e.currentTarget;
    switch (type) {
      case NAME:
        toShowErrIndicator({
          ...showErrIndicator,
          onName: false,
        });
        value.length <= 40 &&
          setContactData({
            ...contactData,
            full_name: value,
          });
        break;

      case EMAIL:
        toShowErrIndicator({
          ...showErrIndicator,
          onEmail: false,
        });
        value.length <= 40 &&
          setContactData({
            ...contactData,
            email: value,
          });
        break;

      case COMPANY:
        value.length <= 20 &&
          setContactData({
            ...contactData,
            company_name: value,
          });
        break;

      case PHONE:
        const formattedNum = formatPhonenum(value);
        setContactData({
          ...contactData,
          phone: formattedNum ?? "",
        });
        break;

      case QUERY:
        toShowErrIndicator({
          ...showErrIndicator,
          onQuery: false,
        });
        value.length <= 500 &&
          setContactData({
            ...contactData,
            body: value,
          });
        break;

      default:
        break;
    }
  };

  // Handle form submit
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Verify the input values
    const isEmailPresent = contactData.email.length > 0;
    const isValidEmail = isEmailPresent ? new RegExp(EMAIL_REGEX).test(contactData.email) : false;

    const isNamePresent = contactData.full_name.length > 0;

    const isQueryPresent = contactData.body.length > 0;

    if (!isValidEmail && !isNamePresent && !isQueryPresent)
      toShowErrIndicator({ onName: true, onEmail: true, onQuery: true });
    else if (!isValidEmail)
      toShowErrIndicator({
        ...showErrIndicator,
        onEmail: true,
      });
    else if (!isNamePresent) toShowErrIndicator({ ...showErrIndicator, onName: true });
    else if (!isQueryPresent) toShowErrIndicator({ ...showErrIndicator, onQuery: true });
    else {
      const dataToSend: ContactUsData = {
        ...contactData,
        phone: contactData.phone
          ? parseInt(
              contactData.phone
                .toString()
                .replace(/\(/g, "")
                .replace(/\)/g, "")
                .replace(/-/g, "")
                .replace(/ /g, "")
            )
          : 0, // Parse the formatted phone number string into integer
      };
      devLogInstance.log("Contact data before dispatching to backend");
      devLogInstance.log(dataToSend);

      // Send the data to API
      Handler.handleSendingContactUsData(dataToSend);
    }
  };

  // send navigation report to google analytics
  useEffect(() => {
    ga4.sendEvent({
      category: ga4Const.event.category.navigation,
      action: `${ga4Const.event.action.navigateIn} to Contact us page`,
    });
    return () => {
      ga4.sendEvent({
        category: ga4Const.event.category.navigation,
        action: `${ga4Const.event.action.navigateOut} from Contact us page`,
      });
    };
  }, []);

  return (
    <div className="relative">
      <Navbar page={CONTACT_US_PAGE} />
      <section id="banner" className="banner font-bold">
        <div className="nested-div-in-banner">
          <h1 className="text-3xl">Get in touch</h1>
        </div>
      </section>

      <section
        id="contact-details-section"
        className="px-4 lg:px-40 py-16 flex flex-col sm:flex-row gap-20"
      >
        <div id="contact-details-wrapper">
          <div id="phone-contact" className="flex items-center gap-6 py-4  border-b-slate-400">
            <FaPhoneAlt className="text-2xl" />
            <span>
              <h2 className="text-2xl font-semibold py-2">{contactDisplayText.phoneheading}</h2>
              <p className="text-xl">{formatPhonenum(contactInfo.contactnumber)}</p>
            </span>
          </div>

          <div id="email-contact" className="flex items-center gap-6 py-4">
            <GrMail className="text-3xl" />
            <span>
              <h2 className="text-2xl font-semibold py-2">{contactDisplayText.emailHeading}</h2>
              <p className="text-xl">{contactInfo.contactemail}</p>
            </span>
          </div>
        </div>

        <form id="message-us" className="w-[100%] sm:w-[70%]" onSubmit={handleSubmit}>
          <h1 className="text-2xl font-bold">{contactDisplayText.messageHeading}</h1>
          <div id="user-info" className="py-4 flex flex-wrap gap-6">
            <div className="w-[48%]">
              <input
                id={NAME}
                type="text"
                className="w-[100%] py-2"
                placeholder={contactDisplayText.messagePlaceholders.name}
                onChange={handleChange}
                value={contactData.full_name}
              />
              {showErrIndicator.onName && (
                <Indicator type={NAME} message="Full name is mandatory!" />
              )}
            </div>

            <input
              id={COMPANY}
              type="text"
              className="w-[48%] py-2"
              placeholder={contactDisplayText.messagePlaceholders.company}
              onChange={handleChange}
              value={contactData.company_name}
            />

            <input
              id={PHONE}
              type="text"
              className="w-[48%] py-2"
              placeholder={contactDisplayText.messagePlaceholders.phone}
              onChange={handleChange}
              value={contactData.phone}
            />

            <div className="w-[48%]">
              <input
                id={EMAIL}
                type="email"
                className="w-[100%] py-2"
                placeholder={contactDisplayText.messagePlaceholders.email}
                onChange={handleChange}
                value={contactData.email}
              />
              {showErrIndicator.onEmail && (
                <Indicator message="A valid email address is required!" />
              )}
            </div>
          </div>

          <div className="py-4">
            <textarea
              name="help-desk"
              id={QUERY}
              rows={5}
              placeholder={contactDisplayText.messagePlaceholders.message}
              className="w-[100%] border-[1px] border-slate-400 rounded-lg resize-none px-4 py-4"
              onChange={handleChange}
              value={contactData.body}
            />
            {showErrIndicator.onQuery && (
              <Indicator message="Please provide a query so we can assist you better!" />
            )}
          </div>
          <div id="send-btn-container" className="text-center">
            <button
              type="submit"
              className="py-2 px-8 rounded-lg text-xl bg-red-600 text-white hover:shadow-xl"
            >
              Send
            </button>
          </div>
        </form>
      </section>
      <Footer />
    </div>
  );
}
