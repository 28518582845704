import decrypt from "../AESHashing/decrypt";
import encrypt from "../AESHashing/encrypt";

export default class SessionStorage {
  static exists(key: string) {
    const item = sessionStorage.getItem(key);
    return item ? true : false;
  }

  static setCacheItem(key: string, value: any) {
    const encryptedVal = encrypt(JSON.stringify(value));
    sessionStorage.setItem(key, encryptedVal.toString());
  }

  static getCacheItem(key: string) {
    const encryptedString = sessionStorage.getItem(key);
    const decryptedString = encryptedString ? decrypt(encryptedString) : "";
    return decryptedString ? JSON.parse(decryptedString) : null;
  }

  static removeCacheItem(key: string) {
    sessionStorage.removeItem(key);
  }
}
