import { axiosInstance } from "../../axiosConfig";
import devLogInstance, { prodLogInstance } from "../../loggerConfig";

export default async function getFormCorrectionData(formId: string) {
  devLogInstance.log("Getting form correction data...");
  try {
    const { data } = await axiosInstance.post("/get_form_data_for_correction", { form_id: formId });
    devLogInstance.log("Response: ");
    devLogInstance.log(data);

    return data;
  } catch (err) {
    prodLogInstance.error(err);
    throw err;
  }
}
