import React from "react";
import Hammenu from "../Hammenu";
import { Link, useNavigate } from "react-router-dom";
import { RiFileExcel2Fill, RiUserSettingsFill } from "react-icons/ri";
import {
  BUSINESS_SETTINGS,
  CART,
  HISTORY_PAGE,
  PROFILE_SETTINGS,
  REVIEW,
  TEMPLATES_DOWNLOAD,
} from "../../../../lib/routes";
import { FaHistory } from "react-icons/fa";
import { SiCodereview } from "react-icons/si";
import { useDispatch, useSelector } from "react-redux";
import { FaShoppingCart } from "react-icons/fa";
import { BiLogOutCircle } from "react-icons/bi";
import logoutUser from "../../../../lib/authRequests/logoutUser";

export default function PostLoginHammenu() {
  const totalRPItems = useSelector((state: any) => state.totalRPItems);
  const totalCartItems = useSelector((state: any) => state.totalCartItems);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Hammenu>
      <div className="pt-12">
        <Link
          to={TEMPLATES_DOWNLOAD}
          className="font-bold flex items-center gap-3 py-6 xl:py-0 border-b-[1px] border-black xl:border-none"
        >
          <RiFileExcel2Fill className="text-3xl" />
          <p className="font-normal">Download Templates</p>
        </Link>

        <Link
          to={HISTORY_PAGE}
          className="py-6 flex items-center gap-3 border-b-[1px] border-black xl:border-none"
        >
          <FaHistory className="text-2xl" />
          <p>Order History</p>
        </Link>

        <Link
          to={REVIEW}
          className="py-6 flex items-center gap-3 border-b-[1px] border-black xl:border-none"
        >
          <SiCodereview className="text-2xl" />
          <p>Review Pending {totalRPItems > 0 && `(${totalRPItems})`}</p>
        </Link>

        <Link
          to={BUSINESS_SETTINGS}
          className="py-6 flex items-center gap-3 border-b-[1px] border-black xl:border-none"
        >
          <RiUserSettingsFill className="text-3xl cursor-pointer" />
          <p>Business Settings</p>
        </Link>

        <a
          href={CART}
          className="py-6 flex items-center gap-3 border-b-[1px] border-black xl:border-none"
        >
          <FaShoppingCart className="text-3xl" />
          <p>Cart {totalCartItems > 0 && `(${totalCartItems})`}</p>
        </a>
      </div>

      <div
        className="py-6 border-b-[1px] border-b-black flex items-center gap-2 cursor-pointer"
        onClick={() => {
          logoutUser(dispatch);
        }}
      >
        <BiLogOutCircle className="text-3xl" />
        <p>Logout</p>
      </div>

      <div
        className="mt-6 text-center py-2 bg-taxeve-primary-blue text-white rounded-lg cursor-pointer"
        onClick={() => {
          navigate(PROFILE_SETTINGS);
        }}
      >
        <p>Profile Settings</p>
      </div>
    </Hammenu>
  );
}
