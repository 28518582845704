import React from "react";
import { useState } from "react";
import "./ChangeFullname.css";
import { useDispatch, useSelector } from "react-redux";
import devLogInstance from "../../../lib/loggerConfig";
import Handler from "../../../lib/Handlers/main";

export default function ChangeFullname() {
  const [inputVals, setInputVals] = useState({
    newFullname: "",
  });
  const style = useSelector((state: any) => state.themeChange);
  const stateSetter = useDispatch();

  const handleChange = (e: any) => {
    const { id: inputType, value } = e.target;
    switch (inputType) {
      case "new-fullname":
        setInputVals({
          ...inputVals,
          newFullname: value,
        });
        break;

      default:
        break;
    }
  };

  const handleNameUpdate = (e: any) => {
    e.preventDefault();
    devLogInstance.log(inputVals.newFullname);
    Handler.handleFullnameChange(inputVals.newFullname, stateSetter);
  };

  return (
    <main className="fullname-setting-main">
      <form className="fullname-setting-left-portion" onSubmit={handleNameUpdate}>
        <div className="new-fullname-container">
          <div className="new-fullname-wrapper">
            <label htmlFor="new-fullname">
              <h3>Full Name</h3>
            </label>
            <p>Enter the name you want to update</p>
            <input
              className="new-fullname-input"
              type="text"
              id="new-fullname"
              value={inputVals.newFullname}
              onChange={handleChange}
            />
          </div>
        </div>
        <button
          type="submit"
          className="fullname-change-btn"
          style={{ background: style.btnBg, color: style.btnFillFontColor }}
        >
          Change Fullname
        </button>
      </form>
    </main>
  );
}
