import { AdminCouponData } from "../../../interfaces/main";
import { axiosInstance } from "../axiosConfig";

export default async function addCoupon(coupondata: AdminCouponData) {
  try {
    const { data } = await axiosInstance.post("/admin/add_coupons", coupondata);
    return data;
  } catch (error) {
    throw error;
  }
}
