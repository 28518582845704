import React from "react";
import { misc1099AmountDisplayTexts } from "../../../../lib/languagePacks/en-us";
import { useDispatch, useSelector } from "react-redux";
import "./Fatca.component.css";
import AmountContainer from "../AmountContainer/AmountContainer";
import { CUSIP_NUM } from "../../../../lib/constants";
import AmountHeading from "../AmountContainer/AmountHeading/AmountHeading";
import devLogInstance from "../../../../lib/loggerConfig";
import Box10And11 from "../1099-R-Box10_11/Box10And11";
import { BsInfoCircleFill } from "react-icons/bs";
import displayInfoModal from "../../../../lib/StateSetters/displayInfoModal";
import CusipInfoModalBody from "../../../decorations/Modal/CusipInfoModalBody/CusipInfoModalBody";

const CUSIP_INFO_ICON = "CUSIP_INFO_ICON";

export default function Fatca({
  globalFormData,
  setGlobalFormData,
  type,
  displayCode,
  showCusip = false,
  showBox10and11 = false,
  styles = {},
  _1099RBox10,
  _1099RBox11,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  const stateSetter = useDispatch();

  // Handle input change
  const handleChange = (e: any) => {
    const { id: type, value } = e.target;
    devLogInstance.log(`CHANGE IN ---> ${type}`);
    switch (type) {
      case "fatca":
        setGlobalFormData({
          ...globalFormData,
          fatca_filing_requirement_indicator: !globalFormData?.fatca_filing_requirement_indicator,
        });
        break;

      case CUSIP_NUM:
        const alphaNumRegex = new RegExp("^[a-z0-9]+$"); // To validate alpha numeric data
        const isValid_ = value.length > 0 ? alphaNumRegex.test(value) : true;
        isValid_ &&
          value.length <= 13 &&
          setGlobalFormData({
            ...globalFormData,
            cusip_Number: value,
          });
        break;

      default:
        break;
    }
  };

  // Handle click event
  const handleClick = (e: React.MouseEvent<SVGElement | HTMLElement>) => {
    const { id } = e.currentTarget;

    switch (id) {
      case CUSIP_INFO_ICON:
        // Display the info modal
        displayInfoModal(true, <CusipInfoModalBody />, "Cusip Number", stateSetter);
        break;

      default:
        break;
    }
  };

  return (
    <div
      className="fatca-filing-container"
      style={{
        borderBottom: `1px solid ${style.eFormBorderColor}`,
        borderLeft: type === "review-form" ? "none" : `1px solid ${style.eFormBorderColor}`,
        borderRight: type === "review-form" ? "none" : `1px solid ${style.eFormBorderColor}`,
        borderTop: "0",
        height: type === "review-form" ? "11%" : "",
        ...styles,
      }}
    >
      {!showCusip && !showBox10and11 ? (
        <div
          className="blank"
          style={{
            background: "#F5F5F5",
            width: "75%",
            borderRight: `1px solid ${style.eFormBorderColor}`,
          }}
        />
      ) : showCusip && !showBox10and11 ? (
        <AmountContainer
          class_="misc-info" // Only one class can be passed
          style={{
            borderRight: `1px solid ${style.eFormBorderColor}`,
            // width: "100%",
          }}
        >
          <div className="flex items-center gap-4">
            <AmountHeading heading={"CUSIP number"} />
            <BsInfoCircleFill
              id={CUSIP_INFO_ICON}
              className="cursor-pointer"
              onClick={handleClick}
            />
          </div>
          {type !== "review-form" ? (
            <input
              id={CUSIP_NUM}
              type="text"
              style={{ padding: "0.5rem 1rem" }}
              value={globalFormData?.cusip_Number ? globalFormData?.cusip_Number : ""}
              onChange={handleChange}
            />
          ) : (
            <div style={{ padding: "0.5rem 1rem" }}>{globalFormData?.cusip_Number}</div>
          )}
        </AmountContainer>
      ) : !showCusip && showBox10and11 ? (
        <Box10And11 box10={_1099RBox10} box11={_1099RBox11} />
      ) : (
        <>show both</>
      )}
      <div
        style={{
          // display: type === "review-form" && "block",
          padding: type === "review-form" ? "0.5rem 1rem" : "2rem",
          width: "25%",
        }}
        className="justify-center"
      >
        <div>
          <h4 style={{ display: "flex", justifyContent: "center", gap: "0.25rem" }}>
            <div>{displayCode && `${displayCode}`}</div>
            <div>{misc1099AmountDisplayTexts.fatca}</div>
          </h4>
        </div>
        <div style={{ marginTop: type === "review-form" ? "0.5rem" : "initial" }}>
          <input
            type="checkbox"
            id="fatca"
            checked={globalFormData?.fatca_filing_requirement_indicator}
            onChange={type !== "review-form" ? handleChange : () => {}}
          />
        </div>
      </div>
    </div>
  );
}
