import React, { ReactNode } from "react";

interface Props extends React.HTMLAttributes<HTMLElement> {
  text: string;
  icon?: string | ReactNode;
}

export default function PanelHeading(props: Props) {
  return (
    <div className={`pt-6 pb-4 border-b-[1px] border-b-slate-300 ${props.className}`}>
      <h2 className="flex items-center gap-2">
        {props.icon && props.icon}
        {props.text}
      </h2>
    </div>
  );
}
