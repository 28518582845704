// import retrieveToken from "../authRequests/retrieveTokens";
import { axiosInstance } from "../axiosConfig";
import devLogInstance from "../loggerConfig";

export default async function getHomeData() {
  const cookie = document.cookie;
  devLogInstance.log("THE COOKIE IS: " + cookie);
  try {
    const { data } = await axiosInstance.post("/home", { year: 2022 });
    return data;
  } catch (err) {
    throw err;
  }
}
