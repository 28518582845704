import React from "react";
import "./Dropbox.component.css";

interface Props {
  file: File | null;
  noCSV?: boolean;
}

export default function Dropbox({ file, noCSV }: Props) {
  return (
    <div className="dropbox-container">
      <img src="/Assets/excel_upload_icon.svg" alt="Upload Excel/CSV" className="w-[6%]" />
      {!file ? (
        <div className="dropbox-text-wrapper">
          <div className="dropbox-text">
            Drag & Drop files OR Click to select a file from your computer
          </div>
          <div className="allowed-file-types">(Allowed file types: {!noCSV && "CSV, "}XLSX)</div>
        </div>
      ) : (
        <div>You have selected: {file.name}</div>
      )}
    </div>
  );
}
