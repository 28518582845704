import {
  DISPLAY_POPUP,
  SET_POPUP_MESSAGE,
  SET_POPUP_STATUS,
} from "../../redux/actions/action.types";

export default function callPopup(message: string, status: string, dispatch: any) {
  dispatch({
    type: DISPLAY_POPUP,
    payload: true,
  });
  dispatch({
    type: SET_POPUP_STATUS,
    payload: status,
  });
  dispatch({
    type: SET_POPUP_MESSAGE,
    payload: message,
  });
}
