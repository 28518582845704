const event = process.env.REACT_APP_EVENT;

class Logger {
  logMsg: any;
  log(msg: any) {
    if (event === "DEVELOPMENT") {
      console.log(msg);
    } else if (event === "STAGING") {
      console.log(this.logMsg);
    }
  }

  warning(warnMsg: any) {
    if (event === "DEVELOPMENT") {
      console.warn(warnMsg);
    }
  }

  error(errMsg: any) {
    console.error(errMsg);
  }
}

const logger = new Logger();

export default logger;
