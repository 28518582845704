import { SET_IS_AUTHENTICATED } from "../../redux/actions/action.types";
import devLogInstance from "../loggerConfig";
import Cookie from "../utils/Cookie";
import logoutUser from "./logoutUser";

export default function checkAuth(authSetter: any) {
  const accessTokenCookieExists = Cookie.retrieveCookie("auth_token");
  if (accessTokenCookieExists) {
    devLogInstance.log("Access token Cookie exists");
    authSetter({
      type: SET_IS_AUTHENTICATED,
      payload: true,
    });
  } else {
    // Logout user
    logoutUser(authSetter);
  }
}
