import React from "react";
import {
  formFieldErrMsg,
  payerDetails,
  payerDetailsFor1099S,
  uploadPageDisplayTexts,
} from "../../../lib/languagePacks/en-us";
import { calCurrentTaxYear } from "../../../lib/calculateTaxYear";
import Address from "./Address";
import { useSelector, useDispatch } from "react-redux";
import PayerTin from "./InternalComponents/PayerTin";
import logger from "../../../logger";
import CustomSearch from "../../decorations/CustomSearch";
import {
  SET_PHONE_DISPLAY,
  PAYER_PHONE,
  TO_SHOW_FORM_VALIDATION_ERR,
  TO_SHOW_INVALID_TIN_ERROR,
} from "../../../redux/actions/action.types";
import formatPhonenum from "../../../lib/form/utils/formatPhone";
import updateTinDisplay from "../../../lib/form/utils/Display Update/updateTinDisplay";
import handleGetPayerDetails from "../../../lib/form/utils/handleGetPayerData";
import { SET_TO_DISABLE_SCREEN } from "../../../redux/actions/action.types";
import updateZipDisplay from "../../../lib/form/utils/Display Update/updateZipDisplay";
import updatePhoneDisplay from "../../../lib/form/utils/Display Update/updatePhoneDisplay";
import { SET_RECIPIENT_NAMES } from "../../../redux/actions/action.types";
import "../../../styles/forms/globalComponentStyles/PayerDetails.css";
import Indicator from "../../Indicator";
import {
  FORM1099_K,
  FORM1099_S,
  PAGE_UPLOAD,
  PAYER_ALL_FIELDS,
  PAYER_COUNTRY,
  PAYER_NAME,
  PAYER_PHONE_NUMBER,
  PAYER_STATE,
} from "../../../lib/constants";
import { useState } from "react";
import { useEffect } from "react";

export const formsWithPayerAsFiler = [FORM1099_S, FORM1099_K];

export default function PayerDetails({
  globalFormData,
  setGlobalFormData,
  pageType,
  recipientNotNeeded,
  formType,
  isCorrection,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  const payerNames = useSelector((state: any) => state.setPayerNames);
  const phoneDisplay = useSelector((state: any) => state.phoneDisplay);
  const showValidationError = useSelector((state: any) => state.showValidationErr);
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();
  logger.log(payerNames);

  // Remove all form field error indicators initially
  useEffect(() => {
    dispatch({
      type: TO_SHOW_FORM_VALIDATION_ERR,
      payload: {
        type: PAYER_ALL_FIELDS,
        toShowErr: false,
      },
    });
  }, [dispatch]);

  // Remove all invalin tin errors on component destroy
  useEffect(() => {
    return () => {
      dispatch({
        type: TO_SHOW_INVALID_TIN_ERROR,
        payload: {
          type: "",
          toShowErr: false,
        },
      });
    };
  }, [dispatch]);

  // Update the global data
  const updateGlobalFormData = (updatedData: any, type: string) => {
    if (type === "new") {
      setGlobalFormData({
        ...globalFormData,
        ...updatedData,
        payer_is_new_or_updated: true,
      });
    } else if (type === "existing") {
      setGlobalFormData({
        ...globalFormData,
        ...updatedData,
        payer_is_new_or_updated: false,
      });

      // Update the payer tin display
      updateTinDisplay(updatedData.payer_tin, updatedData.payer_tin_type, "payer", dispatch);

      // Update the payer zip display
      updateZipDisplay(updatedData.payer_zipcode, "payer", dispatch);

      // Update the payer phone number display
      updatePhoneDisplay(updatedData.payer_phone_number, PAYER_PHONE, dispatch);

      // Remove all mandatory field warnings from payer block
      dispatch({
        type: TO_SHOW_FORM_VALIDATION_ERR,
        payload: {
          type: PAYER_ALL_FIELDS,
          toShowErr: false,
        },
      });
    }
  };

  // Handle Getting payer details
  const getPayerDetails = async (payerId: string) => {
    // Start the screen disabler
    dispatch({
      type: SET_TO_DISABLE_SCREEN,
      payload: true,
    });

    // Get the payer details
    const data = await handleGetPayerDetails(payerId, dispatch, recipientNotNeeded);

    // Stop the screen disabler
    dispatch({
      type: SET_TO_DISABLE_SCREEN,
      payload: false,
    });
    logger.log(data);

    if (!recipientNotNeeded) {
      // Set the recipient names
      dispatch({
        type: SET_RECIPIENT_NAMES,
        payload: data.recipient_names,
      });
    }

    // Update the global form data
    updateGlobalFormData(data.payer_details, "existing");

    // Remove any warnings from react select components
    // Remove any mandatory field warning from payer country select field
    dispatch({
      type: TO_SHOW_FORM_VALIDATION_ERR,
      payload: {
        type: PAYER_COUNTRY,
        toShowErr: false,
      },
    });

    // Remove any mandatory field warning from payer state select field
    dispatch({
      type: TO_SHOW_FORM_VALIDATION_ERR,
      payload: {
        type: PAYER_STATE,
        toShowErr: false,
      },
    });
  };

  // handle input change
  const handleChange = (e: any) => {
    const { value, id } = e.target;
    switch (id) {
      case "contact-name":
        if (value.length <= 100) {
          setGlobalFormData({
            ...globalFormData,
            payer_contact_name: value,
            payer_is_new_or_updated: true,
          });
        }
        break;

      case "email":
        if (value.length <= 100) {
          setGlobalFormData({
            ...globalFormData,
            payer_email: value,
            payer_is_new_or_updated: true,
          });
        }
        break;

      case "phone":
        // Remove any error indicators
        dispatch({
          type: TO_SHOW_FORM_VALIDATION_ERR,
          payload: {
            type: PAYER_PHONE_NUMBER,
            toShowErr: false,
          },
        });

        // Enable the extn field
        setIsDisabled(false);

        // Format the phone number display
        const formattedNum = formatPhonenum(value);
        formattedNum
          ? dispatch({
              type: SET_PHONE_DISPLAY,
              phoneType: PAYER_PHONE,
              payload: formattedNum,
            })
          : dispatch({
              type: SET_PHONE_DISPLAY,
              phoneType: PAYER_PHONE,
              payload: "",
            });

        // Update the global form data
        if (value.length <= 14) {
          const updatedVal = value
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(" ", "")
            .replaceAll("-", "");
          setGlobalFormData({
            ...globalFormData,
            payer_phone_number: updatedVal,
          });
        }
        // Disable the extn field
        if (value.length <= 0) {
          // Remove any existing extn data and update the global phone data
          setGlobalFormData({
            ...globalFormData,
            payer_phone_number: "",
            payer_phone_number_extn: "",
            payer_is_new_or_updated: true,
          });
          // Disable the extn field
          setIsDisabled(true);
        }
        break;

      case "extn":
        if (value.length <= 5) {
          setGlobalFormData({
            ...globalFormData,
            payer_phone_number_extn: value ? value : "",
            payer_is_new_or_updated: true,
          });
        }
        break;

      case "business-termination":
        setGlobalFormData({
          ...globalFormData,
          payer_is_terminating_this_year: !globalFormData.payer_is_terminating_this_year,
          payer_is_new_or_updated: true,
        });
        break;

      default:
        break;
    }
  };
  return (
    <div
      style={{
        border: pageType === PAGE_UPLOAD ? "none" : `1px solid ${style.eFormBorderColor}`,
        padding: pageType !== PAGE_UPLOAD ? "1rem" : "0.5rem 1rem",
      }}
    >
      <div className="required-details">
        <h4>
          {pageType !== PAGE_UPLOAD
            ? formsWithPayerAsFiler.includes(formType)
              ? payerDetailsFor1099S.requiredDetails
              : payerDetails.requiredDetails
            : uploadPageDisplayTexts.selectPayer.heading}
        </h4>
      </div>
      <div className="payer-name-input-wrapper">
        <label htmlFor="payer-name">
          {pageType !== PAGE_UPLOAD
            ? formsWithPayerAsFiler.includes(formType)
              ? payerDetailsFor1099S.payerNamePlaceholder
              : payerDetails.payerNamePlaceholder
            : uploadPageDisplayTexts.selectPayer.payerNamelabel}{" "}
          <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
        </label>
        <CustomSearch
          searchList={payerNames}
          value={globalFormData.payer_name}
          updateGlobalData={updateGlobalFormData}
          handleAPICall={getPayerDetails}
          ddType="payer_names"
          isCorrection={isCorrection}
        />
        {showValidationError.onPayerName && (
          <Indicator
            type={PAYER_NAME}
            message={
              !formsWithPayerAsFiler.includes(formType)
                ? formFieldErrMsg.nec1099.payer.emptyName
                : formFieldErrMsg.s1099.payer.emptyName
            }
          />
        )}
      </div>

      <PayerTin
        globalFormData={globalFormData}
        setGlobalFormData={setGlobalFormData}
        formType={formType}
        isCorrection={isCorrection}
      />

      <Address
        globalFormData={globalFormData}
        setGlobalFormData={setGlobalFormData}
        type="payer"
        isCorrection={isCorrection}
      />

      <div className="personal-info-wrapper">
        <div className="contact-name-wrapper">
          <label htmlFor="contact-name">{payerDetails.contactNamePlaceholder}</label>
          <input
            className="payer-details-input"
            id="contact-name"
            value={globalFormData.payer_contact_name}
            onChange={handleChange}
            autoComplete="off"
            disabled={isCorrection}
          />
        </div>
        <div className="misc-detail-container">
          <div className="email-wrapper">
            <label htmlFor="email">{payerDetails.emailPlaceholder}</label>
            <input
              className="payer-details-input"
              id="email"
              type="email"
              value={globalFormData.payer_email}
              onChange={handleChange}
              autoComplete="off"
              disabled={isCorrection}
            />
          </div>
          <div className="phone-wrapper">
            <label htmlFor="phone">{payerDetails.phonePlaceholder}</label>
            <input
              className="payer-details-input"
              type="text"
              id="phone"
              value={phoneDisplay.payerPhone ?? ""}
              onChange={handleChange}
              autoComplete="off"
              disabled={isCorrection}
            />
            {showValidationError.onPayerPhone && (
              <Indicator
                type={PAYER_PHONE_NUMBER}
                message={formFieldErrMsg.s1099.invalidPhoneNum}
              />
            )}
          </div>
          <div className="extn-wrapper">
            <label htmlFor="extn">{payerDetails.extnPlaceholder}</label>
            <input
              className="payer-details-input"
              type="number"
              id="extn"
              value={
                !globalFormData.payer_phone_number_extn
                  ? ""
                  : globalFormData.payer_phone_number_extn
              }
              onChange={handleChange}
              autoComplete="off"
              disabled={isDisabled}
            />
          </div>
        </div>
      </div>

      <div style={{ margin: "0.5rem 0 0" }} className="business-termination-confirm-wrapper">
        <div className="business-termination-confirm">
          <input
            type="checkbox"
            id="business-termination"
            onChange={handleChange}
            checked={globalFormData.payer_is_terminating_this_year}
            autoComplete="off"
            disabled={isCorrection}
          />
          <label htmlFor="business-termination">
            {payerDetails.businessTerminationConfirmText} - {calCurrentTaxYear()}
          </label>
        </div>
      </div>
    </div>
  );
}
