import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import Navbar from "../components/navbar";
import Handler from "../lib/Handlers/main";
import { AccountDetailsResponse, StaffAccountInfo, UserAccountInfo } from "../../interfaces/main";
import { useSelector } from "react-redux";
import loader from "../components/decorations/Loader";
import AccountSwitchContainer from "../components/AccountDetails/AccountSwitchingRelated/AccountSwitchContainer";
import { StaffAccountsContext } from "../App";

export default function AccountSwitch() {
  const [userDetails, setUserDetails] = useState<UserAccountInfo | null>(null);
  const [staffDetails, setStaffDetails] = useState<StaffAccountInfo[]>([]);

  const startLoader = useSelector((state: any) => state.startLoader);
  const style = useSelector((state: any) => state.themeChange);

  const staffAccountsCtx = useContext(StaffAccountsContext);

  if (!staffAccountsCtx) throw new Error("StaffAccountsCtx is null!");

  const { setTotalStaffAccounts } = staffAccountsCtx;

  // Get account related details
  const getAccountRelatedDetails = async () => {
    try {
      const accountDetailsRes: AccountDetailsResponse = await Handler.handleGettingAccountDetails();
      setUserDetails(accountDetailsRes.userDetails);
      setStaffDetails(accountDetailsRes.staffAccounts);
    } catch (err) {
      // Error will be handled in the handler itself
    }
  };
  // Update the account details state
  useEffect(() => {
    getAccountRelatedDetails();

    return () => {
      setUserDetails(null);
      setStaffDetails([]);
    };
  }, []);

  // Clear the totalStaffAccounts value whenever landing on the account switch page
  useLayoutEffect(() => {
    setTotalStaffAccounts(0);
  }, [setTotalStaffAccounts]);

  return !startLoader ? (
    <main className="page">
      <Navbar />
      <div className="page-main-body pt-24">
        <AccountSwitchContainer userDetails={userDetails} staffDetails={staffDetails} />
      </div>
    </main>
  ) : (
    loader.start(style)
  );
}
