import React, { useEffect } from "react";
import "../styles/Pages/Settings.css";
import Navbar from "../components/navbar";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  ACCOUNT,
  EMAIL_SETTING,
  NAME_SETTING,
  PASSWORD_SETTING,
  PHONE_SETTING,
  PROFILE,
} from "../lib/constants";
import { useState } from "react";
import { useHover } from "../lib/customHooks";
import ChangeEmail from "../components/ProfileSettings/ChangeEmail/ChangeEmail";
import ChangePassword from "../components/ProfileSettings/ChangePassword/ChangePassword";
import ChangePhonenumber from "../components/ProfileSettings/ChangePhonenumber/ChangePhonenumber";
import ChangeFullname from "../components/ProfileSettings/ChangeFullname/ChangeFullname";
import { ga4 } from "../App";
import ga4Const from "../lib/json/AnalyticsRelated/analyticsConst.json";
import isUserStaff from "../lib/utils/isUserStaff";

export default function Settings() {
  const style = useSelector((state: any) => state.themeChange);
  const { settingsType } = useParams();
  const [isSelected, setIsSelected] = useState({
    email: true, // Selected by default
    name: false,
    phone: false,
    password: false,
  });
  const [isHovered, handleHoverIn, handleHoverOut] = useHover();

  // Send analytics report when user navigates in or out if the profile settings page
  useEffect(() => {
    ga4.sendEvent({
      category: ga4Const.event.category.navigation,
      action: `${ga4Const.event.action.navigateIn} to Profile settings page`,
    });
    return () => {
      ga4.sendEvent({
        category: ga4Const.event.category.navigation,
        action: `${ga4Const.event.action.navigateOut} from Profile settings page`,
      });
    };
  }, []);

  const handleClick = (e: any) => {
    const setting = e.target.id ? e.target.id : e.target.parentElement.id;
    switch (setting) {
      case EMAIL_SETTING:
        setIsSelected({
          email: true,
          name: false,
          phone: false,
          password: false,
        });
        break;

      case NAME_SETTING:
        setIsSelected({
          email: false,
          name: true,
          phone: false,
          password: false,
        });
        break;

      case PHONE_SETTING:
        setIsSelected({
          email: false,
          name: false,
          phone: true,
          password: false,
        });
        break;

      case PASSWORD_SETTING:
        setIsSelected({
          email: false,
          name: false,
          phone: false,
          password: true,
        });
        break;

      default:
        break;
    }
  };

  return (
    <main style={{ position: "relative" }}>
      <Navbar />
      <div className="page-body account-settings-body">
        {!isUserStaff() ? (
          <>
            <section
              className="settings-list"
              style={{ borderRight: `1px solid ${style.secondaryBorderColor}` }}
            >
              {settingsType === PROFILE && (
                <div className="profile-settings-container">
                  <div
                    className="settings-name"
                    style={{ borderBottom: `1px solid ${style.secondaryBorderColor}` }}
                  >
                    <h2>
                      <i className="fa-solid fa-user" />
                      Profile Settings
                    </h2>
                  </div>
                  <ul className="profile-settings-list">
                    <li
                      id={EMAIL_SETTING}
                      onMouseOver={() => {
                        handleHoverIn(EMAIL_SETTING);
                      }}
                      onMouseLeave={() => {
                        handleHoverOut(EMAIL_SETTING);
                      }}
                      onClick={handleClick}
                      style={{
                        background: isSelected.email
                          ? style.isSelectedBg
                          : isHovered[EMAIL_SETTING] === true
                          ? style.ddOptionHover
                          : "",
                        color: isSelected.email ? style.isSelectedColor : "",
                      }}
                    >
                      <p onClick={handleClick}>
                        <i className="fa-solid fa-envelope" />
                        Email
                      </p>
                    </li>
                    <li
                      id={NAME_SETTING}
                      onMouseOver={() => {
                        handleHoverIn(NAME_SETTING);
                      }}
                      onMouseLeave={() => {
                        handleHoverOut(NAME_SETTING);
                      }}
                      onClick={handleClick}
                      style={{
                        background: isSelected.name
                          ? style.isSelectedBg
                          : isHovered[NAME_SETTING] === true
                          ? style.ddOptionHover
                          : "",
                        color: isSelected.name ? style.isSelectedColor : "",
                      }}
                    >
                      <p onClick={handleClick}>
                        <i className="fa-solid fa-file-signature" />
                        Name
                      </p>
                    </li>
                    <li
                      id={PHONE_SETTING}
                      onMouseOver={() => {
                        handleHoverIn(PHONE_SETTING);
                      }}
                      onMouseLeave={() => {
                        handleHoverOut(PHONE_SETTING);
                      }}
                      onClick={handleClick}
                      style={{
                        background: isSelected.phone
                          ? style.isSelectedBg
                          : isHovered[PHONE_SETTING] === true
                          ? style.ddOptionHover
                          : "",
                        color: isSelected.phone ? style.isSelectedColor : "",
                      }}
                    >
                      <p onClick={handleClick}>
                        <i className="fa-solid fa-phone" />
                        Phone Number
                      </p>
                    </li>
                    <li
                      id={PASSWORD_SETTING}
                      onMouseOver={() => {
                        handleHoverIn(PASSWORD_SETTING);
                      }}
                      onMouseLeave={() => {
                        handleHoverOut(PASSWORD_SETTING);
                      }}
                      onClick={handleClick}
                      style={{
                        background: isSelected.password
                          ? style.isSelectedBg
                          : isHovered[PASSWORD_SETTING] === true
                          ? style.ddOptionHover
                          : "",
                        color: isSelected.password ? style.isSelectedColor : "",
                      }}
                    >
                      <p onClick={handleClick}>
                        <i className="fa-solid fa-key" />
                        Password
                      </p>
                    </li>
                  </ul>
                </div>
              )}
              {settingsType === ACCOUNT && (
                <div className="account-settings-container">
                  <div className="settings-name">
                    <h2>Account Settings</h2>
                  </div>
                </div>
              )}
            </section>
            <section className="setting">
              {isSelected.email && <ChangeEmail />}
              {isSelected.password && <ChangePassword />}
              {isSelected.phone && <ChangePhonenumber />}
              {isSelected.name && <ChangeFullname />}
            </section>
          </>
        ) : (
          <section className="pt-24 mt-20 w-full flex justify-center text-3xl font-bold opacity-50">
            <em>Members don't have permission to view this page.</em>
          </section>
        )}
      </div>
    </main>
  );
}
