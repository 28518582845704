import React from "react";
import { useSelector } from "react-redux";
import DownloadOtherTemplates from "./DownloadOtherTemplates";

interface Props extends React.HTMLAttributes<HTMLElement> {
  instructions: string[];
  templateFor: string;
  noCSV?: boolean;
  downloadLink?: string;
}

export default function DownloadTemplateInstr(props: Props) {
  const style = useSelector((state: any) => state.themeChange);
  return (
    <div
      className="template-downloader-main"
      style={{ border: `1px solid ${style.tableHeadingPrimaryColor}` }}
    >
      <div className="instruction">
        <div className="instruction-heading" style={{ background: style.tableHeadingPrimaryColor }}>
          <h2>Instructions</h2>
        </div>
        <ul>
          {props.instructions.map((point, i) => (
            <li key={i}>{point}</li>
          ))}
        </ul>
      </div>

      {/* Download buttons */}
      <DownloadOtherTemplates
        templateFor={props.templateFor}
        noCSV={props.noCSV}
        downloadLink={props.downloadLink}
      />
    </div>
  );
}
