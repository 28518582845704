import React from "react";
import { PAYMENT } from "../../../../../lib/constants";
import StateAmount from "../StateAmount/StateAmount";

export default function StateIncome({ heading, incAmt1, incAmt2, state1, state2 }: any) {
  return (
    <div className="rf-amt-child" style={{ borderRight: `1px solid black` }}>
      <div className="rf-si-heading">{heading}</div>
      <StateAmount amt1={incAmt1} amt2={incAmt2} state1={state1} state2={state2} type={PAYMENT} />
    </div>
  );
}
