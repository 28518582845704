import React from "react";
import "../styles/LoginRegister.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logger from "../logger";

export default function LoginRegister() {
  const style = useSelector((state: any) => state.themeChange);
  logger.log(style);
  const [isHovered, setIsHovered] = useState({
    login: false,
    register: false,
  });

  const fillbutton = () => {
    setIsHovered({
      ...isHovered,
      login: true,
    });
  };
  const unfillBtn = () => {
    setIsHovered({
      ...isHovered,
      login: false,
    });
  };
  return (
    <div className="cred-container">
      <Link
        onMouseOver={fillbutton}
        onMouseLeave={unfillBtn}
        to="/signin"
        className={`button-container border-2 border-taxeve-primary-violet ${
          isHovered.login ? "text-white" : "text-taxeve-primary-violet"
        }`}
      >
        Sign in
        <div
          className={`button-filler ${
            isHovered.login ? "h-[100%]" : "h-0"
          } bg-taxeve-primary-violet`}
        />
      </Link>
      <Link
        to="/register"
        className={`button-container bg-taxeve-primary-violet text-white border-2 border-taxeve-primary-violet ${
          isHovered.register && "shadow-md shadow-taxeve-primary-violet"
        }`}
        onMouseEnter={() => {
          setIsHovered({ ...isHovered, register: true });
        }}
        onMouseLeave={() => {
          setIsHovered({ ...isHovered, register: false });
        }}
      >
        Sign up
      </Link>
    </div>
  );
}
