export const themes = [
  {
    theme: "default",
    fontColor: "#5E3ABB",
    border: "#5E3ABB",
    btnBg: "#5E3ABB",
    btnSecondaryBg: "#F24C3D",
    siteBg: "white",
    btnFillFontColor: "white",
    popupSuccessBg: "#B3FFAE",
    popupErrorBg: "red",
    passwordSetupInstructionBg: "#FFD89C",
    modalTopPanelBg: "#F2BE22",
    modalBottomPanelBg: "#5E3ABB",
    modalTopPanelFontColor: "black",
    eFormBorderColor: "black",
    secondaryBorderColor: "rgba(122, 122, 122, 0.5)",
    loaderColor: "#5E3ABB",
    tinBgColor: "#F5F5F5",
    tinSelectedBgColor: "#D2E9E9",
    boxShadowColor: "rgba(0,0,0,0.5)",

    // Dropdown
    ddShadow: "rgba(0, 0, 0, 0.4)",
    ddOptionHover: "#b262d163",
    isSelectedColor: "white",
    isSelectedBg: "#5E3ABB",
    ddBg: "white",
    ddFontColor: "black",

    // Account instructions popup
    hoverablePopupBg: "#CBB279",
    hoverableTextColor: "#1D5D9B",

    // Global modal
    gModalTopPanelBg: "#FF8551",

    // User initial
    userProfileImageBg: "rgba(122, 122, 122, 0.5)",

    // Cart notification
    notificationBg: "#F29727",
    notificationColor: "white",

    // Review pending
    rpBtnPrimaryColor: "#CEE6F3",
    // rpBtnBorderColor: "#AED8CC",
    rpBtnSecondaryColor: "#A1CCD1",
    rpBtnFontColor: "#5E3ABB",

    // Edit Next buttons
    nextBtnBg: "orange",
    editBtnBg: "blue",

    // Hoverable name
    hoverableNameBg: "#F9D949",
    hoverableNameColor: "black",

    // Bulk action container
    bulkActionContainerBg: "#EEEEEE",

    // Table / container heading background
    tableHeadingPrimaryColor: "#ADC4CE",

    // Cart page
    cartTableTopPanel: "#8E99A2",
    cartTableTopPanelFontColor: "white",

    // Active color
    active: "#362FD9",
    inactive: "black",
  },
];
