import React from "react";
import { FaDownload } from "react-icons/fa";
import { BsClipboardCheck, BsClipboardX } from "react-icons/bs";
import { ImBin2 } from "react-icons/im";
import { DELETE, DOWNLOAD_SINGLE, REVIEW, UNREVIEW } from "../../../../../../lib/constants";

export default function ActionContextMenu() {
  return (
    <ul className="absolute bottom-[-9rem] left-0 w-[100%] bg-white shadow-xl z-[5] rounded-xl overflow-hidden">
      <li id={REVIEW} className="px-4 py-2 hover:bg-blue-200 flex items-center gap-2">
        Review <BsClipboardCheck />
      </li>
      <li id={UNREVIEW} className="px-4 py-2 hover:bg-blue-200 flex items-center gap-2">
        Unreview <BsClipboardX />
      </li>
      <li id={DOWNLOAD_SINGLE} className="px-4 py-2 hover:bg-blue-200 flex items-center gap-2">
        Download <FaDownload />
      </li>
      <li id={DELETE} className="px-4 py-2 hover:bg-blue-200 flex items-center gap-2">
        Delete <ImBin2 />
      </li>
    </ul>
  );
}
