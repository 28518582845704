import {
  NOT_MANDATORY,
  PAYER_ADDRESS_1,
  PAYER_CITY,
  PAYER_COUNTRY,
  PAYER_NAME,
  PAYER_PHONE_NUMBER,
  PAYER_STATE,
  PAYER_TIN,
  PAYER_ZIPCODE,
  RECIPIENT_ADDRESS_1,
  RECIPIENT_CITY,
  RECIPIENT_COUNTRY,
  RECIPIENT_NAME,
  RECIPIENT_STATE,
  RECIPIENT_TIN,
  RECIPIENT_ZIPCODE,
  RECIPIENT_PHONE_NUMBER,
  PAGE_UPLOAD,
} from "../../constants";

export default function fieldsToValidate(globalFormData: any, pageType: any) {
  return pageType !== PAGE_UPLOAD
    ? [
        // Payer block
        {
          fieldName: PAYER_NAME,
          data: globalFormData.payer_name,
        },
        {
          fieldName: PAYER_TIN,
          data: globalFormData.payer_tin,
        },
        {
          fieldName: PAYER_ADDRESS_1,
          data: globalFormData.payer_address_line1,
        },
        {
          fieldName: PAYER_CITY,
          data: globalFormData.payer_city,
        },
        !globalFormData.payer_is_foreign_address
          ? {
              fieldName: PAYER_STATE,
              data: globalFormData.payer_state,
            }
          : {
              fieldName: PAYER_STATE,
              data: NOT_MANDATORY,
            },
        {
          fieldName: PAYER_ZIPCODE,
          data: globalFormData.payer_zipcode,
        },
        {
          fieldName: PAYER_COUNTRY,
          data: globalFormData.payer_country,
        },
        {
          fieldName: PAYER_PHONE_NUMBER,
          data: globalFormData.payer_phone_number,
        },

        // Recipient block
        {
          fieldName: RECIPIENT_NAME,
          data: globalFormData.recipient_name,
        },
        {
          fieldName: RECIPIENT_TIN,
          data: globalFormData.recipient_tin,
        },
        {
          fieldName: RECIPIENT_ADDRESS_1,
          data: globalFormData.recipient_address_line1,
        },
        {
          fieldName: RECIPIENT_CITY,
          data: globalFormData.recipient_city,
        },
        !globalFormData.recipient_is_foreign_address
          ? {
              fieldName: RECIPIENT_STATE,
              data: globalFormData.recipient_state,
            }
          : {
              fieldName: RECIPIENT_STATE,
              data: NOT_MANDATORY,
            },
        {
          fieldName: RECIPIENT_ZIPCODE,
          data: globalFormData.recipient_zipcode,
        },
        {
          fieldName: RECIPIENT_COUNTRY,
          data: globalFormData.recipient_country,
        },
        {
          fieldName: RECIPIENT_PHONE_NUMBER,
          data: globalFormData.recipient_phone_number,
        },
      ]
    : [
        // Payer block
        {
          fieldName: PAYER_NAME,
          data: globalFormData.payer_name,
        },
        {
          fieldName: PAYER_TIN,
          data: globalFormData.payer_tin,
        },
        {
          fieldName: PAYER_ADDRESS_1,
          data: globalFormData.payer_address_line1,
        },
        {
          fieldName: PAYER_CITY,
          data: globalFormData.payer_city,
        },
        !globalFormData.payer_is_foreign_address
          ? {
              fieldName: PAYER_STATE,
              data: globalFormData.payer_state,
            }
          : {
              fieldName: PAYER_STATE,
              data: NOT_MANDATORY,
            },
        {
          fieldName: PAYER_ZIPCODE,
          data: globalFormData.payer_zipcode,
        },
        {
          fieldName: PAYER_COUNTRY,
          data: globalFormData.payer_country,
        },
        {
          fieldName: PAYER_PHONE_NUMBER,
          data: globalFormData.payer_phone_number,
        },
      ];
}
