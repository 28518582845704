import React, { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GroupRecord, PayerUnderStaffOrGroup, StaffRecord } from "../../../../../interfaces/main";
import Handler from "../../../../lib/Handlers/main";
import devLogInstance from "../../../../lib/loggerConfig";
import { FaMinusSquare } from "react-icons/fa";
import formatTin from "../../../../lib/form/utils/formatTin";
import displayPayerUnassignmentModal from "../../../../lib/StateSetters/StaffAndGroupRelated/displayPayerUnassignmentModal";
import { removePayerIdFromArray } from "./AssignPayerModal";
import { getStaff } from "../../../BusinessSettingsRelated/ManageStaff";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { getGroup } from "../../../BusinessSettingsRelated/ManageGroup";

export default function UnassignPayerModal() {
  const payerUnassignmentModalProps: {
    isStaff: boolean;
    staffOrGroupId: string;
    assignedPayers: PayerUnderStaffOrGroup[];
    updateStaffRecords: React.Dispatch<React.SetStateAction<StaffRecord[]>> | null;
    updateGroupRecords: React.Dispatch<React.SetStateAction<GroupRecord[]>> | null;
    staffName?: string;
    groupName?: string;
  } = useSelector((state: any) => state.displayPayerUnassignmentModal);

  const [selectedPayers, setSelectedPayers] = useState<string[]>([]);
  devLogInstance.log({ selectedPayers });

  const dispatch = useDispatch();

  // close the modal
  const closeModal = () => {
    displayPayerUnassignmentModal(
      {
        toDisplay: false,
        staffOrGroupId: "",
        isStaff: false,
        assignedPayers: [],
        updateStaffRecords: null,
        updateGroupRecords: null,
      },
      dispatch
    );
  };

  // Select a single payer
  const handleSinglePayerSelection = (e: ChangeEvent<HTMLInputElement>) => {
    const { id: payerId } = e.currentTarget;
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedPayers([...selectedPayers, payerId]);
    } else {
      const filteredArray = removePayerIdFromArray(selectedPayers, payerId);
      setSelectedPayers(filteredArray);
    }
  };

  // Handle selecting all the payers
  const handleAllPayersSelection = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      const payerIds = payerUnassignmentModalProps.assignedPayers.map((payer) => payer._id);
      setSelectedPayers(payerIds);
    } else {
      deselectAllPayers();
    }
  };

  // Deselect all payers
  const deselectAllPayers = () => {
    setSelectedPayers([]);
  };

  // Handle click on assign button
  const handleClickOnUnassignButton = () => {
    Handler.handleUnassigningPayers(
      payerUnassignmentModalProps.staffOrGroupId,
      selectedPayers,
      payerUnassignmentModalProps.isStaff
    ).finally(() => {
      deselectAllPayers();

      // Close the modal
      closeModal();

      if (payerUnassignmentModalProps.isStaff) {
        // Refetch the staff data to get the latest staff records
        if (payerUnassignmentModalProps.updateStaffRecords)
          getStaff(payerUnassignmentModalProps.updateStaffRecords);
      } else {
        // Refetch the group data to get the latest group records
        if (payerUnassignmentModalProps.updateGroupRecords)
          getGroup(payerUnassignmentModalProps.updateGroupRecords);
      }
    });
  };

  return (
    <div className="form-modal shadow-lg">
      <div className="form-modal-header bg-taxeve-primary-violet text-white flex items-center justify-between">
        <h2>Unassign Business'</h2>
        <IoIosCloseCircleOutline className="text-3xl cursor-pointer" onClick={closeModal} />
      </div>
      <div className="form-modal-body bg-white">
        <section className="staff-name text-left p-4">
          <h2 className="">
            <span className="underline underline-offset-2">Unassigning from</span>:{" "}
            {payerUnassignmentModalProps.isStaff
              ? payerUnassignmentModalProps.staffName || <em>No staff name found</em>
              : payerUnassignmentModalProps.groupName || <em>No group name found</em>}
          </h2>
        </section>

        <section className="payers p-4 h-72 overflow-auto">
          {payerUnassignmentModalProps.assignedPayers.length > 0 ? (
            <table className="payers-container">
              <thead className="container-heading py-2 px-4 rounded-t-xl bg-taxeve-primary-violet-low">
                <tr>
                  <th className="relative">
                    {selectedPayers.length > 0 &&
                    payerUnassignmentModalProps.assignedPayers.length !== selectedPayers.length ? (
                      <FaMinusSquare
                        id="semi-checked-icon"
                        className="absolute left-[2.85rem] top-3 text-blue-600"
                        onClick={deselectAllPayers}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        onChange={handleAllPayersSelection}
                        checked={
                          payerUnassignmentModalProps.assignedPayers.length ===
                          selectedPayers.length
                        }
                      />
                    )}
                  </th>
                  <th className="text-center">Business name</th>
                  <th className="text-center">Business TIN</th>
                </tr>
              </thead>

              <tbody className="container-body py-2 px-4">
                {payerUnassignmentModalProps.assignedPayers.map((payer) => {
                  return (
                    <tr key={payer._id}>
                      <td className="py-2">
                        <input
                          type="checkbox"
                          id={payer._id}
                          onChange={handleSinglePayerSelection}
                          checked={selectedPayers.includes(payer._id)}
                        />
                      </td>
                      <td className="text-center">{payer.payer_name}</td>
                      <td className="text-center">
                        <span className="font-bold">{payer.payer_tin_type}:</span>{" "}
                        {formatTin(payer.payer_tin, payer.payer_tin_type)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="h-[15rem] flex items-center justify-center text-2xl font-bold opacity-40">
              <em>There are no payers assigned currently.</em>
            </div>
          )}
        </section>

        <section className="assignment p-4 text-right">
          <button
            id="unassign-payers-btn"
            className={`py-2 px-6 bg-taxeve-primary-violet ${
              selectedPayers.length !== 0 ? "hover:opacity-80" : "opacity-80"
            }
              ${selectedPayers.length === 0 && "cursor-not-allowed"} text-white rounded-md`}
            onClick={handleClickOnUnassignButton}
            disabled={selectedPayers.length === 0}
          >
            Unassign
          </button>
        </section>
      </div>
    </div>
  );
}
