import getRecDetails from "../../backendData/getRecData";
import retrieveToken from "../../authRequests/retrieveTokens";
import getRefreshToken from "../../authRequests/getRefreshToken";
import saveTokens from "../../authRequests/saveTokens";
import logoutUser from "../../authRequests/logoutUser";
import logger from "../../../logger";

export default async function handleGetRecDetails(recId: any, handleLogout: any) {
  try {
    const data = await getRecDetails(recId);
    return data;
  } catch (err: any) {
    logger.error(err);
    if (err.response && err.response.status === 401) {
      logger.error(err.response.data.Error);
      // Update the access and refresh token
      const oldRefToken = retrieveToken("refresh_token");
      if (oldRefToken) {
        // Get the new refresh and access tokens
        try {
          const res = await getRefreshToken(oldRefToken);
          logger.log("New Access and Refresh token");
          logger.log(res);

          // Save the new tokens
          await saveTokens(res.accessToken, res.refreshToken);
          logger.log("New Tokens saved");

          // Try getting the recipient details using new tokens
          const data = await getRecDetails(recId);
          return data;
        } catch (err) {
          logger.error(err);
          // If any error is encountered in the try block, logout the user
          logoutUser(handleLogout);
        }
      } else {
        // Logout the user if not initial ref token is found in local storage
        logger.log("No refresh token found in localstorage");
        logoutUser(handleLogout);
      }
    } else {
      // Logout the user if the error response is not 401
      logger.log("Error is not 401");
      logoutUser(handleLogout);
    }
  }
}
