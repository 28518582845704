import { axiosInstance } from "../axiosConfig";

export default async function changePhonenum(hashedPass: string, newPhonenum: string) {
  try {
    const { data } = await axiosInstance.post("/change_phone_number", {
      password: hashedPass,
      new_phone_number: newPhonenum,
    });
    return data;
  } catch (err) {
    throw err;
  }
}
