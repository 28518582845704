import {
  PAYER_PHONE,
  PAYER_ZIP,
  REC_PHONE,
  REC_ZIP,
  SET_PAYER_TIN_DISPLAY,
  SET_RECIPIENT_TIN_DISPLAY,
  SET_PHONE_DISPLAY,
  SET_ZIPCODE,
  SET_STATE_AMOUNT,
} from "../../../redux/actions/action.types";

export default function handleInputDisplayClearance(handleClearance: any) {
  // Clear payer phone display
  handleClearance({
    type: SET_PHONE_DISPLAY,
    phoneType: PAYER_PHONE,
    payload: "",
  });
  // Clear recipient phone display
  handleClearance({
    type: SET_PHONE_DISPLAY,
    phoneType: REC_PHONE,
    payload: "",
  });
  // Clear payer zipcode display
  handleClearance({
    type: SET_ZIPCODE,
    zipType: PAYER_ZIP,
    payload: "",
  });
  // Clear recipient zipcode display
  handleClearance({
    type: SET_ZIPCODE,
    zipType: REC_ZIP,
    payload: "",
  });
  // Clear payer tin display
  handleClearance({
    type: SET_PAYER_TIN_DISPLAY,
    payload: "",
  });
  // Clear recipient tin display
  handleClearance({
    type: SET_RECIPIENT_TIN_DISPLAY,
    payload: "",
  });
  // Clear the state amount
  handleClearance({
    type: SET_STATE_AMOUNT,
    payload: {
      stateTax1: "",
      stateIncome1: "",

      stateTax2: "",
      stateIncome2: "",
    },
  });
}
