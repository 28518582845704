import axios from "axios";
import { prodLogInstance } from "../loggerConfig";

export default class Convert {
  static async fromDataURLToFile(dataUrl: string, filename: string, mimetype: string) {
    try {
      const { data } = await axios.get(dataUrl, { responseType: "blob" });
      const dataBlob = data;

      // Conversion
      const file = new File([dataBlob], filename, { type: mimetype });
      return file;
    } catch (err) {
      prodLogInstance.error(err);
      throw err;
    }
  }
}
