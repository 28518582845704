import React from "react";
import "../styles/Pages/CartPage.css";
import Navbar from "../components/navbar";
import Collapsible from "../components/decorations/Collapsible/Collapsible";
import { useEffect, useMemo, useState } from "react";
import Handler from "../lib/Handlers/main";
import devLogInstance from "../lib/loggerConfig";
import { useDispatch, useSelector } from "react-redux";
import setCartData from "../lib/StateSetters/setCartData";
import Price from "../lib/Price/main";
import ClTable from "../components/CartPage/ClTable";
import loader from "../components/decorations/Loader";
import LoaderState from "../lib/Loader/main";
import { useInactive, useYear } from "../lib/customHooks";
import OrderDetails from "../components/CartPage/OrderDetails";
import EmailRequiredModal from "../components/decorations/EmailRequiredModal/EmailRequiredModal";
import BulkUpdate from "../components/CartPage/BulkUpdate/BulkUpdate";
import {
  removeAllRecForPayer,
  selectAllRecForPayer,
} from "../lib/StateSetters/allRecipientsForPayer";
import TinMaskContainer from "../components/decorations/TinMaskContainer/TinMaskContainer";
import maskTin from "../lib/StateSetters/maskTin";
import { useLocation } from "react-router-dom";
import handlePopup from "../lib/popup/handlePopup";
import { ERROR_POPUP } from "../lib/constants";
import { BulkAction } from "../lib/utils/BulkAction";
import displayConfirmationModal from "../lib/StateSetters/displayConfirmationModal";
import { BULK_REVIEW_FORM } from "../lib/UserActions/action";
import { ga4 } from "../App";
import ga4Const from "../lib/json/AnalyticsRelated/analyticsConst.json";
import YearSelector from "../components/YearSelector";
// import AdBanner from "../components/decorations/AdBanner";
// import { irsFilingStart } from "../lib/languagePacks/en-us";

export default function CartPage({ showFeedbackWindow: feedbackWindowObj }: any) {
  const style = useSelector((state: any) => state.themeChange);
  const startLoader = useSelector((state: any) => state.startLoader);
  const cartData = useSelector((state: any) => state.cartData);
  const [draftData, setDraftData] = useState<any[]>([]);
  const totalCartValues = useSelector((state: any) => state.totalCartValues);
  const cartPageSelectedRec = useSelector((state: any) => state.cartPageSelectedRec);
  const dispatch = useDispatch();
  const [selectAllPayer, setSelectAllPayer] = useState<boolean | null>(null);
  const [toDisplaySelectAllPayer, setToDisplaySelectAllPayer] = useState(false);
  const [totalSelectedRec, setTotalSelectedRec] = useState([]);
  const [amounts, setAmounts] = useState({
    totalAmount: 0,
    discount: 0,
    totalAmtPayable: 0,
  });
  const [formsAlreadyFiled, setFormsAlreadyFiled] = useState(0);
  const toCallEmailRequiredModal = useSelector((state: any) => state.toCallEmailRequiredModal);
  const totalRecsWithoutStatefiling = useSelector(
    (state: any) => state.totalRecsWithoutStateFiling
  );

  const location = useLocation();
  const paymentStatus = new URLSearchParams(location.search).get("q");

  const handleInactivity = useInactive();

  const { year, handleYearChange } = useYear();

  devLogInstance.log(feedbackWindowObj);

  devLogInstance.log(`Total cart values:`);
  devLogInstance.log(totalCartValues);

  devLogInstance.log("CART DATA: ");
  devLogInstance.log(cartData);

  devLogInstance.log("TOTAL RECIPIENTS WITHOUT STATE FILING FOR THE CURRENT PAYER");
  devLogInstance.log(totalRecsWithoutStatefiling);

  // Collapsible header style
  const headerStyle = {
    background: style.cartTableTopPanel,
    color: style.cartTableTopPanelFontColor,
  };

  // Collapsible Main conttainer style
  const clMainStyle = {
    boxShadow: `3px 3px 8px ${style.boxShadowColor}`,
  };

  // Custom GA4 analytics checkout event
  useEffect(() => {
    ga4.sendEvent({
      category: ga4Const.event.category["e-filing"],
      action: ga4Const.event.action.checkout,
    });
  }, []);

  useMemo(() => {
    if (paymentStatus === "failed") {
      handlePopup("Payment failed. Please retry again!", ERROR_POPUP, dispatch);
    }
  }, [paymentStatus, dispatch]);

  useMemo(() => {
    if (totalSelectedRec.length === 0) {
      setToDisplaySelectAllPayer(true);
    }
  }, [totalSelectedRec]);

  useEffect(() => {
    if (selectAllPayer) selectAllRecForPayer(dispatch, "ALL_PAYERS");
    else if (selectAllPayer === false) removeAllRecForPayer(dispatch, "ALL_PAYERS");
    return () => {
      setSelectAllPayer(null);
    };
  }, [selectAllPayer, dispatch]);

  // Get the latest pricing and discount related data
  useEffect(() => {
    Handler.handleGettingLatestPrices().then((res: any) => {
      devLogInstance.log(res);
      const [fedprice, stateprice, pmprice, oaprice, forms] = res;
      // Initialize the Price
      Price.federalPrice = fedprice;
      Price.stateFileprice = stateprice;
      Price.onlineAccessPrice = oaprice;
      Price.postalMailingPrice = pmprice;
      Price.forms = forms;

      // Initialize the discount related values
      // Price.discountType = discountType;
      // Price.discountAmountArray = discountAmounts;
      // Price.discountPercentArray = discountPercents;
      // Price.minItemsArray = minForms;
    });
  }, []);

  // Get and set the cart data
  useEffect(() => {
    // Start the loader
    LoaderState.initialize();

    Handler.handleGetCartData(year.value).then((data) => {
      devLogInstance.log(data);
      devLogInstance.log("SAVING THE CART DATA");
      data && setCartData(dispatch, data.cart_data);
      data && setDraftData(data.draft_data);

      data && data.forms_filed && setFormsAlreadyFiled(data.forms_filed);

      // Assign the initial form filings value to Price class
      Price.formsAlreadyFiled = data.forms_filed ? data.forms_filed : 0;

      // Stop the loader
      LoaderState.deinitialize();
    });
    return () => {
      setCartData(dispatch, []);
      setDraftData([]);
    };
  }, [dispatch, year]);

  // Calculate the total price
  useEffect(() => {
    const totalOnlineAcess = totalCartValues.totalOA;
    const totalPostalMailing = totalCartValues.totalPM;
    const totalStateFiling = totalCartValues.totalSF;
    const totalRecSelected = totalSelectedRec.length;

    // devLogInstance.log(totalSelectedRec);
    const discount = Price.getDiscount(totalRecSelected);

    const [totalPrice, discountedPrice] = Price.calculateTotalPrice(
      totalOnlineAcess,
      totalPostalMailing,
      totalStateFiling,
      totalRecSelected,
      discount
    );
    // Set the discount amt, total and the discounted price as state
    setAmounts({
      totalAmount: totalPrice,
      discount: discount ? discount : 0,
      totalAmtPayable: discountedPrice,
    });
    devLogInstance.log(`Total price ---> ${totalPrice}`);
    devLogInstance.log(`Discounted price ---> ${discountedPrice}`);
  }, [totalCartValues, cartPageSelectedRec, totalSelectedRec]);

  // Revert to default mask tin state when this page component is destroyed
  useEffect(() => {
    return () => {
      maskTin(true, dispatch);
    };
  }, [dispatch]);

  // Handle marking all form as reviewed
  const handleAllFormReview = () => {
    // Get the cart ids
    const cartIds: any[] = [];
    draftData.forEach((data) => {
      data.recipient_data.forEach((recData: any) => {
        cartIds.push({ _id: recData.cartId });
      });
    });

    // Set the cart ids for bulk action
    BulkAction.cartIds = cartIds;
    BulkAction.stateSetter = dispatch;

    // Display the confirmatiomn modal
    displayConfirmationModal(dispatch, BULK_REVIEW_FORM, true);
  };

  return !startLoader ? (
    <main
      style={{ position: "relative" }}
      onMouseMove={handleInactivity}
      onKeyDown={handleInactivity}
    >
      {toCallEmailRequiredModal.toCall && (
        <EmailRequiredModal
          dataArray={toCallEmailRequiredModal.dataArray}
          payerId={toCallEmailRequiredModal.payerId}
          cartIds={toCallEmailRequiredModal.cartIds}
        />
      )}
      <Navbar />

      {/* Remove when form filing starts */}
      {/*<AdBanner adtext={irsFilingStart} />*/}

      {cartData.length > 0 || draftData.length > 0 ? (
        <div
          style={{
            paddingTop: "6rem",
            minHeight: "100vh",
            display: "flex",
            flexWrap: "wrap-reverse",
          }}
        >
          <div className="cl-wrapper">
            <YearSelector year={year} handleYearChange={handleYearChange} className="w-[30%]" />
            {cartData.length > 0 && (
              <>
                <div className="cart-page-heading">
                  <h2>Cart</h2>
                </div>
                <div className="cart-page-subheader">
                  {toDisplaySelectAllPayer ? (
                    <div
                      className="bulk-payer-selection-text"
                      onClick={() => {
                        setSelectAllPayer(true);
                      }}
                      style={{
                        color: style.fontColor,
                      }}
                    >
                      Select all payer
                    </div>
                  ) : (
                    <div
                      className="bulk-payer-selection-text"
                      onClick={() => {
                        setSelectAllPayer(false);
                      }}
                      style={{
                        color: style.fontColor,
                      }}
                    >
                      Deselect all payer
                    </div>
                  )}
                  <BulkUpdate totalSelectedCartIds={totalSelectedRec} />
                </div>
              </>
            )}
            {cartData.map((obj: any, i: number) => {
              return (
                <Collapsible
                  key={i}
                  header={obj.payer_data.payer_name}
                  headerStyle={headerStyle}
                  clMainStyle={clMainStyle}
                  setToDisplaySelectAllPayer={setToDisplaySelectAllPayer}
                  setTotalSelectedRec={setTotalSelectedRec}
                  recipientDataArray={obj.recipient_data}
                  payerId={i}
                  isDraftData={false}
                  body={
                    <ClTable
                      recipientDataArray={obj.recipient_data}
                      payerId={i}
                      isDraftData={false}
                    />
                  }
                />
              );
            })}
            {draftData.length > 0 && (
              <>
                <div className="flex flex-col gap-4 mt-10">
                  <h2 className="underline underline-offset-2">Unreviewed Forms</h2>
                  <div
                    className="text-base underline underline-offset-4 text-taxeve-primary-blue w-[fit-content] cursor-pointer"
                    onClick={handleAllFormReview}
                  >
                    Mark all forms as reviewed for filing
                  </div>
                  {draftData.map((obj: any, i: number) => {
                    return (
                      <Collapsible
                        key={i}
                        header={obj.payer_data.payer_name}
                        headerStyle={headerStyle}
                        clMainStyle={clMainStyle}
                        recipientDataArray={obj.recipient_data}
                        payerId={i + cartData.length}
                        isDraftData={true}
                        body={
                          <ClTable
                            recipientDataArray={obj.recipient_data}
                            payerId={i + cartData.length}
                            isDraftData={true}
                          />
                        }
                      />
                    );
                  })}
                </div>
              </>
            )}
            <div className="tin-mask-container-wrapper">
              <TinMaskContainer />
            </div>
          </div>
          {totalSelectedRec.length !== 0 && (
            <OrderDetails
              cartIds={totalSelectedRec}
              totalForms={totalSelectedRec.length}
              amounts={amounts}
              formsAlreadyFiled={formsAlreadyFiled}
            />
          )}
        </div>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // opacity: "0.4",
          }}
          className="relative"
        >
          <YearSelector
            year={year}
            handleYearChange={handleYearChange}
            className="w-[20%] absolute left-8 top-32"
          />
          <h2 className="opacity-40">There are no forms added to the cart yet</h2>
        </div>
      )}
    </main>
  ) : (
    loader.start(style)
  );
}
