import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import devLogInstance from "../../lib/loggerConfig";
import handleAmountFormat from "../../lib/form/utils/handleAmountFormat";
import formSubmissionHelper from "../../lib/FormSubmission/formSubmissionHelper";
import {
  AMOUNT_1,
  AMOUNT_2,
  AMOUNT_3,
  AMOUNT_5,
  AMOUNT_6,
  AMOUNT_7,
  AMOUNT_8,
  AMOUNT_9,
  AMOUNT_A,
  AMOUNT_B,
  AMOUNT_C,
  AMOUNT_D,
  AMOUNT_E,
  AMOUNT_F,
  AMOUNT_G,
  AMOUNT_H,
  AMOUNT_J,
  CORRECTION_INITIAL,
  DEFAULT_NON_FOREIGN_COUNTRY,
  DIV_1099,
  ERROR_POPUP,
  FOREIGN_COUNTRY_OR_US_POSSESSION,
  PAGE_E_FILE,
  SUBMIT_ADD_ANOTHER,
} from "../../lib/constants";
import PayerDetails from "./GlobalFormComponents/PayerDetails";
import RecipientDetails from "./GlobalFormComponents/RecipientDetails";
import Fatca from "./GlobalFormComponents/FATCA/Fatca";
import RecAccount from "./GlobalFormComponents/RecAccount";
import AmountContainer from "./GlobalFormComponents/AmountContainer/AmountContainer";
import AmountHeading from "./GlobalFormComponents/AmountContainer/AmountHeading/AmountHeading";
import { div1099AmountDisplayTexts, stateInfoDisplayTexts } from "../../lib/languagePacks/en-us";
import Amount from "./GlobalFormComponents/AmountContainer/AmountInput/Amount";
import FormDetail from "./GlobalFormComponents/InternalComponents/FormDetail";
import StateInfo from "./GlobalFormComponents/StateInfo";
import SubmitButton from "../SubmitButton/SubmitButton";
import UpdateButton from "../UpdateButton/UpdateButton";
import { isValidAmount } from "./Form1099-B";
import Middleware from "../../lib/utils/Middleware";
import handlePopup from "../../lib/popup/handlePopup";
import SessionStorage from "../../lib/LocalCaching/SessionStorage";
import CorrectionSubmissionType from "./GlobalFormComponents/CorrectionRelated/CorrectionSubmissionType";

export default function Form1099DIV({
  formData,
  setFormData,
  formType,
  taxYear,
  isEditable,
  isCorrection,
  historyId,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  const dispatch = useDispatch();
  const [amount, setAmount] = useState({
    amount1: "",
    amount2: "",
    amount3: "",
    amount5: "",
    amount6: "",
    amount7: "",
    amount8: "",
    amount9: "",
    amountA: "",
    amountB: "",
    amountC: "",
    amountD: "",
    amountE: "",
    amountF: "",
    amountH: "",
    amountJ: "",
    amountG: "",
  }); // for displaying on the input field
  const [miscFields, setMiscFields] = useState({
    foreignCountry: "",
  });
  const navigate = useNavigate();
  const correctionSubmissionType = useSelector((state: any) => state.correctionSubmissionType);

  devLogInstance.log(formData);

  useEffect(() => {
    // Set the formatted amount values
    setAmount({
      amount1: formData.payment_amount1
        ? handleAmountFormat((formData.payment_amount1 / 100).toString())
        : "",
      amount2: formData.payment_amount2
        ? handleAmountFormat((formData.payment_amount2 / 100).toString())
        : "",
      amount3: formData.payment_amount3
        ? handleAmountFormat((formData.payment_amount3 / 100).toString())
        : "",
      amount5: formData.payment_amount5
        ? handleAmountFormat((formData.payment_amount5 / 100).toString())
        : "",
      amount6: formData.payment_amount6
        ? handleAmountFormat((formData.payment_amount6 / 100).toString())
        : "",
      amount7: formData.payment_amount7
        ? handleAmountFormat((formData.payment_amount7 / 100).toString())
        : "",
      amount8: formData.payment_amount8
        ? handleAmountFormat((formData.payment_amount8 / 100).toString())
        : "",
      amount9: formData.payment_amount9
        ? handleAmountFormat((formData.payment_amount9 / 100).toString())
        : "",
      amountA: formData.payment_amountA
        ? handleAmountFormat((formData.payment_amountA / 100).toString())
        : "",
      amountB: formData.payment_amountB
        ? handleAmountFormat((formData.payment_amountB / 100).toString())
        : "",
      amountC: formData.payment_amountC
        ? handleAmountFormat((formData.payment_amountC / 100).toString())
        : "",
      amountD: formData.payment_amountD
        ? handleAmountFormat((formData.payment_amountD / 100).toString())
        : "",
      amountE: formData.payment_amountE
        ? handleAmountFormat((formData.payment_amountE / 100).toString())
        : "",
      amountF: formData.payment_amountF
        ? handleAmountFormat((formData.payment_amountF / 100).toString())
        : "",
      amountG: formData.payment_amountG
        ? handleAmountFormat((formData.payment_amountG / 100).toString())
        : "",
      amountH: formData.payment_amountH
        ? handleAmountFormat((formData.payment_amountH / 100).toString())
        : "",
      amountJ: formData.payment_amountJ
        ? handleAmountFormat((formData.payment_amountJ / 100).toString())
        : "",
    });

    // Set the formatted Misc field values
    setMiscFields({
      foreignCountry: formData.foreign_country_or_us_Possession
        ? formData.foreign_country_or_us_Possession
        : "",
    });

    return () => {
      setAmount({
        amount1: "",
        amount2: "",
        amount3: "",
        amount5: "",
        amount6: "",
        amount7: "",
        amount8: "",
        amount9: "",
        amountA: "",
        amountB: "",
        amountC: "",
        amountD: "",
        amountE: "",
        amountF: "",
        amountH: "",
        amountJ: "",
        amountG: "",
      });
      setMiscFields({
        foreignCountry: "",
      });
    };
  }, [
    formData.payment_amount1,
    formData.payment_amount2,
    formData.payment_amount3,
    formData.payment_amount5,
    formData.payment_amount6,
    formData.payment_amount7,
    formData.payment_amount8,
    formData.payment_amount9,
    formData.payment_amountA,
    formData.payment_amountB,
    formData.payment_amountC,
    formData.payment_amountD,
    formData.payment_amountE,
    formData.payment_amountF,
    formData.payment_amountG,
    formData.payment_amountH,
    formData.payment_amountJ,
    formData.foreign_country_or_us_Possession,
  ]);

  // Handle input change on payment amount fields
  const handleChange = (e: any) => {
    const { id, value } = e.target;
    const formattedAmount = handleAmountFormat(value);
    switch (id) {
      case AMOUNT_1:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount1: formattedAmount,
          });
        break;
      case AMOUNT_2:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount2: formattedAmount,
          });
        break;
      case AMOUNT_3:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount3: formattedAmount,
          });
        break;
      case AMOUNT_5:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount5: formattedAmount,
          });
        break;
      case AMOUNT_6:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount6: formattedAmount,
          });
        break;
      case AMOUNT_7:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount7: formattedAmount,
          });
        break;
      case AMOUNT_8:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount8: formattedAmount,
          });
        break;
      case AMOUNT_9:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount9: formattedAmount,
          });
        break;
      case AMOUNT_A:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountA: formattedAmount,
          });
        break;
      case AMOUNT_B:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountB: formattedAmount,
          });
        break;
      case AMOUNT_C:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountC: formattedAmount,
          });
        break;
      case AMOUNT_D:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountD: formattedAmount,
          });
        break;
      case AMOUNT_E:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountE: formattedAmount,
          });
        break;
      case AMOUNT_F:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountF: formattedAmount,
          });
        break;
      case AMOUNT_G:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountG: formattedAmount,
          });
        break;
      case AMOUNT_H:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountH: formattedAmount,
          });
        break;
      case AMOUNT_J:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountJ: formattedAmount,
          });
        break;

      default:
        break;
    }
  };

  // Handle MISC field change
  const handleMiscFieldChange = (e: any) => {
    const { id: fieldType, value } = e.target;
    switch (fieldType) {
      case FOREIGN_COUNTRY_OR_US_POSSESSION:
        const letterRegex = new RegExp("^[a-zA-Z]*$");
        devLogInstance.log(`Foreign country val ---> ${value}`);
        const isValid = value.length > 0 ? letterRegex.test(value) : true;
        devLogInstance.log(isValid);
        isValid &&
          value.length <= 40 &&
          setMiscFields({
            ...miscFields,
            foreignCountry: value,
          });
        break;

      default:
        break;
    }
  };

  // Handle form submit
  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    const { id: submitType } = e.target;
    devLogInstance.log(`Clicked on ---> ${submitType}`);

    let data = {
      ...formData,
      payer_country: !formData.payer_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.payer_country,
      recipient_country: !formData.recipient_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.recipient_country,
      form_type: formType,
      foreign_country_or_us_Possession: miscFields.foreignCountry,
      payment_amount1: amount.amount1 ? parseFloat(amount.amount1.replace(/,/gi, "")) : 0,
      payment_amount2: amount.amount2 ? parseFloat(amount.amount2.replace(/,/gi, "")) : 0,
      payment_amount3: amount.amount3 ? parseFloat(amount.amount3.replace(/,/gi, "")) : 0,
      payment_amount5: amount.amount5 ? parseFloat(amount.amount5.replace(/,/gi, "")) : 0,
      payment_amount6: amount.amount6 ? parseFloat(amount.amount6.replace(/,/gi, "")) : 0,
      payment_amount7: amount.amount7 ? parseFloat(amount.amount7.replace(/,/gi, "")) : 0,
      payment_amount8: amount.amount8 ? parseFloat(amount.amount8.replace(/,/gi, "")) : 0,
      payment_amount9: amount.amount9 ? parseFloat(amount.amount9.replace(/,/gi, "")) : 0,
      payment_amountA: amount.amountA ? parseFloat(amount.amountA.replace(/,/gi, "")) : 0,
      payment_amountB: amount.amountB ? parseFloat(amount.amountB.replace(/,/gi, "")) : 0,
      payment_amountC: amount.amountC ? parseFloat(amount.amountC.replace(/,/gi, "")) : 0,
      payment_amountD: amount.amountD ? parseFloat(amount.amountD.replace(/,/gi, "")) : 0,
      payment_amountE: amount.amountE ? parseFloat(amount.amountE.replace(/,/gi, "")) : 0,
      payment_amountF: amount.amountF ? parseFloat(amount.amountF.replace(/,/gi, "")) : 0,
      payment_amountG: amount.amountG ? parseFloat(amount.amountG.replace(/,/gi, "")) : 0,
      payment_amountH: amount.amountH ? parseFloat(amount.amountH.replace(/,/gi, "")) : 0,
      payment_amountJ: amount.amountJ ? parseFloat(amount.amountJ.replace(/,/gi, "")) : 0,
    };
    devLogInstance.log(data);

    const paymentAmounts = [
      data.payment_amount1,
      data.payment_amount2,
      data.payment_amount3,
      data.payment_amount5,
      data.payment_amount6,
      data.payment_amount7,
      data.payment_amount8,
      data.payment_amount9,
      data.payment_amountA,
      data.payment_amountB,
      data.payment_amountC,
      data.payment_amountD,
      data.payment_amountE,
      data.payment_amountF,
      data.payment_amountG,
      data.payment_amountH,
      data.payment_amountJ,
    ];
    const incomeAmounts = [
      data.payment_amount1,
      data.payment_amount2,
      data.payment_amount3,
      data.payment_amount5,
      data.payment_amount6,
      data.payment_amount7,
      data.payment_amount8,
      data.payment_amount9,
      data.payment_amountB,
      data.payment_amountC,
      data.payment_amountD,
      data.payment_amountE,
      data.payment_amountF,
      data.payment_amountG,
      data.payment_amountH,
      data.payment_amountJ,
    ];

    // Correction filing middleware
    Middleware.fileCorrection(
      {
        isCorrection: isCorrection,
        correctionSubmissionType: correctionSubmissionType,
        correctionCode1Details: data,
        correctionCode2Details: data,
        formType: data.form_type,
      },
      (err, correctionCode) => {
        if (err) {
          console.error(err);
          handlePopup(err, ERROR_POPUP, dispatch);
          return;
        }

        if (correctionCode) {
          data = {
            ...data,
            file_submission_type: correctionSubmissionType,
            correction_type: correctionCode,
            payer_is_new_or_updated: false,
            history_id: historyId,
          };
          devLogInstance.log("Data before sumbitting correction");
          devLogInstance.log(data);
        }

        devLogInstance.log("Removing cached intial data...");
        SessionStorage.removeCacheItem(CORRECTION_INITIAL);
        devLogInstance.log("Cache removed!");

        formSubmissionHelper(
          data,
          paymentAmounts,
          data.payment_amountA,
          incomeAmounts,
          setFormData,
          setAmount,
          dispatch,
          submitType,
          navigate,
          PAGE_E_FILE
        ).then((res) => {
          if (submitType === SUBMIT_ADD_ANOTHER) {
            // Clear the local state values
            setMiscFields({ foreignCountry: "" });
          }
        });
      }
    );
    // formSubmissionHelper(
    //   data,
    //   paymentAmounts,
    //   data.payment_amountA,
    //   incomeAmounts,
    //   setFormData,
    //   setAmount,
    //   dispatch,
    //   submitType,
    //   navigate,
    //   PAGE_E_FILE
    // ).then((res) => {
    //   if (submitType === SUBMIT_ADD_ANOTHER) {
    //     // Clear the local state values
    //     setMiscFields({ foreignCountry: "" });
    //   }
    // });
  };

  return (
    <>
      <form className="form-main-container">
        {isCorrection && <CorrectionSubmissionType />}
        <div
          className="form-wrapper"
          style={{
            border: `2px solid ${style.eFormBorderColor}`,
          }}
        >
          <div className="form-details-amount-wrapper">
            <div className="left-portion-details">
              <PayerDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                isCorrection={isCorrection}
              />
              <RecipientDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                formType={formType}
                isCorrection={isCorrection}
              />
              <Fatca
                globalFormData={formData}
                setGlobalFormData={setFormData}
                type={"filing-form"}
              />
              <RecAccount globalFormData={formData} setGlobalFormData={setFormData} />
            </div>
            <div
              className="right-portion-details"
              style={{
                overflow: "hidden",
                borderBottom: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <div className="top-section">
                <div>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderTop: `1px solid ${style.eFormBorderColor}`,
                    }}
                  >
                    <AmountHeading
                      heading={div1099AmountDisplayTexts.ordinaryDividends}
                      amountNum="1a"
                    />
                    <Amount
                      type="text"
                      id={AMOUNT_1}
                      placeHolder="0.00"
                      class_="payer-details-input" // Only one class can be passed
                      value={amount.amount1}
                      onChangeHandler={handleChange}
                    />
                  </AmountContainer>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderTop: `1px solid ${style.eFormBorderColor}`,
                    }}
                  >
                    <AmountHeading
                      heading={div1099AmountDisplayTexts.qualifiedDividends}
                      amountNum="1b"
                    />
                    <Amount
                      type="text"
                      id={AMOUNT_2}
                      placeHolder="0.00"
                      class_="payer-details-input" // Only one class can be passed
                      value={amount.amount2}
                      onChangeHandler={handleChange}
                    />
                  </AmountContainer>
                </div>
                <FormDetail formType={formType} taxYear={taxYear} formName={DIV_1099} />
              </div>

              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading
                    heading={div1099AmountDisplayTexts.totalCapitalGain}
                    amountNum="2a"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_3}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount3}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading heading={div1099AmountDisplayTexts.sec1250Gain} amountNum="2b" />
                  <Amount
                    type="text"
                    id={AMOUNT_6}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount6}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={div1099AmountDisplayTexts.sec1202Gain} amountNum="2c" />
                  <Amount
                    type="text"
                    id={AMOUNT_7}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount7}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading
                    heading={div1099AmountDisplayTexts.collectiblesGain}
                    amountNum="2d"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_8}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount8}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="payer-direct-sales" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={div1099AmountDisplayTexts.sec897Div} amountNum="2e" />
                  <Amount
                    type="text"
                    id={AMOUNT_H}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountH}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading
                    heading={div1099AmountDisplayTexts.sec897CapitalGain}
                    amountNum="2f"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_J}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountJ}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={div1099AmountDisplayTexts.nondivDistr} amountNum="3" />
                  <Amount
                    type="text"
                    id={AMOUNT_9}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount9}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading
                    heading={div1099AmountDisplayTexts.incomeTaxWithheldDisplayText}
                    amountNum="4"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_A}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountA}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={div1099AmountDisplayTexts.sec199ADiv} amountNum="5" />
                  <Amount
                    type="text"
                    id={AMOUNT_5}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount5}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading heading={div1099AmountDisplayTexts.invstExpenses} amountNum="6" />
                  <Amount
                    type="text"
                    id={AMOUNT_B}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountB}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={div1099AmountDisplayTexts.foreignTaxPaid} amountNum="7" />
                  <Amount
                    type="text"
                    id={AMOUNT_C}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountC}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading
                    heading={div1099AmountDisplayTexts.foreignCountryOrUSPossession}
                    amountNum="8"
                  />
                  <input
                    id={FOREIGN_COUNTRY_OR_US_POSSESSION}
                    type="text"
                    style={{ padding: "0.5rem 1rem" }}
                    value={miscFields.foreignCountry}
                    onChange={handleMiscFieldChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  //   borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={div1099AmountDisplayTexts.cashLiquidDist} amountNum="9" />
                  <Amount
                    type="text"
                    id={AMOUNT_D}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountD}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading
                    heading={div1099AmountDisplayTexts.noncashLiquidDist}
                    amountNum="10"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_E}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountE}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={div1099AmountDisplayTexts.exemptIntDiv} amountNum="12" />
                  <Amount
                    type="text"
                    id={AMOUNT_F}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountF}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading
                    heading={div1099AmountDisplayTexts.pvtActivityBondInterest}
                    amountNum="13"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_G}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountG}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                }}
              />
            </div>
          </div>
        </div>
        <StateInfo
          state1Heading={stateInfoDisplayTexts.div1099.state1.heading}
          state2Heading={stateInfoDisplayTexts.div1099.state2.heading}
          taxWithheldLabel={stateInfoDisplayTexts.div1099.stateTaxWithheldLabel}
          stateVolLabel={stateInfoDisplayTexts.div1099.stateLabel}
          stateNoLabel={stateInfoDisplayTexts.div1099.stateNoLabel}
          stateIncomeLabel={""}
          globalFormData={formData}
          setGlobalFormData={setFormData}
          formType={formType}
          isCorrection={isCorrection}
        />
      </form>
      {!isEditable ? (
        <SubmitButton handleSubmit={handleFormSubmit} isCorrection={isCorrection} />
      ) : (
        <UpdateButton handleUpdate={handleFormSubmit} />
      )}
    </>
  );
}
