import { GetAdminsResponseData } from "../../../interfaces/main";
import { axiosInstance } from "../axiosConfig";

export default async function getAdmins(): Promise<GetAdminsResponseData> {
  try {
    const { data } = await axiosInstance.get("/admin/view_admins");
    return data;
  } catch (error) {
    throw error;
  }
}
