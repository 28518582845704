import Handler from "../../Handlers/main";
import setReviewedFormIds from "../../StateSetters/setReviewedFormIds";
import { markReviewed } from "../../../components/forms/ReviewForm/Edit-Next/EditNextBtn";
import setNoDefault from "../setNoDefault";
import { FormReviewHelperArgs } from "../../../../interfaces/main";

export default async function formReviewHelper({
  dispatch,
  cartIds,
  selectedRecordIdx,
}: FormReviewHelperArgs) {
  const res = await Handler.handleFormReview(cartIds, dispatch);
  // Set the reviewed Id
  // setReviewedids([...reviwedIds, ...res.successfulIdsArray]);
  setReviewedFormIds(dispatch, res.successfulIdsArray);
  // Mark the current record as reviewed
  markReviewed(dispatch, selectedRecordIdx);

  // Use no default
  setNoDefault(dispatch, true);
}
