import React from "react";
import {
  GroupRecord,
  PayerUnderStaffOrGroup,
  StaffUnderGroup,
  ViewAssignedPayersActionPayload,
  ViewAssignedStaffsActionPayload,
} from "../../../../interfaces/main";
import { FaMinusSquare } from "react-icons/fa";
import { TbDots } from "react-icons/tb";
import GroupRelatedActionPopup from "./GroupRelatedActionPopup";
import displayViewAssignedPayersModal from "../../../lib/StateSetters/StaffAndGroupRelated/displayViewAssignedPayersModal";
import { useDispatch } from "react-redux";
import displayViewAssignedStaffsModal from "../../../lib/StateSetters/StaffAndGroupRelated/displayViewAssignedStaffsModal";

interface Props extends React.HTMLProps<HTMLElement> {
  groupRecords: GroupRecord[];
  setGroupRecords: React.Dispatch<React.SetStateAction<GroupRecord[]>>;
  handleSingleGroupSelection: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleAllGroupSelection: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedGroupIds: string[];
  deselectAllGroups: () => void;
  clickedOnActionWithId: (e: React.MouseEvent<any>) => void;
  actionId: string;
}

export default function GroupsTable(props: Props) {
  const dispatch = useDispatch();

  const viewAssignedPayers = (assignedPayers: PayerUnderStaffOrGroup[], groupName: string) => {
    const payload: ViewAssignedPayersActionPayload = {
      toDisplay: true,
      assignedPayers,
      staffOrGroupName: groupName,
      isStaff: false,
    };
    displayViewAssignedPayersModal(payload, dispatch);
  };

  const viewAssignedStaffs = (assignedStaffs: StaffUnderGroup[], groupName: string) => {
    const payload: ViewAssignedStaffsActionPayload = {
      toDisplay: true,
      assignedStaffs,
      groupName,
    };
    displayViewAssignedStaffsModal(payload, dispatch);
  };

  return (
    <div className={`${props.className}`}>
      <table>
        <thead>
          <tr>
            <th className="text-center relative">
              {props.selectedGroupIds.length > 0 &&
              props.groupRecords.length !== props.selectedGroupIds.length ? (
                <FaMinusSquare
                  id="semi-checked-icon"
                  className="absolute left-5 top-3 text-blue-600"
                  onClick={props.deselectAllGroups}
                />
              ) : (
                <input
                  type="checkbox"
                  name="All Staff Selector"
                  id="select-all-staff"
                  onChange={props.handleAllGroupSelection}
                  checked={props.groupRecords.length === props.selectedGroupIds.length}
                />
              )}
            </th>
            <th className="text-center">Name</th>
            <th className="text-center w-[30%]">Description</th>
            <th className="text-center">Total Business'</th>
            <th className="text-center">Total Members</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {props.groupRecords.map((group) => {
            return (
              <tr key={group._id}>
                <td className="text-center py-3">
                  <input
                    type="checkbox"
                    name="Staff Selector"
                    id={group._id}
                    onChange={props.handleSingleGroupSelection}
                    checked={props.selectedGroupIds.includes(group._id)}
                  />
                </td>
                <td className="text-center">
                  <p>{group.group_name}</p>
                </td>
                <td className="text-center">
                  <p>{group.group_description}</p>
                </td>
                <td className="text-center">
                  <div className="flex items-center justify-center">
                    <button
                      onClick={() => viewAssignedPayers(group.payer_ids, group.group_name)}
                      className="bg-[#7E30E1] text-white text-md font-semibold rounded-full w-2 h-2 p-4 flex items-center justify-center"
                    >
                      {group.payer_ids.length}
                    </button>
                  </div>
                </td>
                <td className="text-center">
                  <div className="flex items-center justify-center">
                    <button
                      onClick={() => viewAssignedStaffs(group.staff_ids, group.group_name)}
                      className="bg-[#7E30E1] text-white text-md font-semibold rounded-full w-2 h-2 p-4 flex items-center justify-center"
                    >
                      {group.staff_ids.length}
                    </button>
                  </div>
                </td>
                <td className="relative">
                  <h1
                    className="flex justify-center action-icon-wrapper"
                    id={group._id}
                    onClick={props.clickedOnActionWithId}
                  >
                    <TbDots id={group._id} className="cursor-pointer" />
                  </h1>
                  {props.actionId.includes(group._id) && (
                    <GroupRelatedActionPopup
                      groupId={group._id}
                      groupName={group.group_name}
                      groupDescription={group.group_description}
                      assignedPayers={group.payer_ids}
                      assignedStaffs={group.staff_ids}
                      setGroupRecords={props.setGroupRecords}
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
