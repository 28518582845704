import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import formatTin from "../../../../../../lib/form/utils/formatTin";
import { BsClipboardCheck } from "react-icons/bs";
import Bulkaction from "../../../../../../lib/utils/BulkAction";
import maskTin, { isEin } from "../../../../../../lib/utils/maskTin";
import devLogInstance from "../../../../../../lib/loggerConfig";
import { FaCopyright } from "react-icons/fa";
import { Action } from "../../../../../../lib/utils/Action";
import displayConfirmationModal from "../../../../../../lib/StateSetters/displayConfirmationModal";
import { DELETE_ACTION_SINGLE } from "../../../../../../lib/UserActions/action";
import ActionContextMenu from "./ActionContextMenu";
import formReviewHelper from "../../../../../../lib/utils/Helpers/formReviewHelper";
import { DELETE, DOWNLOAD_SINGLE, REVIEW, UNREVIEW } from "../../../../../../lib/constants";
import formUnreviewHelper from "../../../../../../lib/utils/Helpers/formUnreviewHelper";

export default function TableContent({
  recName,
  recNameCont,
  recTin,
  recTinType,
  correctionType,
  idx,
  formId,
  onClickHandler,
  formType,
  formIdx,
  isAllChecked,
}: any) {
  const selectedRecData = useSelector((state: any) => state.selectedRecData);
  const recData = useSelector((state: any) => state.rpRecipientData);
  const bulkActionItems = useSelector((state: any) => state.bulkActionItems);
  const dispatch = useDispatch();
  const [isSelected, setIsSelected] = useState(false);
  const [checkedRecord, setCheckedRecord] = useState<any | null>(null);
  const [isChecked, setIsChecked] = useState(false);
  const toMaskTin = useSelector((state: any) => state.toMaskTin);
  const [timesClicked, setTimesClicked] = useState(0);
  const [displayActionContextMenu, toDisplayActionContextMenu] = useState(false);

  // logger.log("Bulk action items:");
  // logger.log(bulkActionItems);
  // logger.log(selectedRecData);

  // If the select all checkbox is checked, then update the isChecked state for individual child component
  useEffect(() => {
    isAllChecked && setIsChecked(true);
  }, [isAllChecked]);

  // Check if the current component is selected
  useEffect(() => {
    if (selectedRecData.selectedRecRecordId === formId) {
      setIsSelected(true);
    }
    return () => {
      setIsSelected(false);
    };
  }, [selectedRecData, formId]);

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (isChecked) {
      Bulkaction.markAsChecked(dispatch, formType, idx, recData[idx], formIdx);
    } else {
      Bulkaction.markAsUnchecked(dispatch, formType, idx, recData[idx], formIdx);
    }

    // return ()=>{
    //   setIsChecked(!isChecked)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked, dispatch]);

  useEffect(() => {
    if (bulkActionItems.length !== 0) {
      devLogInstance.log(bulkActionItems[formIdx]);
      const checkedRecord = bulkActionItems[formIdx]?.data.filter((dataObj: any) => {
        return dataObj.idx === idx;
      })[0];
      checkedRecord?.length !== 0 ? setCheckedRecord(checkedRecord) : setCheckedRecord(null);
      bulkActionItems[formIdx]?.data.length === 0 && setIsChecked(false);
    }
    return () => {
      setCheckedRecord(null);
    };
  }, [bulkActionItems, formType, idx, formIdx]);

  // Handle clicks on individual records
  useEffect(() => {
    const handleClick = async (e: any) => {
      const id = e.target.id ?? null;
      devLogInstance.log("CLICKED: " + id);
      if (id && id.includes(idx.toString())) {
        setTimesClicked((prevState) => {
          return (prevState += 1);
        });
      }

      setTimeout(() => {
        setTimesClicked(0);
      }, 1000);
    };
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [idx]);

  // Handle right mouse click
  useEffect(() => {
    const handleClick = async (e: any) => {
      const id = e.target.id ?? null;
      devLogInstance.log("CLICKED: " + id);
      const clickType = e.button;

      if (clickType === 2) {
        id && id.includes(idx.toString())
          ? toDisplayActionContextMenu(true)
          : toDisplayActionContextMenu(false);
      } else {
        toDisplayActionContextMenu(false);
        if (isSelected) {
          const cartIds = [{ _id: selectedRecData.selectedRecRecord.cartId }]; // Will be used if any of the action is selected
          switch (id) {
            case REVIEW:
              formReviewHelper({
                dispatch,
                cartIds,
                selectedRecordIdx: selectedRecData.selectedRecRecordIdx,
              });
              break;

            case UNREVIEW:
              formUnreviewHelper({
                dispatch,
                cartIds,
                selectedRecordIdx: selectedRecData.selectedRecRecordIdx,
              });
              break;

            case DELETE:
              Action.cartIds = cartIds;
              displayConfirmationModal(dispatch, DELETE_ACTION_SINGLE, true);
              break;

            case DOWNLOAD_SINGLE:
              Action.cartIds = cartIds;
              Action.formDownload();
              break;

            default:
              break;
          }
        }
      }
    };
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [idx, dispatch, selectedRecData, isSelected]);

  // Mark current form as reviewed if clicked on its record twice
  useEffect(() => {
    if (timesClicked === 2 && isSelected) {
      const cartIds = [{ _id: selectedRecData.selectedRecRecord.cartId }];
      formReviewHelper({
        dispatch,
        cartIds,
        selectedRecordIdx: selectedRecData.selectedRecRecordIdx,
      });
    }
  }, [timesClicked, dispatch, selectedRecData, isSelected]);

  // Shortcut key actions on inidividual forms
  /* DOC: SHIFT + U -> Unreview the current form
          SHIFT + X -> Delete the current form
          SHIFT + D -> Download the current form
  */
  useEffect(() => {
    const handleKeydown = async (e: any) => {
      const isSHIFT = e.shiftKey;
      const key = e.key;
      if (isSelected && isSHIFT) {
        const cartIds = [{ _id: selectedRecData.selectedRecRecord.cartId }];
        switch (key.toUpperCase()) {
          // Unreview
          case "U":
            formUnreviewHelper({
              dispatch,
              cartIds,
              selectedRecordIdx: selectedRecData.selectedRecRecordIdx,
            });
            break;

          // Delete
          case "X":
            Action.cartIds = cartIds;
            displayConfirmationModal(dispatch, DELETE_ACTION_SINGLE, true);
            break;

          // Download
          case "D":
            Action.cartIds = cartIds;
            Action.formDownload();
            break;

          default:
            break;
        }
      }
    };
    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [dispatch, selectedRecData, isSelected]);

  return (
    <tbody>
      <tr>
        <td>
          <input
            id={idx}
            type="checkbox"
            onChange={handleChange}
            checked={checkedRecord ? checkedRecord.checked : false}
          />
        </td>
        <td
          id={`RECORD_${idx}`}
          style={{ background: isSelected ? "#B9E9FC" : "" }}
          className="rec-record relative"
          onClick={() => {
            onClickHandler(formId, idx);
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            onClickHandler(formId, idx);
          }}
        >
          <div>
            <p>{recName?.length > 15 ? `${recName.substring(0, 15)}....` : recName}</p>
            <p>{recNameCont?.length > 15 ? `${recNameCont.substring(0, 15)}....` : recNameCont}</p>
            <p>
              {recTinType}:{" "}
              {!isEin(recTinType)
                ? toMaskTin
                  ? maskTin(formatTin(recTin, recTinType), recTinType)
                  : formatTin(recTin, recTinType)
                : formatTin(recTin, recTinType)}
            </p>
          </div>
          {correctionType && (
            <h2 className="px-2">
              <FaCopyright className="text-red-500" />
            </h2>
          )}
          {recData[idx]?.reviewed && (
            <h2 className="review-check">
              <BsClipboardCheck />
            </h2>
          )}
          {displayActionContextMenu && <ActionContextMenu />}
        </td>
      </tr>
    </tbody>
  );
}
