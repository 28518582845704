import SessionStorage from "../LocalCaching/SessionStorage";
import Cookie from "../utils/Cookie";
import authenticateAdmin from "./authenticateAdmin";

export default function handleAdminLogin(token: string, adminLevel: string, stateSetter: any) {
  if (token) {
    // Save the token in a cookie
    Cookie.setCookie("admin_token", token);

    // Save the admin level in session storage
    SessionStorage.setCacheItem("admin_level", adminLevel);

    // Authenticate the admin
    authenticateAdmin(stateSetter, true);
  }
}
