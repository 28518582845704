import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ClBody from "./ClBody";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { useEffect, useState } from "react";
import devLogInstance from "../../lib/loggerConfig";
import { ONLINE_ACCESS, POSTAL_MAILING, STATE_FILING } from "../../lib/constants";
import Handler from "../../lib/Handlers/main";
import callEmailRequiredModal from "../../lib/StateSetters/callEmailRequiredModal";
import TruthyCartValues, { StateFiling } from "../../lib/utils/CartValues";
import displayConfirmationModal from "../../lib/StateSetters/displayConfirmationModal";
import { DISABLE_STATE_FILING_FOR_PAYER } from "../../lib/UserActions/action";

export default function ClTable({ recipientDataArray, payerId, isDraftData = false }: any) {
  const truthyCartValues = useSelector((state: any) => state.truthyCartValues);
  const totalRecsWithoutStatefiling = useSelector(
    (state: any) => state.totalRecsWithoutStateFiling
  );
  // console.log(`PAYER ID FOR DRAFT DATA: ${payerId}`);
  // const cartPageSelectedRec = useSelector(state => state.cartPageSelectedRec);
  // const [removeAll, setRemoveAll] = useState({
  //   onlineAccess: null,
  //   stateFiling: null,
  //   postalMailing: null,
  // });
  const recsWithoutEmailId = useSelector((state: any) => state.recipientsWithoutEmailId);
  const dispatch = useDispatch();
  const [filteredSFCartId, setFilteredSFCartId] = useState<{ filteredIds: string[] }[]>([]);

  // console.log("RECS  WITHOUT  EMAIL ID IN CART PAGE: ", recsWithoutEmailId);

  // // Filter the state filing cart ids
  useEffect(() => {
    const filteredCartIds: string[] = [];
    truthyCartValues.stateFiling[payerId]?.data.forEach((cartId: string) => {
      !filteredCartIds.includes(cartId) && filteredCartIds.push(cartId);
    });

    setFilteredSFCartId(prevState => {
      return [...prevState, { filteredIds: filteredCartIds }];
    });
    return () => {
      setFilteredSFCartId([]);
    };
  }, [truthyCartValues, payerId]);

  const handleRemoveAll = async (e: any) => {
    const { id: cartValType } = e.target.parentElement;
    devLogInstance.log(`Clicked on ---> ${cartValType}`);
    switch (cartValType) {
      case POSTAL_MAILING:
        const cartIdsPM = truthyCartValues.postalMailing[payerId]?.data;
        devLogInstance.log("CART IDS AT THE TIME OF REMOVAL: ");
        devLogInstance.log(cartIdsPM);
        await Handler.handleCartCheckboxUpdate(
          e,
          truthyCartValues,
          false,
          cartIdsPM,
          payerId,
          dispatch
        );
        cartIdsPM.forEach((cartId: string) => {
          TruthyCartValues.removeTruthyCartVals(cartId, payerId, cartValType, dispatch);
        });
        break;
      case ONLINE_ACCESS:
        const cartIdsOA = truthyCartValues.onlineAccess[payerId]?.data;
        devLogInstance.log("CART IDS AT THE TIME OF REMOVAL: ");
        devLogInstance.log(cartIdsOA);
        await Handler.handleCartCheckboxUpdate(
          e,
          truthyCartValues,
          false,
          cartIdsOA,
          payerId,
          dispatch
        );
        cartIdsOA.forEach((cartId: string) => {
          TruthyCartValues.removeTruthyCartVals(cartId, payerId, cartValType, dispatch);
        });
        break;
      case STATE_FILING:
        const filteredCartIds: string[] = [];
        truthyCartValues.stateFiling[payerId].data.forEach((cartId: string) => {
          !filteredCartIds.includes(cartId) && filteredCartIds.push(cartId);
        });
        devLogInstance.log(filteredCartIds);
        // Set the filtered cart ids and current payer id for the current payer
        StateFiling.filteredCartIdsForStatefiling = filteredCartIds;
        StateFiling.currentpayerId = payerId;
        // Call the confirmation modal
        displayConfirmationModal(dispatch, DISABLE_STATE_FILING_FOR_PAYER, true);
        // await Handler.handleCartCheckboxUpdate(
        //   e,
        //   truthyCartValues,
        //   false,
        //   filteredCartIds,
        //   payerId,
        //   dispatch
        // );
        // filteredCartIds.forEach((cartId) => {
        //   TruthyCartValues.removeTruthyCartVals(cartId, payerId, cartValType, dispatch);
        // });
        break;

      default:
        break;
    }
  };

  const handleSelectAll = async (e: any) => {
    const { id: cartValType } = e.target.parentElement;
    devLogInstance.log(`Clicked on ---> ${cartValType}`);
    let stateFilingOptions;
    const cartIds = recipientDataArray.map((recData: any) => {
      return recData.cartId;
    });
    devLogInstance.log(cartIds);
    devLogInstance.log("State filing options when clicked on select all:");
    devLogInstance.log(stateFilingOptions);
    // Check if recipients without email id exists when the cartValType is of online access
    devLogInstance.log("RECS WITHOUT EMAIL ID:");
    devLogInstance.log(recsWithoutEmailId);
    let recCartIdsWithoutEmailForCurrentPayer: string[] = [];
    let recWithoutEmailForCurrentPayer: any[] = [];
    if (cartValType === ONLINE_ACCESS) {
      recsWithoutEmailId.forEach((obj: any) => {
        obj.payerId === payerId && recCartIdsWithoutEmailForCurrentPayer.push(obj.cartId);
        obj.payerId === payerId && recWithoutEmailForCurrentPayer.push(obj);
      });
      devLogInstance.log("RECIPIENT CART IDS WITHOUT EMAIL FOR THE CURRENT PAYER");
      devLogInstance.log(recCartIdsWithoutEmailForCurrentPayer);
      // Call the update checkbox api for the recipients who have an email
      var filteredCartIds = cartIds.filter((cartId: string) => {
        return !recCartIdsWithoutEmailForCurrentPayer.includes(cartId);
      });
      await Handler.handleCartCheckboxUpdate(
        e,
        truthyCartValues,
        true,
        filteredCartIds,
        payerId,
        dispatch
      );
    } else if (cartValType !== ONLINE_ACCESS) {
      await Handler.handleCartCheckboxUpdate(e, truthyCartValues, true, cartIds, payerId, dispatch);
    }
    switch (cartValType) {
      case POSTAL_MAILING:
        cartIds.forEach((cartId: string) => {
          // setTruthyCartVals(cartId, cartValType);
          TruthyCartValues.setTruthyCartVals(cartId, payerId, cartValType, dispatch);
        });
        break;

      case STATE_FILING:
        cartIds.forEach((cartId: string, i: number) => {
          stateFilingOptions = {
            state1: recipientDataArray[i].state1,
            state2: recipientDataArray[i].state2,
          };
          TruthyCartValues.setTruthyCartVals(
            cartId,
            payerId,
            cartValType,
            dispatch,
            stateFilingOptions
          );
        });
        break;

      case ONLINE_ACCESS:
        // Check if recipients without email id exist
        if (recCartIdsWithoutEmailForCurrentPayer.length === 0) {
          cartIds.forEach((cartId: string) => {
            TruthyCartValues.setTruthyCartVals(cartId, payerId, cartValType, dispatch);
          });
        } else {
          filteredCartIds.forEach((cartId: string) => {
            TruthyCartValues.setTruthyCartVals(cartId, payerId, cartValType, dispatch);
          });
          // Call the email required modal
          const options = {
            dataArray: recWithoutEmailForCurrentPayer,
            payerId: payerId,
            cartIds: cartIds,
            toCall: true,
          };
          callEmailRequiredModal(dispatch, options);
        }
        break;

      default:
        break;
    }
  };

  isDraftData && devLogInstance.log(`Truthy cart values for draft data: `);
  isDraftData && devLogInstance.log(truthyCartValues);

  return (
    <table>
      <thead>
        <tr>
          <th></th>
          <th style={{ fontSize: "1rem", textAlign: "center" }}>Recipient</th>
          <th style={{ fontSize: "1rem", textAlign: "center" }}>FormType</th>
          <th style={{ fontSize: "1rem" }}>
            <div id={STATE_FILING} style={{ alignItems: "center" }}>
              {filteredSFCartId[0]?.filteredIds.length === recipientDataArray.length && (
                <i
                  id="check"
                  onClick={handleRemoveAll}
                  className="fa-solid fa-square-check"
                  style={{
                    fontSize: "1rem",
                    color: "#0A6EBD",
                  }}
                />
              )}
              {filteredSFCartId[0]?.filteredIds.length !== 0 &&
                filteredSFCartId[0]?.filteredIds.length !== recipientDataArray.length && (
                  <i
                    id="minus"
                    onClick={handleRemoveAll}
                    className="fa-solid fa-square-minus"
                    style={{
                      fontSize: "1rem",
                      color: "#0A6EBD",
                    }}
                  />
                )}
              {totalRecsWithoutStatefiling[payerId]?.totalRecs !== recipientDataArray.length &&
                filteredSFCartId[0]?.filteredIds.length === 0 && (
                  <MdOutlineCheckBoxOutlineBlank
                    id="checkbox"
                    onClick={handleSelectAll}
                    style={{ fontSize: "1rem" }}
                  />
                )}
              <span>State E-File</span>
            </div>
          </th>
          <th style={{ fontSize: "1rem" }}>
            <div id={ONLINE_ACCESS} style={{ alignItems: "center" }}>
              {truthyCartValues.onlineAccess[payerId]?.data.length ===
                recipientDataArray.length && (
                <i
                  id="check"
                  onClick={handleRemoveAll}
                  className="fa-solid fa-square-check"
                  style={{
                    fontSize: "1rem",
                    color: "#0A6EBD",
                  }}
                />
              )}
              {truthyCartValues.onlineAccess[payerId]?.data.length !== 0 &&
                truthyCartValues.onlineAccess[payerId]?.data.length !==
                  recipientDataArray.length && (
                  <i
                    id="minus"
                    onClick={handleRemoveAll}
                    className="fa-solid fa-square-minus"
                    style={{
                      fontSize: "1rem",
                      color: "#0A6EBD",
                    }}
                  />
                )}
              {truthyCartValues.onlineAccess[payerId]?.data.length === 0 && (
                <MdOutlineCheckBoxOutlineBlank
                  id="checkbox"
                  onClick={handleSelectAll}
                  style={{ fontSize: "1rem" }}
                />
              )}
              <span>Online Access</span>
            </div>
          </th>
          <th style={{ fontSize: "1rem" }}>
            <div id={POSTAL_MAILING} style={{ alignItems: "center" }}>
              {truthyCartValues.postalMailing[payerId]?.data.length ===
                recipientDataArray.length && (
                <i
                  id="check"
                  onClick={handleRemoveAll}
                  className="fa-solid fa-square-check"
                  style={{
                    fontSize: "1rem",
                    color: "#0A6EBD",
                  }}
                />
              )}
              {truthyCartValues.postalMailing[payerId]?.data.length !== 0 &&
                truthyCartValues.postalMailing[payerId]?.data.length !==
                  recipientDataArray.length && (
                  <i
                    id="minus"
                    onClick={handleRemoveAll}
                    className="fa-solid fa-square-minus"
                    style={{
                      fontSize: "1rem",
                      color: "#0A6EBD",
                    }}
                  />
                )}
              {truthyCartValues.postalMailing[payerId]?.data.length === 0 && (
                <MdOutlineCheckBoxOutlineBlank
                  id="checkbox"
                  onClick={handleSelectAll}
                  style={{ fontSize: "1rem" }}
                />
              )}
              Postal Mailing
            </div>
          </th>
          <th style={{ fontSize: "1rem", textAlign: "center" }} className="w-[10%]">
            Actions
          </th>
        </tr>
      </thead>
      {recipientDataArray.map((recObj: any, i: number) => {
        return (
          <ClBody
            key={i}
            recipientData={recObj}
            payerId={payerId}
            uniqueId={`${i}_${payerId}`}
            isDraftData={isDraftData}
          />
        );
      })}
    </table>
  );
}
