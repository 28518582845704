import React, { useState } from "react";
import devLogInstance, { prodLogInstance } from "../../lib/loggerConfig";
import submitLoginData from "../../lib/AdminRelatedUtils/submitLoginData";
import handleAdminLogin from "../../lib/AdminRelatedUtils/handleAdminLogin";
import { useDispatch } from "react-redux";
import { SHA256 } from "crypto-js";
import DisableScreen from "../../lib/utils/disableScreen";
import handlePopup from "../../lib/popup/handlePopup";
import { ERROR_POPUP } from "../../lib/constants";
import Password from "../../components/Password";

// Constants
const ADMIN_EMAIL = "ADMIN_EMAIL";
const ADMIN_PASSWORD = "ADMIN_PASSWORD";

export default function AdminLogin() {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const stateSetter = useDispatch();

  // Handle input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id: inputType, value } = e.target;

    switch (inputType) {
      case ADMIN_EMAIL:
        setLoginData({
          ...loginData,
          email: value,
        });
        break;

      case ADMIN_PASSWORD:
        setLoginData({
          ...loginData,
          password: value,
        });
        break;

      default:
        break;
    }
  };

  // Handle form submit
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    DisableScreen.enable();

    const encpass = SHA256(process.env.REACT_APP_NONCE + loginData.password).toString();
    devLogInstance.log(`Admin encrypted pass: ${encpass}`);
    const _data = {
      ...loginData,
      password: encpass,
    };
    devLogInstance.log(_data);
    // Call the API to submit data
    try {
      const data = await submitLoginData(_data);
      devLogInstance.log(data);
      // Handle Login
      handleAdminLogin(data.token, data.level, stateSetter);

      DisableScreen.disable();
    } catch (err: any) {
      prodLogInstance.error(err);
      if (err.response) {
        DisableScreen.disable();
        const toastMessage =
          err.response.data.Error ?? "There was an unexpected error! Please try after sometime.";
        handlePopup(toastMessage, ERROR_POPUP, stateSetter);
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center gap-6 h-[100vh]">
      <h1 className="text-4xl font-bold">Admin Login</h1>
      <form className="flex flex-col gap-5 w-[40%]" onSubmit={handleSubmit}>
        <input
          id={ADMIN_EMAIL}
          type="email"
          placeholder="Admin email"
          value={loginData.email}
          onChange={handleChange}
          className="py-2"
        />
        <Password
          id={ADMIN_PASSWORD}
          placeholder="Admin password"
          value={loginData.password}
          onChangeEvent={handleChange}
          sendOnChangeEvent={true}
          width="100%"
        />
        <div className="flex justify-center">
          <button
            type="submit"
            className="text-xl font-semibold w-[20%] py-3 px-8 rounded-xl bg-blue-600 text-white"
          >
            Login
          </button>
        </div>
      </form>
    </div>
  );
}
