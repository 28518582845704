import React from "react";
import { useSelector } from "react-redux";
import AmountContainer from "../../GlobalFormComponents/AmountContainer/AmountContainer";
import AmountHeading from "../../GlobalFormComponents/AmountContainer/AmountHeading/AmountHeading";
import {
  k1099AmountDisplayTexts,
  stateInfoDisplayTexts,
} from "../../../../lib/languagePacks/en-us";
import FormDetail from "../../GlobalFormComponents/InternalComponents/FormDetail";
import RFPayerTin from "../RFPayerTin/RFPayerTin";
import RFRecTin from "../RFRecTin/RFRecTin";
import IRSDisclaimer from "../FormIRSDisclaimer/IRSDisclaimer";
import StateWithoutIncomeField from "../RFState/StateWithoutIcomeField/StateWithoutIncomeField";
import { FiDollarSign } from "react-icons/fi";

export default function KRightPortion({
  formType,
  taxYear,
  formName,
  formData,
  ombNum,
  revMonth,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  return (
    <div className="right-portion-details" style={{ width: "50%" }}>
      <div className="top-section rf-ts">
        <div>
          <RFPayerTin
            formData={formData}
            styles={{
              width: "100%",
              border: "none",
              borderTop: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          />
          <RFRecTin
            formData={formData}
            styles={{
              width: "100%",
            }}
          />
          <AmountContainer
            class_="misc-info" // Only one class can be passed
            style={{
              borderTop: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountHeading
              heading={k1099AmountDisplayTexts.grossAmountOfPaymentCard}
              amountNum="1a"
            />
            {formData?.payment_amount1 !== 0 && (
              <div
                className="amount-values"
                style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
              >
                <FiDollarSign /> {(formData?.payment_amount1 / 100).toFixed(2)}
              </div>
            )}
          </AmountContainer>
        </div>
        <FormDetail
          formType={formType}
          taxYear={taxYear}
          formName={formName}
          ombNum={ombNum}
          revMonth={revMonth}
        />
      </div>
      <div style={{ display: "flex", overflow: "hidden" }}>
        <div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <AmountHeading
                heading={k1099AmountDisplayTexts.cardNotPresentTransactions}
                amountNum="1b"
              />
              {formData?.payment_amount2 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount2 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="misc-info" // Only one class can be passed
            >
              <AmountHeading heading={k1099AmountDisplayTexts.merchantCategoryCode} amountNum="2" />
              {formData?.merchant_category_code && (
                <div className="amount-values" style={{ color: "#30A2FF" }}>
                  {formData?.merchant_category_code}
                </div>
              )}
            </AmountContainer>
          </div>

          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <AmountHeading
                heading={k1099AmountDisplayTexts.numberOfPaymentTransac}
                amountNum="3"
              />
              {formData?.number_of_payment_transactions !== 0 && (
                <div className="amount-values" style={{ color: "#30A2FF" }}>
                  {formData?.number_of_payment_transactions}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="misc-info" // Only one class can be passed
            >
              <AmountHeading heading={k1099AmountDisplayTexts.taxWithheld} amountNum="4" />
              {formData?.payment_amount4 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount4 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>

          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <AmountHeading heading={k1099AmountDisplayTexts.janPayment} amountNum="5a" />
              {formData?.payment_amount5 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount5 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="misc-info" // Only one class can be passed
            >
              <AmountHeading heading={k1099AmountDisplayTexts.febPayment} amountNum="5b" />
              {formData?.payment_amount6 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount6 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>

          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <AmountHeading heading={k1099AmountDisplayTexts.marPayment} amountNum="5c" />
              {formData?.payment_amount7 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount7 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="misc-info" // Only one class can be passed
            >
              <AmountHeading heading={k1099AmountDisplayTexts.aprPayment} amountNum="5d" />
              {formData?.payment_amount8 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount8 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>

          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <AmountHeading heading={k1099AmountDisplayTexts.mayPayment} amountNum="5e" />
              {formData?.payment_amount9 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount9 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="misc-info" // Only one class can be passed
            >
              <AmountHeading heading={k1099AmountDisplayTexts.junPayment} amountNum="5f" />
              {formData?.payment_amountA !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountA / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>

          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <AmountHeading heading={k1099AmountDisplayTexts.julPayment} amountNum="5g" />
              {formData?.payment_amountB !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountB / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="misc-info" // Only one class can be passed
            >
              <AmountHeading heading={k1099AmountDisplayTexts.augPayment} amountNum="5h" />
              {formData?.payment_amountC !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountC / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>

          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <AmountHeading heading={k1099AmountDisplayTexts.sepPayment} amountNum="5i" />
              {formData?.payment_amountD !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountD / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="misc-info" // Only one class can be passed
            >
              <AmountHeading heading={k1099AmountDisplayTexts.octPayment} amountNum="5j" />
              {formData?.payment_amountE !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountE / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>

          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <AmountHeading heading={k1099AmountDisplayTexts.novPayment} amountNum="5k" />
              {formData?.payment_amountF !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountF / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="misc-info" // Only one class can be passed
            >
              <AmountHeading heading={k1099AmountDisplayTexts.decPayment} amountNum="5l" />
              {formData?.payment_amountG !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountG / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>
        </div>
        <IRSDisclaimer />
      </div>
      <StateWithoutIncomeField displayTexts={stateInfoDisplayTexts.k1099} formData={formData} />
    </div>
  );
}
