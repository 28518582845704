const globalFormData = {
  // payer data
  form_type: "",
  year: 0,
  payer_name: "",
  payer_is_foreign_address: false,
  payer_address_line1: "",
  payer_address_line2: "",
  payer_city: "",
  payer_state: "",
  payer_country: "",
  payer_zipcode: "",
  payer_contact_name: "",
  payer_email: "",
  payer_phone_number: "",
  payer_phone_number_extn: "",
  payer_is_terminating_this_year: false,
  payer_tin_type: "EIN",
  payer_tin: 0,

  // recipient data
  recipient_name: "",
  recipient_name_cont: "",
  recipient_is_foreign_address: false,
  recipient_address_line1: "",
  recipient_address_line2: "",
  recipient_city: "",
  recipient_state: "",
  recipient_country: "",
  recipient_zipcode: "",
  recipient_email: "",
  recipient_phone_number: "",
  recipient_phone_number_extn: "",
  recipient_tin_type: "SSN",
  recipient_tin: 0,
  online_access: false,

  // account related data
  payers_account_number_for_payee: "",
  second_tin_notice: false,

  // Amount data
  payment_amount1: 0,
  payment_amount2: 0,
  payment_amount3: 0,
  payment_amount4: 0,
  payment_amount5: 0,
  payment_amount6: 0,
  payment_amount7: 0,
  payment_amount8: 0,
  payment_amount9: 0,
  payment_amountA: 0,
  payment_amountB: 0,
  payment_amountC: 0,
  payment_amountD: 0,
  payment_amountE: 0,
  payment_amountF: 0,
  payment_amountG: 0,
  payment_amountH: 0,
  payment_amountJ: 0,

  // State related data
  state_income_tax_withheld1: 0,
  state1: "",
  state_income_tax_withheld2: 0,
  state2: "",
  payer_state_number1: "",
  state_income1: 0,
  payer_state_number2: "",
  state_income2: 0,

  number_of_w2_filed: 0,
  total_tax_withheld_for_all_w2s: 0,

  // Direct sales indicator
  direct_sales_indicator: false,

  // Fatca
  fatca_filing_requirement_indicator: false,
};

export default globalFormData;
