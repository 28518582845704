import { axiosInstance } from "../axiosConfig";

export default async function updateForm(correctedData: any) {
  try {
    const { data } = await axiosInstance.post("/update_cart", { ...correctedData });
    return data;
  } catch (err) {
    throw err;
  }
}
