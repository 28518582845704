import { PasswordResetData } from "../../../../interfaces/main";
import { axiosInstance } from "../../axiosConfig";

export default async function resetPassword(data_: PasswordResetData) {
  try {
    const { data } = await axiosInstance.post("/forgot_password/set_new_password", data_);
    return data;
  } catch (err) {
    throw err;
  }
}
