import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./OtpSender.css";
import { useDisableBtn, useResendTimer } from "../../../lib/customHooks";
import { useMemo } from "react";
import devLogInstance from "../../../lib/loggerConfig";
import { EmailValidator } from "../../../lib/validators";
import handlePopup from "../../../lib/popup/handlePopup";
import { EMAIL_SETTING, ERROR_POPUP, SUCCESS_POPUP } from "../../../lib/constants";
import { SHA256 } from "crypto-js";
import Handler from "../../../lib/Handlers/main";

export default function OtpSender({
  verificationData,
  emptyInputs,
  settingType,
  setIsVerificationCodeSent,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  const [toStartTimer, timer] = useResendTimer(false);
  const [toDisableBtn, setToDisableBtn] = useDisableBtn(false);
  const stateSetter = useDispatch();

  useMemo(() => {
    timer === 0 && setToDisableBtn(false);
  }, [timer, setToDisableBtn]);

  const handleClick = async () => {
    devLogInstance.log(verificationData);
    const { email, password } = verificationData;
    try {
      const isValidEmail = await EmailValidator.validateEmail(email);
      devLogInstance.log(`Is valid email ---> ${isValidEmail}`);
      const encryptedPass = SHA256(process.env.REACT_APP_NONCE + password).toString();
      devLogInstance.log(`Encrypted password ---> ${encryptedPass}`);
      // Call the API to get verification code
      const isVerificationCodeSent = await Handler.handleGettingVerificationCode(
        email,
        encryptedPass,
        stateSetter
      );
      devLogInstance.log(`Verification code sent? ---> ${isVerificationCodeSent}`);
      if (isVerificationCodeSent) {
        // Display a success toast message
        const toastMessage =
          settingType === EMAIL_SETTING
            ? "A verification code has been sent to your new email id"
            : "";
        handlePopup(toastMessage, SUCCESS_POPUP, stateSetter);
        // Display the verification code input field
        setIsVerificationCodeSent(true);
        // Start the resend timer and disable the btn
        toStartTimer(true);
        setToDisableBtn(true);
      }
    } catch (err: any) {
      devLogInstance.error(err);
      handlePopup(err.message ? err.message : err, ERROR_POPUP, stateSetter);
    }
  };
  return (
    <main className="otp-sender-main">
      <button
        id="otp"
        className="send-otp-btn"
        onClick={handleClick}
        disabled={toDisableBtn || emptyInputs}
        style={{
          background: style.btnBg,
          color: style.btnFillFontColor,
          opacity: toDisableBtn || emptyInputs ? "0.5" : "1",
        }}
      >
        Send Verification Code
      </button>
      {toDisableBtn && <span>Resend verification code in ({timer})</span>}
    </main>
  );
}
