import React from "react";
import { MdGroupAdd, MdGroupRemove, MdGroups } from "react-icons/md";
import { TiUserDelete } from "react-icons/ti";
import devLogInstance from "../../../lib/loggerConfig";
import displayPayerAssignmentModal from "../../../lib/StateSetters/StaffAndGroupRelated/displayPayerAssignmentModal";
import { useDispatch } from "react-redux";
import displayPayerUnassignmentModal from "../../../lib/StateSetters/StaffAndGroupRelated/displayPayerUnassignmentModal";
import { PayerUnderStaffOrGroup, StaffRecord } from "../../../../interfaces/main";
import displayConfirmationModal from "../../../lib/StateSetters/displayConfirmationModal";
import { REMOVE_STAFF } from "../../../lib/UserActions/action";
import displayViewGroupsModal from "../../../lib/StateSetters/StaffAndGroupRelated/displayViewGroupsModal";

interface Props {
  staffId: string;
  staffName: string;
  staffEmail: string;
  assignedPayers: PayerUnderStaffOrGroup[];
  setStaffRecords: React.Dispatch<React.SetStateAction<StaffRecord[]>>;
}

export default function StaffRelatedActionPopup(props: Props) {
  const dispatch = useDispatch();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const { id } = e.currentTarget;

    switch (id) {
      case "assign-payers":
        devLogInstance.log("Clicked on assign payers!");
        displayPayerAssignmentModal(
          {
            toDisplay: true,
            isStaff: true,
            staffOrGroupId: props.staffId,
            staffName: props.staffName,
            staffEmail: props.staffEmail,
            updateStaffRecords: props.setStaffRecords,
            updateGroupRecords: null,
          },
          dispatch
        );
        break;

      case "unassign-payers":
        devLogInstance.log("Clicked on unassign payers!");
        displayPayerUnassignmentModal(
          {
            toDisplay: true,
            isStaff: true,
            staffOrGroupId: props.staffId,
            staffName: props.staffName,
            staffEmail: props.staffEmail,
            assignedPayers: props.assignedPayers,
            updateStaffRecords: props.setStaffRecords,
            updateGroupRecords: null,
          },
          dispatch
        );
        break;

      case "remove-staff":
        devLogInstance.log("Clicked on remove payer!");
        displayConfirmationModal(dispatch, REMOVE_STAFF, true, props.setStaffRecords, [
          props.staffId,
        ]);
        break;

      case "view-groups":
        devLogInstance.log("Clicked on view groups!");
        displayViewGroupsModal(true, props.staffId, dispatch);
        break;

      default:
        break;
    }
  };

  return (
    <ul
      id="actions-list"
      className="absolute -left-20 top-3 bg-white z-10 w-[13rem] shadow-lg rounded-lg overflow-hidden"
    >
      <li
        id="assign-payers"
        className="py-3 px-4 cursor-pointer flex items-center gap-2 hover:bg-taxeve-primary-violet hover:text-white"
        onClick={handleClick}
      >
        <MdGroupAdd className="text-xl" /> Assign Business'
      </li>
      <li
        id="unassign-payers"
        className="py-3 px-4 cursor-pointer flex items-center gap-2 hover:bg-taxeve-primary-violet hover:text-white"
        onClick={handleClick}
      >
        <MdGroupRemove className="text-xl" /> Unassign Business'
      </li>
      <li
        id="view-groups"
        className="py-3 px-4 cursor-pointer flex items-center gap-2 hover:bg-taxeve-primary-violet hover:text-white"
        onClick={handleClick}
      >
        <MdGroups className="text-2xl" /> View Groups
      </li>
      <li
        id="remove-staff"
        className="py-3 px-4 cursor-pointer flex items-center gap-2 hover:bg-red-500 hover:text-white"
        onClick={handleClick}
      >
        <TiUserDelete className="text-xl" />
        Remove Member
      </li>
    </ul>
  );
}
