import { axiosInstance } from "../lib/axiosConfig";

export default async function registerUser(
  email: string,
  name: string,
  phone: any,
  password: string
) {
  try {
    const res = await axiosInstance.post("/signup", {
      email: `${email}`,
      full_name: `${name}`,
      phonenumber: `${phone}`,
      password: `${password}`,
    });
    return res;
  } catch (err) {
    throw err;
  }
}
