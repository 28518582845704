import React from "react";
import "../styles/PassInstructionPopup.css";
import { useSelector } from "react-redux";
import { passwordSetupInstructions } from "../lib/languagePacks/en-us";

export default function PassInstructionPopup() {
  const style = useSelector((state: any) => state.themeChange);
  return (
    <div
      className="pass-instruction-container-main"
      style={{ background: style.passwordSetupInstructionBg }}
    >
      <h4>{passwordSetupInstructions.instructionTitle}</h4>
      <ul className="instructions-container">
        <li>
          <span>*</span> {passwordSetupInstructions.point1}
        </li>
        <li>
          <span>*</span> {passwordSetupInstructions.point2}
        </li>
        <li>
          <span>*</span> {passwordSetupInstructions.point3}
        </li>
        <li>
          <span>*</span> {passwordSetupInstructions.point4}
        </li>
        <li>
          <span>*</span> {passwordSetupInstructions.point5}
        </li>
      </ul>
    </div>
  );
}
