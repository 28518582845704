import React, {
  useState,
  useEffect,
  useMemo,
  Dispatch,
  useContext,
  useRef,
  LegacyRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import html2canvas from "html2canvas";
import { calCurrentTaxYear } from "./calculateTaxYear";
import { YearSelectionInterface } from "../../interfaces/main";
import devLogInstance from "./loggerConfig";
import toDisplayHammenu from "./StateSetters/HammenuRelated/toDisplayHammenu";
import { ProgressContext } from "../App";
import logger from "../logger";
import { Link, useNavigate } from "react-router-dom";
import handlePopup from "./popup/handlePopup";
import { CSV, ERROR_POPUP, XLSX } from "./constants";
import { progressTexts, uploadPageDisplayTexts } from "./languagePacks/en-us";
import { HOME, REVIEW } from "./routes";
import Handler from "./Handlers/main";
import { FaCheckCircle } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";
import displayInfoModal from "./StateSetters/displayInfoModal";
import InvalidDataModalMsg from "../components/UploadPage/InvalidDataModalMsg";

// hook to disable button
export const useDisableBtn = (
  initial: boolean
): [toDisableBtn: boolean, setToDisableBtn: Dispatch<React.SetStateAction<boolean>>] => {
  const [toDisableBtn, setToDisableBtn] = useState(initial);
  return [toDisableBtn, setToDisableBtn];
};

type ResentTimerReturnType = [toStartTimer: Dispatch<React.SetStateAction<boolean>>, timer: number];

// resend mail timer hook
export const useResendTimer = (initial: boolean): ResentTimerReturnType => {
  const [toStartTimer, setToStartTimer] = useState(initial);
  const [timer, setTimer] = useState(30);
  useMemo(() => {
    if (timer >= 0 && toStartTimer) {
      setTimeout(() => {
        setTimer((prevState) => {
          return prevState - 1;
        });
      }, 1000);
    } else {
      setTimer(30);
    }
    // if (timer === 0) {
    //   setToStartTimer(false);
    // }
  }, [toStartTimer, timer]);

  return [setToStartTimer, timer];
};

export const usePopup = () => {
  const displayPopup = useSelector((state: any) => state.displayPopup);
  const popupMessage = useSelector((state: any) => state.popupMessage);
  const popupStatus = useSelector((state: any) => state.popupStatus);

  return [displayPopup, popupMessage, popupStatus];
};

export const useHover = (): [
  isHovered: any,
  handleHoverIn: (val: string) => void,
  handleHoverOut: (val: string) => void
] => {
  const [isHovered_, setIsHovered] = useState<any>({});

  const handleMouseOver_ = (val: string) => {
    console.log(val);
    const _isHovered = isHovered_;
    _isHovered[val] = true;
    setIsHovered({ ..._isHovered });
  };

  const handleMouseLeave_ = (val: string) => {
    const _isHovered = isHovered_;
    if (_isHovered[val]) {
      _isHovered[val] = false;
    }
    setIsHovered({ ..._isHovered });
  };

  useEffect(() => {
    return () => {
      setIsHovered({});
    };
  }, []);

  return [isHovered_, handleMouseOver_, handleMouseLeave_];
};

export const useInactive = () => {
  const [initialTime, setInitialTime] = useState(new Date().getSeconds());

  const handleInactivity = () => {
    const currentTime = new Date().getSeconds();
    // logger.log(`Current time --> ${currentTime}`);
    // Refresh the page if inactive for 50 seconds
    const inActiveTime = currentTime - initialTime;
    const maxInactiveTime: string | undefined = process.env.REACT_APP_INACTIVE_TIME_PERIOD_SECONDS;
    if (maxInactiveTime) {
      if (inActiveTime >= parseInt(maxInactiveTime)) {
        window.location.reload(); // Refresh the page
      } else {
        setInitialTime(currentTime); // Set the current time as the new initial time
      }
    }
  };

  return handleInactivity;
};

export const usePasswordToggle = (
  initial: boolean
): [showPassword: boolean, toShowPassword: Dispatch<React.SetStateAction<boolean>>] => {
  const [showPassword, toShowPassword] = useState(initial);
  return [showPassword, toShowPassword];
};

/**
 * @module Main_Hook
 * Hook return
 * @typedef {Array} HookReturn
 * @property {string} HookReturn[0] - image string
 * @property {string} HookReturn[1] - take screen shot string
 * @property {object} HookReturn[2] - errors
 */

/**
 * hook for creating screenshot from html node
 * @returns {HookReturn}
 */
export const useScreenshot = ({ type = "", quality = "" } = {}): [
  image: string | null,
  takeScreenshot: (node: any) => Promise<string | void>
] => {
  const [image, setImage] = useState<string | null>(null);
  const [error, setError] = useState(null);
  /**
   * convert html node to image
   * @param {HTMLElement} node
   */
  const takeScreenShot = (node: HTMLElement) => {
    if (!node) {
      throw new Error("You should provide correct html node.");
    }
    return html2canvas(node)
      .then((canvas) => {
        const croppedCanvas = document.createElement("canvas");
        const croppedCanvasContext = croppedCanvas.getContext("2d");
        // init data
        const cropPositionTop = -20;
        const cropPositionLeft = 0;
        const cropWidth = canvas.width;
        const cropHeight = canvas.height;

        croppedCanvas.width = cropWidth;
        croppedCanvas.height = cropHeight;

        croppedCanvasContext?.drawImage(canvas, cropPositionLeft, cropPositionTop);

        const base64Image = croppedCanvas.toDataURL(type, quality);

        setImage(base64Image);
        return base64Image;
      })
      .catch(setError);
  };

  return [image, takeScreenShot];
};

export const useReactSelectTaxYear = (): [
  year: YearSelectionInterface,
  handleYearChange: (option: YearSelectionInterface | null) => void
] => {
  const [year, setYear] = useState({
    label: calCurrentTaxYear(),
    value: calCurrentTaxYear(),
  });

  // Handle year change
  const handleYearChange = (option: YearSelectionInterface | null) => {
    option && setYear(option);
  };

  return [year, handleYearChange];
};

// Convert blob to data url blob
export const useDataURL = (): [
  dataUrl: string,
  setBlob: Dispatch<React.SetStateAction<Blob | null>>
] => {
  const [blob, setBlob] = useState<Blob | null>(null);
  const [dataUrl, setDataUrl] = useState("");

  useEffect(() => {
    if (blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const result = reader.result;
        if (result && typeof result === "string") {
          setDataUrl(result);
        }
      };
    }

    return () => {
      setDataUrl("");
    };
  }, [blob]);

  return [dataUrl, setBlob];
};

// Custom hook to control ham menu
export const useHam = (menutype: string) => {
  const dispatch = useDispatch();
  const displayHammenu: { toDisplay: boolean; menutype: string } = useSelector(
    (state: any) => state.displayHammenu
  );

  useEffect(() => {
    const handleClick = (e: any) => {
      const id = e.target.id;

      if (id && id.includes("HAM")) {
        devLogInstance.log("Clicked on " + id);
        devLogInstance.log(`Menu type: ${menutype}`);

        toDisplayHammenu(dispatch, !displayHammenu.toDisplay, menutype);
      } else {
        toDisplayHammenu(dispatch, false, "");
      }
    };
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [menutype, dispatch, displayHammenu.toDisplay]);

  useEffect(() => {
    return () => {
      toDisplayHammenu(dispatch, false, "");
    };
  }, [dispatch]);
};

export const useInProgress = () => {
  const InProgress = useContext(ProgressContext);

  if (!InProgress) throw new Error("Inprogress context value is null!");

  const [inProgress, setInProgress] = InProgress;

  return { inProgress, setInProgress };
};

export const useMaxScreenHeight = (): number => {
  const [maxScreenHeight, setMaxScreenHeight] = useState(document.documentElement.clientHeight);

  devLogInstance.log(`Max Screen height: ${maxScreenHeight}`);

  useEffect(() => {
    const handleResize = (e: any) => {
      setMaxScreenHeight(document.documentElement.clientHeight);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [maxScreenHeight]);

  return maxScreenHeight;
};

// Hook to calculate an html element height
export const useElemHeight = (): { elemRef: LegacyRef<any>; elemHeight: number } => {
  const elemRef: LegacyRef<HTMLElement> | null = useRef(null);
  const [elemHeight, setElemHeight] = useState(0);

  useEffect(() => {
    const ch = elemRef?.current?.clientHeight;
    ch && setElemHeight(ch + 50);

    return () => setElemHeight(0);
  }, []);

  return { elemRef, elemHeight };
};

export const useYear = (): {
  year: { label: number; value: number };
  handleYearChange: (option: any) => void;
} => {
  const [year, setYear] = useState({
    label: calCurrentTaxYear(),
    value: calCurrentTaxYear(),
  });

  // Year change event handler
  const handleYearChange = (option: any) => {
    // Handle the change
    setYear({
      label: option.label,
      value: option.value,
    });
  };

  return { year, handleYearChange };
};

// Custom hook to manage file upload
export const useFileUpload = ({
  payerId,
  userId,
  formType,
  taxYear,
  payerName,
  noForm,
}: {
  payerId: string;
  userId: string;
  formType: string;
  noForm: boolean;
  taxYear: number;
  payerName?: string;
}): {
  originalFile: File | null;
  handleChange: (file: File) => void;
  handleError: (err: Error) => void;
  handleSizeError: (err: Error) => void;
  handleFileUpload: () => void;
} => {
  const [file, setFile] = useState<File | null>(null); // This is the original file state
  const [fileToUpload, setFileToUpload] = useState<File | null>(null); // This is the file to actually upload
  // state where the name is formatted and then stored
  const dispatch = useDispatch();
  const { setInProgress } = useInProgress();
  const navigate = useNavigate();

  const filenameChange = (file: File, formType: string, payerId: string, userId: string) => {
    const filenameChunks = file.name?.split(".");
    const fileExt = filenameChunks[filenameChunks.length - 1];

    if (formType === "business") {
      // If the form type is business, it means the user is uplaoding
      // a bulk business adding template.
      const newFileName = `Business_Template.${fileExt}`;
      // In that case the name formatting will be pretty simple
      // and different than when an actual form is uploaded.
      const newFileInstance = new File([file], newFileName, {
        type: `${file.type}`,
      });
      return newFileInstance;
    } else if (formType === "recipient") {
      // If the form type is recipient, it means the user is uplaoding
      // a bulk recipient adding template.
      const newFileName = `Recipient_Template.${fileExt}`;
      // In that case the name formatting will be pretty simple
      // and different than when an actual form is uploaded.
      const newFileInstance = new File([file], newFileName, {
        type: `${file.type}`,
      });
      return newFileInstance;
    } else if (formType === "staff" || formType === "group") {
      // No need to format the file name if the user is trying to upload
      // a staff or a group template
      const newFileInstance = new File([file], file.name, { type: `${file.type}` });
      return newFileInstance;
    }

    const newFilenameTemplate = `Form${formType?.replace(
      /-/gi,
      ""
    )}_Template_${userId}_${payerId}.${fileExt}`;

    const newFileInstance = new File([file], newFilenameTemplate, {
      type: `${file.type}`,
    });
    return newFileInstance;
  };

  // Handle when file is dropped to in the dropbox
  const handleChange = (file: File) => {
    logger.log("Uploaded file:");
    logger.log(file);

    // Update the file name
    logger.log("File subclass with updated name");
    logger.log(filenameChange(file, formType, payerId, userId));
    // Update the file state with the original file
    setFile(file);
    // Update the file to upload state
    setFileToUpload(filenameChange(file, formType, payerId, userId));
  };

  const handleError = (err: Error) => {
    devLogInstance.error(err);
    handlePopup("File type is not supported!", ERROR_POPUP, dispatch);
  };

  // Handle any error related to the file size uploaded by the user
  const handleSizeError = (err: Error) => {
    logger.log(err);
    handlePopup("File size too big!", ERROR_POPUP, dispatch);
  };

  // Handle file upload
  const handleFileUpload = async () => {
    try {
      if (fileToUpload) {
        const filenameDivisions = fileToUpload.name.split(".");
        const format = filenameDivisions[filenameDivisions.length - 1];
        // Upload the file
        const uploadOptions = {
          format: format === "xlsx" ? XLSX : CSV,
          payerId: payerId,
          formType: formType.replace(/-/g, "").toUpperCase(),
        };

        setInProgress({
          inProgress: true,
          message: progressTexts.uploadInProgress,
        });

        navigate(HOME);

        const res = await Handler.handleFileUpload(fileToUpload, dispatch, uploadOptions, taxYear);
        devLogInstance.log(res);

        // Set the file state to default
        setFile(null);
        // Set the file to upload state to default
        setFileToUpload(null);

        // End the uplodad progress
        setInProgress({
          inProgress: false,
          message: (
            <div className="flex gap-2 items-center">
              <FaCheckCircle className="text-2xl text-green-600" />
              {progressTexts.uploadComplete}{" "}
              {/* Dislay the 'Go to review page' option only when a form is uploaded */}
              {!noForm && (
                <span>
                  <Link
                    onClick={() => setInProgress({ inProgress: null, message: "" })}
                    to={REVIEW}
                    className="text-taxeve-primary-violet underline-offset-1"
                  >
                    Go to Review Page
                  </Link>
                </span>
              )}
            </div>
          ),
        });
        // Store the payer id in ls if payerId is not empty
        payerId && localStorage.setItem("payer_id", payerId);
      } else {
        // Show an error on the toast message
        handlePopup(uploadPageDisplayTexts.noFileSelectedError, ERROR_POPUP, dispatch);
      }
    } catch (err: any) {
      // Stop the progress container with an error message
      // if the upload fails.
      setInProgress({
        inProgress: false,
        message: (
          <div className="flex gap-2 items-center">
            <RiCloseCircleFill className="text-red-500 text-2xl" />{" "}
            <p>{progressTexts.uploadFailed}</p>
          </div>
        ),
      });
      // Error will be handled by the handler itself
      // Only error related to invalid data will be handled here
      // Display the SNS modal
      err.length >= 0 &&
        displayInfoModal(true, <InvalidDataModalMsg errors={err} />, "Invalid Data", dispatch);
    }
  };

  return {
    originalFile: file,
    handleChange,
    handleError,
    handleSizeError,
    handleFileUpload,
  };
};
