import { getPayerDetails } from "../../backendData/getPayerData";
import logger from "../../../logger";
import retrieveToken from "../../authRequests/retrieveTokens";
import saveTokens from "../../authRequests/saveTokens";
import getRefreshToken from "../../authRequests/getRefreshToken";
import logoutUser from "../../authRequests/logoutUser";

export default async function handleGetPayerDetails(
  payerId: string,
  handleLogut: any,
  recNotNeeded: boolean
) {
  try {
    const data = await getPayerDetails(payerId, recNotNeeded);
    return data;
  } catch (err: any) {
    logger.error(err);
    if (err.response && err.response.status === 401) {
      logger.error(err.response.data.Error);
      // Update the access and refresh token
      const oldRefToken = retrieveToken("refresh_token");
      if (oldRefToken) {
        // Get the new refresh and access tokens
        try {
          const res = await getRefreshToken(oldRefToken);
          logger.log("New Access and Refresh token");
          logger.log(res);

          // Save the new tokens
          await saveTokens(res.accessToken, res.refreshToken);
          logger.log("New Tokens saved");

          // Try getting the payer details using new tokens
          const data = await getPayerDetails(payerId, recNotNeeded);
          return data;
        } catch (err) {
          logger.error(err);
          // If any error is encountered in the try block, logout the user
          logoutUser(handleLogut);
        }
      } else {
        // Logout the user if not initial ref token is found in local storage
        logger.log("No refresh token found in localstorage");
        logoutUser(handleLogut);
      }
    } else {
      // Logout the user if the error response is not 401
      logger.log("Error is not 401");
      logoutUser(handleLogut);
    }
  }
}
