import React from "react";
import { useSelector } from "react-redux";
import devLogInstance from "../../../../lib/loggerConfig";
import { FiDollarSign } from "react-icons/fi";
import IRSDisclaimer from "../FormIRSDisclaimer/IRSDisclaimer";
// import State from "../RFState/State.component";
import AmountHeading from "../../GlobalFormComponents/AmountContainer/AmountHeading/AmountHeading";
import AmountContainer from "../../GlobalFormComponents/AmountContainer/AmountContainer";
import {
  div1099AmountDisplayTexts,
  stateInfoDisplayTexts,
} from "../../../../lib/languagePacks/en-us";
import FormDetail from "../../GlobalFormComponents/InternalComponents/FormDetail";
import StateWithoutIncomeField from "../RFState/StateWithoutIcomeField/StateWithoutIncomeField";

export default function DivRightPortion({
  formType,
  taxYear,
  formName,
  formData,
  ombNum,
  revMonth,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  devLogInstance.log(formData);
  return (
    <div className="right-portion-details">
      <div className="top-section rf-ts">
        <div style={{ width: "35%" }}>
          <AmountContainer
            class_="rf-amt-container" // Only one class can be passed
            style={{
              borderTop: `1px solid ${style.eFormBorderColor}`,
              height: "50%",
              width: "100%",
              padding: "0.5rem 1rem",
            }}
          >
            <AmountHeading heading={div1099AmountDisplayTexts.ordinaryDividends} amountNum="1a" />
            {parseInt(formData?.payment_amount1) !== 0 && (
              <div
                className="amount-values"
                style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
              >
                <FiDollarSign /> {(formData?.payment_amount1 / 100).toFixed(2)}
              </div>
            )}
          </AmountContainer>
          <AmountContainer
            class_="rf-amt-container" // Only one class can be passed
            style={{
              borderTop: `1px solid ${style.eFormBorderColor}`,
              height: "50%",
              width: "100%",
              padding: "0.5rem 1rem",
            }}
          >
            <AmountHeading heading={div1099AmountDisplayTexts.qualifiedDividends} amountNum="1b" />
            {formData?.payment_amount2 !== 0 && (
              <div
                className="amount-values"
                style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
              >
                <FiDollarSign /> {(formData?.payment_amount2 / 100).toFixed(2)}
              </div>
            )}
          </AmountContainer>
        </div>
        <FormDetail
          formType={formType}
          formName={formName}
          taxYear={taxYear}
          ombNum={ombNum}
          revMonth={revMonth}
        />
      </div>
      <div style={{ display: "flex", overflow: "hidden" }}>
        <div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{
                width: "50%",
                borderRight: `1px solid ${style.eFormBorderColor}`,
                padding: "0.5rem 1rem",
              }}
            >
              <AmountHeading heading={div1099AmountDisplayTexts.totalCapitalGain} amountNum="2a" />
              {formData?.payment_amount3 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount3 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{
                width: "50%",
                padding: "0.5rem 1rem",
              }}
            >
              <AmountHeading heading={div1099AmountDisplayTexts.sec1250Gain} amountNum="2b" />
              {formData?.payment_amount6 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount6 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{
                width: "50%",
                borderRight: `1px solid ${style.eFormBorderColor}`,
                padding: "0.5rem 1rem",
              }}
            >
              <AmountHeading heading={div1099AmountDisplayTexts.sec1202Gain} amountNum="2c" />
              {formData?.payment_amount7 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount7 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{
                width: "50%",
                padding: "0.5rem 1rem",
              }}
            >
              <AmountHeading heading={div1099AmountDisplayTexts.collectiblesGain} amountNum="2d" />
              {formData?.payment_amount8 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount8 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{ padding: "0.5rem 1rem", borderRight: "1px solid black", width: "50%" }}
            >
              <AmountHeading heading={div1099AmountDisplayTexts.sec897Div} amountNum="2e" />
              {formData?.payment_amountH !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountH / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{ padding: "0.5rem 1rem", width: "50%" }}
            >
              <AmountHeading heading={div1099AmountDisplayTexts.sec897CapitalGain} amountNum="2f" />
              {formData?.payment_amountJ !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountJ / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
                width: "50%",
                padding: "0.5rem 1rem",
              }}
            >
              <AmountHeading heading={div1099AmountDisplayTexts.nondivDistr} amountNum="3" />
              {formData?.payment_amount9 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount9 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{ padding: "0.5rem 1rem", width: "50%" }}
            >
              <AmountHeading
                heading={div1099AmountDisplayTexts.incomeTaxWithheldDisplayText}
                amountNum="4"
              />
              {formData?.payment_amountA !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountA / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
                width: "50%",
                padding: "0.5rem 1rem",
              }}
            >
              <AmountHeading heading={div1099AmountDisplayTexts.sec199ADiv} amountNum="5" />
              {formData?.payment_amount5 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount5 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{ padding: "0.5rem 1rem", width: "50%" }}
            >
              <AmountHeading heading={div1099AmountDisplayTexts.invstExpenses} amountNum="6" />
              {formData?.payment_amountB !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountB / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
                width: "50%",
                padding: "0.5rem 1rem",
              }}
            >
              <AmountHeading heading={div1099AmountDisplayTexts.foreignTaxPaid} amountNum="7" />
              {formData?.payment_amountC !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountC / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{ padding: "0.5rem 1rem", width: "50%" }}
            >
              <AmountHeading
                heading={div1099AmountDisplayTexts.foreignCountryOrUSPossession}
                amountNum="8"
              />
              {formData?.foreign_country_or_us_Possession && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  {formData?.foreign_country_or_us_Possession}
                </div>
              )}
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              border: `1px solid ${style.eFormBorderColor}`,
              borderLeft: "none",
              borderBottom: "none",
            }}
          >
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
                width: "50%",
                padding: "0.5rem 1rem",
              }}
            >
              <AmountHeading heading={div1099AmountDisplayTexts.cashLiquidDist} amountNum="9" />
              {formData?.payment_amountD !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountD / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{ padding: "0.5rem 1rem", width: "50%" }}
            >
              <AmountHeading heading={div1099AmountDisplayTexts.noncashLiquidDist} amountNum="10" />
              {formData?.payment_amountE !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountE / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              border: `1px solid ${style.eFormBorderColor}`,
              borderLeft: "none",
              height: "100%",
            }}
          >
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{
                width: "50%",
                borderRight: `1px solid ${style.eFormBorderColor}`,
                padding: "0.5rem 1rem",
              }}
            >
              <AmountHeading heading={div1099AmountDisplayTexts.exemptIntDiv} amountNum="12" />
              {formData?.payment_amountF && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountF / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{
                width: "50%",
                padding: "0.5rem 1rem",
              }}
            >
              <AmountHeading
                heading={div1099AmountDisplayTexts.pvtActivityBondInterest}
                amountNum="13"
              />
              {formData?.payment_amountG && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountG / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>
        </div>
        <IRSDisclaimer />
      </div>
      <StateWithoutIncomeField displayTexts={stateInfoDisplayTexts.div1099} formData={formData} />
    </div>
  );
}
