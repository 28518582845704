import React from "react";
import "./AccountInfoPopup.component.css";
import { useDispatch, useSelector } from "react-redux";
import { BiLogOut } from "react-icons/bi";
import { useState } from "react";
import logoutUser from "../../../lib/authRequests/logoutUser";
import { Link, useNavigate } from "react-router-dom";
import { PROFILE_SETTINGS, SWITCH_ACCOUNTS } from "../../../lib/routes";
import displayFeedbackModal from "../../../lib/StateSetters/displayFeedbackModal";
import isUserStaff from "../../../lib/utils/isUserStaff";

interface ComponentProp {
  name: string;
  email: string;
  showFeedbackWindow: boolean;
}

export default function AccountInfoPopup({ name, email, showFeedbackWindow }: ComponentProp) {
  const style = useSelector((state: any) => state.themeChange);
  const [isHovered, setIsHovered] = useState({
    isLogoutHovered: false,
    isFeedbackHovered: false,
  });
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  // const [displayFeedbackModal, toDisplayFeedbackModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Handle logout
  const handleLogout = () => {
    showFeedbackWindow ? displayFeedbackModal(true, dispatch) : logoutUser(dispatch);
  };
  return (
    <div
      className="popup-main-container z-20"
      style={{
        background: style.siteBg,
        boxShadow: `4px 4px 10px ${style.boxShadowColor}`,
      }}
    >
      <div className="triangle-up" />
      <div className="user-details">
        <div>
          <div className="user-name">
            <h3>{name}</h3>
          </div>
          <div className="user-email">
            <em>
              <h4>{email}</h4>
            </em>
          </div>
        </div>
        <Link
          id="account-switch-link"
          to={SWITCH_ACCOUNTS}
          className="text-taxeve-primary-violet font-bold hover:underline hover:underline-offset-2 cursor-pointer"
        >
          Switch
        </Link>
      </div>
      <div className="account-settings-btn-container">
        {!isUserStaff() ? (
          <button
            className="account-settings-btn"
            style={{
              background: isButtonHovered ? style.siteBg : style.btnBg,
              border: `1px solid ${style.btnBg}`,
              color: isButtonHovered ? style.fontColor : style.btnFillFontColor,
            }}
            onMouseEnter={() => {
              setIsButtonHovered(true);
            }}
            onMouseLeave={() => {
              setIsButtonHovered(false);
            }}
            onClick={() => {
              navigate(PROFILE_SETTINGS);
            }}
          >
            Profile Settings
          </button>
        ) : (
          <button
            className="account-settings-btn opacity-50"
            disabled
            style={{
              background: style.btnBg,
              border: `1px solid ${style.btnBg}`,
              color: style.btnFillFontColor,
            }}
          >
            Profile Settings
          </button>
        )}
      </div>
      <div
        className="feedback-option px-6 py-4 text-lg cursor-pointer"
        onClick={() => {
          displayFeedbackModal(true, dispatch);
        }}
        onMouseEnter={() => {
          setIsHovered({
            isLogoutHovered: false,
            isFeedbackHovered: true,
          });
        }}
        onMouseLeave={() => {
          setIsHovered({
            isLogoutHovered: false,
            isFeedbackHovered: false,
          });
        }}
        style={{
          background: isHovered.isFeedbackHovered ? style.ddOptionHover : style.siteBg,
        }}
      >
        Give us a feedback!
      </div>
      <div
        className="logout-container"
        style={{
          background: isHovered.isLogoutHovered ? style.ddOptionHover : style.siteBg,
        }}
        onMouseEnter={() => {
          setIsHovered({
            isFeedbackHovered: false,
            isLogoutHovered: true,
          });
        }}
        onMouseLeave={() => {
          setIsHovered({
            isFeedbackHovered: false,
            isLogoutHovered: false,
          });
        }}
        onClick={handleLogout}
      >
        <p>Logout</p>
        <h1>
          <BiLogOut />
        </h1>
      </div>
    </div>
  );
}
