import React from "react";
import { StaffAccountInfo } from "../../../../interfaces/main";
import { FaPhone } from "react-icons/fa";
import Handler from "../../../lib/Handlers/main";
import { useNavigate } from "react-router-dom";

interface Props extends React.HTMLAttributes<HTMLElement> {
  staffDetails: StaffAccountInfo[];
}

export default function StaffDetailsCard({ staffDetails }: Props) {
  const navigate = useNavigate();

  const handleMemberAccountSwitch = (staffId: string, staffName: string) => {
    Handler.handleSwitchingToMember(staffId, staffName, navigate);
  };

  const handleAcceptingInvite = (staffId: string) => {
    Handler.handleInvitationAccept(staffId);
  };

  return (
    <div className="rounded-xl overflow-hidden shadow-lg w-[25rem]">
      <section className="header bg-taxeve-primary-violet text-white p-4 text-center">
        <h2>Member Account(s)</h2>
      </section>

      <section className="max-h-[29.5rem] overflow-auto">
        {staffDetails.map((staffDetail, i) => (
          <div key={i} className="border-b border-b-gray-300 py-4">
            <section className="body p-6">
              <div>
                <div className="flex items-center gap-2 mb-6">
                  {/* <FaUser className="text-2xl opacity-70" /> */}
                  <p className="text-lg opacity-70">
                    <span className="font-bold">Assigned by :</span>{" "}
                    {staffDetail.parent_id.full_name}
                  </p>
                </div>
                <div className={`flex items-center gap-2 ${staffDetail.staff_phone && "mb-6"}`}>
                  {/* <MdEmail className="text-2xl opacity-70" /> */}
                  <p className="text-lg opacity-70">
                    <span className="font-bold">Assignor's email :</span>{" "}
                    {staffDetail.parent_id.email}
                  </p>
                </div>
                {staffDetail.staff_phone && (
                  <div className="flex items-center gap-2">
                    <FaPhone className="text-2xl opacity-70" />
                    <p className="text-lg opacity-70">{staffDetail.staff_phone}</p>
                  </div>
                )}
              </div>
            </section>
            <section className="switch-to-main p-4 text-center">
              {staffDetail.is_invitation_accepted ? (
                <button
                  id="switch-to-member-btn"
                  onClick={() => handleMemberAccountSwitch(staffDetail._id, staffDetail.staff_name)}
                  className="px-4 py-2 rounded-lg bg-taxeve-primary-violet text-white hover:opacity-90"
                >
                  Switch to member account
                </button>
              ) : (
                <button
                  id="accept-invite-btn"
                  onClick={() => handleAcceptingInvite(staffDetail._id)}
                  className="px-4 py-2 rounded-lg bg-taxeve-primary-violet text-white hover:opacity-90"
                >
                  Accept Invitation
                </button>
              )}
            </section>
          </div>
        ))}
      </section>
    </div>
  );
}
