import React from "react";
import { MdGroupAdd, MdGroupRemove } from "react-icons/md";
import { TiUserDelete } from "react-icons/ti";
import devLogInstance from "../../../lib/loggerConfig";
import displayPayerAssignmentModal from "../../../lib/StateSetters/StaffAndGroupRelated/displayPayerAssignmentModal";
import { useDispatch } from "react-redux";
import displayPayerUnassignmentModal from "../../../lib/StateSetters/StaffAndGroupRelated/displayPayerUnassignmentModal";
import {
  GroupRecord,
  PayerUnderStaffOrGroup,
  RemoveStaffsFromGroupActionPayload,
  StaffUnderGroup,
} from "../../../../interfaces/main";
import displayConfirmationModal from "../../../lib/StateSetters/displayConfirmationModal";
import { DELETE_GROUP } from "../../../lib/UserActions/action";
import displayAddStaffsToGroupModal from "../../../lib/StateSetters/StaffAndGroupRelated/displayAddStaffsToGroupModal";
import displayRemoveStaffsFromGroupModal from "../../../lib/StateSetters/StaffAndGroupRelated/displayRemoveStaffsFromGroupModal";

interface Props {
  groupId: string;
  groupName: string;
  groupDescription: string;
  assignedPayers: PayerUnderStaffOrGroup[];
  assignedStaffs: StaffUnderGroup[];
  setGroupRecords: React.Dispatch<React.SetStateAction<GroupRecord[]>>;
}

export default function GroupRelatedActionPopup(props: Props) {
  const dispatch = useDispatch();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const { id } = e.currentTarget;

    switch (id) {
      case "assign-payers":
        devLogInstance.log("Clicked on assign payers!");
        displayPayerAssignmentModal(
          {
            toDisplay: true,
            isStaff: false,
            staffOrGroupId: props.groupId,
            groupName: props.groupName,
            updateStaffRecords: null,
            updateGroupRecords: props.setGroupRecords,
          },
          dispatch
        );
        break;

      case "unassign-payers":
        devLogInstance.log("Clicked on unassign payers!");
        displayPayerUnassignmentModal(
          {
            toDisplay: true,
            isStaff: false,
            staffOrGroupId: props.groupId,
            groupName: props.groupName,
            assignedPayers: props.assignedPayers,
            updateStaffRecords: null,
            updateGroupRecords: props.setGroupRecords,
          },
          dispatch
        );
        break;

      case "assign-staffs":
        devLogInstance.log("Clicked on assign staffs!");
        displayAddStaffsToGroupModal(true, props.groupId, props.setGroupRecords, dispatch);
        break;

      case "unassign-staffs":
        devLogInstance.log("Clicked on unassign staffs!");
        const payload: RemoveStaffsFromGroupActionPayload = {
          toDisplay: true,
          groupId: props.groupId,
          assignedStaffs: props.assignedStaffs,
          updateGroupRecords: props.setGroupRecords,
        };
        displayRemoveStaffsFromGroupModal(payload, dispatch);
        break;

      case "delete-group":
        devLogInstance.log("Clicked on delete group!");
        displayConfirmationModal(dispatch, DELETE_GROUP, true, props.setGroupRecords, [
          props.groupId,
        ]);
        break;

      default:
        break;
    }
  };

  return (
    <ul
      id="actions-list"
      className="absolute -left-20 top-3 bg-white z-10 w-[13rem] shadow-lg rounded-lg overflow-hidden"
    >
      <li
        id="assign-payers"
        className="py-3 px-4 cursor-pointer flex items-center gap-2 hover:bg-taxeve-primary-violet hover:text-white"
        onClick={handleClick}
      >
        <MdGroupAdd className="text-xl" /> Add Business'
      </li>
      <li
        id="unassign-payers"
        className="py-3 px-4 cursor-pointer flex items-center gap-2 hover:bg-taxeve-primary-violet hover:text-white"
        onClick={handleClick}
      >
        <MdGroupRemove className="text-xl" /> Remove Business'
      </li>
      <li
        id="assign-staffs"
        className="py-3 px-4 cursor-pointer flex items-center gap-2 hover:bg-taxeve-primary-violet hover:text-white"
        onClick={handleClick}
      >
        <MdGroupAdd className="text-xl" /> Add Members
      </li>
      <li
        id="unassign-staffs"
        className="py-3 px-4 cursor-pointer flex items-center gap-2 hover:bg-taxeve-primary-violet hover:text-white"
        onClick={handleClick}
      >
        <MdGroupRemove className="text-xl" /> Remove Members
      </li>
      {/* <li
        id="view-groups"
        className="py-3 px-4 cursor-pointer flex items-center gap-2 hover:bg-taxeve-primary-violet hover:text-white"
        onClick={handleClick}
      >
        <MdGroups className="text-2xl" /> View Groups
      </li> */}
      <li
        id="delete-group"
        className="py-3 px-4 cursor-pointer flex items-center gap-2 hover:bg-red-500 hover:text-white"
        onClick={handleClick}
      >
        <TiUserDelete className="text-xl" />
        Delete Group
      </li>
    </ul>
  );
}
