import React from "react";
import { reviewFormLabels } from "../../../../lib/languagePacks/en-us";

export default function RFRecLocation({ formData }: any) {
  return (
    <div className="rf-child-component">
      <div>
        <h4>{reviewFormLabels.recLocationAndZip}</h4>
      </div>
      <div style={{ color: "#30A2FF" }}>
        <p>
          {formData?.recipient_city} {formData?.recipient_state}{" "}
          {formData?.recipient_is_foreign_address ? formData?.recipient_country : ""}{" "}
          {formData?.recipient_zipcode}
        </p>
      </div>
    </div>
  );
}
