import { REMOVE_CART_PAGE_SELECTED_RECIPIENTS } from "../../redux/actions/action.types";

export default function removeCartPageSelectedRec(
  stateHandler: any,
  payerId: any,
  cartId: any,
  occurence: number
) {
  stateHandler({
    type: REMOVE_CART_PAGE_SELECTED_RECIPIENTS,
    payload: {
      cartIdToRemove: cartId,
      payerId: payerId,
      occurence: occurence,
    },
  });
}
