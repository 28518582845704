import React, { useContext } from "react";
import { PanelOptionContext } from "../../pages/BusinessSettings";

interface Props extends React.HTMLAttributes<HTMLElement> {
  option: string;
  idx: number;
  isSelected: boolean;
}

export default function PanelOption(props: Props) {
  const panelOptionCtx = useContext(PanelOptionContext);

  // If the panel option ctx is not provided throw an error
  if (!panelOptionCtx) throw new Error("Panel option ctx not provided!");

  // Destructure the required context values
  const { selectOption } = panelOptionCtx;

  // This is to handle the functionality when any panel option
  // is clicked. Bind the option idx to panel option ctx value.
  const handleSelection = () => {
    selectOption(props.idx);
  };

  return (
    <div
      id={props.option}
      onClick={handleSelection}
      className={`p-4 font-bold text-lg ${
        !props.isSelected && "hover:bg-taxeve-secondary-violet"
      } cursor-pointer ${props.isSelected && "bg-taxeve-primary-violet text-white"}`}
    >
      {props.option}
    </div>
  );
}
