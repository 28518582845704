import React, { useEffect, useState } from "react";
import ESigningModal from "../../components/OAPRelatedComponents/ESigningRelated/ESigningModal";
import SessionStorage from "../../lib/LocalCaching/SessionStorage";
import { useNavigate } from "react-router-dom";
import { ONLINE_ACCESS_DASHBOARD } from "../../lib/routes";
import { BsFillArrowRightCircleFill, BsTelephoneFill } from "react-icons/bs";
import getFormattedDate from "../../lib/utils/getFormattedDate";
import Handler from "../../lib/Handlers/main";
import { RiContactsBookFill } from "react-icons/ri";
import formatPhonenum from "../../lib/form/utils/formatPhone";
import { AiTwotoneMail } from "react-icons/ai";
import { DO_NOT_GIVE_CONSENT, ERROR_POPUP, GIVE_CONSENT } from "../../lib/constants";
import Convert from "../../lib/utils/Convert";
import devLogInstance, { prodLogInstance } from "../../lib/loggerConfig";
import handlePopup from "../../lib/popup/handlePopup";
import { useDispatch } from "react-redux";
import logoutRecipient from "../../lib/OnlineAccessRelatedUtils/logoutRecipient";

export default function OnlineAccessConsent() {
  const [showSigningModal, toShowSigningModal] = useState(false);
  const [signatureImgDataUrl, setSignatureDataUrl] = useState<string | null>(null);
  const [recName, setRecName] = useState("");
  const [payerName, setPayerName] = useState("");
  const [payerContact, setPayerContact] = useState({
    payerAddress: "",
    payerPhone: "",
    payerEmail: "",
  });
  const [consent, setConsent] = useState({
    giveConsent: false,
    donotGiveConsent: false,
  });
  const [isDisabled, toDisable] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Get the consent page data
  useEffect(() => {
    const getConsentPageData = async () => {
      const { consentData } = await Handler.handleGettingConsentPageData();
      // Save the required info from the data
      setRecName(consentData.RecipientName);
      setPayerName(consentData.PayerName);
      setPayerContact({
        payerAddress: consentData.PayerAddress,
        payerPhone: consentData.PayerPhoneNumber,
        payerEmail: consentData.PayerEmail,
      });
    };
    getConsentPageData();
  }, []);

  // Monitor the consent status to disable/enable the continue button
  useEffect(() => {
    if (consent.giveConsent || consent.donotGiveConsent) {
      toDisable(false);
    } else if (!consent.giveConsent && !consent.donotGiveConsent) {
      toDisable(true);
    }
    return () => {
      toDisable(true);
    };
  }, [consent]);

  // Handle consent submission
  const handleConsentSubmit = async () => {
    try {
      if (!signatureImgDataUrl) {
        // Display a pop up
        handlePopup("Please e-sign the consent document to continue", ERROR_POPUP, dispatch);
        throw new Error("No data url to create file instance!").message;
      }

      // Convert the img data URL to a File instance
      const filename = SessionStorage.getCacheItem("fid");
      const imgFile = await Convert.fromDataURLToFile(
        signatureImgDataUrl,
        `${filename}.png`,
        "image/png"
      );
      devLogInstance.log("Image file");
      devLogInstance.log(imgFile);

      const consentStatus = consent.giveConsent;
      devLogInstance.log(`Consent status: ${consentStatus}`);

      await Handler.handleSubmittingConsentData(imgFile, consentStatus);

      // Update the cached consent status
      consent.giveConsent
        ? SessionStorage.setCacheItem("rec_consent_status", "accepted")
        : SessionStorage.setCacheItem("rec_consent_status", "rejected");

      // redirect to dashboard / logout recipient
      consent.giveConsent ? navigate(ONLINE_ACCESS_DASHBOARD) : logoutRecipient(dispatch);
    } catch (err) {
      prodLogInstance.error(err);
    }
  };

  return (
    <div className="page">
      {showSigningModal && (
        <ESigningModal
          setSignatureDataUrl={setSignatureDataUrl}
          toDisplayModal={toShowSigningModal}
          recName={recName}
        />
      )}
      <section className="text-center py-12 flex justify-center">
        {/* <h1 className="text-3xl">Tax Eve Logo</h1> */}
        <img src="/Assets/taxeve_logo.png" alt="Taxeve" className="w-[10%]" />
      </section>
      <section className="consent-body">
        <h2 className="text-center py-4">CONSENT TO ELECTRONIC DELIVERY OF TAX STATEMENTS</h2>
        <div className="px-40">
          <div className="px-20 py-8 pb-20 text-lg text-slate-700 border-b border-slate-400">
            <p>
              Hello <span className="font-semibold">{recName}</span>,
            </p>
            <br />
            <p>
              The Internal Revenue Service (IRS) has approved the use of electronic tax statements;
              however, recipients need to provide consent to receive their tax statements
              electronically.
            </p>
            <br />
            <p>
              Please read the following information and provide your consent by signing the document
              below.
            </p>
            <br />
            <p>
              <span className="font-semibold">{payerName}</span> is required by the IRS to furnish
              tax statements to the recipients. As a recipient, you may choose to receive your tax
              statements electronically in place of paper format.
            </p>
            <br />
            <p>
              You will have access to your tax statements online through our easy-to-use secure Tax
              Eve portal.
            </p>
            <br />
            <ul className="flex flex-col gap-4 mb-6">
              <p className="font-semibold">
                BENEFITS OF RECEIVING TAX STATEMENTS ELECTRONICALLY ARE:
              </p>
              <li className="list-inside list-disc flex items-center px-4 gap-4">
                <i>
                  <BsFillArrowRightCircleFill />
                </i>
                <p>
                  Secure your identity by consenting to receive electronic statements. This legal
                  document contains your Taxpayer ID Number and annual earning, which may be
                  compromised if it is lost in the postal mail or misplaced.
                </p>
              </li>
              <li className="list-inside list-disc flex items-center px-4 gap-4">
                <i>
                  <BsFillArrowRightCircleFill />
                </i>
                <p>Early access to tax statements than those receiving it via postal mail.</p>
              </li>
              <li className="list-inside list-disc flex items-center px-4 gap-4">
                <i>
                  <BsFillArrowRightCircleFill />
                </i>
                <p>Quick access to the tax statements from anywhere at any time.</p>
              </li>
              <li className="list-inside list-disc flex items-center px-4 gap-4">
                <i>
                  <BsFillArrowRightCircleFill />
                </i>
                <p>The Electronic copy has the same information as a mailed copy.</p>
              </li>
              <li className="list-inside list-disc flex items-center px-4 gap-4">
                <i>
                  <BsFillArrowRightCircleFill />
                </i>
                <p>View and/or print all prior years' tax documents as often as needed.</p>
              </li>
            </ul>

            <hr className=" border-t-0 border-b border-slate-400" />

            <ul className="flex flex-col gap-4 mt-6">
              <p className="font-semibold">DISCLOSURE NOTICE:</p>
              <li className="list-inside list-disc flex items-center px-4 gap-4">
                <i>
                  <BsFillArrowRightCircleFill />
                </i>
                <p>
                  IRS regulations require that recipients must affirmatively consent to receive
                  their Tax Statements electronically. Tax Statements include Form W-2, 1095, 1099
                  and 5498 series.
                </p>
              </li>
              <li className="list-inside list-disc flex items-center px-4 gap-4">
                <i>
                  <BsFillArrowRightCircleFill />
                </i>
                <p>
                  A recipient who consents to receive his/her statement electronically will not
                  receive a paper copy. If a recipient does not agree to electronic delivery, he/she
                  will continue to receive a paper copy of all their Tax Statements.
                </p>
              </li>
              <li className="list-inside list-disc flex items-center px-4 gap-4">
                <i>
                  <BsFillArrowRightCircleFill />
                </i>
                <p>
                  An recipient who elects to receive his/her Tax Statements online can also receive
                  a paper copy of the same by submitting a request to the concerned employer/payer.
                </p>
              </li>
              <li className="list-inside list-disc flex items-center px-4 gap-4">
                <i>
                  <BsFillArrowRightCircleFill />
                </i>
                <p>
                  The recipients' consent to receive the Tax Statements in electronic format will
                  remain in effect until he/she withdraws the consent by contacting your
                  Employer/Payer.
                </p>
              </li>
              <li className="list-inside list-disc flex items-center px-4 gap-4">
                <i>
                  <BsFillArrowRightCircleFill />
                </i>
                <p>
                  One should be aware that even if the Tax Form is provided electronically, they may
                  need to attach it to their annual tax returns, including federal, state, and local
                  tax returns.
                </p>
              </li>
              <li className="list-inside list-disc flex items-center px-4 gap-4">
                <i>
                  <BsFillArrowRightCircleFill />
                </i>
                <p>
                  The hardware and software requirements needed to access the Tax Statements
                  electronically include an internet connection, web browser, and PDF reader
                  software.
                </p>
              </li>
            </ul>
            <br />
            <p className="mb-6">
              It is the recipient's responsibility to notify the employer of any changes to their
              personal contact information. Employers will inform all the future changes in the{" "}
              <span className="font-semibold">{payerName}</span> contact information.
            </p>

            <hr className=" border-t-0 border-b border-slate-400" />

            <p className="mt-6 font-semibold">Employer/Payer Contact Details</p>
            <div className="mb-6 flex gap-20 py-4">
              <div className="flex items-center gap-4 w-[45%]">
                <i className="text-2xl">
                  <RiContactsBookFill />
                </i>
                <span>
                  <p className="font-bold">{payerName},</p>
                  <p>{payerContact.payerAddress}</p>
                </span>
              </div>

              <div className="flex items-center gap-4">
                <i className="text-xl">
                  <BsTelephoneFill />
                </i>
                <p>{formatPhonenum(payerContact.payerPhone)}</p>
              </div>

              <div className="flex items-center gap-4">
                <i className="text-xl">
                  <AiTwotoneMail />
                </i>
                <p>{payerContact.payerEmail}</p>
              </div>
            </div>

            <hr className=" border-t-0 border-b border-slate-400" />

            <div className="mt-6 flex gap-10 px-6">
              <div>
                <input
                  id={GIVE_CONSENT}
                  type="checkbox"
                  checked={consent.giveConsent}
                  onChange={() => {
                    setConsent({
                      donotGiveConsent: false,
                      giveConsent: !consent.giveConsent,
                    });
                  }}
                />
                <label htmlFor={GIVE_CONSENT}>I agree to receive my Tax Forms electronically</label>
              </div>
              <div>
                <input
                  id={DO_NOT_GIVE_CONSENT}
                  type="checkbox"
                  checked={consent.donotGiveConsent}
                  onChange={() => {
                    setConsent({
                      giveConsent: false,
                      donotGiveConsent: !consent.donotGiveConsent,
                    });
                  }}
                />
                <label htmlFor={DO_NOT_GIVE_CONSENT}>
                  I DO NOT agree to receive my Tax Forms electronically
                </label>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-20 px-40 text-lg flex justify-between">
        <div className="flex gap-4 items-center">
          <span className="font-semibold">Name:</span>
          <input type="text" value={recName} className="py-1" disabled />
        </div>
        <div className="flex gap-4 items-center justify-center">
          <span className="font-semibold">Signature:</span>
          {signatureImgDataUrl ? (
            <img src={signatureImgDataUrl} alt="signature" className="w-[40%]" />
          ) : (
            <button
              className="primary-button py-2 px-4 rounded-lg"
              onClick={() => {
                toShowSigningModal(true);
              }}
            >
              Click to e-sign
            </button>
          )}
        </div>
        <div className="flex gap-4 items-center">
          {" "}
          <span className="font-semibold">Date:</span>
          {getFormattedDate()}
        </div>
      </section>
      <section className="py-10 text-center">
        <button
          className={`secondary-button py-3 px-7 text-lg ${isDisabled && "opacity-40"}`}
          onClick={handleConsentSubmit}
          disabled={isDisabled}
        >
          Continue
        </button>
      </section>
    </div>
  );
}
