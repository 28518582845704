import { SetTransmissionStatusBody } from "../../../interfaces/main";
import { axiosInstance } from "../axiosConfig";
import devLogInstance, { prodLogInstance } from "../loggerConfig";

export default async function setTransmissionStatus(data_: SetTransmissionStatusBody) {
  try {
    const { data } = await axiosInstance.post("/admin/set_transmission_status", data_);
    devLogInstance.log("Response from server after submitting the transmission status");
    devLogInstance.log(data);
    return data;
  } catch (err) {
    prodLogInstance.error(err);
    throw err;
  }
}
