import React, { useLayoutEffect, useState } from "react";
import Navbar from "../../components/navbar";
import Handler from "../../lib/Handlers/main";
import Select from "react-select";
import { useReactSelectTaxYear } from "../../lib/customHooks";
import ReactSelectOptions from "../../lib/utils/ReactSelectOptions";
import { useSelector } from "react-redux";
import SelectStyle from "../../lib/ReactSelectTheme/reactSelectStyle";
import PdfViewer from "../../components/OAPRelatedComponents/PdfViewer";
import { Action } from "../../lib/utils/Action";
import { ONLINE_ACCESS_PDF_PREVIEW } from "../../lib/constants";

export default function OnlineAccessDashboard() {
  const [formData, setFormData] = useState<any | null>(null);
  const [year, handleYearChange] = useReactSelectTaxYear();
  const style = useSelector((state: any) => state.themeChange);

  // console.log(formData);

  useLayoutEffect(() => {
    // Get the onlice access form data
    const getFormData = async () => {
      try {
        const formData = await Handler.handleGettingOnlineAcessFormData();

        setFormData(formData);
      } catch (err) {
        // Error in the handler function will be handled by itself
      }
    };

    getFormData();

    return () => {
      setFormData(null);
    };
  }, []);

  // Handle download
  const handleDownload = async () => {
    Action.formDownload({ type: ONLINE_ACCESS_PDF_PREVIEW });
  };

  return (
    <div className="page">
      <Navbar />
      <div className="pt-20 min-h-[100vh]">
        <section className="py-7 px-10 flex justify-between items-center">
          {/* <input className="py-2" type="text" value={formData?.payer_name ?? ""} disabled /> */}
          <div className="flex flex-col gap-2">
            <div className="text-lg">
              <label className="font-bold px-1">Business: </label>
              {formData?.payer_name}
            </div>

            <div className="text-lg">
              <label className="font-bold px-1">Form: </label>
              {formData?.form_type}
            </div>
          </div>

          <div className="flex items-center w-[30%]">
            <div className="flex items-center justify-center gap-2 w-[65%]">
              <label className="font-bold">Tax year:</label>
              <div className="w-[50%]">
                <Select
                  value={year}
                  options={ReactSelectOptions.yearOptions()}
                  theme={new SelectStyle(style).customTheme}
                  styles={new SelectStyle(style).customStyle}
                  onChange={handleYearChange}
                  isSearchable={false}
                  isDisabled={true} // TODO: Make it false when backend supports dynamic year
                />
              </div>
            </div>

            <button className="px-4 py-2 primary-button" onClick={handleDownload}>
              Download
            </button>
          </div>
        </section>

        <section className="px-10 border border-slate-300 flex">
          {/* pdf viewer */}
          <PdfViewer />
        </section>
      </div>
    </div>
  );
}
