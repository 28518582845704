import React from "react";
import {
  FEDERAL_EFILE_STATUS,
  ONLINE_ACCESS_STATUS,
  POSTAL_MAILING_STATUS,
  STATE_EFILE_STATUS,
} from "../../../lib/constants";

interface Props extends React.HTMLAttributes<HTMLElement> {
  statusType: string;
}

const infoTextMap: Record<string, string> = {
  [FEDERAL_EFILE_STATUS]: "All pending forms will be transmitted within 24 hours",
  [STATE_EFILE_STATUS]: "All pending forms will be transmitted within 24 hours",
  [POSTAL_MAILING_STATUS]: "Postal Mails will be sent once IRS accepts the form",
  [ONLINE_ACCESS_STATUS]: "Online Access will be granted once IRS accepts the form",
};

export default function FormStatusInfoBody(props: Props) {
  return (
    <div className="p-6">
      <h2>{infoTextMap[props.statusType]}</h2>
    </div>
  );
}
