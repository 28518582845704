import ReactGA from "react-ga4";
import { GA4EventOptions } from "../../../../interfaces/main";

class Analytics {
  public sendGA4Event(options: GA4EventOptions) {
    ReactGA.event(options);
  }
  public sendGA4PageHits(pagePath: string) {
    // const pagePathSplits = pagePath.split("/");
    // const pageName = pagePathSplits.length > 0 ? pagePathSplits[pagePathSplits.length - 1] : "landing page";
    ReactGA.send({
      hitType: "pageview",
      page: pagePath,
      title: pagePath,
    });
  }
}

export class GA4 extends Analytics {
  private trackId: string = "";

  constructor(trackId: string) {
    super();
    this.trackId = trackId;
  }

  public initialize() {
    ReactGA.initialize(this.trackId);
  }

  public sendEvent(options: GA4EventOptions) {
    this.sendGA4Event(options);
  }
  public sendPageHit(path: string) {
    this.sendGA4PageHits(path);
  }
  public reset() {
    ReactGA.reset();
  }
}
