// import React from "react";

export default function redirect(pathname: string) {
  // Create an anchor tag element and assign certain attrs
  const a = Object.assign(document.createElement("a"), {
    href: pathname,
    style: "display:none",
  });
  // Append the anchor tag
  document.body.appendChild(a);
  // Click the anchor tag
  a.click();
  a.remove();
}
