import React from "react";
import { recipientDetails, recipientDetailsFor1099S } from "../../../../lib/languagePacks/en-us";
import formatTin from "../../../../lib/form/utils/formatTin";
import { FORM1099_S } from "../../../../lib/constants";
import { useSelector } from "react-redux";
import { isEin } from "../../../../lib/utils/maskTin";
import maskTin from "../../../../lib/utils/maskTin";

export default function RFRecTin({ formData, styles }: any) {
  const toMaskTin = useSelector((state: any) => state.toMaskTin);
  return (
    <div style={{ width: "50%", ...styles }} className="tin-container">
      <div>
        <h4>
          {formData?.form_type === FORM1099_S
            ? recipientDetailsFor1099S.recipientTinDisplayText
            : recipientDetails.recipientTinDisplayText}
        </h4>
      </div>
      <div style={{ color: "#30A2FF" }}>
        <p>
          {formData?.recipient_tin &&
            `${formData?.recipient_tin_type}: ${
              !isEin(formData?.recipient_tin_type)
                ? toMaskTin
                  ? maskTin(
                      formatTin(formData?.recipient_tin, formData?.recipient_tin_type),
                      formData?.recipient_tin_type
                    )
                  : formatTin(formData?.recipient_tin, formData?.recipient_tin_type)
                : formatTin(formData?.recipient_tin, formData?.recipient_tin_type)
            }`}
        </p>
      </div>
    </div>
  );
}
