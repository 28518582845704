import React, { useState, useEffect } from "react";
import { CORRECTION_CORRECTED, CORRECTION_VOID } from "../../../../lib/constants";
import devLogInstance from "../../../../lib/loggerConfig";
import { useDispatch } from "react-redux";
import setCorrectionSubmissionType from "../../../../lib/StateSetters/CorrectionRelated/setCorrectionSubmissionType";

export default function CorrectionSubmissionType() {
  const [selected, isSelected] = useState({
    void: false,
    corrected: false,
  });
  const dispatch = useDispatch();

  // Handle change event
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id: type } = e.currentTarget;

    switch (type) {
      case CORRECTION_VOID:
        isSelected({
          void: !selected.void,
          corrected: false,
        });
        break;

      case CORRECTION_CORRECTED:
        isSelected({
          void: false,
          corrected: !selected.corrected,
        });
        break;

      default:
        break;
    }
  };

  // Set the correction file submission type based on the type selection
  useEffect(() => {
    if (selected.void) {
      // file submission type: void
      devLogInstance.log("Correction file submission type: VOID");
      setCorrectionSubmissionType("void", dispatch);
      return;
    } else if (selected.corrected) {
      // file submission type: corrected
      devLogInstance.log("Correction file submission type: CORRECTED");
      setCorrectionSubmissionType("corrected", dispatch);
      return;
    }
    // file submission type: null
    devLogInstance.log("Correction file submission type: NULL");
    setCorrectionSubmissionType(null, dispatch);

    return () => {
      // Set the file submission type to null on component destroy
      setCorrectionSubmissionType(null, dispatch);
    };
  }, [selected, dispatch]);

  return (
    <section className="pb-4 flex justify-center items-center gap-8">
      <div className="flex items-center void">
        <input
          id={CORRECTION_VOID}
          type="checkbox"
          checked={selected.void}
          onChange={handleChange}
        />
        <label htmlFor={CORRECTION_VOID}>
          <h2>Void</h2>
        </label>
      </div>
      <div className="flex items-center corrected">
        <input
          id={CORRECTION_CORRECTED}
          type="checkbox"
          checked={selected.corrected}
          onChange={handleChange}
        />
        <label htmlFor={CORRECTION_CORRECTED}>
          <h2>Corrected</h2>
        </label>
      </div>
    </section>
  );
}
