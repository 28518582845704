import { axiosInstance } from "../axiosConfig";

export default async function submitForm(data_: any) {
  try {
    const { data } = await axiosInstance.post("/submit_form", {
      ...data_,
    });
    return data;
  } catch (err) {
    throw err;
  }
}
