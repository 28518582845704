import React from "react";
import { useSelector } from "react-redux";
import "./EmailRequiredModal.css";
import EmailRequiredCard from "./EmailRequiredCard/EmailRequiredCard";

export default function EmailRequiredModal({ dataArray, payerId, cartIds }: any) {
  const style = useSelector((state: any) => state.themeChange);
  return (
    <div className="erm-container" style={{ background: "rgba(122, 122, 122, 0.6)" }}>
      <EmailRequiredCard dataArray={dataArray} payerId={payerId} cartIds={cartIds} />
    </div>
  );
}
