import { addressLine_1_and_2_limit, addressLineTriggerLimit } from "../../../constants";
import { addressLineWarning } from "../../../languagePacks/en-us";

export default function validateAddressLineLimit(addressLine1: any, addressLine2: any) {
  const currentTotalChars = totalChars(addressLine1, addressLine2);

  if (
    currentTotalChars >= addressLineTriggerLimit &&
    currentTotalChars < addressLine_1_and_2_limit
  ) {
    const remainingChars = addressLine_1_and_2_limit - currentTotalChars;
    return {
      status: true,
      remainingChars: remainingChars,
      messagePt1: addressLineWarning.nec1099.message_pt1,
      messagePt2: addressLineWarning.nec1099.message_pt2,
    };
  } else if (currentTotalChars > addressLine_1_and_2_limit) {
    return {
      status: true,
      remainingChars: 0,
      messagePt1: addressLineWarning.nec1099.exceed_msg,
      messagePt2: "",
    };
  } else {
    return {
      status: false,
      remainingChars: null,
      messagePt1: "",
      messagePt2: "",
    };
  }
}

function totalChars(addressLine1: any, addressLine2: any) {
  return addressLine1.length + addressLine2.length;
}
