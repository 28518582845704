import React, { useEffect, useState } from "react";
import Handler from "../../lib/Handlers/main";
import Select from "react-select";
import SelectStyle from "../../lib/ReactSelectTheme/reactSelectStyle";
import { useSelector } from "react-redux";
import { SetTransmissionStatusBody, TransmissionStatusSelect } from "../../../interfaces/main";
import { FIRE_FILENAME, TAXEVE_FILENAME } from "../../lib/constants";

const transmissionOptions = [
  {
    label: "Awaiting Transmission",
    value: "AwaitingTransmission",
  },
  {
    label: "Transmitted",
    value: "Transmitted",
  },
  {
    label: "Accepted",
    value: "Accepted",
  },
  {
    label: "Rejected",
    value: "Rejected",
  },
];

export default function FireFiling() {
  const style = useSelector((state: any) => state.themeChange);
  const [transmissionStatus, setTransmissionStatus] = useState<TransmissionStatusSelect | null>(
    null
  );
  const [transmissionStatusBody, setBody] = useState<SetTransmissionStatusBody>({
    taxEve_file_name: "",
    fire_file_name: "",
    status: "",
  });
  const [isSubmitBtnDisabled, disableSubmitBtn] = useState(true);

  // Validate the input fields in real time
  useEffect(() => {
    const isValidStatus = transmissionStatusBody.status.length > 0;
    const isValidTaxeveFilename = transmissionStatusBody.taxEve_file_name.length > 0;
    const isValidFireFilename = transmissionStatusBody.fire_file_name.length > 0;

    if (isValidFireFilename && isValidTaxeveFilename && isValidStatus) {
      disableSubmitBtn(false);
      return;
    }
    disableSubmitBtn(true);
  }, [transmissionStatusBody]);

  // Handle ascii file download
  const handleAsciiFileDownload = async () => {
    try {
      await Handler.handleAsciiFileDownload();
    } catch (err) {
      // Error will be handled by error handler
    }
  };

  // Handle transmission status change
  const handleTransmissionStatusChange = (option: TransmissionStatusSelect | null) => {
    if (!option) return;
    setTransmissionStatus(option);
    setBody({
      ...transmissionStatusBody,
      status: option.value,
    });
  };

  // Handle change event
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id: type, value } = e.currentTarget;

    switch (type) {
      case TAXEVE_FILENAME:
        setBody({
          ...transmissionStatusBody,
          taxEve_file_name: value,
        });
        break;

      case FIRE_FILENAME:
        setBody({
          ...transmissionStatusBody,
          fire_file_name: value,
        });
        break;

      default:
        break;
    }
  };

  // Handle submit
  const handleSubmit = async () => {
    // Handle form submit
    try {
      await Handler.handleSettingTransmissionStatus(transmissionStatusBody);
    } catch (err) {
      // handled by handler
    }
  };

  return (
    <div className="px-40 py-20 page">
      <section className="px-10 py-10 get-ascii-file">
        <button className="primary-button py-4 px-6" onClick={handleAsciiFileDownload}>
          Download ASCII File
        </button>
      </section>

      <section className="px-10 py-10 set-transmission-status">
        <h2 className="underline underline-offset-2 mb-6">Set the transmission status</h2>
        <div className="flex flex-col gap-6">
          <div className="w-[23%]">
            <Select
              theme={new SelectStyle(style).customTheme}
              styles={new SelectStyle(style).customStyle}
              options={transmissionOptions}
              placeholder="Select transmission status"
              onChange={handleTransmissionStatusChange}
              value={transmissionStatus}
              isSearchable={false}
            />
          </div>
          <input
            id={TAXEVE_FILENAME}
            type="text"
            placeholder="TaxEve Filename"
            className="w-[30%] py-2"
            onChange={handleChange}
            value={transmissionStatusBody.taxEve_file_name}
          />
          <input
            id={FIRE_FILENAME}
            type="text"
            placeholder="Fire Filename"
            className="w-[30%] py-2"
            onChange={handleChange}
            value={transmissionStatusBody.fire_file_name}
          />
          <button
            className={`primary-button py-4 px-4 w-[10%] ${isSubmitBtnDisabled && "opacity-50"}`}
            onClick={handleSubmit}
            disabled={isSubmitBtnDisabled}
          >
            Submit
          </button>
        </div>
      </section>
    </div>
  );
}
