import React from "react";
import { useSelector } from "react-redux";
import AmountContainer from "../../GlobalFormComponents/AmountContainer/AmountContainer";
import AmountHeading from "../../GlobalFormComponents/AmountContainer/AmountHeading/AmountHeading";
import { b1099AmountDisplayTexts } from "../../../../lib/languagePacks/en-us";
import FormDetail from "../../GlobalFormComponents/InternalComponents/FormDetail";
import IRSDisclaimer from "../FormIRSDisclaimer/IRSDisclaimer";
import { organizeDate } from "../Form1099SRightPortion/SRightPortion";
import { FiDollarSign } from "react-icons/fi";

export default function BRightPortion({
  formType,
  taxYear,
  formName,
  formData,
  ombNum,
  revMonth,
}: any) {
  const style = useSelector((state: any) => state.themeChange);

  const onChangeHandler = () => {};
  return (
    <div className="right-portion-details">
      <div className="top-section">
        <AmountContainer
          class_="misc-info" // Only one class can be passed
          style={{
            borderTop: `1px solid ${style.eFormBorderColor}`,
            width: "40%",
            justifyContent: "start",
          }}
        >
          <AmountHeading heading={b1099AmountDisplayTexts.applicableCheckboxForm8949} />
          {formData?.applicable_check_box_of_form_8949 && (
            <div
              className="amount-values"
              style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
            >
              {formData?.applicable_check_box_of_form_8949}
            </div>
          )}
        </AmountContainer>

        <FormDetail
          formType={formType}
          taxYear={taxYear}
          formName={formName}
          ombNum={ombNum}
          revMonth={revMonth}
          newVersion={true}
        />
      </div>
      <div style={{ display: "flex", overflow: "hidden" }}>
        <div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="misc-info" // Only one class can be passed
            >
              <AmountHeading heading={b1099AmountDisplayTexts.propertyDescription} amountNum="1a" />
              {formData?.description_of_property && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  {formData?.description_of_property}
                </div>
              )}
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <AmountHeading heading={b1099AmountDisplayTexts.dateAcquired} amountNum="1b" />
              {formData?.date_acquired && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  {organizeDate(formData.date_acquired)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="misc-info" // Only one class can be passed
            >
              <AmountHeading heading={b1099AmountDisplayTexts.dateSold} amountNum="1c" />
              {formData?.date_sold_or_disposed && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  {organizeDate(formData.date_sold_or_disposed)}
                </div>
              )}
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="payer-direct-sales" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
                gap: "0.5rem",
              }}
            >
              <AmountHeading heading={b1099AmountDisplayTexts.proceeds} amountNum="1d" />
              {formData?.payment_amount2 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount2 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="misc-info" // Only one class can be passed
            >
              <AmountHeading heading={b1099AmountDisplayTexts.costOrOtherBasis} amountNum="1e" />
              {formData?.payment_amount3 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount3 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <AmountHeading
                heading={b1099AmountDisplayTexts.accruedMarketDiscount}
                amountNum="1f"
              />
              {formData?.payment_amountD !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountD / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="misc-info" // Only one class can be passed
            >
              <AmountHeading
                heading={b1099AmountDisplayTexts.washSaleLossDisallowed}
                amountNum="1g"
              />
              {formData?.payment_amount5 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount5 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
                //   flexDirection: "row",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <AmountHeading
                  heading={b1099AmountDisplayTexts.shortTermGainOrLoss}
                  amountNum="2"
                />
                <input
                  type="checkbox"
                  checked={
                    formData?.type_of_gain_or_loss_indicator === "1" ||
                    formData?.type_of_gain_or_loss_indicator === "3"
                  }
                  onChange={onChangeHandler}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1rem",
                }}
              >
                <AmountHeading heading={b1099AmountDisplayTexts.longTermGainOrLoss} />
                <input
                  type="checkbox"
                  checked={
                    formData?.type_of_gain_or_loss_indicator === "2" ||
                    formData?.type_of_gain_or_loss_indicator === "4"
                  }
                  onChange={onChangeHandler}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1rem",
                }}
              >
                <AmountHeading heading={b1099AmountDisplayTexts.ordinary} />
                <input
                  type="checkbox"
                  checked={
                    formData?.type_of_gain_or_loss_indicator === "3" ||
                    formData?.type_of_gain_or_loss_indicator === "4"
                  }
                  onChange={onChangeHandler}
                />
              </div>
            </AmountContainer>
            <AmountContainer
              class_="misc-info" // Only one class can be passed
            >
              <AmountHeading
                heading={`${b1099AmountDisplayTexts.checkProceedsFromHeading}:`}
                amountNum="3"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1rem",
                }}
              >
                <AmountHeading heading={b1099AmountDisplayTexts.collectibles} />
                <input
                  type="checkbox"
                  checked={formData?.applicable_check_box_for_collectables}
                  onChange={onChangeHandler}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1rem",
                }}
              >
                <AmountHeading heading={b1099AmountDisplayTexts.qof} />
                <input
                  type="checkbox"
                  checked={formData?.applicable_check_box_for_qof}
                  onChange={onChangeHandler}
                />
              </div>
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <AmountHeading heading={b1099AmountDisplayTexts.taxWithheld} amountNum="4" />
              {formData?.payment_amount4 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount4 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{ flexDirection: "row", alignItems: "center" }}
            >
              <AmountHeading
                heading={b1099AmountDisplayTexts.nonCoveredSecurityCheck}
                amountNum="5"
              />
              <input
                type="checkbox"
                checked={formData?.noncovered_security_indicator}
                onChange={onChangeHandler}
              />
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <AmountHeading
                heading={`${b1099AmountDisplayTexts.reportedToIRSHeading}:`}
                amountNum="6"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1rem",
                }}
              >
                <AmountHeading heading={b1099AmountDisplayTexts.grossProceeds} />
                <input
                  type="checkbox"
                  checked={formData?.gross_proceeds_indicator === "1"}
                  onChange={onChangeHandler}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1rem",
                }}
              >
                <AmountHeading heading={b1099AmountDisplayTexts.netProceeds} />
                <input
                  type="checkbox"
                  checked={formData?.gross_proceeds_indicator === "2"}
                  onChange={onChangeHandler}
                />
              </div>
            </AmountContainer>
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{ flexDirection: "row", alignItems: "center" }}
            >
              <AmountHeading heading={b1099AmountDisplayTexts.lossNotAllowedCheck} amountNum="7" />
              <input
                type="checkbox"
                checked={formData?.loss_not_allowed_indicator}
                onChange={onChangeHandler}
              />
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <AmountHeading heading={b1099AmountDisplayTexts.realizedProfitOrLoss} amountNum="8" />
              {formData?.payment_amount9 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount9 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <AmountHeading
                heading={b1099AmountDisplayTexts.unrealizedProfitOrLoss.openContracts2022}
                amountNum="9"
              />
              {formData?.payment_amountA !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountA / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <AmountHeading
                heading={b1099AmountDisplayTexts.unrealizedProfitOrLoss.openContracts2023}
                amountNum="10"
              />
              {formData?.payment_amountB !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountB / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <AmountHeading
                heading={b1099AmountDisplayTexts.aggregateProfitOrLoss}
                amountNum="11"
              />
              {formData?.payment_amountC !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountC / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <AmountHeading
                heading={b1099AmountDisplayTexts.basisReportedToIRSCheck}
                amountNum="12"
              />
              <input
                type="checkbox"
                checked={formData?.basis_reported_to_irs}
                onChange={onChangeHandler}
              />
            </AmountContainer>
            <AmountContainer
              class_="misc-info" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <AmountHeading heading={b1099AmountDisplayTexts.bartering} amountNum="13" />
              {formData?.payment_amount7 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount7 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>
        </div>
        <IRSDisclaimer toPutBorderbottom={true} />
      </div>
    </div>
  );
}
