import React from "react";
import { useSelector } from "react-redux";
import FormDetail from "../../GlobalFormComponents/InternalComponents/FormDetail";
import AmountContainer from "../../GlobalFormComponents/AmountContainer/AmountContainer";
import AmountHeading from "../../GlobalFormComponents/AmountContainer/AmountHeading/AmountHeading";
import {
  nec1099AmountDisplayTexts,
  stateInfoDisplayTexts,
} from "../../../../lib/languagePacks/en-us";
import { FiDollarSign } from "react-icons/fi";
import PayerDirectSales from "../../GlobalFormComponents/InternalComponents/PayerDirectSales/PayerDirectSales";
import State from "../RFState/State";
import IRSDisclaimer from "../FormIRSDisclaimer/IRSDisclaimer";
import { useEffect } from "react";
import { FORM1099_NEC } from "../../../../lib/constants";
import devLogInstance from "../../../../lib/loggerConfig";

export default function NecRightPortion({
  formType,
  taxYear,
  formName,
  formData,
  ombNum,
  revMonth,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  useEffect(() => {
    devLogInstance.log(formData);
  }, [formData]);
  return (
    <div className="right-portion-details rf-right-portion">
      <div className="top-section rf-ts">
        <div
          className="amount-container blank"
          style={{
            background: "#F5F5F5",
            width: "10rem",
            borderTop: `1px solid ${style.eFormBorderColor}`,
          }}
        />
        <FormDetail
          formType={formType}
          formName={formName}
          taxYear={taxYear}
          ombNum={ombNum}
          revMonth={revMonth}
        />
      </div>
      <div style={{ display: "flex" }}>
        <div className="amounts">
          <AmountContainer
            class_="rf-amt-conatiner"
            style={{
              borderBottom: `1px solid ${style.eFormBorderColor}`,
              borderRight: `1px solid ${style.eFormBorderColor}`,
              padding: "0.5rem 1rem",
              width: "100%",
            }}
          >
            <AmountHeading
              heading={nec1099AmountDisplayTexts.compensationHeading}
              amountNum={"1"}
            />
            {formData?.payment_amount1 !== 0 && (
              <div
                className="amount-value"
                style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
              >
                <FiDollarSign /> {(formData?.payment_amount1 / 100).toFixed(2)}
              </div>
            )}
          </AmountContainer>
          <PayerDirectSales
            style_={{ padding: "0.5rem 1rem", fontSize: "0.85rem" }}
            status={formData?.direct_sales_indicator}
            onChangeHandler={() => {}}
            fieldId="2"
          />
          <div
            className="blank amount-container"
            style={{
              height: "8vh",
              borderBottom: `1px solid ${style.eFormBorderColor}`,
              borderRight: `1px solid ${style.eFormBorderColor}`,
              background: "#F5F5F5",
              padding: "0.5rem 1rem",
              width: "100%",
            }}
          >
            <h4>3.</h4>
          </div>
          <AmountContainer
            class_="rf-amt-conatiner" // Only one class can be passed
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              padding: "0.5rem 1rem",
              width: "100%",
            }}
          >
            <AmountHeading
              heading={nec1099AmountDisplayTexts.incomeTaxWithheldDisplayText}
              amountNum="4"
            />
            {formData?.payer_amount4 !== 0 && (
              <div
                className="amount-value"
                style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
              >
                <FiDollarSign /> {(formData?.payment_amount4 / 100).toFixed(2)}
              </div>
            )}
          </AmountContainer>
        </div>
        <IRSDisclaimer />
      </div>
      <State
        displayTexts={stateInfoDisplayTexts.nec1099}
        formData={formData}
        formType={FORM1099_NEC}
      />
    </div>
  );
}
