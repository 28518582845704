import { SET_CART_PAGE_BULK_ACTIONS } from "../../redux/actions/action.types";

export default function setCartPageBulkActionPayload(
  stateSetter: any,
  bulkActionType: string | null,
  recsToExclude?: any
) {
  stateSetter({
    type: SET_CART_PAGE_BULK_ACTIONS,
    payload: {
      bulkActionType: bulkActionType,
      recsToExclude: recsToExclude,
    },
  });
}
