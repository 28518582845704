export const SET_THEME_DETAILS = "SET_THEME_DETAILS";
export const SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED";

// Popup
export const DISPLAY_POPUP = "DISPLAY_POPUP";
export const SET_POPUP_MESSAGE = "SET_POPUP_MESSAGE";
export const SET_POPUP_STATUS = "SET_POPUP_STATUS";

// Payer Data
export const SET_PAYER_NAMES = "SET_PAYER_NAMES";

// Recipient Data
export const SET_RECIPIENT_NAMES = "SET_RECIPIENT_NAMES";

// Tin
export const SET_PAYER_TIN_DISPLAY = "SET_PAYER_TIN_DISPLAY";
export const SET_RECIPIENT_TIN_DISPLAY = "SET_RECIPIENT_TIN_DISPLAY";

// ZIP code
export const SET_ZIPCODE = "SET_ZIPCODE";
export const PAYER_ZIP = "PAYER_ZIP";
export const REC_ZIP = "REC_ZIP";

// Phone number
export const SET_PHONE_DISPLAY = "SET_PHONE_DISPLAY";
export const PAYER_PHONE = "PAYER_PHONE";
export const REC_PHONE = "REC_PHONE";

// Screen Disabler
export const SET_TO_DISABLE_SCREEN = "SET_TO_DISABLE_SCREEN";

// Form clear
export const TO_CLEAR = "TO_CLEAR";

// State related data
export const SET_STATE_AMOUNT = "SET_STATE_AMOUNT";

// Foreign address
export const TO_SHOW_FA_WARNING = "TO_SHOW_FA_WARNING"; // Foreign address limit warning
export const SET_FA_WARN_MSG = "SET_FA_WARN_MSG";

// Address line
export const SET_AL_WARN_MSG = "SET_AL_WARN_MSG";
export const TO_SHOW_AL_WARNING = "TO_SHOW_AL_WARNING";

// Form fields validation  error
export const TO_SHOW_FORM_VALIDATION_ERR = "TO_SHOW_FORM_VALIDATION_ERR";

// Modal
export const TO_DISPLAY_MODAL = "TO_DISPLAY_MODAL";
export const SET_MODAL_MESSAGE = "SET_MODAL_MESSAGE";
export const SET_MODAL_HEADING = "SET_MODAL_HEADING";

// User account related actions
export const SET_TOTAL_CART_ITEMS = "SET_TOTAL_CART_ITEMS";
export const SET_DRAFT_DATA = "SET_DRAFT_DATA";
export const SET_TOTAL_REVIEW_PENDING_ITEMS = "SET_TOTAL_REVIEW_PENDING_ITEMS";

// Loader
export const TO_START_LOADER = "TO_START_LOADER";

// Review Page
export const SET_NON_REVIEWED_DATA = "SET_NON_REVIEWWED_DATA";
export const SET_SELECTED_PAYER_ID = "SET_SELECTED_PAYER_ID";
export const SET_RP_RECIPIENT_DATA = "SET_RP_RECIPIENT_DATA";
export const SET_SELECTED_REC_DATA = "SET_REC_DATA";
export const SET_REVIEWED = "SET_REVIEWED";
export const SET_NO_DEFAULT = "SET_NO_DEFAULT";
export const SET_FORM_TYPES = "SET_FORM_TYPES";
export const SET_BULK_ACTION_ITEMS = "SET_BULK_ACTION_ITEMS";
export const CLEAR_BULK_ACTION_ITEMS = "CLEAR_BULK_ACTION_ITEMS";

// Data to edit
export const SET_DATA_TO_EDIT = "SET_DATA_TO_EDIT";

// Cart data
export const SET_CART_DATA = "SET_CART_DATA";

export const SET_TRUTHY_CART_VALUES = "SET_TRUTHY_CART_VALUES";
export const REMOVE_TRUTHY_CART_VALUES = "REMOVE_TRUTHY_CART_VALUES";
export const REVERT_TO_DEFAULT_TRUTHY_CART_VALUES = "REVERT_TO_DEFAULT_TRUTHY_CART_VALUES";

export const SET_CART_PAGE_SELECTED_RECIPIENTS = "SET_CART_PAGE_SELECTED_RECIPIENTS";
export const REMOVE_CART_PAGE_SELECTED_RECIPIENTS = "REMOVE_CART_PAGE_SELECTED_RECIPIENTS";

// Cart page pricing
export const SET_TOTAL_POSTAL_MAILING = "SET_TOTAL_POSTAL_MAILING";
export const SET_TOTAL_ONLINE_ACCESS = "SET_TOTAL_ONLINE_ACCESS";
export const SET_TOTAL_STATE_FILING = "SET_TOTAL_STATE_FILING";

export const RESET_TOTAL_CART_VALUES = "RESET_TOTAL_CART_VALUES";

export const SET_RECIPIENTS_WITHOUT_EMAIL_ID = "SET_RECIPIENTS_WITHOUT_EMAIL_ID";
export const REMOVE_REC_FROM_RECIPIENTS_WITHOUT_EMAIL_ARRAY =
  "REMOVE_REC_FROM_RECIPIENTS_WITHOUT_EMAIL_ARRAY";

export const CALL_EMAIL_REQUIRED_MODAL = "CALL_EMAIL_REQUIRED_MODAL";

// Cart page bulk actions
export const SET_CART_PAGE_BULK_ACTIONS = "SET_CART_PAGE_BULK_ACTIONS";

export const SELECT_ALL_RECIPIENTS_FOR_A_PAYER = "SELECT_ALL_RECIPIENTS_FOR_PAYER";
export const REMOVE_ALL_RECIPIENTS_FOR_A_PAYER = "REMOVE_ALL_RECIPIENTS_FOR_PAYER";

// Modal
export const TO_DISPLAY_CONFIRMATION_MODAL = "TO_DISPLAY_CONFIRMATION_MODAL";
export const TO_DISPLAY_E_FILING_ERROR_MODAL = "TO_DISPLAY_E_FILING_ERROR_MODAL";

export const SET_RECIPIENTS_WITHOUT_STATE_FILING_FOR_CURRENT_PAYER =
  "SET_RECIPIENTS_WITHOUT_STATE_FILING_FOR_CURRENT_PAYER";

export const SET_REVIEWED_FORM_IDS = "SET_REVIEWED_FORM_IDS";
export const REMOVE_REVIEWED_FORM_IDS = "REMOVE_REVIEWED_FORM_IDS";

// TIN masking
export const MASK_TIN = "MASK_TIN";

// Feedback window related
export const DISPLAY_FEEDBACK_MODAL = "DISPLAY_FEEDBACK_MODAL";

// Form history crat ids
export const SET_FORM_HISTORY_CART_IDS = "SET_FORM_HISTORY_CART_IDS";

export const SET_CHECKED = "SET_CHECKED";

// Admin auth
export const SET_IS_ADMIN_AUTHENTICATED = "SET_IS_ADMIN_AUTHENTICATED";

// Online access auth
export const SET_IS_RECIPIENT_AUTHENTICATED = "SET_IS_RECIPIENT_AUTHENTICATED";

// Correction submission type related
export const SET_CORRECTION_SUBMISSION_TYPE = "SET_CORRECTION_SUBMISSION_TYPE";

// Pdf viewer modal
export const TO_DISPLAY_PDF_VIEWER_MODAL = "TO_DISPLAY_PDF_VIEWER_MODAL";

export const TO_DISPLAY_HAM_MENU = "TO_DISPLAY_HAM_MENU";

export const TO_SHOW_INVALID_TIN_ERROR = "TO_SHOW_INVALID_TIN_ERROR";

// Staff and group related
export const TO_DISPLAY_PAYER_ASSIGNMENT_MODAL = "TO_DISPLAY_PAYER_ASSIGNMENT_MODAL";
export const TO_DISPLAY_PAYER_UNASSIGNMENT_MODAL = "TO_DISPLAY_PAYER_UNASSIGNMENT_MODAL";
export const TO_DISPLAY_MANUAL_STAFF_ADDING_MODAL = "TO_DISPLAY_MANUAL_STAFF_ADDING_MODAL";
export const TO_DISPLAY_GROUP_VIEWING_MODAL = "TO_DISPLAY_GROUP_VIEWING_MODAL";
export const TO_DISPLAY_MANUAL_GROUP_CREATING_MODAL = "TO_DISPLAY_MANUAL_GROUP_CREATING_MODAL";
export const TO_DISPLAY_ADD_STAFFS_TO_GROUP_MODAL = "TO_DISPLAY_ADD_STAFFS_TO_GROUP_MODAL";
export const TO_DISPLAY_REMOVE_STAFFS_FROM_GROUP_MODAL =
  "TO_DISPLAY_REMOVE_STAFFS_FROM_GROUP_MODAL";
export const TO_DISPLAY_VIEW_ASSIGNED_PAYERS_MODAL = "TO_DISPLAY_VIEW_ASSIGNED_PAYERS_MODAL";
export const TO_DISPLAY_VIEW_ASSIGNED_STAFFS_MODAL = "TO_DISPLAY_VIEW_ASSIGNED_STAFFS_MODAL";
export const TO_DISPLAY_ADD_BULK_STAFF_MODAL = "TO_DISPLAY_ADD_BULK_STAFF_MODAL";
export const TO_DISPLAY_BULK_CREATE_GROUP_MODAL = "TO_DISPLAY_BULK_CREATE_GROUP_MODAL";
