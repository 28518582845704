import React from "react";
import { useEffect } from "react";
import formatTin from "../../../../../lib/form/utils/formatTin";

export default function UpdateEmail({
  recName,
  recTin,
  recTinType,
  cartId,
  onlineAccessEmails,
  setonlineAccessEmails,
  currentIdx,
}: any) {
  // Create the online access email array format for each cart id to send to the backend
  useEffect(() => {
    setonlineAccessEmails((prevState: any) => {
      return [
        ...prevState,
        {
          cart_id: cartId,
          new_recipient_email: "",
          isValid: null,
          invalidErrMsg: "",
        },
      ];
    });
    return () => {
      setonlineAccessEmails([]);
    };
  }, [cartId, setonlineAccessEmails, recTin]);

  // Handle new email input
  const handleNewEmailInput = (e: any) => {
    const { value: newEmail } = e.target;
    const initialArray = onlineAccessEmails;
    initialArray[currentIdx].new_recipient_email = newEmail;
    initialArray[currentIdx].isValid = true;
    initialArray[currentIdx].invalidErrMsg = "";
    // console.log(initialArray);
    setonlineAccessEmails([...initialArray]);
  };
  return (
    <tbody>
      <tr>
        <td style={{ textAlign: "left" }}>
          <div style={{ padding: "0.5rem 0" }}>
            <div style={{ marginBottom: "0.5rem" }}>{recName}</div>
            <div>
              {recTinType}: {formatTin(recTin, recTinType)}
            </div>
          </div>
        </td>
        <td>
          <div>
            <input
              //   value={
              //     onlineAccessEmails[currentIdx]
              //       ? onlineAccessEmails[currentIdx].new_recipient_email
              //       : ""
              //   }
              defaultValue={""}
              type="email"
              style={{ width: "100%", padding: "0.5rem 1rem" }}
              onChange={handleNewEmailInput}
            />
            {onlineAccessEmails[currentIdx]?.isValid === false && (
              <p style={{ color: "red" }}>*{onlineAccessEmails[currentIdx]?.invalidErrMsg}</p>
            )}
          </div>
        </td>
      </tr>
    </tbody>
  );
}
