import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ClBody.css";
import { useEffect, useState } from "react";
import state_codes from "../../lib/json/states_key_val.json";
import {
  CP_BULK_DISABLE_ONLINE_ACCESS,
  CP_BULK_DISABLE_POSTAL_MAILING,
  CP_BULK_DISABLE_STATE_FILING,
  CP_BULK_ENABLE_ONLINE_ACCESS,
  CP_BULK_ENABLE_POSTAL_MAILING,
  CP_BULK_ENABLE_STATE_FILING,
  ONLINE_ACCESS,
  POSTAL_MAILING,
  STATE_FILING,
} from "../../lib/constants";
import Handler from "../../lib/Handlers/main";
import { RiErrorWarningFill } from "react-icons/ri";
import revertToDefaultTCV from "../../lib/StateSetters/revertToDefaultTCV";
import setCartPageSelectedRec from "../../lib/StateSetters/setCartPageSelectedRec";
import removeCartPageSelectedRec from "../../lib/StateSetters/removeCartPageSelectedRec";
// import devLogInstance from "../../lib/loggerConfig";
import {
  resetTotalCartValues,
  setTotalOA,
  setTotalPM,
  setTotalSF,
} from "../../lib/StateSetters/setTotalCartValues";
import callEmailRequiredModal from "../../lib/StateSetters/callEmailRequiredModal";
import setRecipientsWithoutEmailId from "../../lib/StateSetters/setRecipientsWithoutEmailId";
import TruthyCartValues, { StateFiling } from "../../lib/utils/CartValues";
import setCartPageBulkActionPayload from "../../lib/StateSetters/setCartPageBulkActionPayload";
import ActionPopup from "./ActionPopup/ActionPopup";
import setRecsWithoutStateFiling from "../../lib/StateSetters/setRecsWithoutStatefiling";
import displayConfirmationModal from "../../lib/StateSetters/displayConfirmationModal";
import { DISABLE_STATE_FILING_SINGLE } from "../../lib/UserActions/action";
import formatTin from "../../lib/form/utils/formatTin";
import { isEin } from "../../lib/utils/maskTin";
import maskTin from "../../lib/utils/maskTin";
import { FaCopyright } from "react-icons/fa";

const statecodes: any = state_codes;

export default function ClBody({ recipientData, payerId, uniqueId, isDraftData = false }: any) {
  const dispatch = useDispatch();
  const truthyCartValues = useSelector((state: any) => state.truthyCartValues);
  const [isChecked, setIsChecked] = useState({
    onlineAccess: false,
    postalMailing: false,
    stateFiling: false,
  });
  const toRemoveAllRecForPayer = useSelector((state: any) => state.toRemoveAllRecForPayer);
  const toSelectAllRecForPayer = useSelector((state: any) => state.toSelectAllRecForPayer);
  const cartPageSelectedRec = useSelector((state: any) => state.cartPageSelectedRec);
  const cartPageBulkAction = useSelector((state: any) => state.cartPageBulkAction);
  const toMaskTin = useSelector((state: any) => state.toMaskTin);
  const [isRecSelected, setIsRecSelected] = useState(false);
  const [isActionDotsClicked, setIsActionDotsClicked] = useState(false);
  const currentRecCartId = recipientData.cartId;
  const state1: string = recipientData.state1;
  const state2: string = recipientData.state2;

  // Update the recipient without email ids state
  useEffect(() => {
    // if (!cartPageSelectedRec[payerId]?.cartIds.includes(currentRecCartId)) {
    //   setRecipientsWithoutEmailId(dispatch, []);
    // }
    // if (cartPageSelectedRec[payerId]?.cartIds.includes(currentRecCartId)) {
    !recipientData.recipient_email &&
      setRecipientsWithoutEmailId(dispatch, { ...recipientData, payerId: payerId });
    // }

    return () => {
      setRecipientsWithoutEmailId(dispatch, []);
    };
  }, [recipientData, dispatch, cartPageSelectedRec, currentRecCartId, payerId]);

  // Set the rec if without state filing
  useEffect(() => {
    if (!state1 && !state2) {
      setRecsWithoutStateFiling(dispatch, true, payerId);
    } else {
      setRecsWithoutStateFiling(dispatch, false, payerId);
    }
    return () => {
      setRecsWithoutStateFiling(dispatch, false, payerId);
    };
  }, [state1, state2, payerId, dispatch]);

  // Set the truthy cart values
  useEffect(() => {
    if (recipientData.online_access) {
      TruthyCartValues.setTruthyCartVals(recipientData.cartId, payerId, ONLINE_ACCESS, dispatch);
    } else {
      TruthyCartValues.setTruthyCartVals(null, payerId, ONLINE_ACCESS, dispatch);
    }
    if (recipientData.postal_mailing) {
      TruthyCartValues.setTruthyCartVals(recipientData.cartId, payerId, POSTAL_MAILING, dispatch);
    } else {
      TruthyCartValues.setTruthyCartVals(null, payerId, POSTAL_MAILING, dispatch);
    }
    if (recipientData.state_filing) {
      const state1 = recipientData.state1;
      const state2 = recipientData.state2;
      const stateFilingOptions = {
        state1: state1,
        state2: state2,
      };
      TruthyCartValues.setTruthyCartVals(
        recipientData.cartId,
        payerId,
        STATE_FILING,
        dispatch,
        stateFilingOptions
      );
    } else {
      TruthyCartValues.setTruthyCartVals(null, payerId, STATE_FILING, dispatch);
    }

    return () => {
      revertToDefaultTCV(dispatch);
    };
  }, [recipientData, dispatch, payerId]);

  // Set initiial statefiling, onlineaccess and postalmailingg ischecked vals
  useEffect(() => {
    setIsChecked({
      onlineAccess: truthyCartValues.onlineAccess[payerId]
        ? truthyCartValues.onlineAccess[payerId].data.includes(recipientData.cartId)
        : false,
      postalMailing: truthyCartValues.postalMailing[payerId]
        ? truthyCartValues.postalMailing[payerId].data.includes(recipientData.cartId)
        : false,
      stateFiling: truthyCartValues.stateFiling[payerId]
        ? truthyCartValues.stateFiling[payerId].data.includes(recipientData.cartId)
        : false,
    });
  }, [truthyCartValues, recipientData, payerId]);

  // De-select all recipient for all payer initially
  useEffect(() => {
    !isDraftData && setCartPageSelectedRec(dispatch, null, payerId);
    return () => {
      !isDraftData && setCartPageSelectedRec(dispatch, null, payerId);
    };
  }, [dispatch, currentRecCartId, payerId, isDraftData]);

  // Select rec for a specific or all payer depending on state
  useEffect(() => {
    if (toSelectAllRecForPayer !== null && !isDraftData) {
      if (toSelectAllRecForPayer.payerId === "ALL_PAYERS") {
        setCartPageSelectedRec(dispatch, currentRecCartId, payerId);
      } else {
        payerId === toSelectAllRecForPayer.payerId &&
          setCartPageSelectedRec(dispatch, currentRecCartId, payerId);
      }
    }
  }, [toSelectAllRecForPayer, payerId, currentRecCartId, dispatch, isDraftData]);

  // Unselect rec for a specific or all payer depending on state
  useEffect(() => {
    if (toRemoveAllRecForPayer !== null) {
      if (toRemoveAllRecForPayer.payerId === "ALL_PAYERS") {
        removeCartPageSelectedRec(dispatch, payerId, currentRecCartId, 1);
      } else {
        payerId === toRemoveAllRecForPayer.payerId &&
          removeCartPageSelectedRec(dispatch, payerId, currentRecCartId, 1);
      }
    }
  }, [toRemoveAllRecForPayer, payerId, currentRecCartId, dispatch]);

  // Set the isRecSelected state
  useEffect(() => {
    cartPageSelectedRec[payerId]?.cartIds.includes(currentRecCartId)
      ? setIsRecSelected(true)
      : setIsRecSelected(false);
  }, [cartPageSelectedRec, currentRecCartId, payerId]);

  // Set the total values for postal mailing, state filing and online access
  useEffect(() => {
    // Check the curent recipient cartiid exists in the array on each render denoting the rec is slelected
    if (cartPageSelectedRec[payerId]?.cartIds.includes(currentRecCartId)) {
      // If so, continue with the block
      const pmexists = truthyCartValues.postalMailing[payerId]?.data.includes(currentRecCartId);
      pmexists && setTotalPM(dispatch, 1);
      const oaexists = truthyCartValues.onlineAccess[payerId]?.data.includes(currentRecCartId);
      oaexists && setTotalOA(dispatch, 1);
      const sfexists = truthyCartValues.stateFiling[payerId]?.data.includes(currentRecCartId);
      if (sfexists)
        state1 && state2 ? setTotalSF(dispatch, 2) : (state1 || state2) && setTotalSF(dispatch, 1);
    }
    return () => {
      resetTotalCartValues(dispatch);
    };
  }, [cartPageSelectedRec, truthyCartValues, payerId, currentRecCartId, state1, state2, dispatch]);

  // Handle bulk actions related to cart values(postal mailing, online access, state filing)
  useEffect(() => {
    if (cartPageBulkAction) {
      switch (cartPageBulkAction.bulkActionType) {
        case CP_BULK_ENABLE_POSTAL_MAILING:
          cartPageSelectedRec[payerId]?.cartIds.includes(currentRecCartId) &&
            TruthyCartValues.setTruthyCartVals(currentRecCartId, payerId, POSTAL_MAILING, dispatch);
          break;

        case CP_BULK_DISABLE_POSTAL_MAILING:
          cartPageSelectedRec[payerId]?.cartIds.includes(currentRecCartId) &&
            TruthyCartValues.removeTruthyCartVals(
              currentRecCartId,
              payerId,
              POSTAL_MAILING,
              dispatch
            );
          break;

        case CP_BULK_ENABLE_STATE_FILING:
          if (cartPageSelectedRec[payerId]?.cartIds.includes(currentRecCartId)) {
            const stateFilingOptions = {
              state1: recipientData.state1,
              state2: recipientData.state2,
            };
            TruthyCartValues.setTruthyCartVals(
              currentRecCartId,
              payerId,
              STATE_FILING,
              dispatch,
              stateFilingOptions
            );
          }
          break;

        case CP_BULK_DISABLE_STATE_FILING:
          cartPageSelectedRec[payerId]?.cartIds.includes(currentRecCartId) &&
            TruthyCartValues.removeTruthyCartVals(
              currentRecCartId,
              payerId,
              STATE_FILING,
              dispatch
            );
          break;

        case CP_BULK_ENABLE_ONLINE_ACCESS:
          if (
            cartPageSelectedRec[payerId]?.cartIds.includes(currentRecCartId) &&
            !cartPageBulkAction.recsToExclude.includes(currentRecCartId)
          ) {
            TruthyCartValues.setTruthyCartVals(currentRecCartId, payerId, ONLINE_ACCESS, dispatch);
          }

          break;

        case CP_BULK_DISABLE_ONLINE_ACCESS:
          cartPageSelectedRec[payerId]?.cartIds.includes(currentRecCartId) &&
            TruthyCartValues.removeTruthyCartVals(
              currentRecCartId,
              payerId,
              ONLINE_ACCESS,
              dispatch
            );
          break;

        default:
          break;
      }
    }
    return () => {
      setCartPageBulkActionPayload(dispatch, null);
    };
  }, [cartPageBulkAction, currentRecCartId, payerId, cartPageSelectedRec, recipientData, dispatch]);

  // Handle click on the action dots
  useEffect(() => {
    const handleClick = (e: any) => {
      const { id } = e.target;
      id === uniqueId
        ? setIsActionDotsClicked(!isActionDotsClicked)
        : setIsActionDotsClicked(false);
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [isActionDotsClicked, uniqueId]);

  return (
    <tbody>
      <tr className="rec-info">
        <td style={{ width: "2rem" }}>
          <input
            type="checkbox"
            checked={isRecSelected}
            disabled={isDraftData}
            onChange={() => {
              isRecSelected && removeCartPageSelectedRec(dispatch, payerId, currentRecCartId, 1);
              !isRecSelected && setCartPageSelectedRec(dispatch, currentRecCartId, payerId);
            }}
          />
        </td>
        <td className="td-content" style={{ width: "35rem" }}>
          <div className="flex items-center justify-between">
            <div>
              <p className="py-1">
                {recipientData.recipient_name.length >= 15
                  ? recipientData.recipient_name.substring(0, 14) + "..."
                  : recipientData.recipient_name}
              </p>
              <p style={{ fontSize: "0.85rem" }} className="text-slate-500 py-1">
                {recipientData.recipient_tin_type}:{" "}
                {!isEin(recipientData?.recipient_tin_type)
                  ? toMaskTin
                    ? maskTin(
                        formatTin(recipientData?.recipient_tin, recipientData?.recipient_tin_type),
                        recipientData?.recipient_tin_type
                      )
                    : formatTin(recipientData?.recipient_tin, recipientData?.recipient_tin_type)
                  : formatTin(recipientData.recipient_tin, recipientData.recipient_tin_type)}
              </p>
            </div>
            {recipientData.correction_type && (
              <h2 className="px-2">
                <FaCopyright className="text-red-500" />
              </h2>
            )}
          </div>
        </td>
        <td className="td-content" style={{ textAlign: "center" }}>
          {recipientData.form_type}
        </td>
        <td className="td-content">
          <div id={STATE_FILING} className="td-content">
            <input
              className="td-checkbox"
              type="checkbox"
              checked={isChecked.stateFiling}
              onChange={(e) => {
                if (isChecked.stateFiling) {
                  StateFiling.filteredCartIdsForStatefiling = [currentRecCartId];
                  StateFiling.currentpayerId = payerId;
                  StateFiling.state1 = state1;
                  StateFiling.state2 = state2;
                  // Display the confirmation modal
                  displayConfirmationModal(dispatch, DISABLE_STATE_FILING_SINGLE, true);
                } else {
                  Handler.handleCartCheckboxUpdate(
                    e,
                    truthyCartValues,
                    !isChecked.stateFiling,
                    [recipientData.cartId],
                    payerId,
                    dispatch,
                    { state1: recipientData.state1, state2: recipientData.state2 }
                  );
                }
              }}
              style={{
                display: !recipientData.state1 && !recipientData.state2 ? "none" : "block",
              }}
            />
            <span>
              {statecodes[state1]} {recipientData.state2 && `, ${statecodes[state2]}`}
              {!isChecked.stateFiling && (state1 || state2) && (
                <h3>
                  <RiErrorWarningFill
                    style={{
                      color: "#F49D1A",
                      display: "flex",
                      alignItems: "center",
                    }}
                  />
                </h3>
              )}
            </span>
          </div>
        </td>
        <td className="td-content">
          <div id={ONLINE_ACCESS}>
            <input
              type="checkbox"
              checked={isChecked.onlineAccess}
              onChange={(e) => {
                recipientData.recipient_email
                  ? Handler.handleCartCheckboxUpdate(
                      e,
                      truthyCartValues,
                      !isChecked.onlineAccess,
                      [recipientData.cartId],
                      payerId,
                      dispatch
                    )
                  : callEmailRequiredModal(dispatch, {
                      dataArray: [recipientData],
                      payerId: payerId,
                      cartIds: [currentRecCartId],
                      toCall: true,
                    });
              }}
            />
            {isChecked.onlineAccess ? (
              <span
                onClick={(e) => {
                  Handler.handleCartCheckboxUpdate(
                    e,
                    truthyCartValues,
                    !isChecked.onlineAccess,
                    [recipientData.cartId],
                    payerId,
                    dispatch
                  );
                }}
                style={{ color: "#0A6EBD", fontWeight: "bold" }}
              >
                Enabled
              </span>
            ) : (
              <span
                onClick={(e) => {
                  recipientData.recipient_email
                    ? Handler.handleCartCheckboxUpdate(
                        e,
                        truthyCartValues,
                        !isChecked.onlineAccess,
                        [recipientData.cartId],
                        payerId,
                        dispatch
                      )
                    : callEmailRequiredModal(dispatch, {
                        dataArray: [recipientData],
                        payerId: payerId,
                        cartIds: [currentRecCartId],
                        toCall: true,
                      });
                }}
                style={{ fontWeight: "bold" }}
              >
                Give Online Access
              </span>
            )}
          </div>
        </td>
        <td className="td-content">
          <div id={POSTAL_MAILING}>
            <input
              type="checkbox"
              checked={isChecked.postalMailing}
              onChange={(e) => {
                Handler.handleCartCheckboxUpdate(
                  e,
                  truthyCartValues,
                  !isChecked.postalMailing,
                  [recipientData.cartId],
                  payerId,
                  dispatch
                );
              }}
            />
            {isChecked.postalMailing ? (
              <span
                className="span"
                onClick={(e) => {
                  Handler.handleCartCheckboxUpdate(
                    e,
                    truthyCartValues,
                    !isChecked.postalMailing,
                    [recipientData.cartId],
                    payerId,
                    dispatch
                  );
                }}
                style={{ color: "#0A6EBD", fontWeight: "bold" }}
              >
                Enabled
              </span>
            ) : (
              <span
                className="span"
                onClick={(e) => {
                  Handler.handleCartCheckboxUpdate(
                    e,
                    truthyCartValues,
                    !isChecked.postalMailing,
                    [recipientData.cartId],
                    payerId,
                    dispatch
                  );
                }}
                style={{ fontWeight: "bold" }}
              >
                Mail a copy
              </span>
            )}
          </div>
        </td>
        <td className="td-content">
          <div style={{ position: "relative" }}>
            <h1 className="action-dots-wrapper">
              <i id={uniqueId} className="fa-solid fa-ellipsis action-dots" />
            </h1>
            {isActionDotsClicked && <ActionPopup cartId={currentRecCartId} />}
          </div>
        </td>
      </tr>
    </tbody>
  );
}
