import React from "react";
import "./Rating.css";
import { useHover } from "../../lib/customHooks";
import devLogInstance from "../../lib/loggerConfig";
import { useState } from "react";

const ratings = [1, 2, 3, 4, 5];

export default function Rating({ callback }: any) {
  const [isHovered, handleHoverIn, handleHoverOut] = useHover();
  const [isClicked, setIsClicked] = useState<number | null>(null);

  // Handle mouse over
  const handleMouseOver = (e: any) => {
    const { id } = e.target;
    let i = 0;
    while (i <= id) {
      handleHoverIn(i.toString());
      i++;
    }
  };

  // Handle mouse leave
  const handleMouseLeave = (e: any) => {
    const { id } = e.target;
    let i = 0;
    while (i <= id) {
      handleHoverOut(i.toString());
      i++;
    }
  };

  // Handle click
  const handleClick = (e: any) => {
    const { id } = e.target;
    const rating = ratings[parseInt(id)];
    devLogInstance.log(`RATING ---> ${rating}`);
    setIsClicked(rating);
    callback(rating);
  };

  return (
    <div className="rating-main-container">
      <div>
        <h2>How would you rate your experience?</h2>
      </div>
      <ul className="star-list">
        <li
          style={{ opacity: isHovered["0"] === true || (isClicked && isClicked > 0) ? "1" : "0.5" }}
        >
          <img
            id={"0"}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
            src="/Assets/rating_star_icon.svg"
            alt="rating-star"
          />
        </li>
        <li
          style={{ opacity: isHovered["1"] === true || (isClicked && isClicked > 1) ? "1" : "0.5" }}
        >
          <img
            id={"1"}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
            src="/Assets/rating_star_icon.svg"
            alt="rating-star"
          />
        </li>
        <li
          style={{ opacity: isHovered["2"] === true || (isClicked && isClicked > 2) ? "1" : "0.5" }}
        >
          <img
            id={"2"}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
            src="/Assets/rating_star_icon.svg"
            alt="rating-star"
          />
        </li>
        <li
          style={{ opacity: isHovered["3"] === true || (isClicked && isClicked > 3) ? "1" : "0.5" }}
        >
          <img
            id={"3"}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
            src="/Assets/rating_star_icon.svg"
            alt="rating-star"
          />
        </li>
        <li
          style={{ opacity: isHovered["4"] === true || (isClicked && isClicked > 4) ? "1" : "0.5" }}
        >
          <img
            id={"4"}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
            src="/Assets/rating_star_icon.svg"
            alt="rating-star"
          />
        </li>
      </ul>
    </div>
  );
}
