import React from "react";
import "./DownloadTemplateBtn.component.css";
import { useSelector } from "react-redux";
import { CSV, TEMPLATE_DOWNLOAD, XLSX } from "../../../../../lib/constants";
import { FaDownload } from "react-icons/fa";
import { btnInnerTxts } from "../../../../../lib/languagePacks/en-us";
import devLogInstance from "../../../../../lib/loggerConfig";
import { Action } from "../../../../../lib/utils/Action";

interface Props {
  payerId: string;
  formType: string;
}

export default function DownloadTemplateBtn({ payerId, formType }: Props) {
  const style = useSelector((state: any) => state.themeChange);

  // Handle click on any of the template download type
  const handleClick = async (e: any) => {
    const { id } = e.target;
    devLogInstance.log(`CLICKED ---> ${id}`);

    const downloadOptions = {
      type: TEMPLATE_DOWNLOAD,
      format: id,
      payerId: payerId,
      formType: formType.replace(/-/gi, ""),
    };
    // Download the template
    Action.templateDownload(downloadOptions);
  };

  return (
    <main id={TEMPLATE_DOWNLOAD} className="download-btn-main">
      <div
        id={XLSX}
        onClick={handleClick}
        style={{ background: style.btnSecondaryBg, color: style.btnFillFontColor }}
        className="flex items-center gap-2"
      >
        <FaDownload /> {btnInnerTxts.downloadXLTemplate}
      </div>
      <div
        id={CSV}
        onClick={handleClick}
        style={{ background: style.btnBg, color: style.btnFillFontColor }}
        className="flex items-center gap-2"
      >
        <FaDownload /> {btnInnerTxts.downloadCSVTemplate}
      </div>
    </main>
  );
}
