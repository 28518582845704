import React from "react";
import { FiDollarSign } from "react-icons/fi";

export default function LocalFilingDetails({ compId }: any) {
  return (
    <div className="state-details-container">
      <div className="state-tax-withheld-container" style={{ width: "100%" }}>
        <div className="state-tax-input-wrapper">
          <label htmlFor="state-tax">
            <h4>Local tax withheld</h4>
          </label>
          <div className="amount-input-container" id={compId}>
            <div className="dollar-sign">
              <FiDollarSign />
            </div>
            <input
              className="payer-details-input amount-input"
              type="text"
              id="state-tax"
              placeholder="0.00"
              //   value={compId === "localfiling1" ? stateAmount.stateTax1 : stateAmount.stateTax2}
              //   onChange={handleChange}
              autoComplete="off"
            />
          </div>
        </div>
        {/* {compId === "state1" && showValidationError.onStateTax1 && (
      <Indicator type={STATE_TAX_1} message={stateFilingErrMsg.nec1099.stateTaxErrMsg} />
    )}
    {compId === "state2" && showValidationError.onStateTax2 && (
      <Indicator type={STATE_TAX_2} message={stateFilingErrMsg.nec1099.stateTaxErrMsg} />
    )} */}
      </div>
      <div
        className="state-stateNo-container"
        style={{
          width: "100%",
        }}
      >
        <div className="state-number-container">
          <div className="state-number-input-wrapper" id={compId}>
            <label htmlFor="state-number">
              <h4>Name of locality</h4>
            </label>
            <input
              className="payer-details-input"
              type="text"
              id="state-number"
              //   value={compId === "state1" ? gData.payer_state_number1 : gData.payer_state_number2}
              //   onChange={handleChange}
              autoComplete="off"
            />
          </div>
          {/* {compId === "state1" && showValidationError.onStateNum1 && (
        <Indicator type={STATE_NUMBER_1} message={stateFilingErrMsg.nec1099.stateNumErrMsg} />
      )}
      {compId === "state2" && showValidationError.onStateNum2 && (
        <Indicator type={STATE_NUMBER_2} message={stateFilingErrMsg.nec1099.stateNumErrMsg} />
      )} */}
        </div>
      </div>
      <div className="state-income-container" style={{ width: "100%" }}>
        <div className="state-income-wrapper">
          <label htmlFor="state-income">
            <h4>Local Distribution</h4>
          </label>
          <div className="amount-input-container" id={compId}>
            <div className="dollar-sign">
              <FiDollarSign />
            </div>
            <input
              className="payer-details-input amount-input"
              type="text"
              id="state-income"
              placeholder="0.00"
              // value={compId === "state1" ? stateAmount.stateIncome1 : stateAmount.stateIncome2}
              // onChange={handleChange}
              autoComplete="off"
            />
          </div>
        </div>
        {/* {compId === "state1" && showValidationError.onStateIncome1 && (
        <Indicator
          type={STATE_INCOME_1}
          message={stateFilingErrMsg.nec1099.stateIncomeErrMsg}
        />
      )}
      {compId === "state2" && showValidationError.onStateIncome2 && (
        <Indicator
          type={STATE_INCOME_2}
          message={stateFilingErrMsg.nec1099.stateIncomeErrMsg}
        />
      )} */}
      </div>
    </div>
  );
}
