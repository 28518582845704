export const FACharLimit = 46;
export const FAWarningTriggerLimit = 43;

export const FA_WARNING_INDICATOR = "FA_WARNING_INDICATOR";

// Address line
export const addressLine_1_limit = 40;
export const addressLine_1_and_2_limit = 39;
export const addressLineTriggerLimit = 36;

export const AL_WARNING_INDICATOR = "AL_WARNING_INDICATOR";

// Mandatory field names
export const FORM_FIELD_VALIDATION_ERROR = "form field validation error";
export const NOT_MANDATORY = "NOT_MANDATORY";
// Payer block
export const PAYER_NAME = "payer_name";
export const PAYER_TIN = "payer_tin";
export const PAYER_ADDRESS_1 = "payer_address_line_1";
export const PAYER_CITY = "payer_city";
export const PAYER_STATE = "payer_state";
export const PAYER_ZIPCODE = "payer_zipcode";
export const PAYER_COUNTRY = "payer_country";
export const PAYER_PHONE_NUMBER = "payer_phone_number";
export const PAYER_ALL_FIELDS = "payer_all_fields";

// Recipient block
export const RECIPIENT_NAME = "recipient_name";
export const RECIPIENT_TIN = "recipient_tin";
export const RECIPIENT_ADDRESS_1 = "recipient_address_line_1";
export const RECIPIENT_CITY = "recipient_city";
export const RECIPIENT_STATE = "recipient_state";
export const RECIPIENT_ZIPCODE = "recipient_zipcode";
export const RECIPIENT_COUNTRY = "recipient_country";
export const RECIPIENT_PHONE_NUMBER = "recipient_phone_number";
export const RECIPIENT_ALL_FIELDS = "recipient_all_fields";

export const INVALID_AMOUNT = "INVALID_AMOUNT";

export const DEFAULT_NON_FOREIGN_COUNTRY = "US";

// State filing
export const STATE_1 = "STATE_1";
export const STATE_2 = "STATE_2";
export const STATE_TAX_1 = "STATE_TAX_1";
export const STATE_TAX_2 = "STATE_TAX_2";
export const STATE_INCOME_1 = "STATE_INCOME_1";
export const STATE_INCOME_2 = "STATE_INCOME_2";
export const STATE_NUMBER_1 = "STATE_NUMBER_1";
export const STATE_NUMBER_2 = "STATE_NUMBER_2";
export const STATE_FILING_VALIDATION_ERROR = "STATE_FILING_VALIDATION_ERROR";
export const STATE1_ALL_DETAILS = "STATE1_ALL_DETAILS";
export const STATE2_ALL_DETAILS = "STATE2_ALL_DETAILS";
export const STATE_NUM_VALIDATION_ERR = "STATE_NUM_VALIDATION_ERR";
export const WV_STATE_NUMBER_1 = "WV_STATE_NUMBER_1";
export const WV_STATE_NUMBER_2 = "WV_STATE_NUMBER_2";
export const UTAH_STATE_NUMBER_1 = "UTAH_STATE_NUMBER_1";
export const UTAH_STATE_NUMBER_2 = "UTAH_STATE_NUMBER_2";
export const MIN_LEN_FOR_WV_NOT_MET = "MIN_LEN_FOR_WV_NOT_MET";
export const MIN_LEN_FOR_UTAH_NOT_MET = "MIN_LEN_FOR_UTAH_NOT_MET";
export const NUMBER_OF_W2_FILED = "NUMBER_OF_W2_FILED";
export const TOTAL_TAX_WITHHELD_FOR_W2 = "TOTAL_TAX_WITHHELD_FOR_W2";

// Submit button id
export const SUBMIT_ADD_ANOTHER = "SUBMIT_ADD_ANOTHER";
export const SUBMIT_CONTINUE = "SUBMIT_CONTINUE";
export const UPDATE_CONTINUE = "UPDATE_CONTINUE";
export const UPDATE_AND_FILE_NEW = "UPDATE_AND_FILE_NEW";

// TIN type
export const EIN = "EIN";
export const SSN = "SSN";

// Pages
export const LANDING_PAGE = "LANDING_PAGE";
export const HOME_PAGE = "HOME_PAGE";
export const LOGIN_PAGE = "LOGIN_PAGE";
export const REGISTER_PAGE = "REGISTER_PAGE";

// Form types
export const FORM1099_NEC = "1099-NEC";
export const FORM1099_MISC = "1099-MISC";
export const FORM1099_INT = "1099-INT";
export const FORM1099_DIV = "1099-DIV";
export const FORM1099_S = "1099-S";
export const FORM1099_B = "1099-B";
export const FORM1099_R = "1099-R";
export const FORM1099_K = "1099-K";

// Form names
export const NEC_1099 = "Nonemployee Compensation";
export const MISC_1099 = "Miscellaneous Information";
export const INT_1099 = "Interest Income";
export const DIV_1099 = "Dividends and Distributions";
export const S_1099 = "Proceeds From Real Estate Transactions";
export const B_1099 = "Proceeds From Broker and Barter Exchange Transactions";
export const R_1099 =
  "Distributions From Pensions, Annuities, Retirement or Profit-Sharing Plans, IRAs, Insurance Contracts, etc.";
export const K_1099 = "Payment Card and Third Party Network Transactions";

// Amount Ids'
export const AMOUNT_1 = "AMOUNT_1";
export const AMOUNT_2 = "AMOUNT_2";
export const AMOUNT_3 = "AMOUNT_3";
export const AMOUNT_4 = "AMOUNT_4";
export const AMOUNT_5 = "AMOUNT_5";
export const AMOUNT_6 = "AMOUNT_6";
export const AMOUNT_7 = "AMOUNT_7";
export const AMOUNT_8 = "AMOUNT_8";
export const AMOUNT_9 = "AMOUNT_9";
export const AMOUNT_A = "AMOUNT_A";
export const AMOUNT_B = "AMOUNT_B";
export const AMOUNT_C = "AMOUNT_C";
export const AMOUNT_D = "AMOUNT_D";
export const AMOUNT_E = "AMOUNT_E";
export const AMOUNT_F = "AMOUNT_F";
export const AMOUNT_G = "AMOUNT_G";
export const AMOUNT_H = "AMOUNT_H";
export const AMOUNT_J = "AMOUNT_J";

// Amount misc fields
export const PAYER_RTN = "PAYER_RTN";
export const CUSIP_NUM = "CUSIP_NUM";
export const FOREIGN_COUNTRY_OR_US_POSSESSION = "FOREIGN_COUNTRY_OR_US_POSSESSION";

// Popup status
export const SUCCESS_POPUP = "SUCCESS";
export const ERROR_POPUP = "ERROR";

// Tax amount
export const INVALID_TAX_AMT = "INVALID_TAX_AMT";

// State filing amount type
export const PAYMENT = "PAYMENT";

// Review page edit and next btn
export const NEXT = "NEXT";
export const EDIT = "EDIT";
export const CONTINUE_TO_CART = "CONTINUE_TO_CART";

// Review Page Bulk actions
export const DOWNLOAD = "DOWNLOAD_BULK";
export const BULK_REVIEW = "BULK_REVIEW";
export const BULK_UNREVIEW = "BULK_UNREVIEW";
export const BULK_DELETE = "BULK_DELETE";

// User profile image
export const USER_PRO_IMG = "USER_PRO_IMG";

// Pages
export const PAGE_UPLOAD = "UPLOAD";
export const PAGE_E_FILE = "E-FILING";

// Download template btn
export const TEMPLATE_DOWNLOAD = "TEMPLATE_DOWNLOAD";
// Template format
export const XLSX = "XLSX";
export const CSV = "CSV";

// Logger Environment
export const DEV_ENVIRONMENT = "development";
export const PROD_ENVIRONMENT = "production";
export const STAGING_ENVIRONMENT = "staging";

// Total cart values
export const INC = "INC";
export const DEC = "DEC";
export const INC_BY_2 = "INC_BY_2";
export const DEC_BY_2 = "DEC_BY_2";
export const REMOVE_ALL = "REMOVE_ALL";

// Cart values
export const POSTAL_MAILING = "POSTAL_MAILING";
export const ONLINE_ACCESS = "ONLINE_ACCESS";
export const STATE_FILING = "STATE_FILING";

export const ONLINE_ACCESS_VALIDATION_ERR = "ONLINE_ACCESS_VALIDATION_ERR";

// Cart page bulk actions
export const CP_BULK_DELETE = "Delete";
export const CP_BULK_DOWNLOAD = "Download";
export const CP_BULK_ENABLE_POSTAL_MAILING = "Enable Postal Mailing";
export const CP_BULK_DISABLE_POSTAL_MAILING = "Disable Postal Mailing";
export const CP_BULK_ENABLE_STATE_FILING = "Enable State Filing";
export const CP_BULK_DISABLE_STATE_FILING = "Disable State Filing";
export const CP_BULK_ENABLE_ONLINE_ACCESS = "Enable Online Access";
export const CP_BULK_DISABLE_ONLINE_ACCESS = "Disable Online Access";

// Form actions
export const FORM_DOWNLOAD = "FORM_DOWNLOAD";
export const FORM_DELETE = "FORM_DELETE";
export const FORM_EDIT = "FORM_EDIT";

// Modal types
export const E_FILING_ERROR_MODAL = "E_FILING_ERROR_MODAL";
export const CONFIRMATION_MODAL = "CONFIRMATION_MODAL";
export const FEEDBACK_MODAL = "FEEDBACK_MODAL";
export const INFO_MODAL = "INFO_MODAL";
export const CORRECTION_FILING_MODAL = "CORRECTION_FILING_MODAL";
export const PDF_VIEWER_MODAL = "PDF_VIEWER_MODAL";
export const PAYER_ASSIGNMENT_MODAL = "PAYER_ASSIGNMENT_MODAL";
export const PAYER_UNASSIGNMENT_MODAL = "PAYER_UNASSIGNMENT_MODAL";
export const MANUAL_STAFF_ADDING_MODAL = "MANUAL_STAFF_ADDING_MODAL";
export const MANUAL_GROUP_CREATING_MODAL = "MANUAL_GROUP_CREATING_MODAL";
export const GROUP_VIEWING_MODAL = "GROUP_VIEWING_MODAL";
export const ADD_STAFFS_TO_GROUP_MODAL = "ADD_STAFFS_TO_GROUP_MODAL";
export const REMOVE_STAFFS_FROM_GROUP_MODAL = "REMOVE_STAFFS_FROM_GROUP_MODAL";
export const VIEW_ASSIGNED_PAYERS_MODAL = "VIEW_ASSIGNED_PAYERS_MODAL";
export const VIEW_ASSIGNED_STAFFS_MODAL = "VIEW_ASSIGNED_STAFFS_MODAL";
export const ADD_BULK_STAFF_MODAL = "ADD_BULK_STAFF_MODAL";
export const BULK_CREATE_GROUP_MODAL = "BULK_CREATE_GROUP_MODAL";

// Tax CAP percentage
export const CAP_PERCENT = 0.35;

// Review page type
export const REVIEW_PAGE_EFORM = "eform";

// Settings type
export const ACCOUNT = "account";
export const PROFILE = "profile";

// Profile settings type
export const EMAIL_SETTING = "EMAIL_SETTING";
export const NAME_SETTING = "NAME_SETTING";
export const PHONE_SETTING = "PHONE_SETTING";
export const PASSWORD_SETTING = "PASSWORD_SETTING";

// TIN masking container
export const TIN_MASKING_CONTAINER = "TIN_MASKING_CONTAINER";

// Date input component different form group ids
export const _1099S_GROUP = "_1099S_GROUP";
export const _1099B_GROUP_1 = "_1099B_GROUP_1";
export const _1099B_GROUP_2 = "_1099B_GROUP_2";
export const _1099R_GROUP = "_1099R_GROUP";

// 1099-B types of gain or loss
export const SHORT_TERM_GAIN_OR_LOSS = "SHORT_TERM_GAIN_OR_LOSS";
export const LONG_TERM_GAIN_OR_LOSS = "LONG_TERM_GAIN_OR_LOSS";
export const ORDINARY = "ORDINARY";

// 1099-B types of proceeds
export const GROSS_PROCEEDS = "GROSS_PROCEEDS";
export const NET_PROCEEDS = "NET_PROCEEDS";

// 1099-B types of applicables
export const COLLECTIBLES = "COLLECTIBLES";
export const QOF = "QOF";

// 1099-B misc field ids
export const NON_COVERED_SECURITY_INDICATOR = "NON_COVERED_SECURITY_INDICATOR";
export const LOSS_NOT_ALLOWED_INDICATOR = "LOSS_NOT_ALLOWED_INDICATOR";
export const BASIS_REPORTED_CHECK = "BASIS_REPORTED_CHECK";

export const PROPERTY_DESCRIPTION = "PROPERTY_DESCRIPTION";

// Applicable checkbox on Form 8949 select component id
export const FORM_8949_APPCHECK_SELECT = "FORM_8949_APPCHECK_SELECT";

// Validation errors
export const PROPERTY_DESCRIPTION_ERROR = "PROPERTY_DESCRIPTION_ERROR";
export const PROCEEDS_ERROR = "PROCEEDS_ERROR";
export const NO_FORM_DATA_ERROR = "NO_FORM_DATA_ERROR";
export const INVALID_AMT = "INVALID_AMT";

// 1099-R misc field ids
export const TAXABLE_AMOUNT_NOT_DETERMINED = "TAXABLE_AMOUNT_NOT_DETERMINED";
export const TOTAl_DISTRO = "TOTAl_DISTRO";

// Feedback submit btn ids
export const SKIP_FEEDBACK = "SKIP";
export const SUBMIT_FEEDBACK = "SUBMIT_FEEDBACK";
export const SUBMIT_FEEDBACK_LOGOUT = "SUBMIT_FEEDBACK_LOGOUT";

// Merchant category code ID
export const MERCHANT_CATEGORY_CODE = "MERCHANT_CATEGORY_CODE";

// Filter type ID
export const PSE = "PSE";
export const EPF = "EPF";

// Filer payment type ID
export const PAYMENT_CARD = "PAYMENT_CARD";
export const THIRD_PARTY_NETWORK = "THIRD_PARTY_NETWORK";

// PSE details id
export const PSE_NAME = "PSE_NAME";
export const PSE_NAME_CONT = "PSE_NAME_CONT";
export const PSE_PHONE = "PSE_PHONE";

// Number of payment transac ID
export const NUMBER_OF_PAYMENT_TRANSAC = "NUMBER_OF_PAYMENT_TRANSAC";

// Payment and filer type indicator id
export const FILER_PAYMENT_TYPE = "FILER_PAYMENT_TYPE";
export const FILER_TYPE = "FILER_TYPE";

// History icon ID
export const HISTORY_ICON = "HISTORY_ICON";

// Form Download options
export const STATE_COPY = "STATE_COPY";
export const PAYER_COPY = "PAYER_COPY";
export const RECIPIENT_COPY = "RECIPIENT_COPY";

export const TIN = "TIN";

// Digital Signature fonts
export const CAVEAT = "caveat";
export const ALLURA = "allura";
export const SACRAMENTO = "sacramento";
export const ZEYADA = "zeyada";
export const ROCKSALT = "rockSolt";
export const REENIE_BEANIE = "reenieBeanie";

// Online access Consent statuses
export const GIVE_CONSENT = "GIVE_CONSENT";
export const DO_NOT_GIVE_CONSENT = "DO_NOT_GIVE_CONSENT";

// Transmission status related
export const TAXEVE_FILENAME = "TAXEVE_FILENAME";
export const FIRE_FILENAME = "FIRE_FILENAME";
export const STATUS = "STATUS";

// Correction submission type
export const CORRECTION_VOID = "CORRECTION_VOID";
export const CORRECTION_CORRECTED = "CORRECTION_CORRECTED";

// Correction initial details
export const CORRECTION_INITIAL = "CORRECTION_INITIAL";

export const SUBMIT_CORRECTION = "SUBMIT_CORRECTION";

// Single action
export const REVIEW = "REVIEW";
export const UNREVIEW = "UNREVIEW";
export const DELETE = "DELETE";
export const DOWNLOAD_SINGLE = "DOWNLOAD_SINGLE";

// Online access pdf preview
export const ONLINE_ACCESS_PDF_PREVIEW = "ONLINE_ACCESS_PDF_PREVIEW";

export const HAMICON = "HAMICON";
export const LANDING_HAM = "LANDING_HAM";
export const POST_LOGIN_HAM = "POST_LOGIN_HAM";

export const NO_SUPPORT_HEADING = "No Support!";

export const INVALID_PAYER_EIN = "INVALID_PAYER_EIN";
export const INVALID_PAYER_SSN = "INVALID_PAYER_SSN";
export const INVALID_RECIPIENT_EIN = "INVALID_RECIPIENT_EIN";
export const INVALID_RECIPIENT_SSN = "INVALID_RECIPIENT_SSN";

export const INVALID_PAYER_TIN_IN_ORDER = "INVALID_PAYER_TIN_IN_ORDER";
export const ALL_SAME_DIGIT_INVALID_PAYER_TIN = "ALL_SAME_DIGIT_INVALID_PAYER_TIN";
export const ALL_ZERO_INVALID_PAYER_TIN = "ALL_ZERO_INVALID_PAYER_TIN";

export const INVALID_RECIPIENT_TIN_IN_ORDER = "INVALID_RECIPIENT_TIN_IN_ORDER";
export const ALL_SAME_DIGIT_INVALID_RECIPIENT_TIN = "ALL_SAME_DIGIT_INVALID_RECIPIENT_TIN";
export const ALL_ZERO_INVALID_RECIPIENT_TIN = "ALL_ZERO_INVALID_RECIPIENT_TIN";

export const FEDERAL_EFILE_STATUS = "FEDERAL_EFILE_STATUS";
export const STATE_EFILE_STATUS = "STATE_EFILE_STATUS";
export const POSTAL_MAILING_STATUS = "POSTAL_MAILING_STATUS";
export const ONLINE_ACCESS_STATUS = "ONLINE_ACCESS_STATUS";

export const ENQUIRY_FLOATER = "ENQUIRY_FLOATER";
export const ENQUIRY_FLOATER_ICON = "ENQUIRY_FLOATER_ICON";
export const ENQUIRY_CONTAINER = "ENQUIRY_CONTAINER";

// Enterprise system related
// Staff related
export const BULK_REMOVE_STAFF = "BULK_REMOVE_STAFF";
// Group related
export const BULK_DELETE_GROUPS = "BULK_DELETE_GROUPS";
