import "../../styles/decorations/SmallLoader.css";
import React, { ReactNode, useMemo, useState } from "react";
import devLogInstance from "../../lib/loggerConfig";
import { RxCross2 } from "react-icons/rx";
import { useElemHeight, useInProgress, useMaxScreenHeight } from "../../lib/customHooks";
import { RiDraggable } from "react-icons/ri";

interface Props {
  inProgress: boolean;
  message: string | ReactNode;
}
// Set y co-ordinate
const setYCord = (maxHeight: number, y: number, h: number) => {
  let diff = 0;
  if (y > maxHeight) {
    diff = y - maxHeight;
  }
  return y - diff - h;
};
// Set the initial/default y co-ordinate
const setDefaultYCord = (maxHeight: number, h: number) => {
  return maxHeight - h;
};

export default function ProgressContainer({ inProgress, message }: Props) {
  const maxScreenHeight = useMaxScreenHeight(); // Get the maximum height of the screen
  // Get the maximum height of a specific html element by creating a reference point
  const { elemRef: progressContainerRef, elemHeight: progressContainerHeight } = useElemHeight();
  // x, y co-ordinates state
  const [cords, setCords] = useState({
    x: 800,
    y: setDefaultYCord(maxScreenHeight, progressContainerHeight),
    delta: 10,
  });
  // Progress related state context
  const { setInProgress } = useInProgress();

  // Set the maximum y co-oridnate when screen resizes so that
  // it always stays at the bottom at a certain position
  useMemo(() => {
    setCords((prevState) => {
      return {
        ...prevState,
        y: setDefaultYCord(maxScreenHeight, progressContainerHeight),
      };
    });
  }, [maxScreenHeight, progressContainerHeight]);
  // Whenever the progress container is dragged update the existing x, y co-ordinates
  const handleDrag = (e: React.DragEvent) => {
    devLogInstance.log(`X: ${e.clientX}`);
    devLogInstance.log(`Y: ${e.clientY}`);
    setCords({
      x: e.clientX,
      // Make sure the container does not move beyond the screen
      y: setYCord(maxScreenHeight, e.clientY, progressContainerHeight),
      delta: 10,
    });
  };

  return (
    <div
      id="progress-container"
      draggable
      onDragEnd={handleDrag}
      className="fixed z-10 bg-white py-4 px-4 flex items-center gap-2 shadow-[4px_4px_15px_3px_rgba(122,122,122,0.5)] rounded-lg cursor-pointer"
      style={{
        top: `${cords.y + cords.delta}px`,
        left: `${cords.x + cords.delta}px`,
      }}
      ref={progressContainerRef}
    >
      <RiDraggable className="text-2xl" />
      {inProgress && <span className="small-loader" />}
      <div className="font-bold text-md">{message}</div>
      {!inProgress && (
        <RxCross2
          className="absolute right-2 top-2 cursor-pointer"
          onClick={() => setInProgress({ inProgress: null, message: "" })}
        />
      )}
    </div>
  );
}
