/* 
Corrected submission middleware is not implemented for 1099-R
*/

import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import devLogInstance from "../../lib/loggerConfig";
import handleAmountFormat from "../../lib/form/utils/handleAmountFormat";
import {
  AMOUNT_1,
  AMOUNT_2,
  AMOUNT_3,
  AMOUNT_4,
  AMOUNT_5,
  AMOUNT_6,
  AMOUNT_8,
  AMOUNT_9,
  AMOUNT_A,
  AMOUNT_B,
  R_1099,
  TAXABLE_AMOUNT_NOT_DETERMINED,
  TOTAl_DISTRO,
  _1099R_GROUP,
} from "../../lib/constants";
import PayerDetails from "./GlobalFormComponents/PayerDetails";
import RecipientDetails from "./GlobalFormComponents/RecipientDetails";
import RecAccount from "./GlobalFormComponents/RecAccount";
import Fatca from "./GlobalFormComponents/FATCA/Fatca";
import AmountContainer from "./GlobalFormComponents/AmountContainer/AmountContainer";
import AmountHeading from "./GlobalFormComponents/AmountContainer/AmountHeading/AmountHeading";
import FormDetail from "./GlobalFormComponents/InternalComponents/FormDetail";
import { r1099AmountDisplayTexts, stateInfoDisplayTexts } from "../../lib/languagePacks/en-us";
import Amount from "./GlobalFormComponents/AmountContainer/AmountInput/Amount";
import StateInfo from "./GlobalFormComponents/StateInfo";
import SubmitButton from "../SubmitButton/SubmitButton";
import UpdateButton from "../UpdateButton/UpdateButton";
import PercentageAmount from "./GlobalFormComponents/AmountContainer/PercentageAmountInput/PercentageAmount";
import Box10 from "./GlobalFormComponents/1099-R-Box10_11/Box10/Box10";
import Box11 from "./GlobalFormComponents/1099-R-Box10_11/Box11/Box11";
import LocalFiling from "./GlobalFormComponents/InternalComponents/LocalFiling/LocalFiling";
import { isValidAmount } from "./Form1099-B";

export default function Form1099R({
  formData,
  setFormData,
  formType,
  taxYear,
  isEditable,
  isCorrection,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  const dispatch = useDispatch();
  const [amount, setAmount] = useState({
    amount1: "",
    amount2: "",
    amount3: "",
    amount4: "",
    amount5: "",
    amount6: "",
    amount8: "",
    amount9: "",
    amountA: "",
    amountB: "",
  }); // for displaying on the input field

  const [miscFields, setMiscFields] = useState({
    taxable_amt_not_determined: false,
    total_distribution_check: false,
  });
  const [date, setDate] = useState("");
  const [isValidDate, setIsValidDate] = useState<boolean | null>(null);
  const [clearDate, setClearDate] = useState<boolean | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Set the formatted amount values
    setAmount({
      amount1: formData.payment_amount1
        ? handleAmountFormat((formData.payment_amount1 / 100).toString())
        : "",
      amount2: formData.payment_amount2
        ? handleAmountFormat((formData.payment_amount2 / 100).toString())
        : "",
      amount3: formData.payment_amount3
        ? handleAmountFormat((formData.payment_amount3 / 100).toString())
        : "",
      amount4: formData.payment_amount4
        ? handleAmountFormat((formData.payment_amount4 / 100).toString())
        : "",
      amount5: formData.payment_amount5
        ? handleAmountFormat((formData.payment_amount5 / 100).toString())
        : "",
      amount6: formData.payment_amount6
        ? handleAmountFormat((formData.payment_amount6 / 100).toString())
        : "",
      amount8: formData.payment_amount8
        ? handleAmountFormat((formData.payment_amount8 / 100).toString())
        : "",
      amount9: formData.payment_amount9
        ? handleAmountFormat((formData.payment_amount9 / 100).toString())
        : "",
      amountA: formData.payment_amountA
        ? handleAmountFormat((formData.payment_amountA / 100).toString())
        : "",
      amountB: formData.payment_amountB
        ? handleAmountFormat((formData.payment_amountB / 100).toString())
        : "",
    });

    // Set the formatted Misc field values
    setMiscFields({
      taxable_amt_not_determined: formData?.taxable_amt_not_determined === true,
      total_distribution_check: formData?.total_distribution_check === true,
    });

    return () => {
      setAmount({
        amount1: "",
        amount2: "",
        amount3: "",
        amount4: "",
        amount5: "",
        amount6: "",
        amount8: "",
        amount9: "",
        amountA: "",
        amountB: "",
      });
      setMiscFields({
        taxable_amt_not_determined: false,
        total_distribution_check: false,
      });
    };
  }, [
    formData.payment_amount1,
    formData.payment_amount2,
    formData.payment_amount3,
    formData.payment_amount4,
    formData.payment_amount5,
    formData.payment_amount6,
    formData.payment_amount8,
    formData.payment_amount9,
    formData.payment_amountA,
    formData.payment_amountB,
    formData.payment_amountC,
    formData.payment_amountD,
    formData.taxable_amt_not_determined,
    formData.total_distribution_check,
  ]);

  // Date related callback
  const callbackRelatedToDate = (date: string, isInvalidDate: boolean) => {
    // Prep the date in the required format to be sent to the backend
    const dateChunks = date?.split("/");
    const date_ = dateChunks[2] + dateChunks[0] + dateChunks[1];

    setDate(date_);
    setIsValidDate(!isInvalidDate);
  };

  // Handle input change on payment amount fields and any other misc fields
  const handleChange = (e: any) => {
    const { id, value } = e.target;
    const formattedAmount = handleAmountFormat(value);
    switch (id) {
      case AMOUNT_1:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount1: formattedAmount,
          });
        break;
      case AMOUNT_2:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount2: formattedAmount,
          });
        break;
      case AMOUNT_3:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount3: formattedAmount,
          });
        break;
      case AMOUNT_4:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount4: formattedAmount,
          });
        break;
      case AMOUNT_5:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount5: formattedAmount,
          });
        break;
      case AMOUNT_6:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount6: formattedAmount,
          });
        break;
      case AMOUNT_8:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount8: formattedAmount,
          });
        break;
      case AMOUNT_9:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount9: formattedAmount,
          });
        break;
      case AMOUNT_A:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountA: formattedAmount,
          });
        break;
      case AMOUNT_B:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountB: formattedAmount,
          });
        break;

      default:
        break;
    }
  };

  // Handle MISC field change
  const handleMiscFieldChange = (e: any) => {
    const { id: fieldType, value } = e.target;
    switch (fieldType) {
      case TAXABLE_AMOUNT_NOT_DETERMINED:
        setMiscFields({
          ...miscFields,
          taxable_amt_not_determined: !miscFields.taxable_amt_not_determined,
        });
        break;

      case TOTAl_DISTRO:
        setMiscFields({
          ...miscFields,
          total_distribution_check: !miscFields.total_distribution_check,
        });
        break;

      default:
        break;
    }
  };

  // Handle form submit
  const handleFormSubmit = (e: any) => {
    //   e.preventDefault();
    //   const { id: submitType } = e.target;
    //   devLogInstance.log(`Clicked on ---> ${submitType}`);
    //   const data = {
    //     ...formData,
    //     ...miscFields,
    //     payer_country: !formData.payer_is_foreign_address
    //       ? DEFAULT_NON_FOREIGN_COUNTRY
    //       : formData.payer_country,
    //     recipient_country: !formData.recipient_is_foreign_address
    //       ? DEFAULT_NON_FOREIGN_COUNTRY
    //       : formData.recipient_country,
    //     form_type: formType,
    //     payment_amount2: amount.amount2 ? parseFloat(amount.amount2.replace(/,/gi, "")) : 0,
    //     payment_amount3: amount.amount3 ? parseFloat(amount.amount3.replace(/,/gi, "")) : 0,
    //     payment_amount4: amount.amount4 ? parseFloat(amount.amount4.replace(/,/gi, "")) : 0,
    //     payment_amount5: amount.amount5 ? parseFloat(amount.amount5.replace(/,/gi, "")) : 0,
    //     payment_amount7: amount.amount7 ? parseFloat(amount.amount7.replace(/,/gi, "")) : 0,
    //     payment_amount9: amount.amount9 ? parseFloat(amount.amount9.replace(/,/gi, "")) : 0,
    //     payment_amountA: amount.amountA ? parseFloat(amount.amountA.replace(/,/gi, "")) : 0,
    //     payment_amountB: amount.amountB ? parseFloat(amount.amountB.replace(/,/gi, "")) : 0,
    //     payment_amountC: amount.amountC ? parseFloat(amount.amountC.replace(/,/gi, "")) : 0,
    //     payment_amountD: amount.amountD ? parseFloat(amount.amountD.replace(/,/gi, "")) : 0,
    //     // Acquiring and selling dates
    //     date_acquired: acquiringDate,
    //     date_sold_or_disposed: sellingDate,
    //   };
    //   devLogInstance.log(data);
    //   const paymentAmounts = [
    //     data.payment_amount2,
    //     data.payment_amount3,
    //     data.payment_amount4,
    //     data.payment_amount5,
    //     data.payment_amount7,
    //     data.payment_amount9,
    //     data.payment_amountA,
    //     data.payment_amountB,
    //     data.payment_amountC,
    //     data.payment_amountD,
    //   ];
    //   const incomeAmounts = [
    //     data.payment_amount2,
    //     data.payment_amount3,
    //     data.payment_amount5,
    //     data.payment_amount7,
    //     data.payment_amount9,
    //     data.payment_amountA,
    //     data.payment_amountB,
    //     data.payment_amountC,
    //     data.payment_amountD,
    //   ];
    //   if (isValidAcquiringDate && isValidSellingDate) {
    //     formSubmissionHelper(
    //       data,
    //       paymentAmounts,
    //       data.payment_amount4,
    //       incomeAmounts,
    //       setFormData,
    //       setAmount,
    //       dispatch,
    //       submitType,
    //       navigate,
    //       PAGE_E_FILE
    //     ).then((res) => {
    //       if (res && submitType === SUBMIT_ADD_ANOTHER) {
    //         // Clear the local state values
    //         // setMiscFields({
    //         //   description_of_property: "",
    //         //   applicable_check_box_for_collectables: false,
    //         //   applicable_check_box_for_qof: false,
    //         //   noncovered_security_indicator: false,
    //         //   loss_not_allowed_indicator: false,
    //         //   basis_reported_to_irs: false,
    //         //   applicable_check_box_of_form_8949: "",
    //         // });
    //       }
    //     });
    //   } else {
    //     scrollTop();
    //   }
  };

  const box13props = {
    clearDate: clearDate,
    callbackRelatedToDate: callbackRelatedToDate,
    dateGroup: _1099R_GROUP,
    dateVal: formData?.date_of_payment ? formData?.date_of_payment : null,
  };

  return (
    <>
      <form className="form-main-container">
        <div
          className="form-wrapper"
          style={{
            border: `2px solid ${style.eFormBorderColor}`,
          }}
        >
          <div className="form-details-amount-wrapper">
            <div className="left-portion-details">
              <PayerDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                isCorrection={isCorrection}
              />
              <RecipientDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                formType={formType}
                isCorrection={isCorrection}
              />
              <Fatca
                displayCode={"12"}
                globalFormData={formData}
                setGlobalFormData={setFormData}
                type={"filing-form"}
                showBox10and11={true}
                _1099RBox10={<Box10 amountObj={amount} changeHandler={handleChange} />}
                _1099RBox11={<Box11 amountObj={amount} changeHandler={handleChange} />}
              />
              <RecAccount
                globalFormData={formData}
                setGlobalFormData={setFormData}
                secondTinNoticeRequired={false}
                show1099RDateOfPayment={true}
                _1099RBox13props={box13props}
              />
            </div>
            <div
              className="right-portion-details"
              style={{
                overflow: "hidden",
                borderBottom: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <div className="top-section">
                <div>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderTop: `1px solid ${style.eFormBorderColor}`,
                      height: "50%",
                    }}
                  >
                    <AmountHeading heading={r1099AmountDisplayTexts.grossDistro} amountNum={"1"} />
                    <Amount
                      type="text"
                      id={AMOUNT_1}
                      placeHolder="0.00"
                      class_="payer-details-input" // Only one class can be passed
                      value={amount.amount1}
                      onChangeHandler={handleChange}
                    />
                  </AmountContainer>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderTop: `1px solid ${style.eFormBorderColor}`,
                      height: "50%",
                    }}
                  >
                    <AmountHeading
                      heading={r1099AmountDisplayTexts.taxableAmount}
                      amountNum={"2a"}
                    />
                    <Amount
                      type="text"
                      id={AMOUNT_2}
                      placeHolder="0.00"
                      class_="payer-details-input" // Only one class can be passed
                      value={amount.amount2}
                      onChangeHandler={handleChange}
                    />
                  </AmountContainer>
                </div>
                <FormDetail
                  formType={formType}
                  taxYear={taxYear}
                  formName={R_1099}
                  newVersion={true}
                />
              </div>

              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{ flexDirection: "row", borderRight: `1px solid black` }}
                >
                  <AmountHeading
                    heading={r1099AmountDisplayTexts.taxableAmountNotDetermined}
                    amountNum="2b"
                  />
                  <input
                    id={TAXABLE_AMOUNT_NOT_DETERMINED}
                    style={{ padding: "0.5rem 1rem" }}
                    type="checkbox"
                    checked={miscFields.taxable_amt_not_determined}
                    onChange={handleMiscFieldChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{ flexDirection: "row" }}
                >
                  <AmountHeading heading={r1099AmountDisplayTexts.totalDistro} />
                  <input
                    id={TOTAl_DISTRO}
                    style={{ padding: "0.5rem 1rem" }}
                    type="checkbox"
                    checked={miscFields.total_distribution_check}
                    onChange={handleMiscFieldChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={r1099AmountDisplayTexts.capitalGain} amountNum="3" />
                  <Amount
                    type="text"
                    id={AMOUNT_3}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount3}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading heading={r1099AmountDisplayTexts.taxWithheld} amountNum="4" />
                  <Amount
                    type="text"
                    id={AMOUNT_4}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount4}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={r1099AmountDisplayTexts.employeeContrib} amountNum="5" />
                  <Amount
                    type="text"
                    id={AMOUNT_5}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount5}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading
                    heading={r1099AmountDisplayTexts.netUnrealizedAppreciation}
                    amountNum="6"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_6}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount6}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <div style={{ display: "flex", width: "50%" }}>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderRight: `1px solid ${style.eFormBorderColor}`,
                      width: "50%",
                    }}
                  >
                    <AmountHeading heading={r1099AmountDisplayTexts.distroCode} amountNum="7" />
                    {/* Put the distro code component.
                  Either of the two component will have a none option, preferrable the second one.
                  Handle that based on the prop sent to the component */}
                  </AmountContainer>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderRight: `1px solid ${style.eFormBorderColor}`,
                      width: "50%",
                    }}
                  >
                    <AmountHeading heading={r1099AmountDisplayTexts.distroCodeType} />
                    <input type="checkbox" />
                  </AmountContainer>
                </div>
                <div style={{ display: "flex", width: "50%" }}>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderRight: `1px solid ${style.eFormBorderColor}`,
                      width: "60%",
                    }}
                  >
                    <AmountHeading heading={r1099AmountDisplayTexts.other} amountNum="8" />
                    <Amount
                      type="text"
                      id={AMOUNT_8}
                      placeHolder="0.00"
                      class_="payer-details-input" // Only one class can be passed
                      value={amount.amount8}
                      onChangeHandler={handleChange}
                    />
                  </AmountContainer>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      width: "40%",
                    }}
                  >
                    <AmountHeading heading={""} />
                    <PercentageAmount
                      type="text"
                      id={"OTHER_PERCENT_VALUE"}
                      placeHolder=""
                      class_="payer-details-input" // Only one class can be passed
                      value={""}
                      onChangeHandler={handleChange}
                    />
                  </AmountContainer>
                </div>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading
                    heading={r1099AmountDisplayTexts.totalDistroPercent}
                    amountNum="9a"
                  />
                  <PercentageAmount
                    type="text"
                    id={"TOTAL_DISTRO_PERCENT"}
                    placeHolder=""
                    class_="payer-details-input" // Only one class can be passed
                    value={""}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading
                    heading={r1099AmountDisplayTexts.totalEmployeeContrib}
                    amountNum="9b"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_9}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount9}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                }}
              />
            </div>
          </div>
        </div>
        <StateInfo
          state1Heading={stateInfoDisplayTexts.r1099.state1.heading}
          state2Heading={stateInfoDisplayTexts.r1099.state2.heading}
          taxWithheldLabel={stateInfoDisplayTexts.r1099.stateTaxWithheldLabel}
          stateVolLabel={stateInfoDisplayTexts.r1099.stateLabel}
          stateNoLabel={stateInfoDisplayTexts.r1099.stateNoLabel}
          stateIncomeLabel={stateInfoDisplayTexts.r1099.stateIncLabel}
          globalFormData={formData}
          setGlobalFormData={setFormData}
          formType={formType}
          isCorrection={isCorrection}
        />
        <LocalFiling />
      </form>
      {!isEditable ? (
        <SubmitButton handleSubmit={handleFormSubmit} />
      ) : (
        <UpdateButton handleUpdate={handleFormSubmit} />
      )}
    </>
  );
}
