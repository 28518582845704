import { DISPLAY_POPUP } from "../actions/action.types";

const displayPopup = (state = false, action: any) => {
  if (action.type === DISPLAY_POPUP) {
    return action.payload;
  } else {
    return state;
  }
};

export default displayPopup;
