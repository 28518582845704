import React from "react";
import { SIGNIN } from "../../lib/routes";
import { Link } from "react-router-dom";

interface Props extends React.HTMLAttributes<HTMLElement> {
  resStatus: number;
}

export default function EmailActivated(props: Props) {
  return (
    <div className={`${props.className}`}>
      <section className="verified-container h-[100vh] flex flex-col items-center gap-11 py-20">
        <div className="verified-logo flex justify-center w-[15%] pl-6">
          <img src="/Assets/check-mark.svg" alt="verified" className="w-[100%]" />
        </div>
        <div className="verified-text">
          <h1 className="text-2xl">
            Email {props.resStatus === 201 ? "already" : "successfully"} verified!
          </h1>
        </div>
        <div className="redirect-wrapper text-lg">
          <p>
            Go to the{" "}
            <span className="login">
              <Link to={SIGNIN} className="text-taxeve-primary-blue font-bold">
                Login
              </Link>
            </span>{" "}
            page
          </p>
        </div>
      </section>
    </div>
  );
}
