import { axiosInstance } from "../axiosConfig";

export default async function getRefreshToken(initialRefreshToken: any) {
  try {
    const { data } = await axiosInstance.get("/get_refresh_token", {
      headers: {
        refresh_token: initialRefreshToken,
      },
    });
    return data;
  } catch (err) {
    throw err;
  }
}
