import React from "react";
import StateAmount from "../StateAmount/StateAmount";

export default function StateNumber({
  heading,
  state1,
  state2,
  stateNo1,
  stateNo2,
  styles = {},
  onLeft = false,
}: any) {
  return (
    <div style={{ borderRight: `1px solid black`, ...styles }} className="rf-state-number">
      <div className="rf-si-heading" style={{ padding: "0.25rem 0.5rem" }}>
        {heading}
      </div>
      <StateAmount
        amt1={stateNo1}
        amt2={stateNo2}
        state1={state1}
        state2={state2}
        onLeft={onLeft}
      />
    </div>
  );
}
