import { axiosInstance } from "../../axiosConfig";
import devLogInstance, { prodLogInstance } from "../../loggerConfig";

export default async function getPayers() {
  try {
    const { data } = await axiosInstance.get("/get_payer_names_for_correction");
    devLogInstance.log("Payer names for correction: ");
    devLogInstance.log(data);
    return data;
  } catch (err) {
    prodLogInstance.error(err);
    throw err;
  }
}
