import { Dispatch } from "@reduxjs/toolkit";
import { PdfViewerModalReducerAction } from "../../../interfaces/main";
import { TO_DISPLAY_PDF_VIEWER_MODAL } from "../../redux/actions/action.types";

export default function toDisplayPdfViewerModal(
  toDisplay: boolean,
  pdfBlob: Blob | null,
  dispatch: Dispatch<PdfViewerModalReducerAction>
) {
  dispatch({
    type: TO_DISPLAY_PDF_VIEWER_MODAL,
    payload: {
      status: toDisplay,
      pdfBlob,
    },
  });
}
