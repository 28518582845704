import { SET_FORM_HISTORY_CART_IDS } from "../../redux/actions/action.types";

export function pushCartId(stateSetter: any, cartId: string | null) {
  stateSetter({
    type: SET_FORM_HISTORY_CART_IDS,
    payload: {
      cartid: cartId,
      updateType: "push",
    },
  });
}

export function removeCartId(stateSetter: any, cartId: string | null) {
  stateSetter({
    type: SET_FORM_HISTORY_CART_IDS,
    payload: {
      cartid: cartId,
      updateType: "remove",
    },
  });
}

export function resetFormHistoryCartIdsArr(stateSetter: any, cartId: string | null) {
  stateSetter({
    type: SET_FORM_HISTORY_CART_IDS,
    payload: {
      cartid: cartId,
      updateType: "reset",
    },
  });
}
