import React from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import displayManualGroupCreatingModal from "../../../../lib/StateSetters/StaffAndGroupRelated/displayManualGroupCreatingModal";
import { CreateGroupModalActionPayload } from "../../../../redux/reducers/Modal/displayManualGroupCreatingModal";
import CreateGroupForm from "../../../BusinessSettingsRelated/GroupRelated/CreateGroupForm";

export default function ManualGroupCreatingModal() {
  const dispatch = useDispatch();

  const createGroupActionProps: CreateGroupModalActionPayload = useSelector(
    (state: any) => state.displayManualGroupCreatingModal
  );

  // Close the modal
  const closeModal = () => {
    displayManualGroupCreatingModal({ toDisplay: false, handleCreatingGroup: null }, dispatch);
  };

  return (
    <div className="form-modal shadow-lg">
      <div className="form-modal-header bg-taxeve-primary-violet text-white flex items-center justify-between">
        <h2>Manually Create Group</h2>
        <IoIosCloseCircleOutline className="text-3xl cursor-pointer" onClick={closeModal} />
      </div>
      <div className="form-modal-body bg-white p-4">
        <CreateGroupForm handleCreatingGroup={createGroupActionProps.handleCreatingGroup} />
      </div>
    </div>
  );
}
