import { SET_POPUP_MESSAGE } from "../actions/action.types";

const popupMessage = (state = "", action: any) => {
  if (action.type === SET_POPUP_MESSAGE) {
    return action.payload;
  } else {
    return state;
  }
};

export default popupMessage;
