import React from "react";
import "./TaxYearNewFont.css";

interface Props {
  taxYear: number;
}

export default function TaxYearNewFont({ taxYear }: Props) {
  return (
    <main className="taxyear-new-font-main" style={{ paddingLeft: "0.85rem" }}>
      <span className="outline-font">
        <h1>{taxYear.toString().substring(0, 2)}</h1>
      </span>
      <span className="fill-font">
        <h1>{taxYear.toString().substring(2, 4)}</h1>
      </span>
    </main>
  );
}
