import React from "react";
import { useSelector } from "react-redux";
import "./UpdateButton.css";
import { useState } from "react";
import { UPDATE_AND_FILE_NEW, UPDATE_CONTINUE } from "../../lib/constants";
import redirect from "../../lib/RoutingMethods/redirect";
import { useLocation } from "react-router-dom";
import devLogInstance from "../../lib/loggerConfig";

export default function UpdateButton({ handleUpdate }: any) {
  const style = useSelector((state: any) => state.themeChange);
  const [isHovered, setIsHovered] = useState({
    isContinue: false,
    isFileNew: false,
  });
  const location = useLocation();

  devLogInstance.log(location.pathname);

  return (
    <main className="update-btn-main flex justify-center gap-10">
      <button
        id={UPDATE_AND_FILE_NEW}
        className="px-6 py-4 rounded-xl bg-taxeve-primary-blue"
        onMouseOver={() => {
          setIsHovered({
            isContinue: false,
            isFileNew: true,
          });
        }}
        onMouseLeave={() => {
          setIsHovered({
            isContinue: false,
            isFileNew: true,
          });
        }}
        onClick={() => {
          redirect(location.pathname);
        }}
        style={{
          // background: style.btnSecondaryBg,
          color: style.btnFillFontColor,
          boxShadow: isHovered.isFileNew ? `4px 4px 20px ${style.btnBg}` : "",
        }}
      >
        Update & File New
      </button>

      <button
        id={UPDATE_CONTINUE}
        className="px-6 py-4 rounded-xl"
        onMouseOver={() => {
          setIsHovered({
            isContinue: true,
            isFileNew: false,
          });
        }}
        onMouseLeave={() => {
          setIsHovered({
            isContinue: false,
            isFileNew: false,
          });
        }}
        onClick={handleUpdate}
        style={{
          background: style.btnSecondaryBg,
          color: style.btnFillFontColor,
          boxShadow: isHovered.isContinue ? `4px 4px 20px ${style.btnSecondaryBg}` : "",
        }}
      >
        Update & Continue
      </button>
    </main>
  );
}
