import React, { useMemo } from "react";
import "../styles/EmailVerification.css";
import { emailActivatePopupText, resendBtnText } from "../lib/languagePacks/en-us";
import { useDisableBtn, useResendTimer } from "../lib/customHooks";
import { useDispatch } from "react-redux";
import resendConfirmationMail from "../lib/resendConfirmationMail";
import logger from "../logger";
import handlePopup from "../lib/popup/handlePopup";
import { ERROR_POPUP, SUCCESS_POPUP } from "../lib/constants";
import { SIGNIN } from "../lib/routes";

export default function EmailVerification({ responseType, userEmail }: any) {
  // const style = useSelector((state: any) => state.themeChange);
  const [toDisableBtn, setToDisableBtn] = useDisableBtn(true);
  const [setToStartTimer, timer] = useResendTimer(true);
  const dispatch = useDispatch();

  //handle resend button click
  const handleClick = async () => {
    setToDisableBtn(true);
    setToStartTimer(true);
    try {
      await resendConfirmationMail(userEmail);
      const message = emailActivatePopupText?.resendEmailText;
      handlePopup(message, SUCCESS_POPUP, dispatch);
    } catch (err: any) {
      logger.error(err.response.data.Error);
      const message = err.response.data.Error;
      handlePopup(message, ERROR_POPUP, dispatch);
    }
  };

  // stop the timer when 30 sec is over
  useMemo(() => {
    if (timer === 0) {
      setToDisableBtn(false);
      setToStartTimer(false);
    }
  }, [timer, setToDisableBtn, setToStartTimer]);
  return (
    <div className="w-[100%] sm:w-[50%] email-activation-main px-10">
      {responseType === "emailActivation" && (
        <div className="email-activation-container">
          <div className="email-activation-img-container flex justify-center">
            <img
              src="/Assets/email_icon.svg"
              alt="Email Activation"
              className="email-activation-img"
            />
          </div>

          <div className="email-activate-msg-container">
            <h3>
              {emailActivatePopupText.message} - {userEmail}
            </h3>
            <h4>{emailActivatePopupText.linkExpiryText}</h4>
          </div>

          <div className="resend-btn-container">
            <button
              disabled={toDisableBtn}
              onClick={handleClick}
              className={`primary-button ${toDisableBtn ? "opacity-50" : "opacity-100"}`}
            >
              {resendBtnText.text}
            </button>
          </div>
          {toDisableBtn && (
            <div className="resend-timer-text">
              {resendBtnText.timerText} ({timer})
            </div>
          )}
          <div className="redirect-text-container">
            <h3>
              {emailActivatePopupText.redirectText}{" "}
              <a href={SIGNIN} className="text-taxeve-primary-blue underline underline-offset-2">
                {emailActivatePopupText.redirectLinkText}
              </a>
            </h3>
          </div>
        </div>
      )}
    </div>
  );
}
