import { GroupRecord, PayerUnderStaffOrGroup, StaffRecord } from "../../../../interfaces/main";
import { TO_DISPLAY_PAYER_UNASSIGNMENT_MODAL } from "../../actions/action.types";

export interface PayerUnassignmentModalActionProps {
  type: string;
  payload: {
    status: boolean;
    isStaff: boolean;
    staffOrGroupId: string;
    assignedPayers: PayerUnderStaffOrGroup[];
    updateStaffRecords?: React.Dispatch<React.SetStateAction<StaffRecord[]>> | null;
    updateGroupRecords?: React.Dispatch<React.SetStateAction<GroupRecord[]>> | null;
    staffName?: string;
    staffEmail?: string;
    groupName?: string;
  };
}

const initialState = {
  status: false,
  isStaff: false,
  staffOrGroupId: "",
  assignedPayers: [],
  updateStaffRecords: null,
  updateGroupRecords: null,
};

const displayPayerUnassignmentModal = (
  state = initialState,
  action: PayerUnassignmentModalActionProps
) => {
  if (action.type === TO_DISPLAY_PAYER_UNASSIGNMENT_MODAL) return action.payload;
  return state;
};

export default displayPayerUnassignmentModal;
