import { SET_ZIPCODE, PAYER_ZIP, REC_ZIP } from "../actions/action.types";

const initialState = {
  payer: "",
  recipient: "",
};

const zipcode = (state = initialState, action: any) => {
  if (action.type === SET_ZIPCODE) {
    if (action.zipType === PAYER_ZIP) {
      return {
        ...state,
        payer: action.payload,
      };
    } else if (action.zipType === REC_ZIP) {
      return {
        ...state,
        recipient: action.payload,
      };
    }
  } else {
    return state;
  }
};

export default zipcode;
