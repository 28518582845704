import React from "react";
import { useSelector } from "react-redux";
import PayerDetails from "../../forms/GlobalFormComponents/PayerDetails";
import { PAGE_UPLOAD } from "../../../lib/constants";
import SubmitButton from "../../SubmitButton/SubmitButton";

export default function AddPayer({ formData, setFormData, handleSubmit, formType, taxYear }: any) {
  const style = useSelector((state: any) => state.themeChange);
  return (
    <main className="page-select-payer">
      <div className="page-heading">
        <h2>
          Form: {formType} <div style={{ borderBottom: "3px double black" }} />
        </h2>
        <h2>
          Tax Year: {taxYear} <div style={{ borderBottom: "3px double black" }} />
        </h2>
      </div>
      <div className="pd-wrapper" style={{ border: `1px solid ${style.tableHeadingPrimaryColor}` }}>
        <div
          className="pd-wrapper-heading"
          style={{
            background: style.tableHeadingPrimaryColor,
          }}
        >
          <h2>Add Company Details</h2>
        </div>
        <PayerDetails
          pageType={PAGE_UPLOAD}
          globalFormData={formData}
          setGlobalFormData={setFormData}
          recipientNotNeeded={true}
        />
      </div>
      <SubmitButton pageType={PAGE_UPLOAD} handleSubmit={handleSubmit} />
    </main>
  );
}
