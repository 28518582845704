import React from "react";
import { filerPaymentType, indicatorTexts } from "../../../../lib/languagePacks/en-us";
import { FILER_PAYMENT_TYPE, PAYMENT_CARD, THIRD_PARTY_NETWORK } from "../../../../lib/constants";
import Indicator from "../../../Indicator";

export default function PaymentType({ handleChange, paymentTypeIndicator, required }: any) {
  return (
    <div className="filer-payment-type-container">
      <div style={{ display: "flex", gap: "0.25rem" }}>
        <span style={{ color: "red" }}>*</span> <span>{filerPaymentType.heading}</span>
      </div>
      <div>
        <div className="payment-card-wrapper">
          <label htmlFor={PAYMENT_CARD}>{filerPaymentType.types.paymentCard}</label>
          <input
            id={PAYMENT_CARD}
            type="checkbox"
            onChange={handleChange}
            checked={paymentTypeIndicator === 1}
            disabled={paymentTypeIndicator === 2}
          />
        </div>
        <div className="third-party-network-wrapper">
          <label htmlFor={THIRD_PARTY_NETWORK}>{filerPaymentType.types.thirdPartyNetwork}</label>
          <input
            id={THIRD_PARTY_NETWORK}
            type="checkbox"
            onChange={handleChange}
            checked={paymentTypeIndicator === 2}
            disabled={paymentTypeIndicator === 1}
          />
        </div>
      </div>
      {required && (
        <Indicator type={FILER_PAYMENT_TYPE} message={indicatorTexts.paymentTypeRequired} />
      )}
    </div>
  );
}
