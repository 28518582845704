import React from "react";
import { MdEmail } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { UserAccountInfo } from "../../../../interfaces/main";
import Handler from "../../../lib/Handlers/main";
import { useNavigate } from "react-router-dom";
import handlePopup from "../../../lib/popup/handlePopup";
import { SUCCESS_POPUP } from "../../../lib/constants";
import { useDispatch } from "react-redux";
import { HOME } from "../../../lib/routes";

interface Props extends React.HTMLAttributes<HTMLElement> {
  userDetails: UserAccountInfo | null;
}

export default function UserDetailsCard(props: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSwitchingToMain = () => {
    const isStaff = sessionStorage.getItem("isStaff") === "true";

    if (isStaff) {
      Handler.handleSwitchingToMain(navigate);
    } else {
      handlePopup("Continuing in main account", SUCCESS_POPUP, dispatch);
      navigate(HOME);
    }
  };

  return (
    <div className="rounded-xl overflow-hidden shadow-lg w-[25rem]">
      <section className="header bg-taxeve-primary-violet text-white p-4 text-center">
        <h2>Main Account</h2>
      </section>
      <section className="body p-6">
        <div className="border-b border-b-gray-300">
          <div className="flex items-center gap-2 mb-6">
            <FaUser className="text-2xl opacity-70" />
            <p className="text-lg opacity-70">{props.userDetails?.full_name}</p>
          </div>
          <div className="flex items-center gap-2 mb-6">
            <MdEmail className="text-2xl opacity-70" />
            <p className="text-lg opacity-70">{props.userDetails?.email}</p>
          </div>
          {props.userDetails?.phonenumber && (
            <div className="flex items-center gap-2 mb-6">
              <FaPhone className="text-2xl opacity-70" />
              <p className="text-lg opacity-70">{props.userDetails?.phonenumber}</p>
            </div>
          )}
        </div>
      </section>
      <section className="switch-to-main p-4 text-center">
        <button
          id="switch-to-main-btn"
          onClick={handleSwitchingToMain}
          className="px-4 py-2 rounded-lg bg-taxeve-primary-violet text-white hover:opacity-90"
        >
          Continue to main account
        </button>
      </section>
    </div>
  );
}
