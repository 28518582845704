import React, { useEffect, useState } from "react";
import { AddStaffsToGroupActionPayload } from "../../../../redux/reducers/Modal/displayAddStaffsToGroupModal";
import { useDispatch, useSelector } from "react-redux";
import { IoIosCloseCircleOutline } from "react-icons/io";
import displayAddStaffsToGroupModal from "../../../../lib/StateSetters/StaffAndGroupRelated/displayAddStaffsToGroupModal";
import { StaffRecord } from "../../../../../interfaces/main";
import { getStaff } from "../../../BusinessSettingsRelated/ManageStaff";
import StaffTable from "../../../BusinessSettingsRelated/StaffRelated/StaffTable";
import Handler from "../../../../lib/Handlers/main";
import devLogInstance from "../../../../lib/loggerConfig";

export default function AddStaffsToGroupModal() {
  const dispatch = useDispatch();
  const [staffs, setStaffs] = useState<StaffRecord[]>([]);
  const [selectedStaffs, updateSelectedStaffs] = useState<string[]>([]);

  const { updateGroupRecords, groupId }: AddStaffsToGroupActionPayload = useSelector(
    (state: any) => state.displayAddStaffsToGroupModal
  );

  const closeModal = () => displayAddStaffsToGroupModal(false, "", null, dispatch);

  const deselectAllStaff = () => updateSelectedStaffs([]);

  // Get all the staffs created by the current user
  useEffect(() => {
    getStaff(setStaffs);

    return () => setStaffs([]);
  }, []);

  // Handle adding members
  const handleAddingMembers = (e: React.MouseEvent<HTMLButtonElement>) => {
    updateGroupRecords
      ? Handler.handleAddingStaffsToGroup(groupId, selectedStaffs, updateGroupRecords)
      : devLogInstance.log("updateGroupRecords method is null!");

    closeModal();
  };

  // *** Handle any operation on the staff records inside the modal ***
  // Handle when a single staff is selected
  const handleSingleStaffSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const staffId = e.currentTarget.id;

    if (isChecked) {
      updateSelectedStaffs([...selectedStaffs, staffId]);
    } else {
      const filteredStaffs = selectedStaffs.filter((id) => id !== staffId);
      updateSelectedStaffs(filteredStaffs);
    }
  };

  // Handle when all the staffs are selected
  const handleAllStaffSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      const allStaffs = staffs.map((record) => record._id);
      updateSelectedStaffs(allStaffs);
    } else {
      deselectAllStaff();
    }
  };

  return (
    <div className="form-modal shadow-lg">
      <div className="form-modal-header bg-taxeve-primary-violet text-white flex items-center justify-between">
        <h2>Add Members</h2>
        <IoIosCloseCircleOutline className="text-3xl cursor-pointer" onClick={closeModal} />
      </div>
      <div className="form-modal-body bg-white py">
        <header className="p-4 underline underline-offset-1 font-bold">
          Select all the Members you want to add to the current Group:
        </header>
        <body className="px-4 max-h-72 overflow-auto">
          {staffs.length > 0 ? (
            <StaffTable
              insideModal={true}
              staffRecords={staffs}
              setStaffRecords={setStaffs}
              handleSingleStaffSelection={handleSingleStaffSelection}
              handleAllStaffSelection={handleAllStaffSelection}
              selectedStaffIds={selectedStaffs}
              deselectAllStaff={deselectAllStaff}
            />
          ) : (
            <div className="p-8 text-center font-bold text-2xl opacity-40">
              <em>You have not added any members yet.</em>
            </div>
          )}
        </body>
      </div>
      <footer className="form-modal-footer bg-white">
        <button
          id="add-members-btn"
          className={`px-4 py-2 rounded-lg bg-taxeve-primary-violet text-white hover:opacity-80 ${
            selectedStaffs.length === 0 && "cursor-not-allowed opacity-80"
          }`}
          disabled={selectedStaffs.length === 0}
          onClick={handleAddingMembers}
        >
          Add Members
        </button>
      </footer>
    </div>
  );
}
