import React from "react";
import "../../../../../styles/forms/Form.css";
import { FiDollarSign } from "react-icons/fi";

export default function Amount({ type, id, placeHolder, class_, value, onChangeHandler }: any) {
  return (
    <div className="amount-input-container">
      <div className="dollar-sign">
        <FiDollarSign />
      </div>
      <input
        type={type}
        id={id}
        placeholder={placeHolder}
        className={`amount-input ${class_}`}
        value={value}
        onChange={onChangeHandler}
        autoComplete="off"
      />
    </div>
  );
}
