import React, { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";

export default function PdfViewerModal({ pdfBlob }: { pdfBlob: Blob }) {
  console.log(pdfBlob);
  const [dataURL, setDataURL] = useState<string>("");

  useEffect(() => {
    const reader = new FileReader();
    reader.readAsDataURL(pdfBlob);
    reader.onloadend = () => {
      const result = reader.result;
      if (result && typeof result === "string") {
        setDataURL(result);
      }
    };

    return () => {
      setDataURL("");
    };
  }, [pdfBlob]);
  return (
    <div className="fixed top-[3rem] overflow-hidden flex gap-0">
      <embed src={dataURL} type="application/pdf" height={700} width={1000} className="" />
      <i>
        <IoMdCloseCircle className="text-4xl text-red-600 cursor-pointer bg-[#323639]" />
      </i>
    </div>
  );
}
