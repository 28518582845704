// import Handler from "../Handlers/main";
// import { getTheFileName } from "../../components/forms/ReviewTable/BulkAction/BulkAction.component";
// import DisableScreen from "./disableScreen";
// import { BulkAction } from "./BulkAction";
import devLogInstance from "../loggerConfig";

export class Download {
  static downloadbyBlob(blob: Blob, filename: string) {
    // Create a blob URL
    const blobURL = URL.createObjectURL(blob);
    devLogInstance.log(`Blob URL ---> ${blobURL}`);
    // Create anchor element and assign attributes
    let a = Object.assign(document.createElement("a"), {
      href: blobURL,
      style: "display:none",
      download: filename,
    });
    // Append the element
    document.body.appendChild(a);
    // Click the element
    a.click();
    // Revoke the blob url
    URL.revokeObjectURL(blobURL);
    devLogInstance.log("Blob URL removed");
    // Remove the anchor element
    a.remove();
  }
}
