import { Dispatch } from "@reduxjs/toolkit";
import { IsRecipientAuthenticatedAction } from "../../../interfaces/main";
import Cookie from "../utils/Cookie";
import SessionStorage from "../LocalCaching/SessionStorage";
import { SET_IS_RECIPIENT_AUTHENTICATED } from "../../redux/actions/action.types";
import { NavigateFunction } from "react-router-dom";
import { ONLINE_ACCESS_CONSENT } from "../routes";

export default function loginRecipient(
  rectoken: string,
  consentStatus: string,
  fid: string,
  dispatch: Dispatch<IsRecipientAuthenticatedAction>,
  navigate: NavigateFunction
) {
  // Save the rec token and cache the consent pending status
  Cookie.setCookie("recipient_token", rectoken);
  SessionStorage.setCacheItem("rec_consent_status", consentStatus);
  SessionStorage.setCacheItem("fid", fid);

  dispatch({
    type: SET_IS_RECIPIENT_AUTHENTICATED,
    payload: true,
  });

  navigate(ONLINE_ACCESS_CONSENT);
}
