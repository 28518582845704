import { PdfViewerModalReducerAction } from "../../../../interfaces/main";
import { TO_DISPLAY_PDF_VIEWER_MODAL } from "../../actions/action.types";

const displayPdfViewerModal = (
  state = { status: false, pdfBlob: null },
  action: PdfViewerModalReducerAction
) => {
  const { type, payload } = action;
  if (type === TO_DISPLAY_PDF_VIEWER_MODAL) {
    return payload;
  }
  return state;
};

export default displayPdfViewerModal;
