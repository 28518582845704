import React from "react";
import { useState } from "react";
import Price from "../../lib/Price/main";
import "./OrderDetails.css";
import PaymentButton from "./PaymentButton/PaymentButton";
import { useSelector } from "react-redux";
// import getUserDetailsLocally from "../../lib/RetrieveUserDetailsLocally/getUserDetailsLocally";

export default function OrderDetails({ cartIds, totalForms, amounts, formsAlreadyFiled }: any) {
  const { totalOA, totalPM, totalSF } = useSelector((state: any) => state.totalCartValues);
  const style = useSelector((state: any) => state.themeChange);
  const [isAcknowledged, setIsAcknowledged] = useState(true);
  return (
    <div className="order-details-container">
      <div className="order-details">
        <div className="heading od-heading">
          <h3>Order Details</h3>
        </div>
        <div className="body od-body" style={{ boxShadow: `3px 3px 10px ${style.boxShadowColor}` }}>
          <div
            className="od-price-breakage"
            style={{
              fontSize: "1rem",
              background: style.cartTableTopPanel,
              color: style.cartTableTopPanelFontColor,
            }}
          >
            <p>Filing Type</p>
            <p>Quantity</p>
            <p />
          </div>
          {totalForms > 0 && (
            <div className="od-price-breakage">
              <p>Federal Filing</p>
              <p>{totalForms}</p>
              <p>${Price.calculateBasePrice(formsAlreadyFiled, totalForms).toFixed(2)}</p>
            </div>
          )}
          {totalPM > 0 && (
            <div className="od-price-breakage">
              <p>Postal Mailing (${Price.postalMailingPrice.toFixed(2)})</p>
              <p>{totalPM}</p>
              <p>${(Price.postalMailingPrice * totalPM).toFixed(2)}</p>
            </div>
          )}
          {totalOA > 0 && (
            <div className="od-price-breakage">
              <p>Online Access (${Price.onlineAccessPrice.toFixed(2)})</p>
              <p>{totalOA}</p>
              <p>${(Price.onlineAccessPrice * totalOA).toFixed(2)}</p>
            </div>
          )}
          {totalSF > 0 && (
            <div className="od-price-breakage">
              <p>State Filing (${Price.stateFileprice.toFixed(2)})</p>
              <p>{totalSF}</p>
              <p>${(Price.stateFileprice * totalSF).toFixed(2)}</p>
            </div>
          )}
        </div>
        <div
          className="od-total-amount"
          style={{ boxShadow: `3px 3px 10px ${style.boxShadowColor}` }}
        >
          <div className="od-total-amount-child">
            <p>Total Amount</p>
            <p>${amounts.totalAmount.toFixed(2)}</p>
          </div>
          {amounts.discount > 0 && (
            <div className="od-total-amount-child flex items-center">
              <p className="flex flex-col">
                Discount{" "}
                <span className="text-sm font-bold">
                  <em>(Calculated on the Federal Filing price only)</em>
                </span>
              </p>
              {Price.discountType === "amount" ? (
                <p>(-${amounts.discount.toFixed(2)})</p>
              ) : (
                Price.discountType === "percentage" && <p>(-{amounts.discount}%)</p>
              )}
            </div>
          )}
          <div className="od-total-amount-child" style={{ fontSize: "0.9rem" }}>
            <h2>You pay</h2>
            <h2>${amounts.totalAmtPayable.toFixed(2)}</h2>
          </div>
        </div>
        {/* <div
          className="payment-disclaimer"
          onClick={() => {
            setIsAcknowledged(!isAcknowledged);
          }}
        >
          <input
            type="checkbox"
            checked={isAcknowledged}
            onChange={() => {
              setIsAcknowledged(!isAcknowledged);
            }}
          />
          <p>
            By checking this box, I acknowledge that I have reviewed TaxEve's recommendation for
            state e-filing and I agree to skip this for the alerted state(s) and proceed with
            filing.
          </p>
        </div> */}
        <PaymentButton cartIds={cartIds} isDisabled={!isAcknowledged} />
      </div>
    </div>
  );
}
