import React from "react";
import logger from "../../../logger";
import PayerAddressBlock from "./InternalComponents/PayerAddressBlock";
import RecipientAddressBlock from "./InternalComponents/RecipientAddressBlock";
import { useSelector, useDispatch } from "react-redux";
import {
  PAYER_ZIP,
  REC_ZIP,
  SET_AL_WARN_MSG,
  SET_FA_WARN_MSG,
  SET_ZIPCODE,
  TO_SHOW_AL_WARNING,
  TO_SHOW_FA_WARNING,
  TO_SHOW_FORM_VALIDATION_ERR,
} from "../../../redux/actions/action.types";
import isLessThan46 from "../../../lib/form/utils/ForeignAddressUtils/isLessThan46";
import validateFALimit from "../../../lib/form/utils/ForeignAddressUtils/validateFALimit";
import {
  addressLine_1_limit,
  addressLine_1_and_2_limit,
  PAYER_ADDRESS_1,
  RECIPIENT_ADDRESS_1,
  PAYER_CITY,
  RECIPIENT_CITY,
  PAYER_ZIPCODE,
  RECIPIENT_ZIPCODE,
} from "../../../lib/constants";
import validateAddressLineLimit from "../../../lib/form/utils/AddressLine/validateAddressLineLimit";

function Address({ globalFormData, setGlobalFormData, type, formType, isCorrection }: any) {
  logger.log(`Foreign address ----> ${globalFormData.payer_is_foreign_address}`);
  const zipcode = useSelector((state: any) => state.zipcode);
  const showFAWarning = useSelector((state: any) => state.showFAWarning);
  const showALWarning = useSelector((state: any) => state.showALWarning);
  const dispatch = useDispatch();

  // handle foreign address change
  const handleChange = (e: any) => {
    const { value, id } = e.target;
    switch (id) {
      case "foreign-address-checkbox":
        if (type === "payer") {
          // Empty all the address fields and set the foreign address status
          setGlobalFormData({
            ...globalFormData,
            payer_is_foreign_address: !globalFormData.payer_is_foreign_address,
            payer_city: "",
            payer_country: "",
            payer_zipcode: "",
            payer_state: "",
            payer_is_new_or_updated: true,
          });
          // Update the payer zipcode display
          dispatch({
            type: SET_ZIPCODE,
            zipType: PAYER_ZIP,
            payload: "",
          });
        } else if (type === "recipient") {
          setGlobalFormData({
            ...globalFormData,
            recipient_is_foreign_address: !globalFormData.recipient_is_foreign_address,
            recipient_city: "",
            recipient_country: "",
            recipient_zipcode: "",
            recipient_state: "",
            recipient_is_new_or_updated: true,
          });
          // Update the rec zipcode display
          dispatch({
            type: SET_ZIPCODE,
            zipType: REC_ZIP,
            payload: "",
          });
        }
        break;

      case "address-1":
        if (type === "payer") {
          // Remove any mandatory field waring onn payer address line
          dispatch({
            type: TO_SHOW_FORM_VALIDATION_ERR,
            payload: {
              type: PAYER_ADDRESS_1,
              toShowErr: false,
            },
          });

          if (globalFormData.payer_address_line2.length === 0) {
            if (
              value.length <= globalFormData.payer_address_line1.length ||
              globalFormData.payer_address_line1.length +
                globalFormData.payer_address_line2.length <
                addressLine_1_limit
            ) {
              setGlobalFormData({
                ...globalFormData,
                payer_address_line1: value,
                payer_is_new_or_updated: true,
              });
            }
          } else {
            const addressLine2Len = globalFormData.payer_address_line2.length;
            const maxChar = addressLine_1_and_2_limit;
            const remainingChar = maxChar - addressLine2Len;
            setGlobalFormData({
              ...globalFormData,
              payer_address_line1: value.substring(0, remainingChar),
              payer_is_new_or_updated: true,
            });

            // Hide any displayed warning
            dispatch({
              type: TO_SHOW_AL_WARNING,
              payload: {
                ...showALWarning,
                onPayerAL1: false,
              },
            });

            // Validate the address line limit
            const { status, remainingChars, messagePt1, messagePt2 } = validateAddressLineLimit(
              value,
              globalFormData.payer_address_line2
            );

            if (status === true) {
              // Update the address line limit warning
              dispatch({
                type: SET_AL_WARN_MSG,
                payload:
                  messagePt2.length !== 0
                    ? messagePt1 + `${remainingChars}` + messagePt2
                    : messagePt1,
              });
              // Update the display warning status
              dispatch({
                type: TO_SHOW_AL_WARNING,
                payload: {
                  ...showALWarning,
                  onPayerAL1: true,
                },
              });
            }
          }
        } else if (type === "recipient") {
          // Remove any mandatory field waring onn recipient address line
          dispatch({
            type: TO_SHOW_FORM_VALIDATION_ERR,
            payload: {
              type: RECIPIENT_ADDRESS_1,
              toShowErr: false,
            },
          });

          if (globalFormData.recipient_address_line2.length === 0) {
            if (
              value.length <= globalFormData.recipient_address_line1.length ||
              globalFormData.recipient_address_line1.length +
                globalFormData.recipient_address_line2.length <
                40
            ) {
              setGlobalFormData({
                ...globalFormData,
                recipient_address_line1: value,
                recipient_is_new_or_updated: true,
              });
            }
          } else {
            const addressLine2Len = globalFormData.recipient_address_line2.length;
            const maxChar = 39;
            const remainingChar = maxChar - addressLine2Len;
            setGlobalFormData({
              ...globalFormData,
              recipient_address_line1: value.substring(0, remainingChar),
              recipient_is_new_or_updated: true,
            });

            // Hide any displayed warning
            dispatch({
              type: TO_SHOW_AL_WARNING,
              payload: {
                ...showALWarning,
                onRecipientAL1: false,
              },
            });

            // Validate the address line limit
            const { status, remainingChars, messagePt1, messagePt2 } = validateAddressLineLimit(
              value,
              globalFormData.recipient_address_line2
            );

            if (status === true) {
              // Update the address line limit warning
              dispatch({
                type: SET_AL_WARN_MSG,
                payload:
                  messagePt2.length !== 0
                    ? messagePt1 + `${remainingChars}` + messagePt2
                    : messagePt1,
              });
              // Update the display warning status
              dispatch({
                type: TO_SHOW_AL_WARNING,
                payload: {
                  ...showALWarning,
                  onRecipientAL1: true,
                },
              });
            }
          }
        }
        break;

      case "address-2":
        if (type === "payer") {
          const addressLine1Len = globalFormData.payer_address_line1.length;
          const maxChar = 39;
          const remainingChar = maxChar - addressLine1Len;
          setGlobalFormData({
            ...globalFormData,
            payer_address_line2: value.substring(0, remainingChar),
            payer_is_new_or_updated: true,
          });

          // Hide any displayed warning
          dispatch({
            type: TO_SHOW_AL_WARNING,
            payload: {
              ...showALWarning,
              onPayerAL2: false,
            },
          });

          // Validate the address line limit
          const { status, remainingChars, messagePt1, messagePt2 } = validateAddressLineLimit(
            globalFormData.payer_address_line1,
            value
          );

          if (status === true) {
            // Update the address line limit warning
            dispatch({
              type: SET_AL_WARN_MSG,
              payload:
                messagePt2.length !== 0
                  ? messagePt1 + `${remainingChars}` + messagePt2
                  : messagePt1,
            });
            // Update the display warning status
            dispatch({
              type: TO_SHOW_AL_WARNING,
              payload: {
                ...showALWarning,
                onPayerAL2: true,
              },
            });
          }
        } else if (type === "recipient") {
          const addressLine1Len = globalFormData.recipient_address_line1.length;
          const maxChar = 39;
          const remainingChar = maxChar - addressLine1Len;
          setGlobalFormData({
            ...globalFormData,
            recipient_address_line2: value.substring(0, remainingChar),
            recipient_is_new_or_updated: true,
          });

          // Hide any displayed warning
          dispatch({
            type: TO_SHOW_AL_WARNING,
            payload: {
              ...showALWarning,
              onRecipientAL2: false,
            },
          });

          // Validate the address line limit
          const { status, remainingChars, messagePt1, messagePt2 } = validateAddressLineLimit(
            globalFormData.recipient_address_line1,
            value
          );

          if (status === true) {
            // Set the warning message
            dispatch({
              type: SET_AL_WARN_MSG,
              payload:
                messagePt2.length !== 0
                  ? messagePt1 + `${remainingChars}` + messagePt2
                  : messagePt1,
            });
            // Display the warning
            dispatch({
              type: TO_SHOW_AL_WARNING,
              payload: {
                ...showALWarning,
                onRecipientAL2: true,
              },
            });
          }
        }
        break;

      case "city":
        if (type === "payer") {
          // Remove any mandatory warning from payer city field
          dispatch({
            type: TO_SHOW_FORM_VALIDATION_ERR,
            payload: {
              type: PAYER_CITY,
              toShowErr: false,
            },
          });

          if (!globalFormData.payer_is_foreign_address && value.length <= 40) {
            setGlobalFormData({
              ...globalFormData,
              payer_city: value,
              payer_is_new_or_updated: true,
            }); // Not foreign address
          } else if (globalFormData.payer_is_foreign_address) {
            // Set any warning state to false initially, always
            dispatch({
              type: TO_SHOW_FA_WARNING,
              payload: {
                ...showFAWarning,
                onPayerCity: false,
              },
            });

            const isLesserThan46 = isLessThan46(
              value,
              globalFormData.payer_state,
              globalFormData.payer_zipcode
            );

            isLesserThan46 &&
              setGlobalFormData({
                ...globalFormData,
                payer_city: value,
                payer_is_new_or_updated: true,
              });

            // Validate the foreign address CAP limit
            const { status, remainingChars, messagePt1, messagePt2 } = validateFALimit(
              value,
              globalFormData.payer_state,
              globalFormData.payer_zipcode
            );
            if (status === true) {
              // Set the warning message
              dispatch({
                type: SET_FA_WARN_MSG,
                payload:
                  messagePt2?.length !== 0
                    ? messagePt1 + `${remainingChars}` + messagePt2
                    : messagePt1,
              });

              // Set the show warning state to true
              dispatch({
                type: TO_SHOW_FA_WARNING,
                payload: {
                  ...showFAWarning,
                  onPayerCity: true,
                },
              });
            }
          }
        } else if (type === "recipient") {
          // Remove any mandatory warning from recipient city field
          dispatch({
            type: TO_SHOW_FORM_VALIDATION_ERR,
            payload: {
              type: RECIPIENT_CITY,
              toShowErr: false,
            },
          });

          if (!globalFormData.recipient_is_foreign_address && value.length <= 40) {
            setGlobalFormData({
              ...globalFormData,
              recipient_city: value,
              recipient_is_new_or_updated: true,
            }); // Not foreign adddress
          } else if (globalFormData.recipient_is_foreign_address) {
            // Set any warning state to false initially, always
            dispatch({
              type: TO_SHOW_FA_WARNING,
              payload: {
                ...showFAWarning,
                onRecipientCity: false,
              },
            });

            const isLesserThan46 = isLessThan46(
              value,
              globalFormData.recipient_state,
              globalFormData.recipient_zipcode
            );

            isLesserThan46 &&
              setGlobalFormData({
                ...globalFormData,
                recipient_city: value,
                recipient_is_new_or_updated: true,
              });

            // Validate the foreign address CAP limit
            const { status, remainingChars, messagePt1, messagePt2 } = validateFALimit(
              value,
              globalFormData.recipient_state,
              globalFormData.recipient_zipcode
            );
            if (status === true) {
              // Set the warning message
              dispatch({
                type: SET_FA_WARN_MSG,
                payload:
                  messagePt2?.length !== 0
                    ? messagePt1 + `${remainingChars}` + messagePt2
                    : messagePt1,
              });

              // Set the show warning state to true
              dispatch({
                type: TO_SHOW_FA_WARNING,
                payload: {
                  ...showFAWarning,
                  onRecipientCity: true,
                },
              });
            }
          }
        }
        break;

      case "state":
        if (type === "payer") {
          // Hide any displayed warning initially, always
          dispatch({
            type: TO_SHOW_FA_WARNING,
            payload: {
              ...showFAWarning,
              onPayerState: false,
            },
          });

          const isLesserThan46 = isLessThan46(
            globalFormData.payer_city,
            value,
            globalFormData.payer_zipcode
          );

          isLesserThan46 &&
            setGlobalFormData({
              ...globalFormData,
              payer_state: value,
              payer_is_new_or_updated: true,
            }); // For foreign address

          // Validate foreign address CAP limit
          const { status, remainingChars, messagePt1, messagePt2 } = validateFALimit(
            globalFormData.payer_city,
            value,
            globalFormData.payer_zipcode
          );

          if (status === true) {
            // Set the warning message
            dispatch({
              type: SET_FA_WARN_MSG,
              payload:
                messagePt2?.length !== 0
                  ? messagePt1 + `${remainingChars}` + messagePt2
                  : messagePt1,
            });

            // Set the show warning state to true
            dispatch({
              type: TO_SHOW_FA_WARNING,
              payload: {
                ...showFAWarning,
                onPayerState: true,
              },
            });
          }
        } else if (type === "recipient") {
          const isLesserThan46 = isLessThan46(
            globalFormData.recipient_city,
            value,
            globalFormData.recipient_zipcode
          );

          isLesserThan46 &&
            setGlobalFormData({
              ...globalFormData,
              recipient_state: value,
              recipient_is_new_or_updated: true,
            }); // For foreign address

          // Validate foreign address CAP limit
          const { status, remainingChars, messagePt1, messagePt2 } = validateFALimit(
            globalFormData.recipient_city,
            value,
            globalFormData.recipient_zipcode
          );

          if (status === true) {
            // Set the warning message
            dispatch({
              type: SET_FA_WARN_MSG,
              payload:
                messagePt2?.length !== 0
                  ? messagePt1 + `${remainingChars}` + messagePt2
                  : messagePt1,
            });

            // Set the show warning state to true
            dispatch({
              type: TO_SHOW_FA_WARNING,
              payload: {
                ...showFAWarning,
                onRecipientState: true,
              },
            });
          }
        }
        break;

      case "zip-code":
        const zipRegex = /^\d(-|\d)*$/;
        let updatedVal = value.replace("-", "");
        if (updatedVal.length > 5) {
          updatedVal = updatedVal.substring(0, 5) + "-" + updatedVal.substring(5);
        }
        const isValidZip = zipRegex.test(updatedVal);
        if (type === "payer") {
          // Remove any mandatory warning from payer zipcode field
          dispatch({
            type: TO_SHOW_FORM_VALIDATION_ERR,
            payload: {
              type: PAYER_ZIPCODE,
              toShowErr: false,
            },
          });

          if (!globalFormData.payer_is_foreign_address) {
            // If the payer address is not foreign follow this block
            if (isValidZip || updatedVal.length === 0) {
              updatedVal.length <= 10 &&
                dispatch({
                  type: SET_ZIPCODE,
                  zipType: PAYER_ZIP,
                  payload: updatedVal,
                });
              // set the zip code on global data
              updatedVal.length <= 10 &&
                setGlobalFormData({
                  ...globalFormData,
                  payer_zipcode: value.replace("-", ""),
                  payer_is_new_or_updated: true,
                });
            }
          } else {
            // If the payer address is foreign follow this block
            // Hide any displayed warnings initially, always
            dispatch({
              type: TO_SHOW_FA_WARNING,
              payload: {
                ...showFAWarning,
                onPayerZipcode: false,
              },
            });

            const isLesserThan46 = isLessThan46(
              globalFormData.payer_city,
              globalFormData.payer_state,
              updatedVal
            );

            isLesserThan46 &&
              updatedVal.length <= 11 &&
              dispatch({
                type: SET_ZIPCODE,
                zipType: PAYER_ZIP,
                payload: updatedVal.replace("-", ""), //  Remove any hyphen from zip code
              });

            // Set the zip on global data
            isLesserThan46 &&
              setGlobalFormData({
                ...globalFormData,
                payer_zipcode: value.replace("-", ""),
                payer_is_new_or_updated: true,
              });

            // Validate foreign address CAP limit
            const { status, remainingChars, messagePt1, messagePt2 } = validateFALimit(
              globalFormData.payer_city,
              globalFormData.payer_state,
              updatedVal.replace("-", "")
            );

            if (status === true) {
              // Set the warning message
              dispatch({
                type: SET_FA_WARN_MSG,
                payload:
                  messagePt2?.length !== 0
                    ? messagePt1 + `${remainingChars}` + messagePt2
                    : messagePt1,
              });

              // Set the show warning state to true
              dispatch({
                type: TO_SHOW_FA_WARNING,
                payload: {
                  ...showFAWarning,
                  onPayerZipcode: true,
                },
              });
            }
          }
        } else if (type === "recipient") {
          // Remove any mandatory warning from recipient zipcode field
          dispatch({
            type: TO_SHOW_FORM_VALIDATION_ERR,
            payload: {
              type: RECIPIENT_ZIPCODE,
              toShowErr: false,
            },
          });

          if (!globalFormData.recipient_is_foreign_address) {
            // If the recipient address is not foreign follow this block
            if (isValidZip || updatedVal.length === 0) {
              updatedVal.length <= 10 &&
                dispatch({
                  type: SET_ZIPCODE,
                  zipType: REC_ZIP,
                  payload: updatedVal,
                });
              // set the zip code on global data
              updatedVal.length <= 10 &&
                setGlobalFormData({
                  ...globalFormData,
                  recipient_zipcode: value.replace("-", ""),
                  recipient_is_new_or_updated: true,
                });
            }
          } else {
            // If the address is foreign follow this block
            // Hide any displayed warrnings
            dispatch({
              type: TO_SHOW_FA_WARNING,
              payload: {
                ...showFAWarning,
                onRecipientZipcode: false,
              },
            });
            const isLesserThan46 = isLessThan46(
              globalFormData.recipient_city,
              globalFormData.recipient_state,
              updatedVal
            );

            isLesserThan46 &&
              updatedVal.length <= 11 &&
              dispatch({
                type: SET_ZIPCODE,
                zipType: REC_ZIP,
                payload: updatedVal.replace("-", ""), //  Remove any hyphen from zip code
              });

            // Set the zip on global data
            isLesserThan46 &&
              setGlobalFormData({
                ...globalFormData,
                recipient_zipcode: value.replace("-", ""),
                recipient_is_new_or_updated: true,
              });

            // Validate foreign address CAP limit
            const { status, remainingChars, messagePt1, messagePt2 } = validateFALimit(
              globalFormData.recipient_city,
              globalFormData.recipient_state,
              value.replaceAll("-", "")
            );

            if (status === true) {
              // Set the warning message
              dispatch({
                type: SET_FA_WARN_MSG,
                payload:
                  messagePt2?.length !== 0
                    ? messagePt1 + `${remainingChars}` + messagePt2
                    : messagePt1,
              });

              // Set the show warning state to true
              dispatch({
                type: TO_SHOW_FA_WARNING,
                payload: {
                  ...showFAWarning,
                  onRecipientZipcode: true,
                },
              });
            }
          }
        }
        break;

      default:
        break;
    }
  };
  return type === "payer" ? (
    <PayerAddressBlock
      globalFormData={globalFormData}
      handleChange={handleChange}
      setGlobalFormData={setGlobalFormData}
      zipcode={zipcode}
      isCorrection={isCorrection}
    />
  ) : (
    <RecipientAddressBlock
      globalFormData={globalFormData}
      handleChange={handleChange}
      setGlobalFormData={setGlobalFormData}
      zipcode={zipcode}
      formType={formType}
    />
  );
}

export default Address;
