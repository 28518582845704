import retrieveToken from "../authRequests/retrieveTokens";
import { axiosInstance } from "../axiosConfig";

export default async function editForm(cartId: any) {
  try {
    const { data } = await axiosInstance.post(
      "/get_single_form_data",
      {
        cart_id: cartId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          auth_token: retrieveToken("auth_token"),
        },
      }
    );
    return data;
  } catch (err) {
    throw err;
  }
}
