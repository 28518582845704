import UpdateGroupRecords from "../../../lib/types/UpdateGroupRecords";
import { TO_DISPLAY_ADD_STAFFS_TO_GROUP_MODAL } from "../../actions/action.types";

export interface AddStaffsToGroupAction {
  type: string;
  payload: AddStaffsToGroupActionPayload;
}

export interface AddStaffsToGroupActionPayload {
  toDisplay: boolean;
  groupId: string;
  updateGroupRecords: UpdateGroupRecords;
}

const initialState: AddStaffsToGroupActionPayload = {
  toDisplay: false,
  groupId: "",
  updateGroupRecords: null,
};

const displayAddStaffsToGroupModal = (state = initialState, action: AddStaffsToGroupAction) => {
  if (action.type === TO_DISPLAY_ADD_STAFFS_TO_GROUP_MODAL) return action.payload;
  return state;
};

export default displayAddStaffsToGroupModal;
