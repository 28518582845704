import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CONTINUE_TO_CART, EDIT, NEXT, SUCCESS_POPUP } from "../../../../lib/constants";
import "./EditNextbtn.component.css";
import { SET_REVIEWED, SET_SELECTED_REC_DATA } from "../../../../redux/actions/action.types";
import Handler from "../../../../lib/Handlers/main";
import logger from "../../../../logger";
import DisableScreen from "../../../../lib/utils/disableScreen";
import { useEffect, useState } from "react";
import setNoDefault from "../../../../lib/utils/setNoDefault";
import handlePopup from "../../../../lib/popup/handlePopup";
import { toastMessage } from "../../../../lib/languagePacks/en-us";
import { useNavigate } from "react-router-dom";
// import setDataToEdit from "../../../../lib/StateSetters/setDataToEdit";
import redirect from "../../../../lib/RoutingMethods/redirect";
import setReviewedFormIds from "../../../../lib/StateSetters/setReviewedFormIds";
import displayConfirmationModal from "../../../../lib/StateSetters/displayConfirmationModal";
import { CONTINUE_TO_CART_WITHOUT_REVIEWING_ALL_FORMS } from "../../../../lib/UserActions/action";
import devLogInstance from "../../../../lib/loggerConfig";
import TinMaskContainer from "../../../decorations/TinMaskContainer/TinMaskContainer";
import { CART } from "../../../../lib/routes";
import { useInProgress } from "../../../../lib/customHooks";

export const markReviewed = (stateHandler: any, selectedRecordIdx: number) => {
  stateHandler({
    type: SET_REVIEWED,
    payload: {
      cidx: selectedRecordIdx,
      status: true,
    },
  });
};

export default function EditNextBtn() {
  const recData = useSelector((state: any) => state.rpRecipientData);
  const selectedRecData = useSelector((state: any) => state.selectedRecData);
  const style = useSelector((state: any) => state.themeChange);
  const navigate = useNavigate();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  // const [reviwedIds, setReviewedids] = useState([]);
  const [isHovered, setIsHovered] = useState({
    next: false,
    edit: false,
  });
  const dispatch = useDispatch();
  const reviewedIds = useSelector((state: any) => state.reviewedFormIds);
  const { inProgress } = useInProgress();

  // Disable next button if last record and form is reviewed
  useEffect(() => {
    if (
      selectedRecData.selectedRecRecordIdx === recData.length - 1 &&
      recData[selectedRecData.selectedRecRecordIdx].reviewed
    ) {
      setIsBtnDisabled(true);
    } else {
      setIsBtnDisabled(false);
    }
  }, [selectedRecData, recData]);

  // Select the next record
  const selectNextRecord = () => {
    const currentIdx = selectedRecData.selectedRecRecordIdx;
    // Use no default
    setNoDefault(dispatch, true);
    // Select the next record
    dispatch({
      type: SET_SELECTED_REC_DATA,
      payload: {
        recData: recData,
        selectedRecordId:
          currentIdx + 1 >= recData.length
            ? recData[recData.length - 1].cartId
            : recData[currentIdx + 1].cartId,
        selectedRecordIdx: currentIdx + 1 >= recData.length ? recData.length - 1 : currentIdx + 1,
      },
    });
  };

  // Handle button click
  const handleClick = async (e: any) => {
    e.preventDefault();
    const { id } = e.target;
    switch (id) {
      case NEXT:
        if (!selectedRecData.selectedRecRecord.reviewed) {
          // Enable the screen disabler
          DisableScreen.enable();
          const cartIds = [{ _id: selectedRecData.selectedRecRecord.cartId }];
          const res = await Handler.handleFormReview(cartIds, dispatch);
          // Disable the screen disabler
          DisableScreen.disable();
          logger.log(res);
          // Set the reviewed Id
          // setReviewedids([...reviwedIds, ...res.successfulIdsArray]);
          setReviewedFormIds(dispatch, res.successfulIdsArray);
          // Mark the current record as reviewed
          markReviewed(dispatch, selectedRecData.selectedRecRecordIdx);
        }
        selectNextRecord();
        break;

      case EDIT:
        if (selectedRecData) {
          // // Enable the screen disabler
          // DisableScreen.enable();
          const cartId = selectedRecData.selectedRecRecord.cartId;
          await Handler.handleFormEdit(cartId, dispatch, navigate);
          // // Disable the screen disabler
          // DisableScreen.disable();
          // logger.log(form_data);
          // // Update the editable data redux state
          // setDataToEdit(form_data, dispatch);
          // // Redirect to E-Filing page
          // navigate(`/e-filing/${form_data.form_type}/${form_data.year}?edit=1`);
        }
        // selectNextRecord();
        break;

      case CONTINUE_TO_CART:
        devLogInstance.log(`REVIEWED IDS LENGTH: ${reviewedIds.length}`);
        devLogInstance.log(`REC DATA LENGTH: ${recData.length}`);
        if (reviewedIds.length !== recData.length && reviewedIds.length + recData.length > 0) {
          // Display the confirmaton modal
          displayConfirmationModal(dispatch, CONTINUE_TO_CART_WITHOUT_REVIEWING_ALL_FORMS, true);
        } else {
          redirect(`${CART}`);
        }
        break;

      default:
        break;
    }
  };

  // Show toast message if all forms are reviewed
  useEffect(() => {
    if (reviewedIds.length === recData.length && reviewedIds.length + recData.length > 0) {
      handlePopup(toastMessage.allFormsReviewed, SUCCESS_POPUP, dispatch);
    }
  }, [recData, reviewedIds, dispatch]);

  return (
    <div className="btn-main-container">
      <TinMaskContainer />
      <button
        id={EDIT}
        onClick={handleClick}
        onMouseOver={() => {
          setIsHovered({ ...isHovered, edit: true });
        }}
        onMouseLeave={() => {
          setIsHovered({ ...isHovered, edit: false });
        }}
        style={{
          background: style.btnBg,
          color: style.btnFillFontColor,
          boxShadow: isHovered.edit ? `2px 2px 15px ${style.btnBg}` : "",
        }}
      >
        Edit
      </button>
      <button
        id={NEXT}
        onClick={handleClick}
        onMouseOver={() => {
          setIsHovered({ ...isHovered, next: true });
        }}
        onMouseLeave={() => {
          setIsHovered({ ...isHovered, next: false });
        }}
        disabled={isBtnDisabled}
        style={{
          background: style.nextBtnBg,
          boxShadow: isHovered.next ? `2px 2px 15px ${style.nextBtnBg}` : "",
          opacity: isBtnDisabled ? "0.7" : "1",
        }}
      >
        Next
      </button>
      <button
        id={CONTINUE_TO_CART}
        onClick={
          !inProgress.inProgress
            ? handleClick
            : () => {
                alert("Cart can be accessed once the current progress is complete!");
              }
        }
        className="continue-btn"
        style={{ background: style.btnSecondaryBg, color: style.btnFillFontColor }}
      >
        Continue
      </button>
      <p className="next-disclaimer">
        (By clicking <span style={{ color: "black" }}> Next</span> you will mark this form as
        reviewed)
      </p>
    </div>
  );
}
