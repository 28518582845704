import {
  STATE_INCOME_1,
  STATE_INCOME_2,
  STATE_NUMBER_1,
  STATE_NUMBER_2,
  STATE_TAX_1,
  STATE_TAX_2,
} from "../../constants";

export default function stateFilingFields() {
  return [STATE_INCOME_1, STATE_INCOME_2, STATE_NUMBER_1, STATE_NUMBER_2, STATE_TAX_1, STATE_TAX_2];
}
