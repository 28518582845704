import React, { useMemo } from "react";
import Navbar from "../components/navbar";
import "../styles/LoginRegisterPage.css";
import TaxEveImg from "../components/TaxEveImg";
import RegisterForm from "../components/RegisterForm";
import { useDispatch } from "react-redux";
import EmailVerification from "../components/EmailVerification";
import { useLayoutEffect, useState } from "react";
import { REGISTER_PAGE } from "../lib/constants";
import checkAuth from "../lib/authRequests/checkAuth";
import { Location, useLocation } from "react-router-dom";
import { ga4 } from "../App";
import ga4Evts from "../lib/json/AnalyticsRelated/analyticsConst.json";

const isFromEfileAd = (location: Location<any>) => {
  return new URLSearchParams(location.search).get("from_efile_banner_ad") === "true";
};

export default function Register() {
  const [responseStatus, setResponseStatus] = useState(null);
  const [input, setInput] = useState({
    email: "",
    name: "",
    password: "",
    phoneNo: "",
  });
  const dispatch = useDispatch();
  const location = useLocation();

  useMemo(() => {
    if (isFromEfileAd(location)) {
      // Send GA4 event
      ga4.sendEvent({
        category: ga4Evts.event.category.navigation,
        action: ga4Evts.event.action.navigateFromEfileAd,
      });
    }
  }, [location]);

  useLayoutEffect(() => {
    checkAuth(dispatch);
  }, [dispatch]);
  return (
    <main className="register-page-main">
      <Navbar page={REGISTER_PAGE} />
      <div className="min-h-[100vh] py-20 px-4 sm:px-16 flex flex-col justify-center">
        <section className="flex items-center justify-between mt-12">
          <TaxEveImg />
          {responseStatus === 201 ? (
            <EmailVerification responseType="emailActivation" userEmail={input.email} />
          ) : (
            <RegisterForm responseStatus={setResponseStatus} input={input} setInput={setInput} />
          )}
        </section>
      </div>
    </main>
  );
}
