import React from "react";
import { useSelector } from "react-redux";
import StateNumber from "../StateNumber/StateNumber";
import StateTax from "../StateTax/StateTax";
import { stateCode } from "../State";
import StateName from "./StateName";
import { formsWithStateFilingOnleft } from "../../ReviewForm";

export default function StateWithoutIncomeField({
  displayTexts,
  formData,
  onLeft = false,
  styles = {},
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  return (
    <div
      className="rf-state-main"
      style={{
        borderTop: `1px solid ${style.eFormBorderColor}`,
        borderBottom: `1px solid ${style.eFormBorderColor}`,
        ...styles,
      }}
    >
      <StateName
        heading={displayTexts.stateLabel}
        styles={{ width: onLeft ? "33.33%" : "" }}
        onleft={onLeft}
        state1={formData.state1}
        state2={formData.state2}
      />
      <StateNumber
        heading={`${displayTexts.stateNoLabel}`}
        stateNo1={formData.payer_state_number1}
        stateNo2={formData.payer_state_number2}
        styles={{ width: onLeft ? "33.33%" : "" }}
        onLeft={onLeft}
      />
      <StateTax
        heading={displayTexts.stateTaxWithheldLabel}
        taxamt1={formData.state_income_tax_withheld1}
        taxAmt2={formData.state_income_tax_withheld2}
        state1={stateCode(formData.state1)}
        state2={stateCode(formData.state2)}
        styles={{
          borderRight: formsWithStateFilingOnleft.includes(formData?.form_type)
            ? "none"
            : "1px solid black",
          width: onLeft ? "33.33%" : "",
        }}
        onLeft={onLeft}
      />
    </div>
  );
}
