import { axiosInstance } from "../axiosConfig";

export default async function submitPayerData(payerData: any) {
  try {
    const { data } = await axiosInstance.post("/save_payer_details", payerData);
    return data;
  } catch (err) {
    throw err;
  }
}
