import { Dispatch } from "@reduxjs/toolkit";
import { TO_DISPLAY_MODAL } from "../../redux/actions/action.types";
import { ModalDisplay } from "../../../interfaces/main";

export default function toDisplayCorrectionFilingModal(
  status: boolean,
  modalType: string,
  dispatch: Dispatch<ModalDisplay>
) {
  dispatch({
    type: TO_DISPLAY_MODAL,
    payload: {
      toDisplay: status,
      modaltype: modalType,
    },
  });
}
