import { axiosInstance } from "./axiosConfig";

export default async function resendConfirmationMail(userEmail: string) {
  try {
    const res = await axiosInstance.post("/resend_confirmation_mail", {
      email: userEmail,
    });
    return res;
  } catch (err) {
    throw err;
  }
}
