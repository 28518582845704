import React, { useLayoutEffect } from "react";
import "./AccountDetails.component.css";
import getUserDetailsLocally from "../../lib/RetrieveUserDetailsLocally/getUserDetailsLocally";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import AccountInfoPopup from "./AccountInfoPopup/AccountInfoPopup";
import { USER_PRO_IMG } from "../../lib/constants";
import { ga4 } from "../../App";
import ga4Const from "../../lib/json/AnalyticsRelated/analyticsConst.json";

export default function AccountDetails() {
  const style = useSelector((state: any) => state.themeChange);
  const [isClicked, setIsClicked] = useState(false);
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
  });
  const [showFeedbackWindow, toShowFeedbackWindow] = useState(false);

  // Get the user details saved locally
  useLayoutEffect(() => {
    const { name, full_name, showFeedbackWindow } = getUserDetailsLocally();
    // Set the user details if name and full_name available
    name &&
      full_name &&
      setUserDetails({
        name: full_name,
        email: name,
      });
    // set feedback window status
    showFeedbackWindow !== null &&
      showFeedbackWindow !== undefined &&
      toShowFeedbackWindow(showFeedbackWindow);

    return () => {
      setUserDetails({
        name: "",
        email: "",
      });
      toShowFeedbackWindow(false);
    };
  }, []);

  useEffect(() => {
    const handleClick = (e: any) => {
      const id = e.target.id
        ? e.target.id
        : e.target.parentElement.id
        ? e.target.parentElement.id
        : null;
      id && id === USER_PRO_IMG ? setIsClicked(!isClicked) : setIsClicked(false);

      // Send analytics report on user icon click
      ga4.sendEvent({
        category: ga4Const.event.category.clickEvent,
        action: ga4Const.event.action.clickedUserIcon,
      });
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [isClicked]);

  return (
    <div>
      <div className="user-account-info">
        <div
          id={USER_PRO_IMG}
          className="user-initial"
          style={{
            boxShadow: isClicked ? `2px 2px 10px ${style.userProfileImageBg}` : "",
            background: style.userProfileImageBg,
          }}
        >
          {/* <svg id={USER_PRO_IMG} height="1.5em" viewBox="0 0 448 512">
            <path
              fill={style.btnFillFontColor}
              d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
            />
          </svg> */}
          <img src="/Assets/user.png" alt="User" />
        </div>
        {isClicked && (
          <AccountInfoPopup
            name={userDetails.name}
            email={userDetails.email}
            showFeedbackWindow={showFeedbackWindow}
          />
        )}
      </div>
    </div>
  );
}
