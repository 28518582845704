import formatNum from "../../utils/formatNum";
import logger from "../../../logger";

export default function handleAmountFormat(value: string) {
  if (value.length !== 0) {
    let val;
    let afterDec;
    if (value.includes(".")) {
      val = value.split(".")[0].replace(/,/gi, "");
      afterDec = value.split(".")[1];
    } else {
      val = value.replace(/,/gi, "");
    }
    let formattedVal = formatNum(val);

    // Check the char length and handle the formatting accordingly
    logger.log(`Formatted val ---> ${formattedVal}`);
    if (formattedVal.replace(/,/gi, "").length <= 13) {
      if (formattedVal.replace(/,/gi, "").length > 10) {
        formattedVal = formattedVal.replace(".", ""); // remove more than one point
        formattedVal =
          formatNum(formattedVal.replace(/,/gi, "").substring(0, 10)) +
          "." +
          formattedVal.replace(/,/gi, "").substring(10);
        logger.log(formattedVal);
      }
      if (value.includes(".")) {
        formattedVal = formattedVal + "." + afterDec;
      }

      return formattedVal;
    }
  }
  return "";
}
