import React from "react";
import { usePasswordToggle } from "../lib/customHooks";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

interface Props {
  id: string;
  width?: string;
  placeholder?: string;
  onChange?: (value: string, id: string) => void;
  onChangeEvent?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  sendOnChangeEvent?: boolean;
  value?: string;
}

export default function Password({
  id,
  width,
  placeholder,
  onChange,
  onChangeEvent,
  sendOnChangeEvent,
  value,
}: Props) {
  const [showPassword, toShowPassword] = usePasswordToggle(false);

  // Handle change event
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.currentTarget;
    if (onChange) onChange(value, id);
  };

  return (
    <div className={`relative py-1 ${width ?? "w-1/2"}`}>
      <input
        id={id}
        className="py-2 w-[100%]"
        type={showPassword ? "text" : "password"}
        onChange={!sendOnChangeEvent ? handleChange : onChangeEvent}
        placeholder={placeholder ?? ""}
        value={value}
      />
      {showPassword ? (
        <AiFillEye
          onClick={() => {
            toShowPassword(false);
          }}
          className="absolute right-4 top-4 text-xl cursor-pointer"
        />
      ) : (
        <AiFillEyeInvisible
          onClick={() => {
            toShowPassword(true);
          }}
          className="absolute right-4 top-4 text-xl cursor-pointer"
        />
      )}
    </div>
  );
}
