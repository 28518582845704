import { Dispatch } from "@reduxjs/toolkit";
import { BulkCreateGroupAction, BulkCreateGroupActionPayload } from "../../../../interfaces/main";
import { TO_DISPLAY_BULK_CREATE_GROUP_MODAL } from "../../../redux/actions/action.types";

export default function displayBulkCreateGroupModal(
  payload: BulkCreateGroupActionPayload,
  dispatch: Dispatch<BulkCreateGroupAction>
) {
  dispatch({
    type: TO_DISPLAY_BULK_CREATE_GROUP_MODAL,
    payload,
  });
}
