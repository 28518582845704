import React from "react";
import { useSelector } from "react-redux";
import AmountContainer from "../../GlobalFormComponents/AmountContainer/AmountContainer";
import AmountHeading from "../../GlobalFormComponents/AmountContainer/AmountHeading/AmountHeading";
import FormDetail from "../../GlobalFormComponents/InternalComponents/FormDetail";
import {
  misc1099AmountDisplayTexts,
  stateInfoDisplayTexts,
} from "../../../../lib/languagePacks/en-us";
import IRSDisclaimer from "../FormIRSDisclaimer/IRSDisclaimer";
import PayerDirectSales from "../../GlobalFormComponents/InternalComponents/PayerDirectSales/PayerDirectSales";
import State from "../RFState/State";
import { FORM1099_MISC } from "../../../../lib/constants";
import { FiDollarSign } from "react-icons/fi";

export default function MiscRightPortion({
  formType,
  taxYear,
  formName,
  formData,
  ombNum,
  revMonth,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  return (
    <div className="right-portion-details">
      <div className="top-section rf-ts">
        <div style={{ width: "35%" }}>
          <AmountContainer
            class_="rf-amt-container" // Only one class can be passed
            style={{
              borderTop: `1px solid ${style.eFormBorderColor}`,
              height: "50%",
              width: "100%",
              padding: "0.5rem 1rem",
            }}
          >
            <AmountHeading heading={misc1099AmountDisplayTexts.rents} amountNum="1" />
            {formData?.payment_amount1 !== 0 && (
              <div
                className="amount-values"
                style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
              >
                <FiDollarSign /> {(formData?.payment_amount1 / 100).toFixed(2)}
              </div>
            )}
          </AmountContainer>
          <AmountContainer
            class_="rf-amt-container" // Only one class can be passed
            style={{
              borderTop: `1px solid ${style.eFormBorderColor}`,
              height: "50%",
              width: "100%",
              padding: "0.5rem 1rem",
            }}
          >
            <AmountHeading heading={misc1099AmountDisplayTexts.royalties} amountNum="2" />
            {formData?.payment_amount2 !== 0 && (
              <div
                className="amount-values"
                style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
              >
                <FiDollarSign /> {(formData?.payment_amount2 / 100).toFixed(2)}
              </div>
            )}
          </AmountContainer>
        </div>
        <FormDetail
          formType={formType}
          formName={formName}
          taxYear={taxYear}
          ombNum={ombNum}
          revMonth={revMonth}
        />
      </div>
      <div style={{ display: "flex", overflow: "hidden" }}>
        <div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
                padding: "0.5rem 1rem",
              }}
            >
              <AmountHeading heading={misc1099AmountDisplayTexts.otherIncome} amountNum="3" />
              {formData?.payment_amount3 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount3 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{ padding: "0.5rem 1rem" }}
            >
              <AmountHeading
                heading={misc1099AmountDisplayTexts.incomeTaxWithheldDisplayText}
                amountNum="4"
              />
              {formData?.payment_amount4 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount4 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
                padding: "0.5rem 1rem",
              }}
            >
              <AmountHeading
                heading={misc1099AmountDisplayTexts.fishingBoatProceeds}
                amountNum="5"
              />
              {formData?.payment_amount5 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount5 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{ padding: "0.5rem 1rem" }}
            >
              <AmountHeading
                heading={misc1099AmountDisplayTexts.medicalHealthCarePayments}
                amountNum="6"
              />
              {formData?.payment_amount6 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount6 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <PayerDirectSales
              style_={{
                padding: "0.5rem 1rem",
                fontSize: "0.85rem",
                width: "50%",
              }}
              status={formData?.direct_sales_indicator}
              onChangeHandler={() => {}}
              formType={FORM1099_MISC}
              fieldId="7"
            />
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{ padding: "0.5rem 1rem" }}
            >
              <AmountHeading
                heading={misc1099AmountDisplayTexts.substitutePayments}
                amountNum="8"
              />
              {formData?.payment_amount8 !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amount8 / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
                padding: "0.5rem 1rem",
              }}
            >
              <AmountHeading heading={misc1099AmountDisplayTexts.cropInsProceeds} amountNum="9" />
              {formData?.payment_amountA !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountA / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{ padding: "0.5rem 1rem" }}
            >
              <AmountHeading
                heading={misc1099AmountDisplayTexts.grossProceedsPaidToAttorney}
                amountNum="10"
              />
              {formData?.payment_amountC !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountC / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              borderBottom: `1px solid ${style.eFormBorderColor}`,
            }}
          >
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
                padding: "0.5rem 1rem",
              }}
            >
              <AmountHeading
                heading={misc1099AmountDisplayTexts.fishPurchasedForResale}
                amountNum="11"
              />
              {formData?.payment_amountF !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountF / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{ padding: "0.5rem 1rem" }}
            >
              <AmountHeading
                heading={misc1099AmountDisplayTexts.section409ADeferrals}
                amountNum="12"
              />
              {formData?.payment_amountD !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountD / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>
          <div
            className="payment-amounts-container"
            style={{
              borderRight: `1px solid ${style.eFormBorderColor}`,
              height: "100%",
            }}
          >
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{
                borderRight: `1px solid ${style.eFormBorderColor}`,
                padding: "0.5rem 1rem",
              }}
            >
              <AmountHeading
                heading={misc1099AmountDisplayTexts.glodenParachutePayments}
                amountNum="14"
              />
              {formData?.payment_amountB !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountB / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
            <AmountContainer
              class_="rf-amt-container" // Only one class can be passed
              style={{ padding: "0.5rem 1rem" }}
            >
              <AmountHeading
                heading={misc1099AmountDisplayTexts.deferredCompensation}
                amountNum="15"
              />
              {formData?.payment_amountE !== 0 && (
                <div
                  className="amount-values"
                  style={{ display: "flex", alignItems: "center", color: "#30A2FF" }}
                >
                  <FiDollarSign /> {(formData?.payment_amountE / 100).toFixed(2)}
                </div>
              )}
            </AmountContainer>
          </div>
        </div>
        <IRSDisclaimer />
      </div>
      <State displayTexts={stateInfoDisplayTexts.misc1099} formData={formData} />
    </div>
  );
}
