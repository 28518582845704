/* NOTE: Make sure to look into and change the route in backend as well 
         if that route is used and changed here.
*/

export const LANDING_PAGE = "/";
export const VERIFY_EMAIL = "/activate";
export const SIGNIN = "/signin";
export const REGISTER = "/register";
export const PASSWORD_RESET = "/reset-password";

// Main / After login routes
export const HOME = "/taxeve/home";
export const E_FILING = "/taxeve/e-filing";
export const REVIEW = "/taxeve/review";
export const FILE_UPLOAD = "/taxeve/file-upload";
export const CART = "/taxeve/cart";
export const SETTINGS = "/taxeve/settings";
export const ACCOUNT_SETTINGS = `${SETTINGS}/account`;
export const PROFILE_SETTINGS = `${SETTINGS}/profile`;
export const HISTORY_PAGE = "/taxeve/order-history";
export const TEMPLATES_DOWNLOAD = "/taxeve/download-templates";
export const CORRECTION = "/taxeve/1099-correction";
export const BUSINESS_SETTINGS = "/taxeve/business-settings";
export const SWITCH_ACCOUNTS = "/taxeve/account-switch";

// Info page routes
export const PRICING_PAGE = "/pricing";
export const CONTACT_US_PAGE = "/contact-us";
export const _1099_FORMS_PAGE = "/1099-forms";
export const WHY_CHOOSE_US_PAGE = "/features";
export const ENQUIRY = "/inquiry";

// Admin routes
export const ADMIN = `/admin-dashboard/${process.env.REACT_APP_ADMIN_SECRET_CODE ?? ""}`;
export const ADMIN_LOGIN = `/admin-login/${process.env.REACT_APP_ADMIN_SECRET_CODE ?? ""}`;
export const ADMIN_MANAGEMENT = `/admin-management/${
  process.env.REACT_APP_ADMIN_SECRET_CODE ?? ""
}`;
export const ADMIN_PRICE_MANAGEMENT = `/admin-price-management/${
  process.env.REACT_APP_ADMIN_SECRET_CODE ?? ""
}`;
export const ADMIN_PASSWORD_CHANGE = `/admin-change-password/${
  process.env.REACT_APP_ADMIN_SECRET_CODE ?? ""
}`;
export const ADMIN_FIRE_FILING = `/admin-fire-filing/${
  process.env.REACT_APP_ADMIN_SECRET_CODE ?? ""
}`;
export const ADMIN_TRACK_TRANSACTIONS = `/admin-track-transactions/${
  process.env.REACT_APP_ADMIN_SECRET_CODE ?? ""
}`;

// Online access portal routes
export const ONLINE_ACCESS_LANDING = "/online-access";
export const ONLINE_ACCESS_LOGIN = "/online-access/login";
export const ONLINE_ACCESS_CONSENT = "/online-access/consent";
export const ONLINE_ACCESS_DASHBOARD = "/online-access/dashboard";

export const TERMS_AND_CONDITION = "/terms-and-condition";
export const PRIVACY_POLICY = "/privacy-policy";
