import { ContactUsData, EnquiryData } from "../../../interfaces/main";
import { axiosInstance } from "../axiosConfig";

export default async function contactUs(data_: ContactUsData | EnquiryData) {
  try {
    const { data } = await axiosInstance.post("/contact_us/contact", data_);
    return data;
  } catch (err) {
    throw err;
  }
}
