import React from "react";
import "./ChangePassword.css";
import { useState } from "react";
import PasswordInput from "../Password/PasswordInput";
import { ERROR_POPUP } from "../../../lib/constants";
import { useDispatch, useSelector } from "react-redux";
import devLogInstance from "../../../lib/loggerConfig";
import Handler from "../../../lib/Handlers/main";
import handlePopup from "../../../lib/popup/handlePopup";
import { SHA256 } from "crypto-js";

export default function ChangePassword() {
  const [inputVals, setInputVals] = useState({
    password: "",
    newPassword: "",
    confirmationPassword: "",
  });
  const stateSetter = useDispatch();
  const style = useSelector((state: any) => state.themeChange);

  const handleChange = (e: any) => {
    const { id: inputType, value } = e.target;
    switch (inputType) {
      case "new-password":
        setInputVals({
          ...inputVals,
          newPassword: value,
        });
        break;

      case "pass":
        setInputVals({
          ...inputVals,
          password: value,
        });
        break;

      case "confirm-password":
        setInputVals({
          ...inputVals,
          confirmationPassword: value,
        });
        break;

      default:
        break;
    }
  };

  // Handle setting change
  const handlePasswordUpdate = async (e: any) => {
    e.preventDefault();
    devLogInstance.log(inputVals.password);
    devLogInstance.log(inputVals.newPassword);
    devLogInstance.log(inputVals.confirmationPassword);
    let isPasswordConfirmed = inputVals.newPassword === inputVals.confirmationPassword;
    if (isPasswordConfirmed) {
      const hashedOldPass = SHA256(process.env.REACT_APP_NONCE + inputVals.password).toString();
      const hashedNewPass = SHA256(process.env.REACT_APP_NONCE + inputVals.newPassword).toString();
      await Handler.handlePasswordChange(hashedNewPass, hashedOldPass, stateSetter);
    } else {
      // Call the pop up with passwords dont match toast message
      const toastMessage = "Passwords dont match!";
      handlePopup(toastMessage, ERROR_POPUP, stateSetter);
    }
  };

  return (
    <main className="password-setting-main">
      <form className="password-setting-left-portion" onSubmit={handlePasswordUpdate}>
        <PasswordInput password={inputVals.password} setPassword={handleChange} />
        <div className="new-password-container">
          <div className="new-password-wrapper">
            <label htmlFor="new-password">
              <h3>New Password</h3>
            </label>
            <p>Enter your new password</p>
            <input
              className="new-password-input"
              type="password"
              id="new-password"
              value={inputVals.newPassword}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="confirm-password-container">
          <div className="confirm-password-wrapper">
            <label htmlFor="confirm-password">
              <h3>Confirm Password</h3>
            </label>
            <p>Confirm your new password</p>
            <input
              className="confirm-password-input"
              type="password"
              id="confirm-password"
              value={inputVals.confirmationPassword}
              onChange={handleChange}
            />
          </div>
        </div>
        <button
          type="submit"
          className="password-change-btn"
          style={{ background: style.btnBg, color: style.btnFillFontColor }}
        >
          Change Password
        </button>
      </form>
    </main>
  );
}
