import { AddStaffParams } from "../../../../interfaces/main";
import { TO_DISPLAY_MANUAL_STAFF_ADDING_MODAL } from "../../actions/action.types";

export interface AddStaffModalActionPayload {
  toDisplay: boolean;
  handleAddingStaff: ((params: AddStaffParams) => Promise<void>) | null;
}

export interface AddStaffModalAction {
  type: string;
  payload: AddStaffModalActionPayload;
}

const initialState: AddStaffModalActionPayload = {
  toDisplay: false,
  handleAddingStaff: null,
};

const displayManualStaffAddingModal = (state = initialState, action: AddStaffModalAction) => {
  if (action.type === TO_DISPLAY_MANUAL_STAFF_ADDING_MODAL) return action.payload;
  return state;
};

export default displayManualStaffAddingModal;
