import React from "react";
import "./SecondTinNotice.component.css";

export default function SecondTinNotice({ status, onChangeHandler, label, styles = {} }: any) {
  return (
    <div className="second-tin-notice flex" style={{ ...styles }}>
      <div>
        <h4>{label}</h4>
      </div>
      <div>
        <input
          className="checkbox"
          type="checkbox"
          id="second-tin-notice"
          checked={status}
          onChange={onChangeHandler}
        />
      </div>
    </div>
  );
}
