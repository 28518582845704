import React from "react";

export default function PdfViewer() {
  return (
    <div className="w-[100%] px-4 py-6">
      <embed
        src={`${process.env.REACT_APP_API_ENDPOINT}/online_access/Recipient-Copy?type=preview`}
        type="application/pdf"
        height={530}
        width={"100%"}
      />
    </div>
  );
}
