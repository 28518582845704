import { CSTReducerAction } from "../../../../interfaces/main";
import { SET_CORRECTION_SUBMISSION_TYPE } from "../../actions/action.types";

const correctionSubmissionType = (state = null, action: CSTReducerAction) => {
  const { type, payload } = action;

  if (type === SET_CORRECTION_SUBMISSION_TYPE) return payload;
  return state;
};

export default correctionSubmissionType;
