import React, { useState } from "react";
import { RemoveStaffsFromGroupActionPayload } from "../../../../../interfaces/main";
import { useDispatch, useSelector } from "react-redux";
import { IoIosCloseCircleOutline } from "react-icons/io";
import displayRemoveStaffsFromGroupModal from "../../../../lib/StateSetters/StaffAndGroupRelated/displayRemoveStaffsFromGroupModal";
import { FaMinusSquare } from "react-icons/fa";
import Handler from "../../../../lib/Handlers/main";
import devLogInstance from "../../../../lib/loggerConfig";

export default function RemoveStaffsFromGroupModal() {
  const dispatch = useDispatch();

  const { groupId, assignedStaffs, updateGroupRecords }: RemoveStaffsFromGroupActionPayload =
    useSelector((state: any) => state.displayRemoveStaffsFromGroupModal);

  const [selectedStaffIds, updateSelectedStaffIds] = useState<string[]>([]);

  const closeModal = () =>
    displayRemoveStaffsFromGroupModal(
      { toDisplay: false, groupId: "", assignedStaffs: [], updateGroupRecords: null },
      dispatch
    );

  const deselectAllStaff = () => updateSelectedStaffIds([]);

  // Handle when a single staff is selected
  const handleSingleStaffSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const staffId = e.currentTarget.id;

    if (isChecked) {
      updateSelectedStaffIds([...selectedStaffIds, staffId]);
    } else {
      const newStaffIds = selectedStaffIds.filter((id) => id !== staffId);
      updateSelectedStaffIds(newStaffIds);
    }
  };

  // Handle when all the staffs are selected
  const handleAllStaffSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      const allStaffIds = assignedStaffs.map((record) => record._id);
      updateSelectedStaffIds(allStaffIds);
    } else {
      deselectAllStaff();
    }
  };

  // Handle removing staffs from group
  const handleUnassigningStaffs = (e: React.MouseEvent<HTMLButtonElement>) => {
    updateGroupRecords
      ? Handler.handleRemovingStaffsFromGroup(groupId, selectedStaffIds, updateGroupRecords)
      : devLogInstance.log("updateGroupRecords method is null");

    closeModal();
  };

  return (
    <div className="form-modal shadow-lg">
      <div className="form-modal-header bg-taxeve-primary-violet text-white flex items-center justify-between">
        <h2>Unassign Members</h2>
        <IoIosCloseCircleOutline className="text-3xl cursor-pointer" onClick={closeModal} />
      </div>
      <div className="form-modal-body bg-white py">
        <header className="p-4 underline underline-offset-1 font-bold">
          Select all the Members you want to unassign from the current Group:
        </header>
        <body className="px-4 max-h-72 overflow-auto">
          {assignedStaffs.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th className="text-center relative">
                    {selectedStaffIds.length > 0 &&
                    assignedStaffs.length !== selectedStaffIds.length ? (
                      <FaMinusSquare
                        id="semi-checked-icon"
                        className="absolute left-7 top-3 text-blue-600"
                        onClick={deselectAllStaff}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        name="All Staff Selector"
                        id="select-all-staff"
                        onChange={handleAllStaffSelection}
                        checked={assignedStaffs.length === selectedStaffIds.length}
                      />
                    )}
                  </th>
                  <th className="text-center">Name</th>
                  <th className="text-center">Email</th>
                </tr>
              </thead>
              <tbody>
                {assignedStaffs.map((staff) => {
                  return (
                    <tr key={staff._id}>
                      <td className="text-center py-3">
                        <input
                          type="checkbox"
                          name="Staff Selector"
                          id={staff._id}
                          onChange={handleSingleStaffSelection}
                          checked={selectedStaffIds.includes(staff._id)}
                        />
                      </td>
                      <td className="text-center">
                        <p>{staff.staff_name}</p>
                      </td>
                      <td className="text-center">
                        <p>{staff.staff_email}</p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="p-8 text-center font-bold text-2xl opacity-40">
              <em>You have not assigned any members to this group yet.</em>
            </div>
          )}
        </body>
      </div>
      <footer className="form-modal-footer bg-white">
        <button
          id="unassign-members-btn"
          className={`px-4 py-2 rounded-lg bg-taxeve-primary-violet text-white hover:opacity-80 ${
            selectedStaffIds.length === 0 && "cursor-not-allowed opacity-80"
          }`}
          disabled={selectedStaffIds.length === 0}
          onClick={handleUnassigningStaffs}
        >
          Unassign Members
        </button>
      </footer>
    </div>
  );
}
