import React from "react";
import { useSelector } from "react-redux";
import "./ReviewPending.component.css";
import { useEffect, useState } from "react";
import { MdOutlinePendingActions } from "react-icons/md";
import { Link } from "react-router-dom";
import { REVIEW } from "../../lib/routes";

export default function ReviewPending() {
  const totalRPItems = useSelector((state: any) => state.totalRPItems);
  // const style = useSelector((state: any) => state.themeChange);
  const [isDisabled, setIsDisabled] = useState(true);

  // Set button disabled state based on total RP items on the initial render
  useEffect(() => {
    totalRPItems > 0 ? setIsDisabled(false) : setIsDisabled(true);
  }, [totalRPItems]);

  return (
    <div className="rp-container">
      <Link to={REVIEW}>
        <button
          className={`rp-btn bg-[#b262d163] border-2 border-solid border-[#b262d1c5] text-taxeve-primary-violet ${
            isDisabled && "opacity-50"
          }`}
          disabled={isDisabled}
        >
          <h1 className="text-2xl">
            <MdOutlinePendingActions />
          </h1>
          <h3 className="text-xl font-bold">
            Review Pending
            {totalRPItems > 0 && ` (${totalRPItems})`}
          </h3>
        </button>
      </Link>
      {isDisabled && <div className="icon-deactivate" style={{ cursor: "not-allowed" }} />}
    </div>
  );
}
