import { axiosInstance } from "../../lib/axiosConfig";

export default async function getCartInfo(year: number) {
  try {
    const { data } = await axiosInstance.get("/cart", {
      headers: {
        year,
      },
    });
    return data;
  } catch (err) {
    throw err;
  }
}
