import { TO_DISPLAY_CONFIRMATION_MODAL } from "../../actions/action.types";

const initialState = {
  status: false,
  action: null,
};

const displayConfirmationModal = (state = initialState, action: any) => {
  if (action.type === TO_DISPLAY_CONFIRMATION_MODAL) return action.payload;
  return state;
};

export default displayConfirmationModal;
