import { Dispatch } from "@reduxjs/toolkit";
import { TO_DISPLAY_PAYER_ASSIGNMENT_MODAL } from "../../../redux/actions/action.types";
import { PayerAssignmentModalActionProps } from "../../../redux/reducers/Modal/displayPayerAssignmentModal";
import { GroupRecord, StaffRecord } from "../../../../interfaces/main";

interface PayerAssignmentState {
  toDisplay: boolean;
  isStaff: boolean;
  staffOrGroupId: string;
  updateStaffRecords: React.Dispatch<React.SetStateAction<StaffRecord[]>> | null;
  updateGroupRecords: React.Dispatch<React.SetStateAction<GroupRecord[]>> | null;
  staffName?: string;
  staffEmail?: string;
  groupName?: string;
}

export default function displayPayerAssignmentModal(
  assignmentStateData: PayerAssignmentState,
  dispatch: Dispatch<PayerAssignmentModalActionProps>
) {
  dispatch({
    type: TO_DISPLAY_PAYER_ASSIGNMENT_MODAL,
    payload: {
      status: assignmentStateData.toDisplay,
      isStaff: assignmentStateData.isStaff,
      staffOrGroupId: assignmentStateData.staffOrGroupId,
      updateStaffRecords: assignmentStateData.updateStaffRecords,
      updateGroupRecords: assignmentStateData.updateGroupRecords,
      staffName: assignmentStateData.staffName,
      staffEmail: assignmentStateData.staffEmail,
      groupName: assignmentStateData.groupName,
    },
  });
}
