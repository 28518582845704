import { axiosInstance } from "../../axiosConfig";

export default async function getOnlineAccessRecCopy() {
  try {
    const res = await axiosInstance.get("/online_access/Recipient-Copy?type=download", {
      responseType: "blob",
    });
    return { data: res.data, header: res.headers };
  } catch (err) {
    throw err;
  }
}
