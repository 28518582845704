import React, { ReactNode, useContext } from "react";
import PanelHeading from "./PanelHeading";
import PanelOption from "./PanelOption";
import { PanelOptionContext } from "../../pages/BusinessSettings";

interface Props extends React.HTMLAttributes<HTMLElement> {
  heading: string;
  headingIcon?: string | ReactNode;
  options: string[];
}

export default function SidePanel(props: Props) {
  const panelOptionCtx = useContext(PanelOptionContext);

  // If the panel option ctx is not provided throw an error
  if (!panelOptionCtx) throw new Error("Panel option ctx not provided!");

  // Destructure the required context values
  const { option: panelOptionIdx } = panelOptionCtx;

  return (
    <section
      className={`${props.className} border-r-[1px] border-r-slate-300 border-b-[1px] border-b-slate-300 w-[15%] h-full`}
    >
      <PanelHeading text={props.heading} icon={props.headingIcon} className="px-4" />
      {/* Panel options */}
      <div className="panel-options overflow-auto">
        {props.options.map((option, i) => (
          <PanelOption id="" key={i} option={option} idx={i} isSelected={i === panelOptionIdx} />
        ))}
      </div>
    </section>
  );
}
