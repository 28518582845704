import React, { useEffect } from "react";
import Navbar from "../components/navbar";
import { WHY_CHOOSE_US_PAGE } from "../lib/routes";
import { whyChooseUsTexts } from "../lib/languagePacks/en-us";
import Card from "../components/decorations/Card/Card";
import { FaHatWizard, FaFileExcel, FaHandshake } from "react-icons/fa";
import { BsFillFileEarmarkLockFill, BsCloudHaze2Fill } from "react-icons/bs";
import { IoMdMailOpen } from "react-icons/io";
import { MdSecurity } from "react-icons/md";
import Footer from "../components/Footer/Footer";
import { ga4 } from "../App";
import ga4Const from "../lib/json/AnalyticsRelated/analyticsConst.json";
import { featuresText } from "../lib/languagePacks/en-us";
import scrollToTop from "../lib/utils/scrollToTop";

export const features = [
  {
    heading: featuresText[0].heading,
    description: featuresText[0].description,
    icon: <FaHatWizard className="text-8xl" />,
  },
  {
    heading: featuresText[1].heading,
    description: featuresText[1].description,
    icon: <BsFillFileEarmarkLockFill className="text-8xl" />,
  },
  {
    heading: featuresText[2].heading,
    description: featuresText[2].description,
    icon: <IoMdMailOpen className="text-8xl" />,
  },
  {
    heading: featuresText[3].heading,
    description: featuresText[3].description,
    icon: <FaFileExcel className="text-8xl" />,
  },
  // {
  //   heading: featuresText[4].heading,
  //   description: featuresText[4].description,
  //   icon: <FaHandshake className="text-8xl" />,
  // },
  {
    heading: featuresText[5].heading,
    description: featuresText[5].description,
    icon: <MdSecurity className="text-8xl" />,
  },
  {
    heading: featuresText[6].heading,
    description: featuresText[6].description,
    icon: <BsCloudHaze2Fill className="text-8xl" />,
  },
];

export default function WhyChooseUs() {
  scrollToTop();

  useEffect(() => {
    ga4.sendEvent({
      category: ga4Const.event.category.navigation,
      action: `${ga4Const.event.action.navigateIn} to Why choose up page`,
    });
    return () => {
      ga4.sendEvent({
        category: ga4Const.event.category.navigation,
        action: `${ga4Const.event.action.navigateOut} from Why choose up page`,
      });
    };
  }, []);

  return (
    <div>
      <Navbar page={WHY_CHOOSE_US_PAGE} />
      <section className="banner">
        <div className="nested-div-in-banner">
          <h1 className="font-bold text-3xl">Why choose us?</h1>
        </div>
      </section>
      <section className="description px-4 sm:px-40 py-16 text-lg">
        {whyChooseUsTexts.description}
      </section>
      <section className="feature-cards-section px-4 sm:px-40 mb-16 flex justify-evenly gap-4 flex-wrap">
        {features.map((featureObj, i) => {
          return (
            <Card
              key={i}
              heading={featureObj.heading}
              description={featureObj.description}
              icons={featureObj.icon}
              width="w-[24rem]"
            />
          );
        })}
      </section>
      <Footer />
    </div>
  );
}
