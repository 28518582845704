export default function formatPhonenum(value: any): string {
  const phoneRegex = /(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/;
  let updatedVal = value.replace("(", "").replace(")", "").replace(" ", "").replace("-", "");
  if (updatedVal.length > 0 && updatedVal.length <= 3) {
    updatedVal = "" + updatedVal;
  } else if (updatedVal.length > 3 && updatedVal.length <= 6) {
    updatedVal = "(" + updatedVal.substring(0, 3) + ") " + updatedVal.substring(3);
  } else if (updatedVal.length > 6) {
    updatedVal =
      "(" +
      updatedVal.substring(0, 3) +
      ") " +
      updatedVal.substring(3, 6) +
      "-" +
      updatedVal.substring(6);
  }
  const isValidPhone = phoneRegex.test(updatedVal);
  if (isValidPhone) {
    if (updatedVal.length <= 14) return updatedVal;
    else return updatedVal.substring(0, 14);
  }
  return updatedVal.substring(0, updatedVal.length - 1);
}
