import React from "react";
import { form1099 } from "../../../../lib/languagePacks/en-us";
import { useSelector } from "react-redux";
import "../../../../styles/forms/globalComponentStyles/FormDetail.css";
import TaxYearNewFont from "../../../decorations/NewTaxYearFont/TaxYearNewFont";
import { FORM1099_K, FORM1099_R } from "../../../../lib/constants";

export default function FormDetail({
  formType,
  taxYear,
  formName,
  ombNum,
  revMonth,
  newVersion,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  return (
    <div className="form-detail-container">
      <div
        className="form-type-tax-year"
        style={{
          border: `1px solid ${style.eFormBorderColor}`,
          borderBottom: "none",
          flexDirection: newVersion ? "column-reverse" : "column",
        }}
      >
        <div
          className="form-type-wrapper"
          style={{
            borderBottom: !newVersion ? `1px solid ${style.eFormBorderColor}` : "",
          }}
        >
          <div>
            {ombNum && !newVersion && (
              <div style={{ fontSize: "0.85rem" }}>
                <p>OMB No. {ombNum}</p>
              </div>
            )}
            <div className="form-type">
              Form <h3>{formType}</h3>
            </div>
            {ombNum && !newVersion && (
              <div style={{ fontSize: "0.85rem" }}>{`(Rev. ${revMonth} ${taxYear})`}</div>
            )}
          </div>
        </div>
        <div
          className="tax-year-wrapper"
          style={{ borderBottom: newVersion ? `1px solid ${style.eFormBorderColor}` : "" }}
        >
          {!newVersion ? (
            <div className="tax-year">
              {!ombNum ? form1099.formDetail.taxYearDisplayTxt : "For calendar year"}
              <h3>{taxYear}</h3>
            </div>
          ) : (
            <div style={{ flexDirection: "column" }}>
              {ombNum && (
                <div style={{ fontSize: "0.85rem" }}>
                  <p>OMB No. {ombNum}</p>
                </div>
              )}
              <TaxYearNewFont taxYear={taxYear} />
            </div>
          )}
        </div>
      </div>
      <div
        className="form-name"
        style={{
          fontSize: formType === FORM1099_K || formType === FORM1099_R ? "0.75rem" : "",
          padding: "1rem",
        }}
      >
        <h2>{formName}</h2>
      </div>
    </div>
  );
}
