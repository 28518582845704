import { AddBulkStaffAction, AddBulkStaffActionPayload } from "../../../../interfaces/main";
import { TO_DISPLAY_ADD_BULK_STAFF_MODAL } from "../../actions/action.types";

const initialState: AddBulkStaffActionPayload = {
  toDisplay: false,
  updateStaffRecords: null,
};

const displayAddBulkStaffModal = (state = initialState, action: AddBulkStaffAction) => {
  if (action.type === TO_DISPLAY_ADD_BULK_STAFF_MODAL) return action.payload;
  return state;
};

export default displayAddBulkStaffModal;
