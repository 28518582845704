import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Handler from "../../lib/Handlers/main";
import redirect from "../../lib/RoutingMethods/redirect";
import { LANDING_PAGE } from "../../lib/routes";
import EmailActivated from "./EmailActivated";
import EmailActivationFailed from "./EmailActivationFailed";
import loader from "../../components/decorations/Loader";
import { useSelector } from "react-redux";

export default function EmailActivation() {
  const { userId, userToken } = useParams();
  const [isActivated, setIsActivated] = useState<boolean | null>(null);
  const [resStatus, setResStatus] = useState<number | null>(null);
  const style = useSelector((state: any) => state.themeChange);

  useEffect(() => {
    if (userId && userToken) {
      const activateEmail = async () => {
        try {
          const res = await Handler.activateEmail(userId, userToken);
          setIsActivated(res.is_activated);
          setResStatus(res.status);
        } catch (error) {
          console.error(error);
          redirect(LANDING_PAGE);
        }
      };
      activateEmail();
    }

    return () => {
      setIsActivated(null);
    };
  }, [userId, userToken]);

  if (isActivated === null) return loader.start(style, true); // The boolean states not to show any navbar

  return isActivated ? <EmailActivated resStatus={resStatus || 0} /> : <EmailActivationFailed />;
}
