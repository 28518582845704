import React from "react";
import { FiDollarSign } from "react-icons/fi";
import { PAYMENT } from "../../../../../lib/constants";

export default function StateAmount({ amt1, amt2, state1, state2, type, onLeft = false }: any) {
  return (
    <div>
      {type === PAYMENT ? (
        <>
          <div
            className={`tax-amt ${onLeft && "tax-amt-left-side"} flex items-center gap-1`}
            style={{ color: "#30A2FF" }}
          >
            <FiDollarSign />
            {state1 !== "" && (amt1 / 100).toFixed(2)}
          </div>
          <hr style={{ borderTop: `2px dashed black` }} />
          <div
            className={`tax-amt ${onLeft && "tax-amt-left-side"} flex items-center gap-1`}
            style={{ color: "#30A2FF" }}
          >
            <FiDollarSign />
            {state2 !== "" && (amt2 / 100).toFixed(2)}
          </div>
        </>
      ) : (
        <>
          <div
            className={`tax-amt ${onLeft && "tax-amt-left-side"} flex items-center gap-1`}
            style={{ color: "#30A2FF" }}
          >
            {state1 && amt1 && `${state1}/${amt1}`}
            {!state1 && amt1 && `${amt1}`}
            {!amt1 && state1 && `${state1}`}
          </div>
          <hr style={{ borderTop: `2px dashed black` }} />
          <div
            className={`tax-amt ${onLeft && "tax-amt-left-side"} flex items-center gap-1`}
            style={{ color: "#30A2FF" }}
          >
            {state2 && amt2 && `${state2}/${amt2}`}
            {!state2 && amt2 && `${amt2}`}
            {!amt2 && state2 && `${state2}`}
          </div>
        </>
      )}
    </div>
  );
}
