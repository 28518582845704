import React from "react";
import "./ChangeEmail.css";
import getUserDetailsLocally from "../../../lib/RetrieveUserDetailsLocally/getUserDetailsLocally";
import PasswordInput from "../Password/PasswordInput";
import OtpSender from "../OTPSender/OtpSender";
import { useState } from "react";
import { EMAIL_SETTING } from "../../../lib/constants";
import VerificationContainer from "../VerificationContainer/VerificationContainer";
import devLogInstance, { prodLogInstance } from "../../../lib/loggerConfig";
import Handler from "../../../lib/Handlers/main";
import { useDispatch } from "react-redux";

export default function ChangeEmail() {
  const [inputVals, setInputVals] = useState({
    newEmail: "",
    password: "",
  });
  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(false);
  const stateSetter = useDispatch();

  const handleChange = (e: any) => {
    const { id: inputType, value } = e.target;
    switch (inputType) {
      case "new-email":
        setInputVals({
          ...inputVals,
          newEmail: value,
        });
        break;

      case "pass":
        setInputVals({
          ...inputVals,
          password: value,
        });
        break;

      default:
        break;
    }
  };

  // Handle setting change
  const handleEmailUpdate = async (verifyCode: string) => {
    devLogInstance.log(inputVals.newEmail);
    devLogInstance.log(verifyCode);
    try {
      await Handler.handleEmailChange(inputVals.newEmail, verifyCode, stateSetter);
    } catch (err) {
      prodLogInstance.error(err);
    }
  };

  return (
    <main className="email-setting-main">
      <div className="email-setting-left-portion">
        <div className="current-email-container">
          <div className="current-email-wrapper">
            <label htmlFor="email">
              <h3>Current Email</h3>
            </label>
            <input
              className="current-email-input"
              type="email"
              id="email"
              value={getUserDetailsLocally().name}
              disabled
            />
          </div>
        </div>
        <PasswordInput password={inputVals.password} setPassword={handleChange} />
        <div className="new-email-container">
          <div className="new-email-wrapper">
            <label htmlFor="new-email">
              <h3>New Email</h3>
            </label>
            <p>Enter a new email address that you want to update</p>
            <input
              className="new-email-input"
              type="email"
              id="new-email"
              value={inputVals.newEmail}
              onChange={handleChange}
            />
          </div>
        </div>
        <OtpSender
          emptyInputs={!inputVals.newEmail || !inputVals.password}
          verificationData={{ email: inputVals.newEmail, password: inputVals.password }}
          settingType={EMAIL_SETTING}
          setIsVerificationCodeSent={setIsVerificationCodeSent}
        />
      </div>
      {isVerificationCodeSent && (
        <VerificationContainer settingType={EMAIL_SETTING} handleUpdate={handleEmailUpdate} />
      )}
    </main>
  );
}
