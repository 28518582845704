import { SET_THEME_DETAILS } from "../actions/action.types";
import { themes } from "../../lib/themeCode";
import logger from "../../logger";

const initialState = themes[0];

const themeChange = (state: any = initialState, action: any) => {
  if (action.type === SET_THEME_DETAILS) {
    // change theme state
    const newTheme = themes.map((theme) => {
      if (theme.theme === action.payload) {
        return theme;
      } else {
        return state;
      }
    });
    logger.log(newTheme);
    if (newTheme) state = newTheme;
    return state;
  } else {
    // return initial state
    return state;
  }
};

export default themeChange;
