import {
  SET_PAYER_TIN_DISPLAY,
  SET_RECIPIENT_TIN_DISPLAY,
} from "../../../../redux/actions/action.types";
import formatTin from "../formatTin";

export default function updateTinDisplay(tin: any, tinType: any, type: any, handleUpdate: any) {
  const formattedTin = formatTin(tin, tinType);
  if (type === "payer") {
    // Update the payer tin display
    handleUpdate({
      type: SET_PAYER_TIN_DISPLAY,
      payload: formattedTin,
    });
  } else if (type === "recipient") {
    // update the rec tin display
    handleUpdate({
      type: SET_RECIPIENT_TIN_DISPLAY,
      payload: formattedTin,
    });
  }
}
