import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./Cart.component.css";
import { CART } from "../../lib/routes";
import { useInProgress } from "../../lib/customHooks";
// import { Link } from "react-router-dom";

export default function Cart() {
  const totalCartItems = useSelector((state: any) => state.totalCartItems);
  const style = useSelector((state: any) => state.themeChange);
  const { inProgress } = useInProgress();
  const [warn, toWarn] = useState(false);

  return (
    <div className="w-[5%] relative">
      <a href={CART} className="cart-container" style={{ color: "black" }}>
        {parseInt(totalCartItems) > 0 && (
          <div
            className="total-cart-items"
            style={{
              background: style.notificationBg,
              color: style.notificationColor,
            }}
          >
            {totalCartItems}
          </div>
        )}
        <h1 className="cart-icon">
          {/* <BsCart3 /> */}
          <img src="/Assets/cart_icon.svg" alt="Cart" className="w-[100%]" />
        </h1>
      </a>
      {inProgress.inProgress && (
        <div
          className="absolute h-full w-full top-0 left-0"
          onMouseOver={() => toWarn(true)}
          onMouseLeave={() => toWarn(false)}
        />
      )}
      {warn && (
        <div className="absolute -left-80 -bottom-14 w-[fit-content] bg-yellow-500 py-1 px-2 border border-black">
          Cart will be available once the current progress is complete
        </div>
      )}
    </div>
  );
}
