import React from "react";
import { FileUploader } from "react-drag-drop-files";
import Dropbox from "./Dropbox/Dropbox";
import { PAGE_UPLOAD } from "../../../lib/constants";
import TemplateDownloader from "./TemplateDownloader/TemplateDownloader";
import SubmitButton from "../../SubmitButton/SubmitButton";
import { useFileUpload } from "../../../lib/customHooks";

export default function AddFile({ payerId, userId, formType, taxYear, payerName }: any) {
  // Using a custom hook to manage file upload
  // and desctructuring its return values to use
  // inside functional component.
  const {
    originalFile: file,
    handleChange,
    handleError,
    handleSizeError,
    handleFileUpload,
  } = useFileUpload({ payerId, userId, formType, taxYear, payerName, noForm: false });

  return (
    <main
      style={{
        padding: "7rem 5rem 0",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        justifyContent: "center",
      }}
    >
      <div className="page-heading">
        <h2>
          {payerName} <div style={{ borderBottom: "3px double black" }} />
        </h2>
        <h2>
          Form: {formType} <div style={{ borderBottom: "3px double black" }} />
        </h2>
        <h2>
          Tax Year: {taxYear} <div style={{ borderBottom: "3px double black" }} />
        </h2>
      </div>
      <TemplateDownloader payerId={payerId} formType={formType} />
      <FileUploader
        children={<Dropbox file={file} />}
        multiple={false}
        types={["xlsx", "csv"]}
        handleChange={handleChange}
        maxSize={20}
        onTypeError={handleError}
        onSizeError={handleSizeError}
      />
      <SubmitButton pageType={PAGE_UPLOAD} handleSubmit={handleFileUpload} />
    </main>
  );
}
