import React, { useEffect, useMemo, useState } from "react";
import { AddBulkStaffActionPayload, GetStaffResponse, StaffRecord } from "../../../interfaces/main";
import Handler from "../../lib/Handlers/main";
import devLogInstance from "../../lib/loggerConfig";
import StaffTable from "./StaffRelated/StaffTable";
import BulkActionsSelector from "./StaffRelated/BulkActionsSelector";
import displayManualStaffAddingModal from "../../lib/StateSetters/StaffAndGroupRelated/displayManualStaffAddingModal";
import { AddStaffModalActionPayload } from "../../redux/reducers/Modal/displayManualStaffAddingModal";
import { useDispatch } from "react-redux";
import { TbFolderSearch } from "react-icons/tb";
import displayAddBulkStaffModal from "../../lib/StateSetters/StaffAndGroupRelated/displayAddBulkStaffModal";

export const getStaff = async (
  setStaffRecords: React.Dispatch<React.SetStateAction<StaffRecord[]>>
) => {
  const data: GetStaffResponse = await Handler.handleGettingStaff();
  devLogInstance.log("Staff Data");
  devLogInstance.log(data);
  if (data) setStaffRecords(data.staffs);
};

export default function ManageStaff() {
  const [staffRecords, setStaffRecords] = useState<StaffRecord[]>([]);

  const [selectedStaffIds, updateSelectedStaffIds] = useState<string[]>([]);
  devLogInstance.log("Selected staff ids");
  devLogInstance.log(selectedStaffIds);

  const [actionId, setActionId] = useState("");

  const dispatch = useDispatch();

  // Deselect all staff
  const deselectEveryStaff = () => {
    updateSelectedStaffIds([]);
  };

  // Get all the staff records created by this user
  useEffect(() => {
    getStaff(setStaffRecords);

    return () => setStaffRecords([]);
  }, []);

  // Everytime the staff record array updates
  // deselect all the staff
  useMemo(() => {
    if (staffRecords) deselectEveryStaff();
  }, [staffRecords]);

  // Handle when a single staff is selected
  const handleSingleStaffSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const staffId = e.currentTarget.id;

    if (isChecked) {
      updateSelectedStaffIds([...selectedStaffIds, staffId]);
    } else {
      const newStaffIds = selectedStaffIds.filter((id) => id !== staffId);
      updateSelectedStaffIds(newStaffIds);
    }
  };

  // Handle when all the staffs are selected
  const handleAllStaffSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      const allStaffIds = staffRecords.map((record) => record._id);
      updateSelectedStaffIds(allStaffIds);
    } else {
      updateSelectedStaffIds([]);
    }
  };

  // Handle click outside of action icon
  useEffect(() => {
    const handleClick = (e: any) => {
      const staffIds = staffRecords.map((record) => record._id);
      const id = e.target.id;

      if (!id || !staffIds.includes(id)) setActionId(""); // Responsible for closing any open action popup
    };
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  // Set the action id when clicked on the actions icon
  const clickedOnAction = (e: React.MouseEvent<any>) => {
    const { id } = e.currentTarget;
    setActionId(id);
  };

  // Handle adding a new staff
  const handleAddingStaff = async ({
    staff_name,
    staff_email,
    staff_phone,
  }: {
    staff_name: string;
    staff_email: string;
    staff_phone: string;
  }) => {
    devLogInstance.log({ staff_email, staff_name, staff_phone });
    await Handler.handleAddingStaff({ staff_name, staff_email, staff_phone });

    getStaff(setStaffRecords); // Reset the staff record
  };

  // Handle staff adding modal
  const handleStaffAddingModal = () => {
    const displayModalPayload: AddStaffModalActionPayload = {
      toDisplay: true,
      handleAddingStaff,
    };
    displayManualStaffAddingModal(displayModalPayload, dispatch);
  };

  // Open Add Bulk Staff modal
  const openAddBulkStaffModal = () => {
    const payload: AddBulkStaffActionPayload = {
      toDisplay: true,
      updateStaffRecords: setStaffRecords,
    };
    displayAddBulkStaffModal(payload, dispatch);
  };

  return (
    <div className="px-8 py-6 flex flex-col gap-8">
      {staffRecords.length > 0 ? (
        <section className="current-staff">
          <div className="flex justify-between items-center">
            <h2 className="underline underline-offset-2 decoration-double">Current Members</h2>

            <div className="flex items-center gap-4 w-[45%]">
              <button
                id="add-member-manually"
                className="py-2 px-4 rounded-lg bg-taxeve-primary-violet hover:opacity-80 text-white"
                onClick={handleStaffAddingModal}
              >
                Add Member
              </button>
              <button
                id="add-members-in-bulk"
                onClick={openAddBulkStaffModal}
                className="py-2 px-4 rounded-lg bg-taxeve-primary-red hover:opacity-80 text-white"
              >
                Add Members in bulk
              </button>
              <BulkActionsSelector
                totalSelectedStaffIdsOrGroupIds={selectedStaffIds.length}
                staffIdsOrGroupIds={selectedStaffIds}
                updateStaffOrGroupRecords={setStaffRecords}
                className="w-[35%]"
              />
            </div>
          </div>
          <StaffTable
            className="mt-4"
            staffRecords={staffRecords}
            setStaffRecords={setStaffRecords}
            handleSingleStaffSelection={handleSingleStaffSelection}
            handleAllStaffSelection={handleAllStaffSelection}
            selectedStaffIds={selectedStaffIds}
            deselectAllStaff={deselectEveryStaff}
            clickedOnActionWithId={clickedOnAction}
            actionId={actionId}
          />
        </section>
      ) : (
        <div className="h-[35rem] flex items-center justify-center">
          <div className="outline-slate-400 text-center px-8 py-6 shadow-xl rounded-xl w-[55%]">
            <div className="flex flex-col items-center border-b border-b-slate-300 py-4">
              <TbFolderSearch className="text-7xl opacity-40" />
              <p className="text-lg font-semibold opacity-40">No members found</p>
            </div>
            <div className="">
              <p className="p-4 text-md">
                You can add and invite new members by clicking the buttons below
              </p>
              <div className="flex items-center justify-center gap-4">
                <button
                  id="add-member-manually"
                  className="py-2 px-4 rounded-lg bg-taxeve-primary-violet hover:opacity-80 text-white"
                  onClick={handleStaffAddingModal}
                >
                  Add Member
                </button>
                <button
                  id="add-members-in-bulk"
                  onClick={openAddBulkStaffModal}
                  className="py-2 px-4 rounded-lg bg-taxeve-primary-red hover:opacity-80 text-white"
                >
                  Add Members in bulk
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
