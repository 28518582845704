import { RemoveStaffsFromGroupActionPayload } from "../../../../interfaces/main";
import { TO_DISPLAY_REMOVE_STAFFS_FROM_GROUP_MODAL } from "../../actions/action.types";

export interface RemoveStaffsFromGroupAction {
  type: string;
  payload: RemoveStaffsFromGroupActionPayload;
}

const initialState: RemoveStaffsFromGroupActionPayload = {
  toDisplay: false,
  groupId: "",
  assignedStaffs: [],
  updateGroupRecords: null,
};

const displayRemoveStaffsFromGroupModal = (
  state = initialState,
  action: RemoveStaffsFromGroupAction
) => {
  if (action.type === TO_DISPLAY_REMOVE_STAFFS_FROM_GROUP_MODAL) return action.payload;
  return state;
};

export default displayRemoveStaffsFromGroupModal;
