import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ViewAssignedStaffsActionPayload } from "../../../../../interfaces/main";
import displayViewAssignedStaffsModal from "../../../../lib/StateSetters/StaffAndGroupRelated/displayViewAssignedStaffsModal";
import { IoIosCloseCircleOutline } from "react-icons/io";

export default function ViewAssignedStaffsModal() {
  const dispatch = useDispatch();

  const { assignedStaffs, groupName }: ViewAssignedStaffsActionPayload = useSelector(
    (state: any) => state.displayViewAssignedStaffsModal
  );

  const closeModal = () =>
    displayViewAssignedStaffsModal(
      {
        toDisplay: false,
        assignedStaffs: [],
        groupName: "",
      },
      dispatch
    );
  return (
    <div className="form-modal shadow-lg">
      <div className="form-modal-header bg-taxeve-primary-violet text-white flex items-center justify-between">
        <h2>Assigned Members</h2>
        <IoIosCloseCircleOutline className="text-3xl cursor-pointer" onClick={closeModal} />
      </div>
      <div className="form-modal-body bg-white p-4 h-[25rem] overflow-auto">
        <div>
          <h3>
            <span className="underline underline-offset-2">Viewing all Members assigned to</span>:{" "}
            {groupName}
          </h3>
        </div>
        {assignedStaffs.length > 0 ? (
          <table className="mt-4">
            <thead>
              <tr>
                <th>S. No.</th>
                <th className="text-center">Member's Name</th>
                <th className="text-center">Member's Email</th>
              </tr>
            </thead>
            <tbody>
              {assignedStaffs.map((staff, i) => (
                <tr key={staff._id}>
                  <td className="font-bold">{i + 1}</td>
                  <td className="text-center py-2 font-semibold">
                    <em>{staff.staff_name}</em>
                  </td>
                  <td className="text-center">{staff.staff_email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="h-[18rem] flex items-center justify-center text-2xl font-bold opacity-40">
            <em>Currently there are no Members assigned to this group yet</em>
          </div>
        )}
      </div>
    </div>
  );
}
