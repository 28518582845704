import { AdminCouponData } from "../../../interfaces/main";
import { axiosInstance } from "../axiosConfig";

export default async function getAdminCoupons(): Promise<AdminCouponData[]> {
  try {
    const { data } = await axiosInstance.get("/admin/get_coupons");
    return data.priceData;
  } catch (err) {
    throw err;
  }
}
