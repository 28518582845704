import { SET_IS_ADMIN_AUTHENTICATED } from "../../actions/action.types";

interface Action {
  type: string;
  payload: boolean;
}

const isAdminAuthenticated = (state = null, action: Action): boolean | null => {
  const type = action.type;
  const payload = action.payload;
  if (type === SET_IS_ADMIN_AUTHENTICATED) {
    return payload;
  }
  return state;
};

export default isAdminAuthenticated;
