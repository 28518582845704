import { SET_CART_PAGE_BULK_ACTIONS } from "../../actions/action.types";

const cartPageBulkAction = (state = null, action: any) => {
  const type = action.type;
  if (type === SET_CART_PAGE_BULK_ACTIONS) {
    const bulkActionType = action.payload.bulkActionType;
    if (bulkActionType === null) return null;
    return action.payload;
  }
  return state;
};

export default cartPageBulkAction;
