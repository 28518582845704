import React from "react";
// import OnlineAccessLoginImg from "../../components/decorations/OnlineAccessLoginImg";

export default function OnlineAccessLanding() {
  return (
    <div className="min-h-[100vh]">
      <section className="text-center py-10 flex justify-center">
        {/* <h1 className="text-3xl">Tax Eve Logo</h1> */}
        <img src="/Assets/taxeve_logo.png" alt="Taxeve" className="w-[10%]" />
      </section>
      <section className="flex justify-center items-center gap-8 px-20 py-6">
        <div className="w-[50%] px-20 text-slate-400">
          {/* <div className="flex justify-center">
            <OnlineAccessLoginImg />
          </div> */}
          <p>This secure portal enables you to view and download your Form 1099 at any time.</p>
          <br />
          <p>
            You will receive email notifications if your employer files any corrections to your Form
            1099.
          </p>
        </div>
        <div className="text-lg text-center font-semibold">
          Please follow the link sent to your email id to login to the online access portal.
        </div>
      </section>
    </div>
  );
}
