import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import globalFormData from "../lib/globalFormData";
import logger from "../logger";
import { useSelector, useDispatch } from "react-redux";
import { DEFAULT_NON_FOREIGN_COUNTRY, PAGE_UPLOAD, SUBMIT_CONTINUE } from "../lib/constants";
import Handler from "../lib/Handlers/main";
import "../styles/Pages/Upload.css";
import Navbar from "../components/navbar";
import formSubmissionHelper from "../lib/FormSubmission/formSubmissionHelper";
import handleInputDisplayClearance from "../lib/form/utils/handleInputDisplayClearance";
import AddPayer from "../components/UploadPage/AddPayer/AddPayer";
import AddFile from "../components/UploadPage/AddFile/AddFile";
import loader from "../components/decorations/Loader";
import devLogInstance from "../lib/loggerConfig";
import { FILE_UPLOAD } from "../lib/routes";

export default function Upload({ showFeedbackWindow: feedbackWindowObj }: any) {
  const { formType, flowType, taxYear } = useParams();
  const location = useLocation();
  const payerId = new URLSearchParams(location.search).get("id");
  const userId = new URLSearchParams(location.search).get("uid");
  const payerName = new URLSearchParams(location.search).get("payerName");
  logger.log(`Payer id recieved on URL ---> ${payerId}`);
  logger.log(`User id recieved on URL ---> ${userId}`);
  const [formData, setFormData] = useState({
    ...globalFormData,
    year: taxYear ? parseInt(taxYear) : 0, // update the year on the global data
  });
  logger.log(formData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const style = useSelector((state: any) => state.themeChange);
  const startLoader = useSelector((state: any) => state.startLoader);

  // Log the feedback window object
  devLogInstance.log(feedbackWindowObj);

  // Handle getting payer names
  useEffect(() => {
    if (flowType === "select-payer") Handler.handlePayerNameProcessing(dispatch);
    return () => {
      handleInputDisplayClearance(dispatch);
      setFormData({
        ...globalFormData,
        year: taxYear ? parseInt(taxYear) : 0,
      });
    };
  }, [dispatch, flowType, taxYear]);

  // Handle payer details / form submit
  const handleSubmit = async (e: any) => {
    const submitType = SUBMIT_CONTINUE;
    // console.log(formData);
    const payerCountry = formData.payer_country
      ? formData.payer_country
      : DEFAULT_NON_FOREIGN_COUNTRY;
    const updatedData = { ...formData, payer_country: payerCountry };
    const userDetails = await formSubmissionHelper(
      updatedData,
      null,
      null,
      null,
      setFormData,
      null,
      dispatch,
      submitType,
      navigate,
      PAGE_UPLOAD
    );

    if (userDetails && typeof userDetails !== "boolean") {
      // Navigate the user to the 2nd portion of the upload flow
      navigate(
        `${FILE_UPLOAD}/${formType}/template-upload/${taxYear}?id=${userDetails[0]}&uid=${userDetails[1]}&payerName=${userDetails[2]}`
      );
    }
  };

  return !startLoader ? (
    <div className="upload-page-container" style={{ position: "relative" }}>
      <Navbar />
      {flowType === "select-payer" ? (
        <AddPayer
          formData={formData}
          setFormData={setFormData}
          handleSubmit={handleSubmit}
          formType={formType}
          taxYear={taxYear}
        />
      ) : (
        <AddFile
          payerId={payerId}
          userId={userId}
          formType={formType}
          taxYear={taxYear}
          payerName={payerName}
        />
      )}
    </div>
  ) : (
    loader.start(style)
  );
}
