import devLogInstance from "../../../lib/loggerConfig";
import {
  REMOVE_REC_FROM_RECIPIENTS_WITHOUT_EMAIL_ARRAY,
  SET_RECIPIENTS_WITHOUT_EMAIL_ID,
} from "../../actions/action.types";

const recipientsWithoutEmailId = (state: any[] = [], action: any) => {
  if (action.type === SET_RECIPIENTS_WITHOUT_EMAIL_ID) {
    if (action.payload.length === 0) return action.payload; // Return the empty array recieved in the payload
    return [...state, action.payload];
  } else if (action.type === REMOVE_REC_FROM_RECIPIENTS_WITHOUT_EMAIL_ARRAY) {
    const cartIdToRemove = action.payload;
    const filteredArray = state.filter((obj) => {
      return obj.cartId !== cartIdToRemove;
    });
    devLogInstance.log(filteredArray);
    return [...filteredArray];
  }
  return state;
};

export default recipientsWithoutEmailId;
