import { TO_SHOW_AL_WARNING } from "../../actions/action.types";

const initialState = {
  onPayerAL1: false,
  onPayerAL2: false,

  // Recipient block
  onRecipientAL1: false,
  onRecipientAL2: false,
};

const showALWarning = (state = initialState, action: any) => {
  if (action.type === TO_SHOW_AL_WARNING) return action.payload;
  else return state;
};

export default showALWarning;
