// import retrieveToken from "../authRequests/retrieveTokens";
import { axiosInstance } from "../axiosConfig";
import devLogInstance from "../loggerConfig";

export default async function formUnreview(cartIds: any[]) {
  try {
    const { data } = await axiosInstance.post("/unreview_form", {
      cart_id_array: cartIds,
    });
    devLogInstance.log(data);
    return data;
  } catch (err) {
    throw err;
  }
}
