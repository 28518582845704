import React from "react";
import { FORM1099_S } from "../../../../lib/constants";
import { recipientDetails, recipientDetailsFor1099S } from "../../../../lib/languagePacks/en-us";

export default function RFRecName({ formData }: any) {
  return (
    <div className="rf-child-component">
      <div>
        <h4>
          {formData?.form_type === FORM1099_S
            ? recipientDetailsFor1099S.recipientNamePlaceholder
            : recipientDetails.recipientNamePlaceholder}
        </h4>
      </div>
      <div style={{ color: "#30A2FF" }}>
        <p>
          {formData?.recipient_name} {formData?.recipient_name_cont}
        </p>
      </div>
    </div>
  );
}
