import { TO_DISPLAY_MODAL } from "../../actions/action.types";

const initial = {
  toDisplay: false,
  modaltype: null,
};

const displayModal = (state = initial, action: any) => {
  if (action.type === TO_DISPLAY_MODAL) return action.payload;
  return state;
};

export default displayModal;
