import React from "react";
import Select from "react-select";
import ReactSelectOptions from "../../../lib/utils/ReactSelectOptions";
import SelectStyle from "../../../lib/ReactSelectTheme/reactSelectStyle";
import { useDispatch, useSelector } from "react-redux";
import { BULK_DELETE_GROUPS, BULK_REMOVE_STAFF } from "../../../lib/constants";
import displayConfirmationModal from "../../../lib/StateSetters/displayConfirmationModal";
import { GroupRecord, StaffRecord } from "../../../../interfaces/main";

interface Props extends React.HTMLProps<HTMLElement> {
  totalSelectedStaffIdsOrGroupIds: number;
  staffIdsOrGroupIds: string[];
  updateStaffOrGroupRecords:
    | React.Dispatch<React.SetStateAction<StaffRecord[]>>
    | React.Dispatch<React.SetStateAction<GroupRecord[]>>;
  isGroup?: boolean;
}

export default function BulkActionsSelector(props: Props) {
  const style = useSelector((state: any) => state.themeChange);
  const dispatch = useDispatch();

  const handleChange = (option: { label: string; value: string } | null) => {
    if (option) {
      const { value } = option;

      switch (value) {
        case BULK_REMOVE_STAFF:
          displayConfirmationModal(
            dispatch,
            BULK_REMOVE_STAFF,
            true,
            props.updateStaffOrGroupRecords,
            props.staffIdsOrGroupIds
          );
          break;

        case BULK_DELETE_GROUPS:
          displayConfirmationModal(
            dispatch,
            BULK_DELETE_GROUPS,
            true,
            props.updateStaffOrGroupRecords,
            props.staffIdsOrGroupIds
          );
          break;

        default:
          break;
      }
    }
  };

  return (
    <div className={`${props.className}`}>
      <Select
        isSearchable={false}
        options={
          !props.isGroup
            ? ReactSelectOptions.staffRelatedBulkActions(props.totalSelectedStaffIdsOrGroupIds)
            : ReactSelectOptions.groupRelatedBulkActions(props.totalSelectedStaffIdsOrGroupIds)
        }
        theme={new SelectStyle(style).customTheme}
        styles={new SelectStyle(style).customStyle}
        placeholder="Bulk Actions"
        onChange={handleChange}
        value={null}
      />
    </div>
  );
}
