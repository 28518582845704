import { TO_START_LOADER } from "../../redux/actions/action.types";

export default class LoaderState {
  static stateHandler: any;
  static initialize() {
    LoaderState.stateHandler &&
      LoaderState.stateHandler({
        type: TO_START_LOADER,
        payload: true,
      });
  }
  static deinitialize() {
    LoaderState.stateHandler &&
      LoaderState.stateHandler({
        type: TO_START_LOADER,
        payload: false,
      });
  }
}
