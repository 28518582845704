import React, { useEffect, useMemo, useState } from "react";
import { GetGroupsResponse, GroupRecord } from "../../../interfaces/main";
import Handler from "../../lib/Handlers/main";
import devLogInstance from "../../lib/loggerConfig";
import BulkActionsSelector from "./StaffRelated/BulkActionsSelector";
import { useDispatch } from "react-redux";
import { TbFolderSearch } from "react-icons/tb";
import GroupsTable from "./GroupRelated/GroupsTable";
import displayManualGroupCreatingModal from "../../lib/StateSetters/StaffAndGroupRelated/displayManualGroupCreatingModal";
import { CreateGroupModalActionPayload } from "../../redux/reducers/Modal/displayManualGroupCreatingModal";
import displayBulkCreateGroupModal from "../../lib/StateSetters/StaffAndGroupRelated/displayBulkCreateGroupModal";

export const getGroup = async (
  setGroupRecords: React.Dispatch<React.SetStateAction<GroupRecord[]>>
) => {
  const res: GetGroupsResponse = await Handler.handleGettingGroups();
  devLogInstance.log("Group Data");
  devLogInstance.log(res);
  if (res) setGroupRecords(res.groups);
};

export default function ManageGroup() {
  const [groupRecords, setGroupRecords] = useState<GroupRecord[]>([]);

  const [selectedGroupIds, updateSelectedGroupIds] = useState<string[]>([]);
  devLogInstance.log("Selected group ids");
  devLogInstance.log(selectedGroupIds);

  const [actionId, setActionId] = useState("");

  const dispatch = useDispatch();

  // Deselect all groups
  const deselectEveryGroup = () => {
    updateSelectedGroupIds([]);
  };

  // Get all the group records created by this user
  useEffect(() => {
    getGroup(setGroupRecords);

    return () => setGroupRecords([]);
  }, []);

  // Everytime the group record array updates
  // deselect all the group
  useMemo(() => {
    if (groupRecords) deselectEveryGroup();
  }, [groupRecords]);

  // Handle when a single group is selected
  const handleSingleGroupSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const groupId = e.currentTarget.id;

    if (isChecked) {
      updateSelectedGroupIds([...selectedGroupIds, groupId]);
    } else {
      const newGroupIds = selectedGroupIds.filter((id) => id !== groupId);
      updateSelectedGroupIds(newGroupIds);
    }
  };

  // Handle when all the groups are selected
  const handleAllGroupSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      const allGroupIds = groupRecords.map((record) => record._id);
      updateSelectedGroupIds(allGroupIds);
    } else {
      updateSelectedGroupIds([]);
    }
  };

  // Handle click outside of action icon
  useEffect(() => {
    const handleClick = (e: any) => {
      const groupIds = groupRecords.map((record) => record._id);
      const id = e.target.id;

      if (!id || !groupIds.includes(id)) setActionId(""); // Responsible for closing any open action popup
    };
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  // Set the action id when clicked on the actions icon
  const clickedOnAction = (e: React.MouseEvent<any>) => {
    const { id } = e.currentTarget;
    setActionId(id);
  };

  // Handle adding a new group
  const handleCreatingGroup = async ({
    group_name,
    group_description,
  }: {
    group_name: string;
    group_description: string;
  }) => {
    devLogInstance.log({ group_name, group_description });
    await Handler.handleCreatingGroup({ group_name, group_description });

    // Close the group creation modal
    displayManualGroupCreatingModal({ toDisplay: false, handleCreatingGroup: null }, dispatch);

    getGroup(setGroupRecords); // Reset the group record
  };

  // Handle group adding modal
  const handleGroupAddingModal = () => {
    const groupCreatinModalProps: CreateGroupModalActionPayload = {
      toDisplay: true,
      handleCreatingGroup: handleCreatingGroup,
    };
    displayManualGroupCreatingModal(groupCreatinModalProps, dispatch);
  };

  const openBulkCreateGroupModal = () => {
    displayBulkCreateGroupModal(
      {
        toDisplay: true,
      },
      dispatch
    );
  };

  return (
    <div className="px-8 py-6 flex flex-col gap-8">
      {groupRecords.length > 0 ? (
        <section className="current-staff">
          <div className="flex justify-between items-center">
            <h2 className="underline underline-offset-2 decoration-double">Current Groups</h2>

            <div className="flex items-center gap-4 w-[45%]">
              <button
                id="create-group-manually"
                className="py-2 px-4 rounded-lg bg-taxeve-primary-violet hover:opacity-80 text-white"
                onClick={handleGroupAddingModal}
              >
                Create Group
              </button>
              <button
                id="create-groups-in-bulk"
                onClick={openBulkCreateGroupModal}
                className="py-2 px-4 rounded-lg bg-taxeve-primary-red hover:opacity-80 text-white"
              >
                Create Groups in bulk
              </button>
              <BulkActionsSelector
                totalSelectedStaffIdsOrGroupIds={selectedGroupIds.length}
                staffIdsOrGroupIds={selectedGroupIds}
                updateStaffOrGroupRecords={setGroupRecords}
                isGroup={true}
                className="w-[35%]"
              />
            </div>
          </div>
          <GroupsTable
            className="mt-4"
            groupRecords={groupRecords}
            setGroupRecords={setGroupRecords}
            handleSingleGroupSelection={handleSingleGroupSelection}
            handleAllGroupSelection={handleAllGroupSelection}
            selectedGroupIds={selectedGroupIds}
            deselectAllGroups={deselectEveryGroup}
            clickedOnActionWithId={clickedOnAction}
            actionId={actionId}
          />
        </section>
      ) : (
        <div className="h-[35rem] flex items-center justify-center">
          <div className="outline-slate-400 text-center px-8 py-6 shadow-xl rounded-xl w-[55%]">
            <div className="flex flex-col items-center border-b border-b-slate-300 py-4">
              <TbFolderSearch className="text-7xl opacity-40" />
              <p className="text-lg font-semibold opacity-40">No groups found</p>
            </div>
            <div className="">
              <p className="p-4 text-md">You can create new groups by clicking the buttons below</p>
              <div className="flex items-center justify-center gap-4">
                <button
                  id="create-group-manually"
                  className="py-2 px-4 rounded-lg bg-taxeve-primary-violet hover:opacity-80 text-white"
                  onClick={handleGroupAddingModal}
                >
                  Create Group
                </button>
                <button
                  id="create-groups-in-bulk"
                  onClick={openBulkCreateGroupModal}
                  className="py-2 px-4 rounded-lg bg-taxeve-primary-red hover:opacity-80 text-white"
                >
                  Create Groups in bulk
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
