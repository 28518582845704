import React from "react";
import { useSelector } from "react-redux";
import { EPF, FILER_TYPE, PSE } from "../../../../lib/constants";
import { filerType, indicatorTexts } from "../../../../lib/languagePacks/en-us";
import Indicator from "../../../Indicator";

export default function FilerType({ handleChange, filerTypeIndicator, required }: any) {
  const style = useSelector((state: any) => state.themeChange);
  return (
    <div
      className="filer-type-container"
      style={{ borderRight: `1px solid ${style.eFormBorderColor}` }}
    >
      <div style={{ display: "flex", gap: "0.25rem" }}>
        <span style={{ color: "red" }}>*</span>
        <span>{filerType.heading}</span>
      </div>
      <div>
        <div className="pse-wrapper">
          <label htmlFor={PSE}>{filerType.types.pse}</label>
          <input
            id={PSE}
            type="checkbox"
            checked={filerTypeIndicator === 1}
            onChange={handleChange}
          />
        </div>
        <div className="epf-wrapper">
          <label htmlFor={EPF}>{filerType.types.epf}</label>
          <input
            id={EPF}
            type="checkbox"
            checked={filerTypeIndicator === 2}
            onChange={handleChange}
          />
        </div>
      </div>
      {required && <Indicator type={FILER_TYPE} message={indicatorTexts.filerTypeRequired} />}
    </div>
  );
}
