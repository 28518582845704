import { SET_TO_DISABLE_SCREEN } from "../../redux/actions/action.types";

class DisableScreen {
  static stateHandler: any;
  // constructor(stateHandler) {
  // 	this.stateHandler = stateHandler;
  // }
  static enable() {
    DisableScreen.stateHandler &&
      DisableScreen.stateHandler({
        type: SET_TO_DISABLE_SCREEN,
        payload: true,
      });
  }

  static disable() {
    DisableScreen.stateHandler &&
      DisableScreen.stateHandler({
        type: SET_TO_DISABLE_SCREEN,
        payload: false,
      });
  }
}

export default DisableScreen;
