import React, { useState } from "react";
import Indicator from "../../Indicator";
import { EmailValidator } from "../../../lib/validators";
import formatPhonenum from "../../../lib/form/utils/formatPhone";
import devLogInstance from "../../../lib/loggerConfig";
import displayManualStaffAddingModal from "../../../lib/StateSetters/StaffAndGroupRelated/displayManualStaffAddingModal";
import { useDispatch } from "react-redux";

interface Props extends React.HTMLProps<HTMLElement> {
  handleAddingStaff:
    | (({
        staff_name,
        staff_email,
        staff_phone,
      }: {
        staff_name: string;
        staff_email: string;
        staff_phone: string;
      }) => void)
    | null;
}

const unformatPhonenum = (formattedPhonenum: string | number) => {
  const phone = formattedPhonenum.toString();
  return phone.replace(/\(/g, "").replace(/\)/g, "").replace(/ /g, "").replace(/-/g, "");
};

export default function AddStaffForm(props: Props) {
  const [staffData, setStaffData] = useState({
    staff_name: "",
    staff_email: "",
    staff_phone: "",
  });

  const [showErrorOn, toShowErrorOn] = useState({
    staffName: false,
    staffEmail: false,
    staffPhone: false,
  });

  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.currentTarget;

    switch (id) {
      case "staff-name":
        // Remove any error indicator
        toShowErrorOn({ ...showErrorOn, staffName: false });

        setStaffData({
          ...staffData,
          staff_name: value,
        });
        break;

      case "staff-email":
        // Remove any error indicator
        toShowErrorOn({ ...showErrorOn, staffEmail: false });

        setStaffData({
          ...staffData,
          staff_email: value,
        });
        break;

      case "staff-phone":
        // Remove any error indicator
        toShowErrorOn({ ...showErrorOn, staffPhone: false });

        setStaffData({
          ...staffData,
          staff_phone: formatPhonenum(value),
        });
        break;

      default:
        break;
    }
  };

  const basicValidations = (staff_name: string, staff_email: string, staff_phone: string) => {
    return new Promise(async (res, rej) => {
      const invalidFields = [];
      if (!staff_name) invalidFields.push("staff-name");

      if (staff_phone && unformatPhonenum(staff_phone).length < 10)
        invalidFields.push("staff-phone");

      try {
        await EmailValidator.validateEmail(staff_email);
      } catch (error) {
        invalidFields.push("staff-email");
      } finally {
        invalidFields.length > 0 ? rej(invalidFields) : res(true);
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent the page from refresing

    const { staff_name, staff_email, staff_phone } = staffData;

    try {
      await basicValidations(staff_name, staff_email, staff_phone);

      if (props.handleAddingStaff) {
        // Call the add staff handler
        props.handleAddingStaff({ ...staffData, staff_phone: unformatPhonenum(staff_phone) });

        // Clear the input fields
        setStaffData({
          staff_email: "",
          staff_name: "",
          staff_phone: "",
        });

        // close the modal
        displayManualStaffAddingModal(
          {
            toDisplay: false,
            handleAddingStaff: null,
          },
          dispatch
        );
      } else devLogInstance.error("add staff handler method is null!");
    } catch (invalidFields: any) {
      invalidFields.forEach((field: string) => {
        switch (field) {
          case "staff-name":
            toShowErrorOn((prevState) => {
              return { ...prevState, staffName: true };
            });
            break;

          case "staff-email":
            toShowErrorOn((prevState) => {
              return { ...prevState, staffEmail: true };
            });
            break;

          case "staff-phone":
            toShowErrorOn((prevState) => {
              return { ...prevState, staffPhone: true };
            });
            break;

          default:
            break;
        }
      });
    }
  };

  return (
    <form className={`${props.className} py-6 flex flex-col gap-6`} onSubmit={handleSubmit}>
      <div className="flex flex-col gap-1">
        <label htmlFor="staff-name" className="label">
          Member's Name*
        </label>
        <input
          type="text"
          id="staff-name"
          className="p-2 w-full"
          onChange={handleChange}
          value={staffData.staff_name}
        />
        {showErrorOn.staffName && (
          <Indicator type="staff-name" message="Member's Name is required!" />
        )}
      </div>

      <div className="flex flex-col gap-1">
        <label htmlFor="staff-email" className="label">
          Member's Email*
        </label>
        <input
          type="email"
          id="staff-email"
          className="p-2 w-full"
          onChange={handleChange}
          value={staffData.staff_email}
        />
        {showErrorOn.staffEmail && (
          <Indicator type="staff-email" message="A valid email is required!" />
        )}
      </div>

      <div className="flex flex-col gap-1">
        <label htmlFor="staff-phone" className="label">
          Member's Phone no.
        </label>
        <input
          type="text"
          id="staff-phone"
          className="p-2 w-full"
          onChange={handleChange}
          value={staffData.staff_phone}
        />
        {showErrorOn.staffPhone && (
          <Indicator
            type="staff-phone"
            message="Please provide a valid phone number! Check the length of your phone number!"
          />
        )}
      </div>

      <div className="text-center">
        <button
          id="add-invite-member"
          type="submit"
          className="py-2 px-4 rounded-md hover:opacity-80 bg-taxeve-primary-violet text-white"
        >
          Add & Invite Member
        </button>
      </div>
    </form>
  );
}
